import { useEffect, useState, useContext } from "react";
import FolderCard from "../../components/chatBot/FolderCard";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import Toast from "../../components/common/Toast";
import ModuleCard from "../../components/module/ModuleCard";
import CardSkeleton from "../../components/loaders/CardSkeleton";

const Modules = () => {
  const [folders, setFolders] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [gpt_key, setgpt_key] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [gpt_keyError, setgpt_keyError] = useState("");
  const [open, setOpen] = useState(false);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const location = useNavigate();
  useEffect(() => {
    getAllFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  useEffect(() => {
    getUserInfo();
  }, []);

  const validation = (name, value, error = null) => {
    if (name === "description") {
      if (error != null) {
        setDescriptionError("This Field is required");
      } else {
        if (value !== "") {
          setDescription(value);
          setDescriptionError("");
        } else {
          setDescription("");
          setDescriptionError("This field is required");
        }
      }
    }

    if (name === "name") {
      if (error != null) {
        setTitleError("This Field is required");
      } else {
        if (value !== "") {
          setTitleError("");
          setTitle(value);
        } else {
          setTitle("");
          setTitleError("This field is required");
        }
      }
    }

    if (name === "gpt_key") {
      if (error != null) {
        setgpt_keyError("This Field is required");
      } else {
        if (value !== "") {
          setgpt_keyError("");
          setgpt_key(value);
        } else {
          setgpt_key("");
          setgpt_keyError("This field is required");
        }
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.name, e.target.value);

    validation(e.target.name, e.target.value);
  };
  // Get all products api call
  const getAllFolders = () => {
    setProcessing(true);

    var postData = {
      search: searchInput,
      community_id: currCommunityId,
    };

    api
      .post("/api/moduleList", postData)
      .then((response) => {
        setFolders(response.data.data);
        setProcessing(false);
      })
      .catch((error) => {
        setFolders([]);
        setProcessing(false);
      });
  };
  const folderSearch = (searchValue) => {
    setSearchInput(searchValue);
  };

  // add new folder
  const folderForm = (e) => {
    e.preventDefault();
    setProcessing(true);
    var postData = {
      name: title,
      description: description,
      gptKey: gpt_key,
      community_id: currCommunityId,
    };
    api
      .post("/api/addModule", postData)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "New Module Added Successfully",
          });
          setOpen(false);
          getAllFolders();
          location("/build-module/" + response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
          setToastMessage({ type: "error", message: "Something Went Wrong" });
        }
        setProcessing(false);
      });
  };

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            <div className="lg:flex items-center justify-between mb-4">
              <h3 className="mb-4 lg:mb-0 main-title">Module List</h3>
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <span className="input-wrapper ">
                  <div className="input-suffix-start">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      className="text-lg"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <input
                    className="input input-sm h-9 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => folderSearch(e.target.value)}
                  ></input>
                </span>
                <button
                  className="button bg-indigo-50 hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round h-9 px-3 py-2 text-sm"
                  onClick={() => setOpen(true)}
                >
                  <span className="flex items-center justify-center">
                    <span className="text-lg">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </span>
                    <span className="ltr:ml-1 rtl:mr-1">New Module</span>
                  </span>
                </button>
              </div>
            </div>
            {processing ? (
              <CardSkeleton />
            ) : folders.length > 0 ? (
              <div className="mt-6 h-full flex flex-col">
                <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                  {folders.map((folder, key) => (
                    <ModuleCard
                      key={key}
                      module={folder}
                      getAllFolders={getAllFolders}
                      setShowToast={setShowToast}
                      setToastMessage={setToastMessage}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-full flex flex-col items-center justify-center">
                <div className="text-center">
                  <img
                    src={require("../../assets/images/welcome.png")}
                    alt="Welcome"
                    className="mx-auto mb-8"
                  />
                  <h3 className="mb-2">
                    Welcome on Board, lets get started with Train your gpt
                    chatbot
                  </h3>
                  <p className="text-base">
                    Telling us a bit about yourself to get the best experience,
                    this will only take a minute or two.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {open && (
          <div
            id="defaultModal"
            tabIndex={-1}
            className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
          >
            <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
              <div className="relative dialog-content bg-white rounded-lg shadow">
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Add new Module
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                    data-modal-hide="defaultModal"
                    onClick={() => setOpen(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <form action="#" onSubmit={folderForm}>
                  <div className="px-6">
                    <div className="mt-4">
                      <div className="form-container vertical">
                        <div className="form-item vertical">
                          <label className="form-label mb-2 required-input">
                            Title
                          </label>
                          <div className>
                            <input
                              className={`${
                                titleError !== "" ? "input-danger" : ""
                              } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                              type="text"
                              name="name"
                              autoComplete="off"
                              placeholder="Enter title"
                              value={title}
                              onChange={(e) => handleChange(e)}
                            />
                            {titleError !== "" ? (
                              <div className="form-error">{titleError}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-item vertical">
                          <label className="form-label mb-2 required-input">
                            Gpt Key
                          </label>
                          <div className>
                            <input
                              className={`${
                                gpt_keyError !== "" ? "input-danger" : ""
                              } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                              type="text"
                              name="gpt_key"
                              autoComplete="off"
                              placeholder="Enter gpt key"
                              value={gpt_key}
                              onChange={(e) => handleChange(e)}
                            />
                            {gpt_keyError !== "" ? (
                              <div className="form-error">{gpt_keyError}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-item vertical invalid">
                          <label className="form-label mb-2 required-input">
                            Content
                          </label>
                          <div className>
                            <textarea
                              className={`${
                                descriptionError !== "" ? "input-danger" : ""
                              } input-textarea input-form input input-invalid input-textarea`}
                              type="text"
                              name="description"
                              autoComplete="off"
                              placeholder="Enter content"
                              spellCheck="false"
                              value={description}
                              onChange={(e) => handleChange(e)}
                            />
                            {descriptionError !== "" ? (
                              <div className="form-error">
                                {descriptionError}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                    <button
                      className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                      type="submit"
                      disabled={processing}
                    >
                      {processing && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}{" "}
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modules;
