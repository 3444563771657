const Checkbox = ({ label, checked, onChange, id, showCheckbox }) => {
  if (!id) {
    return (
      <div className="text-red-600">
        Id is required for this checkbox component, Please give the id unique
        for this page
      </div>
    );
  }

  return (
    <div className="flex items-center">
      {showCheckbox ? (
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          className="w-[14px] h-[14px]"
        />
      ) : null}

      <label className="ml-2 mt-0.5" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
