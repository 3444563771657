import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../../api/api";
import SignInCommon from "../../components/common/SignInCommon";

const ResetPassword = () => {
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordType, setPasswordType] = useState({
    password: "password",
    confirmPassword: "password",
    currentPassword: "password",
  });
  const [formError, setFormError] = useState("");
  const [processing, setProcessing] = useState(false);
  const { id } = useParams();
  const { currUser, getUserInfo, setToastMessage, setShowToast } =
    useContext(AppContext);
  const location = useNavigate();

  useEffect(() => {
    if (confirmPassword !== "" && password !== "") {
      if (confirmPassword !== password) {
        setConfirmPasswordError(
          "The Password and confirm password Didn't Match"
        );
      } else {
        setConfirmPasswordError("");
      }
    }
  }, [password, confirmPassword]);

  const togglePassword = (type) => {
    setPasswordType((prevState) => ({
      ...prevState,
      [type]: prevState[type] === "password" ? "text" : "password",
    }));
  };
  const validation = (name, value, error = null) => {
    if (name === "password") {
      if (error != null) {
        setPasswordError(error);
      } else {
        if (value !== "") {
          setPasswordError("");
          setPassword(value);
        } else {
          setPassword("");
          setPasswordError("This field is required");
        }
      }
    }
    if (name === "password_confirmation") {
      if (error != null) {
        setConfirmPasswordError(error);
      } else {
        if (value !== "") {
          setConfirmPasswordError("");
          setConfirmPassword(value);
        } else {
          setConfirmPassword("");
          setConfirmPasswordError("This field is required");
        }
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    validation(e.target.name, e.target.value);
  };

  const handleForm = (e) => {
    e.preventDefault();
    setProcessing(true);
    var payload = {
      password: password,
      password_confirmation: confirmPassword,
      token: id,
    };

    api
      .post("/api/auth/updatePassword", payload)
      .then((response) => {
        // console.log(response.data);
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Password Updated Successfully",
        });
        location("/signin");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
        setProcessing(false);
      });
  };

  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-3 h-full">
        <SignInCommon />
        <div className=" flex flex-col justify-center items-center bg-white">
          <div className="xl:min-w-[450px] px-8">
            <div className="mb-8">
              <h3 className="mb-1 text-3xl font-semibold">Hello!</h3>
              <p className="text-gray-600">
                Please enter your Details to Reset password!
              </p>
            </div>
            <div>
              <form action="#" method="post" onSubmit={handleForm}>
                <div>
                  <div className="form-item vertical w-full">
                    <label className="form-label mb-2 required-input">
                      New Password
                    </label>
                    <div className>
                      <span className="input-wrapper w-full">
                        <input
                          type={passwordType.password}
                          className={`${
                            passwordError !== "" ? "input-danger" : ""
                          } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                          name="password"
                          autoComplete="off"
                          placeholder="Password"
                          style={{ paddingRight: "2.25rem" }}
                          onChange={(e) => handleChange(e)}
                          value={password}
                        />
                        <div className="input-suffix-end">
                          <span className="cursor-pointer text-xl">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth={0}
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => togglePassword("password")}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              />
                            </svg>
                          </span>
                        </div>
                      </span>
                      {passwordError !== "" ? (
                        <div className="form-error">{passwordError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className=" w-full">
                    <label className="form-label mb-2 required-input">
                      Confirm Password
                    </label>
                    <div className>
                      <span className="input-wrapper w-full">
                        <input
                          type={passwordType.confirmPassword}
                          className={`${
                            confirmPasswordError !== "" ? "input-danger" : ""
                          } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                          name="password_confirmation"
                          autoComplete="off"
                          placeholder="Confirm Password"
                          style={{ paddingRight: "2.25rem" }}
                          onChange={(e) => handleChange(e)}
                          value={confirmPassword}
                        />
                        <div className="input-suffix-end">
                          <span className="cursor-pointer text-xl">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth={0}
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => togglePassword("confirmPassword")}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              />
                            </svg>
                          </span>
                        </div>
                      </span>
                      {confirmPasswordError !== "" ? (
                        <div className="form-error">{confirmPasswordError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <button
                    className="mt-4 inline-flex items-center justify-center px-4 py-2 font-semibold leading-6 shadow rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-150 w-full"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
