import { useCallback, useContext, useEffect, useRef, useState } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import Spinner from "../loaders/Spinner";
import moment from "moment";
import WhatsappChat from "./WhatsappChat";
import PreviewAttachment from "../common/PreviewAttachment";
import AttachmentThumpnail from "../common/AttachmentThumpnail";
import {
  MIME_TYPE_TO_EXTENSION_MAPPING,
  socketURL,
} from "../common/commonHelpers";
import useApiData from "../../hooks/useApiData";
import Loading from "../loaders/Loading";
import LoadMoreBtn from "./LoadMoreBtn";
import AssignDropdown from "./AssignDropdown";
import useTeam from "../settings/team/useTeam";

function getContentHeight() {
  const containerHeight =
    document.getElementById("content-container")?.scrollHeight;

  const headerHeight = document.getElementById("chat-box-header")?.scrollHeight;

  const footerHeight = document.getElementById("chat-box-footer")?.scrollHeight;

  const occupied = headerHeight + footerHeight;

  return containerHeight - occupied;
}

const UserChat = (props) => {
  const [messages, setMessages] = useState({
    messages: [],
  });
  const [whatsappMsgs, setWhatsappMsgs] = useState([]);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    socket,
    setSocket,
    setCurrSocketId,
    currSocketId,
  } = useContext(AppContext);
  const [newMessage, setNewMessage] = useState("");
  const messagesRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [groupedMessages, setGroupMessages] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [openAttachmentPreview, setOpenAttachmentPreview] = useState();
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    type: "",
  });
  const [popOpen, setPopOpen] = useState({
    open: false,
    type: "",
  });
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const btnRef = useRef(null);
  const inputFileRef = useRef(null);
  const observerTarget = useRef(null);

  const { isWhatsappTab, isWhatsappEnabled } = props;

  const handleMessages = (prevMessages, pageNo) => {
    const transformedMessages = prevMessages.map((msg) => ({
      message: msg.message,
      attachments: msg.attachmets,
      type: msg.senderId === currCommunityId ? "user" : "other",
      timestamp: timestamp(msg.timestamp),
    }));
    setMessages((prev) => ({
      ...prev,
      messages: [...transformedMessages, ...prev.messages],
    }));
    // props.updatedContent(room);
  };
  const updateMessageRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        updateMessageRef.current &&
        !updateMessageRef.current.contains(e.target)
      ) {
        setModal({
          open: false,
          type: "",
        });
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  function scrollToBottom() {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  function onSuccess(msg) {
    setShowToast(true);
    setToastMessage({
      type: "success",
      message: msg,
    });
  }

  const { getTeams } = useTeam();

  const {
    data: teamData,
    mutate: getLists,
    loading: isTeamLoading,
  } = useApiData({
    onSuccess: (response) => {},
    onError: () => onError("Something went wrong while getting Team"),
  });

  const { mutate: getMsgsMutate, loading } = useApiData({
    onSuccess: (res) => {
      const { messages = [], pageNo, isHavingNextPage } = res.data;

      setHasMore(isHavingNextPage);

      if (messages.length) {
        handleMessages(messages, pageNo);
      }

      props.handleLoading((prev) => ({
        ...prev,
        ["message"]: false,
      }));

      if (Number(pageNo) === 1) {
        setTimeout(() => {
          scrollToBottom();
        }, 500);
      }
    },
    onError: (err) => {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong while getting messages.",
      });

      props.handleLoading((prev) => ({
        ...prev,
        ["message"]: false,
      }));
    },
  });

  const getMsgs = useCallback(
    (pageNo) => {
      if (loading) return;

      props.handleLoading((prev) => ({
        ...prev,
        message: true,
      }));

      const url = `${socketURL}/chatbot/messages/${props.user_id}?pageNo=${pageNo}`;

      getMsgsMutate(() => api.get(url));
    },
    [getMsgsMutate, loading, props]
  );

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       const firstEntry = entries[0];
  //       if (firstEntry.isIntersecting) {
  //         getMsgs();
  //         console.log("calling===>");
  //       }
  //     },
  //     { threshold: 1 }
  //   );

  //   if (observerTarget.current) {
  //     observer.observe(observerTarget.current);
  //   }

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [observerTarget.current, getMsgs]);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        btnRef.current &&
        !btnRef.current.contains(e.target) &&
        e.target.closest(".assign-modal-chat") == null &&
        e.target.closest(".toggler") == null
      ) {
        setAssignModal(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setMessages({ messages: [] });

    setTimeout(() => {
      if (props.user_id && !isWhatsappTab) {
        getMsgs(1);
      }
    }, 500);
  }, [props.user_id]);

  useEffect(() => {
    if (socket != null) {
      var socketID = document.cookie.replace(
        /(?:(?:^|.*;\s*)chatSocketID-admin\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      socket.emit("join", props.user_id);

      // if (!loading) {
      //   getMsgs();
      // }

      // socket.on("getMessages", (prevMessages, room) => {
      //   props.handleLoading((prev) => ({
      //     ...prev,
      //     ["message"]: false,
      //   }));

      //   const transformedMessages = prevMessages.map((msg) => ({
      //     message: msg.message,
      //     attachments: msg.attachmets,
      //     type: msg.senderId === currCommunityId ? "user" : "other",
      //     timestamp: timestamp(msg.timestamp),
      //   }));
      //   setMessages({ messages: transformedMessages });
      //   props.updatedContent(room);
      // });

      socket.emit("setUserType", "admin", props.selectedChat, currCommunityId);

      socket.on("sendMessage", (msg) => {
        setProcessing(false);
        if (socketID == msg.senderId || props.user_id == msg.senderId) {
          const isSentByCurrentUser = msg.senderId === socketID;
          var status =
            props.userDetails?.messages &&
            props.userDetails?.messages[0]?.status == "resolved"
              ? "reopen"
              : "resolved";
          if (msg.status == "reopen") {
            if (status == "resolved") {
              var postData = {
                status: "reopen",
                client_id: props.user_id,
                community_id: currCommunityId,
                team_id: props.selectedChat,
              };
              api.post("/api/updateMessage", postData).then((response) => {
                if (response.data.status == "success") {
                  // console.log("response.data.data", props.currPage);
                  props.setClient(response.data.data);
                  // socket.emit(
                  //   "getUsers",
                  //   currCommunityId,
                  //   props.selectedChat,
                  //   props.userFilter,
                  //   props.currUser.hashId,
                  //   props.currPage
                  // );

                  props.getUsers();
                }
              });
            }
          }
          console.log("msg.message", msg);
          setMessages((prevState) => {
            const messages = Array.isArray(prevState.messages)
              ? [
                  ...prevState.messages,
                  {
                    message: msg.message,
                    attachments: msg.attachmets,
                    type: isSentByCurrentUser ? "user" : "other",
                    timestamp: timestamp(msg.timestamp),
                  },
                ]
              : [
                  {
                    message: msg.message,
                    attachments: msg.attachmets,
                    timestamp: timestamp(msg.timestamp),
                    type: isSentByCurrentUser ? "user" : "other",
                  },
                ];
            return {
              messages,
            };
          });
          props.updatedContent(props.user_id);
        }
      });

      socket.on("wt-message", (data) => {
        console.log("whatsapp msg", data);

        const { msg, client } = data;

        if (msg.client_id === props.user_id) {
          setWhatsappMsgs((prev) => [...prev, msg]);
          scrollToBottom();
        }

        if (client.community_id === currCommunityId) {
          props.getUsers(2);
        }
      });

      return () => {
        socket.off("sendMessage");
        socket.off("wt-message");
      };
    }
  }, [props.user_id]);

  const timestamp = (time) => {
    const date = new Date(time);
    const now = new Date();
    const diffInMs = now - date;
    const diffInHours = Math.floor(diffInMs / 3600000);
    const diffInDays = Math.floor(diffInHours / 24);

    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    time = date.toLocaleTimeString(undefined, options);

    let timeAgo = "";
    timeAgo = `${date.toLocaleDateString()} ${time}`;

    // if (diffInDays >= 1) {
    // } else {
    //   timeAgo = `${time}`;
    // }
    return timeAgo;
  };

  const handleSendMessage = (e, msg = null, statusChat = null) => {
    e.preventDefault();
    var status = "open";
    if (msg != null) {
      var message = msg;
      if (statusChat == 1) {
        status = "resolved";
      } else {
        status = "reopen";
      }
    } else {
      var message = newMessage;
    }
    // console.log(message);
    var userId = props.user_id;

    socket.emit("sendMessage", {
      recipient: userId,
      message: message,
      type: "user",
      senderId: currSocketId,
      status: status,
      notification: userId,
      file: attachments,
    });
    scrollToBottom();
    setNewMessage("");
    setAttachments([]);
    setProcessing(true);
    if (msg === "invitedToCall") {
      window.open(`https://meet.jit.si/${props.user_id}`, "_blank");
    }
  };

  const submitAssign = (id) => {
    setLoadingAssign(true);

    var postData = {
      client_id: props.user_id,
      status: "",
      handled_by: id,
      community_id: currCommunityId,
      team_id: props.userDetails?.team_id,
      assign: true,
    };
    console.log("check");
    api
      .post("/api/updateMessage", postData)
      .then((response) => {
        setLoadingAssign(false);
        if (response.data.status == "success") {
          // console.log("response.data.data", response.data.data);
          props.setClient(response.data.data);
        }
      })
      .catch((err) => {
        setLoadingAssign(false);
      });
  };

  const submiteamAssign = (id) => {
    var postData = {
      client_id: props.user_id,
      community_id: currCommunityId,
      team_id: id,
    };
    setLoadingAssign(true);

    api
      .post("/api/AssignChat", postData)
      .then((response) => {
        setLoadingAssign(false);
        if (response.data.status == "success") {
          props.getUsers();
          props.setClient(response.data.data);
        }
      })
      .catch((err) => {
        setLoadingAssign(false);
      });
  };

  const statusUpdate = (e, status) => {
    e.preventDefault();

    var postData = {
      status: status,
      client_id: props.user_id,
      community_id: currCommunityId,
      team_id: props.selectedChat,
      assign: true,
    };

    setProcessing(true);

    api
      .post("/api/updateMessage", postData)
      .then((response) => {
        if (response.data.status == "success") {
          console.log("response.data.data", props.currPage);
          props.setClient(response.data.data);
          setProcessing(false);

          if (status == "resolved") {
            var msg = "Issue Resolved";

            handleSendMessage(e, msg, 1);
          } else {
            var msg = "Issue Reopen";
            setProcessing(false);
          }
          // socket.emit(
          //   "getUsers",
          //   currCommunityId,
          //   props.selectedChat,
          //   props.userFilter,
          //   props.currUser.hashId,
          //   props.currPage
          // );
          props.queueRefresh();
          props.getUsers();
          socket.emit("emitCheckQueueStatus", response?.data?.client_id, 2);

          props.handleSelectedUser(null);
        }
      })
      .catch(() => {
        setProcessing(false);
      });
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            fileData,
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      console.log("attachmentFiles", attachmentFiles);
      setAttachments(attachmentFiles);
      setProcessing(false);
    } catch (error) {
      setShowToast(true);
      // console.log("errr", error);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const processImage = (imageFile) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      // setImageDataURL(event.target.result);

      const fileData = event.target.result;
      const base64Data = fileData.split(",")[1];
      const mimeType = fileData.match(/^data:(.*?);/)[1];
      const fileName = imageFile.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      setAttachments((prev) => [
        ...prev,
        {
          fileData,
          base64Data,
          fileName,
          fileExtension,
          mimeType,
        },
      ]);

      setNewMessage("");
    };
    reader.readAsDataURL(imageFile);
  };

  const onPaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const type = items[i].type;

      const isImage = type.indexOf("image") !== -1;

      const isPdf = type.indexOf("pdf") !== -1;

      const isSupportedDocs = MIME_TYPE_TO_EXTENSION_MAPPING[type];

      if (isImage || isSupportedDocs || isPdf) {
        const imageFile = items[i].getAsFile();
        processImage(imageFile);
      }
    }
  };

  const handleToggleModal = (type, open) => {
    setModal({
      type: type,
      open: open,
    });
  };

  useEffect(() => {
    const groupedMessages = messages.messages.reduce((groups, message) => {
      const date = message?.timestamp?.split(" ")[0];

      if (!groups[date]) {
        groups[date] = [];
      }

      groups[date].push(message);
      return groups;
    }, {});
    setGroupMessages(groupedMessages);
  }, [messages]);

  const formatTime = (time) => {
    const dateTimeString = time?.split(" ")[1];
    const format = time?.split(" ")[2];
    return dateTimeString + " " + format;
  };

  if (isWhatsappTab && !isWhatsappEnabled) {
    return "Your Whatsapp is not enabled.";
  }

  if (props.user_id) {
    return (
      <>
        <div
          id="chat-box-header"
          className="py-4 relative flex items-center justify-between rounded-t-md bg-secondary-600 text-white border-b p-4"
        >
          <div className="w-1/2">
            <div className="flex items-center">
              <div className="mr-2 flex bg-white text-black font-semibold justify-center items-center flex-shrink-0 h-10 w-10 rounded-full">
                {props.userDetails && props.userDetails.client_name
                  ? Array.from(props.userDetails.client_name)[0]
                  : "G"}
              </div>
              <div ref={btnRef} className="font-medium">
                <div className="truncate">
                  {props.userDetails && props.userDetails.client_name
                    ? props.userDetails.client_name
                    : "Guest -" + props.user_id}
                </div>

                {isWhatsappTab ? null : (
                  <div className="relative">
                    <span
                      className="text-sm cursor-pointer border-b"
                      onClick={() => setAssignModal(!assignModal)}
                    >
                      {props.userDetails?.handled_user
                        ? props.userDetails?.handled_user.username
                        : "Assign to"}
                    </span>
                    <div className="text-gray-700">
                      {assignModal && (
                        <AssignDropdown
                          userDetails={props.userDetails}
                          setClient={props.setClient}
                          user_id={props.user_id}
                          submitAssign={submitAssign}
                          submiteamAssign={submiteamAssign}
                          handled_by={props.userDetails?.handled_by}
                          team_id={props.userDetails?.team_id}
                          teamsData={props.teamsData}
                          loadingAssign={loadingAssign}
                          handlePopOpen={setPopOpen}
                          popOpen={popOpen}
                          setAssignModal={setAssignModal}
                          getTeam={() =>
                            getLists(
                              getTeams({
                                isUsers: true,
                                team_id: props.userDetails?.team_id,
                              })
                            )
                          }
                          teamData={teamData}
                          isTeamLoading={isTeamLoading}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            {isWhatsappTab ? null : (
              <div
                target="blank"
                onClick={(e) => {
                  handleSendMessage(e, "invitedToCall");
                }}
                className={`flex items-center justify-center hover:bg-gray-700 hover:text-white duration-300 ease cursor-pointer rounded-full text-sm`}
              >
                {/* <i className="fa-solid fa-phone"></i> */}

                <img src="/images/icons/headphone.svg" alt="meeting" />
              </div>
            )}

            <div
              className="px-4 chat-slider duration-300 ease cursor-pointer rounded-full text-sm"
              onClick={() => props.handleChatToggle(!props.chatToggle)}
            >
              <img src="/images/icons/dock-left.svg" alt="dock-left" />
            </div>

            {isWhatsappTab ? null : (
              <div className="relative" ref={updateMessageRef}>
                <button
                  className={`${
                    props.userDetails?.messages?.length > 0 &&
                    (props.userDetails?.messages[0]?.status === "resolved"
                      ? "bg-emerald-500 text-white"
                      : props.userDetails?.messages[0]?.status === "abandoned"
                      ? "bg-black text-white"
                      : props.userDetails?.messages[0]?.status === "reopen"
                      ? "bg-gray-500 text-white"
                      : "")
                  }
                     ml-2 text-sm inline-flex items-center justify-center button hover:bg-gray-600 hover:text-white border text-white radius-round h-8 px-4 gap-2 py-2 duration-300 ease-in capitalize`}
                  onClick={() =>
                    handleToggleModal("messageUpdate", !modal.open)
                  }
                >
                  {processing == true && (
                    <svg
                      className="animate-spin -ml-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {props.userDetails?.messages &&
                  props.userDetails?.messages[0]?.status
                    ? props.userDetails?.messages[0]?.status
                    : "Action"}
                  <i className="fa-solid fa-chevron-down"></i>
                </button>
                {modal?.type == "messageUpdate" && modal?.open && (
                  <div className="z-10 rounded absolute mt-3 bg-white shadow-md w-28 text-sm left-0">
                    <button
                      className="w-full border-b py-2 px-3 text-gray-700 cursor-pointer light-blue-hover hover:text-gray-700"
                      onClick={(e) =>
                        statusUpdate(
                          e,
                          props.userDetails?.messages &&
                            props.userDetails?.messages[0]?.status == "resolved"
                            ? "reopen"
                            : "resolved"
                        )
                      }
                    >
                      {props.userDetails?.messages?.length > 0 &&
                      props.userDetails?.messages[0]?.status === "resolved"
                        ? "Reopen"
                        : "Resolve"}
                    </button>
                    <button
                      className="w-full py-2 px-3 text-gray-700 light-blue-hover cursor-pointer hover:text-gray-700"
                      onClick={(e) => statusUpdate(e, "abandoned")}
                    >
                      Abandoned
                    </button>
                  </div>
                )}
                {/*                 
                <button
                  className={`${
                    props.userDetails?.messages?.length > 0 &&
                    props.userDetails?.messages[0]?.status === "resolved"
                      ? "bg-emerald-500 text-white hover:bg-emerald-600"
                      : ""
                  } text-sm inline-flex items-center justify-center button hover:bg-emerald-600 hover:text-white border radius-round h-8 px-6 py-2`}
                  onClick={(e) => statusUpdate(e)}
                >
                  {processing == true && (
                    <svg
                      className="animate-spin -ml-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {props.userDetails?.messages?.length > 0 &&
                  props.userDetails?.messages[0]?.status === "resolved"
                    ? "Reopen"
                    : "Resolve"}
                </button> */}
              </div>
            )}
          </div>
        </div>

        {props?.msgTab === "whatsapp" ? (
          <WhatsappChat
            {...props}
            socket={socket}
            messages={whatsappMsgs}
            setMessages={setWhatsappMsgs}
            setOpenAttachmentPreview={setOpenAttachmentPreview}
            messagesRef={messagesRef}
            scrollToBottom={scrollToBottom}
            page={page}
            setPage={setPage}
            hasMore={hasMore}
            setHasMore={setHasMore}
            getContentHeight={getContentHeight}
          />
        ) : (
          <>
            <div
              // className="flex flex-col custom-scroll min-h-[100px] p-4 overflow-auto overflow-x-hidden userchat-area h-[60vh]"
              className="overflow-auto p-4"
              style={{ height: getContentHeight() }}
              ref={messagesRef}
            >
              {props.loading?.message && page === 1 ? (
                <div className="flex items-center justify-center h-[50vh]">
                  <Spinner />
                </div>
              ) : (
                <>
                  <LoadMoreBtn
                    loading={loading}
                    hasMore={hasMore}
                    onLoad={() => {
                      setPage((prev) => prev + 1);
                      getMsgs(page + 1);
                    }}
                  />

                  {Object.entries(groupedMessages).map(([date, messages]) => (
                    <div key={date}>
                      <h2 className="mb-5 w-full relative flex items-center">
                        <div className=" bg-gradient-to-t from-transparent via-transparent to-[rgba(9,30,66,.12)] h-1 bg-repeat-x bg-bottom bg-no-repeat w-[40%]"></div>
                        <span className="text-sm font-bold px-3">{date}</span>
                        <div className="bg-gradient-to-t from-transparent via-transparent to-[rgba(9,30,66,.12)] h-1 bg-repeat-x bg-bottom bg-no-repeat w-[40%]"></div>
                      </h2>
                      {messages.map(
                        ({ message, type, attachments, timestamp }, index) => (
                          <>
                            {type == "user" ? (
                              <>
                                <div
                                  key={message.id}
                                  className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end"
                                >
                                  <div>
                                    {message && (
                                      <div className="bg-[#DAE8FF] px-3 py-2 text-grey p-3 rounded-l-lg rounded-br-lg">
                                        <p className="text-sm">
                                          {message === "invitedToCall"
                                            ? "You have initiated the call"
                                            : message}
                                        </p>
                                      </div>
                                    )}
                                    <div className="flex flex-col w-full mt-2 space-x-1 max-w-xs ml-auto items-end text-sm text-blue-500 underline">
                                      {attachments?.map((file, index) => (
                                        <div
                                          className="flex items-center justify-between three-dots bg-indigo-50  text-indigo-500 p-1"
                                          key={index}
                                        >
                                          <div
                                            className="cursor-pointer pl-2"
                                            onClick={() =>
                                              setOpenAttachmentPreview({
                                                link: file,
                                              })
                                            }
                                          >
                                            Attachment-{index + 1}
                                          </div>
                                          <a
                                            className="cursor-pointer"
                                            href={file}
                                            download="download"
                                            target="__blank"
                                          >
                                            <i class="fa-solid fa-download mr-2"></i>
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                    <i className="text-[13px] font-normal flex w-full max-w-xs ml-auto justify-end">
                                      {/* {moment(timestamp).format("hh:mm A")} */}
                                      {formatTime(timestamp)}
                                    </i>
                                  </div>

                                  <div className="flex justify-center items-center flex-shrink-0 h-10 w-10 rounded-full bg-gray-100">
                                    <i className="fa-solid fa-user bg-gray-100 p-2 rounded-full "></i>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  key={index}
                                  className="flex w-full mt-2 space-x-3 max-w-xs"
                                >
                                  <div className="flex justify-center items-center flex-shrink-0 h-10 w-10 rounded-full bg-[#F1F2F3] !font-semibold">
                                    {props.userDetails &&
                                    props.userDetails.client_name
                                      ? Array.from(
                                          props.userDetails.client_name
                                        )[0]
                                      : "G"}
                                  </div>
                                  <div>
                                    {message && (
                                      <div className="bg-[#F1F2F3] px-3 py-2 rounded-r-lg rounded-bl-lg">
                                        {message == "invitedToCallAgent" ? (
                                          <>
                                            <p className="text-sm">
                                              Invited To connect. please click
                                              answer to connect
                                            </p>
                                            <a
                                              href={`https://meet.jit.si/${props.user_id}`}
                                              target="blank"
                                              className="inline-flex items-center justify-center button bg-green-600 hover:bg-green-500 active:bg-green-700 text-white radius-round h-11 px-8 py-2 w-full"
                                            >
                                              Answer
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <p className="text-sm text-grey">
                                              {message}
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    )}
                                    <div className="flex flex-col w-full mt-2 space-x-1  ml-auto text-sm text-blue-500 underline">
                                      {attachments?.map((file, index) => (
                                        <div
                                          className="flex items-center justify-between three-dots bg-indigo-50  text-indigo-500 p-1"
                                          key={index}
                                        >
                                          <div
                                            className="cursor-pointer pl-2"
                                            onClick={() =>
                                              setOpenAttachmentPreview({
                                                link: file,
                                              })
                                            }
                                          >
                                            Attachment-{index + 1}
                                          </div>
                                          <a
                                            className="cursor-pointer"
                                            href={file}
                                            download="download"
                                            target="__blank"
                                          >
                                            <i class="fa-solid fa-download mr-2"></i>
                                          </a>
                                        </div>
                                      ))}
                                    </div>
                                    <i className="text-[13px] font-normal flex w-full ml-auto justify-start">
                                      {/* {moment(timestamp).format("hh:mm A")} */}
                                      {formatTime(timestamp)}
                                    </i>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>

            <div
              id="chat-box-footer"
              className="px-4 py-3 bg-gray-100 border-t border-gray-300 rounded-b-md flex flex-col justify-center items-center"
            >
              <form
                className="flex bg-white-300 items-center space-x-3 w-full"
                onSubmit={(e) => handleSendMessage(e)}
              >
                <input
                  className="input flex items-center border h-10 w-full rounded px-3 text-sm"
                  type="text"
                  placeholder="Type your message…"
                  value={newMessage}
                  required={attachments?.length <= 0}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                  }}
                  onPaste={onPaste}
                />
                <button
                  type="submit"
                  className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round px-8 py-2 text-[14px]"
                  disabled={processing}
                >
                  {processing == true ? (
                    <svg
                      className="animate-spin -ml-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Send"
                  )}
                </button>
                <div>
                  {/* <button
                      className="border ml-2 hover:bg-indigo-500 hover:text-white duration-300 ease px-4 py-2 rounded  z-10"
                      onClick={handleSelectButtonClick}
                      type="button"
                    >
                      <i className="fa-solid fa-paperclip"></i>
                    </button> */}
                  <img
                    src="/images/icons/attachment-btn.svg"
                    alt="attachment"
                    className="cursor-pointer h-12"
                    onClick={handleSelectButtonClick}
                  />
                  <input
                    type="file"
                    className="hidden"
                    ref={inputFileRef}
                    onChange={handleFileUpload}
                    multiple
                    disabled={processing}
                  />
                </div>
              </form>
              {attachments?.length > 0 && (
                <div className="w-full">
                  {/* {attachments.length} Files Selected */}
                  <AttachmentThumpnail
                    attachments={attachments}
                    onRemove={(index) => {
                      setAttachments((prev) => {
                        const remaining = prev.filter((p, i) => i !== index);

                        return remaining;
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <div className="userchat-empty py-4 px-4">
        Please select a user from left side
      </div>
    );
  }
};

export default UserChat;
