import React, { useContext, useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import { AppContext } from "../../App";
import Spinner from "../loaders/Spinner";
import LevelFieldsForm from "./LevelFieldsForm";
import { Tooltip } from "react-tooltip";

function validate(fields) {
  const err = {};
  let isError = false;

  Object.entries(fields).forEach(([key, value]) => {
    const { field_type, field_values } = value;

    const isHavingSubItem =
      field_type === "select" || field_type === "multiselect";

    if (isHavingSubItem && !field_values?.length) {
      err[key] = "Atleast one field is required.";
      isError = true;
    }
  });

  return { err, isError };
}

function handleChange(e, fieldNo, setFields, levelFieldsFormData) {
  const { name, value } = e.target;

  setFields((prev) => {
    let oldData = prev[fieldNo] || {};

    oldData[name] = value;

    if (name === "field_type") {
      oldData.field_name = "";

      delete levelFieldsFormData.current[fieldNo];
    }

    // if (name === "field_type" && value === "dependency_dropdown") {
    //   oldData.field_values = {
    //     choices: getDefaultLevelFields(),
    //   };
    // }

    return { ...prev, [fieldNo]: oldData };
  });
}

function onDependancyDropdownFieldNameChange(e, fieldNo, setFields) {
  const { name: levelNo, value } = e.target;

  setFields((prev) => {
    let oldData = prev[fieldNo] || {};

    const key = `level${levelNo}`;

    const oldFieldName =
      typeof oldData.field_name === "object" && oldData.field_name
        ? oldData.field_name
        : {};

    oldFieldName[key] = value;

    oldData.field_name = oldFieldName;

    return { ...prev, [fieldNo]: oldData };
  });
}

function handleRemove(
  fieldNo,
  fields,
  setFields,
  setFieldsCount,
  levelFieldsFormData
) {
  const oldFields = fields;

  delete oldFields[fieldNo]; // remove selected field

  const newFieldData = {};

  const newLevelFormData = {};

  // refactoring the balance fields
  Object.values(oldFields).forEach((data, i) => {
    const fieldNo = i + 1;
    newFieldData[fieldNo] = data;

    if (data.field_type === "dependency_dropdown") {
      newLevelFormData[fieldNo] = data.field_values;
    }
  });

  levelFieldsFormData.current = newLevelFormData;

  setFields(newFieldData);
  setFieldsCount((prev) => prev - 1);
}

function getFieldsValuesForPayload(
  field_type,
  field_values,
  levelFieldsFormData,
  fieldNo
) {
  let field_vals = field_values?.length ? field_values : "";

  if (field_type === "dependency_dropdown") {
    field_vals = levelFieldsFormData.current[fieldNo];
  }

  return field_vals;
}

function TicketAdditionalFieldsForm({ closeModal }) {
  const [fields, setFields] = useState({}); // store data in obj based on field number, i.e, for field 1, 1:{ data },for field 2, 2:{ data }
  const [fieldsCount, setFieldsCount] = useState(0);
  const [error, setError] = useState({});

  const loading = false;
  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);

  const levelFieldsFormData = useRef({});

  const {
    data: fieldsData,
    mutate: getTicketFieldsMutate,
    loading: isLoading,
  } = useApiData({
    onSuccess: (res) => {
      const { data = [] } = res.data || {};

      const refactorData = {};

      data.forEach((field, i) => {
        const fieldNo = i + 1;

        const { id, field_name, field_type, field_values, is_required } = field;

        refactorData[fieldNo] = {
          id,
          field_name,
          field_type,
          field_values,
          is_required,
        };

        if (field_type === "dependency_dropdown") {
          levelFieldsFormData.current = {
            ...levelFieldsFormData.current,
            [fieldNo]: field_values,
          };
        }
      });

      setFields(refactorData);
      setFieldsCount(refactorData ? Object.keys(refactorData).length : 0);
    },
    onError: (err) => console.log("err", err),
  });

  const getTicketFieldsData = () => {
    getTicketFieldsMutate(() =>
      api.get(`api/getTicketCustomFields/${currCommunityId}`)
    );
  };

  useEffect(() => {
    getTicketFieldsData();
  }, []);

  const { mutate: updateApi, loading: isUpdateLoading } = useApiData({
    onSuccess: (res) => {
      // closeModal();
      getTicketFieldsData();
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Data Updated Successfully",
      });
    },
    onError: (err) => {
      console.log("err", err);
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something Went Wrong",
      });
    },
  });

  const {
    data: deletedData,
    mutate: deleteApi,
    loading: isdeleteLoading,
  } = useApiData({
    onSuccess: (res) => {
      if (res.data.statusCode == "200") {
        getTicketFieldsData();

        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Data Deleted Successfully",
        });
      } else {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      }
    },
  });

  const formSubmit = (e) => {
    e.preventDefault();

    const { isError, err } = validate(fields);

    if (isError) {
      setError(err);
      return;
    } else {
      setError({});
    }

    const clientCustomFields = Object.values(fields).map(
      ({ id, field_name, field_type, field_values, is_required }, i) => ({
        id: id ? String(id) : "",
        field_name,
        field_type,
        is_required,
        field_values: getFieldsValuesForPayload(
          field_type,
          field_values,
          levelFieldsFormData,
          i + 1
        ),
      })
    );

    const payload = {
      community_id: currCommunityId,
      communityFields: clientCustomFields,
    };

    updateApi(() => api.post(`api/updateTicketCommunityFields`, payload));
  };

  const removeField = async (fieldNo, field) => {
    // if (field.id) {
    //   const payload = {
    //     customfield_id: [field.id],
    //   };
    //   console.log(payload);

    //   deleteApi(() =>
    //     api.post(
    //       `api/v1/deleteClientCustomFields/communityId/${currCommunityId}`,
    //       payload
    //     )
    //   );
    // } else {
    //   handleRemove(fieldNo, fields, setFields, setFieldsCount);
    // }

    handleRemove(
      fieldNo,
      fields,
      setFields,
      setFieldsCount,
      levelFieldsFormData
    );
  };
  // console.log(fields);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full overflow-hidden h-[30vh]">
          <Spinner />
        </div>
      ) : (
        <form
          className="mt-3"
          onSubmit={(e) => {
            formSubmit(e);
          }}
        >
          <div className="grid grid-cols-1 gap-2">
            {fieldsCount
              ? Array.from({ length: fieldsCount }).map((__, i) => {
                  const fieldNo = i + 1;
                  return (
                    <Form
                      key={i}
                      data={fields[fieldNo]}
                      onValueChange={(e) =>
                        handleChange(e, fieldNo, setFields, levelFieldsFormData)
                      }
                      onRemove={() => removeField(fieldNo, fields[fieldNo])}
                      error={error[fieldNo]}
                      isdeleteLoading={isdeleteLoading}
                      onDependancyDropdownFieldNameChange={(e) =>
                        onDependancyDropdownFieldNameChange(
                          e,
                          fieldNo,
                          setFields
                        )
                      }
                      levelFieldsFormData={levelFieldsFormData}
                      fieldNo={fieldNo}
                      fielfields={fields}
                      setFields={setFields}
                      fieldsData={fieldsData}
                    />
                  );
                })
              : null}
          </div>

          <FooterBtns
            onAdd={() => {
              setFields((prev) => ({ ...prev, [fieldsCount + 1]: {} }));
              setFieldsCount((prev) => prev + 1);
            }}
            fieldsCount={fieldsCount}
            loading={isUpdateLoading}
          />
        </form>
      )}
    </>
  );
}

export default TicketAdditionalFieldsForm;

function Form({
  data = {},
  onRemove,
  onValueChange,
  error,
  isdeleteLoading,
  onDependancyDropdownFieldNameChange,
  levelFieldsFormData,
  fieldNo,
  fields,
  setFields,
  fieldsData,
}) {
  const showSubItem =
    data.field_type === "select" || data.field_type === "multiselect";

  const showDependancyDropdown = data.field_type === "dependency_dropdown";
  return (
    <div className="mb-8 mt-3">
      <div className="flex flex-wrap justify-between items-center">
        <div className="w-[65%]">
          <label className="required-input">Field Type</label>
          <select
            className="mb-2 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            name="field_type"
            value={data.field_type}
            onChange={onValueChange}
            required
          >
            <option value="">Select Type</option>
            <option value="input">Input</option>
            <option value="select">Select</option>
            {/* <option value="multiselect">Multi Select</option> */}
            <option value="dependency_dropdown">Dependency Dropdown</option>
          </select>
        </div>
        <div className="w-[30%]">
          <label>Mandatory Type</label>
          <div className="border border-slate-300 rounded-md w-full mb-2">
            <div className="flex gap-4 justify-between items-center p-2 w-52">
              <label>
                {data.is_required == 1 ? "Mandatory" : "Non-Mandatory"}
              </label>

              <div>
                {data.is_required === 1 ? (
                  <img
                    src="/images/icons/toggle-on.svg"
                    alt="online"
                    onClick={() =>
                      onValueChange(
                        { target: { name: "is_required", value: 0 } },
                        fieldNo
                      )
                    }
                    className="cursor-pointer w-8 h-8"
                  />
                ) : (
                  <img
                    src="/images/icons/toggle-off.svg"
                    alt="offline"
                    onClick={() =>
                      onValueChange(
                        { target: { name: "is_required", value: 1 } },
                        fieldNo
                      )
                    }
                    className="cursor-pointer w-8 h-8"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDependancyDropdown ? null : (
        <div>
          <label className="required-input">Field Name</label>
          <input
            type="text"
            name="field_name"
            value={data.field_name}
            onChange={onValueChange}
            className="mb-2 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            required
          />
        </div>
      )}

      {showDependancyDropdown ? (
        <LevelFieldsForm
          onDependancyDropdownFieldNameChange={
            onDependancyDropdownFieldNameChange
          }
          fieldNames={data.field_name}
          levelFieldsFormData={levelFieldsFormData}
          fieldNo={fieldNo}
          fields={fields}
          setFields={setFields}
          fieldsData={fieldsData}
        />
      ) : null}

      {showSubItem && (
        <div>
          <label>Field values</label>
          <FieldValues
            defaultValue={data.field_values}
            onChange={(values) => {
              const value = values.map((v) => v.value);
              onValueChange({ target: { value, name: "field_values" } }, true);
            }}
          />

          {error ? <div className="form-error">{error}</div> : ""}
        </div>
      )}

      <button
        type="button"
        onClick={onRemove}
        className="flex items-center add-btn justify-end button bg-red-50 hover:bg-red-500 active:bg-red-700 text-red-500 hover:text-white radius-round h-8 px-3 py-2 mb-1"
        disabled={isdeleteLoading}
      >
        {isdeleteLoading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        Remove
        <i aria-hidden="true"></i>
      </button>
    </div>
  );
}

function FooterBtns({ loading, onAdd, fieldsCount }) {
  return (
    <div className="custom-buttons">
      <div>
        <button
          className="flex mt-3 duration-300 ease-in inline-flex items-center justify-center button bg-gray-200 light-blue-hover text-gray-700 radius-round h-11 px-8 py-2 w-full"
          onClick={onAdd}
          disabled={fieldsCount >= 5}
          data-tooltip-content={
            fieldsCount >= 5 ? "You can only add up to 5 fields" : ""
          }
          data-tooltip-id="disable-add-button"
          data-tooltip-place="top"
        >
          Add
        </button>
        <Tooltip id="disable-add-button" />
      </div>
      {/* {fieldsCount ? ( */}
      <button
        type="submit"
        className="flex save-btn justify-end mt-4 float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
        disabled={loading}
      >
        {loading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        Save
      </button>
      {/* ) : (
        ""
      )} */}
    </div>
  );
}

function FieldValues({ defaultValue, onChange }) {
  const defaultValueOptions = defaultValue
    ? defaultValue.map((value) => ({
        value,
        label: value,
      }))
    : [];
  return (
    <CreatableSelect
      className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 mb-2 "
      isMulti
      onChange={onChange}
      defaultValue={defaultValueOptions}
    />
  );
}
