import React from "react";

const TimeDisplay = ({ timestamp }) => {
  const currentTimestamp = new Date();
  const currentUTCTimestamp = new Date(
    currentTimestamp.getUTCFullYear(),
    currentTimestamp.getUTCMonth(),
    currentTimestamp.getUTCDate(),
    currentTimestamp.getUTCHours(),
    currentTimestamp.getUTCMinutes(),
    currentTimestamp.getUTCSeconds()
  );
  const targetTimestamp = new Date(timestamp);
  const diffInMinutes = Math.floor(
    (currentUTCTimestamp - targetTimestamp) / (1000 * 60)
  );

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedTime = targetTimestamp.toLocaleString("en-US", options);

  let timeAgo = "";

  if (diffInMinutes < 60) {
    timeAgo = `${diffInMinutes} minutes ago`;
  } else if (diffInMinutes < 1440) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    timeAgo = `${diffInHours} hours ago`;
  } else if (diffInMinutes < 43200) {
    const diffInDays = Math.floor(diffInMinutes / 1440);
    timeAgo = `${diffInDays} days ago`;
  } else if (diffInMinutes < 525600) {
    const diffInMonths = Math.floor(diffInMinutes / 43200);
    timeAgo = `${diffInMonths} months ago`;
  } else {
    const diffInYears = Math.floor(diffInMinutes / 525600);
    timeAgo = `${diffInYears} years ago`;
  }

  return (
    <div>
      {formattedTime} ({timeAgo})
    </div>
  );
};

export default TimeDisplay;
