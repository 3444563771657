import React from "react";

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  customCls = "",
  grayCls = "",
  containerCls = "",
}) => {
  if (!isOpen) {
    return;
  }

  return (
    <>
      <div
        className={`fixed bg-gray-800 opacity-50 w-screen h-screen top-0 left-0 ${grayCls}`}
        style={{ zIndex: 100 }}
      ></div>

      <div
        className={`fixed inset-0 z-50 flex items-center justify-center block ${containerCls}`}
        style={{ zIndex: 200 }}
      >
        <div
          className={`bg-white rounded-lg p-6 shadow-md min-w-[400px] md:max-w-[800px] max-w-fit max-h-[72vh] overflow-x-hidden overflow-y-auto ${customCls}`}
        >
          {title && (
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold text-gray-700">{title}</h3>

              <i
                className="fa-solid fa-xmark text-xl text-gray-700 cursor-pointer hover:text-gray-500"
                onClick={onClose}
              ></i>
            </div>
          )}

          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
