import { useContext, useState } from "react";
import { AppContext } from "../../App";

const SlaSettings = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setInviteModal,
    inviteModal,
    setHasChanged,
    handleLinkClick,
  } = useContext(AppContext);
  // const [processing, setProcessing] = useState(false);

  const handlePriority = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const [priority, property] = name.split(".");
    props.handlePriorityForm((prev) => ({
      ...prev,
      [priority]: {
        ...prev[priority],
        [property]: value,
      },
    }));
    setHasChanged(true);
  };

  return (
    <>
      {props.hideTitle ? null : (
        <div
          className="cursor-pointer light-blue-hover rounded duration-300 ease px-2  py-3 flex mt-5 items-center justify-between w-full block"
          onClick={() => props.handleTabClick(1)}
        >
          <h3 className="mb-4 text-[17px] lg:mb-0 text-gray-700 font-semibold">
            SLA Settings
          </h3>
          <div>
            <span>
              {props.openTabs.includes(1) ? (
                <i className="fa fa-minus"></i>
              ) : (
                <i className="fa fa-plus"></i>
              )}
            </span>
          </div>
        </div>
      )}

      {props.openTabs.includes(1) && (
        <div className="px-2  py-3">
          <div className="communityMembers">
            <form
              className=""
              onSubmit={(e) => props.formSubmit(e, "slaSettings")}
            >
              <div className="flex flex-wrap w-full">
                <div className="w-[50%] pb-3 pr-4 border-b border-r">
                  <label
                    className="text-sm font-semibold font-medium text-gray-700"
                    htmlFor="sla_type"
                  >
                    Priority 1
                  </label>
                  <div className="flex mt-2 gap-3">
                    <div>
                      <span>Days</span>
                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="days"
                        name="p1.days"
                        value={props.priorityForm.p1.days}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                    <div>
                      <span>Hours</span>
                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="hours"
                        name="p1.hour"
                        value={props.priorityForm.p1.hour}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-[50%] pb-3 pl-4 border-b border-l">
                  <label
                    className="text-sm font-semibold font-medium text-gray-700"
                    htmlFor="sla_type"
                  >
                    Priority 2
                  </label>
                  <div className="flex mt-2 gap-3">
                    <div>
                      <span>Days</span>
                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="days"
                        name="p2.days"
                        value={props.priorityForm.p2.days}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                    <div>
                      <span>Hours</span>

                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="hours"
                        name="p2.hour"
                        value={props.priorityForm.p2.hour}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-[50%] pt-3 pr-4 border-t border-r">
                  <label
                    className="text-sm font-semibold font-medium text-gray-700"
                    htmlFor="sla_type"
                  >
                    Priority 3
                  </label>
                  <div className="flex mt-2 gap-3">
                    <div>
                      <span>Days</span>
                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="days"
                        name="p3.days"
                        value={props.priorityForm.p3.days}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                    <div>
                      <span>Hours</span>
                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="hours"
                        name="p3.hour"
                        value={props.priorityForm.p3.hour}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-[50%] pt-3 pl-4 border-t border-l">
                  <label
                    className="text-sm font-semibold font-medium text-gray-700"
                    htmlFor="sla_type"
                  >
                    Priority 4
                  </label>
                  <div className="flex mt-2 gap-3">
                    <div>
                      <span>Days</span>
                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="days"
                        name="p4.days"
                        value={props.priorityForm.p4.days}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                    <div>
                      <span>Hours</span>
                      <input
                        type="text"
                        className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        placeholder="hours"
                        name="p4.hour"
                        value={props.priorityForm.p4.hour}
                        onChange={(e) => handlePriority(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-5">
                <span>
                  FRD <span>(First response due)</span>
                </span>
                {console.log("priorityForm", props?.priorityForm)}
                <input
                  type="text"
                  pattern="\d*"
                  maxlength="2"
                  className="p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                  placeholder="minutes"
                  name="fdr"
                  value={props.priorityForm.fdr}
                  onChange={(e) => {
                    props.handlePriorityForm((prev) => ({
                      ...prev,
                      ["fdr"]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="text-right">
                <button
                  type="submit"
                  className="flex justify-end mt-4 float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
                  disabled={props?.processing}
                >
                  {props?.processing && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SlaSettings;
