import { useEffect, useState } from "react";
import {
  DisplayObjectData,
  MAP_RESPONSE_TYPE,
  checkDataType,
} from "./DisplayResponseData";
import TestVariables from "./TestVariables";
import Button from "../../../../common/Button";
import Loading from "../../../../loaders/Loading";

function HandleApiResponse({
  usingParameters,
  updateParameters,
  removeParameters,
  remoteVariables,
  onRunTest,
  showRunTest,
  isRunningTest,
  data,
  testVariables,
  setTestVariables,
  apiError,
  apiForm,
  validateApiBtnText,
}) {
  const [tab, setTab] = useState("response");

  return (
    <div className="border border-gray-300">
      <Header
        onTabChange={(tab) => setTab(tab)}
        activeTab={tab}
        remoteVariables={remoteVariables}
        onRunTest={onRunTest}
        showRunTest={showRunTest}
        isRunningTest={isRunningTest}
        apiForm={apiForm}
        validateApiBtnText={validateApiBtnText}
      />

      {tab === "response" ? (
        <>
          <div className="p-4">Body</div>

          <div className="relative h-72 overflow-x-scroll mx-4 bg-gray-700">
            <pre
              className="p-8 rounded-md text-white"
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxWidth: "400px",
              }}
            >
              {apiError ? (
                apiError
              ) : (
                <>
                  {data ? (
                    <>
                      {Object.entries(data).map(([key, value], index) => {
                        const Comp = MAP_RESPONSE_TYPE[checkDataType(value)];

                        if (!Comp) {
                          return "Unknown";
                        }

                        return (
                          <div key={index} className="my-3">
                            <Comp
                              dataKey={key}
                              value={value}
                              parentKey={key}
                              usingParameters={usingParameters}
                              onUpdateKey={(key, add) => {
                                if (add) {
                                  updateParameters(key);
                                } else {
                                  removeParameters(key);
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    "No response data"
                  )}
                </>
              )}
            </pre>
          </div>
        </>
      ) : null}

      {tab === "testVariables" ? (
        <TestVariables
          remoteVariables={remoteVariables}
          testVariables={testVariables}
          setTestVariables={setTestVariables}
          apiForm={apiForm}
        />
      ) : null}
    </div>
  );
}

export default HandleApiResponse;

const defaultTabs = [
  {
    label: "Response",
    value: "response",
  },
];

function Header({
  onTabChange,
  activeTab,
  remoteVariables,
  showRunTest,
  onRunTest,
  isRunningTest,
  apiForm,
  validateApiBtnText,
}) {
  const [tabs, setTabs] = useState(defaultTabs);

  useEffect(() => {
    if (remoteVariables?.length || apiForm?.length) {
      setTabs([
        {
          label: "Test variables",
          value: "testVariables",
        },
        ...defaultTabs,
      ]);

      onTabChange("testVariables");
    } else {
      setTabs(defaultTabs);
    }
  }, [remoteVariables?.length, apiForm?.length]);

  return (
    <div className="px-4 flex justify-between items-center border-b border-gray-300 bg-gray-100">
      <div className="space-x-8">
        {tabs.map((tab, i) => {
          const isActive = activeTab === tab.value;

          return (
            <Button
              className={`${
                isActive ? "border-b-2 border-indigo-600" : ""
              } py-3 px-1`}
              onClick={() => onTabChange(tab.value)}
              key={i}
            >
              {tab.label}
            </Button>
          );
        })}
      </div>

      <button
        className={`px-4 py-2 rounded-md bg-gray-50 border border-gray-700 text-gray-700 text-sm flex items-center space-x-2 ${
          showRunTest ? "" : "opacity-50 cursor-not-allowed"
        }`}
        // onClick={onRunTest}
        disabled={!showRunTest || isRunningTest}
        type="submit"
      >
        {isRunningTest && <Loading />}
        {/* Run Test */}
        {validateApiBtnText}
      </button>
    </div>
  );
}
