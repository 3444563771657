import React, { useState } from "react";

function MessageOptionsSidebar({ msgTab, onTabChange, loading }) {
  return (
    <div className="flex justify-center relative">
      <div className="">
        <button
          className={`bg-indigo-600 p-2.5 rounded-md mt-4 outline-none ${
            msgTab === "chat" ? "ring-2 ring-offset-2 ring-blue-400" : ""
          } ${loading ? "cursor-not-allowed opacity-70" : ""}`}
          onClick={() => onTabChange("chat")}
          disabled={loading}
        >
          {/* <i class="fa-solid fa-comments text-2xl text-white"></i> */}
          <img src="/images/icons/message.svg" alt="message" />
        </button>
        <br />

        <button
          className={`bg-green-600 p-2.5 rounded-md mt-4 outline-none hover:bg-green-500 ${
            msgTab === "whatsapp" ? "ring-2 ring-offset-2 ring-green-400" : ""
          } ${loading ? "cursor-not-allowed opacity-70" : ""}`}
          onClick={() => onTabChange("whatsapp")}
          disabled={loading}
        >
          {/* <i class="fa-brands fa-whatsapp text-3xl text-white ml-0.5"></i> */}
          <img src="/images/icons/whatsapp.svg" alt="whatsapp" />
        </button>
      </div>
    </div>
  );
}

export default MessageOptionsSidebar;
