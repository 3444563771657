import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import ClientDetails from "../../components/tickets/ClientDetails";
import CustomField from "../../components/tickets/CustomFields";
import TicketAttachments from "../../components/tickets/TicketAttachments";
import TicketHistory from "../../components/tickets/TicketHistory";
import RelatedTickets from "../../components/tickets/RelatedTickets";
import TicketAssign from "../../components/tickets/TicketAssign";
import TicketClockWork from "../../components/tickets/TicketClockWork.js";
import TicketComments from "../../components/tickets/TicketComments";
import TicketInfo from "../../components/tickets/TicketInfo";
import Spinner from "../../components/loaders/Spinner";
import useApiData from "../../hooks/useApiData";
import TicketApprovalStatus from "../../components/tickets/TicketApprovalStatus";
import ChildTickets from "../../components/tickets/ChildTickets";
import usePortals from "../settings/portals/usePortals.js";
import TicketContent from "../../components/tickets/TicketContent.js";
import Loading from "../../components/loaders/Loading.js";
import useTicket from "../../components/tickets/useTicket.js";
import AssignTeams from "../../components/tickets/AssignTeams.js";
import useTicketTeamAssign from "../../components/tickets/useTicketTeamAssign.js";
import Scheduler from "../../components/tickets/Scheduler.js";

function getClientDetails(data = {}) {
  const {
    client_name,
    client_phone_no,
    client_email,
    client_custom_fields = [],
    recipients,
  } = data;

  const refactor = {
    name: client_name,
    phoneNo: client_phone_no,
    email: client_email,
    customFieldValues: client_custom_fields?.map((c) => ({
      customfield_values: c?.customfield_values,
      customfield_id: c?.customfield_id,
    })),
    recipients,
  };

  return refactor;
}

const TicketDetail = () => {
  // const { quill, quillRef } = useQuill();

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    handleLinkClick,
    hasChanged,
    setHasChanged,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [ticketDetail, setTicketDetail] = useState();
  const [clientDetails, setClientDetails] = useState({});
  const [description, setDescription] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [priority, setPriority] = useState(null);
  const [priorityModal, setPriorityModal] = useState(false);
  const [portalModal, setPortalModal] = useState(false);
  const { id } = useParams();
  const [ticketName, setTicketName] = useState("");
  const dropdownRef = useRef(null);
  // const iframeRef = useRef();
  // const [distributorRemove, setDistributorRemove] = useState(false);
  const [toggleHistory, setToggleHistory] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // setOpenDropdownId(null);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => document.body.removeEventListener("click", handleClickOutside);
  }, []);
  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }
  const { getPortals } = usePortals();

  const {
    data: portalData,
    mutate: getPortalsData,
    loading: isPortalsLoading,
  } = useApiData({
    onSuccess: (response) => {},
    onError: () => onError("Something went wrong while getting Teams"),
  });

  const { getTicketClockWork, getTicketComments, getTicketHistory } =
    useTicket();

  const {
    data: clockWorkData,
    mutate: getClockWorkMutate,
    loading: isGettingClockWork,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => onError("Something went wrong while getting clockwork"),
  });

  function getClockWork() {
    getClockWorkMutate(getTicketClockWork(id));
  }

  const {
    data: commentsData,
    mutate: getCommentsMutate,
    loading: isGettingComments,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => onError("Something went wrong while getting comments"),
  });

  function getComments() {
    getCommentsMutate(getTicketComments(id));
  }

  const {
    data: historyData,
    mutate: getHistoryMutate,
    loading: isGettingHistory,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => onError("Something went wrong while getting history"),
  });

  function getHistory() {
    getHistoryMutate(getTicketHistory(id));
  }

  const { getTicketAssignees } = useTicketTeamAssign();

  const {
    data: teamAssigneesData,
    mutate: getTeamAssigneesMutate,
    loading: isGettingTeamAssignees,
  } = useApiData({
    onSuccess: (res) => {
      getHistory();
    },
    onError: () => onError("Something went wrong while getting team assignees"),
  });

  function getTeamAssignees() {
    getTeamAssigneesMutate(getTicketAssignees(id));
  }

  useEffect(() => {
    getUserInfo();
    getPortalsData(getPortals());
    getTicketDetails();
    getClockWork();
    getComments();
    getHistory();
    getTeamAssignees();
  }, []);

  // useEffect(() => {
  //   if (quill) {
  //     // getTicketDetails();
  //     // quill.on("text-change", (delta, oldDelta, source) => {
  //     //   setDescription(quill.root.innerHTML);
  //     // });
  //   }
  // }, [currUser, quill]);

  // useEffect(() => {
  //   const iframe = iframeRef.current;

  //   const adjustIframeHeight = () => {
  //     iframe.style.height =
  //       iframe.contentDocument.documentElement.scrollHeight + "px";
  //   };

  //   if (iframe) {
  //     iframe.onload = adjustIframeHeight;
  //   }

  //   return () => {
  //     if (iframe) {
  //       iframe.onload = null; // Cleanup the event listener when the component unmounts
  //     }
  //   };
  // }, [ticketDetail]);

  const getTicketDetails = () => {
    var payload = {
      community_id: currCommunityId,
      ticket_id: id,
    };
    setLoading(true);
    api
      .post("/api/getTicket", payload)
      .then((response) => {
        setLoading(false);
        // console.log(response);
        if (response.data.data == null) {
          setNotFound(true);
        }
        if (response.data.status == "success") {
          setTicketDetail(response.data.data);
          setClientDetails(getClientDetails(response.data.data));
          // quill.clipboard.dangerouslyPasteHTML(
          //   response.data.data?.ticketContent
          // );
          setDescription(response.data.data?.ticketContent);
          setStatus(response.data.data?.status);
          setPriority(response.data.data?.priority);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotFound(true);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  useEffect(() => {
    if (ticketName) {
      updateTicket("name");
    }
  }, [ticketName]);

  const divRef = useRef(null);

  const getTextInsideDiv = () => {
    if (divRef.current) {
      const text = divRef.current.textContent;
      if (text) {
        setTicketName(text);
      } else {
        setTicketName(ticketDetail.topic);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Ticket title needed",
        });
      }
    }
  };

  const updateTicket = (part, value = null) => {
    var priorityValue = priority;
    var statusValue = status;
    var portalId = ticketDetail.portal_id;

    if (part == "priority") {
      if (value != null) {
        priorityValue = value;
      }
    }

    if (part == "status") {
      if (value != null) {
        statusValue = value;
      }
    }

    if (part == "portal_id") {
      if (value != null) {
        portalId = value;
      }
    }

    var payload = {
      community_id: currCommunityId,
      id: id,
      content: description,
      status: statusValue,
      part: part,
      name: ticketName ? ticketName : ticketDetail.topic,
      priority: priorityValue,
      portal_id: portalId,
    };
    setProcessing(part || true);

    api
      .post("/api/updateTicket", payload)
      .then((response) => {
        // console.log(response);
        setProcessing(false);

        if (response.data.status == "success") {
          setHasChanged(false);
          setTicketDetail(response.data.data);
          // quill.clipboard.dangerouslyPasteHTML(
          //   response.data.data?.ticketContent
          // );
          console.log("part====>", part, value);
          if (part === "status" && value === "completed") {
            getClockWork();
          }

          if (part == "desc") {
            setEditModal(false);
          } else if (part == "status") {
            setStatus(response.data.data?.status);
            setStatusModal(false);
            setShowToast(true);
            setToastMessage({
              type: "success",
              message: "Status Updated Successfully",
            });

            getHistory();
          } else if (part == "priority") {
            setPriority(response.data.data?.priority);
            setPriorityModal(false);
            setPortalModal(false);
            setShowToast(true);
            setToastMessage({
              type: "success",
              message: "Priority Updated Successfully",
            });
          } else if (part == "portal_id") {
            setPortalModal(false);
            setShowToast(true);
            setToastMessage({
              type: "success",
              message: "Portal Updated Successfully",
            });
          }
        }
      })
      .catch((error) => {
        const msg = error?.response?.data?.msg;

        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: msg || "Something Went Wrong",
        });
      });
  };

  const { mutate: approveTicket, loading: isApproveLoading } = useApiData({
    onSuccess: (res) => {
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Ticket Approved Successfully",
      });
      getTicketDetails();
      getHistory();
    },
    onError: (error) => {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something Went Wrong",
      });
    },
  });

  function approveTicketApi() {
    approveTicket(() =>
      api.post(
        `api/approveRequest/${currCommunityId}/ticket/${ticketDetail.ticket_id}`
      )
    );
  }

  // useEffect(() => {
  //   // console.log("distributorRemove", distributorRemove);
  // }, [distributorRemove]);

  const hideTicketApprovalStatus =
    ticketDetail?.approvalStatus &&
    !ticketDetail?.approvalStatus?.status &&
    !ticketDetail?.approvalStatus?.approvers;

  console.log("ticketDetail", ticketDetail);

  const {
    ticket_id,
    topic,
    external_created_by,
    client_name,
    created_by,
    ticketContent,
  } = ticketDetail || {};

  if (loading) {
    return (
      <div className="text-center flex justify-center items-center h-[80vh] text-gray-500 text-xl">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {notFound ? (
        <div className="text-center flex justify-center items-center h-[80vh] text-gray-500 text-xl">
          <i className="fa-solid fa-triangle-exclamation mr-2"></i>Ticket not
          found
        </div>
      ) : (
        <div className="p-8 px-2">
          <div>
            <span
              className="hover:border-b border-gray-700 cursor-pointer"
              onClick={() => navigate("/tickets")}
            >
              Tickets
            </span>
            <span className=""> / Ticket {ticket_id}</span>
          </div>

          <div className="md:flex w-full justify-between md:space-x-8 mt-6">
            <div className="md:w-[70%] w-full h-fit border border-gray-300 rounded-md bg-white p-6">
              <div className="border-b border-gray-300 pb-6 flex justify-between items-center">
                <div className="w-full">
                  {processing === "name" ? (
                    <Loading />
                  ) : (
                    <div
                      className="text-2xl font-semibold hover:bg-gray-100 outline-none focus:ring-2 ring-indigo-400 w-full"
                      contentEditable
                      onBlur={getTextInsideDiv}
                      ref={divRef}
                    >
                      {topic}
                    </div>
                  )}

                  <div className="mt-2 font-regular">
                    #{ticket_id} created by{" "}
                    {external_created_by === 1 ? client_name : created_by}
                  </div>
                </div>
              </div>

              <div className="my-6">
                <TicketContent
                  ticketContent={ticketContent}
                  isUpdating={processing === "desc"}
                  updateTicket={() => updateTicket("desc")}
                  description={description}
                  setDescription={setDescription}
                />
              </div>

              <TicketAttachments
                id={id}
                handleTicketDetail={setTicketDetail}
                ticketDetail={ticketDetail}
                // handleDistributorRemove={setDistributorRemove}
                // distributorRemove={distributorRemove}
              />

              <TicketClockWork
                handleTicketDetail={setTicketDetail}
                ticketDetail={ticketDetail}
                loading={isGettingClockWork}
                getClockWork={getClockWork}
                clockWork={clockWorkData?.data || []}
              />

              <TicketComments
                handleTicketDetail={setTicketDetail}
                ticketDetail={ticketDetail}
                isGettingComments={isGettingComments}
                getComments={getComments}
                comments={commentsData?.data || []}
              />
            </div>
            <div className="md:w-[30%] w-full">
              <TicketInfo
                getTicketDetails={getTicketDetails}
                ticketDetail={ticketDetail}
                statusModal={statusModal}
                status={status}
                handleStatusModal={setStatusModal}
                updateTicket={updateTicket}
                priority={priority}
                handlePriorityModal={setPriorityModal}
                priorityModal={priorityModal}
                handlePortalModal={setPortalModal}
                portalModal={portalModal}
                processing={processing}
                approveTicketApi={approveTicketApi}
                isApproveLoading={isApproveLoading}
                portalData={portalData}
                setTicketDetail={setTicketDetail}
              />

              {hideTicketApprovalStatus ? null : (
                <TicketApprovalStatus
                  approvedBy={ticketDetail?.approvalStatus?.approvered_by || {}}
                />
              )}

              <AssignTeams
                teamAssignees={teamAssigneesData?.data || []}
                loading={isGettingTeamAssignees}
                getTeamAssignees={getTeamAssignees}
                ticketId={id}
                ticketDetail={ticketDetail}
              />

              {/* <TicketAssign
                ticketDetail={ticketDetail}
                handleTicketDetail={setTicketDetail}
                getTicketDetails={getTicketDetails}
              /> */}

              <ClientDetails
                getTicketDetails={getTicketDetails}
                ticketDetail={ticketDetail}
                clientDetails={clientDetails}
                setClientDetails={setClientDetails}
              />

              <Scheduler
                getTicketDetails={getTicketDetails}
                ticketDetail={ticketDetail}
                clientDetails={clientDetails}
                setClientDetails={setClientDetails}
              />

              {/* <CustomField
                getTicketDetails={getTicketDetails}
                ticketDetail={ticketDetail}
                handleTicketDetail={setTicketDetail}
              /> */}

              <TicketHistory
                ticketDetail={ticketDetail}
                handletoggleHistory={setToggleHistory}
                toggleHistory={toggleHistory}
                loading={isGettingHistory}
                getHistory={getHistory}
                history={historyData?.data || []}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketDetail;
