import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../../App";
import SideBar from "../../../components/settings/SideBar";
import WhatsappIntergration from "../../../components/settings/WhatsappIntegration";
import MailIntergration from "../../../components/intergration//mailIntegration/MailIntegration";

const Integrations = () => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            <div className="flex gap-4 text-gray-700">
              <SideBar />

              <div className="w-[80%] h-[300px] mt-5">
                <div className="text-2xl font-semibold pb-4 h-auto">
                  Integrations
                </div>

                <div className="grid grid-cols-3 gap-8 w-fit">
                  <MailIntergration />
                  <WhatsappIntergration />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
