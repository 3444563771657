import moment from "moment";
import { useContext, useEffect, useState } from "react";
import useApiData from "../../hooks/useApiData";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import Modal from "../common/Modal";
import SendMailForm from "./mail/SendMailForm";
import Checkbox from "../common/Checkbox";
import useDropDown from "../../hooks/useDropDown";

function removeDuplicateClients(users) {
  const uniqueUsers = users?.filter((obj, index, self) => {
    return index === self.findIndex((el) => el.client_id === obj.client_id);
  });

  return uniqueUsers;
}

function getReadableDate(date) {
  if (!date) {
    return "";
  }

  return moment(date).format("MMMM DD, YYYY");
}

function CmTable({
  selectedUsers,
  setSelectedUsers,
  onDelete,
  onEdit,
  onMail,
  sortTicket,
  data,
  customFields,
}) {
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [columns, setColumns] = useState();
  console.log("selectedUsers", selectedUsers);

  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);
  useEffect(() => {
    if (data?.meta?.prev_page_url != null) {
      var url = new URL(data?.meta?.prev_page_url);
      var searchParams = new URLSearchParams(url.search);
      var page = searchParams.get("page");
      setPrevPage(page);
    } else {
      setPrevPage("");
    }

    if (data?.meta?.next_page_url != null) {
      var url = new URL(data?.meta?.next_page_url);
      var searchParams = new URLSearchParams(url.search);
      var page = searchParams.get("page");
      setNextPage(page);
    } else {
      setNextPage("");
    }
  }, [data]);

  const handlePageChange = (pageNumber) => {
    sortTicket({
      page: pageNumber,
    });
  };
  useEffect(() => {
    var initialColumns = [
      {
        label: (
          <DisplayUserLabel
            allUsers={data?.data || []}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        ),
        displayData: (data) => (
          <DisplayUser
            data={data}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        ),
      },
      { label: "Email", displayData: (data) => data.client_email },
      { label: "Phone", displayData: (data) => data.client_phone_no },
      {
        label: "Last contacted on",
        displayData: (data) => getReadableDate(data.created_at),
      },
      // {
      //   label: "Actions",
      //   displayData: (data) => (
      //     <CMActions
      //       data={data}
      //       onMail={onMail}
      //       onEdit={onEdit}
      //       onDelete={onDelete}
      //       selectedUsers={selectedUsers}
      //     />
      //   ),
      // },
    ];

    const actions = {
      label: "Actions",
      displayData: (data) => (
        <CMActions
          data={data}
          onMail={onMail}
          onEdit={onEdit}
          onDelete={onDelete}
          selectedUsers={selectedUsers}
        />
      ),
    };

    if (customFields?.data?.length > 0) {
      const newColumns = customFields.data?.map((data) => ({
        label: data.field_name,
        displayData: (row) => row[data.field_name],
      }));

      const mergedColumns = [...initialColumns, ...newColumns, actions];
      setColumns(mergedColumns);
    } else {
      const cols = [...initialColumns, actions];
      setColumns(cols);
    }
  }, [customFields, selectedUsers, data]);

  return (
    <div className="mt-8">
      <table className="table-auto w-full text-sm text-left text-gray-500 overflow-x-scroll border border-gray-300">
        <thead className="text-gray-700 uppercase bg-[#E3E9F3] issue-list-title">
          <tr className="border-b border-gray-300">
            {columns?.map((col, i) => {
              return (
                <th key={i} className="py-4 cursor-pointer px-2">
                  {col.label}
                </th>
              );
            })}

            {/* <th className="py-3 cursor-pointer px-2 text-center">Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {data
            ? data.data.map((userData, i) => {
                const isSelected = selectedUsers.includes(
                  userData?.client_email
                );

                return (
                  <tr
                    key={i}
                    className={`border-b border-gray-300 ${
                      isSelected ? "bg-blue-100" : "hover:bg-gray-100"
                    }`}
                  >
                    {columns?.map((col, i) => {
                      return (
                        <td key={i} className="py-4 px-2">
                          {col.displayData(userData)}
                        </td>
                      );
                    })}

                    {/* <td className="py-4 px-2 text-center">
                      <i className="fa-solid fa-envelope mr-4 cursor-pointer hover:text-indigo-600"></i>

                      <i
                        className="fa-solid fa-pen-to-square mr-4 cursor-pointer hover:text-blue-600"
                        onClick={() => onEdit(userData?.client_id)}
                      ></i>
                      <i
                        className="fa-solid fa-trash cursor-pointer hover:text-red-600"
                        onClick={() => onDelete(userData?.client_id)}
                      ></i>
                    </td> */}
                  </tr>
                );
              })
            : "No data Found"}
        </tbody>
      </table>
      {data != null && (
        <div className="flex items-center justify-end py-3">
          <div className="pagination">
            <div className="pagination-total">
              Total <span>{data.meta.total_records}</span> Items
            </div>

            <span
              className={`pagination-pager pagination-pager-next ${
                !prevPage ? "pagination-pager-inactive" : ""
              }`}
              onClick={() => {
                if (prevPage) {
                  handlePageChange(prevPage);
                }
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 20 20"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <ul>
              {Array.from({ length: data.meta.last_page }, (v, i) => i + 1).map(
                (pageNumber) => {
                  if (
                    pageNumber === 1 ||
                    pageNumber === data.meta.last_page ||
                    Math.abs(pageNumber - data.meta.current_page) <= 1
                  ) {
                    return (
                      <li
                        className={`pagination-pager ${
                          data.meta.current_page == pageNumber
                            ? "text-indigo-600 bg-indigo-50"
                            : ""
                        } hover:bg-indigo-50 `}
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </li>
                    );
                  } else if (
                    pageNumber === data.meta.current_page - 2 ||
                    pageNumber === data.meta.current_page + 2
                  ) {
                    return (
                      <li className="pagination-pager pagination-pager-inactive">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={0}
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                          />
                        </svg>
                      </li>
                    );
                  }
                }
              )}
            </ul>
            <span
              className={`pagination-pager pagination-pager-next ${
                !nextPage ? "pagination-pager-inactive" : ""
              }`}
              onClick={() => {
                if (nextPage) {
                  handlePageChange(nextPage);
                }
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 20 20"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default CmTable;

function CMActions({ data, onMail, onEdit, onDelete, selectedUsers }) {
  const [open, setOpen] = useState(false);

  const { elementRef } = useDropDown({ open, setOpen });
  return (
    <div ref={elementRef} className="relative">
      <button
        onClick={() => setOpen(!open)}
        className="ml-3 hover:bg-gray-200 p-2 py-4 rounded-md"
      >
        <img src="/images/icons/more.svg" alt="more" />
      </button>

      {open ? (
        <div className="absolute top-8 right-0 rounded-md bg-white shadow-xl border text-sm text-black py-4 z-[50] min-w-[160px]">
          <div className="font-semibold text-md pb-2 px-4">ACTIONS</div>

          {/* {selectedUsers?.length ? null : (
            <button
              className="px-4 py-2 hover:bg-gray-100 w-full text-left rounded-md"
              onClick={() => onMail(data?.client_email)}
            >
              Email
            </button>
          )} */}

          <button
            className="px-4 py-2 hover:bg-gray-100 w-full text-left rounded-md"
            onClick={() => onEdit(data?.client_id)}
          >
            Edit client details
          </button>

          <button
            className="px-4 py-2 hover:bg-gray-100 w-full text-left rounded-md"
            onClick={() => onDelete(data?.client_id)}
          >
            Delete client
          </button>

          {/* {selectedUsers?.length ? null : (
            <i
              className={`fa-solid fa-envelope mr-4 cursor-pointer hover:text-indigo-600 ${
                data?.client_email ? "" : "invisible"
              }`}
              onClick={() => onMail(data?.client_email)}
            ></i>
          )}

          <i
            className="fa-solid fa-pen-to-square mr-4 cursor-pointer hover:text-blue-600"
            onClick={() => onEdit(data?.client_id)}
          ></i>

          <i
            className="fa-solid fa-trash cursor-pointer hover:text-red-600"
            onClick={() => onDelete(data?.client_id)}
          ></i> */}
        </div>
      ) : null}
    </div>
  );
}

function isAllUsersChecked(allUsers, selectedUsers) {
  let isChecked = true;
  allUsers.forEach((a) => {
    if (!selectedUsers?.find((s) => s.client_id === a.client_id)) {
      // if any user is not checked
      isChecked = false;
    }
  });

  return isChecked;
}

function DisplayUserLabel({ allUsers, selectedUsers, setSelectedUsers }) {
  return (
    <Checkbox
      id="online-users"
      label={"Name"}
      showCheckbox={true}
      onChange={(e) => {
        const { checked } = e.target;

        setSelectedUsers((prev) => {
          let newUsers = [...prev];

          const all = [];

          allUsers?.forEach((a) => {
            if (a) {
              all.push(a);
            }
          });

          if (checked) {
            newUsers = [...newUsers, ...all];
          } else {
            newUsers = prev.filter((p) => {
              const client = allUsers.find((a) => a.client_id === p.client_id);

              return client ? false : true;
            });
          }

          return removeDuplicateClients(newUsers);
        });
      }}
      checked={isAllUsersChecked(allUsers, selectedUsers)}
    />
  );
}

function DisplayUser({ data, selectedUsers, setSelectedUsers }) {
  return (
    <Checkbox
      id={data?.client_id}
      label={
        <div className="text-blue-600 font-semibold">{data.client_name}</div>
      }
      showCheckbox={true}
      onChange={(e) => {
        const { checked } = e.target;

        setSelectedUsers((prev) => {
          let newUsers = [...prev];

          if (checked) {
            newUsers.push(data);
          } else {
            newUsers = prev.filter((p) => p?.client_id !== data?.client_id);
          }

          return removeDuplicateClients(newUsers);
        });
      }}
      checked={selectedUsers.some((s) => s.client_id === data.client_id)}
    />
  );
}
