import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";

const ModuleCard = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [processing, setProcessing] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const btnRef = useRef();
  const [gpt_key, setgpt_key] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    type: "",
    message: "",
  });
  const [gpt_keyError, setgpt_keyError] = useState("");

  const location = useNavigate();

  useEffect(() => {
    setTitle(props.module.name);
    setDescription(props.module.description);
    setgpt_key(props.module.gpt_key);

    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const deleteFolder = (e) => {
    e.preventDefault();
    setProcessing(true);
    var postData = {
      id: folderId,
    };
    api
      .post("/api/deleteModule", postData)
      .then((response) => {
        props.getAllFolders();
        setDeleteModal(false);
        setProcessing(false);
        props.setShowToast(true);
        props.setToastMessage({
          type: "success",
          message: "Module Deleted Successfully",
        });
      })
      .catch((error) => {
        setProcessing(false);
        props.setShowToast(true);
        props.setToastMessage({
          type: "success",
          message: "Something Went Wrong",
        });
      });
  };
  const redirectFun = (e) => {
    if (btnRef.current && !btnRef.current.contains(e.target)) {
      location("/build-module/" + props.module.uuid);
    }
  };
  const validation = (name, value, error = null) => {
    if (name === "description") {
      if (error != null) {
        setDescriptionError("This Field is required");
      } else {
        if (value !== "") {
          setDescription(value);
          setDescriptionError("");
        } else {
          setDescription("");
          setDescriptionError("This field is required");
        }
      }
    }

    if (name === "name") {
      if (error != null) {
        setTitleError("This Field is required");
      } else {
        if (value !== "") {
          setTitleError("");
          setTitle(value);
        } else {
          setTitle("");
          setTitleError("This field is required");
        }
      }
    }
    if (name === "gpt_key") {
      if (error != null) {
        setgpt_keyError("This Field is required");
      } else {
        if (value !== "") {
          setgpt_keyError("");
          setgpt_key(value);
        } else {
          setgpt_key("");
          setgpt_keyError("This field is required");
        }
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    validation(e.target.name, e.target.value);
  };
  const editForm = (e) => {
    e.preventDefault();
    setProcessing(true);
    var postData = {
      name: title,
      description: description,
      id: folderId,
      gpt_key: gpt_key,
    };
    api
      .post("/api/editModule", postData)
      .then((response) => {
        props.getAllFolders();
        setEditModal(false);
        setProcessing(false);
        props.setShowToast(true);
        props.setToastMessage({
          type: "success",
          message: "Chat Updated Successfully",
        });
      })
      .catch((error) => {
        if (error.response.data.errors) {
          props.setShowToast(true);
          props.setToastMessage({
            type: "error",
            message: "Something Went Wrong",
          });
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
        setProcessing(false);

        // console.log(error);
      });
  };

  return (
    <div>
      {
        <div key={props.key} className="card card-border">
          {/* <Link to={`/train-chat-bot/${props.module.uuid}`}> */}
          <div className="card-body h-full folder-card">
            <div className="flex flex-col justify-between h-full">
              <div className="cursor-pointer" onClick={(e) => redirectFun(e)}>
                <div className="flex justify-between">
                  <h6 className="card-title">{props.module.name}</h6>
                  <div className="dropdown" ref={btnRef}>
                    <div
                      className="dropdown-toggle"
                      id="dropdown-toggle-873-eVCGyHdcfE"
                    >
                      <button
                        className="button bg-transparent border border-transparent hover:bg-gray-50 active:bg-gray-100 text-gray-600 radius-circle h-7 w-7 inline-flex items-center justify-center text-base"
                        onClick={(e) => {
                          setOpen(!open);
                        }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                        </svg>
                      </button>
                    </div>
                    <ul
                      id="base-menu-124-eIThs96oYp"
                      className={`dropdown-menu bottom-end ${
                        open ? "block" : "hidden"
                      }`}
                      style={{
                        opacity: 1,
                        visibility: "visible",
                        transform: "rotateX(0deg)",
                      }}
                    >
                      <li
                        className="menu-item menu-item-dark menu-item-hoverable hover:bg-gray-50"
                        style={{ height: "35px" }}
                        onClick={() => {
                          setEditModal(true);
                          setFolderId(props.module.uuid);
                        }}
                      >
                        <span className="text-base">
                          <i className="fa-regular fa-pen-to-square"></i>
                        </span>
                        <span className="ml-2 rtl:mr-2">Edit</span>
                      </li>
                      <li
                        className="menu-item menu-item-dark menu-item-hoverable text-red-500 hover:text-white hover:bg-red-500"
                        style={{ height: "35px" }}
                        onClick={() => {
                          setDeleteModal(true);
                          setFolderId(props.module.uuid);
                        }}
                      >
                        <span className="text-base">
                          <i className="fa-regular fa-trash-can"></i>
                        </span>
                        <span className="ml-2 rtl:mr-2">Delete</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="mt-4 chatDescription">
                  {props.module.description}
                </p>
              </div>
              {deleteModal && (
                <div
                  id="defaultModal"
                  tabIndex={-1}
                  className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
                >
                  <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
                    <div
                      className="relative dialog-content bg-white rounded-lg shadow"
                      ref={btnRef}
                    >
                      <div className="text-center flex items-start justify-between p-4 rounded-t">
                        <div>
                          <h3 className="text-xl font-semibold text-gray-900  ">
                            Delete Folder
                          </h3>
                          <p className="mt-5">
                            Are you sure you want to delete this Folder? All the
                            Chats under this Folder will be deleted as well.
                            This action cannot be undone.
                          </p>
                        </div>
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                          data-modal-hide="defaultModal"
                          onClick={() => {
                            setDeleteModal(false);
                            setFolderId();
                          }}
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                      <form action="#" onSubmit={deleteFolder}>
                        <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                          <button
                            className="inline-flex items-center justify-center button bg-red-600 hover:bg-red-500 active:bg-red-700 text-white radius-round h-11 px-8 py-2 w-full"
                            type="submit"
                            disabled={processing}
                          >
                            {processing && (
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                            Delete
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              {editModal && (
                <div
                  id="defaultModal"
                  tabIndex={-1}
                  className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
                >
                  <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
                    <div className="relative dialog-content bg-white rounded-lg shadow">
                      <div className="flex items-start justify-between p-4 rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900  ">
                          Edit Module
                        </h3>
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                          data-modal-hide="defaultModal"
                          onClick={() => {
                            setEditModal(false);
                            setFolderId();
                          }}
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                      <form action="#" onSubmit={editForm}>
                        <div className="px-6">
                          <div className="mt-4">
                            <div className="form-container vertical">
                              <div className="form-item vertical">
                                <label className="form-label mb-2 required-input">
                                  Title
                                </label>
                                <div className>
                                  <input
                                    className={`${
                                      titleError !== "" ? "input-danger" : ""
                                    } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                    type="text"
                                    name="name"
                                    autoComplete="off"
                                    placeholder="Enter title"
                                    value={title}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  {titleError !== "" ? (
                                    <div className="form-error">
                                      {titleError}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="form-item vertical">
                                <label className="form-label mb-2 required-input">
                                  Gpt Key
                                </label>
                                <div className>
                                  <input
                                    className={`${
                                      titleError !== "" ? "input-danger" : ""
                                    } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                    type="text"
                                    name="gpt_key"
                                    autoComplete="off"
                                    placeholder="Enter gpt key"
                                    value={gpt_key}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  {titleError !== "" ? (
                                    <div className="form-error">
                                      {gpt_keyError}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="form-item vertical invalid">
                                <label className="form-label mb-2 required-input">
                                  Content
                                </label>
                                <div className>
                                  <textarea
                                    className={`${
                                      descriptionError !== ""
                                        ? "input-danger"
                                        : ""
                                    } input-textarea input-form input input-invalid input-textarea`}
                                    type="text"
                                    name="description"
                                    autoComplete="off"
                                    placeholder="Enter content"
                                    spellCheck="false"
                                    value={description}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  {descriptionError !== "" ? (
                                    <div className="form-error">
                                      {descriptionError}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                          <button
                            className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                            type="submit"
                            disabled={processing}
                          >
                            {processing && (
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* </Link> */}
        </div>
      }
    </div>
  );
};

export default ModuleCard;
