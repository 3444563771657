import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { DateRangePicker } from "react-date-range";
import SelectDateRange from "../common/SelectDateRange";
import {
  formatRangeDateForPayload,
  getDefaultRangeDates,
} from "../common/commonHelpers";
import { AppContext } from "../../App";

function getDisplayData(chats = {}, key) {
  const displayCounts = [];

  Object.values(chats).forEach((value) => {
    displayCounts.push(value[key]);
  });

  return displayCounts;
}

function getChartData(chats) {
  if (!Object.keys(chats)?.length) {
    return {};
  }

  const chartData = {
    series: [
      {
        name: "Open",
        data: getDisplayData(chats, "open_count"),
      },
      {
        name: "Reopen",
        data: getDisplayData(chats, "reopen_count"),
      },
      {
        name: "Resolved",
        data: getDisplayData(chats, "resolved_count"),
      },
      {
        name: "Abandoned",
        data: getDisplayData(chats, "abandoned_count"),
      },
      {
        name: "Queue",
        data: getDisplayData(chats, "queue_count"),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        // width: "100%",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: Object.keys(chats),
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return chartData;
}

function ChatsAnalytics({ chats, parentWidth }) {
  const [chartData, setChartData] = useState(getChartData(chats));

  if (!parentWidth) {
    return;
  }

  return (
    <Chart
      options={chartData?.options}
      series={chartData?.series}
      type="bar"
      height="400px"
      width={parentWidth}
    />
  );
}

export default ChatsAnalytics;
