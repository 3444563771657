import { useState } from "react";
import useDropDown from "../../../hooks/useDropDown";
import Button from "../../../components/common/Button";

function ThemeCard({
  name,
  containerCls = "",
  isOptionType,
  isSelected,
  isLive,
  onApply,
}) {
  const [open, setOpen] = useState(false);

  const { elementRef } = useDropDown({ open, setOpen });

  return (
    <div
      className={`mt-6 ${
        isSelected ? "border border-blue-600" : ""
      } w-[400px] relative ${containerCls}`}
      ref={elementRef}
    >
      {isSelected ? (
        <i class="fa-solid fa-circle-check absolute top-1 right-2 text-xl text-blue-600"></i>
      ) : null}

      <img
        src={require("../../../assets/images/support-portal.png")}
        alt="support-portal"
        className="w-full h-auto"
      />

      <div className="bg-gray-100 absolute bottom-0 left-0 w-full p-4 text-gray-700">
        <div className="flex justify-between">
          <div className="font-semibold">{name}</div>

          {isOptionType && !isSelected ? (
            <i
              class="fa-solid fa-ellipsis cursor-pointer"
              onClick={() => setOpen(!open)}
            ></i>
          ) : isLive ? (
            <div className="bg-green-50 border border-green-300 text-green-600 font-semibold rounded-md px-2 py-1 text-xs w-fit">
              Live
            </div>
          ) : null}
        </div>

        {/* <div className="text-sm mt-2">
          Updated {formatDateWithTime(new Date())}
        </div> */}

        {open ? (
          <div className="absolute z-10 -right-32 bottom-12 flex flex-col justify-center items-center bg-white shadow-lg text-gray-700 rounded-lg border w-fit mx-auto">
            <Button
              className="px-4 py-2 text-sm hover:bg-gray-100"
              onClick={() => {
                onApply();
                setOpen(false);
              }}
            >
              <i class="fa-solid fa-eye mr-2"></i>
              Apply this theme
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ThemeCard;
