import React from "react";
import Modal from "../common/Modal";
import { useContext, useEffect, useState } from "react";
import useApiData from "../../hooks/useApiData";
import { AppContext } from "../../App";
import api from "../../api/api";
import Select from "react-select";
import Spinner from "../loaders/Spinner";

function EditModal({
  customFields,
  open,
  onClose,
  clientId,
  getCustomerDetails,
}) {
  const [editForm, setEditForm] = useState({});
  const [customFieldForm, setCustomFieldForm] = useState(null);

  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);

  const { data, mutate, loading } = useApiData({});
  const {
    data: customFieldValues,
    mutate: customFieldApi,
    loading: isCustomFieldLoading,
  } = useApiData({
    onSuccess: (res) => {
      if (res?.data?.statusCode == "200") {
        const formattedData = res.data.data.map((data) => ({
          customfield_values:
            data.customfield_values && data.field_type === "multiselect"
              ? Object.values(JSON.parse(data.customfield_values))
              : data.customfield_values,
          customfield_id: data.customfield_id,
        }));
        setCustomFieldForm(formattedData);
      }
    },
  });

  const {
    data: updateData,
    mutate: updateApi,
    loading: isLoadingConfig,
  } = useApiData({
    onSuccess: (res) => {
      if (res.data.statusCode == 200) {
        onClose();
        getCustomerDetails();
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Client details updated successfully",
        });
      } else {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      }
    },
  });

  const getCustomerDetail = () => {
    mutate(() =>
      api.get(
        `api/v1/getClientDetail/communityId/${currCommunityId}/clientId/${clientId}`
      )
    );
    customFieldApi(() =>
      api.get(
        `api/v1/getClientCustomFieldValues/communityId/${currCommunityId}/clientId/${clientId}`
      )
    );
  };

  useEffect(() => {
    if (clientId) {
      getCustomerDetail();
    }
  }, [clientId]);

  useEffect(() => {
    if (data) {
      setEditForm({
        client_name: data.data[0].client_name,
        client_email: data.data[0].client_email,
        client_phone_no: data.data[0].client_phone_no,
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCustomFieldChange = (value, item) => {
    const newValue =
      item.field_type === "multiselect" ? value.map((v) => v) : value;

    const index = customFieldForm.findIndex(
      (field) => field.customfield_id === item.id
    );

    const updatedCustomFieldForm = [...customFieldForm];

    if (index !== -1) {
      updatedCustomFieldForm[index] = {
        ...updatedCustomFieldForm[index],
        customfield_values: newValue,
      };
    } else {
      updatedCustomFieldForm.push({
        customfield_id: item.id,
        customfield_values: newValue,
      });
    }

    setCustomFieldForm(updatedCustomFieldForm);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...editForm,
      customFieldValues: customFieldForm,
    };
    updateApi(() =>
      api.put(
        `api/v1/updateClient/communityId/${currCommunityId}/clientId/${clientId}`,
        payload
      )
    );
  };
  return (
    <Modal isOpen={open} onClose={onClose} title={"Edit Client Details"}>
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div
          id="defaultModal"
          tabIndex={-1}
          // className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div>
            <div>
              {/* <div className="flex items-start justify-between p-4 rounded-t">
                <h3 className="text-xl font-semibold text-gray-900  ">
                  Edit Client Details
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                  data-modal-hide="defaultModal"
                  onClick={onClose}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div> */}
              <form
                action="#"
                onSubmit={(e) => onSubmit(e)}
                className="bg-white"
              >
                <div className="">
                  <div className="mt-4">
                    <div className="form-container vertical">
                      <div className="form-item vertical mb-4">
                        <label className="form-label mb-2">Name</label>
                        <div className>
                          <input
                            className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                            type="text"
                            name="client_name"
                            autoComplete="off"
                            placeholder="Enter name"
                            value={editForm.client_name}
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-item vertical mb-4">
                        <label className="form-label mb-2">Email</label>
                        <div className>
                          <input
                            className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                            type="text"
                            name="client_email"
                            autoComplete="off"
                            placeholder="Enter Email"
                            value={editForm.client_email}
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-item vertical mb-4">
                        <label className="form-label mb-2">Phone Number</label>
                        <div className>
                          <input
                            className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                            type="text"
                            name="client_phone_no"
                            autoComplete="off"
                            placeholder="Enter Phone Number"
                            value={editForm.client_phone_no}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="form-item vertical">
                        <label className="form-label mb-2">Custom Fields</label>
                        <div>
                          {customFields?.data?.map((item) => (
                            <div key={item.id} className="custom-field">
                              <label className="form-label mb-2">
                                {item.field_name}
                              </label>
                              {console.log(
                                "customFieldValues",
                                customFieldForm
                              )}
                              {item.field_type === "input" && (
                                <input
                                  type="text"
                                  placeholder={item.field_name}
                                  className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                                  value={
                                    customFieldForm?.find(
                                      (field) =>
                                        field.customfield_id === item.id
                                    )?.customfield_values || ""
                                  }
                                  onChange={(e) =>
                                    handleCustomFieldChange(
                                      e.target.value,
                                      item
                                    )
                                  }
                                />
                              )}
                              {item.field_type === "select" && (
                                <Select
                                  options={Object.entries(
                                    JSON.parse(item.field_values)
                                  ).map(([key, value]) => ({
                                    value,
                                    label: value,
                                  }))}
                                  value={
                                    customFieldForm?.find(
                                      (field) =>
                                        field.customfield_id === item.id
                                    )?.customfield_values && {
                                      value: customFieldForm.find(
                                        (field) =>
                                          field.customfield_id === item.id
                                      ).customfield_values,
                                      label: customFieldForm.find(
                                        (field) =>
                                          field.customfield_id === item.id
                                      ).customfield_values,
                                    }
                                  }
                                  onChange={(selectedOption) =>
                                    handleCustomFieldChange(
                                      selectedOption
                                        ? selectedOption.value
                                        : null,
                                      item
                                    )
                                  }
                                />
                              )}
                              {item.field_type === "multiselect" && (
                                <Select
                                  options={Object.entries(
                                    JSON.parse(item.field_values)
                                  ).map(([key, value]) => ({
                                    value,
                                    label: value,
                                  }))}
                                  value={customFieldForm
                                    ?.filter(
                                      (field) =>
                                        field.customfield_id === item.id
                                    )
                                    .flatMap((field) =>
                                      field.customfield_values.map((val) => ({
                                        value: val,
                                        label: val.toString(),
                                      }))
                                    )}
                                  isMulti
                                  onChange={(selectedOptions) =>
                                    handleCustomFieldChange(
                                      selectedOptions
                                        ? selectedOptions.map(
                                            (option) => option.value
                                          )
                                        : [],
                                      item
                                    )
                                  }
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center pb-6 space-x-2  border-gray-200 rounded-b">
                  <button
                    className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-fit"
                    type="submit"
                    disabled={isLoadingConfig}
                  >
                    {isLoadingConfig && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default EditModal;
