import Select from "react-select";

const statusOptions = [
  { label: "Active", value: 0 },
  { label: "Closed", value: 1 },
  { label: "Hold", value: 2 },
];

function OfflineUsersStatusSelect({ defaultValue = "", onChange }) {
  return (
    <Select
      className="capitalize input-md text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 mb-4 focus:border-indigo-600"
      options={statusOptions}
      isClearable
      defaultValue={statusOptions.find(
        (s) => s.value.toString() === defaultValue?.toString()
      )}
      onChange={onChange}
      placeholder="Status"
    />
  );
}

export default OfflineUsersStatusSelect;
