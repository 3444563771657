import ViewMoreWrapper from "../common/ViewMoreWrapper";
import Spinner from "../loaders/Spinner";

const TicketHistory = (props) => {
  const { history, loading } = props;

  return (
    <div className="border border-gray-300 rounded-md bg-white p-6 mt-6">
      <h4 className="font-semibold text-xl pb-2 border-b border-gray-300">
        History
      </h4>

      {loading ? (
        <Spinner />
      ) : (
        <div className="ticket-history mt-4">
          <ViewMoreWrapper data={history}>
            {(data) => {
              return data?.map((history, index) => {
                const date = new Date(history.created_at);
                const now = new Date();
                const diffInMs = now - date;
                const diffInHours = Math.floor(diffInMs / 3600000);
                const diffInDays = Math.floor(diffInHours / 24);

                let timeAgo = "";

                if (diffInDays >= 1) {
                  timeAgo = diffInDays + "d ";
                }

                if (diffInHours < 1) {
                  const diffInMinutes = Math.floor(diffInMs / 60000);
                  timeAgo += diffInMinutes + "m ago";
                } else {
                  const remainingHours = diffInHours % 24;
                  timeAgo += remainingHours + "h ago";
                }
                return (
                  <li key={index} className="w-full flex space-x-4 mt-4">
                    <div className="w-2 h-2 rounded-full bg-black mt-1.5"></div>

                    <div className="">
                      <p className="text-sm">
                        <span className="font-semibold">
                          {history.username}
                        </span>
                        <div className="flex flex-col flex-wrap">
                          <span className="">{history.message}</span>
                          <span>{timeAgo}</span>
                        </div>
                      </p>
                    </div>
                  </li>
                );
              });
            }}
          </ViewMoreWrapper>
        </div>
      )}
    </div>
  );
};

export default TicketHistory;
