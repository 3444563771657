import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import DropFileInput from "../../components/drop-file-input/DropFileInput";
import api from "../../api/api";
import useApiData from "../../hooks/useApiData";
import { initialDomainUrl, setCookie } from "../common/commonHelpers";
import useCommunityDetails from "../../hooks/useCommunityDetails";
import BackBtn from "../common/BackBtn";
import SignInCommon from "../common/SignInCommon";

const CommunityForm = () => {
  const [title, setTitle] = useState("");
  const [indutryType, setIndustryType] = useState("");
  const [members, setMembers] = useState("");
  const [titleError, setTitleError] = useState("");
  const [indutryTypeError, setIndustryTypeError] = useState("");
  const [membersError, setMembersError] = useState("");
  const [addCommunityModal, setAddCommunityModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [logo, setLogo] = useState(false);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    setShowCommunityModal,
    onError,
  } = useContext(AppContext);
  const location = useNavigate();

  const locationData = useLocation();
  const queryParams = new URLSearchParams(locationData.search);

  const jwtToken = queryParams.get("jwtToken");

  // useEffect(() => {
  //   getUserInfo();
  // }, []);
  const validation = (name, value, error = null) => {
    if (name === "indutryType") {
      if (error != null) {
        setIndustryTypeError("This Field is required");
      } else {
        if (value !== "") {
          setIndustryType(value);
          setIndustryTypeError("");
        } else {
          setIndustryType("");
          setIndustryTypeError("This field is required");
        }
      }
    }
    // console.log("name", name);
    if (name === "name") {
      if (error != null) {
        setTitleError("This Field is required");
      } else {
        if (value !== "") {
          setTitleError("");
          setTitle(value);
        } else {
          setTitle("");
          setTitleError("This field is required");
        }
      }
    }

    if (name === "members") {
      if (error != null) {
        setMembersError("This Field is required");
      } else {
        if (value !== "") {
          setMembersError("");
          setMembers(value);
        } else {
          setMembers("");
          setMembersError("This field is required");
        }
      }
    }
  };
  const onFileChange = (files) => {
    // console.log("logo", files);

    setLogo(files[0]);
  };
  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.name, e.target.value);
    validation(e.target.name, e.target.value);
  };

  const { getCommunityDetails, loading } = useCommunityDetails();

  const addCommunity = (payload) => {
    api
      .post("/api/createCommunity", payload)
      .then((response) => {
        setProcessing(false);
        // console.log("response.data.status", response.data.status);
        if (response.data.status == "success") {
          setAddCommunityModal(false);
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Data added Successfully",
          });

          const community_id = response.data.data.community_id;
          localStorage.setItem("currCommunity", community_id);

          var payload = {
            community_id,
          };

          // getCommunityDetails(payload);
          window.location.href = "/";
        }
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
        const msgData = error?.response?.data?.error || "";
        onError(
          msgData ? msgData : "Something went wrong while creating Community"
        );
      });
  };

  const communityForm = (e) => {
    e.preventDefault();
    setProcessing(true);
    // console.log("logo", logo);

    if (logo) {
      var reader = new FileReader();
      reader.readAsDataURL(logo);
      reader.onload = function () {
        var postData = {
          name: title,
          industry_type: indutryType,
          members: members,
          logo: reader.result,
        };
        addCommunity(postData);
      };
    } else {
      var postData = {
        name: title,
        industry_type: indutryType,
        members: members,
        logo: null,
      };
      addCommunity(postData);
    }
  };

  useEffect(() => {
    localStorage.setItem("jwtToken", jwtToken);
    getUserInfo();
  }, []);

  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-4 h-full">
        <SignInCommon />
        <div className="col-span-2 my-5 flex flex-col justify-center items-center">
          <div className="xl:min-w-[550px] px-8">
            <div className="mb-8">
              <h3 className="mb-1 text-3xl font-semibold font-['Inter']">
                Add Community!
              </h3>
              <p className="text-gray-600">
                This community will get created under your subdomain.
              </p>
            </div>
            <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
              <div className="relative">
                <form action="#" onSubmit={(e) => communityForm(e)}>
                  <div className="">
                    <div className="">
                      <div className="form-container vertical">
                        <div className="form-item vertical">
                          <label className="form-label mb-2 required-input">
                            Title
                          </label>
                          <div className>
                            <input
                              className={`${
                                titleError !== "" ? "input-danger" : ""
                              } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                              type="text"
                              name="name"
                              autoComplete="off"
                              placeholder="Enter title"
                              value={title}
                              onChange={(e) => handleChange(e)}
                            />
                            {titleError !== "" ? (
                              <div className="form-error">{titleError}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="form-item vertical invalid">
                          <label className="form-label mb-2">Logo</label>
                          <div className="w-full">
                            <DropFileInput
                              onFileChange={(files) => onFileChange(files)}
                              acceptType={".png,.jpg"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2  border-gray-200 rounded-b">
                    <button
                      className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                      type="submit"
                      disabled={processing || loading}
                    >
                      {(processing || loading) && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}{" "}
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityForm;
