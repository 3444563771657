import axios from "axios";
import { initialDomainUrl } from "../components/common/commonHelpers";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000",
  // baseURL: "https://rhinodeskbackend.dckap.com",
  // baseURL: "https://udims.dckap.co/chatgpt/trainyourdata/backendLaravel",
  baseURL: "https://api.rhinoassist.com",
});

let isRefreshing = false;
let refreshSubscribers = [];

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // console.log(error);
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          refreshSubscribers.push(function (token) {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            resolve(instance(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("jwtToken");

      localStorage.removeItem("currCommunity");
      document.cookie = "chatSocketID-admin=";

      if (window.location.pathname !== "/signin") {
        window.location.href = initialDomainUrl + "/signin";
      }
    }
    return Promise.reject(error);
  }
);

function processQueue(error, token = null) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

export default instance;
