import { useCallback, useContext } from "react";
import api from "../../../../api/api";
import { AppContext } from "../../../../App";

function useSection() {
  const { currCommunityId } = useContext(AppContext);

  const createSection = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/createsection`, updatedPayload);
    },
    [currCommunityId]
  );

  const getSection = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/getsection`, updatedPayload);
    },
    [currCommunityId]
  );

  const deleteSection = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/deletesection`, updatedPayload);
    },
    [currCommunityId]
  );

  return {
    createSection,
    getSection,
    deleteSection,
  };
}

export default useSection;
