import React, { useMemo } from "react";

function PreviewAttachment({ openModal, setOpenModal }) {
  const attachment = openModal || {};

  const { link, type: attachmentType } = attachment;

  const type = useMemo(() => {
    if (!link) {
      return "";
    }

    if (attachmentType) {
      return attachmentType;
    }

    return link.split(/[#?]/)[0].split(".").pop().trim();
  }, [link, attachmentType]);

  return (
    <div>
      {openModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay attachment-popup fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-[60rem]	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="text-center flex items-start justify-between p-4 rounded-t">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Document
                  </h3>
                </div>
                <div className="flex items-center">
                  <a
                    href={link}
                    download
                    className="py-1 px-2 font-semibold bg-main text-white rounded-sm text-sm"
                    target="__blank"
                  >
                    <i class="fa-solid fa-download mr-2"></i>
                    Download
                  </a>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                    data-modal-hide="defaultModal"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
              </div>
              <div style={{ height: "100%" }}>
                {/* <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={docs}
            /> */}

                {["doc", "docx", "xls", "xlsx"].includes(type) ? (
                  <embed
                    className="w-[100%]"
                    style={{ height: "450px" }}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                      link
                    )}`}
                  ></embed>
                ) : ["jpg", "jpeg", "png", "gif"].includes(type) ? (
                  <img
                    className="w-[100%]"
                    style={{ height: "auto" }}
                    src={`${link}`}
                  ></img>
                ) : ["pdf"].includes(type) ? (
                  <embed
                    type="application/pdf"
                    className="w-[100%]"
                    style={{ height: "450px" }}
                    src={`${link}`}
                  ></embed>
                ) : ["mp4", "3gp"].includes(type) ? (
                  <video controls width="100%" style={{ height: "auto" }}>
                    <source src={link} type={`video/${type}`} />
                    Sorry, your browser doesn't support videos.
                  </video>
                ) : (
                  <embed
                    type={`application/${type}`}
                    className="w-[100%]"
                    style={{ height: "450px" }}
                    src={`${link}`}
                  ></embed>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PreviewAttachment;
