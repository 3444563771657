import { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../App";
import CommunityDropdown from "../community/CommunityDropdown";

const sidebarConfig = [
  {
    label: "Analytics",
    src: "/images/icons/sidebar/dashboard.svg",
    routeTo: "/",
  },
  {
    label: "Conversations",
    src: "/images/icons/sidebar/chat.svg",
    routeTo: "/messages",
  },
  {
    label: "Tickets",
    src: "/images/icons/sidebar/ticket.svg",
    routeTo: "/tickets",
  },
  {
    label: "Email",
    src: "/images/icons/sidebar/email.svg",
    routeTo: "/mails",
  },
  {
    label: "Widget Configuration",
    src: "/images/icons/sidebar/widget.svg",
    routeTo: "/chatbots",
    showRoute: (currUser) => {
      const { role } = currUser?.currCommunity || {};

      return role === 1 || role === 2;
    },
  },
  {
    label: "Work Log",
    src: "/images/icons/sidebar/clockwork.svg",
    routeTo: "/work-log",
  },
  {
    label: "Customer Management",
    src: "/images/icons/sidebar/customer.svg",
    routeTo: "/customer-management",
  },
];

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    setShowCommunityModal,
    showCommunityModal,
    currCommunityId,
    navToggle,
    setNavToggle,
    handleLinkClick,
    hasChanged,
  } = useContext(AppContext);
  const communityDropdownRef = useRef();
  const [isOpen, setIsOpen] = useState(0);
  const [position, setPosition] = useState({ top: 0 });
  const [showToolTip, setShowToolTip] = useState("");

  const toggleDropdown = (value) => {
    if (isOpen) {
      setIsOpen(0);
    } else {
      setIsOpen(value);
    }
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        communityDropdownRef.current &&
        !communityDropdownRef.current.contains(e.target)
      ) {
        setShowCommunityModal(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  useEffect(() => {
    setActiveItem(location.pathname);
    getUserInfo();
  }, [location]);
  const handleActiveItem = (value) => {
    if (!hasChanged) {
      setActiveItem(value);
    }
  };
  return (
    <div className="fixed-sidebar text-white transition-all ease-in-out duration-300">
      <div className=" flex flex-col items-center h-full overflow-hidden sidebar-nav !bg-secondary-600 text-white">
        {/* <div ref={communityDropdownRef}>
          <div
            className="text-center justify-start font-medium text-2xl flex items-center w-full px-3 py-3 mt-3 cursor-pointer community-modal"
            onClick={() => setShowCommunityModal(!showCommunityModal)}
          >
            {currUser?.user &&
              currUser.user.map((user, index) =>
                user.community_id == currCommunityId ? (
                  user.logo ? (
                    <div key={index} className="flex flex-col user-name">
                      <img src={user.logo} width={150}></img>
                      <span className="logo-tagname">{user.name}</span>
                    </div>
                  ) : (
                    <>
                      <div className="user-name">{user.name}</div>
                    </>
                  )
                ) : null
              )}

            <svg
              data-hid="true"
              aria-hidden="true"
              viewBox="0 0 20 20"
              className="w-[15%] mt-2 h-auto"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.72 7.47a.75.75 0 0 1 1.06 0L10 10.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0L5.72 8.53a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          {showCommunityModal && <CommunityDropdown />}
        </div>
        <div className="toggle" onClick={() => setNavToggle(!navToggle)}>
          <img src="/images/icons/sidebar/toggle.svg" alt="toggle sidebar" />
        </div> */}
        {showToolTip ? (
          <div
            className="bg-[#323F5E] rounded-md text-white text-sm px-4 py-2 shadow-md absolute left-[90px] z-[9999] truncate"
            style={{
              top: position.top,
            }}
          >
            {showToolTip}
          </div>
        ) : null}

        <div className={`mt-4 h-full w-full px-5`}>
          <div className="h-full flex flex-col justify-between py-2">
            <div>
              {sidebarConfig.map((s, i) => {
                const { label, src, routeTo, showRoute } = s;

                let show = true;

                if (showRoute) {
                  show = showRoute(currUser);
                }

                return (
                  <DisplayNav
                    show={show}
                    routeTo={routeTo}
                    activeItem={activeItem}
                    navToggle={navToggle}
                    src={src}
                    label={label}
                    handleClick={(e) => {
                      setShowToolTip("");
                      handleLinkClick(e);
                      handleActiveItem(routeTo);
                    }}
                    handleMouseEnter={(e) => {
                      if (!navToggle) {
                        return;
                      }

                      const { top } = e.target.getBoundingClientRect();

                      setPosition({
                        top: top - 64,
                      });

                      setShowToolTip(label);
                    }}
                    handleMouseLeave={() => {
                      setShowToolTip("");
                    }}
                  />
                );
              })}
            </div>

            <div className="border-t border-gray-600">
              <Link
                to="/settings/profile"
                className={`${
                  activeItem === "/settings/profile" ? "bg-secondary-100" : ""
                } p-3 hover:bg-secondary-100 mt-2 font-medium rounded-md flex item-center ${
                  navToggle ? "w-fit mx-auto" : ""
                }`}
                onClick={(e) => {
                  handleLinkClick(e);
                  handleActiveItem("/settings/profile");
                }}
              >
                <div className={navToggle ? "" : "w-8"}>
                  <img
                    src="/images/icons/sidebar/settings.svg"
                    className="w-5"
                    alt="Settings"
                  />
                </div>

                <div
                  className={`text-sm font-medium ${navToggle ? "hidden" : ""}`}
                >
                  Settings
                </div>
              </Link>
            </div>

            {/* <Link
              to="/"
              className={`${
                activeItem === "/" ? "active" : ""
              } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
              onClick={(e) => {
                handleLinkClick(e);
                handleActiveItem("/");
              }}
            >
              <i className="fa-solid fa-chart-line"></i>

              <span className="ml-2 text-sm font-medium">Dashboard</span>
            </Link>

            <Link
              to="/messages"
              className={`${
                activeItem === "/messages" ? "active" : ""
              } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
              onClick={(e) => {
                handleLinkClick(e);
                handleActiveItem("/messages");
              }}
            >
              <i className="fa-regular fa-comment-dots"></i>
              <span className="ml-2 text-sm font-medium">Chats</span>
            </Link>
            <Link
              to="/tickets"
              className={`${
                activeItem === "/tickets" ? "active" : ""
              } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
              onClick={(e) => {
                handleLinkClick(e);
                handleActiveItem("/tickets");
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="presentation"
              >
                <g fill="currentColor" fillRule="evenodd">
                  <path
                    d="M5 12.991c0 .007 14.005.009 14.005.009C18.999 13 19 5.009 19 5.009 19 5.002 4.995 5 4.995 5 5.001 5 5 12.991 5 12.991zM3 5.01C3 3.899 3.893 3 4.995 3h14.01C20.107 3 21 3.902 21 5.009v7.982c0 1.11-.893 2.009-1.995 2.009H4.995A2.004 2.004 0 013 12.991V5.01zM19 19c-.005 1.105-.9 2-2.006 2H7.006A2.009 2.009 0 015 19h14zm1-3a2.002 2.002 0 01-1.994 2H5.994A2.003 2.003 0 014 16h16z"
                    fillRule="nonzero"
                  ></path>
                  <path d="M10.674 11.331c.36.36.941.36 1.3 0l2.758-2.763a.92.92 0 00-1.301-1.298l-2.108 2.11-.755-.754a.92.92 0 00-1.3 1.3l1.406 1.405z"></path>
                </g>
              </svg>
              <span className="ml-2 text-sm font-medium">Tickets</span>
            </Link>
            <Link
              to="/mails"
              className={`${
                activeItem === "/mails" ? "active" : ""
              } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
              onClick={(e) => {
                handleLinkClick(e);
                handleActiveItem("/mails");
              }}
            >
              <i className="fa-regular fa-envelope"></i>
              <span className="ml-2 text-sm font-medium">E-Mail</span>
            </Link>
            {(currUser?.currCommunity?.role == 1 ||
              currUser?.currCommunity?.role == 2) && (
              <>
                <Link
                  to={`/chatbots`}
                  className={`${
                    activeItem === "/chatbots" ? "active" : ""
                  } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
                  onClick={(e) => {
                    handleLinkClick(e);
                    handleActiveItem("/chatbots");
                  }}
                >
                  <i className="fa-sharp fa-regular fa-comments"></i>
                  <span className="ml-2 text-sm font-medium">
                    Widget Configuration
                  </span>
                </Link>
              </>
            )}
            <Link
              to="/clockwork"
              className={`${
                activeItem === "/clockwork" ? "active" : ""
              } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
              onClick={(e) => {
                handleLinkClick(e);
                handleActiveItem("/");
              }}
            >
              <i className="fa-regular fa-clock"></i>
              <span className="ml-2 text-sm font-medium">Clockwork</span>
            </Link>
            <Link
              to="/customer-management"
              className={`${
                activeItem?.includes("/customer-management") ? "active" : ""
              } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
              onClick={(e) => {
                handleLinkClick(e);
                handleActiveItem("/");
              }}
            >
              <i className="fa-regular fa-user"></i>
              <span className="ml-2 text-sm font-medium">
                Customer Management
              </span>
            </Link>
            <Link
              to="/settings/profile"
              className={`${
                activeItem === "/settings/profile" ? "active" : ""
              } flex items-center w-full p-3 mt-2 rounded hover:bg-gray-300`}
              onClick={(e) => {
                handleLinkClick(e);
                handleActiveItem("/settings/profile");
              }}
            >
              <i className="fa-sharp fa-solid fa-gear"></i>
              <span className="ml-2 text-sm font-medium">Settings</span>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

function DisplayNav({
  show,
  routeTo,
  activeItem,
  navToggle,
  src,
  label,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
}) {
  return (
    <div>
      {show ? (
        <Link
          to={routeTo}
          className={`${
            activeItem === routeTo ? "bg-secondary-100" : ""
          } relative p-3 hover:bg-secondary-100 mt-2 font-medium rounded-md flex item-center ${
            navToggle ? "w-fit mx-auto" : ""
          }`}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={navToggle ? "" : "w-8"}>
            <img src={src} className="w-5" alt={label} />
          </div>

          <div className={`text-sm font-medium ${navToggle ? "hidden" : ""}`}>
            {label}
          </div>
        </Link>
      ) : null}
    </div>
  );
}
