import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";

import { Link } from "react-router-dom";
import Field from "./Field";

const PromptFormPage = (props) => {
  const [availableVariables, setAvaialbleVariables] = useState([]);
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const { currUser, getUserInfo, setToastMessage, setShowToast } =
    useContext(AppContext);
  const [embedModal, setEmbedModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState(
    "<a target='blank' href='https://rhinodeskcrm.dckap.com/module/" +
      props.folderDetails[0].uuid +
      "'></a>"
  );
  useEffect(() => {
    var availableVariable = props.fieldValue.map((item) => item.label);
    setAvaialbleVariables(availableVariable);
    // console.log(props.userPromptValue);
    setText(props.userPromptValue);
  }, []);

  useEffect(() => {
    props.setUserPromptValue(text);
  }, [text]);

  const generateOutput = (e) => {};

  const setOpenTabValues = (e) => {
    props.setOpenTabValue(e);
  };

  const onSubmit = (e) => {
    props.formBuilderSubmit(e);
  };

  const handleNameDragStart = (event, variable) => {
    event.dataTransfer.setData("name", JSON.stringify(variable));
    // console.log(event.dataTransfer.getData("name"));
  };

  const handleTextAreaDrop = (event) => {
    event.preventDefault();
    const droppedName = JSON.parse(event.dataTransfer.getData("name"));
    const textarea = event.target;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const text = textarea.value;
    const newText =
      text.substring(0, startPos) + droppedName + text.substring(endPos);
    setText(newText);
    props.setUserPromptValue(newText);
    // set the cursor position to the end of the inserted variable name
    textarea.selectionStart = startPos + droppedName.length;
    textarea.selectionEnd = startPos + droppedName.length;
  };

  const handleTextAreaKeyDown = (event) => {
    if (event.keyCode === 8) {
      // backspace key code
      const words = text.split(" ");
      const lastWord = words[words.length - 1];
      if (lastWord.startsWith("{") && lastWord.endsWith("}")) {
        setText(text.slice(0, -lastWord.length));
      }
    }
  };

  const handleTextAreaChange = (event) => {
    const { value, selectionStart } = event.target;
    // check if backspace key was pressed
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // check if cursor is positioned at the beginning of a word inside {}
      const startOfWord = value.lastIndexOf("{", selectionStart - 1);
      const endOfWord = value.indexOf("}", selectionStart);

      if (
        startOfWord >= 0 &&
        endOfWord > startOfWord &&
        endOfWord === selectionStart
      ) {
        // delete the entire word inside {}
        const newValue =
          value.substring(0, startOfWord) + value.substring(endOfWord + 1);
        setText(newValue);
        // set the cursor position to the beginning of the deleted word
        event.target.selectionStart = startOfWord;
        event.target.selectionEnd = startOfWord;
        return;
      }
    }
    setText(event.target.value);
  };

  return (
    <div className="container mx-auto ">
      <div className="bg-white rounded ">
        <h1 className="text-xl font-bold mb-6">Enter Prompt Text</h1>
        <div className="mb-4">
          <div className="flex justify-between">
            <span className=" text-2xl font-bold mb-4 mb-5 block">
              Prompt Page
            </span>
            <div>
              <button
                type="button"
                className="mt-3 inline-flex items-center justify-center button bg-gray-100 light-blue-hover text-gray-700 radius-round h-6 text-sm px-4 py-4"
                onClick={() => setEmbedModal(!embedModal)}
              >
                Embed Code
              </button>
              <button
                type="button"
                className="mt-3 inline-flex items-center justify-center button bg-indigo-400 hover:bg-indigo-300 text-white ml-3 radius-round h-6 text-sm px-4 py-4"
                onClick={() => setOpenTabValues(3)}
              >
                Preview
              </button>
            </div>
          </div>
          <div className="flex">
            <div className="rounded-xl h-80 w-3/4 p-4 bg-gray-50">
              <textarea
                index={0}
                className="w-full h-72 p-3 rounded-xl"
                onDrop={handleTextAreaDrop}
                onChange={handleTextAreaChange}
                onDragOver={(event) => event.preventDefault()}
                onKeyDown={(event) => handleTextAreaKeyDown(event)}
                value={text}
              ></textarea>
            </div>
            <div className="ml-3 rounded-xl w-1/4 p-4 bg-gray-50 mr-4">
              <h2 className="text-lg font-bold mb-2">Fields</h2>
              {availableVariables.map((variable, index) => (
                <div
                  variable={variable}
                  index={index}
                  draggable
                  onDragStart={(event) =>
                    handleNameDragStart(event, " {" + variable + "} ")
                  }
                >
                  {variable}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-8">
          <span
            onClick={(e) => setOpenTabValues(1)}
            className="inline-block align-baseline font-bold text-sm text-indigo-500 hover:text-indigo-800"
          >
            Back to Form Builder
          </span>
          <button
            className="flex bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            onClick={(e) => onSubmit(e)}
          >
            {props.processing && (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            Generate Prompt
          </button>
        </div>
      </div>
      {embedModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="text-center flex items-start justify-between p-4 rounded-t">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Implement ChatBot In your Website
                  </h3>

                  <p className="mt-5">
                    <label
                      htmlFor="email"
                      className="relative text-gray-400 focus-within:text-gray-600 block"
                    >
                      <input
                        type="text"
                        name="text"
                        placeholder=""
                        value={embedUrl}
                        className="mb-4 pr-11 pt-2 pb-2 pl-2 input form-input w-full border"
                      />
                      <button
                        type="button"
                        data-copy-state="copy"
                        className="copyInput flex items-center text-xs font-medium text-gray-600 bg-gray-100 border-l border-gray-200   hover:text-blue-700   copy-to-clipboard-button"
                        onClick={() => {
                          navigator.clipboard.writeText(embedUrl);
                          setShowToast(true);
                          setToastMessage({
                            type: "success",
                            message: "Copied code to clipboard",
                          });
                        }}
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                          />
                        </svg>
                      </button>
                    </label>
                    Implement ChatBot In your Website, paste the above code in
                    end of the body tag
                  </p>
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setEmbedModal(false)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptFormPage;
