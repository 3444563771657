import { useState } from "react";

const Toast = (props) => {
  const [visible, setVisible] = useState(true);

  setTimeout(() => {
    setVisible(false);
    props.onClose();
  }, props.duration);

  return visible ? (
    <div>
      <div className="toast">
        <div className="toast-wrapper">
          <div className="notification">
            <div className="notification-content no-child">
              <div className="mr-3">
                <span
                  className={`text-2xl ${
                    props.message.type == "success"
                      ? "text-emerald-400"
                      : "text-red-400"
                  }`}
                >
                  {props.message.type == "success" ? (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 20 20"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : props.message.type == "warning" ? (
                    <i className="fa-solid fa-triangle-exclamation !text-yellow-500"></i>
                  ) : (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 20 20"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                </span>
              </div>
              <div className="mr-4">
                <div className="notification-title">
                  {props.message.message}
                </div>
                <div className="notification-description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Toast;
