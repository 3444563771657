import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../../App";
import SideBar from "../../../components/settings/SideBar";
import api from "../../../api/api";

const Profile = () => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [bio, setBio] = useState("");
  const [bioError, setBioError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [openDetailsForm, setOpenDetailsForm] = useState(false);
  const [userName, setUserName] = useState();
  const [image, setImage] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [userNameError, setUserNameError] = useState("");
  const [passwordType, setPasswordType] = useState({
    password: "password",
    confirmPassword: "password",
    currentPassword: "password",
  });
  const inputFileRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUploadImage(reader.result);
      // console.log(reader.result);
    };
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  useEffect(() => {
    setEmail(currUser?.email);
    setUserName(currUser?.username);
    setImage(currUser?.image);
    // setUploadImage(currUser?.image);
    setBio(currUser?.bio);
  }, [currUser]);

  useEffect(() => {
    if (confirmPassword !== "" && password !== "") {
      if (confirmPassword !== password) {
        setConfirmPasswordError(
          "The confirm password and password must match."
        );
      } else {
        setConfirmPasswordError("");
      }
    }
  }, [password, confirmPassword]);

  const validation = (name, value, error = null) => {
    if (name === "currentPassword") {
      if (error != null) {
        setCurrentPasswordError(error);
      } else {
        if (value !== "") {
          setCurrentPasswordError("");
          setCurrentPassword(value);
        } else {
          setCurrentPassword("");
          setCurrentPasswordError("This field is required");
        }
      }
    }
    if (name === "email") {
      if (error !== null) {
        setEmailError(error);
      } else {
        if (value !== "") {
          setEmail(value);
          setEmailError("");
        } else {
          setEmail("");
          setEmailError("Please enter your email");
        }
      }
    }

    if (name === "userName") {
      if (error !== null) {
        setUserNameError(error);
      } else {
        if (value !== "") {
          setUserName(value);
          setUserNameError("");
        } else {
          setUserName("");

          setUserNameError("Please enter your username");
        }
      }
    }
    if (name === "bio") {
      setBio(value);
    }
    if (name === "password") {
      if (error != null) {
        setPasswordError(error);
      } else {
        if (value !== "") {
          setPasswordError("");
          setPassword(value);
        } else {
          setPassword("");
          setPasswordError("This field is required");
        }
      }
    }

    if (name === "confirmPassword") {
      if (error != null) {
        setConfirmPasswordError(error);
      } else {
        if (value !== "") {
          setConfirmPasswordError("");
          setConfirmPassword(value);
        } else {
          setConfirmPassword("");
          setConfirmPasswordError("This field is required");
        }
      }
    }
  };

  const resetForm = () => {
    setEmail(currUser?.email);
    setUserName(currUser?.username);
    setImage(currUser?.image);
    setBio(currUser?.bio);
    setUploadImage("");
  };

  const handleChange = (e) => {
    e.preventDefault();
    validation(e.target.name, e.target.value);
  };

  const togglePassword = (type) => {
    setPasswordType((prevState) => ({
      ...prevState,
      [type]: prevState[type] === "password" ? "text" : "password",
    }));
  };
  const formSubmit = (e, type) => {
    e.preventDefault();
    if (type == "profileUpdate") {
      var payload = {
        username: userName,
        email: email,
        image: uploadImage,
        bio: bio,
        type: type,
      };
    }

    if (type == "passwordUpdate") {
      var payload = {
        currentPassword: currentPassword,
        password: password,
        confirmPassword: confirmPassword,
        type: type,
      };
    }

    setProcessing(true);
    api
      .post("/api/auth/updateprofileDetails", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status === "success") {
          setShowToast(true);
          setOpenDetailsForm(false);
          setToastMessage({
            type: "success",
            message: "Data updated Successfully",
          });
          getUserInfo();
          setCurrentPassword("");
          setPassword("");
          setConfirmPassword("");
          setUploadImage(null);
        }
      })
      .catch((error) => {
        const { errors, message } = error.response.data;
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: message ? message : "Something Went Wrong",
        });
        if (errors) {
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        } else {
        }
      });
  };

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col">
          <div className="container mx-auto h-full">
            <div className="lg:flex items-center justify-between mb-4">
              {/* <h3 className="">Settings</h3> */}
            </div>
            <div className="flex gap-4">
              <SideBar />
              <div className="w-[80%] mt-5">
                <div className="text-2xl font-semibold mb-4 text-black">
                  My Profile
                </div>

                <div className="bg-white px-6 py-4 border border-gray-300 rounded-md">
                  <div className="flex items-center justify-between w-full block">
                    <h3 className="mb-4 text-xl lg:mb-0 font-semibold">
                      Profile Details
                    </h3>
                    {!openDetailsForm && (
                      <button
                        className="hover:bg-gray-100 py-2 px-3 rounded profile-edit-button duration-300 ease"
                        onClick={(e) => setOpenDetailsForm(true)}
                      >
                        <i className="fa-regular fa-pen-to-square text-primary-600 text-lg"></i>
                      </button>
                    )}
                    {openDetailsForm && (
                      <button
                        className="bg-gray-100 py-2 px-3 rounded profile-cancel-button duration-300 ease"
                        onClick={(e) => {
                          setOpenDetailsForm(false);
                          resetForm();
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>

                  <form
                    onSubmit={(e) => formSubmit(e, "profileUpdate")}
                    className="mt-3 flex"
                  >
                    <div className="profileImage rounded flex items-center justify-center bg-gray-200 w-[150px] h-[150px]">
                      {!openDetailsForm &&
                        (image ? (
                          <img
                            src={image}
                            className={`${
                              openDetailsForm && "filter contrast-50"
                            }`}
                          />
                        ) : (
                          <i className="fa-solid fa-user text-3xl"></i>
                        ))}
                      {openDetailsForm && (
                        <>
                          <img
                            src={uploadImage ? uploadImage : image}
                            className={`${
                              openDetailsForm && "filter contrast-50"
                            }`}
                          />
                          <div className="absolute flex flex-col gap-2">
                            <button
                              className="bg-indigo-500 text-white px-4 py-2 rounded  z-10"
                              onClick={handleSelectButtonClick}
                              type="button"
                            >
                              Select
                            </button>
                            {image && (
                              <button
                                className="bg-red-500 text-white px-4 py-2 rounded  z-10"
                                onClick={() => setUploadImage("delete")}
                                type="button"
                              >
                                Delete
                              </button>
                            )}
                          </div>
                          <input
                            type="file"
                            accept=".png"
                            className="hidden"
                            ref={inputFileRef}
                            onChange={handleFileUpload}
                          />
                        </>
                      )}
                    </div>
                    <div className="ml-8 mx-3 profileDetails w-[80%]">
                      <div className="grid grid-cols-2 gap-8 w-full">
                        <div>
                          <label className="font-semibold required-input">
                            Username
                          </label>
                          {!openDetailsForm && <p>{currUser?.username}</p>}

                          {openDetailsForm && (
                            <>
                              <input
                                type="text"
                                className={`${
                                  userNameError !== "" ? "input-danger" : ""
                                } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                name="userName"
                                autoComplete="off"
                                placeholder="Username"
                                style={{ paddingRight: "2.25rem" }}
                                value={userName}
                                onChange={(e) => handleChange(e)}
                              />
                              {userNameError !== "" ? (
                                <div className="form-error">
                                  {userNameError}
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>

                        <div>
                          <label className="font-semibold required-input">
                            Email
                          </label>
                          {!openDetailsForm && <p>{currUser?.email}</p>}
                          {openDetailsForm && (
                            <>
                              <input
                                type="text"
                                className={`${
                                  emailError !== "" ? "input-danger" : ""
                                } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                name="email"
                                autoComplete="off"
                                placeholder="Email"
                                style={{ paddingRight: "2.25rem" }}
                                value={email}
                                onChange={(e) => handleChange(e)}
                              />
                              {emailError !== "" ? (
                                <div className="form-error">{emailError}</div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>

                        <div>
                          <label className="font-semibold">Bio</label>
                          {!openDetailsForm && <p>{currUser?.bio}</p>}
                          {openDetailsForm && (
                            <>
                              <textarea
                                className={`${
                                  bioError !== "" ? "input-danger" : ""
                                } input-textarea input-form input input-invalid input-textarea`}
                                type="text"
                                name="bio"
                                autoComplete="off"
                                placeholder="Enter content"
                                spellCheck="false"
                                value={bio}
                                onChange={(e) => handleChange(e)}
                              />
                              {bioError !== "" ? (
                                <div className="form-error">{bioError}</div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {openDetailsForm && (
                        <div className="text-right">
                          <button
                            type="submit"
                            className="flex justify-end float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
                            disabled={processing}
                          >
                            {processing && (
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>

                <div className="bg-white px-6 py-6 border border-gray-300 rounded-md mt-8">
                  <div className="flex items-center justify-between w-full block">
                    <h3 className="mb-4 text-xl font-bold">Change Password</h3>
                  </div>
                  <div className="mr-3">
                    <form
                      onSubmit={(e) => formSubmit(e, "passwordUpdate")}
                      className="mr-3 mt-3 w-full"
                    >
                      <div>
                        <div className="form-item vertical w-full">
                          <label className="form-label mb-2 required-input">
                            Current Password
                          </label>
                          <div className>
                            <span className="input-wrapper w-full">
                              <input
                                type={passwordType.currentPassword}
                                className={`${
                                  currentPasswordError !== ""
                                    ? "input-danger"
                                    : ""
                                } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                name="currentPassword"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                style={{ paddingRight: "2.25rem" }}
                                onChange={(e) => handleChange(e)}
                                value={currentPassword}
                              />
                              <div className="input-suffix-end">
                                <span className="cursor-pointer text-xl">
                                  <i
                                    className={`cursor-pointer text-gray-700 fa-regular ${
                                      passwordType.currentPassword ===
                                      "password"
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    }`}
                                    onClick={() =>
                                      togglePassword("currentPassword")
                                    }
                                  ></i>
                                  {/* <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth={0}
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      togglePassword("currentPassword")
                                    }
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                    />
                                  </svg> */}
                                </span>
                              </div>
                            </span>
                            {currentPasswordError !== "" ? (
                              <div className="form-error">
                                {currentPasswordError}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-5">
                        <div className="form-item vertical w-full">
                          <label className="form-label mb-2 required-input">
                            New Password
                          </label>
                          <div className>
                            <span className="input-wrapper w-full">
                              <input
                                type={passwordType.password}
                                className={`${
                                  passwordError !== "" ? "input-danger" : ""
                                } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                name="password"
                                autoComplete="off"
                                placeholder="Password"
                                style={{ paddingRight: "2.25rem" }}
                                onChange={(e) => handleChange(e)}
                                value={password}
                              />
                              <div className="input-suffix-end">
                                <span className="cursor-pointer text-xl">
                                  <i
                                    className={`cursor-pointer text-gray-700 fa-regular ${
                                      passwordType.password === "password"
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    }`}
                                    onClick={() => togglePassword("password")}
                                  ></i>

                                  {/* <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth={0}
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => togglePassword("password")}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                    />
                                  </svg> */}
                                </span>
                              </div>
                            </span>
                            {passwordError !== "" ? (
                              <div className="form-error">{passwordError}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className=" w-full">
                          <label className="form-label mb-2 required-input">
                            Confirm Password
                          </label>
                          <div className>
                            <span className="input-wrapper w-full">
                              <input
                                type={passwordType.confirmPassword}
                                className={`${
                                  confirmPasswordError !== ""
                                    ? "input-danger"
                                    : ""
                                } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                name="confirmPassword"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                style={{ paddingRight: "2.25rem" }}
                                onChange={(e) => handleChange(e)}
                                value={confirmPassword}
                              />
                              <div className="input-suffix-end">
                                <span className="cursor-pointer text-xl">
                                  <i
                                    className={`cursor-pointer text-gray-700 fa-regular ${
                                      passwordType.confirmPassword ===
                                      "password"
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    }`}
                                    onClick={() =>
                                      togglePassword("confirmPassword")
                                    }
                                  ></i>
                                  {/* <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth={0}
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      togglePassword("confirmPassword")
                                    }
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                    />
                                  </svg> */}
                                </span>
                              </div>
                            </span>
                            {confirmPasswordError !== "" ? (
                              <div className="form-error">
                                {confirmPasswordError}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="flex items-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
                          disabled={processing}
                        >
                          {processing && (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
