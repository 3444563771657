import { useState } from "react";

function updateApiState(update, setApiState) {
  setApiState((prev) => ({ ...prev, ...update }));
}

function handleApi(apiFn, setApiState, onSuccess, onError) {
  updateApiState({ loading: true }, setApiState);

  apiFn()
    .then((response) => {
      updateApiState({ loading: false, data: response?.data }, setApiState);
      onSuccess(response);
    })
    .catch((error) => {
      updateApiState({ loading: false, error, isError: true }, setApiState);
      onError(error);
    });
}

function useApiData({ onSuccess = () => {}, onError = () => {} }) {
  const [apiState, setApiState] = useState({
    data: null,
    loading: false,
    error: null,
    isError: false,
  });

  return {
    mutate: (apiFn) => handleApi(apiFn, setApiState, onSuccess, onError),
    ...apiState,
  };
}

export default useApiData;
