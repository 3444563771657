function Table({
  containerCls = "",
  data = [],
  columns,
  tdCls = "",
  thCls = "",
}) {
  return (
    <table
      className={`my-6 border table-auto w-full text-left ${containerCls}`}
    >
      <thead>
        <tr>
          {columns.map((col, i) => {
            return (
              <th
                key={i}
                className={`border border-gray-300 bg-[#E3E9F3] p-3 ${thCls}`}
              >
                {col.name}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {data.map((d, i) => {
          return (
            <tr key={i}>
              {columns.map((col, j) => {
                const Comp = col.renderComp(d, i);

                return (
                  <td key={j} className={`border border-gray-300 p-3 ${tdCls}`}>
                    {Comp}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
