import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../App";
import FilterButton, {
  SelectFilterOptionCheckbox,
  SelectFilterOptionRadio,
} from "../common/FilterButton";
import { Calendar, DateRangePicker } from "react-date-range";
import moment from "moment";
import TicketSettings from "./TicketSettings";
import Select from "react-select";
import DependencyDropdown from "./DependencyDropdown";
import api from "../../api/api";
import Loading from "../loaders/Loading";
import { Tooltip } from "react-tooltip";

function getRadioOptions(options) {
  return [...options, { label: "Clear", value: "" }];
}

const statusOptions = [
  { value: "open", label: "Open" },
  { value: "inprogress", label: "Inprogress" },
  { value: "hold", label: "Hold" },
  { value: "completed", label: "Completed" },
];

const isSchedulerOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

const priorityOptions = [
  { value: "p1", label: "P1" },
  { value: "p2", label: "P2" },
  { value: "p3", label: "P3" },
  { value: "p4", label: "P4" },
];

function TicketsHeader({
  sortTicket,
  queryParams,
  showReset,
  onReset,
  folderSearch,
  onNewIssueBtnClick,
  commnuityFields,
  getAllFields,
  portalOptions,
  chatOptions,
  clientOptions,
  isClientLoading,
  onClientSearch,
}) {
  const { currUser, currCommunityId, onError } = useContext(AppContext);

  const assigneeOptions = currUser?.currCommunity?.members?.map(
    (data, index) => ({
      label: data.username,
      value: data.id?.toString(),
    })
  );

  const defaultAssignee = queryParams?.assignee?.split(",");

  const selectedAssignees = currUser?.currCommunity?.members
    ?.filter((item) => defaultAssignee?.includes(item.id.toString()))
    .map((item) => ({ value: item.id.toString(), label: item.username }));

  const defaultClient = queryParams?.client?.split(",");

  const selectedClient = clientOptions
    ?.filter((item) => defaultClient?.includes(item.value))
    .map((item) => ({ value: item.value, label: item.label }));

  return (
    <div className="text-xs">
      <div className="w-full flex justify-between items-center text-sm text-gray-700 !pb-4 mb-6 mt-2 border-b border-gray-300">
        <h3 className="main-title">Tickets</h3>

        <div className="flex items-center space-x-3">
          <NewIssueBtn onNewIssueBtnClick={onNewIssueBtnClick} />

          <TicketSettings onClosingAdditionalFields={getAllFields} />
        </div>
      </div>

      <div className="flex flex-wrap items-center gap-2">
        <SearchInput folderSearch={folderSearch} />

        <ChatFilter
          sortTicket={sortTicket}
          selectedChat={queryParams?.chat}
          chatOptions={chatOptions}
        />

        <PortalFilter
          sortTicket={sortTicket}
          selectedPortal={queryParams?.portal_id}
          portalOptions={portalOptions}
        />

        <AssigneeFilter
          assigneeOptions={assigneeOptions?.length ? assigneeOptions : []}
          selectedAssignees={selectedAssignees}
          sortTicket={sortTicket}
        />

        <ClientFilter
          selectedClient={selectedClient}
          clientOptions={clientOptions}
          sortTicket={sortTicket}
          isClientLoading={isClientLoading}
          onClientSearch={onClientSearch}
        />

        <StatusFilter
          selectedStatus={queryParams?.status}
          sortTicket={sortTicket}
        />

        <IsScheduleFilter
          selectedValue={queryParams?.isScheduler}
          sortTicket={sortTicket}
        />

        <PriorityFilter
          selectedPriority={queryParams?.priority}
          sortTicket={sortTicket}
        />

        <CreatedAtFilter
          sortTicket={sortTicket}
          created_at={queryParams?.created_at}
        />

        <AdditionalFieldsFilter
          currCommunityId={currCommunityId}
          sortTicket={sortTicket}
          customFields={queryParams?.customFields}
          onError={onError}
          commnuityFields={commnuityFields}
        />

        {showReset ? (
          <button
            className="flex items-center px-4 py-2 border-dotted border-2 border-blue-200 text-indigo-600 rounded-md hover:bg-gray-100 w-fit"
            onClick={onReset}
          >
            <i class="fa-solid fa-xmark mr-2"></i>
            Reset
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default TicketsHeader;

function SearchInput({ folderSearch }) {
  return (
    <span className="input-wrapper">
      <div className="input-suffix-start">
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth={0}
          viewBox="0 0 24 24"
          className="text-lg"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <input
        className="input input-sm h-9 outline-none focus:ring-1 focus:ring-blue-600"
        type="text"
        placeholder="Search"
        onChange={(e) => folderSearch(e.target.value)}
      ></input>
    </span>
  );
}

function ChatFilter({ sortTicket, selectedChat, chatOptions }) {
  const [open, setOpen] = useState(false);

  if (!chatOptions?.length) {
    return;
  }

  const selectedValue = chatOptions.find((c) => c.value === selectedChat);

  return (
    <FilterButton
      label={"Team"}
      selected={selectedValue ? [selectedValue] : null}
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          chat: "",
        });
      }}
    >
      {getRadioOptions(chatOptions)?.map((chat, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={chat.value || "none"}
            label={chat.label}
            handleChange={(e) => {
              sortTicket({
                chat: chat?.value,
              });
            }}
            isChecked={selectedChat === chat.value}
          />
        );
      })}
    </FilterButton>
  );
}

function PortalFilter({ sortTicket, selectedPortal, portalOptions }) {
  const [open, setOpen] = useState(false);

  if (!portalOptions?.length) {
    return;
  }

  const selectedValue = portalOptions.find((c) => c.value === selectedPortal);

  return (
    <FilterButton
      label={"Portal"}
      selected={selectedValue ? [selectedValue] : null}
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          portal_id: "",
        });
      }}
    >
      {getRadioOptions(portalOptions)?.map((portal, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={portal.value || "none"}
            label={portal.label}
            handleChange={(e) => {
              sortTicket({
                portal_id: portal?.value,
              });
            }}
            isChecked={selectedPortal === portal.value}
          />
        );
      })}
    </FilterButton>
  );
}

function ClientFilter({
  selectedClient,
  clientOptions,
  sortTicket,
  isClientLoading,
  onClientSearch,
}) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const searchClient = (value) => {
    setSearchValue(value);
    onClientSearch(value);
  };

  useEffect(() => {
    onClientSearch(searchValue);
  }, []);

  return (
    <FilterButton
      label={"Client"}
      pluralLabel="Client's"
      selected={selectedClient}
      open={open}
      setOpen={setOpen}
      showPluralLabel={true}
      onClear={(e) => {
        sortTicket({
          client: "",
        });
      }}
    >
      <span className="p-2 w-full input-wrapper ">
        <div className="input-suffix-start">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={0}
            viewBox="0 0 24 24"
            className="text-lg"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <input
          className="input search-new-chatbot input-sm h-8  focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 w-full"
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={(e) => searchClient(e.target.value)}
        ></input>
      </span>
      {!isClientLoading ? (
        clientOptions?.map((client, i) => {
          return (
            <SelectFilterOptionCheckbox
              key={i}
              id={client.value}
              label={client.label}
              handleCheckboxChange={(e) => {
                let clients = selectedClient || [];

                if (e.target.checked) {
                  clients.push(client);
                } else {
                  clients = clients.filter((a) => a.value !== client.value);
                }

                sortTicket({
                  client: clients.map((option) => option.value).join(","),
                });
              }}
              isChecked={
                selectedClient?.find((a) => a.value === client.value)
                  ? true
                  : false
              }
            />
          );
        })
      ) : (
        <div className="w-[200px] flex justify-center">
          <Loading />
        </div>
      )}
    </FilterButton>
  );
}

function AssigneeFilter({ assigneeOptions, selectedAssignees, sortTicket }) {
  const [open, setOpen] = useState(false);

  return (
    <FilterButton
      label={"Assignee"}
      pluralLabel="Assignee's"
      selected={selectedAssignees}
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          assignee: "",
        });
      }}
    >
      {assigneeOptions?.map((assignee, i) => {
        return (
          <SelectFilterOptionCheckbox
            key={i}
            id={assignee.value}
            label={assignee.label}
            handleCheckboxChange={(e) => {
              let assignees = selectedAssignees || [];

              if (e.target.checked) {
                assignees.push(assignee);
              } else {
                assignees = assignees.filter((a) => a.value !== assignee.value);
              }

              sortTicket({
                assignee: assignees.map((option) => option.value).join(","),
              });
            }}
            isChecked={
              selectedAssignees.find((a) => a.value === assignee.value)
                ? true
                : false
            }
          />
        );
      })}
    </FilterButton>
  );
}

function StatusFilter({ sortTicket, selectedStatus }) {
  const [open, setOpen] = useState(false);

  return (
    <FilterButton
      label={"Status"}
      selected={
        selectedStatus
          ? [{ label: selectedStatus, value: selectedStatus }]
          : null
      }
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          status: "",
        });
      }}
    >
      {getRadioOptions(statusOptions)?.map((status, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={status.value || "none"}
            label={status.label}
            handleChange={(e) => {
              sortTicket({
                status: status?.value,
              });
            }}
            isChecked={selectedStatus === status.value}
          />
        );
      })}
    </FilterButton>
  );
}

function IsScheduleFilter({ sortTicket, selectedValue }) {
  const [open, setOpen] = useState(false);

  return (
    <FilterButton
      label={"Scheduled Tickets"}
      selected={
        selectedValue ? [{ label: selectedValue, value: selectedValue }] : null
      }
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          isScheduler: "",
        });
      }}
    >
      {getRadioOptions(isSchedulerOptions)?.map((data, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={data.value || "none"}
            label={data.label}
            handleChange={(e) => {
              sortTicket({
                isScheduler: data?.value,
              });
            }}
            isChecked={selectedValue === data.value}
          />
        );
      })}
    </FilterButton>
  );
}

function PriorityFilter({ sortTicket, selectedPriority }) {
  const [open, setOpen] = useState(false);

  return (
    <FilterButton
      label={"Priority"}
      selected={
        selectedPriority
          ? [{ label: selectedPriority, value: selectedPriority }]
          : null
      }
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          priority: "",
        });
      }}
    >
      {getRadioOptions(priorityOptions)?.map((priority, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={priority.value || "none"}
            label={priority.label}
            handleChange={(e) => {
              sortTicket({
                priority: priority?.value,
              });
            }}
            isChecked={selectedPriority === priority.value}
          />
        );
      })}
    </FilterButton>
  );
}

function CreatedAtFilter({ sortTicket, created_at }) {
  const [open, setOpen] = useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [formattedDateObject, setFormattedDateObject] = useState();

  const parsedDate = created_at
    ? moment(created_at, "YYYY-MM-DD").toDate()
    : new Date();

  useEffect(() => {
    if (created_at) {
      const created_at_data = JSON.parse(created_at);
      setFormattedDateObject(created_at_data);

      const startDate = new Date(
        created_at_data?.fromDate.split("/").reverse().join("-")
      );

      const endDate = new Date(
        created_at_data?.toDate.split("/").reverse().join("-")
      );

      setDateRange([
        {
          startDate,
          endDate,
          key: "selection",
        },
      ]);
    }
  }, [created_at]);

  useEffect(() => {
    if (dateRange) {
      if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
        var payload = {
          fromDate: new Date(dateRange[0].startDate).toLocaleDateString(
            "en-GB",
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }
          ),
          toDate: new Date(dateRange[0].endDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }),
        };
        sortTicket({
          created_at: JSON.stringify(payload),
        });
      }
    }
  }, [dateRange]);

  return (
    <div>
      <FilterButton
        label={"Created at"}
        childrenCls="top-0"
        position="right-0"
        selected={
          created_at
            ? [
                {
                  label:
                    formattedDateObject?.fromDate +
                    " to " +
                    formattedDateObject?.toDate,
                  value: created_at,
                },
              ]
            : null
        }
        open={open}
        setOpen={setOpen}
        noScroll={true}
        id="createAt-fields"
        onClear={(e) => {
          sortTicket({
            created_at: "",
          });
        }}
      >
        {/* <Calendar
          date={parsedDate}
          onChange={(value) => {
            const formattedDate = moment(value).format("YYYY-MM-DD");

            sortTicket({
              created_at: formattedDate,
            });
          }}
        /> */}
        <Tooltip
          anchorSelect="#createAt-fields"
          place="bottom-end"
          style={{
            zIndex: 99,
            background: "#fff",
            color: "black",
            padding: "1px",
            opacity: 1,
            fontSize: "0.75rem",
          }}
          clickable
          isOpen={open}
          opacity={1}
        >
          <DateRangePicker
            onChange={(item) => setDateRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateRange}
            opens="center"
            direction="vertical"
          />
        </Tooltip>
      </FilterButton>
    </div>
  );
}

function NewIssueBtn({ onNewIssueBtnClick }) {
  return (
    <button
      className="flex items-center bg-primary-600 hover:bg-primary-500 text-white text-[14px] px-8 py-2.5 rounded-md"
      onClick={onNewIssueBtnClick}
    >
      <img src="/images/icons/add.svg" alt="+" className="mr-2 w-5" />
      New Ticket
    </button>
  );
}

function displayAdditionalFields(fieldsData, commnuityFields) {
  if (!Object.keys(fieldsData)?.length) {
    return null;
  }

  const displayData = [];

  Object.entries(fieldsData).forEach(([key, value]) => {
    const { field_name } =
      commnuityFields.find((c) => c.id.toString() === key) || {};

    if (typeof value === "string") {
      // for input and select field

      const display = `${field_name}: ${value}`;

      displayData.push({ label: display, value: display });
    } else {
      // for dependency dropdown

      let display = [];

      Object.entries(value).forEach(([levelKey, levelValue]) => {
        const refactor = `${field_name[levelKey]}: ${levelValue}`;

        display.push(refactor);
      });

      const displayDataRefactor = display.join(", ");

      displayData.push({
        label: displayDataRefactor,
        value: displayDataRefactor,
      });
    }
  });

  return displayData;
}

function AdditionalFieldsFilter({
  currCommunityId,
  sortTicket,
  customFields,
  onError,
  commnuityFields,
}) {
  const [open, setOpen] = useState(false);

  const commnuityFieldsData = useMemo(() => {
    return customFields ? JSON.parse(customFields) : {};
  }, [customFields]);

  // const { data, mutate, loading } = useApiData({
  //   onError: (error) =>
  //     onError(
  //       error?.response?.data?.message ||
  //         "Something went wrong while getting community fields"
  //     ),
  // });

  // useEffect(() => {
  //   mutate(() =>
  //     api.post("/api/getCommunityFields", { community_id: currCommunityId })
  //   );
  // }, []);

  // const commnuityFields = data?.data || [];

  function updateCommnuityFieldsData(id, data, field) {
    const prevData = { ...commnuityFieldsData };

    if (data) {
      prevData[id] = data;
    } else {
      delete prevData[id];
    }

    const customFieldsData = Object.keys(prevData)?.length
      ? JSON.stringify(prevData)
      : null;

    sortTicket({
      customFields: customFieldsData,
    });
  }

  // if (loading) {
  //   return <Loading />;
  // }

  if (!commnuityFields?.length) {
    return;
  }

  return (
    <FilterButton
      label="Additional Fields"
      pluralLabel="Fields"
      selected={displayAdditionalFields(commnuityFieldsData, commnuityFields)}
      open={open}
      setOpen={setOpen}
      noScroll={true}
      childrenCls="min-w-[350px]"
      position="right-0"
      dontCloseOnReactSelect={true}
      id="additional-fields"
      onClear={(e) => {
        sortTicket({
          customFields: "",
        });
      }}
    >
      <Tooltip
        anchorSelect="#additional-fields"
        place="bottom-end"
        style={{
          zIndex: 99,
          background: "#fff",
          color: "black",
          padding: "1px",
          opacity: 1,
          fontSize: "0.75rem",
        }}
        clickable
        isOpen={open}
        opacity={1}
      >
        <div className="grid grid-cols-2 gap-4 p-4 border">
          {commnuityFields.map((c, i) => {
            return (
              <AdditionalFieldsForm
                key={i}
                field={c}
                updateCommnuityFieldsData={updateCommnuityFieldsData}
                commnuityFieldsData={commnuityFieldsData}
              />
            );
          })}
        </div>
      </Tooltip>
    </FilterButton>
  );
}

function AdditionalFieldsForm({
  field,
  commnuityFieldsData,
  updateCommnuityFieldsData,
}) {
  const { field_name, field_type, field_values, id } = field;

  const value = commnuityFieldsData[id];

  if (field_type === "input") {
    return (
      <Input
        label={field_name}
        onChange={(e) => updateCommnuityFieldsData(id, e.target.value, field)}
        value={value}
      />
    );
  }

  if (field_type === "select") {
    const options = field_values?.map((f) => ({ label: f, value: f }));

    return (
      <div className="">
        <div className="mb-2 font-semibold text-gray-700">{field_name}</div>

        <Select
          options={options}
          isClearable
          value={value ? { label: value, value: value } : null}
          onChange={(value) => {
            updateCommnuityFieldsData(id, value?.value, field);
          }}
        />
      </div>
    );
  }

  if (field_type === "dependency_dropdown") {
    return (
      <DependencyDropdown
        field={field}
        selectedValues={value}
        handleChange={(val) => {
          const value = Object.keys(val).length ? val : null;

          updateCommnuityFieldsData(id, value, field);
        }}
        fromApprovalPage={true}
      />
    );
  }

  return "Unknown Type";
}

function Input(props) {
  const { label, disabled, value, onChange = () => {}, required } = props || {};

  return (
    <div className="">
      <div className="mb-2 font-semibold text-gray-700">{label}</div>

      <input
        type="text"
        className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 ${
          disabled ? "cursor-not-allowed bg-gray-200" : ""
        }`}
        disabled={disabled}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  );
}
