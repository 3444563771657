import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../App";

const SideBar = () => {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();

  const { currCommunityId, currUser } = useContext(AppContext);

  const { role } = currUser?.currCommunity || {};

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  return (
    <div className="w-[15%] border-r border-gray-300 min-h-[86vh]">
      <div className="text-center font-semibold text-lg mb-2 mt-6">
        Settings
      </div>

      <div className="background[#fff] rounded block">
        <div className="settingList p-3">
          <Link
            to="/settings/profile"
            className={`${
              activeItem === "/settings/profile"
                ? "settingList__item_active"
                : ""
            } flex items-center w-full p-3 rounded settingList__item rounded-md`}
            onClick={() => setActiveItem("/settings/profile")}
          >
            My profile
          </Link>

          {role === 3 ? null : (
            <Link
              to={`/settings/community/${currCommunityId}`}
              className={`${
                activeItem === `/settings/community/${currCommunityId}`
                  ? "settingList__item_active"
                  : ""
              } flex items-center w-full p-3 rounded settingList__item`}
              onClick={() =>
                setActiveItem(`/settings/community/${currCommunityId}`)
              }
            >
              Community
            </Link>
          )}
          <Link
            to="/settings/integrators"
            className={`${
              activeItem === "/settings/integrators"
                ? "settingList__item_active"
                : ""
            } flex items-center w-full p-3 rounded settingList__item`}
            onClick={() => setActiveItem("/settings/integrators")}
          >
            Integrations
          </Link>
          {/* <Link
            to="/settings/notifications"
            className={`${
              activeItem === "/settings/notifications"
                ? "settingList__item_active"
                : ""
            } flex items-center w-full p-3 rounded settingList__item`}
            onClick={() => setActiveItem("/settings/notifications")}
          >
            Notifications
          </Link> */}
          <Link
            to="/settings/portals"
            className={`${
              activeItem?.includes("/settings/portals")
                ? "settingList__item_active"
                : ""
            } flex items-center w-full p-3 rounded settingList__item`}
            onClick={() => setActiveItem("/settings/portals")}
          >
            Support portals
          </Link>
          <Link
            to="/settings/pricing-plan"
            className={`${
              activeItem?.includes("/settings/pricing-plan")
                ? "settingList__item_active"
                : ""
            } flex items-center w-full p-3 rounded settingList__item`}
            onClick={() => setActiveItem("/settings/portals")}
          >
            My Plan
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
