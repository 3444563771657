import { useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Insert from "../common/Insert";
import {
  customReactSelectStyles,
  getReactSelectOptions,
  getSelectedValue,
} from "../../../../common/commonHelpers";
import DefaultInsertOptions from "../common/DefaultInsertOptions";
import FormApiOptions from "./FormApiOptions";

const inputTypes = ["input", "number", "textarea", "password", "select"];

function getDefaultInputVal() {
  return {
    type: "",
    name: "",
  };
}

function FormResponseType({
  onSubmitForm,
  dialogResponses,
  isDisabled,
  error,
}) {
  const { configurationId } = dialogResponses;

  const { apiName } = configurationId || {};

  return (
    <>
      <div className="mt-4 text-sm flex items-center space-x-2">
        <Insert
          disabled={isDisabled}
          label={
            <button className="px-4 py-2 rounded-md bg-indigo-600 text-white">
              On submit
            </button>
          }
          optionsCls={"left-28"}
        >
          <FormApiOptions onSubmitForm={onSubmitForm} />
        </Insert>

        {apiName ? <div className="w-20 truncate">: {apiName}</div> : null}
      </div>

      {error ? <div className="my-2 text-red-600 text-sm">{error}</div> : null}
    </>
  );
}

export default FormResponseType;
