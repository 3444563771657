import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../../api/api";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import SignInCommon from "../../components/common/SignInCommon";
import SignInWithGoogle from "../../components/common/SignInWithGoogle";
const SignUp = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [formError, setFormError] = useState("");
  const [processing, setProcessing] = useState(false);
  const location = useNavigate();
  const { currUser, getUserInfo, setToastMessage, setShowToast } =
    useContext(AppContext);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const validation = (name, value, error = null) => {
    if (name === "email") {
      if (error != null) {
        setEmailError(error);
      } else {
        if (value !== "") {
          setEmail(value);
          setEmailError("");
        } else {
          setEmail("");
          setEmailError("Please enter your email");
        }
      }
    }

    if (name === "password") {
      if (error != null) {
        setPasswordError(error);
      } else {
        if (value !== "") {
          setPasswordError("");
          setPassword(value);
        } else {
          setPassword("");
          setPasswordError("Please enter your password");
        }
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    validation(e.target.name, e.target.value);
  };

  const handleForm = (e) => {
    e.preventDefault();
    setProcessing(true);
    var requestOptions = {
      email: email,
      password: password,
    };

    api
      .post("/api/auth/login", requestOptions)
      .then((response) => {
        if (response.data.status === "error") {
          setFormError(response.data.message);
        }
        if (response.data.status === "success") {
          localStorage.clear();

          localStorage.setItem("jwtToken", response.data.authorisation.token);

          if (response.data.origin) {
            const origin = window.location.host.includes("localhost")
              ? "http://localhost:3000"
              : response.data.origin;

            localStorage.setItem("origin", origin);
          }

          location("/communities");
        }
        // console.log(response.data);
        setProcessing(false);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
        setProcessing(false);
      });
  };

  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-4 h-full">
        <SignInCommon />
        <div className="col-span-2 flex flex-col justify-center items-center bg-[#F4F7FC]">
          <div className="xl:min-w-[550px] px-8">
            <img src="/images/dckap-desk-logo.svg" alt="RhinoAssist" />

            <div className="mt-12 mb-8">
              <h3 className="mb-1 text-3xl text-slate-800 font-semibold font-['Inter']">
                Hello!
              </h3>
              <p className="text-slate-700 text-sm font-['Inter']">
                Please enter your Details to Login!
              </p>
            </div>
            <div>
              <form action="#" method="post" onSubmit={handleForm}>
                <div className="form-container signin-form vertical">
                  <div className="form-item vertical">
                    <label className="form-label mb-1">Email</label>
                    <div className>
                      <input
                        className={`${
                          emailError !== "" || formError !== ""
                            ? "input-danger"
                            : ""
                        } p-3 w-full input text-sm font-['Inter'] input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow input-light`}
                        type="text"
                        name="email"
                        autoComplete="off"
                        placeholder="Email"
                        onChange={(e) => handleChange(e)}
                      />
                      {emailError !== "" ? (
                        <div className="form-error">{emailError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-1">Password</label>
                    <div className>
                      <span className="input-wrapper w-full">
                        <input
                          className={`${
                            passwordError !== "" || formError !== ""
                              ? "input-danger"
                              : ""
                          } p-3 input w-full text-sm font-['Inter'] input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow input-light`}
                          type={passwordType}
                          name="password"
                          autoComplete="off"
                          placeholder="Password"
                          style={{ paddingRight: "2.25rem" }}
                          onChange={(e) => handleChange(e)}
                        />
                        <div className="input-suffix-end">
                          <span className="cursor-pointer text-base">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth={0}
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={togglePassword}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              />
                            </svg>
                          </span>
                        </div>
                      </span>
                      {passwordError !== "" ? (
                        <div className="form-error">{passwordError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {formError !== "" ? (
                    <div className="mb-5 form-error">{formError}</div>
                  ) : (
                    ""
                  )}
                  <button
                    className="mt-2 grat-btn inline-flex font-['Inter'] items-center justify-center px-4 py-2 font-medium text-base leading-6 shadow rounded text-white hover:shadow-lg h-11 transition ease-in-out duration-150 w-full button_animi"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Log In
                  </button>
                  <div className="my-8 text-center">
                    <span>Want to reset your password? </span>
                    <Link
                      className="text-blue-700 border-b border-blue-500"
                      to={`/forgot-password`}
                    >
                      Forgot Password
                    </Link>
                  </div>
                  <div className="divider my-2">
                    <div className="divider-text font-['Inter']">or</div>
                  </div>
                  {/* <SignInWithGoogle /> */}
                  <div className="mt-8 text-center font-['Inter']">
                    <span>Don't have an account yet? </span>
                    <Link
                      className="text-blue-700 border-b border-blue-500"
                      to={`/signup`}
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
