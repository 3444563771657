import { useCallback, useContext, useMemo } from "react";
import { AppContext } from "../../../App";
import api from "../../../api/api";

function usePortals() {
  const { currCommunityId } = useContext(AppContext);

  const basePath = useMemo(() => {
    return `/api`;
  }, []);

  const getPortals = useCallback(() => {
    const url = basePath + `/getPortals/${currCommunityId}`;

    return () => api.get(url);
  }, [basePath, currCommunityId]);

  const getPortal = useCallback(
    (id) => {
      const url = basePath + `/getPortal/${currCommunityId}/${id}`;

      return () => api.get(url);
    },
    [basePath, currCommunityId]
  );

  const createPortal = useCallback(
    (payload) => {
      const url = basePath + `/createPortal/${currCommunityId}`;

      return () => api.post(url, payload);
    },
    [basePath, currCommunityId]
  );

  const updatePortal = useCallback(
    (id, payload) => {
      const url = basePath + `/updatePortal/${currCommunityId}/${id}`;

      return () => api.post(url, payload);
    },
    [basePath, currCommunityId]
  );

  const deletePortal = useCallback(
    (id) => {
      const url = basePath + `/deletePortal/${currCommunityId}/${id}`;

      return () => api.delete(url);
    },
    [basePath, currCommunityId]
  );

  const deleteAdditionalLink = useCallback(
    (portal_id, id) => {
      const url = basePath + `/deleteAdditionalLink/${portal_id}/${id}`;

      return () => api.delete(url);
    },
    [basePath, currCommunityId]
  );

  return {
    getPortals,
    getPortal,
    createPortal,
    updatePortal,
    deletePortal,
    deleteAdditionalLink,
  };
}

export default usePortals;
