import React, { useState } from "react";
import OfflineUsersStatusSelect from "./OfflineUsersStatusSelect";
import moment from "moment";
import Spinner from "../../loaders/Spinner";
import Modal from "../../common/Modal";
import MailForm from "./MailForm";

function getReadableDate(date) {
  if (!date) {
    return "";
  }

  return moment(date).format("MMMM DD, YYYY");
}

function getColumns({ onMail }) {
  return [
    { label: "Name", displayData: (data) => data.name },
    { label: "Email", displayData: (data) => data.email },
    { label: "Message", displayData: (data) => data.message },
    {
      label: "Contacted At",
      displayData: (data) => getReadableDate(data.created_at),
    },
    {
      label: "Mail",
      displayData: (data) => (
        <i
          className="fa-solid fa-envelope ml-2 cursor-pointer hover:text-blue-600 text-xl"
          onClick={() => onMail(data)}
        ></i>
      ),
    },
  ];
}

function OfflineUsersTable({ data, updateUser, updateLoading, selectedUser }) {
  const [openMail, setOpenMail] = useState(null);

  const columns = getColumns({ onMail: (data) => setOpenMail(data) });

  return (
    <div className="mt-8">
      <table class="table-auto w-full text-sm text-left text-gray-500 overflow-x-scroll border border-gray-300">
        <thead className="text-gray-700 uppercase bg-[#E3E9F3] issue-list-title">
          <tr className="border-b border-gray-300">
            {columns?.map((col, i) => {
              return (
                <th key={i} className="py-3 cursor-pointer px-2">
                  {col.label}
                </th>
              );
            })}

            <th className="py-3 cursor-pointer px-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {data
            ? data?.data?.map((userData, i) => {
                const userId = userData?.uuid;

                return (
                  <tr key={i} className="border-b border-gray-300">
                    {columns?.map((col, i) => {
                      return (
                        <td key={i} className="py-4 px-2">
                          {col.displayData(userData)}
                        </td>
                      );
                    })}

                    <td className="py-4 px-2 w-40">
                      {updateLoading && selectedUser === userId ? (
                        <Spinner customStyle={{ height: 20, weight: 20 }} />
                      ) : (
                        <OfflineUsersStatusSelect
                          defaultValue={userData.status}
                          onChange={(selectedOption) => {
                            const value = selectedOption?.value;

                            if (value === undefined) {
                              return;
                            }

                            updateUser(userId, value);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })
            : "No data Found"}
        </tbody>
      </table>

      <Modal isOpen={openMail} onClose={() => setOpenMail(null)} title="Mail">
        <MailForm user={openMail} onClose={() => setOpenMail(null)} />
      </Modal>
    </div>
  );
}

export default OfflineUsersTable;
