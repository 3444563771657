import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const ComposeEmail = (props) => {
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [messageId, setMessageId] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [processing, setProcessing] = useState(false);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);

  var formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula",
    // 'image'
    // 'video'
  ];
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline"],
        ["code-block"],
      ],
    },
    placeholder: "Compose an epic...",
    theme: "snow", // or 'bubble'
    formats: formats,
  });
  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        setBody(quill.root.innerHTML);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (props.to) {
      setTo(props.to);
    }
    if (props.cc) {
      setCc(props.cc);
    }
    if (props.messageId) {
      setMessageId(props.messageId);
    }
    if (props.referenceId) {
      setReferenceId(props.referenceId);
    }
    if (props.subject) {
      setSubject(props.subject);
    }
    // console.log(props);
  }, []);

  const handleAttachmentChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("to", to);
    formData.append("cc", cc);
    formData.append("bcc", bcc);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("community_id", currCommunityId);
    formData.append("messageId", messageId);
    formData.append("reference_id", referenceId);

    // Create an array to store the promises for attachment processing
    const attachmentPromises = attachments.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1]; // Extract the base64 data (remove the data:image/xxx;base64 part)
          const mimeType = fileData.match(/^data:(.*?);/)[1]; // Extract the MIME type
          formData.append(
            "attachments[]",
            JSON.stringify({
              data: base64Data,
              type: mimeType,
            })
          );
          // console.log("some1", formData);
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      // Wait for all attachment promises to resolve
      await Promise.all(attachmentPromises);
      // console.log("some");
      setProcessing(true);
      // All attachments have been processed, trigger the email
      const response = await api
        .post("/api/sendEmail", formData)
        .then((response) => {
          setProcessing(false);
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Mail send successfully",
          });
        });
      setAttachments([]);
      window.location.reload();
    } catch (error) {
      setProcessing(false);

      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  return (
    <div
      id="defaultModal"
      tabIndex={-1}
      className={`compose-mail dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
    >
      <div className="mx-auto relative w-full h-full max-w-3xl	 md:h-auto">
        <div className="relative dialog-content bg-white rounded-lg shadow">
          <div className="text-center flex items-start justify-between p-4 rounded-t">
            <div>
              <h3 className="text-xl font-semibold text-gray-900  ">
                Compose Mail
              </h3>
            </div>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
              data-modal-hide="defaultModal"
              onClick={() => {
                props.handleComposeModal(false);
              }}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="px-6">
              <div className="mt-4">
                <div className="form-container vertical">
                  <div className="form-item w-full vertical">
                    <label className="form-label mb-2">To:</label>
                    <input
                      type="text"
                      id="to"
                      className="p-3 w-full input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                    />
                  </div>
                  <div className="flex gap-2">
                    <div className="form-item w-full  vertical">
                      <label className="form-label mb-2">CC:</label>
                      <input
                        type="text"
                        id="cc"
                        value={cc}
                        className="p-3 w-full input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        onChange={(e) => setCc(e.target.value)}
                      />
                    </div>
                    <div className="form-item w-full  vertical">
                      <label className="form-label mb-2">BCC:</label>
                      <input
                        type="text"
                        id="bcc"
                        value={bcc}
                        className="p-3 w-full input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        onChange={(e) => setBcc(e.target.value)}
                      />
                    </div>
                  </div>
                  {!referenceId && (
                    <div className="form-item vertical">
                      <label className="form-label mb-2 required-input">
                        Subject:
                      </label>
                      <input
                        type="text"
                        id="subject"
                        value={subject}
                        className="p-3 w-full input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        onChange={(e) => setSubject(e.target.value)}
                        required
                      />
                    </div>
                  )}
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Body:</label>
                    {/* <textarea
                      id="body"
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      className="input-textarea input-form input input-invalid input-textarea"
                    ></textarea> */}
                    <div ref={quillRef} />
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Attachments:</label>
                    <input
                      type="file"
                      id="attachments"
                      multiple
                      onChange={handleAttachmentChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
              <button
                className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                type="submit"
                disabled={processing}
              >
                {processing && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}{" "}
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ComposeEmail;
