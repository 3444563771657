import { useState } from "react";
import DropDown from "../common/DropDown";

function CmSettings({ onManageFields, onMailTemplates }) {
  return (
    <div className="relative">
      <DropDown
        label={
          <div className="cursor-pointer bg-indigo-50 hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round h-9 px-3 py-2 text-sm rounded-md">
            <i className="fa-solid fa-gear"></i>
          </div>
        }
      >
        <div
          className="flex items-center space-x-4 cursor-pointer hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round px-4 py-3 text-sm rounded-md whitespace-nowrap"
          onClick={onManageFields}
        >
          <i className="fa-solid fa-bars-progress"></i>
          <div>Manage Fields</div>
        </div>

        <div
          className="flex items-center space-x-4 cursor-pointer hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round px-4 py-3 text-sm rounded-md whitespace-nowrap border-t"
          onClick={onMailTemplates}
        >
          <i className="fa-solid fa-envelope-open-text"></i>
          <div>Campaign Templates</div>
        </div>
      </DropDown>

      {/* {modal === "fields" && (
        <TicketAdditionalFieldsContainer
          modal={modal}
          setModal={setModal}
          onClosingAdditionalFields={onClosingAdditionalFields}
        />
      )}

      {modal === "approval" && (
        <ManageApproval modal={modal} setModal={setModal} />
      )} */}
    </div>
  );
}

export default CmSettings;
