import React, { useContext, useEffect, useRef, useState } from "react";
import SelectDateRange from "../common/SelectDateRange";
import Spinner from "../loaders/Spinner";
import FilterButton, { SelectFilterOptionRadio } from "../common/FilterButton";
import {
  AdditionalFieldsFilter,
  AssigneeFilter,
  ClientFilter,
  PortalFilter,
  PriorityFilter,
  StatusFilter,
} from "../tickets/TicketFilters";
import { AppContext } from "../../App";

const memberStatusOptions = [
  {
    label: "Active",
    value: "1",
  },
  {
    label: "In Active",
    value: "0",
  },
];

const MAP_FILTERS = {
  tickets: FilterTickets,
  memberTickets: FilterMemberTickets,
};

function DashboardCard({
  children,
  title,
  onDateRangeApply,
  defaultDateRange,
  setDefaultDateRange,
  isNotFound,
  notFoundLabel,
  loading,
  teamOptions,
  selectedTeam,
  onTeamChange,
  tab,
  setTab,
  filterType,
  filter,
  setFilter,
  onResetFilter,
  uniqueId,
}) {
  const { navToggle } = useContext(AppContext);

  const FilterComponent = MAP_FILTERS[filterType];

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(null);

  useEffect(() => {
    // getting the parent width while side bar toggle's
    // we are using this parent width in chart's

    if (parentRef.current) {
      const width = parentRef.current.offsetWidth;
      setParentWidth(width);
    }
  }, [navToggle]);

  return (
    <div className="p-4 border border-gray-300 rounded-md h-[550px] bg-white flex flex-col">
      <div className="flex items-center justify-between mb-10 relative">
        <div className="flex items-center space-x-4 mr-4">
          <div className="text-lg font-semibold">{title}</div>

          {tab ? (
            <ChangeViewTabs
              activeTab={tab}
              onTabChange={(selectedTab) => {
                setTab(selectedTab);
              }}
            />
          ) : null}
        </div>

        <div className="flex items-center space-x-4 text-sm">
          {filterType === "memberProductivity" ? (
            <MemberStatusFilter
              selectedStatus={filter?.status}
              onMemberStatusChange={(status) => {
                setFilter(status);
              }}
            />
          ) : null}

          {filterType === "memberProductivity" ? null : (
            <TeamFilter
              teamOptions={teamOptions}
              selectedTeam={selectedTeam}
              onTeamChange={onTeamChange}
            />
          )}

          <SelectDateRange
            onApply={(dateRange) => {
              onDateRangeApply(dateRange);
            }}
            defaultDateRange={defaultDateRange}
            setDefaultDateRange={setDefaultDateRange}
            uniqueId={"analytics" + uniqueId}
          />

          {filterType === "myTickets" ? (
            <a
              href={`/tickets`}
              className="px-4 py-2.5 text-indigo-600 border border-gray-300 rounded-lg font-medium text-sm hover:bg-gray-100"
              target="_blank"
              rel="noreferrer"
            >
              View All
            </a>
          ) : null}
        </div>
      </div>

      {FilterComponent ? (
        <FilterComponent
          filter={filter}
          sortTicket={setFilter}
          onResetFilter={onResetFilter}
        />
      ) : null}

      {loading ? (
        <div className="flex justify-center items-center h-[50%]">
          <Spinner />
        </div>
      ) : null}

      {isNotFound && !loading ? (
        <div className="text-center mt-12 font-semibold text-lg text-gray-500">
          {notFoundLabel}
        </div>
      ) : null}

      <div className="h-[90%] overflow-y-auto w-full" ref={parentRef}>
        {parentWidth && !isNotFound && !loading
          ? React.cloneElement(children, { parentRef, parentWidth })
          : null}
      </div>
    </div>
  );
}

export default DashboardCard;

function getRadioOptions(options) {
  return [...options, { label: "Clear", value: "" }];
}

function TeamFilter({ onTeamChange, selectedTeam, teamOptions }) {
  const [open, setOpen] = useState(false);

  if (!teamOptions?.length) {
    return;
  }

  const selectedValue = teamOptions.find((c) => c.value === selectedTeam);

  return (
    <FilterButton
      label={"Team"}
      selected={selectedValue ? [selectedValue] : null}
      open={open}
      setOpen={setOpen}
    >
      {getRadioOptions(teamOptions)?.map((team, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={team.value || "none"}
            label={team.label}
            handleChange={(e) => {
              onTeamChange(team?.value, team);
            }}
            isChecked={selectedTeam === team.value}
          />
        );
      })}
    </FilterButton>
  );
}

function MemberStatusFilter({ onMemberStatusChange, selectedStatus }) {
  const [open, setOpen] = useState(false);

  const selectedValue = memberStatusOptions.find(
    (c) => c.value === selectedStatus
  );

  return (
    <FilterButton
      label={"Status"}
      selected={selectedValue ? [selectedValue] : null}
      open={open}
      setOpen={setOpen}
    >
      {getRadioOptions(memberStatusOptions)?.map((status, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={status.value || "none"}
            label={status.label}
            handleChange={(e) => {
              onMemberStatusChange({
                status: status?.value,
              });
            }}
            isChecked={selectedStatus === status.value}
          />
        );
      })}
    </FilterButton>
  );
}

const tabs = [
  {
    iconCls: "fa-solid fa-chart-simple",
    value: "chart",
  },
  {
    iconCls: "fa-solid fa-table",
    value: "table",
  },
];

function ChangeViewTabs({ activeTab, onTabChange }) {
  return (
    <div className="flex items-center border border-gray-300 rounded-md w-fit text-sm">
      {tabs.map((tab, i) => {
        const { value, iconCls } = tab;

        const isActive = value === activeTab;

        return (
          <button
            className={`px-4 py-2 rounded-md ${
              isActive ? "text-white bg-indigo-600" : ""
            }`}
            onClick={() => onTabChange(value)}
            key={i}
          >
            <i className={iconCls}></i>
          </button>
        );
      })}
    </div>
  );
}

function FilterTickets({ filter, sortTicket, onResetFilter }) {
  const { priority, assignee } = filter;

  const showReset = priority || assignee;

  return (
    <div className="flex flex-wrap items-center gap-4 text-[12px] mb-6 -mt-4">
      <AssigneeFilter assignee={assignee || ""} sortTicket={sortTicket} />

      <PriorityFilter
        selectedPriority={priority || ""}
        sortTicket={sortTicket}
      />

      {showReset ? (
        <button
          className="flex items-center px-4 py-2 border-dotted border-2 border-blue-200 text-indigo-600 rounded-md hover:bg-gray-100 w-fit"
          onClick={onResetFilter}
        >
          <i class="fa-solid fa-xmark mr-2"></i>
          Reset
        </button>
      ) : null}
    </div>
  );
}

function FilterMemberTickets({ filter, sortTicket, onResetFilter }) {
  const { priority, status, portal_id, client, customFields } = filter;

  const showReset = priority || status || portal_id || client || customFields;

  return (
    <div className="flex flex-wrap items-center gap-4 text-[12px] mb-6 -mt-4">
      <PriorityFilter
        selectedPriority={priority || ""}
        sortTicket={sortTicket}
      />

      <StatusFilter selectedStatus={status || ""} sortTicket={sortTicket} />

      <PortalFilter selectedPortal={portal_id || ""} sortTicket={sortTicket} />

      <ClientFilter client={client || ""} sortTicket={sortTicket} />

      <AdditionalFieldsFilter
        customFields={customFields}
        sortTicket={sortTicket}
      />

      {showReset ? (
        <button
          className="flex items-center px-4 py-2 border-dotted border-2 border-blue-200 text-indigo-600 rounded-md hover:bg-gray-100 w-fit"
          onClick={onResetFilter}
        >
          <i class="fa-solid fa-xmark mr-2"></i>
          Reset
        </button>
      ) : null}
    </div>
  );
}
