import { FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";

const imageExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "tif",
  "tiff",
  "webp",
  "svg",
  "eps",
];

const excelExtensions = [
  "xls",
  "xlsx",
  "xlsm",
  "xlsb",
  "xlt",
  "xltx",
  "xltm",
  "ods",
];

const wordDocumentExtensions = [
  "doc",
  "docx",
  "docm",
  "dot",
  "dotx",
  "dotm",
  "odt",
  "rtf",
  "txt",
];

const pdfExtensions = ["pdf"];

function getFileType(attachment = {}) {
  const { fileExtension } = attachment;

  if (imageExtensions.includes(fileExtension)) {
    return "image";
  }

  if (excelExtensions.includes(fileExtension)) {
    return "excel";
  }

  if (wordDocumentExtensions.includes(fileExtension)) {
    return "word";
  }

  if (pdfExtensions.includes(fileExtension)) {
    return "pdf";
  }

  return "";
}

function ImageThumpnail({ attachment }) {
  return (
    <img
      src={attachment?.fileData}
      alt="attachment"
      className="w-12 h-12 rounded-lg shadow-lg border border-gray-300 p-2"
    />
  );
}

const MAP_ICONS = {
  excel: {
    Icon: FaFileExcel,
    color: "green",
  },
  pdf: {
    Icon: FaFilePdf,
    color: "#FF0080",
  },
  word: {
    Icon: FaFileWord,
    color: "#00559c",
  },
};

function ExcelPdfDocThumpnail({ attachment, type }) {
  const { Icon, color } = MAP_ICONS[type] || {};
  return (
    <div className="flex border text-xs space-x-2 items-center p-2 rounded-lg border-gray-300">
      <Icon color={color} className="w-8 h-8" />

      <div className="w-20 truncate border-l border-gray-400 pl-2">
        {attachment?.fileName || "Attachment"}
      </div>
    </div>
  );
}

const MAP_THUMPNAIL = {
  image: ImageThumpnail,
  excel: ExcelPdfDocThumpnail,
  pdf: ExcelPdfDocThumpnail,
  word: ExcelPdfDocThumpnail,
};

function AttachmentThumpnail({ attachments = [], onRemove, customCls = "" }) {
  return (
    <div
      className={`flex flex-wrap my-3 overflow-y-auto max-h-32 ${customCls}`}
    >
      {attachments.map((attachment, i) => {
        const type = getFileType(attachment);
        const Comp = MAP_THUMPNAIL[type];

        return (
          <div key={i} className="relative mx-1 my-3">
            {type ? (
              <Comp attachment={attachment} type={type} />
            ) : (
              <p className="w-auto h-12 rounded-lg shadow-lg border border-gray-300 p-2">
                No preview
              </p>
            )}

            <i
              class="fa-solid fa-circle-xmark absolute -top-1 right-0 cursor-pointer"
              onClick={() => onRemove(i)}
            ></i>
          </div>
        );
      })}
    </div>
  );
}

export default AttachmentThumpnail;
