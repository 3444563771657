import CreatableSelect from "react-select/creatable";
import InputWrapper from "../../common/InputWrapper";
import ReactQuill from "react-quill";
import Button from "../../common/Button";
import PrimaryButton from "../../common/PrimaryButton";
import MailTemplateForm from "./MailTemplateForm";
import { useCallback, useEffect, useState } from "react";
import {
  getReactSelectOptions,
  getSelectedValue,
} from "../../common/commonHelpers";

const formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  // 'image'
  // 'video'
];

const modules = {
  toolbar: [["bold", "italic", "underline", "code-block", "image"]],
};

function SendMailForm(props) {
  const [form, setForm] = useState({
    to: [],
    subject: "",
    body: "",
  });

  const updateForm = useCallback((update) => {
    setForm((prev) => ({ ...prev, ...update }));
  }, []);

  const [error, setError] = useState({
    to: "",
    subject: "",
    body: "",
  });

  const { to, subject, body } = form;

  return <MailTemplateForm />;

  useEffect(() => {
    if (props?.to?.length) {
      updateForm({ to: [props.to] });
    }
  }, [props.to]);

  return (
    <div className="my-1">
      <form
        className="flex flex-col space-y-4"
        onSubmit={(e) => {
          e.preventDefault();

          const err = {};

          if (!to?.length) {
            err.to = "To is required";
          }

          if (!subject) {
            err.subject = "Subject is required";
          }

          if (!body) {
            err.body = "Body is required";
          }

          if (Object.keys(err)) {
            setError(err);
            return;
          }

          setError({
            to: "",
            subject: "",
            body: "",
          });
        }}
      >
        <InputWrapper label="To" error={error?.to}>
          <CreatableSelect
            options={[]}
            onChange={(value) => {
              console.log("value", value);
              updateForm({ to: value?.value || [] });
            }}
            value={
              to?.length
                ? to?.map((email) => ({ label: email, value: email }))
                : null
            }
            required
            isMulti
            isDisabled
          />
        </InputWrapper>

        <InputWrapper label="Subject" error={error?.subject}>
          <input
            type="text"
            placeholder="Subject"
            className="border border-gray-300 px-2 py-2 w-full rounded-md outline-none focus:ring-1 focus:ring-blue-400"
            onChange={(e) => updateForm({ subject: e.target.value })}
            value={subject}
          />
        </InputWrapper>

        <InputWrapper label="Body" error={error?.body}>
          <ReactQuill
            theme="snow"
            value={body}
            onChange={(value) => {
              updateForm({ body: value });
            }}
            modules={modules}
            formats={formats}
            placeholder="Your email..."
          />
        </InputWrapper>

        <div className="flex justify-end">
          <PrimaryButton label="Send email" />
        </div>
      </form>
    </div>
  );
}

export default SendMailForm;
