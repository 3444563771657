import { useContext, useEffect, useState } from "react";
import useDropDown from "../../hooks/useDropDown";
import ViewMoreWrapper from "../common/ViewMoreWrapper";
import Select from "react-select";
import useTicketTeamAssign from "./useTicketTeamAssign";
import useApiData from "../../hooks/useApiData";
import Spinner from "../loaders/Spinner";
import useTeam from "../settings/team/useTeam";
import { AppContext } from "../../App";
import Loading from "../loaders/Loading";
import Modal from "../common/Modal";
import TicketTeamMembersForm from "./TicketTeamMembersForm";

const statusOptions = [
  { label: "Pending", value: "PENDING" },
  { label: "In Progress", value: "INPROGRESS" },
  { label: "Hold", value: "HOLD" },
  { label: "Completed", value: "COMPLETED" },
];

const getStatusBackgroundColor = (status) => {
  switch (status) {
    case "PENDING":
      return "#fef9c3";
    case "INPROGRESS":
      return "#ffedd5";
    case "HOLD":
      return "#dbeafe";
    case "COMPLETED":
      return "#dcfce7";
    default:
      return "white";
  }
};

const getStatusCls = (status) => {
  switch (status) {
    case "PENDING":
      return "text-yellow-600 border-yellow-600 bg-yellow-50";
    case "INPROGRESS":
      return "text-orange-500 border-orange-500 bg-orange-50";
    case "HOLD":
      return "text-blue-600 border-blue-600 bg-blue-50";
    case "COMPLETED":
      return "text-green-600 border-green-600 bg-green-50";
    default:
      return "white";
  }
};

function getMembersForTeam(teamAssignees, team_id) {
  const team = teamAssignees?.find((a) => a?.teamDetails?.team_id === team_id);

  return team?.assignees;
}

function getUnAssignedTeam(teams, teamAssignees) {
  const newTeams = [];

  if (!teams?.length) {
    return;
  }

  teams.forEach((t) => {
    const { team_id } = t || {};

    const assigned = teamAssignees?.find(
      (a) => a?.teamDetails?.team_id === team_id
    );

    if (!assigned) {
      newTeams.push(t);
    }
  });

  return newTeams;
}

function AssignTeams({
  teamAssignees,
  loading,
  ticketId,
  getTeamAssignees,
  ticketDetail,
}) {
  const { onSuccess, onError, currUser } = useContext(AppContext);

  const [openAddTeam, setOpenAddTeam] = useState(false);

  const [openMembersForm, setOpenMembersForm] = useState({
    open: false,
    type: "",
    data: null,
  });

  function clearMembersForm() {
    setOpenMembersForm({
      open: false,
      type: "",
      data: null,
    });
  }

  const { elementRef } = useDropDown({
    open: openAddTeam,
    setOpen: setOpenAddTeam,
  });

  const { getTeams } = useTeam();

  const {
    addTeamToTicket,
    removeTeamFromTicket,
    searchTeamMembers,
    ticketAssign,
    removeTicketAssign,
    updateTicketTeamStatus,
  } = useTicketTeamAssign();

  const {
    data: teams,
    mutate: teamsMutate,
    loading: isGettingTeams,
  } = useApiData({
    onError: (res) => onError("Unable to get teams"),
  });

  const { mutate: addTeam, loading: isAddingTeam } = useApiData({
    onSuccess: (res) => {
      const { message } = res?.data || {};

      if (res?.data?.message === "success") {
        onSuccess("Team added successfully");
        setOpenAddTeam(false);
        getTeamAssignees();
      } else {
        onError(message || "Unable to add team");
      }
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to add team");
    },
  });

  const { mutate: removeTeam, loading: isRemovingTeam } = useApiData({
    onSuccess: (res) => {
      const { message } = res?.data || {};

      if (res?.data?.message === "success") {
        onSuccess("Team removed successfully");
        setOpenAddTeam(false);
        getTeamAssignees();
      } else {
        onError(message || "Unable to remove team");
      }
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to remove team");
    },
  });

  const {
    data: searchData,
    mutate: search,
    loading: isSearchLoading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => onError("Something went wrong while Searching "),
  });

  const { mutate: assignMember, loading: isAssigning } = useApiData({
    onSuccess: (res) => {
      onSuccess("Member added Successfully..!");
      getTeamAssignees();
    },
    onError: (err) => {
      const msgData = err?.response?.data?.msg || [];
      onError(msgData ? msgData : "Something went wrong while Adding Member");
    },
  });

  const { mutate: removeMember, loading: isRemovingMember } = useApiData({
    onSuccess: (res) => {
      onSuccess("Member Removed Successfully..!");
      getTeamAssignees();
    },
    onError: (err) => {
      const msgData = err?.response?.data?.error || [];
      onError(msgData ? msgData : "Something went wrong while Removing Member");
    },
  });

  const { mutate: updateTeamStatus, loading: isUpdatingTeamStatus } =
    useApiData({
      onSuccess: (res) => {
        onSuccess("Team updated successfully!");
        getTeamAssignees();
      },
      onError: (err) => {
        const msgData = err?.response?.data?.error || [];
        onError(
          msgData ? msgData : "Something went wrong while updating status"
        );
      },
    });

  useEffect(() => {
    teamsMutate(getTeams({ isUsers: false }));
  }, []);

  const unAssignedTeams = getUnAssignedTeam(teams, teamAssignees);

  const approvalStatus = ticketDetail?.approvalStatus || {};

  return (
    <div className="border border-gray-300 rounded-md bg-white p-6 mt-6">
      <div
        className="pb-2 mb-4 border-b border-gray-300 flex items-center justify-between relative"
        ref={elementRef}
      >
        <h4 className="font-semibold text-xl">Team Assignee(s)</h4>

        <button
          className="text-indigo-600 hover:bg-gray-100 text-sm font-semibold flex items-center space-x-4"
          onClick={() => setOpenAddTeam(!openAddTeam)}
        >
          {isAddingTeam ? <Loading /> : null}
          {openAddTeam ? "Back" : "Add Team"}
        </button>

        {openAddTeam ? (
          <div className="absolute top-8 right-0 z-40 bg-white border rounded-md shadow-md">
            {isGettingTeams ? <Loading customCls="m-4" /> : null}

            {unAssignedTeams?.length ? null : (
              <div className="font-semibold text-center text-gray-500 my-2">
                No Teams
              </div>
            )}

            {unAssignedTeams?.map((btn, i) => {
              const { name, team_id } = btn;

              return (
                <button
                  className={`px-4 py-2 hover:bg-gray-100 border-b w-full text-left flex items-center`}
                  key={i}
                  onClick={() => {
                    addTeam(addTeamToTicket(ticketId, { team_id }));
                  }}
                >
                  {name}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <>
          {teamAssignees?.length ? null : (
            <div className="font-semibold text-center text-gray-500">
              No Teams Assigned
            </div>
          )}
          {teamAssignees.map((team, i) => {
            const { teamDetails, assignees } = team;
            const {
              name,
              status,
              inThisTeam,
              canStatusEdit,
              ticket_team_id,
              team_id,
            } = teamDetails || {};

            const selectedStatus = statusOptions.find(
              (s) => s.value === status
            );

            const statusColor = getStatusBackgroundColor(status);

            return (
              <div
                key={i}
                className="flex justify-between items-center space-x-4 text-sm my-3"
              >
                <div className="w-1/2">
                  <div className="py-2 font-semibold text-md">{name}</div>

                  {assignees?.length ? null : (
                    <div className="font-semibold text-gray-500 text-sm">
                      No Assignees
                    </div>
                  )}

                  <ViewMoreWrapper
                    data={assignees?.length ? assignees : []}
                    labelCls="!my-2 !text-[12px]"
                    viewMoreLabel="View more assignee(s)"
                  >
                    {(data) => {
                      const assignees = [];

                      data.forEach((d) => {
                        assignees.push(d.username || d.email);
                      });

                      return (
                        <div className="text-gray-500">
                          {assignees.join(", ")}
                        </div>
                      );
                    }}
                  </ViewMoreWrapper>
                </div>

                <div className="flex items-center space-x-4">
                  {!approvalStatus.status && canStatusEdit ? (
                    <>
                      {isUpdatingTeamStatus ? (
                        <Loading />
                      ) : (
                        <Select
                          options={statusOptions}
                          value={selectedStatus}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              backgroundColor: statusColor,
                              border: `2px solid ${statusColor}`,
                              fontWeight: 500,
                            }),
                            dropdownIndicator: (base) => ({
                              ...base,
                              color: "black",
                            }),
                          }}
                          onChange={(val) => {
                            if (val?.value) {
                              updateTeamStatus(
                                updateTicketTeamStatus(
                                  ticketId,
                                  ticket_team_id,
                                  {
                                    status: val?.value,
                                  }
                                )
                              );
                            }
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <div
                      // style={{
                      //   background: statusColor,
                      // }}
                      className={`px-4 py-2 text-[12px] rounded-full font-semibold border ${getStatusCls(
                        status
                      )}`}
                    >
                      {selectedStatus?.label}
                    </div>
                  )}

                  <HandleAction
                    onRemove={() => {
                      removeTeam(
                        removeTeamFromTicket(ticketId, ticket_team_id)
                      );
                    }}
                    isRemoving={isRemovingTeam}
                    onEdit={() => {
                      setOpenMembersForm({
                        type: "Assign",
                        open: true,
                        data: team_id,
                      });
                    }}
                    onAssignToMe={() => {
                      assignMember(
                        ticketAssign({
                          handled_by: currUser?.hashId,
                          ticket_id: ticketId,
                          team_id: team_id,
                        })
                      );
                    }}
                    isAssigning={isAssigning}
                    inThisTeam={inThisTeam}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}

      <Modal
        title={`${openMembersForm.type} Members`}
        isOpen={openMembersForm?.open && openMembersForm?.type !== "Delete"}
        onClose={clearMembersForm}
      >
        <TicketTeamMembersForm
          data={{
            users: getMembersForTeam(teamAssignees, openMembersForm.data) || [],
          }}
          onSearch={(data) => {
            search(
              searchTeamMembers(ticketId, {
                search: data,
              })
            );
          }}
          onAddUser={(data) => {
            const { id } = data || {};

            assignMember(
              ticketAssign({
                handled_by: id,
                ticket_id: ticketId,
                team_id: openMembersForm?.data,
              })
            );
          }}
          onRemoveUser={(data) => {
            const { id, user_id } = data || {};

            removeMember(
              removeTicketAssign({
                id: id,
                ticket_id: ticketId,
                assign_id: user_id,
              })
            );
          }}
          loading={loading}
          searchData={searchData}
          isSearchLoading={isSearchLoading}
          isAddUserLoading={isAssigning}
          isRemoveUserLoading={isRemovingMember}
        />
      </Modal>
    </div>
  );
}

export default AssignTeams;

function HandleAction({
  onRemove,
  isRemoving,
  onEdit,
  onAssignToMe,
  isAssigning,
  inThisTeam,
}) {
  const [open, setOpen] = useState(false);

  const { elementRef } = useDropDown({ open, setOpen });

  const actionBtns = [
    {
      label: "Manage Assignees",
      icon: "fa-solid fa-people-roof",
      onClick: onEdit,
      cls: "text-gray-700",
      loading: false,
    },
    {
      label: "Remove",
      icon: "fa-solid fa-trash-can",
      onClick: onRemove,
      cls: "text-red-600",
      loading: isRemoving,
    },
  ];

  if (inThisTeam) {
    const newActionBtn = {
      label: "Assignee to me",
      icon: "fa-solid fa-user-plus",
      onClick: onAssignToMe,
      cls: "text-gray-700",
      loading: isAssigning,
    };

    actionBtns.unshift(newActionBtn);
  }

  return (
    <div ref={elementRef} className="relative">
      <i
        className="fa-solid fa-ellipsis-vertical text-lg cursor-pointer px-2 hover:bg-gray-100 rounded-md"
        onClick={() => setOpen(!open)}
      ></i>

      {open ? (
        <div className="absolute w-[200px] top-8 right-0 z-40 bg-white border rounded-md shadow-md">
          {actionBtns.map((btn, i) => {
            const { label, onClick, icon, cls, loading } = btn;

            return (
              <button
                className={`px-4 py-2 hover:bg-gray-100 border-b w-full text-left flex items-center ${cls}`}
                key={i}
                onClick={onClick}
              >
                {loading ? <Loading /> : null}
                <i className={`${icon} mx-2`}></i>
                {label}
              </button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
