import React, { useState, useEffect, useContext } from "react";
import UserChat from "../../components/message/UserChat";
import UsersList from "../../components/message/UsersList";
import io from "socket.io-client";
import api from "../../api/api";
import { AppContext } from "../../App";
import Usersdetails from "../../components/message/UserDetails";
import Select from "react-select";
import Spinner from "../../components/loaders/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fi } from "date-fns/locale";
import MessageOptionsSidebar from "../../components/message/MessageOptionsSidebar";
import CreateWhatsappClientForm from "../../components/message/CreateWhatsappClientForm";
import useApiData from "../../hooks/useApiData";
import { socketURL } from "../../components/common/commonHelpers";
import useTeam from "../../components/settings/team/useTeam";

// function getContentHeight() {
//   const containerHeight =
//     document.getElementById("content-container")?.scrollHeight;

//   const headerHeight = document.getElementById("chat-box-header")?.scrollHeight;

//   const footerHeight = document.getElementById("chat-box-footer")?.scrollHeight;

//   const occupied = headerHeight + footerHeight;

//   return containerHeight - occupied;
// }

const MessageContainer = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedChat, setSelectedChat] = useState({
    name: "",
    value: "",
  });
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState({
    list: true,
    message: true,
  });
  const [userDetails, setUserDetails] = useState([]);
  const [userUpdated, setUserUpdated] = useState([]);
  const [userFilter, setUserFilter] = useState("Assigned-to-me");

  const searchParams = new URLSearchParams(window.location.search);

  const selectedMsgTab = searchParams.get("msgTab");

  const [msgTab, setMsgTab] = useState(
    selectedMsgTab === "whatsapp" ? "whatsapp" : "chat"
  );

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    socket,
    setSocket,
    navToggle,
  } = useContext(AppContext);

  const { chatbot, filter, chatId } = useParams();
  const location = useLocation();
  const history = useNavigate();
  const [currPage, setCurrPage] = useState(1);

  const [availableChats, setAvailableChats] = useState([]);
  const [chatToggle, setChatToggle] = useState(false);

  const [onTicketCreation, setOnTicketCreation] = useState();

  const { getTeams } = useTeam();

  const {
    data: teamsData,
    mutate: getLists,
    loading: isTeamLoading,
  } = useApiData({
    onSuccess: (response) => {
      var options = response.data.map((item) => {
        return {
          label: item.name,
          value: item.team_id,
        };
      });
      setAvailableChats(options);
      var firstOption;
      const searchParams = new URLSearchParams(window.location.search);
      const chat = searchParams.get("bot");
      if (chat) {
        firstOption = options.find((data) => data.value == chat);
      } else {
        firstOption = options[0];
      }
      setSelectedChat({
        name: firstOption?.label,
        value: firstOption?.value,
      });
    },
    onError: () => onError("Something went wrong while getting Teams"),
  });
  const handleConnectedUsers = (usersList, type) => {
    if (type === "whatsapp" && selectedMsgTab === "whatsapp") {
      console.log("type 1", usersList);
      setUsers(usersList);
    } else if (!type && selectedMsgTab !== "whatsapp") {
      setUsers(usersList);
      console.log("type 2");
    }

    setLoading((prev) => ({
      ...prev,
      ["list"]: false,
    }));
  };

  const { mutate: getUsersMutate } = useApiData({
    onSuccess: (res) => {
      const { users = [], type } = res.data;
      handleConnectedUsers(users, type);
    },
    onError: (err) => {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong while getting users.",
      });
    },
  });

  const { mutate: queueRefreshApi } = useApiData({
    onSuccess: (res) => {
      if (res?.data) {
        socket.emit("emitCheckQueueStatus", JSON.stringify(res?.data));
      }
    },
    onError: (err) => {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong while getting users.",
      });
    },
  });

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  function onSuccess(msg) {
    setShowToast(true);
    setToastMessage({
      type: "success",
      message: msg,
    });
  }

  function getUsers(type = 1) {
    if (currCommunityId && selectedChat?.value && userFilter) {
      const searchParams = new URLSearchParams(window.location.search);
      const filter = searchParams.get("filter");
      const payload = {
        community_id: currCommunityId,
        chatId: selectedChat.value,
        userFilter: filter,
        hashId: currUser.hashId,
        type,
      };
      const url = `${socketURL}/chatbot/users?pageNo=${currPage}`;

      getUsersMutate(() => api.post(url, payload));
    }
  }

  function queueRefresh() {
    const payload = {
      community_id: currCommunityId,
      team_id: selectedChat.value,
    };

    const url = `/api/get_queue`;

    queueRefreshApi(() => api.post(url, payload));
  }

  const handleUserId = (user) => {
    // console.log(user);
    setSelectedUser(user);

    socket.emit("message-update", user);

    getUsers();

    setLoading((prev) => ({
      ...prev,
      ["message"]: true,
    }));
  };

  // useEffect(() => {
  //   setLoading({
  //     list: true,
  //     message: true,
  //   });
  // }, []);

  const setClient = (data) => {
    setUserDetails(data);
  };

  const setUserFilterValue = (data) => {
    setUserFilter(data);
  };

  useEffect(() => {
    setLoading((prev) => ({
      ...prev,
      ["list"]: true,
    }));
  }, [userFilter, selectedChat.value, msgTab]);

  const getAllFolders = () => {
    setProcessing(true);
    var postData = {
      search: "",
      community_id: currCommunityId,
    };

    api
      .post("/api/folderList", postData)
      .then((response) => {
        setProcessing(false);

        var options = response.data.data.map((item) => {
          return {
            label: item.name,
            value: item.uuid,
          };
        });
        setAvailableChats(options);

        var firstOption;

        const searchParams = new URLSearchParams(window.location.search);
        const chat = searchParams.get("bot");

        if (chat) {
          firstOption = options.find((data) => data.value == chat);
        } else {
          firstOption = options[0];
        }
        setSelectedChat({
          name: firstOption?.label,
          value: firstOption?.value,
        });
      })
      .catch((error) => {
        setAvailableChats([]);
        setProcessing(false);
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const filter = searchParams.get("filter");
    const client = searchParams.get("client");
    if (filter) {
      setUserFilter(filter);
    }
    if (client) {
      setSelectedUser(client);
    }
  }, []);

  useEffect(() => {
    if (selectedChat.value) {
      const searchParams = new URLSearchParams();

      if (msgTab === "whatsapp") {
        searchParams.append("msgTab", msgTab);

        if (selectedUser) {
          searchParams.append("client", selectedUser);
        }
      } else {
        searchParams.append("bot", selectedChat.value);
        searchParams.append("filter", userFilter);
        searchParams.append("client", selectedUser);
      }
      history({ search: searchParams.toString() });
    }
  }, [selectedChat, userFilter, selectedUser, msgTab]);

  useEffect(() => {
    getUserInfo();
    getLists(getTeams());
  }, []);

  const setUsersList = (data) => {
    setUsers(data);
  };

  useEffect(() => {
    if (socket) {
      // console.log(selectedChat.value, userFilter, currUser.hashId);

      socket.on("userConnected", (data) => {
        // socket.emit(
        //   "getUsers",
        //   currCommunityId,
        //   selectedChat.value,
        //   userFilter,
        //   currUser.hashId,
        //   currPage,
        //   selectedMsgTab === "whatsapp" ? 2 : 1
        // );

        getUsers(selectedMsgTab === "whatsapp" ? 2 : 1);

        console.log("getUsers", userFilter);
      });

      // socket.on("connectedUsers", handleConnectedUsers);

      // socket.emit(
      //   "getUsers",
      //   currCommunityId,
      //   selectedChat.value,
      //   userFilter,
      //   currUser.hashId,
      //   currPage,
      //   selectedMsgTab === "whatsapp" ? 2 : 1
      // );

      getUsers(selectedMsgTab === "whatsapp" ? 2 : 1);

      const handleChatNotification = async (msg) => {
        var socketID = document.cookie.replace(
          /(?:(?:^|.*;\s*)chatSocketID-admin\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        );
        console.log("notification", currPage);
        if (socketID) {
          // await socket.emit(
          //   "getUsers",
          //   currCommunityId,
          //   selectedChat.value,
          //   userFilter,
          //   currUser.hashId,
          //   currPage,
          //   selectedMsgTab === "whatsapp" ? 2 : 1
          // );
          getUsers(selectedMsgTab === "whatsapp" ? 2 : 1);
        }
        // if (socketID != msg.senderId) {
        //   const senderId = msg.senderId;
        //   const message = "";
        //   const timestamp = new Date();
        //   const status = 0;
        //   setUsers((prevUsers) => {
        //     return prevUsers.map((user) => {
        //       if (user.client_id === senderId) {
        //         const newMessage = {
        //           senderId: senderId,
        //           message: message,
        //           timestamp: timestamp,
        //           status: status,
        //         };
        //         // console.log("senderId", senderId);
        //         const contentArray = JSON.parse(user.content);
        //         const existingMessage = contentArray.find(
        //           (msg) => JSON.parse(msg).timestamp === timestamp
        //         );

        //         if (!existingMessage) {
        //           contentArray.push(JSON.stringify(newMessage));
        //         }

        //         return {
        //           ...user,
        //           content: JSON.stringify(contentArray),
        //         };
        //       }

        //       return user;
        //     });
        //   });
        // }
      };

      socket.on("notification", handleChatNotification);

      return () => {
        socket.off("userConnected");
        // socket.off("notification");
        socket.off("connectedUsers", handleConnectedUsers);
      };
    }
  }, [selectedChat.value, userFilter, currPage, selectedMsgTab]);

  useEffect(() => {
    // console.log(users);
  }, [userUpdated]);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedChat({
        name: selectedOption.label,
        value: selectedOption.value,
      });
    } else {
      setSelectedChat({ name: "Please select a team", value: "" });
    }
  };

  const updatedContent = (currId) => {
    if (socket) {
      socket.emit("message-update", currId);
      // console.log("message-update", currId);
      var id = currId;
      setUsers((prevUsers) => {
        return prevUsers.map((user) => {
          // console.log("users", users);

          if (user.client_id === currId) {
            if (user?.content) {
              const contentArray = JSON.parse(user.content);
              const updatedContent = contentArray.map((message) => {
                const messageData = JSON.parse(message);
                messageData.status = 1;
                return JSON.stringify(messageData);
              });

              return {
                ...user,
                content: JSON.stringify(updatedContent),
              };
            }
          }
          return user;
        });
      });
    }
  };

  const handleLoadMore = async () => {
    // setLoading((prev) => ({
    //   ...prev,
    //   ["list"]: true,
    // }));
    setCurrPage((prevPage) => prevPage + 1);

    // await socket.emit(
    //   "getUsers",
    //   selectedChat.value,
    //   userFilter,
    //   currUser.hashId,
    //   currPage + 1
    // );
  };

  useEffect(() => {
    console.log("page", currPage);
  }, [currPage]);

  const isWhatsappTab = msgTab === "whatsapp";

  // function getUsers(type = 1) {
  //   socket.emit(
  //     "getUsers",
  //     currCommunityId,
  //     selectedChat.value,
  //     userFilter,
  //     currUser.hashId,
  //     currPage,
  //     type
  //   );
  // }
  const currCommunity =
    currUser?.user?.find((u) => u.community_id === currCommunityId) || {};

  const isWhatsappEnabled =
    currCommunity?.phone_no_id && currCommunity?.whatsapp_number;

  return (
    <div
      className="conversation-container flex overflow-auto"
      id="conversation-container"
    >
      <div className="w-[6%]">
        <MessageOptionsSidebar
          msgTab={msgTab}
          onTabChange={(tab) => {
            setMsgTab(tab);

            setUserDetails({});
            setSelectedUser(null);
            setUsers([]);

            getUsers(tab === "whatsapp" ? 2 : 1);
          }}
          loading={loading?.list}
        />
      </div>

      <div className="w-[94%] border-l border-gray-300 flex flex-col">
        <div
          className="px-4 border-b border-gray-300 h-[12%] flex items-center justify-between"
          id="conversation-header"
        >
          <div className="flex my-4">
            <div>
              <h3 className="lg:mb-0 main-title">
                {isWhatsappTab ? "Whatsapp" : "Conversations"}
              </h3>

              <div className="text-[14px] mt-1 text-[#172B4D]">
                {currUser?.username}
              </div>
            </div>

            {isWhatsappTab && isWhatsappEnabled ? (
              <CreateWhatsappClientForm getUsers={getUsers} />
            ) : null}
          </div>

          {isWhatsappTab ? null : (
            <Select
              className="rounded-md border-2 border-gray-200 chat-select text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600"
              options={[...availableChats]}
              value={{
                value: selectedChat.value,
                label: selectedChat.name,
              }}
              onChange={handleSelectChange}
              placeholder="Select Chatbot"
              isSearchable
              styles={{
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#1C1B1F",
                }),
              }}
            />
          )}
        </div>

        <div
          className="flex justify-between space-x-4 h-[88%] overflow-auto px-2 py-4"
          id="content-container"
        >
          <div className="w-[20%] flex flex-col pl-3">
            <UsersList
              handleUserId={handleUserId}
              users={users}
              user_id={selectedUser}
              setUserFilterValue={setUserFilterValue}
              userFilter={userFilter}
              loading={loading}
              handleLoadMore={handleLoadMore}
              isWhatsappTab={isWhatsappTab}
            />
          </div>

          <div
            className={`bg-white ${
              chatToggle ? "w-[80%]" : "w-[60%]"
            } flex flex-col justify-between border border-gray-300`}
          >
            <UserChat
              user_id={selectedUser}
              selectedChat={selectedChat.value}
              userDetails={userDetails}
              setClient={setClient}
              users={users}
              setUsers={setUsers}
              updatedContent={updatedContent}
              chatToggle={chatToggle}
              handleChatToggle={setChatToggle}
              loading={loading}
              handleLoading={setLoading}
              userFilter={userFilter}
              currUser={currUser}
              currPage={currPage}
              msgTab={msgTab}
              isWhatsappTab={isWhatsappTab}
              getUsers={getUsers}
              isWhatsappEnabled={isWhatsappEnabled}
              onTicketCreation={onTicketCreation}
              setOnTicketCreation={setOnTicketCreation}
              queueRefresh={queueRefresh}
              handleSelectedUser={setSelectedUser}
              teamsData={teamsData}
            />
          </div>

          {chatToggle ? null : (
            <div className="w-[20%] flex flex-col transition-transform duration-200">
              <div className="overflow-auto pr-2">
                <Usersdetails
                  handleUserId={handleUserId}
                  users={users}
                  user_id={selectedUser}
                  selectedChat={selectedChat.value}
                  setClient={setClient}
                  userDetails={userDetails}
                  setUserUpdated={setUserUpdated}
                  setUsers={setUsers}
                  setOnTicketCreation={setOnTicketCreation}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageContainer;
