import React, { useState } from "react";
import { Link } from "react-router-dom";

const Folder = (props) => {
  const [openDropdown, setOpenDropDown] = useState(false);
  return (
    <>
      {props.folder.children.length > 0 ? (
        <>
          <div
            className="navItem order-3 flex justify-between cursor-pointer flex items-center w-full p-3 mt-2 rounded hover:bg-gray-200"
            onClick={(e) => setOpenDropDown(!openDropdown)}
          >
            <div className="flex justify-between w-full items-center">
              <span className="text-sm font-medium">{props.folder.name}</span>
              <i className="fa-solid fa-angle-down"></i>
            </div>
          </div>
          {openDropdown && (
            <div className="ml-5 childItem order-3">
              {props.folder.children.map((child) => (
                <Folder
                  handleClick={props.handleClick}
                  activeFolder={props.activeFolder}
                  key={child.path}
                  folder={child}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`${
              props.folder.name == "INBOX" ? "order-1" : "order-last"
            } ${
              props.activeFolder === props.folder.path ? "folder-active" : ""
            } flex text-base cursor-pointer items-center w-full p-3 rounded hover:bg-gray-200 settingList__item
            href="/settings/profile`}
            onClick={() => props.handleClick(props.folder)}
          >
            <span className="text-sm font-medium">{props.folder.name}</span>
          </div>
        </>
      )}
    </>
  );
};

export default Folder;
