import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { json, useNavigate, useParams } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Link, useLocation } from "react-router-dom";
import ClockworkModal from "./ClockworkModal";
import Select from "react-select";
import Button from "../common/Button";
import ViewMoreWrapper from "../common/ViewMoreWrapper";
import Spinner from "../loaders/Spinner";

const TicketClockWork = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [clockWorkModal, setClockWorkModal] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [clockForm, setClockForm] = useState({
    hours: "",
    minutes: "",
    date: "",
  });
  const { id } = useParams();
  const [processing, setProcessing] = useState(false);
  const btnRef = useRef(null);
  const dropdownRef = useRef(null);
  // useEffect(() => {
  //   console.log("props.distributorRemove", props.distributorRemove);
  //   props.handleDistributorRemove(!props.distributorRemove);
  // }, [clockWorkModal]);

  const { getClockWork, clockWork, loading } = props;

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setHasChanged,
  } = useContext(AppContext);

  const addClockWork = (form, clockId = null) => {
    var payload = {
      clockId: clockId,
      community_id: currCommunityId,
      ticket_id: id,
      date: form.date,
      minutes: form.minutes,
      hours: form.hours,
    };
    api
      .post("/api/addOrUpdateClockWork", payload)
      .then((response) => {
        setProcessing(false);
        // console.log(response);
        if (response.data.message == "success") {
          setHasChanged(false);
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Log added",
          });
          console.log("reponse", response.data);
          // props.handleTicketDetail(response.data.data);
          getClockWork();
        }
        setClockForm({ hours: "", minutes: "", date: "" });
        setOpenDropdownId(null);
        setClockWorkModal(false);
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
        setClockWorkModal(false);
        setClockForm({ hours: "", minutes: "", date: "" });
        setOpenDropdownId(null);
      });
  };

  const handleEllipsisClick = (clockId) => {
    if (openDropdownId === clockId) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(clockId);
    }
  };

  const deleteClockWork = (e) => {
    e.preventDefault();
    var payload = {
      clockId: openDropdownId,
      community_id: currCommunityId,
      ticket_id: id,
    };
    setProcessing(true);
    api
      .post("/api/deleteClockWork", payload)
      .then((response) => {
        setProcessing(false);
        // console.log(response);
        if (response.data.message == "success") {
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Log Removed",
          });
          // props.handleTicketDetail(response.data.data);
          getClockWork();
        }
        setClockForm({ hours: "", minutes: "", date: "" });
        setOpenDropdownId(null);
        setDeleteModal(false);
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
        setDeleteModal(false);
        setClockForm({ hours: "", minutes: "", date: "" });
        setOpenDropdownId(null);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="mt-16">
      <h4 className="font-semibold text-xl pb-2 border-b border-gray-300">
        Work Log
      </h4>

      <div className="bg-[#E3E9F3] hover:bg-gray-100 duration-300 ease flex justify-between px-4 py-4 mt-6 rounded-md">
        <div className="font-semibold">Add Time</div>

        <Button onClick={(e) => setClockWorkModal(true)}>
          <img src="/images/icons/add-blue-btn.svg" alt="add+" />
        </Button>
      </div>

      <div className="mt-8">
        <ViewMoreWrapper data={clockWork}>
          {(data) => {
            return data?.map((clock, index) => {
              const { image, username, email } = clock;

              return (
                <div key={index} className="my-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <div className="w-9 h-9 overflow-hidden rounded-full">
                        <img
                          src={image || "/images/icons/user.svg"}
                          alt="user"
                          className="object-fill w-full h-full"
                        />
                      </div>

                      <div className="font-semibold">{username || email}</div>
                    </div>

                    <div
                      ref={dropdownRef}
                      className=" relative flex gap-4 items-center justify-between text-sm font-medium"
                    >
                      <div className="text-gray-500">
                        {new Date(clock.date).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </div>

                      <div className="text-black">
                        {`${clock?.hours ? `${clock?.hours}h` : ""} ${
                          clock?.minutes ? `${clock?.minutes}m` : ""
                        }`}
                      </div>

                      <div className="relative">
                        <span
                          className="cursor-pointer"
                          onClick={() => handleEllipsisClick(clock.id)}
                        >
                          <i className="fa-solid fa-ellipsis-vertical text-xl"></i>
                        </span>

                        {openDropdownId === clock.id && (
                          <div className="z-10 absolute right-0 bg-white border border-gray-300 rounded-md">
                            <div className="p-2 rounded-md w-[auto] shadow-md z-10">
                              <div
                                className="px-2 py-1 rounded duration-300 ease cursor-pointer font-md w-full hover:bg-gray-200"
                                onClick={() => {
                                  setClockWorkModal(true);
                                  setClockForm({
                                    date: clock.date,
                                    hours: clock.hours,
                                    minutes: clock.minutes,
                                  });
                                }}
                              >
                                Edit
                              </div>
                              <div
                                className="px-2 py-1 rounded duration-300 ease cursor-pointer font-md w-full hover:bg-gray-200"
                                onClick={() => {
                                  setDeleteModal(true);
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            });
          }}
        </ViewMoreWrapper>
      </div>

      {clockWorkModal && (
        <ClockworkModal
          toggleClockModal={setClockWorkModal}
          addClockWork={addClockWork}
          currClockId={openDropdownId}
          form={clockForm}
          handleClockForm={setClockForm}
          handleOpenDropdownId={setOpenDropdownId}
        />
      )}
      {deleteModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
            <div
              className="relative dialog-content bg-white rounded-lg shadow"
              ref={btnRef}
            >
              <div className="text-left flex items-start justify-between p-4 rounded-t">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Delete Clockwork
                  </h3>
                  <p className="mt-5">
                    Are you sure you want to delete this work?
                  </p>
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                  data-modal-hide="defaultModal"
                  onClick={() => {
                    setDeleteModal(false);
                    setOpenDropdownId(null);
                  }}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form action="#" onSubmit={deleteClockWork}>
                <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                  <button
                    className="inline-flex items-center justify-center button bg-red-600 hover:bg-red-500 active:bg-red-700 text-white radius-round h-11 px-8 py-2 w-full"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketClockWork;
