import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import TicketComment from "./TicketComment";
import Loading from "../loaders/Loading";
import ViewMoreWrapper from "../common/ViewMoreWrapper";
import FileCard from "./FileCard";

function getData(data, viewMore, length) {
  const newData = data?.length ? [...data] : [];

  return viewMore ? newData : newData.slice(0, length || 3);
}

const TicketComments = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setHasChanged,
  } = useContext(AppContext);
  const inputFileRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const [checked, setChecked] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const { comments, getComments } = props;

  const handleChange = () => {
    setChecked(!checked);
    setHasChanged(true);
  };

  const addComments = (e) => {
    e.preventDefault();
    var payload = {
      comment: comment,
      id: id,
      community_id: currCommunityId,
      checked: checked,
      attachments: attachments,
    };
    setProcessing(true);

    api
      .post("/api/addComment", payload)
      .then((response) => {
        setProcessing(false);

        if (response.data.message == "success") {
          setHasChanged(false);
          setComment("");
          setChecked(false);
          setAttachments([]);
          // props.handleTicketDetail(response.data.data);
          getComments();
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Comment Added Successfully",
          });
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      setAttachments(attachmentFiles);
      setProcessing(false);
      setHasChanged(true);
    } catch (error) {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  console.log("attachments", attachments);

  return (
    <div className="mt-16">
      <h4 className="font-semibold text-xl pb-2 border-b border-gray-300">
        Comments
      </h4>

      <form
        onSubmit={(e) => addComments(e)}
        className="mt-6 mb-3 flex flex-auto gap-4"
      >
        <div className="w-9 h-9 overflow-hidden rounded-full">
          <img
            src={"/images/icons/user.svg"}
            alt="user"
            className="object-fill w-full h-full"
          />
        </div>

        <div className="w-full">
          <textarea
            className="outline-none border focus:border-none border-gray-400 rounded-md focus:ring-2 focus:ring-indigo-400 w-full p-4"
            type="text"
            placeholder="Add a comment..."
            defaultValue={""}
            onChange={(e) => {
              setComment(e.target.value);
              setHasChanged(true);
            }}
            required
            value={comment}
          />

          {attachments?.length ? (
            <div className="flex flex-wrap gap-4 my-4">
              {attachments.map((attach, index) => (
                <div className="">
                  <FileCard
                    key={index}
                    file={attach}
                    index={index}
                    handleRemoveAttachment={() => {
                      setAttachments((prev) => {
                        return prev.filter((p, i) => i !== index);
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          ) : null}

          <div className="mt-3 w-fit text-gray-600">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                className="mr-2 w-4 h-4 border border-gray-500"
              />
              Make comment visible to client (External)
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="flex items-center space-x-4 bg-indigo-600 rounded-md text-white px-8 h-11 font-medium"
        >
          {processing ? <Loading /> : null}
          Post
        </button>

        <div>
          <div onClick={handleSelectButtonClick} className="cursor-pointer">
            <img
              src="/images/icons/attachment-btn.svg"
              alt="attachment"
              className="w-[3.4rem]"
            />
          </div>

          <input
            type="file"
            className="hidden"
            ref={inputFileRef}
            onChange={handleFileUpload}
            multiple
          />
        </div>
      </form>

      <ul className="timeline mt-12">
        <ViewMoreWrapper data={comments}>
          {(data) => {
            return data?.map((comment, index) => (
              <TicketComment
                handleTicketDetail={props.handleTicketDetail}
                key={index}
                comment={comment}
                getComments={getComments}
                // handleDistributorRemove={props.handleDistributorRemove}
                // distributorRemove={props.distributorRemove}
              />
            ));
          }}
        </ViewMoreWrapper>

        {/* {getData(props.ticketDetail?.ticketComments, viewMore)?.map(
          (comment, index) => (
            <TicketComment
              handleTicketDetail={props.handleTicketDetail}
              key={index}
              comment={comment}
              handleDistributorRemove={props.handleDistributorRemove}
              distributorRemove={props.distributorRemove}
            />
          )
        )} */}
      </ul>
    </div>
  );
};

export default TicketComments;
