import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import SelectEmails from "../common/SelectEmails";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import { AppContext } from "../../App";
import Spinner from "../loaders/Spinner";
import DependencyDropdown from "./DependencyDropdown";
import DropDown from "../common/DropDown";
import DeleteModal from "../common/DeleteModal";

function getFieldsOptions(fieldsData = []) {
  const options = [];

  fieldsData.forEach((f) => {
    const { field_name, field_type, id } = f;

    let label = "";

    if (field_type === "dependency_dropdown") {
      label = Object.values(field_name).join("--> ");
    } else {
      label = field_name;
    }

    options.push({
      label,
      value: id,
      field_type,
    });
  });

  return options;
}

function getColumns(props = {}) {
  return [
    {
      name: "Action",
      render: (data = {}) => <ToggleBtn props={props} data={data} />,
    },
    {
      name: <div className="required-input">Labels</div>,
      render: (data = {}) => {
        const { approval = {}, approvalIndex } = data;

        const { custom_field_id } = approval;

        const existingFormData = props.formData[approvalIndex] || {};

        const { label = "" } = existingFormData;

        return (
          <Input
            props={{
              ...props,
              required: true,
              value: label,
              onChange: (e) =>
                props.updateFormData(
                  {
                    label: e.target.value,
                    custom_field_id,
                  },
                  approvalIndex
                ),
            }}
            data={data}
            required={true}
          />
        );
      },
    },
    {
      name: "Field Names",
      render: (data = {}) => <FieldNames props={props} data={data} />,
    },
    {
      name: <div className="required-input">Values</div>,
      render: (data = {}) => <FieldValues props={props} data={data} />,
    },
    {
      name: <div className="required-input">Approvals</div>,
      render: (data = {}) => <Approvals props={props} data={data} />,
    },
  ];
}

function ManageApprovalForm({ closeModal }) {
  const { currCommunityId, currUser, setShowToast, setToastMessage } =
    useContext(AppContext);

  const [formData, setFormData] = useState({});
  const [deleteId, setDeleteId] = useState();

  function updateFormData(update, index) {
    setFormData((prev) => {
      const existingFormData = prev[index] || {};

      return {
        ...prev,
        [index]: {
          id: "",
          ...existingFormData,
          ...update,
        },
      };
    });
  }

  const members = currUser?.currCommunity?.members || [];

  const membersOptions = members.map((m) => ({
    label: m.username,
    value: m.email,
  }));

  const columns = getColumns({
    membersOptions,
    formData,
    setFormData,
    updateFormData,
  });

  const {
    data,
    mutate: getTicketFieldsMutate,
    loading: isLoading,
  } = useApiData({
    onSuccess: (res) => {
      const fieldsData = res?.data?.data || [];

      if (fieldsData?.length) {
        getTicketApprovalsData();
      }
    },
  });

  const fieldsData = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const {
    data: approvalsData,
    mutate: getTicketApprovals,
    loading: isLoadingApprovals,
  } = useApiData({
    onSuccess: (res) => {},
  });

  const getTicketApprovalsData = () => {
    getTicketApprovals(() =>
      api.get(`api/getTicketApprovals/${currCommunityId}`)
    );
  };

  const { mutate: deleteApproval, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Approval Deleted Successfully",
      });
      setDeleteId(null);
      getTicketApprovalsData();
    },
    onError: (error) => {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something Went Wrong",
      });
      setDeleteId(null);
    },
  });

  const { mutate: createUpdateApproval, loading: isCreateUpdateLoading } =
    useApiData({
      onSuccess: (res) => {
        const error = res?.data?.errMessage?.alreadyExists;

        if (error) {
          setShowToast(true);
          setToastMessage({
            type: "error",
            message: error,
          });
          return;
        }

        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Approval updated Successfully",
        });
        getTicketApprovalsData();
      },
      onError: (error) => {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      },
    });

  const getTicketFieldsData = () => {
    getTicketFieldsMutate(() =>
      api.get(`api/getTicketCustomFields/${currCommunityId}`)
    );
  };

  const deleteApprovalApi = () => {
    const { id, index } = deleteId || {};

    if (id) {
      deleteApproval(() =>
        api.delete(`api/deleteApprovals/${currCommunityId}/${id}`)
      );
    } else {
      setFormData((prev) => {
        const prevData = { ...prev };

        delete prevData[index];

        const refactor = {};

        Object.values(prevData).forEach((p, i) => {
          refactor[i] = p;
        });

        return refactor;
      });

      setDeleteId(null);
    }
  };

  function createUpdateApi() {
    createUpdateApproval(() =>
      api.post(`api/createUpdateApprovals/${currCommunityId}`, {
        approvals_list: Object.values(formData),
      })
    );
  }

  useEffect(() => {
    getTicketFieldsData();
  }, []);

  useEffect(() => {
    const approvals = approvalsData?.data || [];

    const refactor = {};

    if (approvals?.length) {
      approvals.forEach((approval, i) => {
        refactor[i] = approval;
      });
    } else {
      fieldsData?.forEach((field, i) => {
        const add = {
          custom_field_id: field.id,
          status: 0,
        };

        if (field.field_type === "input") {
          add.approve_value = "";
        }

        refactor[i] = add;
      });
    }

    setFormData(refactor);
  }, [approvalsData, fieldsData]);

  if (!fieldsData?.length) {
    return;
  }

  if (isLoading || isLoadingApprovals) {
    return <Spinner />;
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createUpdateApi();
        }}
      >
        <table class="table-auto w-full text-sm text-left text-gray-500 overflow-x-scroll">
          <thead className="text-gray-700 uppercase bg-gray-200 issue-list-title">
            <tr>
              {columns.map((column, i) => {
                return (
                  <th
                    className="py-3 cursor-pointer px-4 whitespace-nowrap"
                    key={i}
                  >
                    {column.name}
                  </th>
                );
              })}

              <th className="py-3 cursor-pointer px-4 whitespace-nowrap text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(formData).map((approval, i) => {
              const field =
                fieldsData.find((f) => f.id === approval.custom_field_id) || {};

              if (!approval) {
                return null;
              }

              return (
                <tr className="border-b" key={i}>
                  {columns.map((column, j) => {
                    const Comp = column.render({
                      approval,
                      field,
                      approvalIndex: i,
                    });

                    return (
                      <td className="py-4 px-4" key={j}>
                        {Comp}
                      </td>
                    );
                  })}

                  <td
                    className="py-4 px-4 text-center bg-red-50 hover:bg-red-100 cursor-pointer"
                    onClick={() =>
                      setDeleteId({
                        id: approval?.id,
                        index: i,
                      })
                    }
                  >
                    {approval.id && approval.id === deleteId ? (
                      <Spinner
                        customStyle={{ height: 24, width: 24, margin: "auto" }}
                      />
                    ) : (
                      <i className="fa-regular fa-trash-can text-xl text-red-600"></i>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <AddApproval
          options={getFieldsOptions(fieldsData)}
          onAddApproval={(selectedField) => {
            if (selectedField?.value) {
              const newApprovalIndex = Object.keys(formData)?.length;

              const add = {
                custom_field_id: selectedField?.value,
                status: 0,
              };

              if (selectedField.field_type === "input") {
                add.approve_value = "";
              }

              updateFormData(add, newApprovalIndex);
            }
          }}
        />

        <SubmitBtn loading={isCreateUpdateLoading} onCancel={closeModal} />
      </form>

      <DeleteModal
        title="Are you sure, you want to delete this approval?"
        open={deleteId}
        onClose={() => setDeleteId(null)}
        onDelete={() => deleteApprovalApi()}
        loading={isDeleteLoading}
      />
    </div>
  );
}

export default ManageApprovalForm;

function ToggleBtn({ props, data }) {
  const { formData, updateFormData } = props;

  const { approval = {}, approvalIndex } = data;

  const { custom_field_id } = approval;

  const existingFormData = formData[approvalIndex] || {};

  const { status } = existingFormData;

  return (
    <button
      onClick={() =>
        updateFormData(
          { status: status ? 0 : 1, custom_field_id },
          approvalIndex
        )
      }
      type="button"
    >
      {status ? (
        <i className="fa-solid fa-toggle-on text-green-500 text-[1.8rem]"></i>
      ) : (
        <i className="fa-solid fa-toggle-off text-[1.8rem]"></i>
      )}
    </button>
  );
}

function Input({ props, data }) {
  const { disabled, value, onChange = () => {}, required } = props || {};

  return (
    <input
      type="text"
      className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 ${
        disabled ? "cursor-not-allowed bg-gray-200" : ""
      }`}
      disabled={disabled}
      value={value}
      onChange={onChange}
      required={required}
    />
  );
}

function FieldValues({ props, data }) {
  const { approval = {}, approvalIndex, field = {} } = data;

  const { field_type, field_values, id } = field;

  const { formData, updateFormData } = props;

  const { custom_field_id } = approval;

  const existingFormData = formData[approvalIndex] || {};

  if (field_type === "input") {
    return <Input props={{ disabled: true, value: field_values }} />;
  }

  if (field_type === "select") {
    const options = field_values?.map((f) => ({ label: f, value: f }));

    return (
      <Select
        options={options}
        value={existingFormData?.approve_value?.map((v) => ({
          label: v,
          value: v,
        }))}
        isMulti
        onChange={(values) =>
          updateFormData(
            {
              approve_value: values.map((v) => v.value),
              custom_field_id,
            },
            approvalIndex
          )
        }
        styles={customStyles}
        required={true}
      />
    );
  }

  if (field_type === "dependency_dropdown") {
    return (
      <DependencyDropdown
        field={field}
        selectedValues={existingFormData?.approve_value}
        handleChange={(val) =>
          updateFormData(
            {
              approve_value: val,
              custom_field_id,
            },
            approvalIndex
          )
        }
        fromApprovalPage={true}
      />
    );
  }

  return "Unknown Type";
}

function FieldNames({ props, data }) {
  const { approvalIndex, field = {} } = data;

  const { field_type, field_name } = field;

  const { formData } = props;

  const existingFormData = formData[approvalIndex] || {};

  if (field_type === "dependency_dropdown" && field_name?.level1) {
    const { level1, level2, level3 } = field_name;

    const { approve_value = {} } = existingFormData;

    return (
      <div className="">
        {" "}
        <div className="my-2 border-l border-gray-400 pl-1">
          {level1 && <div>{level1}</div>}

          {approve_value?.level1 && level2 && (
            <div className="my-1 flex items-center">
              <div className="text-gray-400 whitespace-nowrap pr-1">--</div>{" "}
              {level2}
            </div>
          )}

          {approve_value?.level2 && level3 && (
            <div className="flex items-center">
              <div className="text-gray-400 whitespace-nowrap pr-1">----</div>{" "}
              {level3}
            </div>
          )}
        </div>
      </div>
    );
  }

  return <div>{field_name}</div>;
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 999 }),
  multiValue: (base) => ({
    ...base,
    zIndex: 100,
  }),
  control: (provided, state) => ({
    ...provided,
    width: "200px",
  }),
};

function getMembers(approvers = [], membersOptions) {
  const existingApprovers = [];

  approvers.forEach((a) => {
    if (a.approvers?.length) {
      a.approvers.forEach((approver) => existingApprovers.push(approver));
    }
  });

  const members = membersOptions.filter(
    (m) => !existingApprovers.includes(m.value)
  );

  return members;
}

const intialApprovers = [
  {
    level: 1,
    approvers: [],
    id: "",
  },
];

function Approvals({ props, data }) {
  const { membersOptions, updateFormData, formData } = props || {};

  const { approval = {}, approvalIndex } = data || {};

  const { custom_field_id } = approval;

  const existingFormData = formData[approvalIndex] || {};

  const { approvers = intialApprovers, remove_approvers = [] } =
    existingFormData;

  function handleApproversChange(values, level) {
    const existingApprovarData = approvers.find((a) => a.level === level) || {};

    const remainingData = approvers.filter((a) => a.level !== level);

    const data = {
      ...existingApprovarData,
      level,
      approvers: values.map((v) => v.value),
    };

    remainingData.push(data);

    updateFormData(
      {
        approvers: remainingData.sort((a, b) => a.level - b.level),
        custom_field_id,
      },
      approvalIndex
    );
  }

  const existingLevels = approvers?.length;

  function onAdd() {
    updateFormData(
      {
        approvers: [
          ...approvers,
          {
            level: existingLevels + 1,
            approvers: [],
            id: "",
          },
        ],
        custom_field_id,
      },
      approvalIndex
    );
  }

  function onDeleteLevel(level) {
    const removedData = approvers.find((a) => a.level === level);

    const remainingData = approvers.filter((a) => a.level !== level);

    const newData = [];

    remainingData.forEach((r, i) => {
      const level = i + 1;
      newData.push({
        ...r,
        level,
      });
    });

    const removedApprovers = [...remove_approvers];

    if (removedData?.id) {
      removedApprovers.push(removedData?.id);
    }

    updateFormData(
      {
        approvers: newData,
        remove_approvers: removedApprovers,
        custom_field_id,
      },
      approvalIndex
    );
  }

  return (
    <div className="pr-6">
      {approvers?.map((approver, i) => {
        const level = i + 1;

        const existingApprovers = approver?.approvers?.length
          ? approver?.approvers?.map((a) => ({
              label: a,
              value: a,
            }))
          : [];

        const isRequired = true;

        return (
          <div key={i} className="relative mt-4 flex items-center space-x-2">
            <div
              className={`absolute -top-3 left-2 bg-white px-2 text-gray-400 z-10 text-[12px] ${
                isRequired ? "required-input" : ""
              }`}
            >
              Level {level}
            </div>

            <Select
              options={getMembers(approvers, membersOptions)}
              value={existingApprovers}
              isMulti
              onChange={(values) => handleApproversChange(values, level)}
              menuPortalTarget={document.querySelector("body")}
              styles={customStyles}
              required={isRequired}
            />

            {level === 1 ? null : (
              <i
                className="fa-regular fa-trash-can text-lg cursor-pointer"
                onClick={() => onDeleteLevel(level)}
              ></i>
            )}
          </div>
        );
      })}

      {existingLevels >= 3 ? null : (
        <div className="flex w-full pl-2">
          <button
            className="text-indigo-600 py-1 text-xs border px-2 border-blue-300 rounded-md mt-2"
            onClick={onAdd}
            type="button"
          >
            + Add Level
          </button>
        </div>
      )}
    </div>
  );
}

function AddApproval({ options, onAddApproval }) {
  const [openSelectField, setOpenSelectField] = useState(false);
  const [selectedField, setSelectedField] = useState();

  return (
    <div className="mt-4">
      {openSelectField ? (
        <div className="flex items-center space-x-2">
          <Select
            options={options}
            onChange={(val) => setSelectedField(val)}
            menuPortalTarget={document.querySelector("body")}
            styles={customStyles}
          />

          <button
            className="cursor-pointer bg-indigo-600 py-2 px-4 text-white rounded-md"
            onClick={() => {
              onAddApproval(selectedField);
              setOpenSelectField(false);
            }}
            type="button"
          >
            + Add
          </button>

          <button
            className="cursor-pointer text-indigo-600 py-2 border-2 px-4 border-blue-300 rounded-md"
            onClick={() => setOpenSelectField(false)}
            type="button"
          >
            Cancel
          </button>
        </div>
      ) : (
        <button
          className="cursor-pointer text-indigo-600 py-2 border-2 px-4 border-blue-300 rounded-md"
          onClick={() => setOpenSelectField(true)}
          type="button"
        >
          + Add Approval
        </button>
      )}
    </div>
  );
}

function SubmitBtn({ loading, onCancel }) {
  return (
    <div className="flex items-center justify-center space-x-4 mt-6">
      <button
        className="cursor-pointer bg-indigo-600 py-2 px-4 text-white rounded-md flex items-center space-x-2"
        type="submit"
      >
        {loading && <Spinner customStyle={{ width: 20, height: 20 }} />}
        Submit
      </button>

      <button
        className="cursor-pointer text-red-600 py-2 border-2 px-4 border-red-300 rounded-md"
        onClick={onCancel}
        type="button"
      >
        Cancel
      </button>
    </div>
  );
}
