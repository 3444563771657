import { useCallback, useContext } from "react";
import api from "../api/api";
import { AppContext } from "../App";

function useCommonApi() {
  const { currCommunityId } = useContext(AppContext);

  const searchMember = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/getCommunityMembers/${currCommunityId}`, payload);
    },
    [currCommunityId]
  );

  return {
    searchMember,
  };
}

export default useCommonApi;
