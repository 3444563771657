const isColorDark = (hexColor) => {
  // You can customize this darkness threshold based on your preference
  const brightnessThreshold = 128;

  // Convert hex to RGB
  const rgb = hexToRgb(hexColor);

  // Calculate brightness (using the Y component of the YIQ color space)
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

  // Check if the color is dark
  return brightness < brightnessThreshold;
};

const hexToRgb = (hex) => {
  // Remove the hash if it's included
  hex = hex.replace(/^#/, "");

  // Parse the hex values
  const bigint = parseInt(hex, 16);

  // Extract RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

const handleColorChange = (e, updateConfig, onWarning) => {
  const inputColor = e.target.value;

  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  if (hexColorRegex.test(inputColor)) {
    updateConfig({ color: inputColor });
  }

  if (!isColorDark(inputColor)) {
    onWarning("Choose dark colors for better experience");
  }
};

function ChooseColor({ color, updateConfig, onWarning }) {
  return (
    <div className="relative flex gap-2">
      <input
        className="w-32 h-10 bg-gray-100 border border-gray-300 rounded-md shadow-sm  block  appearance-none leading-normal focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        type="color"
        name="subject"
        autoComplete="off"
        placeholder="Enter subject"
        value={color}
        onChange={(e) => handleColorChange(e, updateConfig, onWarning)}
      />

      <input
        id="hexcolor"
        className="p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
        value={color}
        type="text"
        disabled
      />
    </div>
  );
}

export default ChooseColor;
