import React from "react";
import Loading from "../loaders/Loading";

function LoadMoreBtn({ hasMore, loading, onLoad }) {
  return (
    <div className="w-full flex justify-center pr-4">
      {" "}
      {hasMore ? (
        <button
          className="flex items-center space-x-2 bg-gray-500 px-4 py-2 my-4 rounded-full text-white w-fit mx-auto text-sm"
          disabled={loading}
          onClick={onLoad}
          type="button"
        >
          {loading && <Loading />}
          Load More
        </button>
      ) : null}
    </div>
  );
}

export default LoadMoreBtn;
