import React, { useContext, useEffect, useState } from "react";
import CmHeader from "../../components/customerManagement/CmHeader";
import UsersTabs from "../../components/customerManagement/UsersTabs";
import OfflineUsersTable from "../../components/customerManagement/offlineUsers/OfflineUsersTable";
import Pagination from "../../components/customerManagement/Pagination";
import { AppContext } from "../../App";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import OfflineUsersStatusSelect from "../../components/customerManagement/offlineUsers/OfflineUsersStatusSelect";
import TableSkeleton from "../../components/loaders/TableSkeleton";
import SearchInput from "../../components/common/SearchInput";

function OfflineUsers() {
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const page = searchParams.get("page") || 1;

  const status = searchParams.get("status");

  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);

  const { data, mutate, loading } = useApiData({});

  const { mutate: updateUserApi, loading: updateOfflineUserLoading } =
    useApiData({
      onSuccess: (res) => {
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Data Updated Successfully",
        });
        setSelectedUser("");
      },
      onError: () => {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong While updating status",
        });
        setSelectedUser("");
      },
    });

  function getUsersList() {
    let endpoint = `api/contactClientList/${currCommunityId}?page=${page}`;

    if (search.length >= 3) {
      endpoint += `&search=${search}`;
    }

    if (status !== null) {
      endpoint += `&status=${status}`;
    }

    mutate(() => api.get(endpoint));
  }

  function updateUser(clientId, status) {
    let endpoint = `api/contactClientUpdate/${currCommunityId}/${clientId}`;

    updateUserApi(() =>
      api.post(endpoint, {
        status,
      })
    );
  }

  useEffect(() => {
    getUsersList();
  }, [page, status, search]);

  return (
    <div className="pb-12 pt-7 px-2">
      <CmHeader />

      <div className="flex justify-between w-full mt-4">
        <div className="w-[30%]"></div>

        <UsersTabs />

        <div className="flex space-x-4">
          <div>
            <SearchInput
              onSearch={(e) => {
                const { name, value } = e.target;
                setSearch(value);
              }}
            />
          </div>

          <OfflineUsersStatusSelect
            defaultValue={searchParams.get("status")}
            onChange={(selectedOption) => {
              const value = selectedOption?.value;
              const queries = Object.fromEntries([...searchParams]);

              if (value !== undefined) {
                queries.status = selectedOption.value;
              } else {
                delete queries.status;
              }

              navigate({
                pathname: location.pathname,
                search: `?${createSearchParams(queries)}`,
              });
            }}
          />
        </div>
      </div>

      {loading ? (
        <TableSkeleton />
      ) : (
        <OfflineUsersTable
          data={data}
          updateUser={(clientId, status) => {
            updateUser(clientId, status);
            setSelectedUser(clientId);
          }}
          updateLoading={updateOfflineUserLoading}
          selectedUser={selectedUser}
        />
      )}

      {data ? (
        <Pagination data={data} handlePageChange={(pageNumber) => {}} />
      ) : null}
    </div>
  );
}

export default OfflineUsers;
