import { useState } from "react";
import Button from "../../../components/common/Button";
import Loading from "../../../components/loaders/Loading";
import useDropDown from "../../../hooks/useDropDown";

const DisplayPortalStatus = ({ status, onClick, loading }) => {
  const [open, setOpen] = useState(false);

  const { elementRef } = useDropDown({ open, setOpen });

  if (loading) {
    return <Loading />;
  }

  return (
    <div ref={elementRef} className="relative">
      {loading ? <Loading /> : null}

      <Button
        className={`${
          loading ? "hidden" : ""
        } flex w-fit py-2 px-4 rounded focus:outline-none font-semibold border border-gray-300 rounded-md ${
          status ? "bg-green-100 text-green-600" : ""
        }`}
        onClick={() => setOpen(!open)}
      >
        {status ? "Enabled" : "Disabled"}
        <i className="fa-solid fa-chevron-down pl-4"></i>
      </Button>

      {open ? (
        <Button
          className="absolute top-11 left-0 bg-white border border-gray-300 px-4 py-2 z-40 w-full rounded-md text-left hover:bg-gray-100"
          onClick={() => {
            onClick(status);
            setOpen(false);
          }}
        >
          {status ? "Disable" : "Enable"}
        </Button>
      ) : null}
    </div>
  );

  return (
    <Button
      className={`w-fit py-2 px-4 rounded focus:outline-none font-semibold ${
        !status
          ? "bg-gray-100 border border-gray-200 text-gray-700"
          : "bg-green-100 border border-green-100 text-green-600"
      }`}
      onClick={() => onClick(status)}
    >
      {status ? "Enabled" : "Disabled"}
    </Button>
  );
};

export default DisplayPortalStatus;
