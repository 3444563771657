import { useContext, useRef, useState } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import { useParams } from "react-router-dom";
import FileCard from "./FileCard";
import Button from "../common/Button";
import Loading from "../loaders/Loading";

const TicketAttachments = (props) => {
  const [processing, setProcessing] = useState(false);
  const inputFileRef = useRef(null);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const { id } = useParams();

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      var payload = {
        filesData: attachmentFiles,
        community_id: currCommunityId,
        ticket_id: id,
      };
      api.post("/api/addAttachments", payload).then((response) => {
        setProcessing(false);
        props.handleTicketDetail(response.data.data);
      });
    } catch (error) {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleRemoveAttach = (attachId) => {
    var payload = {
      id: attachId,
      community_id: currCommunityId,
      ticket_id: id,
    };
    api.post("/api/removeAttachment", payload).then((response) => {
      setProcessing(false);
      props.handleTicketDetail(response.data.data);
    });
  };

  return (
    <div className="mt-16">
      <h4 className="font-semibold text-xl pb-2 border-b border-gray-300">
        Attachments
      </h4>

      <div className="flex flex-wrap gap-4 my-6">
        {props.ticketDetail?.ticketAttachments?.map((attach, index) => (
          <div className="">
            <FileCard
              key={attach.id}
              file={attach}
              index={index}
              handleRemoveAttachment={() => handleRemoveAttach(attach.id)}
            />
          </div>
        ))}

        <Button
          className="text-indigo-600 font-medium hover:bg-gray-100 w-full py-3 border border-gray-300 rounded-md flex justify-center items-center"
          onClick={handleSelectButtonClick}
        >
          {processing ? <Loading /> : null}
          <img
            src="/images/icons/attachment-blue.svg"
            alt="attachment"
            className="mr-2"
          />
          Attach File
          <input
            type="file"
            className="hidden"
            ref={inputFileRef}
            onChange={handleFileUpload}
            multiple
          />
        </Button>

        {/* <button
          className="flex bg-indigo-500 text-white px-4 py-2 rounded  z-10"
          onClick={handleSelectButtonClick}
          type="button"
          disabled={processing}
        >
          {processing && (
            <svg
              className="animate-spin -ml-1 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Select
        </button>
        <input
          type="file"
          className="hidden"
          ref={inputFileRef}
          onChange={handleFileUpload}
          multiple
        /> */}
      </div>
    </div>
  );
};

export default TicketAttachments;
