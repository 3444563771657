import TemplateCard from "./TemplateCard";

function ListTemplates({ templates, onEdit, onDelete }) {
  if (!templates?.length) {
    return;
  }

  return (
    <div className="my-6">
      <div className="grid md:grid-cols-4 gap-6 w-fit">
        {templates.map((template, i) => {
          return (
            <TemplateCard
              template={template}
              key={i}
              isEditable={true}
              onEdit={() => onEdit(template)}
              onDelete={() => onDelete(template)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ListTemplates;
