import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../App";
import useApiConfiguration from "../../configuration/apiConfiguration/useApiConfiguration";
import useApiData from "../../../../../hooks/useApiData";
import usePropertyConfiguration from "../../configuration/propertyConfiguration/usePropertyConfiguration";
import Loading from "../../../../loaders/Loading";
import Button from "../../../../common/Button";

const insertOptions = [
  { label: "APIs", value: "API" },
  { label: "Properties", value: "properties" },
];

function DefaultInsertOptions(props) {
  const [options, setOptions] = useState({
    page: 1,
    type: "",
    options: insertOptions,
  });

  const [loading, setLoading] = useState(false);

  const { id, botflowId } = useParams();

  const { onError } = useContext(AppContext);

  const { getApiConfigurations } = useApiConfiguration(id, botflowId);

  const { getPropertyConfigurations } = usePropertyConfiguration(id, botflowId);

  const {
    data: apiData,
    mutate: getApiList,
    loading: apiLoading,
  } = useApiData({
    onSuccess: (res) => {
      let options = res.data?.data || [];

      const refactor = [];

      let count = 0;

      options.forEach((o) => {
        if (o.uuid !== props.configurationId) {
          count++;

          refactor.push({
            label: `${count}. ${o.apiName}`,
            value: o.uuid,
          });
        }
      });

      setOptions({
        page: 2,
        options: refactor,
      });
    },
    onError: () => onError("Something went wrong while getting apis"),
  });

  const { mutate: getPropertyList, loading: propertyLoading } = useApiData({
    onSuccess: (res) => {
      let options = res.data?.data || [];

      const refactor = options.map((o) => ({
        label: o.variableName,
        value: o.variableName,
        variableType: o.variableType,
        variableConfigurationId: o.uuid,
        prefixString: "property",
      }));

      setOptions({
        page: 2,
        type: "properties",
        options: refactor,
      });
    },
    onError: () => onError("Something went wrong while getting properties"),
  });

  const apiList = apiData?.data || [];

  return (
    <div>
      {apiLoading || propertyLoading || loading ? (
        <div className="flex justify-center mx-auto my-4">
          <Loading />
        </div>
      ) : null}

      {options.type === "properties" ? (
        <DisplayProperties
          {...options}
          onOptionsSelect={(value, page, option) => {
            props.onInsert(value, option);
            props.setOpen(false);
          }}
        />
      ) : null}

      {options.type ? null : (
        <DisplayOptions
          {...options}
          onOptionsSelect={(value, page, option) => {
            if (page === 1) {
              if (value === "API") {
                getApiList(getApiConfigurations());
              } else if (value === "properties") {
                getPropertyList(getPropertyConfigurations());
              }
            } else if (page === 2) {
              const api = apiList.find((api) => api.uuid === value) || {};

              const parameters = api?.usingParameters || [];

              setLoading(true);

              setTimeout(() => {
                setOptions({
                  page: 3,
                  options: parameters.map((p) => ({
                    label: p,
                    value: p,
                    apiConfigurationId: value,
                    prefixString: api.apiName,
                  })),
                });

                setLoading(false);
              }, 200);
            } else if (page === 3) {
              props.onInsert(value, option);
              props.setOpen(false);
            }
          }}
        />
      )}
    </div>
  );
}

export default DefaultInsertOptions;

export function DisplayOptions({ options, page, onOptionsSelect }) {
  let opts = options?.length ? options : [{ label: "No Options", value: "" }];
  return (
    <>
      {opts.map((o, i) => {
        const { label, value } = o;
        return (
          <Button
            key={i}
            className="py-1.5 px-4 hover:bg-gray-100 w-full text-left border-b"
            onClick={() => onOptionsSelect(value, page, o)}
            //*** insert-data id is used for finding the element is clicked inside or outside dialog
            id="insert-data"
          >
            {label}
          </Button>
        );
      })}
    </>
  );
}

function DisplayProperties({ options, page, onOptionsSelect }) {
  let opts = options?.length ? options : [{ label: "No Options", value: "" }];

  const userProperties = opts.filter(
    (o) => o.variableType === "USER_PROPERTIES"
  );

  const botProperties = opts.filter((o) => o.variableType === "BOT_PROPERTIES");

  return (
    <div className="p-2">
      <div className="font-semibold mb-2 px-2 whitespace-nowrap">
        Bot Properties
      </div>

      <DisplayOptions
        options={botProperties}
        page={page}
        onOptionsSelect={onOptionsSelect}
      />

      <div className="font-semibold my-2 px-2 whitespace-nowrap">
        User Properties
      </div>

      <DisplayOptions
        options={userProperties}
        page={page}
        onOptionsSelect={onOptionsSelect}
      />
    </div>
  );
}
