function SubdomainInput({ isError, handleChange, inputProps }) {
  const props = inputProps ? inputProps : {};

  return (
    <div
      className={`flex justify-between border ${
        isError ? "border-red-600" : "border-gray-300"
      } rounded-md text-sm`}
    >
      <input
        className="outline-none p-2 rounded-md"
        type="text"
        name="subdomain"
        autoComplete="off"
        placeholder="subdomain"
        onChange={handleChange}
        {...props}
      />

      <div className="rounded-r-md bg-gray-200 w-fit px-4 flex items-center justify-center font-semibold border text-gray-600 text-sm">
        .rhinoassist.com
      </div>
    </div>
  );
}

export default SubdomainInput;
