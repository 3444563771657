function Radio({ options, selectedValue, onChange, containerCls }) {
  return (
    <div
      className={`flex flex-col space-y-2 font-medium text-gray-700 ${containerCls}`}
    >
      {options.map((option) => (
        <label key={option.value} className="">
          <input
            type="radio"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => onChange(option.value)}
            className="mr-2"
          />
          {option.label}
        </label>
      ))}
    </div>
  );
}

export default Radio;
