import Button from "../common/Button";

function ButtonOptions({ options, onOptionChange, isSelected }) {
  return (
    <div className="flex items-center space-x-3">
      {options.map((option, i) => {
        const { label, value } = option;

        const selected = isSelected(value);

        return (
          <Button
            className={`px-4 py-2 rounded-md font-medium min-w-[60px] ${
              selected
                ? "text-white bg-secondary-600"
                : "border border-gray-300"
            }`}
            onClick={() => onOptionChange(value)}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
}

export default ButtonOptions;
