import { useState } from "react";
import Loading from "../loaders/Loading";
import QuillInput from "../common/QuillInput";
import Button from "../common/Button";
import { isQuillHavingText } from "../common/commonHelpers";

function TicketContent({
  description,
  setDescription,
  ticketContent,
  updateTicket,
  isUpdating,
}) {
  const [edit, setEdit] = useState(false);

  if (isUpdating) {
    return <Loading />;
  }

  return (
    <div className="text-sm min-w-full whitespace-pre-line">
      {edit ? (
        <div className="break-words	w-[56vw]">
          <QuillInput
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="content"
          />

          <div className="flex items-center space-x-2 my-4 font-semibold text-sm">
            <Button
              className="bg-indigo-600 text-white px-4 py-2 rounded-md"
              onClick={() => {
                updateTicket(description);
                setEdit(false);
              }}
            >
              Save
            </Button>

            <Button
              className="text-gray-700 px-4 py-2 rounded-md"
              onClick={() => {
                setDescription(ticketContent);
                setEdit(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="py-2 hover:bg-gray-100" onClick={() => setEdit(true)}>
          {isQuillHavingText(description) ? (
            <div
              className="w-[56vw] break-words"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            "Add a description"
          )}
        </div>
      )}
    </div>
  );
}

export default TicketContent;
