import { useCallback, useContext } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";

function useTicketTeamAssign() {
  const { currCommunityId } = useContext(AppContext);

  const addTeamToTicket = useCallback(
    (id, payload) => {
      return () =>
        api.post(`/api/${currCommunityId}/addTeamToTicket/${id}`, payload);
    },
    [currCommunityId]
  );

  const getTicketAssignees = useCallback(
    (id) => {
      return () => api.get(`/api/${currCommunityId}/getTicketAssignees/${id}`);
    },
    [currCommunityId]
  );

  const removeTeamFromTicket = useCallback(
    (id, uuid) => {
      return () =>
        api.delete(
          `/api/${currCommunityId}/removeTeamFromTicket/${id}/${uuid}`
        );
    },
    [currCommunityId]
  );

  const searchTeamMembers = useCallback(
    (id, payload) => {
      return () =>
        api.post(`/api/${currCommunityId}/searchTeamMembers/${id}`, payload);
    },
    [currCommunityId]
  );

  const ticketAssign = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/ticketAssign`, {
          ...payload,
          community_id: currCommunityId,
        });
    },
    [currCommunityId]
  );

  const removeTicketAssign = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/removeTicketAssign`, {
          ...payload,
          community_id: currCommunityId,
        });
    },
    [currCommunityId]
  );

  const updateTicketTeamStatus = useCallback(
    (id, uuid, payload) => {
      return () =>
        api.put(
          `/api/${currCommunityId}/updateTicketTeamStatus/${id}/${uuid}`,
          payload
        );
    },
    [currCommunityId]
  );

  // const getTicketClockWork = useCallback(
  //   (ticket_id) => {
  //     return () =>
  //       api.get(`/api/${currCommunityId}/getTicketClockWork/${ticket_id}`);
  //   },
  //   [currCommunityId]
  // );

  // const getTicketComments = useCallback(
  //   (ticket_id) => {
  //     return () =>
  //       api.get(`/api/${currCommunityId}/getTicketComments/${ticket_id}`);
  //   },
  //   [currCommunityId]
  // );

  // const getTicketHistory = useCallback(
  //   (ticket_id) => {
  //     return () =>
  //       api.get(`/api/${currCommunityId}/getTicketHistory/${ticket_id}`);
  //   },
  //   [currCommunityId]
  // );

  return {
    getTicketAssignees,
    addTeamToTicket,
    removeTeamFromTicket,
    searchTeamMembers,
    ticketAssign,
    removeTicketAssign,
    updateTicketTeamStatus,
  };
}

export default useTicketTeamAssign;
