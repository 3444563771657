import { useEffect, useRef, useState } from "react";
import Loading from "../loaders/Loading";

const ClientCard = ({
  client,
  onRemove,
  isRemoveTicketClientLoading,
  onSetAsPrimary,
  isSetAsPrimaryClientTicketLoading,
}) => {
  const [open, setOpen] = useState(false);
  const btnRef = useRef();
  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);
  return (
    <a
      href={`customer-management?page=1&client_id=${client?.client_id}`}
      className="hover:bg-gray-100 w-full rounded-b-md px-3 py-2 text-gray-700 text-md bg-white flex items-center justify-between mt-2"
      target="_blank"
      rel="noreferrer"
    >
      {/* {JSON.stringify(userDetails)} */}
      <div className="flex">
        <div className="user-profile">
          {client && client.client_name
            ? Array.from(client.client_name)[0]
            : "C"}
        </div>
        <div className="detailsUser">
          <div className="userName flex gap-2">
            {client.client_name ? client.client_name : "No Name"}
            {client.is_primary == 1 && (
              <div className="bg-green-100 text-green-500 px-1 text-sm">
                Primary
              </div>
            )}
          </div>
          <div className="others text-sm whitespace-normal break-words w-[200px]">
            {client.client_email ? client.client_email : "No Email"}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="dropdown flex relative" ref={btnRef}>
          <div className="dropdown-toggle" id="dropdown-toggle-873-eVCGyHdcfE">
            <button
              className="button bg-transparent border border-transparent hover:bg-gray-50 active:bg-gray-100 text-gray-600 radius-circle h-7 w-7 inline-flex items-center justify-center text-base"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
              </svg>
            </button>
          </div>
          <ul
            id="base-menu-124-eIThs96oYp"
            className={`dropdown-menu bottom-end ${
              open ? "block" : "hidden"
            } right-[0%] min-w-[100px] top-[20px]`}
            style={{
              opacity: 1,
              visibility: "visible",
              transform: "rotateX(0deg)",
            }}
          >
            <div
              className={`menu-item menu-item-dark menu-item-hoverable ${
                client?.is_primary == 1
                  ? "text-green-500 cursor-default"
                  : "hover:bg-gray-50"
              }`}
              style={{ height: "35px" }}
              onClick={(e) => {
                e.preventDefault();
                client?.is_primary != 1 && onSetAsPrimary(client.uuid);
              }}
            >
              {isSetAsPrimaryClientTicketLoading && <Loading />}
              <span className="text-base">
                <i class="fa-regular fa-circle-check"></i>
              </span>
              <span className="ml-2 rtl:mr-2">
                {client?.is_primary == 1
                  ? "Setted as Primary"
                  : "Set as primary"}
              </span>
            </div>

            <li
              className="menu-item menu-item-dark menu-item-hoverable text-red-500 hover:text-white hover:bg-red-500"
              style={{ height: "35px" }}
              onClick={(e) => {
                e.preventDefault();
                onRemove(client.uuid);
              }}
            >
              {isRemoveTicketClientLoading && <Loading />}
              <span className="text-base">
                <i className="fa-regular fa-trash-can"></i>
              </span>
              <span className="ml-2 rtl:mr-2">Remove</span>
            </li>
          </ul>
        </div>
      </div>
    </a>
  );
};

export default ClientCard;
