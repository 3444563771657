import Select from "react-select";
import {
  customReactSelectStyles,
  extractDynamicProperties,
  getReactSelectOptions,
  insertPropertyToInput,
  propertySplit,
} from "../../../../common/commonHelpers";
import CreatableSelect from "react-select/creatable";
import Insert from "../common/Insert";
import SearchInput from "../../../../common/SearchInput";
import { useParams } from "react-router-dom";
import usePropertyConfiguration from "../../configuration/propertyConfiguration/usePropertyConfiguration";
import useApiData from "../../../../../hooks/useApiData";
import { useContext, useEffect, useRef, useState } from "react";
import Spinner from "../../../../loaders/Spinner";
import InsertDialogOptions from "./InsertDialogOptions";
import { AppContext } from "../../../../../App";

function handleReference(reference, data, flowName) {
  const { uuid, displayOrder } = data || {};

  const existingReference = reference.find(
    (r) => r.uuid === uuid && r.flowName === flowName
  );

  if (existingReference) {
    return reference;
  }

  const newReference = [...reference];

  const referenceData = { uuid, displayOrder, type: "DIALOGUE", flowName };

  newReference.push(referenceData);

  return newReference;
}

function SelectProperty({
  propertyData,
  onSetProperty,
  onSetPropertyName,
  configurationId,

  error,
}) {
  const { enumSaveIn, key, value, reference } = propertyData || {};

  return (
    <div>
      <SaveInProperties
        onSetProperty={onSetProperty}
        value={enumSaveIn}
        onSetPropertyName={onSetPropertyName}
        error={error?.enumSaveIn}
      />

      {enumSaveIn ? (
        <PropertyName
          enumSaveIn={enumSaveIn}
          onSetPropertyName={onSetPropertyName}
          value={key}
          configurationId={configurationId}
          error={error?.key}
        />
      ) : null}

      {key ? (
        <PropertyValue
          onSetProperty={onSetProperty}
          value={value}
          reference={reference || []}
          error={error?.value}
        />
      ) : null}
    </div>
  );
}

export default SelectProperty;

const saveInOptions = [
  {
    label: "User Properties",
    value: "USER_PROPERTIES",
  },
  {
    label: "Bot Properties",
    value: "BOT_PROPERTIES",
  },
];

function SaveInProperties({ onSetPropertyName, value, error }) {
  return (
    <div>
      <div className="text-gray-500 my-1.5 mt-3 font-semibold required-input">
        Save in
      </div>

      <Select
        className={`text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 ${
          error ? "border-red-600" : ""
        }`}
        options={saveInOptions}
        onChange={(val) => {
          onSetPropertyName(
            {
              enumSaveIn: val.value,
              key: null,
              value: null,
              reference: null,
            },
            null
          );

          // saveAction.current = false;
        }}
        value={saveInOptions.find((s) => s.value === value)}
        styles={customReactSelectStyles}
      />
    </div>
  );
}

function PropertyName({
  enumSaveIn,
  onSetPropertyName,
  value,
  configurationId,
  error,
}) {
  const { onSuccess, onError } = useContext(AppContext);
  const { id, botflowId } = useParams();

  const createdProperty = useRef(null);

  const { getPropertyConfigurations, createPropertyConfiguration } =
    usePropertyConfiguration(id, botflowId);

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: (res) => {
      const data = res?.data?.data || [];

      if (createdProperty?.current) {
        const existingData =
          data.find((d) => {
            const variableName =
              d?.variableName === createdProperty?.current?.variableName;

            const variableType =
              d?.variableType === createdProperty?.current?.variableType;

            return variableName && variableType;
          }) || {};

        const { variableName, uuid } = existingData;

        onSetPropertyName(
          {
            key: variableName,
            value: null,
            reference: null,
          },
          {
            variableName: variableName,
            uuid,
          }
        );

        createdProperty.current = null;
      }
    },
    onError: () => {},
  });

  const { mutate: create, loading: isCreateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Property Created Successfully");
      console.log("res", res);

      getList(getPropertyConfigurations());
    },
    onError: () => onError("Something went wrong while creating property"),
  });

  useEffect(() => {
    getList(getPropertyConfigurations());
  }, []);

  const propertiesList = data?.data || [];

  const properties = propertiesList.filter(
    (p) => p.variableType === enumSaveIn
  );

  const refactor = properties?.map((p) => {
    return {
      label: p.variableName,
      value: p.uuid,
    };
  });

  return (
    <div>
      <div className="text-gray-500 my-1.5 mt-3 font-semibold required-input">
        Property name
      </div>

      {isCreateLoading ? "Creating..." : null}

      {loading ? (
        <Spinner />
      ) : (
        <CreatableSelect
          className={`h-auto manageCustomField ${
            error ? "border-red-600" : ""
          }`}
          onChange={(val = {}, actions) => {
            const { label, value, __isNew__ } = val || {};
            if (__isNew__ && val?.value) {
              const createPayload = {
                variableName: val?.value,
                variableType: enumSaveIn,
              };
              createdProperty.current = createPayload;

              create(createPropertyConfiguration(createPayload));

              return;
            }

            onSetPropertyName(
              {
                key: label,
                value: null,
                reference: null,
              },
              {
                variableName: label,
                uuid: value,
              }
            );
          }}
          value={
            configurationId
              ? {
                  label: configurationId?.variableName,
                  value: configurationId?.uuid,
                }
              : null
          }
          options={refactor}
          placeholder="Type or select property name..."
          isClearable
        />
      )}
    </div>
  );
}

function PropertyValue({ onSetProperty, value, reference, error }) {
  const propertyValueInputRef = useRef();

  return (
    <div>
      <div className="text-gray-500 my-1.5 mt-3 font-semibold required-input">
        Property value
      </div>

      <div
        className={`mt-2 flex justify-between items-center border border-gray-300 bg-white relative pr-2 ${
          error ? "border-red-600" : ""
        }`}
      >
        <input
          type="text"
          className="w-11/12 p-2 outline-none text-sm"
          placeholder="property value"
          onChange={(e) => {
            const { value } = e.target;

            const newReference = [];

            // pushing only the available reference's, while user types
            reference.forEach((r) => {
              const property = `{{${r.flowName}: ${r.displayOrder}}}`;

              if (value.includes(property)) {
                newReference.push(r);
              }
            });

            onSetProperty({
              value,
              reference: newReference?.length ? newReference : null,
            });
          }}
          value={value}
          ref={propertyValueInputRef}
        />

        <Insert optionsCls="left-10">
          <InsertDialogOptions
            onInsert={(insertValue, option, flowName) => {
              insertPropertyToInput(
                propertyValueInputRef,
                insertValue,
                (value) => {
                  onSetProperty({
                    value,
                    reference: handleReference(
                      reference,
                      option?.data,
                      flowName
                    ),
                  });
                }
              );
            }}
          />
        </Insert>
      </div>
    </div>
  );
}
