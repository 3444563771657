const CardSkeleton = () => {
  return (
    <div className="mt-6 h-full flex flex-col">
      <div className="flex flex-wrap gap-4">
        {Array.from({ length: 6 }).map((_, index) => (
          <div className="card card-border">
            <div className="card-body h-full folder-card">
              <div className="flex flex-col justify-between h-full">
                <div className="cursor-pointer">
                  <div className="flex justify-between">
                    <span
                      className="skeleton"
                      style={{ height: "20px", width: "85%" }}
                    ></span>
                    <span
                      className="skeleton ml-2"
                      style={{ height: "20px", width: "15%" }}
                    ></span>
                  </div>
                  <p className="mt-2 chatDescription">
                    <span
                      className="skeleton"
                      style={{ height: "60px", width: "100%" }}
                    ></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSkeleton;
