import { useState } from "react";

function findMaxLevel(approvedBy) {
  let maxLevel = 0;

  approvedBy.forEach((item) => {
    const levels = Object.keys(item.level);
    const maxItemLevel = Math.max(...levels);

    if (maxItemLevel > maxLevel) {
      maxLevel = maxItemLevel;
    }
  });

  return maxLevel;
}

function getColumns(approvals) {
  const columns = [
    {
      name: "Approvers",
      displayData: (data) => data.label,
    },
  ];

  const maxLevels = findMaxLevel(approvals);

  Array.from({ length: maxLevels }).forEach((_, i) => {
    const level = i + 1;

    columns.push({
      name: `Level ${level}`,
      displayData: (data) => {
        const levelData = data.level[level] || {};

        const status =
          typeof levelData.status === "number" ? levelData.status : null;

        return <StatusChip status={status} emails={levelData.emails} />;
      },
    });
  });

  return columns;
}

function TicketApprovalStatus({ approvedBy = {} }) {
  const approvals = Object.values(approvedBy) || [];

  const columns = getColumns(approvals);

  if (!approvals.length) {
    return;
  }

  return (
    <div className="border border-gray-300 rounded-md bg-white p-6 mt-6">
      <h4 className="font-semibold text-xl pb-2 border-b border-gray-300">
        Approvals
      </h4>

      <div className="overflow-x-auto mt-4">
        <table className="min-w-full text-sm text-left text-gray-500">
          <thead className="text-gray-700 uppercase bg-gray-50 issue-list-title">
            <tr>
              {columns?.map((col, i) => {
                return (
                  <th
                    key={i}
                    className={`py-3 px-2 whitespace-nowrap bg-white !text-gray-500 ${
                      i === 0 ? "sticky top-0 left-0" : "text-center"
                    }`}
                  >
                    {col.name}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {approvals.map((approval, i) => {
              return (
                <tr key={i} className="border-b">
                  {columns?.map((col, i) => {
                    const data = col.displayData(approval) || {};

                    return (
                      <td
                        key={i}
                        className={`py-4 px-2 bg-white ${
                          i === 0 ? "sticky left-0" : "text-center"
                        }`}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TicketApprovalStatus;

function StatusChip({ status, emails = [] }) {
  const [isHovering, setIsHovering] = useState(false);

  if (status === 1) {
    return (
      <ApproversToolTip emails={emails}>
        <div className="mx-auto text-[11px] w-fit p-0.5 px-2 text-center text-green-600 rounded-full border border-green-600 bg-green-100">
          Approved
        </div>
      </ApproversToolTip>
    );
  }

  if (status === 0) {
    return (
      <ApproversToolTip emails={emails}>
        <div className="mx-auto text-[11px] w-fit p-0.5 px-2 text-center text-yellow-700 rounded-full border border-yellow-600 bg-yellow-50">
          Pending
        </div>
      </ApproversToolTip>
    );
  }

  return <div className="text-center">-</div>;
}

function ApproversToolTip({ children, emails }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className="relative flex items-center mx-auto w-fit space-x-2">
      {children}

      <i
        class="fa-solid fa-circle-info text-gray-400 cursor-pointer"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      ></i>

      {isHovering ? (
        <div className="py-2 px-4 bg-white rounded-lg shadow-md border absolute bottom-6 right-0 text-gray-700 text-xs">
          <div className="font-semibold pb-1 border-b">Approvers</div>
          <div className="mt-2">{emails.join(", ")}</div>
        </div>
      ) : null}
    </div>
  );
}
