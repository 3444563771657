import { useEffect, useRef, useState } from "react";
import api from "../../api/api";
import io from "socket.io-client";
import AssignDropdown from "../common/AssignDropdown";
import { Link, useLocation } from "react-router-dom";
import ToolTip from "../common/ToolTip";

const TicketRow = (props) => {
  const [assignModal, setAssignModal] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const btnRef = useRef(null);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        btnRef.current &&
        !btnRef.current.contains(e.target) &&
        e.target.closest("#assign-modal-chat") == null
      ) {
        setAssignModal(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const submitAssign = (id, ticketId) => {
    var postData = {
      client_id: props.user_id,
      status: "",
      community_id: props.currCommunityId,
      handled_by: id,
      ticket_id: ticketId,
    };
    setLoadingAssign(true);
    api
      .post("/api/ticketAssign", postData)
      .then((response) => {
        if (response.data.status == "success") {
          // console.log("response.data.data", response.data.data);
          props.getAllTickets();
          setLoadingAssign(false);
        }
      })
      .catch(() => {
        setLoadingAssign(false);
      });
  };

  function Avatar({ name }) {
    const colors = [
      "red",
      "blue",
      "green",
      "yellow",
      "indigo",
      "purple",
      "pink",
    ];
    const color = colors[Math.floor(Math.random() * colors.length)];
    const initials = name
      ?.split(" ")
      ?.map((word) => word[0])
      ?.join("");

    return (
      <div
        className={`bg-gray-100 assignMember cursor-pointer hover:shadow-md duration-300 ease`}
      >
        {initials}
      </div>
    );
  }

  return (
    <tr
      key={props.index}
      className="bg-white border-b border-gray-300 issue-lists"
    >
      {props.activeColumns["Issue No"] && (
        <th
          scope="row"
          className="text-primary-600 px-6 py-4 font-medium whitespace-nowrap  ticket-id"
        >
          <Link to={`/ticket-detail/${props.ticket.ticket_id}`}>
            {props.ticket.ticket_id}
          </Link>
        </th>
      )}
      {props.activeColumns["Topic"] && (
        <td className="px-6 py-4 text-[#070822]">{props.ticket.topic}</td>
      )}
      {/* {props.activeColumns["Assignee"] && (
        <td className="px-6 py-4">
          <div className="assignParentDiv">
            <div className="flex assignList">
              {props.ticket?.assignedMembers?.map((data, index) => (
                <ToolTip tip={data.username || data.email} tipCls="!w-20">
                  <div className="assignMemberContainer">
                    {data?.image ? (
                      <img
                        src={data.image}
                        className={`rounded-full w-[25px] h-[25px]`}
                      />
                    ) : (
                      <Avatar
                        key={index}
                        name={data.username || data.email}
                        data={data}
                      />
                    )}
                  </div>
                </ToolTip>
              ))}
              <div
                className="relative assignMemberContainer"
                onClick={() => setAssignModal(true)}
                ref={btnRef}
              >
                <img
                  src="/images/icons/add-assignee.svg"
                  alt="+"
                  className="cursor-pointer ml-2 -mt-1 hover:shadow-md rounded-full"
                />
                {assignModal && (
                  <AssignDropdown
                    loadingAssign={loadingAssign}
                    userDetails={props.userDetails}
                    handled_by={props.ticket.assignedMembers}
                    user_id={props.user_id}
                    submitAssign={submitAssign}
                    additionalclassName={"ticketPosition"}
                    ticketId={props.ticket.ticket_id}
                  />
                )}
              </div>
            </div>
          </div>
        </td>
      )} */}
      {props.activeColumns["Priority"] && (
        <td className="px-6 py-4 ticket-priority text-[#070822]">
          {props.ticket.priority}
        </td>
      )}
      {props.activeColumns["Clients"] && (
        <td className="px-6 py-4">
          <div className="assignParentDiv">
            <div className="flex assignList">
              {props.ticket?.ticketClients?.map((data, index) => (
                <ToolTip
                  tip={data.client_name || data.client_email}
                  tipCls="!w-auto"
                >
                  <div className="assignMemberContainer">
                    {data?.image ? (
                      <img
                        src={data.image}
                        className={`rounded-full w-[25px] h-[25px]`}
                      />
                    ) : (
                      <Avatar
                        key={index}
                        name={data.client_name || data.client_email}
                        data={data}
                      />
                    )}
                  </div>
                </ToolTip>
              ))}
            </div>
          </div>
        </td>
      )}
      {props.activeColumns["Status"] && (
        <td className="px-6 py-4 ticket-status text-[#070822]">
          {props.ticket.status}
        </td>
      )}
      {props.commnuityFields?.map((field) => {
        const matchingObject = props.ticket?.customFields?.find(
          (obj) => obj.customfield_id === field.id
        );
        if (matchingObject) {
          return (
            props.activeColumns[field.field_name] && (
              <td key={matchingObject.customfield_id}>
                {matchingObject.customfield_values}
              </td>
            )
          );
        } else {
          return <td></td>;
        }
      })}
    </tr>
  );
};

export default TicketRow;
