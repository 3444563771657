import React, { useEffect, useState } from "react";

function Pagination({ data = { meta: {} }, handlePageChange }) {
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");

  useEffect(() => {
    if (data?.meta?.prev_page_url != null) {
      var url = new URL(data?.meta?.prev_page_url);
      var searchParams = new URLSearchParams(url.search);
      var page = searchParams.get("page");
      setPrevPage(page);
    } else {
      setPrevPage("");
    }

    if (data?.meta?.next_page_url != null) {
      var url = new URL(data?.meta?.next_page_url);
      var searchParams = new URLSearchParams(url.search);
      var page = searchParams.get("page");
      setNextPage(page);
    } else {
      setNextPage("");
    }
  }, [data]);

  return (
    <div className="flex items-center justify-end py-3">
      <div className="pagination">
        <div className="pagination-total">
          Total <span>{data.meta.total_records}</span> Items
        </div>

        <span
          className={`pagination-pager pagination-pager-next ${
            !prevPage ? "pagination-pager-inactive" : ""
          }`}
          onClick={() => {
            if (prevPage) {
              handlePageChange(prevPage);
            }
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 20 20"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <ul>
          {Array.from({ length: data.meta.last_page }, (v, i) => i + 1).map(
            (pageNumber) => {
              if (
                pageNumber === 1 ||
                pageNumber === data.meta.last_page ||
                Math.abs(pageNumber - data.meta.current_page) <= 1
              ) {
                return (
                  <li
                    className={`pagination-pager ${
                      data.meta.current_page == pageNumber
                        ? "text-indigo-600 bg-indigo-50"
                        : ""
                    } hover:bg-indigo-50 `}
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </li>
                );
              } else if (
                pageNumber === data.meta.current_page - 2 ||
                pageNumber === data.meta.current_page + 2
              ) {
                return (
                  <li className="pagination-pager pagination-pager-inactive">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      />
                    </svg>
                  </li>
                );
              }
            }
          )}
        </ul>
        <span
          className={`pagination-pager pagination-pager-next ${
            !nextPage ? "pagination-pager-inactive" : ""
          }`}
          onClick={() => {
            if (nextPage) {
              handlePageChange(nextPage);
            }
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 20 20"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}

export default Pagination;
