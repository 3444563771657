import moment from "moment";
import { Tooltip } from "react-tooltip";
import { formatTimeFromSeconds } from "../common/commonHelpers";

function readableDate(date) {
  const originalDate = moment(date);
  const formattedDate = originalDate.format("DD MMM");

  return formattedDate;
}

function getDates(selectedDates) {
  const startDate = moment(selectedDates?.startDate);
  const endDate = moment(selectedDates?.endDate);

  const allDates = [];

  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    allDates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return allDates;
}

function MemberChatAnalytics({ memberChats, selectedDates, setPopup, popup }) {
  const dates = getDates(selectedDates);

  return (
    <div>
      <table className="min-w-full text-sm text-left">
        <thead className="uppercase bg-gray-50 issue-list-title">
          <tr>
            <th
              className={`p-3 border border-gray-300 bg-[#E3E9F3] sticky top-0 -left-1 z-50`}
            >
              Members
            </th>

            {dates.map((date, i) => {
              return (
                <th
                  key={i}
                  className={`border border-gray-300 bg-[#E3E9F3] p-3`}
                >
                  <div className="w-20">{readableDate(date)}</div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {memberChats.map((member, i) => {
            const { chatsData, username, email, image } = member;

            const name = username || email;

            return (
              <tr key={i}>
                <td
                  className={`p-3 border border-gray-300 bg-white sticky -left-1 z-50`}
                >
                  <div key={i} className="flex items-center space-x-4 h-[4rem]">
                    <div className="rounded-full w-[30px] h-[30px] flex justify-center items-center font-semibold bg-gray-100 overflow-hidden text-sm">
                      {image ? (
                        <img
                          src={image}
                          alt="user-profile"
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        name[0]
                      )}
                    </div>

                    <div className="max-w-[6rem] truncate">{name}</div>
                  </div>
                </td>

                {dates.map((date, j) => {
                  const chat = chatsData ? chatsData[date] : null;

                  const keyForData = member.id + "-" + date;

                  return (
                    <td
                      key={j}
                      className={`border border-gray-300 bg-white p-3`}
                    >
                      {chat ? (
                        <DisplayChatDetails
                          chat={chat || {}}
                          date={date}
                          keyForData={keyForData}
                          setPopup={setPopup}
                          popup={popup}
                        />
                      ) : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default MemberChatAnalytics;

function DisplayChatDetails({ chat, date, keyForData, setPopup, popup }) {
  const {
    all_chats_count,
    resolved_count,
    open_count,
    reopen_count,
    abandoned_count,
    queue_count,
    avg_resolve_time,
  } = chat || {};

  return (
    <div className="flex items-center relative">
      {`${resolved_count}/${all_chats_count}`}

      <i
        className={`fa-solid fa-circle-info ml-3 text-gray-600 cursor-pointer text-lg`}
        id={"member-chat-" + keyForData}
      ></i>

      <Tooltip
        anchorSelect={"#member-chat-" + keyForData}
        place="top"
        style={{
          zIndex: 99999,
        }}
        clickable
      >
        <>
          <DisplayDetails label="Open" value={open_count} />
          <DisplayDetails label="Reopen" value={reopen_count} />
          <DisplayDetails label="Resolved" value={resolved_count} />
          <DisplayDetails label="Abandoned" value={abandoned_count} />
          <DisplayDetails label="Queue" value={queue_count} />
          <DisplayDetails label="Total" value={all_chats_count} />
          <DisplayDetails
            label="Avg. resolved time"
            value={formatTimeFromSeconds(avg_resolve_time)}
          />
        </>
      </Tooltip>
    </div>
  );
}

export function DisplayDetails({ label, value }) {
  return (
    <div className="flex items-center text-white">
      <div>{label}: </div>
      <div className="font-semibold ml-1">{value}</div>
    </div>
  );
}

export function DisplayCount({ ids, count, isToolTip }) {
  return count ? (
    <a
      href={`tickets?ticketIds=${ids}`}
      className={
        isToolTip
          ? "text-white font-semibold underline"
          : "text-blue-600 underline"
      }
      target="_blank"
      rel="noreferrer"
    >
      {count}
    </a>
  ) : (
    <div>{count}</div>
  );
}
