import { useState } from "react";

function ToolTip({ children, tip, tipCls, containerCls = "" }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={`relative flex items-center mx-auto w-fit space-x-2 ${containerCls}`}
    >
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {children}
      </div>

      {isHovering ? (
        <div
          className={`py-1 px-3 bg-indigo-600 rounded-lg shadow-md absolute bottom-[38px] right-0 text-white text-xs ${tipCls}`}
        >
          {tip}
        </div>
      ) : null}
    </div>
  );
}

export default ToolTip;
