import { useEffect, useState } from "react";
import _ from "lodash";
import { checkLabelLevel } from "../../hooks/commonHelper";

const limit = 5;

function generateDefaultInputSelectedValue(limit) {
  const defaultInputSelectedValue = {};
  for (let i = 1; i <= limit; i++) {
    defaultInputSelectedValue[i] = 0;
  }
  return defaultInputSelectedValue;
}

function getLevel3Choice() {
  return [
    { label: "", choices: [] },
    { label: "", choices: [] },
    { label: "", choices: [] },
  ];
}

function getDefaultChoice() {
  return {
    label: "",
    choices: [
      { label: "", choices: getLevel3Choice() },
      { label: "", choices: getLevel3Choice() },
      { label: "", choices: getLevel3Choice() },
    ],
  };
}

function getDefaultLevelFields(level) {
  const defaultInputFields = 3;
  const data = [];
  for (let i = 0; i < defaultInputFields; i++) {
    data.push(_.cloneDeep(getDefaultChoice(level)));
  }
  return data;
}

function getInputValues(levelNo, selectedInput, levelFields) {
  if (!levelFields || !levelFields.choices) {
    return [];
  }

  const { choices } = levelFields;

  if (levelNo === 1) {
    return choices;
  }

  let currentChoice = choices[selectedInput[1]];
  if (!currentChoice || !currentChoice.choices) {
    return [];
  }

  if (levelNo === 2) {
    return currentChoice.choices;
  } else {
    for (let index = 2; index < levelNo; index++) {
      currentChoice = currentChoice.choices[selectedInput[index]];
      if (!currentChoice || !currentChoice.choices) {
        return [];
      }
    }
    return currentChoice.choices;
  }
}

function handleDelete(
  setLevelFields,
  index,
  levelNo,
  selectedInput,
  setSelectedInput
) {
  setLevelFields((prev) => {
    const updatedFields = _.cloneDeep(prev.choices);
    let currentChoice = updatedFields[selectedInput[1]];

    if (levelNo === 1) {
      updatedFields.splice(index, 1);
    } else if (levelNo === 2) {
      currentChoice.choices.splice(index, 1);
    } else {
      for (let idx = 2; idx < levelNo; idx++) {
        currentChoice = currentChoice.choices[selectedInput[idx]];
      }
      currentChoice.choices.splice(index, 1);
    }

    return { ...prev, choices: updatedFields };
  });
  setSelectedInput((prev) => ({
    ...prev,
    [levelNo]: 0,
  }));
}

function getInitialData(updateFields, level) {
  if (updateFields?.choices) {
    return updateFields;
  }

  return {
    choices: getDefaultLevelFields(level),
  };
}

function LevelFieldsForm(props) {
  const {
    onDependancyDropdownFieldNameChange,
    fieldNames = {},
    levelFieldsFormData,
    fieldNo,
    fieldsData,
  } = props;
  const [num, setNum] = useState(3);
  const DEFAULT_INPUT_SELECTED_VALUE = generateDefaultInputSelectedValue(limit);

  const [levelFields, setLevelFields] = useState(
    getInitialData(levelFieldsFormData.current[fieldNo])
  );
  const [selectedInput, setSelectedInput] = useState(
    DEFAULT_INPUT_SELECTED_VALUE
  );
  const levelFieldContainer = [];

  useEffect(() => {
    const maxLevelWithLabels = checkLabelLevel(levelFields);
    if (maxLevelWithLabels > num) {
      setNum(maxLevelWithLabels);
    }
  }, []);

  useEffect(() => {
    levelFieldsFormData.current = {
      ...levelFieldsFormData.current,
      [fieldNo]: levelFields,
    };
  }, [levelFields]);

  useEffect(() => {
    setLevelFields(getInitialData(levelFieldsFormData.current[fieldNo]));
  }, [fieldsData, num]);

  const defaultChoice = getDefaultChoice();

  function addLevelFields(level) {
    setLevelFields((prev) => {
      const updatedFields = _.cloneDeep(prev.choices);
      let currentChoice = updatedFields[selectedInput[1]];

      // Ensure currentChoice has a choices field at the initial level
      if (!currentChoice.choices) {
        currentChoice.choices = [];
      }

      if (level === 2) {
        currentChoice.choices.push({
          label: "",
          choices: getLevel3Choice(),
        });
      } else {
        for (let index = 2; index < level; index++) {
          if (!currentChoice.choices[selectedInput[index]]) {
            currentChoice.choices[selectedInput[index]] = { choices: [] };
          }
          currentChoice = currentChoice.choices[selectedInput[index]];

          // Ensure the nested currentChoice has a choices field at each level
          if (!currentChoice.choices) {
            currentChoice.choices = [];
          }
        }
        currentChoice?.choices?.push({
          label: "",
          choices: getLevel3Choice(),
        });
      }

      return { ...prev, choices: updatedFields };
    });
  }

  function handleAddLevel(level) {
    setLevelFields((prev) => {
      const updatedFields = _.cloneDeep(prev.choices);
      let currentChoice = updatedFields[selectedInput[1]];

      for (let index = 2; index < level; index++) {
        currentChoice =
          currentChoice?.choices?.[selectedInput[index]] ?? currentChoice;
      }
      if (currentChoice?.choices?.length === 0) {
        for (let i = 0; i < 3; i++) {
          currentChoice?.choices?.push({
            label: "",
            choices: getLevel3Choice(),
          });
        }
      }

      return { ...prev, choices: updatedFields };
    });

    setNum(level);
  }

  function handleInputValueChange(value, index, level) {
    setLevelFields((prev) => {
      const updatedFields = _.cloneDeep(prev.choices);

      let currentChoice = updatedFields[selectedInput[1]];

      if (level === 2) {
        currentChoice.choices[index].label = value;
      } else {
        for (let idx = 2; idx < level; idx++) {
          currentChoice = currentChoice.choices[selectedInput[idx]];
        }
        currentChoice.choices[index].label = value;
      }

      return { ...prev, choices: updatedFields };
    });
  }

  for (let i = 2; i <= num; i++) {
    levelFieldContainer.push(
      <Level
        key={i}
        levelNo={i}
        selectedInput={selectedInput}
        onInputSelect={(levelNo, index) => {
          setSelectedInput((prev) => ({
            ...prev,
            [levelNo]: index,
          }));
        }}
        inputValues={getInputValues(i, selectedInput, levelFields)}
        prevInputValues={getInputValues(i - 1, selectedInput, levelFields)}
        onInputValueChange={(value, index) => {
          handleInputValueChange(value, index, i);
        }}
        onDelete={(index, levelNo) => {
          handleDelete(
            setLevelFields,
            index,
            levelNo,
            selectedInput,
            setSelectedInput
          );
        }}
        onAdd={() => {
          addLevelFields(i);
        }}
        onDependancyDropdownFieldNameChange={
          onDependancyDropdownFieldNameChange
        }
        fieldNames={fieldNames[`level${i}`]}
        showAddBtn={true}
      />
    );
  }

  return (
    <div className="my-4">
      <div className="flex overflow-x-auto space-x-4 p-4 border border-gray-200 rounded-lg">
        <Level
          levelNo={1}
          onInputValueChange={(value, index) => {
            setLevelFields((prev) => {
              const updatedFields = _.cloneDeep(prev.choices);
              updatedFields[index].label = value;
              return { ...prev, choices: updatedFields };
            });
          }}
          inputValues={getInputValues(1, selectedInput, levelFields)}
          prevInputValues={getInputValues(1, selectedInput, levelFields)}
          selectedInput={selectedInput}
          onInputSelect={(levelNo, index) => {
            setSelectedInput((prev) => ({
              ...DEFAULT_INPUT_SELECTED_VALUE,
              [levelNo]: index,
            }));
          }}
          onDelete={(index, levelNo) => {
            handleDelete(
              setLevelFields,
              index,
              levelNo,
              selectedInput,
              setSelectedInput
            );
          }}
          onAdd={() => {
            setLevelFields((prev) => {
              const prevData = _.cloneDeep(prev);
              const choices = _.cloneDeep(prev.choices);
              const prevChoices = [...choices];
              prevChoices.push({ ...defaultChoice });
              return { ...prevData, choices: prevChoices };
            });
          }}
          onDependancyDropdownFieldNameChange={
            onDependancyDropdownFieldNameChange
          }
          fieldNames={fieldNames?.level1}
          showAddBtn={true}
        />
        {levelFieldContainer}
        <div className="my-auto">
          <button
            className="mt-4 rounded-lg border border-gray-700 px-4 py-2 hover:bg-gray-100"
            onClick={() => {
              if (num < limit) {
                handleAddLevel(num + 1);
              }
            }}
            disabled={num >= limit}
            data-tooltip-content={num >= limit ? "Maximum level reached" : ""}
            data-tooltip-id="disable-add-button"
            data-tooltip-place="bottom"
            type="button"
          >
            <i className="fa-solid fa-circle-plus mr-2 text-lg"></i> Add Levels
          </button>
        </div>
      </div>
    </div>
  );
}

export default LevelFieldsForm;

function Level({
  levelNo,
  selectedInput,
  onInputSelect,
  onInputValueChange,
  inputValues,
  onDelete,
  onAdd,
  onDependancyDropdownFieldNameChange,
  fieldNames,
  showAddBtn,
  prevInputValues,
}) {
  const prevLevelValues = prevInputValues;
  return (
    <div>
      <div className="mb-4">
        <label className="required-input">Field Name for Level {levelNo}</label>
        <input
          type="text"
          name={levelNo}
          value={fieldNames}
          onChange={onDependancyDropdownFieldNameChange}
          className="mb-2 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
          required
        />
      </div>

      <div className="w-full bg-indigo-600 px-4 py-4 text-white font-semibold rounded-lg shadow-lg">
        Level {levelNo}
      </div>

      <div className="border mt-4 rounded-lg">
        {inputValues.map((__, i) => {
          const inputNo = i + 1;

          const selected = selectedInput[levelNo - 1];

          const inputId = selected
            ? `${selected}-${levelNo}-${inputNo}`
            : `${levelNo}-${inputNo}`;

          const isSelected = selectedInput[levelNo] === i;

          return (
            <div
              className={`px-4 py-4 flex items-center cursor-pointer ${
                isSelected
                  ? "bg-blue-100 border-l-4 border-blue-800"
                  : "border-b"
              }`}
              key={inputId}
            >
              <div onClick={() => onInputSelect(levelNo, i)}>
                <input
                  type="text"
                  className="w-[130px] border-2 border-gray-300 rounded-lg px-2 py-2 outline-none focus:ring ring-blue-400 focus:border-none"
                  onChange={(e) =>
                    onInputValueChange(e.target.value, i, levelNo)
                  }
                  value={inputValues[i]?.label ? inputValues[i].label : ""}
                />
              </div>

              <i
                class={`fa-solid fa-trash ml-4 text-xl cursor-pointer hover:text-red-500`}
                onClick={() => onDelete(i, levelNo)}
              ></i>
            </div>
          );
        })}
      </div>

      {showAddBtn ? (
        <button
          className="mt-4 rounded-lg border border-gray-700 px-4 py-2 hover:bg-gray-100"
          onClick={() => onAdd()}
          disabled={prevLevelValues?.length === 0}
          data-tooltip-content={
            prevInputValues.length === 0
              ? "Please add previous level field"
              : ""
          }
          data-tooltip-id="disable-add-button"
          data-tooltip-place="bottom"
          type="button"
        >
          <i class="fa-solid fa-circle-plus mr-2 text-lg"></i>
          Add more fields
        </button>
      ) : null}
    </div>
  );
}

{
  /* <Level
        levelNo={2}
        selectedInput={selectedInput}
        onInputSelect={(levelNo, index) => {
          setSelectedInput((prev) => ({
            ...prev,
            [levelNo]: index,
          }));
        }}
        inputValues={getInputValues(2, selectedInput, levelFields)}
        onInputValueChange={(value, index) => {
          handleInputValueChange(value, index, 2);
        }}
        onDelete={(index, levelNo) => {
          handleDelete(
            setLevelFields,
            index,
            levelNo,
            selectedInput,
            setSelectedInput
          );
        }}
        onAdd={() => {
          addlevelels(2);
        }}
        onDependancyDropdownFieldNameChange={
          onDependancyDropdownFieldNameChange
        }
        fieldNames={fieldNames?.level2}
        // showAddBtn={selectedInput[1] === 0 || selectedInput[1]}
        showAddBtn={getInputValues(1, selectedInput, levelFields)?.length}
      />

      <Level
        levelNo={3}
        selectedInput={selectedInput}
        onInputSelect={(levelNo, index) => {
          setSelectedInput((prev) => ({
            ...prev,
            [levelNo]: index,
          }));
        }}
        onInputValueChange={(value, index) => {
          handleInputValueChange(value, index, 3);
        }}
        inputValues={getInputValues(3, selectedInput, levelFields)}
        onDelete={(index, levelNo) => {
          handleDelete(
            setLevelFields,
            index,
            levelNo,
            selectedInput,
            setSelectedInput
          );
        }}
        onAdd={() => {
          addlevelels(3);
        }}
        onDependancyDropdownFieldNameChange={
          onDependancyDropdownFieldNameChange
        }
        fieldNames={fieldNames?.level3}
        // showAddBtn={selectedInput[2] === 0 || selectedInput[2]}
        showAddBtn={getInputValues(2, selectedInput, levelFields)?.length}
        // showAddBtn={true}
      />
      <Level
        levelNo={4}
        selectedInput={selectedInput}
        onInputSelect={(levelNo, index) => {
          setSelectedInput((prev) => ({
            ...prev,
            [levelNo]: index,
          }));
        }}
        onInputValueChange={(value, index) => {
          // setLevelFields((prev) => {
          //   const updatedFields = [...prev.choices];

          //   const level1Choice = updatedFields[selectedInput[1]];
          //   const level2Choice = level1Choice.choices[selectedInput[2]];
          //   const level3Choice = level2Choice.choices[selectedInput[3]];
          //   level3Choice.choices[index].label = value;

          //   return { ...prev, choices: updatedFields };
          // });
          handleInputValueChange(value, index, 4);
        }}
        inputValues={getInputValues(4, selectedInput, levelFields)}
        onDelete={(index, levelNo) => {
          handleDelete(
            setLevelFields,
            index,
            levelNo,
            selectedInput,
            setSelectedInput
          );
        }}
        onAdd={() => {
          addlevelels(4);
        }}
        onDependancyDropdownFieldNameChange={
          onDependancyDropdownFieldNameChange
        }
        fieldNames={fieldNames?.level4}
        // showAddBtn={selectedInput[2] === 0 || selectedInput[2]}
        // showAddBtn={getInputValues(3, selectedInput, levelFields)?.length}
        showAddBtn={true}
      />
      <Level
        levelNo={5}
        selectedInput={selectedInput}
        onInputSelect={(levelNo, index) => {
          setSelectedInput((prev) => ({
            ...prev,
            [levelNo]: index,
          }));
        }}
        onInputValueChange={(value, index) => {
          setLevelFields((prev) => {
            const updatedFields = [...prev.choices];

            const level1Choice = updatedFields[selectedInput[1]];
            const level2Choice = level1Choice.choices[selectedInput[2]];
            const level3Choice = level2Choice.choices[selectedInput[3]];
            const level4Choice = level3Choice.choices[selectedInput[4]];
            level4Choice.choices[index].label = value;

            return { ...prev, choices: updatedFields };
          });
        }}
        inputValues={getInputValues(5, selectedInput, levelFields)}
        onDelete={(index, levelNo) => {
          handleDelete(
            setLevelFields,
            index,
            levelNo,
            selectedInput,
            setSelectedInput
          );
        }}
        onAdd={() => {
          addlevelels(5);
        }}
        onDependancyDropdownFieldNameChange={
          onDependancyDropdownFieldNameChange
        }
        fieldNames={fieldNames?.level5}
        // showAddBtn={selectedInput[2] === 0 || selectedInput[2]}
        // showAddBtn={getInputValues(3, selectedInput, levelFields)?.length}
        showAddBtn={true}
      /> */
}

// function createChoices(level) {
//   if (level === 0) {
//     return [];
//   }
//   return [
//     { label: "", choices: createChoices(level - 1) },
//     { label: "", choices: createChoices(level - 1) },
//     { label: "", choices: createChoices(level - 1) },
//   ];
// }

// function getLevelChoices(n) {
//   return createChoices(n);
// }
