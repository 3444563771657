import React from "react";

function SearchInput({ onSearch, customCls = "" }) {
  return (
    <span className="input-wrapper">
      <div className="input-suffix-start">
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth={0}
          viewBox="0 0 24 24"
          className="text-lg"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <input
        className={`input input-sm h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 ${customCls}`}
        type="text"
        placeholder="Search"
        onChange={onSearch}
      ></input>
    </span>
  );
}

export default SearchInput;
