import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "../../../../common/SearchInput";
import { formatDateWithTime } from "../../../../common/commonHelpers";
import ApiDetailsCard from "./ApiDetailsCard";
import useApiConfiguration from "./useApiConfiguration";
import useApiData from "../../../../../hooks/useApiData";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../App";
import Spinner from "../../../../loaders/Spinner";
import DeleteModal from "../../../../common/DeleteModal";

// const apis = [
//   {
//     method: "POST",
//     name: "Sample POST API - Create a Freshdesk Ticket",
//     created_at: new Date(),
//   },
//   {
//     method: "GET",
//     name: "Sample API",
//     created_at: new Date(),
//   },
//   {
//     method: "put",
//     name: "Sample API",
//     created_at: new Date(),
//   },
//   {
//     method: "delete",
//     name: "Sample API",
//     created_at: new Date(),
//   },
// ];

function ApiConfigurationList() {
  const { id, botflowId } = useParams();

  const { onError, onSuccess } = useContext(AppContext);

  const [openDelete, setOpenDelete] = useState(false);
  const [search, setSearch] = useState("");

  const { getApiConfigurations, deleteApiConfiguration } = useApiConfiguration(
    id,
    botflowId
  );

  const navigate = useNavigate();

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting API's"),
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("API configuration Deleted Successfully");
      setOpenDelete(false);
      getList(getApiConfigurations());
    },
    onError: () =>
      onError("Something went wrong while deleting API configuration"),
  });

  useEffect(() => {
    if (!search || search?.length > 2) {
      getList(getApiConfigurations({ search }));
    }
  }, [search]);

  const apis = data?.data || [];

  const formPath = `/train-chat-bot/${id}/botflow/${botflowId}/configuration/api/form`;

  // if (loading) {
  //   return (
  //     <div className="w-fit mx-auto mt-8">
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <div className="text-gray-700">
      <div className="text-3xl font-semibold mb-2">API library</div>
      <div className="text-sm mb-6">
        Integrate your bot with your favorite apps through APIs to push and
        fetch data dynamically.
      </div>

      <div className="flex justify-between items-center">
        <SearchInput
          onSearch={(e) => {
            setSearch(e.target.value);
          }}
        />

        <button
          className="px-4 py-2 bg-indigo-600 text-white text-primary-600 rounded-md hover:bg-blue-50"
          onClick={() => {
            navigate({
              pathname: formPath,
            });
          }}
        >
          New API
        </button>
      </div>

      {loading ? (
        <div className="w-fit mx-auto mt-8">
          <Spinner />
        </div>
      ) : (
        <div>
          {apis?.length ? (
            <>
              {apis.map((api, i) => {
                return (
                  <ApiDetailsCard
                    key={i}
                    api={api}
                    formPath={formPath}
                    navigate={navigate}
                    onEdit={() => {
                      navigate({
                        pathname: formPath,
                        search: `?configurationId=${api.uuid}`,
                      });
                    }}
                    onDelete={() => setOpenDelete(api.uuid)}
                    isDeleteLoading={isDeleteLoading}
                  />
                );
              })}
            </>
          ) : (
            <div className="text-center text-gray-400 font-semibold mt-8">
              API's not found
            </div>
          )}
        </div>
      )}

      {openDelete && (
        <DeleteModal
          title={"Are you sure, you want to delete this API configuration."}
          open={openDelete}
          onClose={() => {
            setOpenDelete(false);
          }}
          loading={isDeleteLoading}
          onDelete={() => {
            deleteApi(deleteApiConfiguration(openDelete));
          }}
        />
      )}
    </div>
  );
}

export default ApiConfigurationList;
