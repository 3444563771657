import { useContext, useState } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import CommunityMemberCard from "../../components/settings/CommunityMemberCard";
import Table from "../common/Table";

const CommunityMembers = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setInviteModal,
    inviteModal,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [communityLeaveModal, setCommunityLeaveModal] = useState(false);
  const [communityId, setCommunityId] = useState("");
  const [userId, setUserId] = useState("");
  const leaveCommunity = (e) => {
    e.preventDefault();
    setProcessing(true);
    var payload = {
      community_id: communityId,
      user_id: userId,
      selectedCommunity: props.communityId,
    };
    api.post("/api/removeCommunity", payload).then((response) => {
      setProcessing(false);
      if (response.data.status == "success") {
        props.storeCommunityDetails(response);
        setShowToast(true);
        setCommunityLeaveModal(false);
        setToastMessage({
          type: "success",
          message: "Person Removed",
        });
      }
    });
  };

  const columns = [
    {
      name: "Member",
      renderComp: (data) => {
        const { image, username, email } = data || {};

        const name = username || email || "";

        return (
          <div className="flex items-center gap-3">
            <div
              className={`rounded-full flex items-center justify-center w-[35px] h-[35px] ${
                !image && "bg-gray-100"
              }`}
            >
              {image ? (
                <img className="rounded-full" src={image} alt="profile"></img>
              ) : (
                <div className="font-medium">{name[0]}</div>
              )}
            </div>
            <div>
              <div className="text-md font-bold">{username}</div>
              <div>{email}</div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Role",
      renderComp: (data, i) => {
        return "test";
      },
    },
    {
      name: "Level",
      renderComp: (data, i) => {
        return "helo";
      },
    },
    {
      name: "Remove",
      renderComp: (data, i) => {
        return "helo";
      },
    },
  ];

  return (
    <>
      <div className="px-2">
        <div className="communityMembers">
          <table className={`my-6 border table-auto w-full text-left`}>
            <thead>
              <tr>
                {columns.map((col, i) => {
                  return (
                    <th key={i} className={`bg-gray-100 p-4`}>
                      {col.name}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {props.communityDetails?.members?.map((member, i) => (
                <tr key={i} className="border-b border-gray-300">
                  <CommunityMemberCard
                    handleCommunityLeaveModal={setCommunityLeaveModal}
                    handleCommunityId={setCommunityId}
                    handleUserId={setUserId}
                    member={member}
                    currUser={currUser}
                    communityDetails={props.communityDetails}
                    storeCommunityDetails={props.storeCommunityDetails}
                    setShowToast={setShowToast}
                    setToastMessage={setToastMessage}
                    communityId={props.communityId}
                    levels={props.communityDetails.levels}
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {communityLeaveModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="text-center flex items-start justify-between p-4 rounded-t">
                <div className="grow">
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Remove This person
                  </h3>
                  <p className="mt-5 text-center w-full block">
                    Are you sure you want to remove this person from{" "}
                    {props.communityDetails?.name} community?
                  </p>
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent light-blue-hover hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center  "
                  data-modal-hide="defaultModal"
                  onClick={() => {
                    setCommunityLeaveModal(false);
                    setCommunityId("");
                    setUserId("");
                  }}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form action="#" onSubmit={(e) => leaveCommunity(e)}>
                <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                  <button
                    className="inline-flex items-center justify-center button bg-red-600 hover:bg-red-500 active:bg-red-700 text-white radius-round h-11 px-8 py-2 w-full"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Remove
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommunityMembers;
