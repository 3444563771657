import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import FileCard from "./FileCard";
import ReactMarkdown from "react-markdown";

const TicketComment = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setHasChanged,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [editedComment, seteditedComment] = useState("");
  const [editComment, setEditComment] = useState("");
  const [editchecked, setEditChecked] = useState(false);
  const [timeAgo, setTimeAgo] = useState("");
  const { id } = useParams();
  const btnRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const inputFileRef = useRef(null);
  const [removeAttachments, setRemoveAttachments] = useState([]);

  useEffect(() => {
    const date = new Date(props.comment.created_at);
    const now = new Date();
    const diffInMs = now - date;
    const diffInHours = Math.floor(diffInMs / 3600000);
    const diffInDays = Math.floor(diffInHours / 24);

    let timeAgo = "";

    if (diffInDays >= 1) {
      timeAgo = diffInDays + "d ";
    }

    if (diffInHours < 1) {
      const diffInMinutes = Math.floor(diffInMs / 60000);
      timeAgo += diffInMinutes + "m ago";
    } else {
      const remainingHours = diffInHours % 24;
      timeAgo += remainingHours + "h ago";
    }
    if (diffInDays >= 1) {
      timeAgo = new Date(props.comment.created_at).toLocaleDateString();
    }

    setTimeAgo(timeAgo);
  }, [props]);

  const handleRemoveAttach = (id) => {
    setRemoveAttachments((prev) => [...prev, id]);
    // console.log("ids", removeAttachments);
    setHasChanged(true);
  };

  const updateComment = (e) => {
    e.preventDefault();
    var payload = {
      comment: editedComment,
      comment_id: editComment,
      id: id,
      community_id: currCommunityId,
      checked: editchecked,
      attachments: attachments,
      removeAttachments: removeAttachments,
    };
    setProcessing(true);

    api
      .post("/api/updateComment", payload)
      .then((response) => {
        setProcessing(false);

        if (response.data.message == "success") {
          setHasChanged(false);
          setEditComment("");
          seteditedComment("");
          setRemoveAttachments([]);
          setAttachments([]);
          props?.getComments();
          setEditChecked(false);
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Comment Updated Successfully",
          });
        }
      })
      .catch((error) => {
        // console.log("errr", error);
        setProcessing(false);
        setShowToast(true);
        setRemoveAttachments([]);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      setAttachments(attachmentFiles);
      setProcessing(false);
      setHasChanged(true);
    } catch (error) {
      setShowToast(true);
      // console.log("errr", error);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };
  return (
    <li className="timeline-item w-full mt-4">
      <div className="timeline-item-wrapper">
        <div className="timeline-item-media">
          <div className="timeline-item-media-content">
            <div className="w-9 h-9 overflow-hidden rounded-full">
              <img
                src={"/images/icons/user.svg"}
                alt="user"
                className="object-fill w-full h-full"
              />
            </div>
          </div>
          <div className="" />
        </div>
        <div className="timeline-item-content">
          <p className="my-1 flex items-center">
            <span className="font-semibold text-gray-900 ">
              {props.comment.username}
            </span>
            <span className="mx-2"></span>
            <span>{timeAgo}</span>
            {props.comment.state == 1 && (
              <span className="external-tag">External</span>
            )}
            {props.comment.state == 2 && (
              <span className="client-tag">Client</span>
            )}
            {props.comment.state != 2 &&
              !editComment &&
              currUser.hashId == props.comment.id && (
                <button
                  type="button"
                  className="ml-4 edit-comment"
                  onClick={() => {
                    setEditComment(props.comment.comment_id);
                    seteditedComment(props.comment.comment);
                    setEditChecked(props.comment.state === 1);
                  }}
                  ref={btnRef}
                >
                  Edit
                </button>
              )}
          </p>
          <div className="">
            <div className="">
              {editComment != props.comment.comment_id ? (
                <div className="w-full">
                  <pre
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {props.comment.comment}
                  </pre>

                  <div className="mt-2 flex gap-2 flex-wrap">
                    {props.comment?.ticketAttachments?.map((attach, index) => (
                      <FileCard
                        key={attach.id}
                        index={index}
                        file={attach}
                        handleRemoveAttachment={() =>
                          handleRemoveAttach(attach.id)
                        }
                        isViewPage={true}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <form
                  className="edit-comment-form"
                  onSubmit={(e) => updateComment(e)}
                >
                  <div className="mt-6 mb-3 flex flex-auto">
                    <div className="ml-4 rtl:mr-4 w-full">
                      <div className="flex space-x-4">
                        {" "}
                        <textarea
                          className="input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 w-full p-2 focus:border-indigo-600 input-textarea"
                          type="text"
                          placeholder="Leave a comment"
                          defaultValue={""}
                          onChange={(e) => {
                            setHasChanged(true);
                            seteditedComment(e.target.value);
                          }}
                          required
                          value={
                            editedComment
                              ? editedComment
                              : props.comment.comment
                          }
                        />
                        <div>
                          <div
                            onClick={handleSelectButtonClick}
                            className="cursor-pointer"
                          >
                            <img
                              src="/images/icons/attachment-btn.svg"
                              alt="attachment"
                              className="w-[2.5rem]"
                            />
                          </div>

                          <input
                            type="file"
                            className="hidden"
                            ref={inputFileRef}
                            onChange={handleFileUpload}
                            multiple
                          />
                        </div>
                      </div>

                      <div className="mt-2 flex gap-2 flex-wrap">
                        {props.comment?.ticketAttachments?.map(
                          (attach, index) => (
                            <div
                              className={`flex gap-2 items-center ${
                                Array.isArray(removeAttachments) &&
                                removeAttachments.includes(attach.id)
                                  ? "hidden"
                                  : ""
                              }`}
                            >
                              <FileCard
                                key={attach.id}
                                index={index}
                                file={attach}
                                handleRemoveAttachment={() =>
                                  handleRemoveAttach(attach.id)
                                }
                              />
                            </div>
                          )
                        )}

                        {attachments.map((attach, index) => (
                          <div className="">
                            <FileCard
                              key={index}
                              file={attach}
                              index={index}
                              handleRemoveAttachment={() => {
                                setAttachments((prev) => {
                                  return prev.filter((p, i) => i !== index);
                                });
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      {/* <div>
                        <div
                          onClick={handleSelectButtonClick}
                          className="mt-2 inline-block text-indigo-500 underline rounded cursor-pointer z-10"
                        >
                          Select Attachments
                        </div>

                        <input
                          type="file"
                          className="hidden"
                          ref={inputFileRef}
                          onChange={handleFileUpload}
                          multiple
                        />
                        {attachments?.length > 0 && (
                          <span className="ml-2">
                            {attachments.length} Files Selected
                          </span>
                        )}
                      </div> */}

                      <div className="mt-3 w-fit text-gray-600">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={editchecked}
                            onChange={(e) => setEditChecked(!editchecked)}
                            className="mr-2 w-4 h-4 border border-gray-500"
                          />
                          Make comment visible to client (External)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-4">
                    <button
                      type="submit"
                      className="flex items-center space-x-4 bg-indigo-600 text-white rounded-md px-4 py-2"
                      disabled={processing}
                    >
                      {processing && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      Update
                    </button>
                    <button
                      type="button"
                      className="text-indigo-600 border border-gray-300 rounded-md hover:bg-gray-100 px-4 py-2"
                      disabled={processing}
                      onClick={() => {
                        setEditComment("");
                        seteditedComment("");
                        setEditChecked(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default TicketComment;
