import { useContext, useEffect, useState } from "react";
import Modal from "../../common/Modal";
import TeamForm from "./TeamForm";
import useTeam from "./useTeam";
import MembersForm from "./MembersForm";
import TeamCard from "./TeamCard";
import useApiData from "../../../hooks/useApiData";
import { AppContext } from "../../../App";
import DeleteModal from "../../common/DeleteModal";
import useCommonApi from "../../../hooks/useCommonApi";
import CardSkeleton from "../../loaders/CardSkeleton";

const Teams = (props) => {
  const { currCommunityId, setShowToast, setToastMessage } =
    useContext(AppContext);

  const [openTab, setOpenTab] = useState(1);
  const [openTeamForm, setOpenTeamForm] = useState({
    open: false,
    type: "",
    data: null,
  });
  const [openMembersForm, setOpenMembersForm] = useState({
    open: false,
    type: "",
    data: null,
  });

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  function onSuccess(msg) {
    setShowToast(true);
    setToastMessage({
      type: "success",
      message: msg,
    });
  }

  function clearForm() {
    setOpenTeamForm({
      open: false,
      type: "",
      data: null,
    });
  }

  const {
    getTeams,
    createTeam,
    updateTeam,
    deleteTeam,
    addUser,
    removeUser,
    primaryTeam,
  } = useTeam();

  const { searchMember } = useCommonApi();

  function clearTeamForm() {
    setOpenTeamForm({
      open: false,
      type: "",
      data: null,
    });
  }

  const {
    data,
    mutate: getLists,
    loading,
  } = useApiData({
    onSuccess: (res) => {
      if (openMembersForm.open) {
        const team = res?.data?.find(function (element) {
          return openMembersForm?.data?.id == element.id;
        });

        setOpenMembersForm({
          open: true,
          type: "Add",
          data: team,
        });
      }
    },
    onError: () => onError("Something went wrong while getting Teams"),
  });

  const { mutate: setPrimaryTeam, loading: isPrimaryTeamLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Updated Successfully..!");
      getLists(getTeams({ isUsers: true }));
    },
    onError: () => onError("Something went wrong while Setting Primary team"),
  });

  const { mutate: create, loading: isCreateLoading } = useApiData({
    onSuccess: (res) => {
      setOpenTeamForm({
        open: false,
        type: "",
        data: null,
      });
      setOpenMembersForm({
        open: true,
        type: "Add",
        data: res?.data?.data,
      });
      onSuccess("Team Created Successfully..!");
      getLists(getTeams({ isUsers: true }));
    },
    onError: (err) => {
      const msgData = err?.response?.data?.error || [];
      onError(msgData ? msgData : "Something went wrong while creating team");
    },
  });

  const { mutate: update, loading: isUpdateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Team Updated Successfully..!");
      clearForm();
      getLists(getTeams({ isUsers: true }));
    },
    onError: (err) => {
      const msgData = err?.response?.data?.error || [];
      onError(msgData ? msgData : "Something went wrong while updating team");
    },
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Team deleted successfully");
      clearForm();
      getLists(getTeams({ isUsers: true }));
    },
    onError: () => onError("Something went wrong while deleting team "),
  });

  const { mutate: addUserInTeam, loading: isAddUserLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Member added successfully..!");
      clearForm();
      getLists(getTeams({ isUsers: true }));
    },
    onError: (err) => {
      const msgData = err?.response?.data?.error || [];
      onError(msgData ? msgData : "Something went wrong while Adding Member");
    },
  });

  const { mutate: removeUserInTeam, loading: isRemoveUserLoading } = useApiData(
    {
      onSuccess: (res) => {
        onSuccess("Member Removed Successfully..!");
        clearForm();
        getLists(getTeams({ isUsers: true }));
      },
      onError: (err) => {
        const msgData = err?.response?.data?.error || [];
        onError(
          msgData ? msgData : "Something went wrong while Removing Member"
        );
      },
    }
  );

  const {
    data: searchData,
    mutate: search,
    loading: isSearchLoading,
  } = useApiData({
    onSuccess: (res) => {
      clearForm();
    },
    onError: () => onError("Something went wrong while Searching "),
  });

  const teams = data || [];

  useEffect(() => {
    getLists(getTeams({ isUsers: true }));
  }, []);

  function clearMembersForm() {
    setOpenMembersForm({
      open: false,
      type: "",
      data: null,
    });
  }
  return (
    <>
      <div className="px-2 py-3">
        <button
          onClick={() =>
            setOpenTeamForm({
              open: true,
              type: "Create",
              data: null,
            })
          }
          className="flex items-center text-white bg-indigo-600 rounded-md px-6 py-3"
        >
          <img src="/images/icons/add.svg" alt="+" className="mr-3" />
          New Team
        </button>

        <div className="communityMembers">
          <div className="mt-6 h-full flex flex-col">
            {loading || isPrimaryTeamLoading ? (
              <CardSkeleton />
            ) : (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                {teams?.map((team, index) => (
                  <TeamCard
                    key={index}
                    team={team}
                    onEdit={() =>
                      setOpenTeamForm({
                        open: true,
                        type: "Update",
                        data: team,
                      })
                    }
                    onAddMembers={() =>
                      setOpenMembersForm({
                        open: true,
                        type: "Add",
                        data: team,
                      })
                    }
                    onDelete={() =>
                      setOpenTeamForm({
                        open: true,
                        type: "Delete",
                        data: team,
                      })
                    }
                    onSetPrimaryTeam={(team_id) => {
                      setPrimaryTeam(
                        primaryTeam({
                          team_id: team_id,
                        })
                      );
                    }}
                    isPrimaryTeamLoading={isPrimaryTeamLoading}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <DeleteModal
          title={"Are you sure, you want to delete this team"}
          open={openTeamForm.open && openTeamForm?.type === "Delete"}
          onClose={clearForm}
          loading={isDeleteLoading}
          onDelete={() => {
            deleteApi(
              deleteTeam({
                team_id: openTeamForm?.data?.team_id,
              })
            );
          }}
        />
        <Modal
          title={`${openTeamForm.type} Team`}
          isOpen={openTeamForm?.open && openTeamForm?.type !== "Delete"}
          onClose={clearTeamForm}
        >
          <TeamForm
            data={openTeamForm?.data}
            buttonText={`${openTeamForm.type}`}
            onSubmit={(data) => {
              if (openTeamForm?.data) {
                const updatedPayload = {
                  ...data,
                  team_id: openTeamForm?.data?.team_id,
                };
                update(updateTeam(updatedPayload));
              } else {
                create(createTeam(data));
              }
            }}
            loading={isCreateLoading || isUpdateLoading}
          />
        </Modal>
        <Modal
          title={`${openMembersForm.type} Members`}
          isOpen={openMembersForm?.open && openMembersForm?.type !== "Delete"}
          onClose={clearMembersForm}
        >
          <MembersForm
            data={openMembersForm?.data}
            buttonText={"Add"}
            onSearch={(data) => {
              search(
                searchMember({
                  search: data,
                })
              );
            }}
            onAddUser={(data) => {
              addUserInTeam(
                addUser({
                  team_id: data.team_id,
                  user_id: [data.user_id],
                })
              );
            }}
            onRemoveUser={(data) => {
              removeUserInTeam(
                removeUser({
                  team_id: data.team_id,
                  user_id: data.user_id,
                })
              );
            }}
            loading={loading}
            searchData={searchData}
            isSearchLoading={isSearchLoading}
            isAddUserLoading={isAddUserLoading}
            isRemoveUserLoading={isRemoveUserLoading}
          />
        </Modal>
      </div>
    </>
  );
};

export default Teams;
