import Select from "react-select";
import { customReactSelectStyles } from "../../../../common/commonHelpers";
import { useEffect, useRef, useState } from "react";
import SelectApis from "./SelectApis";
import SelectProperty from "./SelectProperty";
import { handleDialogEditListener } from "../helpers/dialogHelpers";

const actionsTypes = [
  {
    label: "Trigger an API",
    value: "API",
  },
  {
    label: "Set Property",
    value: "SET_PROPERTY",
  },
];

function getActionDialogPreview(actionsData) {
  const { actionType, configurationId } = actionsData || {};

  const { label } = actionsTypes.find((a) => a.value === actionType) || {};

  let display = label;

  if (configurationId?.apiName) {
    display += "- " + configurationId?.apiName;
  }

  return display;
}

function ActionDialog({
  actionsData,
  onSelectingActionType,
  onSelectingApis,
  onSetProperty,
  onDeleteDialog,
  onSetPropertyName,
  error,
  isActive,
}) {
  const { actionType, configurationId, setProperty } = actionsData || {};

  const propertyData = setProperty || {};

  const actionDialogRef = useRef();

  const preview = getActionDialogPreview(actionsData);

  return (
    <>
      <div className="text-sm" ref={actionDialogRef}>
        <div className="my-6 border border-[#0099d8] bg-blue-50 rounded-md w-96 p-1 relative">
          <div className="absolute top-0 -right-12 w-10 text-center">
            <i
              className="fa-solid fa-xmark border border-[#0099d8] bg-blue-50 w-full py-1.5 cursor-pointer text-primary-600 text-xl rounded-md"
              onClick={onDeleteDialog}
            ></i>

            <br />
          </div>
          <div className="flex items-center">
            <i className="fa-solid fa-bolt text-primary-600 bg-blue-100 p-3 rounded-md"></i>
            <div className="text-indigo-600 ml-3">
              {isActive.current ? "Action " : preview}
            </div>
          </div>

          {isActive.current ? (
            <div className="pt-1 my-1 border-t border-[#0099d8] p-2">
              <SelectActionType
                value={actionsTypes.find((a) => a.value === actionType)}
                onChange={(val) => onSelectingActionType(val?.value)}
                error={error.actionType}
              />

              {actionType === "API" ? (
                <SelectApis
                  onSelectingApis={(api) => {
                    onSelectingApis(api);
                  }}
                  configurationId={configurationId || {}}
                  error={error.api}
                />
              ) : null}

              {actionType === "SET_PROPERTY" ? (
                <SelectProperty
                  propertyData={propertyData}
                  configurationId={configurationId}
                  onSetProperty={onSetProperty}
                  onSetPropertyName={onSetPropertyName}
                  error={error}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ActionDialog;

function SelectActionType({ value, onChange, error }) {
  return (
    <div>
      <div className="text-gray-500 my-1.5 mt-3 font-semibold required-input">
        Action Type
      </div>
      <Select
        className={`text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 ${
          error ? "border-red-600" : ""
        }`}
        options={actionsTypes}
        onChange={onChange}
        value={value}
        styles={customReactSelectStyles}
      />
    </div>
  );
}
