import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { json, useNavigate, useParams } from "react-router-dom";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import AssignDropdown from "../../components/common/AssignDropdown";
import { Link, useLocation } from "react-router-dom";
import FileCard from "../../components/tickets/FileCard";
import Spinner from "../../components/loaders/Spinner";

const TicketClientDetail = () => {
  //const { quill, quillRef } = useQuill();
  const iframeRef = useRef();

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ticketDetail, setTicketDetail] = useState([]);
  const [description, setDescription] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [comment, setComment] = useState("");
  const [editedComment, seteditedComment] = useState("");
  const [editComment, setEditComment] = useState("");
  const { id } = useParams();
  const btnRef = useRef(null);
  const selectRef = useRef(null);
  const [assignModal, setAssignModal] = useState(false);
  const [removeAttachments, setRemoveAttachments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const inputFileRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setStatusModal(false);
      }

      if (
        btnRef.current &&
        !btnRef.current.contains(event.target) &&
        event.target.closest("#assign-modal-chat") == null
      ) {
        setAssignModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);
  useEffect(() => {
    const iframe = iframeRef.current;

    const adjustIframeHeight = () => {
      iframe.style.height =
        iframe.contentDocument.documentElement.scrollHeight + "px";
    };

    if (iframe) {
      iframe.onload = adjustIframeHeight;
    }

    return () => {
      if (iframe) {
        iframe.onload = null; // Cleanup the event listener when the component unmounts
      }
    };
  }, [ticketDetail]);
  const handleRemoveAttach = (id) => {
    setRemoveAttachments((prev) => [...prev, id]);
    // console.log("ids", removeAttachments);
  };
  useEffect(() => {
    getTicketDetails();
  }, []);

  // useEffect(() => {
  //   if (quill) {

  //     quill.on("text-change", (delta, oldDelta, source) => {
  //       setDescription(quill.root.innerHTML);
  //     });
  //   }
  // }, [currUser, quill]);

  const getTicketDetails = () => {
    var payload = {
      community_id: currCommunityId,
      ticket_id: id,
    };
    setLoading(true);

    api
      .post("/api/getClientTicket", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          setTicketDetail(response.data.data);
          // quill.clipboard.dangerouslyPasteHTML(
          //   response.data.data?.ticketContent
          // );
          setStatus(response.data.data?.status);
          setLoading(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setLoading(false);

        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };
  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      setAttachments(attachmentFiles);
      setProcessing(false);
    } catch (error) {
      setShowToast(true);
      // console.log("errr", error);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };
  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };
  const addComments = (e) => {
    e.preventDefault();

    var payload = {
      comment: comment,
      id: id,
      community_id: currCommunityId,
      checked: 2,
      attachments: attachments,
    };
    setProcessing(true);

    api
      .post("/api/addClientComment", payload)
      .then((response) => {
        setProcessing(false);

        if (response.data.status == "success") {
          setComment("");
          setTicketDetail(response.data.data);
          // quill.clipboard.dangerouslyPasteHTML(
          //   response.data.data?.ticketContent
          // );
          setStatus(response.data.data?.status);
          setAttachments([]);
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Comment Added Successfully",
          });
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  const updateComment = (e) => {
    e.preventDefault();
    var payload = {
      comment: editedComment,
      comment_id: editComment,
      id: id,
      community_id: currCommunityId,
      checked: 2,
      removeAttachments: removeAttachments,
      attachments: attachments,
    };
    setProcessing(true);

    api
      .post("/api/updateClientComment", payload)
      .then((response) => {
        setProcessing(false);

        if (response.data.status == "success") {
          setEditComment("");
          seteditedComment("");
          setTicketDetail(response.data.data);
          setAttachments([]);

          setStatus(response.data.data?.status);

          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Comment Updated Successfully",
          });
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            {loading ? (
              <div className="flex items-center justify-center h-[50vh]">
                <Spinner />
              </div>
            ) : (
              <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="lg:col-span-2">
                  <div className="card border-0 border-r md:border-gray-200 rounded-tr-none rounded-br-none rtl:rounded-tr-none rtl:rounded-br-none card-border">
                    <div className="card-body card-gutterless p-5">
                      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                        <div>
                          <h3 className="mb-2 font-bold">
                            {ticketDetail?.topic}
                          </h3>
                          <p>
                            #{ticketDetail?.ticket_id} created by
                            <span className="font-semibold text-gray-900  mx-1 cursor-pointer">
                              {ticketDetail?.created_by}
                            </span>
                          </p>
                        </div>
                      </div>
                      <hr className="my-6" />
                      <div className={`cursor-pointer`}>
                        {ticketDetail?.ticketContent && (
                          <iframe
                            ref={iframeRef}
                            srcDoc={ticketDetail?.ticketContent}
                            className="w-[100%]"
                            style={{ maxHeight: "500px" }}
                          ></iframe>
                        )}
                      </div>
                      <div className="mt-12">
                        <h4>Attachments</h4>
                        <hr className="my-6" />
                        <div className="flex flex-wrap gap-2">
                          {ticketDetail?.ticketAttachments?.map(
                            (attach, index) => (
                              <div className="flex items-center gap-2">
                                <FileCard
                                  key={attach.id}
                                  index={index}
                                  file={attach}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="mt-12">
                        <h4>Activity</h4>
                        <hr className="my-6" />
                        <ul className="timeline">
                          {ticketDetail?.ticketComments?.map((comment) => {
                            const date = new Date(comment.created_at);
                            const now = new Date();
                            const diffInMs = now - date;
                            const diffInHours = Math.floor(diffInMs / 3600000);
                            const diffInDays = Math.floor(diffInHours / 24);

                            let timeAgo = "";

                            if (diffInDays >= 1) {
                              timeAgo = diffInDays + "d ";
                            }

                            if (diffInHours < 1) {
                              const diffInMinutes = Math.floor(
                                diffInMs / 60000
                              );
                              timeAgo += diffInMinutes + "m ago";
                            } else {
                              const remainingHours = diffInHours % 24;
                              timeAgo += remainingHours + "h ago";
                            }

                            return (
                              <li className="timeline-item w-full">
                                <div className="timeline-item-wrapper">
                                  <div className="timeline-item-media">
                                    <div className="timeline-item-media-content">
                                      <span className="avatar avatar-circle">
                                        <i className="flex items-center justify-center text-[15px] avatar-img avatar-circle fa-solid fa-user"></i>
                                      </span>
                                    </div>
                                    <div className="timeline-connect" />
                                  </div>
                                  <div className="timeline-item-content">
                                    <p className="my-1 flex items-center">
                                      <span className="font-semibold text-gray-900 ">
                                        {comment.state == 2 ? (
                                          <span className="font-semibold text-gray-900 ">
                                            {ticketDetail.client_name}
                                          </span>
                                        ) : (
                                          <span className="font-semibold text-gray-900 ">
                                            {comment.username}
                                          </span>
                                        )}
                                      </span>
                                      <span className="mx-2">
                                        added a comment{" "}
                                      </span>
                                      <span>{timeAgo}</span>

                                      {comment.state == 2 && !editComment && (
                                        <button
                                          type="button"
                                          className="edit-comment"
                                          onClick={() => {
                                            setEditComment(comment.comment_id);
                                            seteditedComment(comment.comment);
                                          }}
                                          ref={btnRef}
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </p>
                                    <div className="card mt-4 card-border">
                                      <div className="card-body edit-comment-form-body">
                                        {editComment != comment.comment_id ? (
                                          <div className="w-full">
                                            <p>{comment.comment}</p>
                                            <div className="mt-2 flex gap-2 flex-wrap">
                                              {comment?.ticketAttachments?.map(
                                                (attach, index) => (
                                                  <FileCard
                                                    key={attach.id}
                                                    index={index}
                                                    file={attach}
                                                  />
                                                )
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <form
                                            className="edit-comment-form"
                                            onSubmit={(e) => updateComment(e)}
                                          >
                                            <div className="mt-6 mb-3 flex flex-auto">
                                              <div className="ml-4 rtl:mr-4 w-full">
                                                <textarea
                                                  className="input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 w-full p-2 focus:border-indigo-600 input-textarea"
                                                  type="text"
                                                  placeholder="Leave a comment"
                                                  defaultValue={""}
                                                  onChange={(e) =>
                                                    seteditedComment(
                                                      e.target.value
                                                    )
                                                  }
                                                  required
                                                  value={
                                                    editedComment
                                                      ? editedComment
                                                      : comment.comment
                                                  }
                                                />
                                                <div className="mt-2 flex gap-2 flex-wrap">
                                                  {comment?.ticketAttachments?.map(
                                                    (attach, index) => (
                                                      <div
                                                        className={`flex gap-2 items-center ${
                                                          Array.isArray(
                                                            removeAttachments
                                                          ) &&
                                                          removeAttachments.includes(
                                                            attach.id
                                                          )
                                                            ? "hidden"
                                                            : ""
                                                        }`}
                                                      >
                                                        <a
                                                          key={index}
                                                          className="three-dots bg-indigo-50 text-indigo-500 p-1"
                                                          href={attach.link}
                                                          download
                                                        >
                                                          {attach.link}
                                                        </a>
                                                        <div
                                                          className="cursor-pointer text-sm underline text-red-500"
                                                          onClick={() =>
                                                            handleRemoveAttach(
                                                              attach.id
                                                            )
                                                          }
                                                        >
                                                          Remove
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <div>
                                                  <div
                                                    onClick={
                                                      handleSelectButtonClick
                                                    }
                                                    className="mt-2 inline-block text-indigo-500 underline rounded cursor-pointer z-10"
                                                  >
                                                    Select Attachments
                                                  </div>

                                                  <input
                                                    type="file"
                                                    className="hidden"
                                                    ref={inputFileRef}
                                                    onChange={handleFileUpload}
                                                    multiple
                                                  />
                                                  {attachments?.length > 0 && (
                                                    <span className="ml-2">
                                                      {attachments.length} Files
                                                      Selected
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="text-right">
                                              <button
                                                type="submit"
                                                className="flex justify-end float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
                                                disabled={processing}
                                              >
                                                {processing && (
                                                  <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <circle
                                                      className="opacity-25"
                                                      cx="12"
                                                      cy="12"
                                                      r="10"
                                                      stroke="currentColor"
                                                      strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                      className="opacity-75"
                                                      fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                  </svg>
                                                )}
                                                Update
                                              </button>
                                              <button
                                                type="button"
                                                className="flex justify-end float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
                                                disabled={processing}
                                                onClick={() => {
                                                  setEditComment("");
                                                  seteditedComment("");
                                                }}
                                              >
                                                {processing && (
                                                  <svg
                                                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <circle
                                                      className="opacity-25"
                                                      cx="12"
                                                      cy="12"
                                                      r="10"
                                                      stroke="currentColor"
                                                      strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                      className="opacity-75"
                                                      fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                  </svg>
                                                )}
                                                Cancel
                                              </button>
                                            </div>
                                          </form>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                        <form onSubmit={(e) => addComments(e)}>
                          <div className="mt-6 mb-3 flex flex-auto">
                            <span
                              className="avatar avatar-circle"
                              style={{
                                width: 30,
                                height: 30,
                                minWidth: 30,
                                lineHeight: 30,
                                fontSize: 12,
                              }}
                            >
                              <i className="flex items-center justify-center text-[15px] avatar-img avatar-circle fa-solid fa-user"></i>
                            </span>
                            <div className="ml-4 rtl:mr-4 w-full">
                              <textarea
                                className="input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 w-full p-2 focus:border-indigo-600 input-textarea"
                                type="text"
                                placeholder="Leave a comment"
                                defaultValue={""}
                                onChange={(e) => setComment(e.target.value)}
                                required
                                value={comment}
                              />
                              <div>
                                <div
                                  onClick={handleSelectButtonClick}
                                  className="inline-block text-indigo-500 underline rounded cursor-pointer z-10"
                                >
                                  Select Attachments
                                </div>

                                <input
                                  type="file"
                                  className="hidden"
                                  ref={inputFileRef}
                                  onChange={handleFileUpload}
                                  multiple
                                />
                                {attachments?.length > 0 && (
                                  <span className="ml-2">
                                    {attachments.length} Files Selected
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="text-right">
                            <button
                              type="submit"
                              className="flex justify-end float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
                              disabled={processing}
                            >
                              {processing && (
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              )}
                              Comment
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketClientDetail;
