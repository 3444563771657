import { useEffect, useRef, useState } from "react";
import api from "../../api/api";
import Loading from "../loaders/Loading";

const CommunityMembersCard = (props) => {
  const [adminTypeDropdown, setAdminTypeDropdown] = useState(false);
  const [role, setRole] = useState(false);
  const [levelDropdown, setLevelDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const adminTypeBtn = useRef();
  const levelBtn = useRef();
  useEffect(() => {
    const closeDropdown = (e) => {
      if (adminTypeBtn.current && !adminTypeBtn.current.contains(e.target)) {
        setAdminTypeDropdown(false);
      }
      if (levelBtn.current && !levelBtn.current.contains(e.target)) {
        setLevelDropdown(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  useEffect(() => {
    if (props?.member?.role == 1) {
      setRole("Owner");
    }

    if (props?.member?.role == 2) {
      setRole("Admin");
    }

    if (props?.member?.role == 3) {
      setRole("Agent");
    }
  }, [props]);

  const updateMember = (userId, value, type) => {
    if (type == "role") {
      var payload = {
        communityId: props.communityId,
        userId: userId,
        role: value,
      };
      setAdminTypeDropdown(false);
    } else if (type == "level") {
      var payload = {
        communityId: props.communityId,
        userId: userId,
        level: value,
      };
      setLevelDropdown(false);
    }
    setLoading(true);
    api
      .post("/api/updateCommunityMember", payload)
      .then((response) => {
        setLoading(false);

        if (response.data.status == "success") {
          props.storeCommunityDetails(response);
          props.setShowToast(true);
          props.setToastMessage({
            type: "success",
            message: response?.data?.message || "Memebers updated",
          });
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const name = props.member.username || props.member.email;

  return (
    <>
      <td className="flex items-center gap-3 p-4">
        <div
          className={`rounded-full flex items-center justify-center w-[35px] h-[35px] font-medium ${
            !props.member.image && "bg-gray-100"
          }`}
        >
          {props.member.image ? (
            <img className="rounded-full" src={props.member.image}></img>
          ) : (
            name[0]
          )}
        </div>
        <div>
          <div className="text-md font-bold">{props.member.username}</div>
          <div>{props.member.email}</div>
        </div>
      </td>

      <td className="p-4">
        {props.member.role == 1 ? (
          <i>owner</i>
        ) : (
          <div
            ref={adminTypeBtn}
            className="relative cursor-pointer flex items-center"
          >
            <div
              className="bg-gray-50 py-2 text-gray-700"
              onClick={() => setAdminTypeDropdown(!adminTypeDropdown)}
            >
              {loading ? (
                <Loading />
              ) : (
                <span className="capitalize border border-gray-300 rounded-md px-4 py-3">
                  {role} <i className="fa-solid fa-chevron-down ml-6"></i>
                </span>
              )}
            </div>

            {adminTypeDropdown && (
              <div className="z-10 rounded absolute mt-3 bg-white shadow-md w-24 left-0 top-10 border border-gray-300">
                <div
                  className="border-b py-2 px-3 light-blue-hover hover:text-gray-700"
                  onClick={() => updateMember(props.member.id, 2, "role")}
                >
                  Admin
                </div>
                <div
                  className=" py-2 px-3 light-blue-hover hover:text-gray-700"
                  onClick={() => updateMember(props.member.id, 3, "role")}
                >
                  Agent
                </div>
              </div>
            )}
          </div>
        )}
      </td>

      <td className="p-4">
        {props.member.role === 3 && (
          <div
            ref={levelBtn}
            className="relative cursor-pointer  dropdown rounded"
          >
            <div
              className="bg-gray-50 py-2 text-gray-700"
              onClick={() => setLevelDropdown(!levelDropdown)}
            >
              <span className="capitalize border border-gray-300 rounded-md px-4 py-3">
                {props.member.level
                  ? props.member.level?.replace("L", "Level ")
                  : "please select level"}{" "}
                <i className="fa-solid fa-chevron-down ml-6"></i>
              </span>
            </div>
            {levelDropdown && (
              <div className="z-10 rounded absolute mt-3 bg-white shadow-md w-24 left-0 top-10 border border-gray-300">
                {props.levels.map((level) => (
                  <div
                    className="border-b py-2 px-3 light-blue-hover hover:text-gray-700"
                    onClick={() =>
                      updateMember(props.member.id, level.name, "level")
                    }
                  >
                    {level.name?.replace("L", "Level ")}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </td>

      <td className="p-4">
        {props.member.role != 1 && (
          <button
            className="hover:bg-red-50 py-2 px-3 rounded"
            onClick={() => {
              props.handleCommunityLeaveModal(true);
              props.handleCommunityId(props.communityId);
              props.handleUserId(props.member.id);
            }}
          >
            <img src="/images/icons/delete-red.svg" alt="remove" />
          </button>
        )}
      </td>
    </>
  );
};

export default CommunityMembersCard;
