import React from "react";
import SearchInput from "../common/SearchInput";

function CmHeader({ onSearch, handleManage }) {
  return (
    <div className="w-full flex justify-between items-center border-b border-gray-300 pb-6">
      <h3 className="main-title">Customer Management</h3>

      {/* <div className="flex items-center">
        <SearchInput onSearch={onSearch} />

        {handleManage ? (
          <button
            className="button bg-indigo-50 hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round h-9 px-3 py-2 text-sm"
            onClick={() => handleManage()}
          >
            <i class="fa-solid fa-bars-progress"></i>
            <span className="ml-3">Manage Fields</span>
          </button>
        ) : null}
      </div> */}
    </div>
  );
}

export default CmHeader;
