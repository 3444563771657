import { useEffect, useState } from "react";
import Loading from "../../../loaders/Loading";
import Select from "react-select";
import DeleteModal from "../../../common/DeleteModal";
import Spinner from "../../../loaders/Spinner";

function TeamConfigurationForm({
  data = {},
  loading,
  buttonText,
  onSubmit = () => {},
  onSearch = () => {},
  searchData,
  isSearchLoading,
  section,
  getSection = () => {},
  handleSectionFormOpen,
  sectionFormOpen,
  isSectionLoading,
}) {
  const [searchOptions, setSearchOptions] = useState([]);
  const [form, setForm] = useState({
    title: "",
    url: "",
    team_id: "",
    bot_id: data?.uuid,
  });

  const onChange = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: name == "url" ? [value] : value,
    }));
  };

  useEffect(() => {
    const formattedOptions = searchData?.map((team) => ({
      value: team.team_id,
      label: team.name,
    }));
    setSearchOptions(formattedOptions ? formattedOptions : []);
  }, [searchData]);

  useEffect(() => {
    getSection();
  }, [data]);

  useEffect(() => {
    if (sectionFormOpen.open) {
      handleSectionFormOpen({
        open: false,
        type: "",
        data: null,
      });
    } else if (!sectionFormOpen.open) {
      setForm({
        title: "",
        url: "",
        team_id: "",
        bot_id: data?.uuid,
      });
    }
  }, [section]);

  return (
    <div className="mt-4">
      {sectionFormOpen.open && sectionFormOpen?.type !== "Delete" && (
        <div className="relative">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit({ ...form });
            }}
            className="flex flex-col space-y-4"
          >
            <input
              className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !normal-case"
              type="text"
              name="title"
              autoComplete="off"
              placeholder="Enter Title"
              required
              onChange={(e) => onChange("title", e.target.value)}
              value={form.title}
            />
            <input
              className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !normal-case"
              type="text"
              name="url"
              autoComplete="off"
              placeholder="Enter Url"
              required
              onChange={(e) => onChange("url", e.target.value)}
              value={form.url}
            />
            <Select
              className="h-11 mt-2"
              options={[...searchOptions]}
              onInputChange={(newValue) => {
                if (newValue.length > 0) {
                  onSearch(newValue);
                }
              }}
              onChange={(value) => onChange("team_id", value.value)}
              isClearable
              isSearchable
              formatOptionLabel={({ value, label }) => (
                <div>
                  <span>{label}</span>
                </div>
              )}
              required
            />
            <div className="flex items-center gap-3">
              <button
                type="button"
                className="mt-4 hover:bg-gray-500 hover:text-white duration-200 ease bg-gray-100 px-4 py-1 rounded-md text-gray-600 flex items-center space-x-2"
                onClick={() =>
                  handleSectionFormOpen({
                    open: false,
                    type: "",
                    data: null,
                  })
                }
              >
                Back
              </button>
              <button
                type="submit"
                className="mt-4  bg-indigo-600 px-4 py-1 rounded-md text-white flex items-center space-x-2"
              >
                {loading && <Loading />}
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
      {!sectionFormOpen.open && (
        <>
          <div className="flex items-center justify-between">
            <label className="mt-4 flex items-center text-md mt-3 font-semibold text-gray-700">
              Existing Team
            </label>
            <button
              className="bg-indigo-600 px-4 py-1 rounded-md text-white"
              onClick={() =>
                handleSectionFormOpen({
                  open: true,
                  type: "Create",
                  data: null,
                })
              }
            >
              Add Team
            </button>
          </div>
          <div className="members">
            <div className="py-5 border-b flex items-center gap-3 justify-between  communityMember">
              {isSectionLoading ? (
                <div className="w-full flex justify-center m-auto">
                  <Spinner />
                </div>
              ) : (
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 issue-list-title">
                    <tr>
                      <th scope="col" className="px-6 py-3 cursor-pointer">
                        <div className="flex gap-1">
                          <span>Title</span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 cursor-pointer">
                        <div className="flex gap-1">
                          <span>Url</span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3">
                        <div className="flex gap-1">
                          <span>Team</span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3">
                        <div className="flex gap-1">
                          <span>Actions</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {section?.data?.map((data, key) => (
                      <tr key={key} className="bg-white border-b issue-lists">
                        <td className="px-6 py-4">{data.title}</td>
                        <td className="px-6 py-4">{data.url}</td>
                        <td className="px-6 py-4 ticket-priority">
                          {data.name}
                        </td>
                        <td className="px-6 py-4 ticket-status">
                          {/* <button className="bg-blue-50 mr-2 text-blue-500 py-2 px-3 rounded hover:bg-red-500 hover:text-white font-semibold duration-300 ease">
                        <i class="fa-solid fa-pen-to-square cursor-pointer hover:text-blue-600"></i>
                      </button> */}

                          <button
                            className="bg-red-50 text-red-500 py-2 px-3 rounded hover:bg-red-500 hover:text-white font-semibold duration-300 ease"
                            onClick={() =>
                              handleSectionFormOpen({
                                open: true,
                                type: "Delete",
                                data: data,
                              })
                            }
                          >
                            {/* <i class="fa-solid fa-trash cursor-pointer hover:text-red-600"></i> */}
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TeamConfigurationForm;
