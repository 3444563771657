import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format, parse, addDays, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import DownloadWorkLog from "../../components/clockwork/DownlaodWorkLog";
import moment from "moment";
import Spinner from "../../components/loaders/Spinner";

const ClockWork = () => {
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");
  var curr = new Date();
  var first = curr.getDate() - curr.getDay();
  var last = first + 6;

  const firstDayOfWeek = moment().startOf("week");
  const lastDayOfWeek = moment().endOf("week");
  var firstday = new Date(firstDayOfWeek.format("YYYY-MM-DD"));
  var lastday = new Date(lastDayOfWeek.format("YYYY-MM-DD"));

  const {
    currUser,
    getUserInfo,
    currCommunityId,
    setToastMessage,
    setShowToast,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [clockModal, setClockModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [workLogs, setWorkLogs] = useState([]);
  const [formattedDates, setFormattedDates] = useState([]);
  const [clockWork, setClockWork] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [records, setRecords] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: firstday,
      endDate: lastday,
      key: "selection",
    },
  ]);
  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setDateModal(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const getClockWork = () => {
    var selectedDateRange = dateRange;
    var payload = {
      fromDate: new Date(dateRange[0].startDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      toDate: new Date(dateRange[0].endDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      community_id: currCommunityId,
    };
    setProcessing(true);
    api
      .post("/api/clockwork", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status === "success") {
          setJsonData(response.data.data);
          setRecords(response?.data?.records || []);

          var userDetails = Object.values(response.data.data).map(
            (data) => data.userDetails
          );
          if (userDetails?.length > 0) {
            setUsers(userDetails);
          }
          var workLogs = Object.values(response.data.data).map(
            (data) => data.worklogs
          );
          if (workLogs?.length > 0) {
            setWorkLogs(workLogs);
          }
          const startDate = new Date(selectedDateRange[0].startDate);
          const endDate = new Date(selectedDateRange[0].endDate);

          const dateRangeLoc = [];
          let currentDate = new Date(startDate);

          while (currentDate <= endDate) {
            dateRangeLoc.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }
          // console.log("dateRangeLoc", dateRangeLoc, currentDate, endDate);
          const formattedDates = dateRangeLoc.map((date) =>
            formatDateSet(date)
          );
          // console.log("dateRangeLoc", formattedDates);

          setFormattedDates(formattedDates);
          setDateModal(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    getUserInfo();
    getClockWork();
  }, []);

  // useEffect(() => {
  //   if (!dateRange || dateRange.length === 0) {
  //     // Handle the case when dateRange is empty or undefined
  //     setFormattedDates([]);
  //     return;
  //   }

  // }, [dateRange]);

  const formatDateSet = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const formatDate = (dateString, innerIndex) => {
    const [day, month, year] = dateString.split("/");
    const dateObj = new Date(Number(year), Number(month) - 1, Number(day));

    const dayString = dateObj.toLocaleString("default", {
      day: "2-digit",
    });
    const weekdayString = dateObj.toLocaleString("default", {
      weekday: "short",
    });

    return (
      <React.Fragment key={innerIndex}>
        <div className="font-semibold">{dayString}</div>
        <div className="text-sm text-gray-500">{weekdayString}</div>
      </React.Fragment>
    );
  };
  const getTickets = (date, userId) => {
    setClockModal(true);

    var payload = {
      date: date,
      user_id: userId,
      community_id: currCommunityId,
    };
    setTicketLoading(true);
    api
      .post("/api/getUserTicketOnId", payload)
      .then((response) => {
        setTicketLoading(false);
        if (response.data.status === "success") {
          setClockWork(response.data.data);
          // setClockModal(true);
        }
      })
      .catch(() => {
        setTicketLoading(false);
      });
  };

  return (
    <div className="w-full">
      <div className="relative h-full flex flex-auto flex-col sm:py-6 w-full">
        <div className="mx-auto h-full w-full">
          <div className="lg:flex items-center justify-between mb-4">
            <h3 className="mb-4 lg:mb-0 main-title">Work Log</h3>
          </div>

          <div className="flex justify-between my-6">
            <div
              ref={btnRef}
              className="relative flex flex-col md:flex-row md:items-center gap-1"
            >
              <div
                className="calendar-days px-4 py-2 rounded-md bg-white hover:bg-gray-200 border border-gray-300 cursor-pointer flex items-center text-sm"
                onClick={() => setDateModal(!dateModal)}
              >
                <img
                  src="/images/icons/calendar.svg"
                  alt="calendar"
                  className="mr-2"
                />
                {new Date(dateRange[0].startDate).toLocaleDateString()} -{" "}
                {new Date(dateRange[0].endDate).toLocaleDateString()}
              </div>

              {dateModal && (
                <div className="set-date-range bg-white absolute top-16 shadow-lg border rounded left-0 z-10">
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                  />
                  <button
                    className="bg-blue-500 px-3 text-white float-right mb-2 mr-2 py-1 rounded apply-btn flex items-center space-x-4"
                    onClick={() => getClockWork()}
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Apply
                  </button>
                </div>
              )}
            </div>

            <DownloadWorkLog
              jsonData={jsonData}
              formattedDates={formattedDates}
              records={records}
            />
          </div>

          {processing ? (
            <div className="flex items-center justify-center h-[50vh]">
              <Spinner />
            </div>
          ) : users?.length > 0 ? (
            <div className="grid grid-cols-3 gap-1 clock-work">
              <div className="border">
                <div className="font-semibold p-3 pb-6 bg-[#E3E9F3]">
                  <div className="mt-2">Users</div>
                </div>
                <div className="list px-3">
                  {users.map((user) => (
                    <div className="child">
                      <div className="flex text-sm items-center justify-between gap-2 border-b py-3">
                        <div className="flex text-sm items-center gap-2 ">
                          <div
                            className={`rounded-full flex items-center justify-center w-[25px] h-[25px] ${
                              !user.image && "bg-gray-200"
                            }`}
                          >
                            {user.image ? (
                              <img
                                className="w-4 h-4 rounded-full object-cover"
                                src={user.image}
                                alt="profile"
                              ></img>
                            ) : (
                              <i className="fa-solid fa-user text-sm"></i>
                            )}
                          </div>
                          <div>
                            <div className="">
                              {user.username || user.email}
                            </div>
                          </div>
                        </div>
                        <div className="total">{user.totalWork}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="border col-span-2 overflow-scroll clockwork-details">
                <div className="w-[fit-content] border-b bg-[#E3E9F3] pl-3">
                  <div className="flex gap-2">
                    {formattedDates.map((formattedDate, innerIndex) => {
                      return (
                        <div
                          className="w-[90px] text-center border-r border-gray-300 py-3"
                          key={innerIndex}
                        >
                          {formatDate(formattedDate, innerIndex)}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="w-[fit-content]">
                  {workLogs.map((log, index) => {
                    return (
                      <div className="border-b flex gap-2 px-3" key={index}>
                        {formattedDates.map((formattedDate, innerIndex) => {
                          const [day, month, year] = formattedDate.split("/");
                          var date = `${day}-${month}-${year}`;
                          const totalWork = log[date]?.total;

                          return (
                            <div
                              className={`py-3 w-[90px] border-r ${
                                totalWork ? "text-blue-600" : "text-gray-400"
                              } cursor-pointer text-center font-medium`}
                              key={innerIndex}
                              onClick={() => {
                                if (!totalWork) {
                                  return;
                                }

                                getTickets(
                                  `${year}-${month}-${day}`,
                                  log[date]?.user_id
                                );
                              }}
                            >
                              <span>{totalWork ? totalWork : "00:00"}</span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
              {clockModal && (
                <div
                  id="defaultModal"
                  tabIndex={-1}
                  className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
                >
                  <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
                    <div className="relative dialog-content bg-white rounded-lg shadow">
                      <div className="flex items-start justify-between p-4 rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900  ">
                          Clock Work
                        </h3>
                        <button
                          type="button"
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                          data-modal-hide="defaultModal"
                          onClick={() => {
                            setClockModal(false);
                          }}
                        >
                          <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                      {ticketLoading ? (
                        <div className="my-2 flex justify-center items-center pb-8">
                          <Spinner />
                        </div>
                      ) : (
                        <div className="pb-8">
                          {clockWork.map((work) => (
                            <div className="">
                              <span className=" border-b px-4 mt-2  flex items-center justify-between gap-2 py-2 px-1 rounded-md">
                                <div className="flex items-center gap-2 ">
                                  <Link
                                    target="_blank"
                                    to={`/ticket-detail/${work.ticket_id}`}
                                    className="text-blue-500 underline font-semibold "
                                  >
                                    {work.ticket_id}
                                  </Link>
                                  <span className="three-dots font-semibold text-gray-500">
                                    {work.topic}
                                  </span>
                                </div>
                                <div className=" relative flex gap-3 items-center justify-between">
                                  <div className=" text-sm font-semibold text-gray-400">
                                    <div className="font-sm text-gray-500 float-right">
                                      {`${
                                        work?.hours ? `${work?.hours}h` : ""
                                      } ${
                                        work?.minutes ? `${work?.minutes}m` : ""
                                      }`}
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>No Worklogs Found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClockWork;
