import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../../api/api";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import SignInCommon from "../../components/common/SignInCommon";
import SignInWithGoogle from "../../components/common/SignInWithGoogle";
const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [formError, setFormError] = useState("");
  const [processing, setProcessing] = useState(false);
  const location = useNavigate();
  const { currUser, getUserInfo, setToastMessage, setShowToast } =
    useContext(AppContext);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const validation = (name, value, error = null) => {
    if (name === "email") {
      if (error != null) {
        setEmailError(error);
      } else {
        if (value !== "") {
          setEmail(value);
          setEmailError("");
        } else {
          setEmail("");
          setEmailError("Please enter your email");
        }
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    validation(e.target.name, e.target.value);
  };

  const handleForm = (e) => {
    e.preventDefault();
    setProcessing(true);
    var requestOptions = {
      email: email,
    };

    api
      .post("/api/auth/forgotPassword", requestOptions)
      .then((response) => {
        // console.log(response.data);
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Mail Sent Successfully",
        });
        setEmail("");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
        setProcessing(false);
      });
  };

  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-3 h-full">
        <SignInCommon />
        <div className=" flex flex-col justify-center items-center bg-white">
          <div className="xl:min-w-[450px] px-8">
            <div className="mb-8">
              <h3 className="mb-1 text-3xl font-semibold">Hello!</h3>
              <p className="text-gray-600">
                Please enter your Details to Reset password!
              </p>
            </div>
            <div>
              <form action="#" method="post" onSubmit={handleForm}>
                <div className="form-container vertical">
                  <div className="form-item vertical">
                    <label className="form-label mb-2">Email</label>
                    <div className>
                      <input
                        className={`${
                          emailError !== "" || formError !== ""
                            ? "input-danger"
                            : ""
                        } p-3 w-full input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                        type="text"
                        name="email"
                        autoComplete="off"
                        placeholder="Email"
                        onChange={(e) => handleChange(e)}
                        value={email}
                      />
                      {emailError !== "" ? (
                        <div className="form-error">{emailError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <button
                    className="inline-flex items-center justify-center px-4 py-2 font-semibold leading-6 shadow rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-150 w-full"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
