import { useEffect, useState, useRef, useContext } from "react";
import { AppContext } from "../../../App";
import SideBar from "../../../components/settings/SideBar";
import api from "../../../api/api";
import { Link, useParams } from "react-router-dom";
import CommunityMembers from "../../../components/settings/CommunityMembers";
import Spinner from "../../../components/loaders/Spinner";
import SlaSettings from "../../../components/settings/SlaSettings";
import AutoAssign from "../../../components/settings/AutoAssign";
import AdditionalFields from "../../../components/settings/AdditionalFields";
import ApiToken from "../../../components/settings/ApiToken";
import EmailTemplates from "../../../components/settings/EmailTemplates";
import Teams from "../../../components/settings/team/Teams";

const tabs = [
  {
    label: "Community Details",
    value: "communityDetails",
  },
  {
    label: "Auto Assign Settings",
    value: "autoAssignSettings",
  },
  {
    label: "Members",
    value: "members",
  },
  {
    label: "API Tokens",
    value: "apiTokens",
  },
  {
    label: "Email Templates",
    value: "emailTemplates",
  },
  {
    label: "Teams",
    value: "teams",
  },
];

const CommunityDetail = () => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setInviteModal,
    inviteModal,
    setHasChanged,
    handleLinkClick,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [openDetailsForm, setOpenDetailsForm] = useState(false);
  const [communityName, setCommunityName] = useState();
  const [image, setImage] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [communityNameError, setCommunityNameError] = useState("");
  const [communityDetails, setCommunityDetails] = useState([]);
  const [priorityForm, setPriorityForm] = useState({
    p1: {
      days: "",
      hour: "",
    },
    p2: {
      days: "",
      hour: "",
    },
    p3: {
      days: "",
      hour: "",
    },
    p4: {
      days: "",
      hour: "",
    },
    fdr: "",
  });
  const [autoAssignForm, setAutoAssignForm] = useState({
    L1: "",
    L2: "",
  });
  const [communityLeaveModal, setCommunityLeaveModal] = useState(false);
  const [communityId, setCommunityId] = useState("");
  const [addAdditionalFeild, setaddAdditionalFeild] = useState(0);
  const [additionaFieldLevel, setadditionaFieldLevel] = useState(3);
  const [additionaFieldVLevel, setadditionaFieldVLevel] = useState(3);
  const [userId, setUserId] = useState("");
  const btnRef = useRef();
  const [rows, setRows] = useState([]);
  const [deleteImage, setDeleteImage] = useState("");
  //const [errorRows, seterrorRows]=useState(0);

  const [selectedTab, setSelectedTab] = useState("communityDetails");

  const [openTabs, setOpenTabs] = useState([]);

  const handleTabClick = (tab) => {
    if (openTabs.includes(tab)) {
      setOpenTabs(openTabs.filter((openTab) => openTab !== tab));
    } else {
      setOpenTabs([...openTabs, tab]);
    }
  };

  const { id } = useParams();
  const inputFileRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUploadImage(reader.result);
    };
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const storeCommunityDetails = (response) => {
    setCommunityName(response.data.data.name);
    setImage(response.data.data.logo);
    setCommunityDetails(response.data.data);
    const p1TimeSplit = response.data.data?.p1_time?.split("/");
    const p2TimeSplit = response.data.data?.p2_time?.split("/");
    const p3TimeSplit = response.data.data?.p3_time?.split("/");
    const p4TimeSplit = response.data.data?.p4_time?.split("/");
    // console.log(p2TimeSplit);
    setPriorityForm({
      p1: {
        days: p1TimeSplit ? p1TimeSplit[0] : "",
        hour: p1TimeSplit ? p1TimeSplit[1] : "",
      },
      p2: {
        days: p2TimeSplit ? p2TimeSplit[0] : "",
        hour: p2TimeSplit ? p2TimeSplit[1] : "",
      },
      p3: {
        days: p3TimeSplit ? p3TimeSplit[0] : "",
        hour: p3TimeSplit ? p3TimeSplit[1] : "",
      },
      p4: {
        days: p4TimeSplit ? p4TimeSplit[0] : "",
        hour: p4TimeSplit ? p4TimeSplit[1] : "",
      },
      fdr: response.data.data.fdr,
    });

    const formState = {};

    for (const level of response.data.data.levels) {
      formState[level.name] = level.members;
    }

    setAutoAssignForm(formState);
  };

  const handlerowChange = (idx, id) => (e) => {
    // console.log(id);
    setRows((prev) => {
      return prev.map((p, i) => {
        if (i == idx) {
          return { ...p, [e.target.name]: e.target.value };
        }
        return p;
      });
    });
    // console.log(rows);
    setHasChanged(true);
    if (e.target.name == "field_type" && e.target.value == "select") {
      setRows((prevState) => {
        const newState = [...prevState];
        const itemToUpdate = newState[idx];
        itemToUpdate.field_values = ["", "", ""];
        // if (itemToUpdate.field_values && itemToUpdate.field_values.length >= 1) {
        //   itemToUpdate.field_values[id] = e.target.value;
        // }
        return newState;
      });
    }
  };

  const handlechildrowChange = (idx, id) => (e) => {
    setRows((prevState) => {
      const newState = [...prevState];
      const itemToUpdate = newState[idx];
      if (itemToUpdate.field_values && itemToUpdate.field_values.length >= 1) {
        itemToUpdate.field_values[id] = e.target.value;
      }
      return newState;
    });
    setHasChanged(true);
  };

  const handleAddRow = () => {
    const item = {
      id: "",
      field_name: "",
      field_type: "",
      field_values: ["", "", ""],
      errorRows: "",
    };
    setRows([...rows, item]);
    // console.log(rows);
    setHasChanged(true);
  };

  const handleRemoveRow = (idx, id) => (e) => {
    e.preventDefault();
    setRows((rows) => {
      const updatedRows = [...rows];
      updatedRows.splice(idx, 1);
      return updatedRows;
    });
    setaddAdditionalFeild(addAdditionalFeild - 1);
    setHasChanged(true);
  };

  function getSelectedCommunity() {
    var payload = {
      selectedCommunity: id,
    };
    setProcessing(true);

    api
      .post("/api/selectedCommunity", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          storeCommunityDetails(response);

          if (typeof response.data.data.communityFields != "undefined") {
            setRows(response.data.data.communityFields);
            //var fieldLength=response.data.data.community_fields - response.data.data.communityFields.length;
            setaddAdditionalFeild(response.data.data.communityFields.length);
            // console.log(addAdditionalFeild);
          }
        }
      })
      .catch((error) => {
        if (error) {
          window.location.href = "/";
          // console.log("error", error);
        }
      });
  }

  useEffect(() => {
    // console.log(rows);
    getUserInfo();
    getSelectedCommunity();
  }, []);

  const validation = (name, value, error = null) => {
    if (name === "communityName") {
      if (error !== null) {
        setCommunityNameError(error);
      } else {
        if (value !== "") {
          setCommunityName(value);
          setHasChanged(true);

          setCommunityNameError("");
        } else {
          setCommunityName("");

          setCommunityNameError("Please enter your community name");
        }
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    validation(e.target.name, e.target.value);
  };

  const handleLevel = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAutoAssignForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    setHasChanged(true);
  };

  const formSubmit = (e, type) => {
    e.preventDefault();
    if (type == "slaSettings") {
      var payload = {
        community_id: id,
        name: communityName,
        p1: `${priorityForm.p1.days}/${priorityForm.p1.hour}`,
        p2: `${priorityForm.p2.days}/${priorityForm.p2.hour}`,
        p3: `${priorityForm.p3.days}/${priorityForm.p3.hour}`,
        p4: `${priorityForm.p4.days}/${priorityForm.p4.hour}`,
        fdr: priorityForm.fdr,
      };
    } else if (type == "autoAssign") {
      var payload = {
        community_id: id,
        name: communityName,
        levels: autoAssignForm,
      };
    } else {
      var payload = {
        name: communityName,
        image: uploadImage,
        community_id: id,
      };
    }

    if (!communityName) {
      setCommunityNameError("Please enter your community name");
      return;
    }

    setProcessing(true);
    api
      .post("/api/updateCommunityDetails", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status === "success") {
          setHasChanged(false);
          storeCommunityDetails(response);
          setShowToast(true);
          setOpenDetailsForm(false);
          setToastMessage({
            type: "success",
            message: "Data updated Successfully",
          });
          getUserInfo();
          setCommunityNameError("");
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
      });
  };

  const handleAddMore = () => {
    if (Object.keys(autoAssignForm).length <= 4) {
      setAutoAssignForm((prev) => ({
        ...prev,
        [`L${Object.keys(prev).length + 1}`]: "",
      }));
      setHasChanged(true);
    } else {
      setShowToast(true);
      setToastMessage({
        type: "Error",
        message: "Limit to add level exists",
      });
    }
  };

  const handleRemove = (index) => {
    setAutoAssignForm((prevState) => {
      const updatedForm = { ...prevState };
      delete updatedForm[`L${index + 1}`];
      setHasChanged(true);

      const newForm = {};
      Object.keys(updatedForm).forEach((key, newIndex) => {
        newForm[`L${newIndex + 1}`] = updatedForm[key];
      });

      return newForm;
    });
  };

  const fieldsFormSubmit = (e, type) => {
    // console.log(rows);
    e.preventDefault();

    var payload = {
      community_id: id,
      communityFields: rows,
    };
    var dropvalue = "";
    let errorRows = 0;
    rows.forEach((row, index) => {
      if (row["field_type"] == "select") {
        dropvalue = "";
        row["field_values"].forEach((value, pos) => {
          if (value != "") {
            dropvalue = value;
          }
        });
        if (dropvalue == "") {
          setRows((prevState) => {
            const newState = [...prevState];
            const itemToUpdate = newState[index];
            itemToUpdate.errorRows = "Any one value required!";
            return newState;
          });
          errorRows = errorRows + 1;
        } else {
          setRows((prevState) => {
            const newState = [...prevState];
            const itemToUpdate = newState[index];
            itemToUpdate.errorRows = "";
            return newState;
          });
        }
      }
    });

    if (errorRows > 0) {
      return;
    }

    setProcessing(true);
    api
      .post("/api/updateCommunityFields", payload)
      .then((response) => {
        setProcessing(false);
        setHasChanged(false);
        if (response.data.status === "success") {
          setCommunityName(response.data.data.name);
          setImage(response.data.data.logo);
          setCommunityDetails(response.data.data);
          setShowToast(true);
          setOpenDetailsForm(false);

          setToastMessage({
            type: "success",
            message: "Data updated Successfully",
          });
          getUserInfo();
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
      });
  };

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            {/* <div className="lg:flex items-center justify-between mb-4">
              <h3 className="mb-4 lg:mb-0 main-title">
                {communityDetails?.name}
              </h3>
            </div> */}
            <div className="flex gap-4 text-gray-700 min-h-[86vh]">
              <SideBar />
              <div className="w-[80%] mt-5">
                <div className="h-full">
                  <div className="text-2xl font-semibold pb-4 h-auto">
                    Community
                  </div>

                  <div className="border border-gray-300 rounded-md min-h-[94%] bg-white">
                    <Tabs
                      tabs={tabs}
                      onTabChange={(tab) => {
                        setSelectedTab(tab);
                      }}
                      activeTab={selectedTab}
                    />

                    {processing ? (
                      <div className="flex items-center justify-center h-[50vh]">
                        <Spinner />
                      </div>
                    ) : (
                      <div className="p-4">
                        {selectedTab === "communityDetails" ? (
                          <div className="flex justify-between">
                            <form
                              className="mt-3 flex justify-start gap-5"
                              onSubmit={(e) => formSubmit(e)}
                            >
                              <div className="profileImage rounded flex items-center justify-center bg-gray-200 w-[150px] h-[150px]">
                                {!openDetailsForm &&
                                  (image ? (
                                    <img
                                      src={image}
                                      className={`${
                                        openDetailsForm && "filter contrast-50"
                                      }`}
                                      alt=""
                                    />
                                  ) : (
                                    <i className="fa-solid fa-user text-3xl"></i>
                                  ))}
                                {openDetailsForm && (
                                  <>
                                    <img
                                      src={uploadImage ? uploadImage : image}
                                      className={`${
                                        openDetailsForm && "filter contrast-50"
                                      }`}
                                      alt=""
                                    />
                                    <div className="absolute flex flex-col gap-2">
                                      <button
                                        className="bg-indigo-500 text-white px-4 py-2 rounded  z-10"
                                        onClick={handleSelectButtonClick}
                                        type="button"
                                      >
                                        Select
                                      </button>
                                      {image && (
                                        <button
                                          className="bg-red-500 text-white px-4 py-2 rounded  z-10"
                                          onClick={() =>
                                            setUploadImage("delete")
                                          }
                                          type="button"
                                        >
                                          Delete
                                        </button>
                                      )}
                                      <input
                                        type="file"
                                        accept=".png"
                                        className="hidden"
                                        ref={inputFileRef}
                                        onChange={handleFileUpload}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="mx-3 profileDetails">
                                <div className="flex gap-5 flex-wrap">
                                  <div className="w-[250px]">
                                    <label className="font-semibold">
                                      Community Name
                                    </label>
                                    {!openDetailsForm && (
                                      <p className="mt-1">
                                        {communityDetails?.name}
                                      </p>
                                    )}

                                    {openDetailsForm && (
                                      <>
                                        <input
                                          type="text"
                                          className={`${
                                            communityNameError !== ""
                                              ? "input-danger"
                                              : ""
                                          } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 mt-2`}
                                          name="communityName"
                                          autoComplete="off"
                                          placeholder="Community Name"
                                          style={{ paddingRight: "2.25rem" }}
                                          value={communityName}
                                          onChange={(e) => handleChange(e)}
                                        />
                                        {communityNameError !== "" ? (
                                          <div className="form-error">
                                            {communityNameError}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>

                                {openDetailsForm && (
                                  <div className="flex items-center space-x-4 mt-6">
                                    <button
                                      className="px-4 py-2 border border-gray-300 rounded-md text-indigo-600 font-medium"
                                      onClick={(e) => setOpenDetailsForm(false)}
                                      type="button"
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      type="submit"
                                      className="flex px-6 py-2 bg-indigo-600 rounded-md text-white font-medium"
                                      disabled={processing}
                                    >
                                      {processing && (
                                        <svg
                                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                          ></circle>
                                          <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          ></path>
                                        </svg>
                                      )}
                                      Save
                                    </button>
                                  </div>
                                )}
                              </div>
                            </form>

                            <div className="">
                              {!openDetailsForm && (
                                <button
                                  className="hover:bg-gray-100 py-2 px-3 rounded hover:bg-gray-200 duration-300 ease"
                                  onClick={(e) => setOpenDetailsForm(true)}
                                >
                                  <i className="fa-regular fa-pen-to-square text-xl text-indigo-600"></i>
                                </button>
                              )}
                              {/* {openDetailsForm && (
                                <button
                                  className="bg-gray-100 py-2 px-3 rounded hover:bg-gray-200 duration-300 ease"
                                  onClick={(e) => setOpenDetailsForm(false)}
                                >
                                  Cancel
                                </button>
                              )} */}
                            </div>
                          </div>
                        ) : null}

                        {/* <SlaSettings
                        handleTabClick={handleTabClick}
                        openTabs={openTabs}
                        communityDetails={communityDetails}
                        currUser={currUser}
                        communityId={id}
                        storeCommunityDetails={storeCommunityDetails}
                        formSubmit={formSubmit}
                        priorityForm={priorityForm}
                        handlePriorityForm={setPriorityForm}
                      /> */}

                        {selectedTab === "autoAssignSettings" ? (
                          <AutoAssign
                            handleTabClick={handleTabClick}
                            openTabs={openTabs}
                            formSubmit={formSubmit}
                            communityDetails={communityDetails}
                            currUser={currUser}
                            communityId={id}
                            storeCommunityDetails={storeCommunityDetails}
                            autoAssignForm={autoAssignForm}
                            handleAddMore={handleAddMore}
                            handleLevel={handleLevel}
                            handleRemove={handleRemove}
                            refetch={getSelectedCommunity}
                          />
                        ) : null}

                        {selectedTab === "members" ? (
                          <CommunityMembers
                            handleTabClick={handleTabClick}
                            openTabs={openTabs}
                            communityDetails={communityDetails}
                            currUser={currUser}
                            communityId={id}
                            storeCommunityDetails={storeCommunityDetails}
                          />
                        ) : null}

                        {selectedTab === "apiTokens" ? (
                          <ApiToken
                            handleTabClick={handleTabClick}
                            openTabs={openTabs}
                            communityDetails={communityDetails}
                            currUser={currUser}
                            communityId={id}
                            storeCommunityDetails={storeCommunityDetails}
                          />
                        ) : null}

                        {selectedTab === "emailTemplates" ? (
                          <EmailTemplates
                            handleTabClick={handleTabClick}
                            openTabs={openTabs}
                          />
                        ) : null}

                        {selectedTab === "teams" ? (
                          <Teams
                            handleTabClick={handleTabClick}
                            openTabs={openTabs}
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityDetail;

function Tabs({ tabs, onTabChange, activeTab }) {
  return (
    <div className="flex flex-wrap gap-5 items-center bg-[#E3E9F3]">
      {tabs.map((tab, i) => {
        const { label, value } = tab;

        const isActive = activeTab === value;

        return (
          <button
            key={i}
            onClick={() => onTabChange(value)}
            className={`py-4 px-4 font-medium hover:text-indigo-600 ${
              isActive ? "border-b-primary text-indigo-600" : ""
            }`}
            type="button"
          >
            {label}
          </button>
        );
      })}
    </div>
  );
}
