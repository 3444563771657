import { useEffect, useState } from "react";
import pkg from "socket.io-msgpack-parser";
import io from "socket.io-client";
import { socketURL } from "../components/common/commonHelpers";

const { customParser } = pkg;

function useSocket({ currCommunityId }) {
  const [socket, setSocket] = useState(null);
  const [currSocketId, setCurrSocketId] = useState(null);

  useEffect(() => {
    let socket;

    if (currCommunityId) {
      var socketID = document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;\\s*)chatSocketID-admin\\s*=\\s*([^;]*).*$)|^.*$`
        ),
        `$1`
      );

      socket = io(socketURL, {
        withCredentials: true,
        extraHeaders: {
          socketID,
        },
        parser: customParser,
      });

      setSocket(socket);

      socket.on("connect", () => {
        console.log("Connecting socket useSocket.js");

        if (!socketID) {
          socketID = currCommunityId;
          document.cookie = "chatSocketID-admin=" + currCommunityId;
        }

        setCurrSocketId(socketID);
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [currCommunityId]);

  return { socket, setSocket, currSocketId, setCurrSocketId };
}

export default useSocket;
