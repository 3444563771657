import { useEffect, useState } from "react";
import Insert from "../common/Insert";
import DefaultInsertOptions from "../common/DefaultInsertOptions";
import { useParams } from "react-router-dom";
import useChildFlow from "../../sidebar/flowPages/useChildFlow";
import useApiData from "../../../../../hooks/useApiData";
import { sortByDisplayOrder } from "../../../../common/commonHelpers";
import ChildFlowsOptions from "./ChildFlowsOptions";
import ToolTip from "../../../../common/ToolTip";

function handleOnChange(value, index, existingButtons) {
  const existingButtonsData = [...existingButtons];

  const prevData = existingButtonsData[index] || {};

  existingButtonsData[index] = { ...prevData, name: value };

  return existingButtonsData;
}

function handleAddBtn(existingButtons) {
  return [...existingButtons, { name: "" }];
}

function handleRemoveBtn(existingButtons, index) {
  const remainingBtns = existingButtons.filter((_, i) => i !== index);
  return remainingBtns;
}

function onChildFlowSelect(childFlow, existingButtons, index) {
  const { uuid, flowName } = childFlow;

  const existingButtonsData = [...existingButtons];

  const prevData = existingButtonsData[index] || {};

  existingButtonsData[index] = {
    ...prevData,
    flowConnection: {
      uuid,
      flowName,
    },
  };

  return existingButtonsData;
}

function ButtonResponseType({ btnData, onChangingBtns, isDisabled, error }) {
  const { id, botflowId } = useParams();

  const { getChildFlows } = useChildFlow(id, botflowId);

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => {},
  });

  useEffect(() => {
    getList(getChildFlows());
  }, []);

  const existingButtons = btnData?.values || [];

  const childFlows = sortByDisplayOrder(data?.data, "displayorder");

  return (
    <div className="mt-4 text-sm">
      {existingButtons.map((btn, index) => {
        return (
          <ButtonText
            key={index}
            value={btn?.name}
            onChange={(e) => {
              const newBtnData = handleOnChange(
                e.target.value,
                index,
                existingButtons
              );

              onChangingBtns(newBtnData);
            }}
            existingButtonsCount={existingButtons?.length}
            onRemove={() => {
              const newBtnData = handleRemoveBtn(existingButtons, index);
              onChangingBtns(newBtnData);
            }}
            childFlows={childFlows}
            loading={loading}
            onChildFlowSelect={(childFlow) => {
              const newBtnData = onChildFlowSelect(
                childFlow,
                existingButtons,
                index
              );

              onChangingBtns(newBtnData);
            }}
            flowConnection={btn?.flowConnection || {}}
            error={error[index]}
            isDisabled={isDisabled}
          />
        );
      })}

      {isDisabled || existingButtons?.length >= 5 ? null : (
        <button
          className="bg-white text-primary-600 p-2 w-full mt-2 border font-semibold hover:bg-blue-50"
          onClick={() => {
            const newBtnData = handleAddBtn(existingButtons);
            onChangingBtns(newBtnData);
          }}
        >
          Add option
        </button>
      )}
    </div>
  );
}

export default ButtonResponseType;

function ButtonText({
  onChange,
  value,
  onRemove,
  existingButtonsCount,
  childFlows,
  loading,
  onChildFlowSelect,
  flowConnection,
  isDisabled,
  error,
}) {
  const childFlowData =
    childFlows.find((c) => c.uuid === flowConnection?.uuid) || {};

  const selectedChildFlowLabel = childFlowData?.displayorder ? (
    <SelectedChildFlowLabel {...childFlowData} />
  ) : null;

  return (
    <div
      className={`mt-2 flex justify-between items-center border border-gray-300 bg-white relative pr-2  ${
        error ? "border-red-600" : ""
      }`}
    >
      <input
        type="text"
        className={`w-11/12 p-2 outline-none text-sm`}
        placeholder="Button text"
        onChange={onChange}
        value={value}
      />

      <Insert
        label={selectedChildFlowLabel}
        optionsCls={selectedChildFlowLabel ? "!left-12" : "left-11"}
      >
        <ChildFlowsOptions
          childFlows={childFlows}
          onChildFlowSelect={onChildFlowSelect}
          loading={loading}
        />
      </Insert>

      {existingButtonsCount <= 1 || isDisabled ? null : (
        <i
          className="fa-solid fa-xmark font-bold text-gray-700 text-md absolute top-3 -right-5 cursor-pointer z-10"
          onClick={() => {
            if (isDisabled) {
              return;
            }

            onRemove();
          }}
        ></i>
      )}
    </div>
  );
}

function SelectedChildFlowLabel({ flowName, displayorder }) {
  if (!displayorder) {
    return;
  }

  return (
    <ToolTip tip={flowName} tipCls="bottom-[22px]">
      <button className="px-3 py-1.5 rounded-md bg-indigo-600 text-white font-semibold">
        {displayorder}
      </button>
    </ToolTip>
  );
}
