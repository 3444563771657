import { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../../api/api";

const CommunityCard = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [communityLeaveModal, setCommunityLeaveModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [communityId, setCommunityId] = useState("");
  const [gpt_key, setgpt_key] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [processing, setProcessing] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [gpt_keyError, setgpt_keyError] = useState("");
  const btnRef = useRef();

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [gptCheck, setGptCheck] = useState();
  const location = useNavigate();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const leaveCommunity = (e) => {
    e.preventDefault();
    setProcessing(true);
    var payload = {
      community_id: communityId,
      currCommunity: currCommunityId,
    };
    api.post("/api/leaveCommunity", payload).then((response) => {
      setProcessing(false);
      if (response.data.status == "success") {
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Leaved This Community",
        });
        getUserInfo();
      }
    });
  };
  const redirectFun = (e) => {
    if (props.community.role == 1 || props.community.role == 2) {
      if (btnRef.current) {
        if (btnRef.current && !btnRef.current.contains(e.target)) {
          location("/settings/community/" + props.community.community_id);
        }
      } else {
        location("/settings/community/" + props.community.community_id);
      }
    }
  };
  return (
    <div>
      <div className="card card-border">
        <div className="card-body h-full folder-card">
          <div className="flex flex-col justify-between h-full">
            <div className="cursor-pointer" onClick={(e) => redirectFun(e)}>
              <div className="flex justify-between">
                <h6 className="card-title">{props.community.name}</h6>
                {props.community.role == 3 && (
                  <div className="dropdown" ref={btnRef}>
                    <div
                      className="dropdown-toggle"
                      id="dropdown-toggle-873-eVCGyHdcfE"
                    >
                      <button
                        className="button bg-transparent border border-transparent hover:bg-gray-50 active:bg-gray-100 text-gray-600 radius-circle h-7 w-7 inline-flex items-center justify-center text-base"
                        onClick={(e) => {
                          setOpen(!open);
                        }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                        </svg>
                      </button>
                    </div>
                    <ul
                      id="base-menu-124-eIThs96oYp"
                      className={`dropdown-menu bottom-end ${
                        open ? "block" : "hidden"
                      }`}
                      style={{
                        opacity: 1,
                        visibility: "visible",
                        transform: "rotateX(0deg)",
                      }}
                    >
                      {props.community.created_by == currUser?.hashId ? (
                        <></>
                      ) : (
                        <li
                          className="menu-item menu-item-dark menu-item-hoverable text-red-500 hover:text-white hover:bg-red-500"
                          style={{ height: "35px" }}
                          onClick={() => {
                            setCommunityLeaveModal(true);
                            setCommunityId(props.community.community_id);
                          }}
                        >
                          <span className="text-base">
                            <i className="fa-solid fa-right-from-bracket"></i>
                          </span>
                          <span className="ml-2 rtl:mr-2">Leave</span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {communityLeaveModal && (
              <div
                id="defaultModal"
                tabIndex={-1}
                className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
              >
                <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
                  <div
                    className="relative dialog-content bg-white rounded-lg shadow"
                    ref={btnRef}
                  >
                    <div className="text-center flex items-start justify-between p-4 rounded-t">
                      <div>
                        <h3 className="text-xl font-semibold text-gray-900  ">
                          Leave Community
                        </h3>
                        <p className="mt-5">
                          Are you sure you want to Leave this community?
                        </p>
                      </div>
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                        data-modal-hide="defaultModal"
                        onClick={() => {
                          setCommunityLeaveModal(false);
                          setCommunityId("");
                        }}
                      >
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <form action="#" onSubmit={(e) => leaveCommunity(e)}>
                      <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                        <button
                          className="inline-flex items-center justify-center button bg-red-600 hover:bg-red-500 active:bg-red-700 text-white radius-round h-11 px-8 py-2 w-full"
                          type="submit"
                          disabled={processing}
                        >
                          {processing && (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}
                          Leave
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
