import { useEffect, useRef, useState } from "react";

function DropDown({ label, children, childrenCls = "", onClose }) {
  const [open, setOpen] = useState(false);

  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);

        if (onClose) {
          onClose();
        }
      }
    };

    document.body.addEventListener("click", closeDropdown);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div className="dropdown w-full" ref={btnRef}>
      <div className="dropdown-toggle" id="dropdown-toggle-873-eVCGyHdcfE">
        <button className="w-full" onClick={() => setOpen(!open)}>
          {label}
        </button>
      </div>

      <div
        className={`mt-3 mr-5 dropdown-menu bottom-end ${
          open ? "block" : "hidden"
        } ${childrenCls}`}
        style={{
          opacity: 1,
          visibility: "visible",
          transform: "rotateX(0deg)",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default DropDown;
