import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useEffect, useRef, useState } from "react";
import useDropDown from "../../../../../hooks/useDropDown";
import Select from "react-select";
import ButtonResponseType from "./ButtonResponseType";
import Insert from "../common/Insert";
import FormResponseType from "./FormResponseType";
import DefaultInsertOptions from "../common/DefaultInsertOptions";
import {
  getInsertFormat,
  insertPropertyToInput,
} from "../../../../common/commonHelpers";
import ReactQuill from "react-quill";
import { handleDialogEditListener } from "../helpers/dialogHelpers";

function isHavingText(messageText) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = messageText || "";
  const textContent = tempElement.textContent;

  return textContent.trim().length > 0;
}

function MessageDialog({
  msgData,
  dialogResponses,
  onTextMsgChange,
  onSelectingResponseType,
  onChangingBtns,
  onDeleteDialog,
  onSubmitForm,
  error,
  isActive,
}) {
  const quillRef = useRef();
  const messageDialogRef = useRef();

  const modules = {
    toolbar: [["bold", "italic", "underline", "image", "video"]],
  };

  const insertTextAtCursor = (textToInsert, remoteVariablesData) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection();

      if (!selection) {
        return;
      }

      quill.insertText(selection.index, `{{${textToInsert}}}`);

      onTextMsgChange(quill.root.innerHTML, remoteVariablesData);
    }
  };

  const { answerType } = dialogResponses[0] || {};

  const isMsgHaving = isHavingText(msgData?.messageText);

  const isMsgError = error?.messageText;

  return (
    <div
      className={`my-6 border rounded-md w-96 relative bg-gray-100`}
      ref={messageDialogRef}
    >
      <div className="absolute top-0 -right-14 w-10 text-center">
        <i
          className="fa-solid fa-xmark border border-gray-300 bg-gray-100 w-full py-1.5 cursor-pointer text-gray-700 text-xl rounded-md"
          onClick={onDeleteDialog}
        ></i>

        <br />
      </div>

      <div className="m-8">
        <ReactQuill
          theme="snow"
          value={msgData?.messageText}
          onChange={(value) => {
            onTextMsgChange(value);
          }}
          className={`bg-white ${isMsgError ? "border-red-600" : ""} ${
            isActive.current ? "" : "hidden"
          }`}
          modules={modules}
          placeholder="Type your message here..."
          ref={quillRef}
        />

        {isActive.current ? null : (
          <p
            className="bg-white p-4 border rounded-md text-[13px] leading-6"
            dangerouslySetInnerHTML={{
              __html: msgData?.messageText,
            }}
          ></p>
        )}

        {isMsgError ? (
          <div className="my-2 text-red-600 text-sm">
            This field is required
          </div>
        ) : null}

        <div className="flex justify-between items-center mt-3 w-full">
          <GetResponse
            responseType={answerType}
            setResponseType={(val) => {
              onSelectingResponseType(val);
            }}
            isActive={isActive}
            isMsgHaving={isMsgHaving}
          />

          <Insert disabled={!isActive.current}>
            <DefaultInsertOptions
              onInsert={(insertValue, option) => {
                if (!insertValue) {
                  return;
                }

                const {
                  variableConfigurationId,
                  apiConfigurationId,
                  prefixString,
                } = option;

                const data = { name: insertValue, prefixString };

                if (variableConfigurationId) {
                  data.variableConfigurationId = variableConfigurationId;
                } else if (apiConfigurationId) {
                  data.apiConfigurationId = apiConfigurationId;
                }

                insertTextAtCursor(
                  getInsertFormat(prefixString, insertValue),
                  data
                );
              }}
            />
          </Insert>
        </div>

        {answerType === "BUTTON" ? (
          <ButtonResponseType
            btnData={dialogResponses[0] || {}}
            onChangingBtns={onChangingBtns}
            isDisabled={!isActive.current || !isMsgHaving}
            error={error?.buttonResponseTypeError || {}}
          />
        ) : null}

        {answerType === "FORM" ? (
          <FormResponseType
            onSubmitForm={(data) => {
              onSubmitForm(data);
            }}
            dialogResponses={dialogResponses[0] || {}}
            isDisabled={!isActive.current || !isMsgHaving}
            error={error?.formResponseTypeError || ""}
          />
        ) : null}
      </div>
    </div>
  );
}

export default MessageDialog;

const responseOptions = [
  {
    label: "Button",
    value: "BUTTON",
  },
  {
    label: "Input",
    value: "INPUT",
  },
  {
    label: "Form",
    value: "FORM",
  },
];

function GetResponse({ responseType, setResponseType, isActive, isMsgHaving }) {
  const [openResponseType, setOpenResponseType] = useState();

  const response = responseOptions.find((r) => r.value === responseType) || {};

  return (
    <div className="w-11/12 relative">
      {response?.value ? (
        <div className="flex items-center space-x-4 text-gray-700">
          {isActive.current ? (
            <>
              <button
                className="hover:bg-gray-200 text-sm p-1"
                onClick={() => setOpenResponseType(true)}
                disabled={!isMsgHaving}
              >
                {response?.label}
                <i className="fa-solid fa-angle-down text-xs ml-1"></i>
              </button>

              <button
                className="text-primary-600 text-sm text-left"
                onClick={() => setResponseType(null)}
                disabled={!isMsgHaving}
              >
                Clear
              </button>
            </>
          ) : (
            <div>{response?.label}</div>
          )}
        </div>
      ) : (
        <button
          className="text-primary-600 text-sm text-left font-semibold"
          onClick={() => setOpenResponseType(true)}
          // disabled={!isMsgHaving || !isActive.current}
        >
          + Get response
        </button>
      )}

      {openResponseType ? (
        <ResponseOptions
          open={openResponseType}
          setOpen={setOpenResponseType}
          onSelectingResponseType={(value) => {
            setResponseType(value);
            setOpenResponseType(false);
          }}
          isActive={isActive}
        />
      ) : null}
    </div>
  );
}

function ResponseOptions({ open, setOpen, onSelectingResponseType, isActive }) {
  const { elementRef } = useDropDown({ open, setOpen });

  return (
    <div
      className="absolutes z-50 py-2 top-6 left-0 text-sm text-gray-700"
      ref={elementRef}
    >
      <Select
        className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 w-40"
        options={responseOptions}
        onChange={onSelectingResponseType}
        isClearable
        components={{ DropdownIndicator: () => null }}
        isSearchable
        menuIsOpen="open"
        autoFocus
        isDisabled={!isActive.current}
      />
    </div>
  );
}
