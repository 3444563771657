import { formatDateWithTime } from "../../../components/common/commonHelpers";

function AvailableThemes() {
  return (
    <div>
      <div className="mt-8 w-[320px] relative">
        <div className="font-semibold text-gray-500 my-2">Available Themes</div>

        <img
          src={require("../../../assets/images/support-portal.png")}
          alt="support-portal"
          className="w-full h-auto"
        />

        <div className="bg-gray-100 absolute bottom-0 left-0 w-full p-4 text-gray-700 text-sm">
          <div className="flex justify-between">
            <div className="font-semibold">Customer Portal</div>

            {/* <div className="bg-green-50 border border-green-300 text-green-600 font-semibold rounded-md px-2 py-1 text-xs w-fit">
              Live
            </div> */}
          </div>

          {/* <div className="text-[12px] mt-2">
            Updated {formatDateWithTime(new Date())}
          </div> */}

          <div className="flex justify-end">
            <i class="fa-solid fa-ellipsis cursor-pointer"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailableThemes;
