import { useContext, useEffect, useState } from "react";
import CmTable from "../../components/customerManagement/CmTable";
import CmHeader from "../../components/customerManagement/CmHeader";
import TableSkeleton from "../../components/loaders/TableSkeleton";
import Modal from "../../components/common/Modal";
import AdditionalFieldsForm from "../../components/common/AdditionalFieldsForm";
import DeleteModal from "../../components/common/DeleteModal";
import EditModal from "../../components/customerManagement/EditModal";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import { AppContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import UsersTabs from "../../components/customerManagement/UsersTabs";
import SearchInput from "../../components/common/SearchInput";
import SendMailForm from "../../components/customerManagement/mail/SendMailForm";
import Tabs from "../../components/common/Tabs";
import VerticalTabs from "../../components/common/VerticalTabs";
import ViewTemplates from "../../components/customerManagement/mail/ViewTemplates";
import CmSettings from "../../components/customerManagement/CmSettings";
import ToolTip from "../../components/common/ToolTip";
import BulkMail from "../../components/customerManagement/mail/BulkMail/BulkMail";

function getEmails(to) {
  const mails = [];

  to?.forEach((t) => {
    mails.push(t.client_email);
  });

  return [...new Set(mails)];
}

const BULK_MAIL_TITLE_FOR_STEP = {
  1: "Filter Users",
  2: "Emails",
  3: "Email Templates",
  4: "Preview",
};

function CmContainer() {
  const [openManageFields, setOpenManageFields] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentModalId, setCurrModalId] = useState();
  const [queryParams, setQueryParams] = useState();
  const [search, setSearch] = useState();
  const [openMailForm, setOpenMailForm] = useState(false);
  const [openBulkMail, setOpenBulkMail] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedMails, setSelectedMails] = useState([]);
  const [mailType, setMailType] = useState("custom");
  const [bulkMailStep, setBulkMailStep] = useState(1);

  const { setToastMessage, setShowToast, currCommunityId, onError } =
    useContext(AppContext);
  const history = useNavigate();
  const location = useLocation();

  const {
    data,
    mutate,
    loading: isLoading,
    isError,
    error: errorConfig,
  } = useApiData({});

  useEffect(() => {
    if (queryParams && Object.keys(queryParams).length > 0) {
      getCustomerDetails();
    }
  }, [queryParams]);

  function getAPIPath() {
    const { client_id, ticket_id, page } = queryParams;

    let path = `api/v1/getClientDetails/communityId/${currCommunityId}?page=${page}`;

    if (search) {
      path += `&search=${search}`;
    }

    if (client_id) {
      path += `&client_id=${client_id}`;
    }

    if (ticket_id) {
      path += `&ticketIds=${ticket_id}`;
    }

    return path;
  }

  useEffect(() => {
    if (search?.length >= 3 || search?.length == 0) {
      mutate(() => api.get(getAPIPath()));
    }
  }, [search]);

  const getCustomerDetails = () => {
    var page = queryParams.page;
    mutate(() => api.get(getAPIPath()));
  };

  const {
    data: deleteData,
    mutate: deleteApi,
    loading: isDeleteLoading,
  } = useApiData({
    onSuccess: (res) => {
      if (res.data.statusCode == "200") {
        setCurrModalId();
        getCustomerDetails();
        setOpenDelete(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Client Deleted Successfully",
        });
      } else {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      }
    },
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      if (value) {
        params[key] = value;
      } else {
        params[key] = "";
      }
    }
    if (!params?.page) {
      params["page"] = 1;
    }
    setQueryParams(params);
  }, []);

  const sortTicket = (sortParams) => {
    const updatedParams = { ...queryParams, ...sortParams };
    setQueryParams(updatedParams);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    // console.log(queryParams);
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        if (queryParams[key]) {
          const value = queryParams[key];
          const paramName = `${key}`;
          searchParams.append(paramName, value);
        }
      }
    }
    history({ search: searchParams.toString() });
  }, [queryParams]);

  const {
    data: customFields,
    mutate: getCustomFieldsApi,
    loading: customFieldLoading,
  } = useApiData({
    onSuccess: (res) => {},
  });

  const getClientCustomFieldsData = () => {
    getCustomFieldsApi(() =>
      api.get(`api/v1/getClientCustomFields/communityId/${currCommunityId}`)
    );
  };

  useEffect(() => {
    getClientCustomFieldsData();
  }, []);

  return (
    <div className="pb-12 pt-7 px-2">
      <CmHeader />

      <div className="flex items-center justify-between mt-4">
        <div className="flex items-center flex-wrap gap-4 w-[30%]">
          <button
            className={`flex items-center border border-gray-300 text-blue-600 rounded-md px-4 py-2 ${
              selectedUsers?.length
                ? "text-red-600"
                : "cursor-not-allowed text-gray-700 opacity-70"
            }`}
            onClick={() => {
              setOpenDelete(true);
            }}
            disabled={!selectedUsers?.length}
          >
            <i className="fa-regular fa-trash-can mr-3"></i>
            Delete
          </button>

          <button
            className={`flex items-center border border-gray-300 text-blue-600 rounded-md px-4 py-2`}
            onClick={() => {
              setOpenBulkMail(true);
            }}
          >
            <img
              src="/images/icons/bulk-mail.svg"
              alt="bulk-mail"
              className="mr-3"
            />
            Bulk Mail
          </button>

          <div className="flex items-center space-x-4">
            <button
              className={`${
                selectedUsers?.length
                  ? "text-blue-600"
                  : "cursor-not-allowed text-gray-500"
              } flex items-center border border-gray-300 rounded-md px-4 py-2`}
              onClick={() => {
                if (!selectedUsers?.length) {
                  onError("Please select the users");
                  return;
                }

                setOpenMailForm(true);

                setSelectedMails(getEmails(selectedUsers));
              }}
              disabled={!selectedUsers?.length}
            >
              <img
                src={`/images/icons/${
                  selectedUsers?.length ? "mail" : "mail-gray"
                }.svg`}
                alt="mail"
                className="mr-3"
              />
              Email
            </button>

            <div>
              <ToolTip
                tip="Please select the users to send Email"
                tipCls="!w-40"
              >
                <i className="fa-regular fa-circle-question text-blue-700 cursor-pointer"></i>
              </ToolTip>
            </div>
          </div>
        </div>

        <UsersTabs />

        {/* <button
          className={`border border-blue-700 text-blue-700 rounded-md px-4 py-1 mr-3`}
          onClick={() => {
            setOpenBulkMail(true);
          }}
        >
          <i className="fa-regular fa-paper-plane text-sm mr-2"></i>
          Bulk Mail
        </button> */}

        {/* <div className="flex items-center mr-3">
          <button
            className={`${
              selectedUsers?.length ? "" : "opacity-70 cursor-not-allowed"
            } border border-blue-700 text-blue-700 rounded-md px-4 py-1 mr-3`}
            onClick={() => {
              if (!selectedUsers?.length) {
                onError("Please select the users");
                return;
              }

              setOpenMailForm(true);
            }}
            disabled={!selectedUsers?.length}
          >
            <i className="fa-regular fa-paper-plane text-sm mr-2"></i>
            Mail
          </button>

          <div>
            <ToolTip tip="Please select the users to send mail" tipCls="!w-40">
              <i className="fa-regular fa-circle-question text-blue-700 cursor-pointer"></i>
            </ToolTip>
          </div>
        </div> */}

        <div className="flex items-center space-x-4 pr-6">
          <SearchInput
            onSearch={(e) => {
              const { name, value } = e.target;
              setSearch(value);
            }}
          />

          <ToolTip
            tip={"Manage Fields"}
            tipCls="!bg-secondary-600 -right-8 !w-[120px] text-center"
          >
            <img
              src="/images/icons/filter-black.svg"
              alt="manage-fields"
              className="cursor-pointer"
              onClick={() => {
                setOpenManageFields(true);
              }}
            />
          </ToolTip>

          <ToolTip
            tip={"Campaign Templates"}
            tipCls="!bg-secondary-600 -right-8 w-[150px] text-center"
          >
            <img
              src="/images/icons/template.svg"
              alt="email-templates"
              className="cursor-pointer"
              onClick={() => {
                history("/customer-management/email-templates");
              }}
            />
          </ToolTip>

          {/* <CmSettings
            onManageFields={() => {
              setOpenManageFields(true);
            }}
            onMailTemplates={() => {
              history("/customer-management/email-templates");
            }}
          /> */}
        </div>

        {/* <button
          className="button bg-indigo-50 hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round h-9 px-3 py-2 text-sm"
          onClick={() => {
            setOpenManageFields(true);
          }}
        >
          <i class="fa-solid fa-bars-progress"></i>
          <span className="ml-3">Manage Fields</span>
        </button> */}
      </div>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <CmTable
          onDelete={(id) => {
            setOpenDelete(true);
            setCurrModalId(id);
          }}
          onEdit={(id) => {
            setOpenEdit(true);
            setCurrModalId(id);
          }}
          onMail={(email) => {
            setSelectedUsers([email]);
            setOpenMailForm(true);
          }}
          queryParams={queryParams}
          sortTicket={sortTicket}
          data={data}
          customFields={customFields}
          getClientCustomFieldsData={getClientCustomFieldsData}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      )}

      <Modal
        isOpen={openManageFields}
        onClose={() => setOpenManageFields(false)}
        title="Manage Fields"
      >
        <AdditionalFieldsForm
          customFields={customFields}
          isLoading={customFieldLoading}
          getClientCustomFieldsData={getClientCustomFieldsData}
        />
      </Modal>
      {openDelete && (
        <DeleteModal
          title={
            selectedUsers?.length > 1
              ? "Are you sure, you want to delete the clients"
              : "Are you sure, you want to delete this client"
          }
          open={openDelete}
          onClose={() => {
            setCurrModalId();
            setOpenDelete(false);

            setSelectedUsers([]);
          }}
          loading={isDeleteLoading}
          onDelete={() => {
            // if (selectedUsers?.length) {
            //   return;
            // }

            deleteApi(() =>
              api.post(`api/v1/deleteClient/communityId/${currCommunityId}`, {
                clientIds: currentModalId ? [currentModalId] : selectedUsers,
              })
            );
          }}
        />
      )}
      {openEdit && (
        <EditModal
          open={openEdit}
          clientId={currentModalId}
          onClose={() => {
            setCurrModalId();
            setOpenEdit(false);
          }}
          customFields={customFields}
          getCustomerDetails={getCustomerDetails}
        />
      )}

      <Modal
        title={`Send Email`}
        isOpen={openMailForm}
        onClose={() => {
          setOpenMailForm(false);
          setSelectedUsers([]);
          setSelectedMails([]);
        }}
      >
        <div className="min-w-[480px]">
          {/* <VerticalTabs
            tabs={[
              { label: "Custom", value: "custom" },
              { label: "Template", value: "template" },
            ]}
            activeTab={mailType}
            onTabChange={(value) => setMailType(value)}
          /> */}
          {/* {mailType === "custom" ? (
            <SendMailForm to={openMailForm} />
          ) : (
            <ViewTemplates to={openMailForm} />
          )} */}

          <ViewTemplates
            to={selectedMails}
            onClose={() => {
              setOpenMailForm(false);
              setSelectedUsers([]);
              setSelectedMails([]);
            }}
            setSelectedUsers={setSelectedMails}
          />
        </div>
      </Modal>

      <Modal
        title={BULK_MAIL_TITLE_FOR_STEP[bulkMailStep]}
        isOpen={openBulkMail}
        onClose={() => {
          setOpenBulkMail(false);
          setBulkMailStep(1);
        }}
        // customCls={bulkMailStep === 0 ? "!overflow-x-visible" : null}
      >
        <BulkMail
          customFields={customFields}
          step={bulkMailStep}
          onNext={() => setBulkMailStep((prev) => prev + 1)}
          onBack={() => setBulkMailStep((prev) => prev - 1)}
          onClose={() => {
            setOpenBulkMail(false);
            setBulkMailStep(1);
          }}
        />
      </Modal>
    </div>
  );
}

export default CmContainer;
