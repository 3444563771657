import Table from "../common/Table";

function Avatar({ name }) {
  const colors = ["red", "blue", "green", "yellow", "indigo", "purple", "pink"];
  const color = colors[Math.floor(Math.random() * colors.length)];
  const initials = name
    ?.split(" ")
    ?.map((word) => word[0])
    ?.join("");

  return (
    <div
      className={`bg-gray-100 assignMember cursor-pointer hover:shadow-md duration-300 ease`}
    >
      {initials}
    </div>
  );
}

const MyTickets = (props) => {
  const columns = [
    {
      name: "Issue No",
      renderComp: (data) => (
        <a
          href={`/ticket-detail/${data.ticket_id}`}
          className="text-indigo-600"
          target="_blank"
          rel="noreferrer"
        >
          {data.ticket_id}
        </a>
      ),
    },
    {
      name: "Topic",
      renderComp: (data) => data.topic,
    },
    {
      name: "Assignee",
      renderComp: (data) => (
        <div className="assignParentDiv">
          <div className="flex assignList">
            {data?.assignedMembers?.map((data, index) => (
              <div className="assignMemberContainer">
                {data?.image ? (
                  <img
                    src={data.image}
                    className={`rounded-full w-[25px] h-[25px]`}
                  />
                ) : (
                  <Avatar key={index} name={data.username || data.email} />
                )}
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      name: "Priority",
      renderComp: (data) => data.priority,
    },
  ];

  return (
    <Table
      data={props?.tickets || []}
      columns={columns}
      containerCls="text-sm"
    />
  );
};

export default MyTickets;
