function DisplayClientCustomFields({ client_custom_fields = [] }) {
  return (
    <div>
      {client_custom_fields.map((field, i) => {
        const { field_name, field_type, customfield_values = [] } = field;

        return (
          <div key={i}>
            {["input", "select"].includes(field_type) ? (
              <DisplayField left={field_name} right={customfield_values} />
            ) : (
              <DisplayField
                left={field_name}
                right={customfield_values.join(", ")}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default DisplayClientCustomFields;

function DisplayField({ left, right }) {
  return (
    <div className="">
      <span className="pr-1 font-semibold text-gray-600">{left}: </span> {right}
    </div>
  );
}
