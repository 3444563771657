import BotflowLayout from "../../components/chatBot/botflow/BotflowLayout";
import PropertyConfigurationList from "../../components/chatBot/botflow/configuration/propertyConfiguration/PropertyConfigurationList";

function PropertyConfigurationContainer() {
  return (
    <BotflowLayout tab="configuration">
      <div className="container chatBotTraining p-8">
        <PropertyConfigurationList />
      </div>
    </BotflowLayout>
  );
}

export default PropertyConfigurationContainer;
