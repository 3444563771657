import React, { useRef, useState, useEffect } from "react";
import io from "socket.io-client";
import { json, useParams } from "react-router-dom";
import { socketURL } from "../common/commonHelpers";

// const ICE_SERVERS = {
//   iceServers: [
//     {
//       urls: "stun:openrelay.metered.ca:80",
//     },
//   ],
// };

const Call = () => {
  const [micActive, setMicActive] = useState(true);
  const [cameraActive, setCameraActive] = useState(true);
  const [shareActive, setShareActive] = useState(false);
  const [peerAdded, setPeerAdded] = useState(false);
  const userVideoRef = useRef();
  const peerVideoRef = useRef();
  const shareVideoRef = useRef();
  const rtcConnectionRef = useRef(null);
  const socketRef = useRef();
  const userStreamRef = useRef();
  const hostRef = useRef(false);
  const senders = useRef([]);
  const [mystream, setmystream] = useState(null);

  const { id: roomName } = useParams();

  useEffect(() => {
    socketRef.current = io(socketURL);
    socketRef.current.emit("join-call", roomName);

    socketRef.current.on("joined", handleRoomJoined);
    socketRef.current.on("created", handleRoomCreated);
    socketRef.current.on("ready", initiateCall);

    socketRef.current.on("leave", onPeerLeave);

    socketRef.current.on("offer", handleReceivedOffer);
    socketRef.current.on("answer", handleAnswer);
    socketRef.current.on("ice-candidate", handlerNewIceCandidateMsg);

    return () => socketRef.current.disconnect();
  }, [roomName]);

  const handleRoomJoined = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        /* use the stream */
        userStreamRef.current = stream;
        userVideoRef.current.srcObject = stream;
        userVideoRef.current.onloadedmetadata = () => {
          userVideoRef.current.play();
        };
        socketRef.current.emit("ready", roomName);
      })
      .catch((err) => {
        /* handle the error */
        // console.log("error", err);
      });
  };

  const handleRoomCreated = () => {
    hostRef.current = true;
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        /* use the stream */
        userStreamRef.current = stream;
        userVideoRef.current.srcObject = stream;
        userVideoRef.current.onloadedmetadata = () => {
          userVideoRef.current.play();
        };

        setmystream(stream);
        toggleMediaStream("video", false);
        setCameraActive(true);
      })
      .catch((err) => {
        /* handle the error */
        // console.log(err);
      });
  };

  const initiateCall = () => {
    if (hostRef.current) {
      // alert("peer");

      rtcConnectionRef.current = createPeerConnection();
      rtcConnectionRef.current.addTrack(
        userStreamRef.current.getTracks()[0],
        userStreamRef.current
      );
      rtcConnectionRef.current.addTrack(
        userStreamRef.current.getTracks()[1],
        userStreamRef.current
      );
      rtcConnectionRef.current
        .createOffer()
        .then((offer) => {
          rtcConnectionRef.current.setLocalDescription(offer);
          socketRef.current.emit("offer", offer, roomName);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const onPeerLeave = () => {
    hostRef.current = true;
    if (peerVideoRef.current.srcObject) {
      peerVideoRef.current.srcObject
        .getTracks()
        .forEach((track) => track.stop());
    }

    if (rtcConnectionRef.current) {
      rtcConnectionRef.current.ontrack = null;
      rtcConnectionRef.current.onicecandidate = null;
      rtcConnectionRef.current.close();
      rtcConnectionRef.current = null;
    }
  };

  const createPeerConnection = () => {
    const connection = new RTCPeerConnection();

    connection.onicecandidate = handleICECandidateEvent;

    connection.ontrack = handleTrackEvent;

    return connection;
  };

  const handleReceivedOffer = (offer) => {
    if (!hostRef.current) {
      rtcConnectionRef.current = createPeerConnection();

      rtcConnectionRef.current.addTrack(
        userStreamRef.current.getTracks()[0],
        userStreamRef.current
      );
      rtcConnectionRef.current.addTrack(
        userStreamRef.current.getTracks()[1],
        userStreamRef.current
      );
      rtcConnectionRef.current.setRemoteDescription(offer);

      rtcConnectionRef.current
        .createAnswer()
        .then((answer) => {
          rtcConnectionRef.current.setLocalDescription(answer);
          socketRef.current.emit("answer", answer, roomName);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const handleAnswer = (answer) => {
    rtcConnectionRef.current
      .setRemoteDescription(answer)
      .catch((err) => console.log(err));
  };

  const handleICECandidateEvent = (event) => {
    if (event.candidate) {
      socketRef.current.emit("ice-candidate", event.candidate, roomName);
    }
  };

  const handlerNewIceCandidateMsg = (incoming) => {
    const candidate = new RTCIceCandidate(incoming);
    rtcConnectionRef.current
      .addIceCandidate(candidate)
      .catch((e) => console.log(e));
  };

  const handleTrackEvent = (event) => {
    peerVideoRef.current.srcObject = event.streams[0];
    setPeerAdded(true);
  };

  const toggleMediaStream = (type, state) => {
    userStreamRef.current.getTracks().forEach((track) => {
      if (track.kind === type) {
        track.enabled = !state;

        if (type === "video") {
          if (state) {
            //track.stop();
          } else {
            //const videoRef = useRef(null);
          }
        }
      }
    });
  };

  const toggleMic = () => {
    toggleMediaStream("audio", micActive);
    setMicActive((prev) => !prev);
  };

  const toggleCamera = () => {
    // toggleMediaStream("video", cameraActive);
    // setCameraActive((cameraActive) => !cameraActive);
    // console.log(mystream);
    // console.log(cameraActive);
    if (cameraActive) {
      // console.log("if");
      setCameraActive((cameraActive) => !cameraActive);

      userVideoRef.current.srcObject = mystream;
      //userVideoRef.current.srcObject.getVideoTracks().forEach((track) => track.stop());

      mystream.getTracks().forEach(function (track) {
        if (track.readyState === "live" && track.kind === "video") {
          track.stop();
        }
      });
    } else {
      // console.log("else if");
      setCameraActive((cameraActive) => !cameraActive);

      userVideoRef.current.srcObject = mystream;
      //userVideoRef.current.play();
      mystream.getTracks().forEach(function (track) {
        if (track.readyState === "live" && track.kind === "video") {
          track.play();
        }
      });
    }
  };

  const leaveRoom = () => {
    socketRef.current.emit("leave", roomName);

    if (userVideoRef.current.srcObject) {
      userVideoRef.current.srcObject
        .getTracks()
        .forEach((track) => track.stop());
    }

    if (peerVideoRef.current.srcObject) {
      peerVideoRef.current.srcObject
        .getTracks()
        .forEach((track) => track.stop());
    }

    if (rtcConnectionRef.current) {
      rtcConnectionRef.current.ontrack = null;
      rtcConnectionRef.current.onicecandidate = null;
      rtcConnectionRef.current.close();
      rtcConnectionRef.current = null;
    }
    window.close();
  };

  const handleScreenShare = () => {
    navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
      // replace the share video source object with the new stream
      // console.log("stream", rtcConnectionRef.current.getSenders());

      senders.current = rtcConnectionRef.current.getSenders();
      const videoSender = senders.current.find((sender) => {
        return sender.track.kind === "video";
      });
      videoSender.replaceTrack(stream.getTracks()[0]);
      setShareActive(true);
    });
  };

  const handleStopScreenShare = () => {
    // replace the share video source object with the new stream
    // console.log("stream", userStreamRef.current.getTracks());

    senders.current = rtcConnectionRef.current.getSenders();
    const videoSender = senders.current.find((sender) => {
      return sender.track.kind === "video";
    });
    videoSender.replaceTrack(userStreamRef.current.getTracks()[1]);

    setShareActive(false);
  };

  return (
    <div className="w-full my-10">
      <div className="m-auto">
        <div className="flex justify-center items-center gap-5 flex-wrap w-full">
          <div className="relative">
            {/* {userVideoRef && (
              <div className="bg-green-500 current_user videoBg text-3xl text-white videoBg absolute w-[500px]  rounded-xl">
                Me
              </div>
            )} */}
            <video
              poster="placeholder.png"
              muted="muted"
              autoPlay
              ref={userVideoRef}
              className="w-[500px] block rounded-xl"
            />
          </div>
          <div className="relative">
            {/* {peerAdded && (
              <div className=" videoBg text-3xl videoBg current_user bg-pink-500 text-white absolute w-[500px]  rounded-xl">
                other
              </div>
            )} */}
            <video
              autoPlay
              ref={peerVideoRef}
              className="w-[500px] block rounded-xl"
            />
          </div>
          {/* {shareActive && (
            <video className="share-video" ref={shareVideoRef} autoPlay></video>
          )} */}
        </div>
        <div className="mt-5 flex justify-center items-center gap-5 flex-wrap w-full">
          <button
            className={`${
              !micActive && "bg-red-700 text-white"
            } duration-300 ease border w-[40px] h-[40px] flex items-center justify-center rounded-full`}
            onClick={toggleMic}
            type="button"
          >
            {micActive ? (
              <i className="fa-solid fa-microphone"></i>
            ) : (
              <i className="fa-solid fa-microphone-slash"></i>
            )}
          </button>

          <button
            className={`${
              !cameraActive && "bg-red-700 text-white"
            } duration-300 ease border w-[40px] h-[40px] flex items-center justify-center rounded-full`}
            onClick={toggleCamera}
            type="button"
          >
            {cameraActive ? (
              <i className="fa-solid fa-video"></i>
            ) : (
              <i className="fa-solid fa-video-slash"></i>
            )}
          </button>
          {shareActive ? (
            <button
              className={` duration-300 ease border w-[40px] h-[40px] flex items-center justify-center rounded-full`}
              type="button"
              onClick={() => handleStopScreenShare()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                id="stop-screen-share"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M21.79 18l2 2H24v-2h-2.21zM1.11 2.98l1.55 1.56c-.41.37-.66.89-.66 1.48V16c0 1.1.9 2 2.01 2H0v2h18.13l2.71 2.71 1.41-1.41L2.52 1.57 1.11 2.98zM4 6.02h.13l4.95 4.93C7.94 12.07 7.31 13.52 7 15c.96-1.29 2.13-2.08 3.67-2.46l3.46 3.48H4v-10zm16 0v10.19l1.3 1.3c.42-.37.7-.89.7-1.49v-10c0-1.11-.9-2-2-2H7.8l2 2H20zm-7.07 3.13l2.79 2.78 1.28-1.2L13 7v2.13l-.07.02z" />
              </svg>
            </button>
          ) : (
            <button
              className={` border w-[40px] h-[40px] flex items-center justify-center rounded-full`}
              type="button"
              onClick={() => handleScreenShare()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                id="screen-share"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6a2 2 0 0 0-2-2H4c-1.11 0-2 .89-2 2v10a2 2 0 0 0 2 2H0v2h24v-2h-4zM4 16V6h16v10.01L4 16zm9-6.87c-3.89.54-5.44 3.2-6 5.87 1.39-1.87 3.22-2.72 6-2.72v2.19l4-3.74L13 7v2.13z" />
              </svg>
            </button>
          )}
          <button
            className="text-sm endCall bg-red-700 text-white py-1 px-3 rounded-md"
            onClick={leaveRoom}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              id="end-call"
            >
              <path fill="none" d="M0 0h24v24H0V0z" />
              <path d="M4.51 15.48l2-1.59c.48-.38.76-.96.76-1.57v-2.6c3.02-.98 6.29-.99 9.32 0v2.61c0 .61.28 1.19.76 1.57l1.99 1.58c.8.63 1.94.57 2.66-.15l1.22-1.22c.8-.8.8-2.13-.05-2.88-6.41-5.66-16.07-5.66-22.48 0-.85.75-.85 2.08-.05 2.88l1.22 1.22c.71.72 1.85.78 2.65.15z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Call;
