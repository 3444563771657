import { useCallback, useContext } from "react";
import api from "../../../../api/api";
import { AppContext } from "../../../../App";

function useBotFlowList() {
  const { currCommunityId } = useContext(AppContext);

  const getBotFlowsList = useCallback(
    (botId) => {
      return () => api.get(`/api/${currCommunityId}/${botId}/parentBotFlows`);
    },
    [currCommunityId]
  );

  const getParentBotFlow = useCallback(
    (botId, id) => {
      return () =>
        api.get(`/api/${currCommunityId}/${botId}/parentBotFlow/${id}`);
    },
    [currCommunityId]
  );

  const createBotFlow = useCallback(
    (botId, payload) => {
      return () =>
        api.post(
          `/api/${currCommunityId}/${botId}/createParentBotFlow`,
          payload
        );
    },
    [currCommunityId]
  );

  const updateBotFlow = useCallback(
    (botId, payload, updateId) => {
      return () =>
        api.put(
          `/api/${currCommunityId}/${botId}/updateParentBotFlow/${updateId}`,
          payload
        );
    },
    [currCommunityId]
  );

  const deleteBotFlow = useCallback(
    (botId, payload, deleteId) => {
      return () =>
        api.delete(
          `/api/${currCommunityId}/${botId}/deleteParentBotFlow/${deleteId}`
        );
    },
    [currCommunityId]
  );

  return {
    getBotFlowsList,
    createBotFlow,
    updateBotFlow,
    deleteBotFlow,
    getParentBotFlow,
  };
}

export default useBotFlowList;
