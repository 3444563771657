import { useParams, useSearchParams } from "react-router-dom";
import BotflowLayout from "../../components/chatBot/botflow/BotflowLayout";
import useBuildBotFlow from "../../components/chatBot/botflow/buildBotFlow/useBuildBotFlow";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import useApiData from "../../hooks/useApiData";
import {
  embedBotflowPreview,
  sortByDisplayOrder,
} from "../../components/common/commonHelpers";
import AddDialogBtn from "../../components/chatBot/botflow/buildBotFlow/common/AddDialogBtn";
import DialogForm from "../../components/chatBot/botflow/buildBotFlow/DialogForm";
import Spinner from "../../components/loaders/Spinner";
import useBotFlowList from "../../components/chatBot/botflow/listBotFlow/useBotFlowList";
import { validateFlow } from "../../components/chatBot/botflow/buildBotFlow/helpers/dialogHelpers";
import DeleteModal from "../../components/common/DeleteModal";

function isAddBtnDisabled(disableAdd) {
  return Object.values(disableAdd).includes(true);
}

function getDefaultFormTypeError() {
  return {
    message: {
      messageText: "",
      responseType: "",
      formResponseTypeError: "",
      buttonResponseTypeError: { 1: "" },
    },
    action: {
      actionType: "",
      enumSaveIn: "",
      key: "",
      value: "",
      api: "",
    },
  };
}

function getDefaultFlow() {
  return {
    1: {
      dialogmessages: [],
      dialogactions: [],
      displayOrder: 1,
    },
  };
}

function BuildBotFlowContainer() {
  const [flows, setFlows] = useState({});

  const [flowsError, setFlowsError] = useState({});

  const [disableAdd, setDisableAdd] = useState(false);

  const [gettingFlows, setGettingFlows] = useState(true);

  const [activeDialog, setActiveDialog] = useState();

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    value: null,
  });
  // state (setFlows) updation is slow, so we creating a ref variable.
  const flowsRef = useRef({});

  const { onSuccess, onError } = useContext(AppContext);

  const { id, botflowId } = useParams();

  const [searchParams] = useSearchParams();
  const childFlowId = searchParams.get("childFlowId");

  const { getBotFlows, createBotFlow, deleteBotFlow } = useBuildBotFlow(
    id,
    botflowId,
    childFlowId
  );

  const { getParentBotFlow } = useBotFlowList();

  const {
    data: parentBotFlowData,
    mutate: getParentBot,
    loading: isGettingParentBot,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => {
      onError("Something went wrong while getting parent bot flow");
    },
  });

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: (res) => {
      const botFlows = res.data?.data || [];

      const flows = botFlows.filter((f) => f.displayOrder);

      if (flows?.length) {
        const refactor = {};

        sortByDisplayOrder(flows, "displayOrder").forEach((f) => {
          const { displayOrder } = f;

          refactor[displayOrder] = f;
        });

        const refactorFlowChanged = {};

        Object.keys(refactor).forEach((r) => (refactorFlowChanged[r] = false));

        setFlows(refactor);
      } else {
        setFlows({});
      }

      setGettingFlows(false);
      setActiveDialog(null);
      setTimeout(() => {
        setDisableAdd(false);
      }, 1000);
    },
    onError: () => onError("Something went wrong while getting flows"),
  });

  const { mutate: create, loading: isCreateLoading } = useApiData({
    onSuccess: (res) => {
      const msg = res?.data?.msg || "Created";
      if (!deleteDialog.open) {
        onSuccess(`Dialogue ${msg} Successfully`);
      }
      getList(getBotFlows());
    },
    onError: () => {
      onError("Something went wrong while creating flow");
      getList(getBotFlows());
    },
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      setDeleteDialog({
        open: false,
        value: null,
      });

      onSuccess("Flow Deleted Successfully");
      getList(getBotFlows());
    },
    onError: () => onError("Something went wrong while deleting flow"),
  });

  function updateFlows(update, displayOrder) {
    embedBotflowPreview(id, botflowId);
    console.log(update);

    let newData;
    setFlows((prev) => {
      const { create } = update;
      const existingData = prev[displayOrder] || {};
      console.log(update);

      if (!create) {
        newData = {
          ...prev,
          [displayOrder]: { ...existingData, ...update },
        };
      } else {
        newData = prev;
        Object.values(prev).forEach((flowData, order) => {
          const int = parseInt(flowData.displayOrder);
          if (int >= displayOrder) {
            newData[int + 1] = {
              ...flowData,
              displayOrder: parseInt(flowData.displayOrder) + 1,
            };
          } else {
            newData[int] = flowData;
          }
        });
        newData[displayOrder] = { ...update, create: false };
      }
      console.log("newData", newData);
      return newData;
    });
  }

  useEffect(() => {
    getParentBot(getParentBotFlow(id, botflowId));
  }, []);

  useEffect(() => {
    if (childFlowId) {
      getList(getBotFlows());
      setGettingFlows(true);
    }
  }, [childFlowId]);

  // const flows = data?.data || [];

  function createBotFlowSetup(displayOrder) {
    // getting data from flows
    const flow = flowsRef.current[displayOrder];

    // if (!flow) {
    //   return;
    // }

    const error = validateFlow(flow);
    const { havingError, ...errorData } = error[displayOrder];

    if (havingError) {
      console.log("error", error);
      setFlowsError(error);
      return;
    } else {
      setFlowsError({});
    }

    const refactor = JSON.parse(JSON.stringify(flow));

    if (flow?.entityType === "MESSAGE") {
      const dialogmessagesData = refactor?.dialogmessages[0] || {};

      const dialogresponsesData = dialogmessagesData?.dialogresponses
        ? dialogmessagesData.dialogresponses[0]
        : {};

      const btnsData = dialogresponsesData?.values || [];

      const dialogresponses = dialogmessagesData?.dialogresponses;

      if (dialogresponses?.length) {
        dialogresponses[0].configurationId =
          dialogresponsesData?.configurationId?.uuid;
      }

      if (btnsData?.length && dialogresponses?.length) {
        dialogresponses[0].values = btnsData.map((b) => ({
          name: b.name,
          flowConnectionId: b?.flowConnection?.uuid,
        }));
      }

      refactor.dialogmessages = [{ ...dialogmessagesData, dialogresponses }];
    } else if (flow?.entityType === "ACTION") {
      const dialogActionsData = refactor?.dialogactions[0] || {};

      if (dialogActionsData?.actionType) {
        dialogActionsData.configurationId =
          dialogActionsData?.configurationId?.uuid;
      }

      refactor.dialogactions = [dialogActionsData];
    }

    console.log("refactor", refactor);

    // updateEnableEdit(false, displayOrder);

    create(createBotFlow(refactor));
  }

  function onDeleteDialog(displayOrder) {
    const flowData = flows[displayOrder] || {};

    setDisableAdd(false);

    // updateDisableAdd(false, displayOrder);

    if (flowData.uuid) {
      deleteApi(deleteBotFlow(flowData.uuid));
    } else {
      const newFlows = JSON.parse(JSON.stringify(flows));

      delete newFlows[displayOrder];

      setFlows(newFlows);
      setDeleteDialog({
        open: false,
        value: null,
      });
    }
  }

  useEffect(() => {
    flowsRef.current = flows;
  }, [flows]);

  const childFlowName = data?.childFlowDetails?.name || "";

  const parentFlowName = parentBotFlowData?.data?.flowName || "";
  // console.log("flows", flows);
  return (
    <BotflowLayout tab="bot">
      <div className="botBuilderContainer flex flex-col items-center pb-12">
        <div className="py-6 font-bold text-indigo-600 text-xl">
          {parentFlowName}
        </div>

        {childFlowId ? (
          <>
            {isDeleteLoading || gettingFlows ? (
              <div className="mt-12">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="pb-4 font-bold text-gray-700 text-lg">
                  {childFlowName}
                </div>

                {isCreateLoading || loading ? (
                  <div className="text-gray-700 mb-2 text-sm">Saving...</div>
                ) : null}

                {Object.entries(flows).map(([key, value], i) => {
                  return (
                    <>
                      <AddDialogBtn
                        updateFlows={updateFlows}
                        nextFlowCount={
                          value?.displayOrder ? parseInt(value.displayOrder) : 1
                        }
                        setActiveDialog={setActiveDialog}
                        disableAdd={disableAdd}
                        setDisableAdd={setDisableAdd}
                      />
                      <DialogForm
                        key={i}
                        flow={value}
                        updateFlows={updateFlows}
                        displayOrder={key}
                        createBotFlowSetup={createBotFlowSetup}
                        onDeleteDialog={() =>
                          setDeleteDialog({
                            open: true,
                            value: key,
                          })
                        }
                        error={flowsError[key] || {}}
                        setActiveDialog={setActiveDialog}
                        setDisableAdd={setDisableAdd}
                        activeDialog={activeDialog}
                      />
                    </>
                  );
                })}
                {/* {Object.keys(flows)?.length == 0 && ( */}
                <AddDialogBtn
                  updateFlows={updateFlows}
                  nextFlowCount={Object.keys(flows)?.length + 1}
                  setActiveDialog={setActiveDialog}
                  disableAdd={disableAdd}
                  setDisableAdd={setDisableAdd}
                />
                {/* )} */}
              </>
            )}
          </>
        ) : (
          <div className="py-2 font-bold text-gray-700 text-lg">
            Please select or create a Flow
          </div>
        )}
      </div>
      <DeleteModal
        title={"Are you sure, you want to delete this Dialogue"}
        open={deleteDialog.open}
        onClose={() =>
          setDeleteDialog({
            open: false,
            value: null,
          })
        }
        loading={isDeleteLoading}
        onDelete={() => {
          onDeleteDialog(deleteDialog.value);
        }}
      />
    </BotflowLayout>
  );
}

export default BuildBotFlowContainer;
