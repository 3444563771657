import { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function QuillInput({
  label,
  labelCls = "",
  inputCls = "",
  error,
  value = "",
  modules = [],
  name,
  onChange,
  ...inputProps
}) {
  const { required } = inputProps;
  const quillRef = useRef();

  return (
    <div className="w-full">
      {label && (
        <div className={`my-1 ${required ? "required-input" : ""} ${labelCls}`}>
          {label}
        </div>
      )}

      <ReactQuill
        theme="snow"
        name={name}
        ref={quillRef}
        className={`${error ? "border border-red-400" : ""} ${inputCls}`}
        value={value}
        onChange={(value) => {
          const payload = {
            target: {
              name,
              value,
            },
          };

          onChange(payload);
        }}
        {...inputProps}
      />

      {error && <div className="text-red-600 pt-2 text-sm">{error}</div>}
    </div>
  );
}

export default QuillInput;
