import { ToggleLeft, ToggleRight, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import Loading from "../../../components/loaders/Loading";

const KnowledgeBase = ({
  portal,
  updateKb,
  loading,
  deleteKb,
  deleteLoading,
  getLoading,
  isUpdating,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (portal?.knowledgeBase) {
      const updatedData = portal?.knowledgeBase.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setData(updatedData);
    }
  }, [portal]);

  const handleEdit = (id, field, value) => {
    const updatedData = data.map((item) =>
      item.id === id
        ? {
            ...item,
            [field]: value,
            error: {
              ...item.error,
              title:
                field === "title"
                  ? !value
                    ? "Title is required"
                    : ""
                  : item.error?.title,
              url:
                field === "url"
                  ? !value
                    ? "Url is required"
                    : ""
                  : item.error?.url,
            },
          }
        : item
    );
    setData(updatedData);
  };

  const removeKey = (id, field) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        const newItem = { ...item };
        delete newItem[field];
        return newItem;
      } else {
        return item;
      }
    });
    setData(updatedData);
  };

  const addNewData = () => {
    const newData = {
      id: data.length + 1,
      uuid: "",
      title: ``,
      url: "",
      status: "1",
      newData: true,
    };
    setData([...data, newData]);
  };

  const deleteData = (id, uuid) => {
    // const updatedData = data.filter((item) => item.id !== id);
    // const rearrangedData = updatedData.map((item, index) => ({
    //   ...item,
    //   id: index + 1,
    // }));
    // setData(rearrangedData);
    deleteKb(uuid);
  };

  const formSubmit = (updatedItem) => {
    const { title, url, id } = updatedItem[0];
    if (!title || !url) {
      const updatedData = data.map((item) =>
        item.id === id
          ? {
              ...item,
              error: {
                ...item.error,
                title: !title ? "Title is required" : "",
                url: !url ? "URL is required" : "",
              },
            }
          : item
      );
      setData(updatedData);
      return;
    }

    const updatedData = data.map((item) => {
      if (item.id === id) {
        const newItem = { ...item, error: { title: "", url: "" } };
        delete newItem["newData"];
        return newItem;
      } else {
        return item;
      }
    });
    setData(updatedData);

    updateKb(updatedItem);
  };
  return (
    <div className="mt-12">
      <div className="font-semibold pb-2 border-b border-gray-300 text-lg">
        Additional Links
      </div>

      <div className="container mx-auto mt-8">
        <button
          className="border border-blue-300 hover:bg-blue-50 px-4 py-2 rounded-md h-fit text-blue-600 float-right mb-3"
          onClick={() => addNewData()}
        >
          + Create New
        </button>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">S.NO</th>
              <th className="py-2 px-4 border-b">Title</th>
              <th className="py-2 px-4 border-b">Url</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <KnowledgeBaseRow
                item={item}
                portal={portal}
                handleEdit={handleEdit}
                deleteData={deleteData}
                removeKey={removeKey}
                formSubmit={formSubmit}
                loading={loading}
                deleteLoading={deleteLoading}
                getLoading={getLoading}
                isUpdating={isUpdating}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KnowledgeBase;

function KnowledgeBaseRow({
  item,
  portal,
  loading,
  deleteLoading,
  getLoading,
  isUpdating,
  handleEdit = () => {},
  deleteData = () => {},
  removeKey = () => {},
  formSubmit = () => {},
}) {
  const [edit, setEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    if (item?.newData) {
      setEdit(true);
    }
  }, []);

  useEffect(() => {
    if (portal && !item?.newData) {
      setEdit(false);
    }
  }, [portal]);

  useEffect(() => {
    if (!isUpdating) {
      setSelectedId("");
    }
  }, [isUpdating]);
  return (
    <tr key={item.id}>
      <td className="py-2 px-4 border-b">{item.id}</td>
      <td className="py-2 px-4 border-b">
        {edit ? (
          <div>
            <input
              type="text"
              value={item.title}
              placeholder="Please enter a title"
              onChange={(e) => handleEdit(item.id, "title", e.target.value)}
              className="w-full px-2 py-1 border rounded"
            />
            {item?.error?.title && (
              <div className="form-error">{item?.error?.title}</div>
            )}
          </div>
        ) : (
          item.title
        )}
      </td>
      <td className="py-2 px-4 border-b">
        {edit ? (
          <div>
            <input
              type="text"
              value={item.url}
              placeholder="Please enter a Url"
              onChange={(e) => handleEdit(item.id, "url", e.target.value)}
              className="w-full px-2 py-1 border rounded"
            />
            {item?.error?.url && (
              <div className="form-error">{item?.error?.url}</div>
            )}
          </div>
        ) : (
          item.url
        )}
      </td>
      <td className={`text-center py-2 px-4 border-b `}>
        {edit ? (
          item.status === "1" ? (
            <i
              className="text-2xl cursor-pointer fa-solid fa-toggle-on text-green-500"
              onClick={(e) => handleEdit(item.id, "status", "0")}
            ></i>
          ) : (
            <i
              className="text-2xl cursor-pointer fa-solid fa-toggle-off "
              onClick={(e) => handleEdit(item.id, "status", "1")}
            ></i>
          )
        ) : (
          <span
            className={`py-2 px-4 text-md ${
              item.status === "1"
                ? "bg-green-100 text-green-600"
                : "bg-gray-100 text-gray-600"
            }`}
          >
            {item.status === "1" ? "Enabled" : "Disabled"}
          </span>
        )}
      </td>
      <td className="text-center py-2 px-4 border-b flex gap-2 items-center justify-center cursor-pointer ">
        {edit ? (
          <div className="flex gap-2 items-center">
            <button
              className="border bg-blue-400 border-blue-300 hover:bg-blue-500 px-2 py-1 rounded-md flex text-white duration-300 ease border-r text-md border-gray-400"
              onClick={(e) => {
                formSubmit([item]);
                setSelectedId(item.id);
              }}
            >
              {isUpdating && item.id === selectedId && <Loading />}
              Save
            </button>
            <i
              className="fa fa-times pl-1.5 border-gray-400"
              aria-hidden="true"
              onClick={(e) => setEdit(false)}
            ></i>
          </div>
        ) : (
          <>
            <i
              className="fa fa-edit text-xl pr-1.5 border-r border-gray-400"
              aria-hidden="true"
              onClick={(e) => setEdit(true)}
            ></i>
            {(deleteLoading || getLoading) && item.uuid === selectedId ? (
              <Loading />
            ) : (
              <i
                class="fa-solid text-xl fa-trash pl-1.5"
                onClick={(e) => {
                  setSelectedId(item.uuid);
                  deleteData(item.id, item.uuid);
                }}
              ></i>
            )}
          </>
        )}
      </td>
    </tr>
  );
}
