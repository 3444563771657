import React from "react";
import { write, utils, writeFile } from "xlsx";

const DownloadWorkLog = (props) => {
  const handleClick = () => {
    const dataArray = convertJSONToArray(props.jsonData, props.formattedDates);
    const workbook = createWorkbook(dataArray);
    const xlsxFile = generateXLSXFile(workbook);
    downloadFile(xlsxFile, "data.xlsx");
  };

  const convertJSONToArray = (json_data, formattedDates) => {
    const dataArray = [];

    // Add header row
    const headerRow = ["Users", "Total Work"];
    const formattedHeaderRow = formattedDates.map((date) => {
      const [day, month, year] = date.split("/");
      return `${day}-${month}-${year.slice(-2)}`;
    });

    headerRow.push(...formattedHeaderRow);
    dataArray.push(headerRow);

    // Add data rows
    for (const user in json_data) {
      const userDetails = json_data[user]["userDetails"];
      const worklogs = json_data[user]["worklogs"];

      const dataRow = [userDetails["username"], userDetails["totalWork"]];
      for (const date of formattedDates) {
        const [day, month, year] = date.split("/");
        var formattedDate = `${day}-${month}-${year.slice(-2)}`;
        const total = worklogs[formattedDate]
          ? worklogs[formattedDate].total
          : "0:00";
        dataRow.push(total);
      }

      dataArray.push(dataRow);
    }

    return dataArray;
  };

  const createWorkbook = (dataArray) => {
    const worksheet = utils.aoa_to_sheet(dataArray);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  const generateXLSXFile = (workbook) => {
    const xlsxFile = write(workbook, { bookType: "xlsx", type: "array" });
    return xlsxFile;
  };

  const downloadFile = (fileData, fileName) => {
    const blob = new Blob([fileData], { type: "application/octet-stream" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(link.href);
  };

  const exportToExcelClockworkRecords = () => {
    const { records } = props;

    if (!records?.length) {
      return;
    }

    const ws = utils.aoa_to_sheet([
      ["Date", "Ticket Id", "Name", "Topic", "Duration"],
      ...records.map((record) => [
        record.date,
        record.ticket_id,
        record.name,
        record.topic,
        `${record.hours ? record.hours : 0}h ${
          record.minutes ? record.minutes : 0
        }m`,
      ]),
    ]);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");

    const filename = "clockwork.xlsx";

    // Export the workbook to Excel format
    writeFile(wb, filename);
  };

  if (!props?.records?.length) {
    return;
  }

  return (
    <div>
      <div
        className="export-file px-4 py-2 rounded-md bg-white hover:bg-gray-200 border border-gray-300 cursor-pointer flex items-center text-sm"
        onClick={() => exportToExcelClockworkRecords()}
      >
        <img src="/images/icons/export.svg" alt="export" className="mr-2" />
        Export
      </div>
    </div>
  );
};

export default DownloadWorkLog;
