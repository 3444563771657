import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import { AppContext } from "../../App";
import { initialDomainUrl } from "./commonHelpers";
import Select from "react-select";
import useCommunityDetails from "../../hooks/useCommunityDetails";
import Loading from "../loaders/Loading";

function getCommunityOptions(currUser) {
  return currUser?.user?.map((user) => ({
    label: user?.name,
    value: user?.community_id,
  }));
}

const TopNav = () => {
  const {
    currUser,
    getUserInfo,
    currCommunityId,
    navToggle,
    setNavToggle,
    setInviteModal,
    inviteModal,
    gettingUserInfo,
  } = useContext(AppContext);

  const { getCommunityDetails, loading } = useCommunityDetails();

  const [open, setOpen] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const btnRef = useRef();
  const location = useNavigate();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const logOut = (e) => {
    e.preventDefault();
    setProcessing(true);
    api
      .post("/api/auth/logout")
      .then((response) => {
        document.cookie = "chatSocketID-admin=";
        localStorage.removeItem("currCommunity");
        // location("/signin");
        window.location.href = initialDomainUrl + "/signin";
      })
      .catch((error) => {});
  };

  const updateUserStatus = (e, status) => {
    e.preventDefault();
    const payload = {
      communityId: currCommunityId,
      status: status,
    };
    setProcessing(true);
    api
      .post("/api/logUserStatus", payload)
      .then((response) => {
        if (response.data.status == "success") {
          getUserInfo();
        }
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  const changeCommunityId = (community_id) => {
    localStorage.setItem("currCommunity", community_id);

    getCommunityDetails({
      community_id,
    });
  };

  const communityOptions = getCommunityOptions(currUser) || [];

  const selectedCommunity = communityOptions?.find(
    (c) => c.value === currCommunityId
  );

  return (
    <div className="top-nav py-4 px-[1.6rem] fixed w-full bg-white z-[999] shadow-md">
      <div className="flex space-x-4">
        <div className="flex items-center space-x-2">
          <img
            src="/images/icons/toggle.svg"
            alt="toggle-sidebar"
            className="cursor-pointer"
            onClick={() => setNavToggle(!navToggle)}
          />

          <img src="/images/dckap-desk-logo.svg" alt="RhinoAssist" />
        </div>

        {/* <div className="toggle" onClick={() => setNavToggle(!navToggle)}>
          <span>
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              ></path>
            </svg>
          </span>
        </div> */}
      </div>

      <div className="nav-list profile">
        <ul className="flex items-center justify-center gap-8">
          <li
            className="nav-item text-sm space-x-3 flex items-center font-medium px-4 h-11 rounded-md border border-gray-300 cursor-pointer"
            onClick={() => setInviteModal(!inviteModal)}
          >
            <i className="fa-solid fa-user-plus"></i>
            <div>Invite</div>
          </li>

          <li className="nav-item text-2xl cursor-pointer flex items-center">
            {loading ? (
              <Loading />
            ) : (
              <Select
                className="w-full capitalize chat-select input-md text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600 border border-gray-300 rounded-md min-w-[200px]"
                value={selectedCommunity}
                options={getCommunityOptions(currUser)}
                onChange={(selectedOption) =>
                  changeCommunityId(selectedOption.value)
                }
                styles={{
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#1C1B1F",
                  }),
                }}
              />
            )}
          </li>

          <li className="nav-item text-2xl cursor-pointer flex items-center">
            {processing || gettingUserInfo ? (
              <Loading />
            ) : (
              <div className="flex items-center space-x-2">
                <div className="text-sm font-medium text-black">
                  {currUser.userStatus?.status == 1 ? "Online" : "Offline"}
                </div>

                <div>
                  {currUser.userStatus?.status == 1 ? (
                    <img
                      src="/images/icons/toggle-on.svg"
                      alt="online"
                      onClick={(e) => updateUserStatus(e, "0")}
                      className="cursor-pointer"
                    />
                  ) : (
                    <img
                      src="/images/icons/toggle-off.svg"
                      alt="offline"
                      onClick={(e) => updateUserStatus(e, "1")}
                      className="cursor-pointer"
                    />
                  )}
                </div>
              </div>
            )}
          </li>
          <li className="nav-item relative">
            <div className="dropdown" ref={btnRef}>
              <div
                className="dropdown-toggle"
                id="dropdown-toggle-873-eVCGyHdcfE"
              >
                <button className="relative" onClick={() => setOpen(!open)}>
                  {currUser?.image ? (
                    <img
                      src={currUser.image}
                      className={`w-[25px] h-[25px] rounded-full`}
                      alt="profile"
                      style={{
                        border: "1px solid #C4D1DD",
                      }}
                    />
                  ) : (
                    <i className="fa-solid fa-user fa-user-custom"></i>
                  )}

                  <div
                    className={`absolute right-0 bottom-0 w-3 h-3 rounded-full border ${
                      currUser.userStatus?.status == 1
                        ? "bg-green-600 border-white"
                        : "bg-white border-gray-400"
                    }`}
                  ></div>
                </button>
              </div>
              <ul
                id=""
                className={`mt-3 mr-5 dropdown-menu bottom-end ${
                  open ? "block" : "hidden"
                }`}
                style={{
                  opacity: 1,
                  visibility: "visible",
                  transform: "rotateX(0deg)",
                }}
              >
                <Link to="/settings/profile">
                  <li
                    className="menu-item menu-item-dark menu-item-hoverable text-sm hover:bg-gray-50"
                    style={{ height: "35px" }}
                  >
                    <span className="text-base">
                      <i className="fa-solid fa-user"></i>
                    </span>
                    <span className="ml-2 rtl:mr-2">My Profile</span>
                  </li>
                </Link>

                <li
                  className="menu-item menu-item-dark menu-item-hoverable text-red-500 hover:text-white hover:bg-red-500 text-sm"
                  style={{ height: "35px" }}
                  onClick={logOut}
                >
                  <span className="text-base">
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </span>
                  <span className="ml-2 rtl:mr-2">Logout</span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <Invite />
    </div>
  );
};

export default TopNav;

function Invite() {
  const [inviteEmailId, setInviteEmailId] = useState("");
  const [inviteEmailIdError, setInviteEmailIdError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [logo, setLogo] = useState(false);
  const [inviteUrl, setInviteUrl] = useState("");
  const [LeaveModal, setLeaveModal] = useState(false);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    setShowCommunityModal,
    csrfToken,
    currCommunityId,
    setCurrCommunityId,
    setInviteModal,
    inviteModal,
  } = useContext(AppContext);

  const mailSend = (e) => {
    e.preventDefault();
    if (!inviteEmailId) {
      setInviteEmailIdError("This Field is required");
      return;
    }
    var payload = {
      community_id: currCommunityId,
      email: inviteEmailId,
    };
    setProcessing(true);
    api
      .post("/api/inviteToCommunity", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Mail send Successfully",
          });
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  useEffect(() => {
    setInviteUrl(`${window.location.origin}/join-community/` + currCommunityId);
  }, [currCommunityId]);

  const validation = (name, value, error = null) => {
    if (name === "email") {
      if (error != null) {
        setInviteEmailIdError("This Field is required");
      } else {
        if (value !== "") {
          setInviteEmailId(value);
          setInviteEmailIdError("");
        } else {
          setInviteEmailId("");
          setInviteEmailIdError("This field is required");
        }
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.name, e.target.value);
    validation(e.target.name, e.target.value);
  };

  return (
    <>
      {inviteModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-[999] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="flex items-start justify-between p-4 rounded-t">
                <h3 className="text-xl font-semibold text-gray-900  ">
                  Add Peoples to {currUser.currCommunity.name}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                  data-modal-hide="defaultModal"
                  onClick={() => setInviteModal(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form action="#" onSubmit={(e) => mailSend(e)}>
                <div className="px-6">
                  <div className="mt-4">
                    <div className="form-container vertical">
                      <div className="form-item vertical mb-3">
                        <div className>
                          <input
                            className={`${
                              inviteEmailIdError !== "" ? "input-danger" : ""
                            } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                            type="email"
                            name="email"
                            autoComplete="off"
                            placeholder="Enter Email"
                            value={inviteEmailId}
                            onChange={(e) => handleChange(e)}
                          />
                          {inviteEmailIdError !== "" ? (
                            <div className="form-error">
                              {inviteEmailIdError}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center px-6 pb-3 space-x-2  border-gray-200 rounded-b">
                  <button
                    className="inline-flex items-center justify-center button grat-btn grat-btn  radius-round h-11 px-8 py-2 w-full"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}{" "}
                    Send
                  </button>
                </div>
              </form>
              <div className="px-6">
                <span className="flex justify-center mb-3">Or</span>
                <div className="">
                  <div className="form-container vertical">
                    <p className="">
                      <span className="mb-3 block">
                        Send this link to people to join this community
                      </span>
                      <label
                        htmlFor="email"
                        className="relative text-gray-400 focus-within:text-gray-600 block"
                      >
                        <input
                          type="text"
                          name="text"
                          placeholder=""
                          value={inviteUrl}
                          className="mb-4 pr-11 pt-2 pb-2 pl-2 input form-input w-full border"
                        />
                        <button
                          type="button"
                          data-copy-state="copy"
                          className="copyInput flex items-center text-xs font-medium text-gray-600 bg-gray-100 border-l border-gray-200   hover:text-blue-700   copy-to-clipboard-button"
                          onClick={() => {
                            navigator.clipboard.writeText(inviteUrl);
                            setShowToast(true);
                            setToastMessage({
                              type: "success",
                              message: "Copied link to clipboard",
                            });
                          }}
                        >
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                            />
                          </svg>
                        </button>
                      </label>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
