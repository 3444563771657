function ToggleBtn({ checked, setChecked, btnSize = "1.8rem" }) {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        setChecked(!checked);
      }}
      type="button"
    >
      {checked ? (
        <i
          className={`fa-solid fa-toggle-on text-green-500 text-[1.8rem]`}
          style={{ fontSize: btnSize }}
        ></i>
      ) : (
        <i
          className={`fa-solid fa-toggle-off text-[1.8rem]`}
          style={{ fontSize: btnSize }}
        ></i>
      )}
    </button>
  );
}

export default ToggleBtn;
