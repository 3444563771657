import { useCallback, useContext, useMemo } from "react";
import api from "../../../../api/api";
import { AppContext } from "../../../../App";

function useBuildBotFlow(botId, flowId, childFlowId) {
  const { currCommunityId } = useContext(AppContext);

  const basePath = useMemo(() => {
    return `/api/${currCommunityId}/${botId}/flow/${flowId}`;
  }, [botId, currCommunityId, flowId]);

  const getBotFlows = useCallback(() => {
    const url = basePath + `/getBotFlowSetup/${childFlowId}`;

    return () => api.get(url);
  }, [basePath, childFlowId]);

  const createBotFlow = useCallback(
    (payload) => {
      const url = basePath + `/botFlowSetup/${childFlowId}`;

      return () => api.post(url, payload);
    },
    [basePath, childFlowId]
  );

  const deleteBotFlow = useCallback(
    (deleteId) => {
      const url = basePath + `/deleteDailog/${childFlowId}`;

      return () =>
        api.delete(url, {
          data: {
            id: deleteId,
          },
        });
    },
    [basePath, childFlowId]
  );

  const getDialogs = useCallback(
    (childFlowId) => {
      const url = basePath + `/getDialogues/${childFlowId}`;

      return () => api.get(url);
    },
    [basePath]
  );

  return {
    getBotFlows,
    createBotFlow,
    deleteBotFlow,
    getDialogs,
  };
}

export default useBuildBotFlow;
