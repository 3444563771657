import {
  formatDateWithTime,
  isQuillHavingText,
} from "../../common/commonHelpers";

function TemplateCard({
  template,
  isActive,
  isEditable,
  onSelect,
  onEdit,
  onDelete,
}) {
  const { subject, header_content, content, footer_content, updated_at } =
    template;

  return (
    <div
      className={`rounded-md border border-gray-300 hover:shadow-md p-4 w-[300px] relative cursor-pointer flex flex-col justify-between ${
        isActive ? "border-indigo-600" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();

        if (onSelect) {
          onSelect();
        }
      }}
    >
      {isActive ? (
        <i className="fa-solid fa-circle-check text-indigo-600 absolute top-2 right-2"></i>
      ) : null}

      <div>
        <div>
          <span className="text-gray-500">Subject:</span> {subject}
        </div>

        <div className={`mt-4 flex flex-col space-y-2`}>
          <DisplayQuill text={header_content} />

          <DisplayQuill text={content} />

          <DisplayQuill text={footer_content} />
        </div>
      </div>

      {isEditable ? (
        <div className="flex justify-between items-center space-x-4 mt-6">
          <div className="text-sm text-gray-500">
            {formatDateWithTime(updated_at)}
          </div>

          <div>
            <i
              className="fa-solid fa-pen-to-square hover:text-blue-600 cursor-pointer mr-4"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            ></i>
            <i
              className="fa-solid fa-trash hover:text-red-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            ></i>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default TemplateCard;

function DisplayQuill({ text }) {
  if (isQuillHavingText(text)) {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></p>
    );
  }
}
