import { useContext, useEffect, useState } from "react";
import Modal from "../common/Modal";
import SlaSettings from "../settings/SlaSettings";
import { AppContext } from "../../App";
import api from "../../api/api";
import Spinner from "../loaders/Spinner";

function SLAModal({ modal, setModal }) {
  const {
    currUser,
    currCommunityId,
    setShowToast,
    setToastMessage,
    getUserInfo,
  } = useContext(AppContext);

  const [processing, setProcessing] = useState(false);
  const [priorityForm, setPriorityForm] = useState({
    p1: {
      days: "",
      hour: "",
    },
    p2: {
      days: "",
      hour: "",
    },
    p3: {
      days: "",
      hour: "",
    },
    p4: {
      days: "",
      hour: "",
    },
    fdr: "",
  });

  const storeCommunityDetails = (response) => {
    const p1TimeSplit = response.data.data?.p1_time?.split("/");
    const p2TimeSplit = response.data.data?.p2_time?.split("/");
    const p3TimeSplit = response.data.data?.p3_time?.split("/");
    const p4TimeSplit = response.data.data?.p4_time?.split("/");
    // console.log(p2TimeSplit);
    setPriorityForm({
      p1: {
        days: p1TimeSplit ? p1TimeSplit[0] : "",
        hour: p1TimeSplit ? p1TimeSplit[1] : "",
      },
      p2: {
        days: p2TimeSplit ? p2TimeSplit[0] : "",
        hour: p2TimeSplit ? p2TimeSplit[1] : "",
      },
      p3: {
        days: p3TimeSplit ? p3TimeSplit[0] : "",
        hour: p3TimeSplit ? p3TimeSplit[1] : "",
      },
      p4: {
        days: p4TimeSplit ? p4TimeSplit[0] : "",
        hour: p4TimeSplit ? p4TimeSplit[1] : "",
      },
      fdr: response.data.data.fdr,
    });
  };

  const formSubmit = (e, type) => {
    e.preventDefault();

    var payload = {
      community_id: currCommunityId,
      name: currUser?.currCommunity?.name,
      p1: `${priorityForm.p1.days}/${priorityForm.p1.hour}`,
      p2: `${priorityForm.p2.days}/${priorityForm.p2.hour}`,
      p3: `${priorityForm.p3.days}/${priorityForm.p3.hour}`,
      p4: `${priorityForm.p4.days}/${priorityForm.p4.hour}`,
      fdr: priorityForm.fdr,
    };

    setProcessing(true);
    api
      .post("/api/updateCommunityDetails", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status === "success") {
          //   setHasChanged(false);
          storeCommunityDetails(response);
          setShowToast(true);
          //   setOpenDetailsForm(false);
          setToastMessage({
            type: "success",
            message: "Data updated Successfully",
          });
          getUserInfo();
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  useEffect(() => {
    // console.log(rows);
    getUserInfo();
    var payload = {
      selectedCommunity: currCommunityId,
    };

    setProcessing(true);

    api
      .post("/api/selectedCommunity", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          storeCommunityDetails(response);
        }
      })
      .catch((error) => {
        if (error) {
          setToastMessage({
            type: "error",
            message: error?.response?.data?.message || "Something Went Wrong",
          });
        }
      });
  }, []);

  return (
    <div>
      <Modal
        isOpen={modal}
        onClose={() => setModal("")}
        title={"SLA Settings"}
        customCls="md:!max-w-[1100px]"
      >
        <div className="py-6">
          {processing ? (
            <Spinner />
          ) : (
            <SlaSettings
              handleTabClick={() => {}}
              openTabs={[1]}
              currUser={currUser}
              communityId={currCommunityId}
              formSubmit={formSubmit}
              priorityForm={priorityForm}
              handlePriorityForm={setPriorityForm}
              hideTitle={true}
              processing={processing}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default SLAModal;
