import { useEffect, useRef, useState } from "react";
import Loading from "../loaders/Loading";

const SchedulerCard = ({ data, onRemove, isRemoveLoading, onEdit }) => {
  const [open, setOpen] = useState(false);
  const btnRef = useRef();
  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);
  return (
    <div
      className="hover:bg-gray-100 w-full rounded-b-md px-3 py-2 text-gray-700 text-md bg-white flex items-center justify-between mt-2"
      rel="noreferrer"
    >
      <div className="flex">
        <div className="user-profile">S</div>
        <div className="detailsUser">
          <div className="capitalize userName">
            {data.type ? data.type : "No type"}
          </div>
          <div className="others text-sm">{data.day ? data.day : "No day"}</div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="dropdown flex relative" ref={btnRef}>
          <div className="dropdown-toggle" id="dropdown-toggle-873-eVCGyHdcfE">
            <button
              className="button bg-transparent border border-transparent hover:bg-gray-50 active:bg-gray-100 text-gray-600 radius-circle h-7 w-7 inline-flex items-center justify-center text-base"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm5 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
              </svg>
            </button>
          </div>
          <ul
            id="base-menu-124-eIThs96oYp"
            className={`dropdown-menu bottom-end ${
              open ? "block" : "hidden"
            } right-[0%] min-w-[100px] top-[20px]`}
            style={{
              opacity: 1,
              visibility: "visible",
              transform: "rotateX(0deg)",
            }}
          >
            <li
              className="menu-item menu-item-dark menu-item-hoverable text-red-500 hover:text-white hover:bg-red-500"
              style={{ height: "35px" }}
              onClick={(e) => {
                e.preventDefault();
                onRemove(data.id);
              }}
            >
              {isRemoveLoading && <Loading />}
              <span className="text-base">
                <i className="fa-regular fa-trash-can"></i>
              </span>
              <span className="ml-2 rtl:mr-2">Remove</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SchedulerCard;
