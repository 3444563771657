import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../loaders/Loading";

const TeamCard = ({
  team,
  onEdit,
  onAddMembers,
  onDelete,
  onSetPrimaryTeam,
  isPrimaryTeamLoading,
  isDelete,
}) => {
  const [open, setOpen] = useState(false);
  const btnRef = useRef();
  const [gptCheck, setGptCheck] = useState();
  const location = useNavigate();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);
  function Avatar({ name }) {
    const colors = [
      "red",
      "blue",
      "green",
      "yellow",
      "indigo",
      "purple",
      "pink",
    ];
    const color = colors[Math.floor(Math.random() * colors.length)];
    const initials = name
      ?.split(" ")
      ?.map((word) => word[0])
      ?.join("");

    return (
      <div
        className={`bg-gray-100 assignMember cursor-pointer hover:shadow-md duration-300 ease font-medium`}
      >
        {initials}
      </div>
    );
  }
  return (
    <div>
      <div className="bg-white border border-gray-300 p-6 rounded-md">
        <div className="h-full">
          <div className="flex flex-col justify-between h-full">
            <div className="">
              <div className="flex justify-between">
                <h6 className="card-title">{team.name}</h6>

                {team.active == 1 && (
                  <div className="bg-green-600 text-white px-3 py-0.5 text-[12px] rounded-full font-medium uppercase flex justify-center items-center">
                    Primary
                  </div>
                )}
              </div>
              <p className="mt-4 chatDescription">Members</p>
              <div className="mt-1 flex justify-between items-center membersParentDiv">
                <div className="flex space-x-1">
                  {team.users?.slice(0, 5).map((user, key) => (
                    <div className="">
                      {user?.image ? (
                        <img
                          src={user.image}
                          className={`rounded-full w-[25px] h-[25px]`}
                          alt="user-profile"
                        />
                      ) : (
                        <Avatar key={key} name={user.username || user.email} />
                      )}
                    </div>
                  ))}

                  <div
                    className="relative border border-gray-300 rounded-full w-7 h-7 flex justify-center items-center hover:bg-gray-100"
                    onClick={() => onAddMembers()}
                  >
                    <i className="cursor-pointer hover:shadow-md duration-300 ease fa-solid fa-plus text-indigo-600 text-sm"></i>
                  </div>
                </div>

                <div className="dropdown flex relative" ref={btnRef}>
                  <div
                    className="dropdown-toggle"
                    id="dropdown-toggle-873-eVCGyHdcfE"
                  >
                    <button
                      className="button bg-transparent border border-transparent hover:bg-gray-50 active:bg-gray-100 text-gray-600 radius-circle h-7 w-7 inline-flex items-center justify-center text-base"
                      onClick={(e) => {
                        setOpen(!open);
                      }}
                    >
                      <img src="/images/icons/more.svg" alt="more" />
                    </button>
                  </div>
                  <ul
                    id="base-menu-124-eIThs96oYp"
                    className={`dropdown-menu bottom-end ${
                      open ? "block" : "hidden"
                    } left-[30%] min-w-[200px] top-8`}
                    style={{
                      opacity: 1,
                      visibility: "visible",
                      transform: "rotateX(0deg)",
                    }}
                  >
                    <div
                      className={`menu-item menu-item-dark menu-item-hoverable ${
                        team.active == 1
                          ? "text-green-500 cursor-default"
                          : "hover:bg-gray-50"
                      }`}
                      style={{ height: "35px" }}
                      onClick={() => {
                        team.active !== 1 && onSetPrimaryTeam(team.team_id);
                      }}
                    >
                      <span className="text-base">
                        <i class="fa-regular fa-circle-check"></i>
                      </span>
                      <span className="ml-2 rtl:mr-2">
                        {team.active == 1
                          ? "Setted as Primary"
                          : "Set as primary"}
                      </span>
                    </div>
                    <div
                      className="menu-item menu-item-dark menu-item-hoverable hover:bg-gray-50"
                      style={{ height: "35px" }}
                      onClick={() => onEdit()}
                    >
                      <span className="text-base">
                        <i className="fa-regular fa-pen-to-square"></i>
                      </span>
                      <span className="ml-2 rtl:mr-2">Edit</span>
                    </div>
                    <li
                      className="menu-item menu-item-dark menu-item-hoverable text-red-500 hover:text-white hover:bg-red-500"
                      style={{ height: "35px" }}
                      onClick={() => onDelete()}
                    >
                      <span className="text-base">
                        <i className="fa-regular fa-trash-can"></i>
                      </span>
                      <span className="ml-2 rtl:mr-2">Delete</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
