import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import Select from "react-select";

const firstDayOfWeek = moment().startOf("week");
const lastDayOfWeek = moment().endOf("week");
const firstday = new Date(firstDayOfWeek.format("YYYY-MM-DD"));
const lastday = new Date(lastDayOfWeek.format("YYYY-MM-DD"));

function FilterUsers({
  customFields,
  dateRange,
  setDateRange,
  customFieldForm,
  setCustomFieldForm,
}) {
  const [dateModal, setDateModal] = useState(false);
  const [dateInputRange, setInputDateRange] = useState([
    {
      startDate: firstday,
      endDate: lastday,
      key: "selection",
    },
  ]);

  // const [customFieldForm, setCustomFieldForm] = useState([]);

  // console.log("customFieldForm", customFieldForm);

  const handleCustomFieldChange = (value, item) => {
    const newValue =
      item.field_type === "multiselect" ? value.map((v) => v) : value;

    const index = customFieldForm.findIndex(
      (field) => field.customfield_id === item.id
    );

    const updatedCustomFieldForm = [...customFieldForm];

    if (index !== -1) {
      updatedCustomFieldForm[index] = {
        ...updatedCustomFieldForm[index],
        customfield_values: newValue,
      };
    } else {
      updatedCustomFieldForm.push({
        customfield_id: item.id,
        customfield_values: newValue,
      });
    }

    setCustomFieldForm(updatedCustomFieldForm);
  };

  return (
    <div>
      <div className="font-semibold border-b text-gray-700 my-3 pb-1 mt-6 text-gray-500">
        Filter by date
      </div>

      <div className="flex flex-col">
        <div className="flex items-center">
          <div
            className="calendar-days py-1 px-3 bg-gray-100 text-gray-600 hover:bg-gray-200 cursor-pointer rounded"
            onClick={() => setDateModal(!dateModal)}
          >
            <i className="fa-regular fa-calendar-days mr-2"></i>
            {dateRange ? (
              <>
                {new Date(dateRange[0].startDate).toLocaleDateString()} -{" "}
                {new Date(dateRange[0].endDate).toLocaleDateString()}
              </>
            ) : (
              "Choose date"
            )}
          </div>

          {dateRange ? (
            <button
              onClick={() => {
                setInputDateRange([
                  {
                    startDate: firstday,
                    endDate: lastday,
                    key: "selection",
                  },
                ]);

                setDateRange(null);
              }}
              className="ml-2 text-blue-600"
            >
              Clear
            </button>
          ) : null}
        </div>

        {dateModal && (
          <div className="bg-white mt-4 shadow-lg border rounded flex flex-col">
            <DateRangePicker
              onChange={(item) => setInputDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              // months={2}
              ranges={dateInputRange}
              direction="horizontal"
            />

            <div className="flex justify-end">
              <button
                className="bg-blue-500 px-3 text-white float-right mb-2 mr-2 py-1 rounded apply-btn w-fit"
                onClick={() => {
                  setDateModal(false);
                  setDateRange(dateInputRange);
                }}
              >
                Apply
              </button>

              <button
                className="border border-blue-600 px-3 text-blue-600 float-right mb-2 mr-2 py-1 rounded w-fit"
                onClick={() => {
                  setDateModal(false);

                  if (dateRange) {
                    setInputDateRange(dateRange);
                  }
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>

      <>
        <div className="font-semibold border-b text-gray-700 my-3 pb-1 mt-6 text-gray-500">
          Custom Fields
        </div>

        <div>
          {customFields?.data?.map((item) => (
            <div key={item.id} className="custom-field mt-4">
              <label className="form-label mb-2">{item.field_name}</label>
              {item.field_type === "input" && (
                <input
                  type="text"
                  placeholder={item.field_name}
                  className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                  value={
                    customFieldForm?.find(
                      (field) => field.customfield_id === item.id
                    )?.customfield_values || ""
                  }
                  onChange={(e) =>
                    handleCustomFieldChange(e.target.value, item)
                  }
                />
              )}
              {item.field_type === "select" && (
                <Select
                  options={Object.entries(JSON.parse(item.field_values)).map(
                    ([key, value]) => ({
                      value,
                      label: value,
                    })
                  )}
                  value={
                    customFieldForm?.find(
                      (field) => field.customfield_id === item.id
                    )?.customfield_values && {
                      value: customFieldForm.find(
                        (field) => field.customfield_id === item.id
                      ).customfield_values,
                      label: customFieldForm.find(
                        (field) => field.customfield_id === item.id
                      ).customfield_values,
                    }
                  }
                  onChange={(selectedOption) =>
                    handleCustomFieldChange(
                      selectedOption ? selectedOption.value : null,
                      item
                    )
                  }
                />
              )}
              {item.field_type === "multiselect" && (
                <Select
                  options={Object.entries(JSON.parse(item.field_values)).map(
                    ([key, value]) => ({
                      value,
                      label: value,
                    })
                  )}
                  value={customFieldForm
                    ?.filter((field) => field.customfield_id === item.id)
                    .flatMap((field) =>
                      field.customfield_values.map((val) => ({
                        value: val,
                        label: val.toString(),
                      }))
                    )}
                  isMulti
                  onChange={(selectedOptions) =>
                    handleCustomFieldChange(
                      selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [],
                      item
                    )
                  }
                />
              )}
            </div>
          ))}
        </div>
      </>
    </div>
  );
}

export default FilterUsers;
