import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import ChatsData from "../../components/dashboard/ChatsData";
import TicketsData from "../../components/dashboard/TicketData";
import MembersStatus from "../../components/dashboard/MembersStatus";
import MyTickets from "../../components/dashboard/MyTickets";
import TicketLevelData from "../../components/dashboard/TicketLevelData";
import Spinner from "../../components/loaders/Spinner";
import useApiData from "../../hooks/useApiData";
import ChatsAnalytics from "../../components/dashboard/ChatsAnalytics";
import MemberChatAnalytics from "../../components/dashboard/MemberChatAnalytics";
import TicketsAnalytics from "../../components/dashboard/TicketsAnalytics";
import MemberTicketAnalytics from "../../components/dashboard/MemberTicketAnalytics";
import OtherAnalytics from "../../components/dashboard/OtherAnalytics";
import moment from "moment";
import {
  formatRangeDateForPayload,
  getDefaultRangeDates,
} from "../../components/common/commonHelpers";
import DashboardCard from "../../components/dashboard/DashboardCard";
import useTeam from "../../components/settings/team/useTeam";

function useDashboard() {
  const { currCommunityId } = useContext(AppContext);

  const getAnalytics = useCallback(
    (payload) => {
      return () => api.post(`/api/analytics/${currCommunityId}`, payload);
    },
    [currCommunityId]
  );

  const getChatsAnalytics = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/analytics/${currCommunityId}`, {
          ...payload,
          type: "chats",
        });
    },
    [currCommunityId]
  );

  const getMemberChatsAnalytics = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/analytics/${currCommunityId}`, {
          ...payload,
          type: "member-wise-chats",
        });
    },
    [currCommunityId]
  );

  const getTicketsAnalytics = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/analytics/${currCommunityId}`, {
          ...payload,
          type: "tickets",
        });
    },
    [currCommunityId]
  );

  const getMemberTicketsAnalytics = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/analytics/${currCommunityId}`, {
          ...payload,
          type: "member-wise-tickets",
        });
    },
    [currCommunityId]
  );

  const getAgentProductivityAnalytics = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/analytics/${currCommunityId}`, {
          ...payload,
          type: "agent-productivity",
        });
    },
    [currCommunityId]
  );

  const getMyTickets = useCallback(
    (payload) => {
      return () =>
        api.post(`/api/analytics/${currCommunityId}`, {
          ...payload,
          type: "my-tickets",
        });
    },
    [currCommunityId]
  );

  return {
    getAnalytics,
    getChatsAnalytics,
    getMemberChatsAnalytics,
    getTicketsAnalytics,
    getMemberTicketsAnalytics,
    getAgentProductivityAnalytics,
    getMyTickets,
  };
}

const firstDayOfWeek = moment().startOf("week");
const lastDayOfWeek = moment().endOf("week");
const firstday = new Date(firstDayOfWeek.format("YYYY-MM-DD"));
const lastday = new Date(lastDayOfWeek.format("YYYY-MM-DD"));

function getDefaultRange() {
  return [
    {
      ...getDefaultRangeDates(),
      key: "selection",
    },
  ];
}

const Dashboard = () => {
  const { currUser, getUserInfo, currCommunityId, onError } =
    useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [chatData, setChatData] = useState(null);
  // const [ticketData, setTicketData] = useState(null);
  const [members, setMembers] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [priority, setPriority] = useState(null);

  const [popup, setPopup] = useState({
    open: false,
    comp: null,
    dimensions: {},
  });

  const [analytics, setAnalytics] = useState({});

  console.log("analytics", analytics);

  const [analyticsDateRange, setAnalyticsDateRange] = useState({
    chats: getDefaultRange(),
    memberChats: getDefaultRange(),
    tickets: getDefaultRange(),
    memberTickets: getDefaultRange(),
    agentProductivity: getDefaultRange(),
    myTickets: getDefaultRange(),
  });

  const [analyticsTeams, setAnalyticsTeams] = useState({
    chats: "",
    memberChats: "",
    tickets: "",
    memberTickets: "",
    agentProductivity: "", // we are not using a (filter by team) for member/agent productivity
  });

  const [analyticsTabView, setAnalyticsTabView] = useState({
    tickets: "chart",
  });

  const [analyticsFilter, setAnalyticsFilter] = useState({
    tickets: {},
    memberTickets: {},
    agentProductivity: {},
  });

  const { getTeams } = useTeam();

  const { data: teamsData, mutate: getTeamsList } = useApiData({
    onError: () => onError("Something went wrong while getting Teams"),
  });

  const {
    getAnalytics,
    getChatsAnalytics,
    getMemberChatsAnalytics,
    getTicketsAnalytics,
    getMemberTicketsAnalytics,
    getAgentProductivityAnalytics,
    getMyTickets,
  } = useDashboard();

  const { loading, mutate } = useApiData({
    onSuccess: (res) => {
      setAnalytics(res.data?.data);
    },
    onError: (error) => {
      onError(error?.response?.data?.message || "Unable to get analytics");
    },
  });

  function getAllData(payload) {
    mutate(getAnalytics(payload));
  }

  const { loading: isChatLoading, mutate: chatMutate } = useApiData({
    onSuccess: (res) => {
      setAnalytics((prev) => ({ ...prev, ...res.data?.data }));
    },
    onError: (error) => {
      onError(error?.response?.data?.message || "Unable to get chat analytics");
    },
  });

  function getChatsData(newPayload) {
    const { startDate, endDate } = analyticsDateRange?.chats[0];

    const payload = {
      fromDate: formatRangeDateForPayload(startDate),
      toDate: formatRangeDateForPayload(endDate),
      team_id: analyticsTeams?.chats,
    };

    chatMutate(
      getChatsAnalytics({
        ...payload,
        ...newPayload,
      })
    );
  }

  const { loading: isMemberChatLoading, mutate: memberChatMutate } = useApiData(
    {
      onSuccess: (res) => {
        setAnalytics((prev) => ({ ...prev, ...res.data?.data }));
      },
      onError: (error) => {
        onError(
          error?.response?.data?.message ||
            "Unable to get member chat analytics"
        );
      },
    }
  );

  function getMemberChatsData(newPayload) {
    const { startDate, endDate } = analyticsDateRange?.memberChats[0];

    const payload = {
      fromDate: formatRangeDateForPayload(startDate),
      toDate: formatRangeDateForPayload(endDate),
      team_id: analyticsTeams?.memberChats,
    };

    memberChatMutate(
      getMemberChatsAnalytics({
        ...payload,
        ...newPayload,
      })
    );
  }

  const { loading: isTicketsLoading, mutate: ticketsMutate } = useApiData({
    onSuccess: (res) => {
      setAnalytics((prev) => ({ ...prev, ...res.data?.data }));
    },
    onError: (error) => {
      onError(
        error?.response?.data?.message || "Unable to get tickets analytics"
      );
    },
  });

  function getTicketsData(newPayload, isFitler, resetFilter) {
    const { startDate, endDate } = analyticsDateRange?.tickets[0];

    let payload = {
      fromDate: formatRangeDateForPayload(startDate),
      toDate: formatRangeDateForPayload(endDate),
      team_id: analyticsTeams?.tickets,
      queryParams: analyticsFilter?.tickets,
    };

    if (isFitler) {
      // for other filters
      payload.queryParams = { ...payload.queryParams, ...newPayload };
    } else {
      // for team and date filters
      payload = { ...payload, ...newPayload };
    }

    if (resetFilter) {
      delete payload.queryParams;
    }

    ticketsMutate(getTicketsAnalytics(payload));
  }

  const { loading: isMemberTicketsLoading, mutate: memberTicketsMutate } =
    useApiData({
      onSuccess: (res) => {
        setAnalytics((prev) => ({ ...prev, ...res.data?.data }));
      },
      onError: (error) => {
        onError(
          error?.response?.data?.message ||
            "Unable to get member tickets analytics"
        );
      },
    });

  function getMemberTicketsData(newPayload, isFitler, resetFilter) {
    const { startDate, endDate } = analyticsDateRange?.memberTickets[0];

    let payload = {
      fromDate: formatRangeDateForPayload(startDate),
      toDate: formatRangeDateForPayload(endDate),
      team_id: analyticsTeams?.memberTickets,
      queryParams: analyticsFilter?.memberTickets,
    };

    if (isFitler) {
      // for other filters
      payload.queryParams = { ...payload.queryParams, ...newPayload };

      const { customFields } = payload.queryParams;

      if (customFields) {
        payload.queryParams = {
          ...payload.queryParams,
          customFields: JSON.parse(customFields),
        };
      }
    } else {
      // for team and date filters
      payload = { ...payload, ...newPayload };
    }

    if (resetFilter) {
      delete payload.queryParams;
    }

    memberTicketsMutate(getMemberTicketsAnalytics(payload));
  }

  const {
    loading: isAgentProductivityLoading,
    mutate: agentProductivityMutate,
  } = useApiData({
    onSuccess: (res) => {
      setAnalytics((prev) => ({ ...prev, ...res.data?.data }));
    },
    onError: (error) => {
      onError(
        error?.response?.data?.message ||
          "Unable to get agent productivity analytics"
      );
    },
  });

  function getAgentProductivityData(newPayload, isFitler) {
    const { startDate, endDate } = analyticsDateRange?.agentProductivity[0];

    const payload = {
      fromDate: formatRangeDateForPayload(startDate),
      toDate: formatRangeDateForPayload(endDate),
      team_id: analyticsTeams?.agentProductivity,
      status: analyticsFilter?.memberTickets?.status,
    };

    agentProductivityMutate(
      getAgentProductivityAnalytics({
        ...payload,
        ...newPayload,
      })
    );
  }

  const { loading: isMyTicketsLoading, mutate: myTicketsMutate } = useApiData({
    onSuccess: (res) => {
      setAnalytics((prev) => ({ ...prev, ...res.data?.data }));
    },
    onError: (error) => {
      onError(error?.response?.data?.message || "Unable to get my tickets");
    },
  });

  function getMyTicketsData(newPayload) {
    const { startDate, endDate } = analyticsDateRange?.myTickets[0];

    const payload = {
      fromDate: formatRangeDateForPayload(startDate),
      toDate: formatRangeDateForPayload(endDate),
    };

    myTicketsMutate(
      getMyTickets({
        ...payload,
        ...newPayload,
      })
    );
  }

  useEffect(() => {
    getAllData({
      fromDate: new Date(firstday).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      toDate: new Date(lastday).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    });

    getTeamsList(getTeams());
  }, []);

  const teamOptions = teamsData
    ? teamsData?.map((team) => {
        return {
          label: team.name,
          value: team.team_id,
        };
      })
    : [];

  const {
    chats,
    communityMembersChats,
    ticketsData,
    communityMembersTickets,
    agentProductivity,
    myTickets,
  } = analytics || {};

  return (
    <main>
      <div className="dashboard-container mx-auto max-w-screen-2xl pt-6 pb-6">
        <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
          <h3 className="text-title-md2 font-bold text-black">
            Hello, {currUser?.username}
          </h3>
        </div>
        {processing || loading ? (
          <div className="flex items-center justify-center h-[50vh]">
            <Spinner />
          </div>
        ) : (
          <>
            <OtherAnalytics others={analytics?.ticketOthers || {}} />

            <div className="grid gap-5 grid-cols-2">
              <DashboardCard
                title="Chats"
                uniqueId="chats"
                onDateRangeApply={(dateRange) => {
                  const { startDate, endDate } = dateRange[0];

                  const payload = {
                    fromDate: formatRangeDateForPayload(startDate),
                    toDate: formatRangeDateForPayload(endDate),
                  };

                  getChatsData(payload);
                }}
                defaultDateRange={analyticsDateRange?.chats}
                setDefaultDateRange={(dateRange) => {
                  setAnalyticsDateRange((prev) => ({
                    ...prev,
                    chats: dateRange,
                  }));
                }}
                isNotFound={!chats || !Object.keys(chats)?.length}
                notFoundLabel="No chats found"
                loading={isChatLoading}
                teamOptions={teamOptions}
                selectedTeam={analyticsTeams?.chats}
                onTeamChange={(team) => {
                  setAnalyticsTeams((prev) => ({
                    ...prev,
                    chats: team,
                  }));

                  getChatsData({ team_id: team });
                }}
              >
                <ChatsAnalytics
                  chats={analytics?.chats || {}}
                  getChatsAnalytics={(payload) => {
                    chatMutate(getChatsAnalytics(payload));
                  }}
                  defaultDateRange={analyticsDateRange?.chats}
                  setDefaultDateRange={(dateRange) => {
                    setAnalyticsDateRange((prev) => ({
                      ...prev,
                      chats: dateRange,
                    }));
                  }}
                />
              </DashboardCard>

              <DashboardCard
                title="Member Chats"
                uniqueId="memberChats"
                onDateRangeApply={(dateRange) => {
                  const { startDate, endDate } = dateRange[0];

                  const payload = {
                    fromDate: formatRangeDateForPayload(startDate),
                    toDate: formatRangeDateForPayload(endDate),
                  };

                  getMemberChatsData(payload);
                }}
                defaultDateRange={analyticsDateRange?.memberChats}
                setDefaultDateRange={(dateRange) => {
                  setAnalyticsDateRange((prev) => ({
                    ...prev,
                    memberChats: dateRange,
                  }));
                }}
                isNotFound={
                  !communityMembersChats || !communityMembersChats?.length
                }
                notFoundLabel="No member chats found"
                loading={isMemberChatLoading}
                teamOptions={teamOptions}
                selectedTeam={analyticsTeams?.memberChats}
                onTeamChange={(team) => {
                  setAnalyticsTeams((prev) => ({
                    ...prev,
                    memberChats: team,
                  }));

                  getMemberChatsData({ team_id: team });
                }}
              >
                <MemberChatAnalytics
                  memberChats={communityMembersChats || []}
                  selectedDates={analyticsDateRange?.memberChats[0]}
                  popup={popup}
                  setPopup={setPopup}
                />
              </DashboardCard>

              <DashboardCard
                title="Tickets"
                uniqueId="tickets"
                onDateRangeApply={(dateRange) => {
                  const { startDate, endDate } = dateRange[0];

                  const payload = {
                    fromDate: formatRangeDateForPayload(startDate),
                    toDate: formatRangeDateForPayload(endDate),
                  };

                  getTicketsData(payload);
                }}
                defaultDateRange={analyticsDateRange?.tickets}
                setDefaultDateRange={(dateRange) => {
                  setAnalyticsDateRange((prev) => ({
                    ...prev,
                    tickets: dateRange,
                  }));
                }}
                isNotFound={!ticketsData || !Object.keys(ticketsData)?.length}
                notFoundLabel="No tickets found"
                loading={isTicketsLoading}
                teamOptions={teamOptions}
                selectedTeam={analyticsTeams?.tickets}
                onTeamChange={(team) => {
                  setAnalyticsTeams((prev) => ({
                    ...prev,
                    tickets: team,
                  }));

                  getTicketsData({ team_id: team });
                }}
                tab={analyticsTabView.tickets}
                setTab={(tab) => {
                  setAnalyticsTabView((prev) => ({ ...prev, tickets: tab }));
                }}
                filterType={
                  analyticsTabView.tickets === "table" ? "tickets" : ""
                }
                filter={analyticsFilter.tickets}
                setFilter={(filter) => {
                  setAnalyticsFilter((prev) => ({
                    ...prev,
                    tickets: { ...prev.tickets, ...filter },
                  }));

                  getTicketsData(filter, true);
                }}
                onResetFilter={() => {
                  setAnalyticsFilter((prev) => ({ ...prev, tickets: {} }));
                  getTicketsData({}, true, true);
                }}
              >
                <TicketsAnalytics
                  tickets={ticketsData || {}}
                  selectedDates={analyticsDateRange?.tickets[0]}
                  tab={analyticsTabView.tickets}
                />
              </DashboardCard>

              <DashboardCard
                title="Member Tickets"
                uniqueId="memberTickets"
                onDateRangeApply={(dateRange) => {
                  const { startDate, endDate } = dateRange[0];

                  const payload = {
                    fromDate: formatRangeDateForPayload(startDate),
                    toDate: formatRangeDateForPayload(endDate),
                  };

                  getMemberTicketsData(payload);
                }}
                defaultDateRange={analyticsDateRange?.memberTickets}
                setDefaultDateRange={(dateRange) => {
                  setAnalyticsDateRange((prev) => ({
                    ...prev,
                    memberTickets: dateRange,
                  }));
                }}
                isNotFound={
                  !communityMembersTickets || !communityMembersTickets?.length
                }
                notFoundLabel="No member tickets found"
                loading={isMemberTicketsLoading}
                teamOptions={teamOptions}
                selectedTeam={analyticsTeams?.memberTickets}
                onTeamChange={(team) => {
                  setAnalyticsTeams((prev) => ({
                    ...prev,
                    memberTickets: team,
                  }));

                  getMemberTicketsData({ team_id: team });
                }}
                tab={analyticsTabView.memberTickets}
                setTab={(tab) => {
                  setAnalyticsTabView((prev) => ({
                    ...prev,
                    memberTickets: tab,
                  }));
                }}
                filterType={"memberTickets"}
                filter={analyticsFilter.memberTickets}
                setFilter={(filter) => {
                  setAnalyticsFilter((prev) => ({
                    ...prev,
                    memberTickets: { ...prev.memberTickets, ...filter },
                  }));

                  getMemberTicketsData(filter, true);
                }}
                onResetFilter={() => {
                  setAnalyticsFilter((prev) => ({
                    ...prev,
                    memberTickets: {},
                  }));
                  getMemberTicketsData({}, true, true);
                }}
              >
                <MemberTicketAnalytics
                  memberTickets={communityMembersTickets || []}
                  selectedDates={analyticsDateRange?.memberTickets[0]}
                />
              </DashboardCard>

              <DashboardCard
                title="Members Productivity"
                uniqueId="memberProductivity"
                onDateRangeApply={(dateRange) => {
                  const { startDate, endDate } = dateRange[0];

                  const payload = {
                    fromDate: formatRangeDateForPayload(startDate),
                    toDate: formatRangeDateForPayload(endDate),
                  };

                  getAgentProductivityData(payload);
                }}
                defaultDateRange={analyticsDateRange?.agentProductivity}
                setDefaultDateRange={(dateRange) => {
                  setAnalyticsDateRange((prev) => ({
                    ...prev,
                    agentProductivity: dateRange,
                  }));
                }}
                isNotFound={
                  !agentProductivity || !Object.keys(agentProductivity)?.length
                }
                notFoundLabel="No members found"
                loading={isAgentProductivityLoading}
                teamOptions={teamOptions}
                selectedTeam={analyticsTeams?.agentProductivity}
                onTeamChange={(team) => {
                  setAnalyticsTeams((prev) => ({
                    ...prev,
                    agentProductivity: team,
                  }));

                  getAgentProductivityData({ team_id: team });
                }}
                tab={analyticsTabView.agentProductivity}
                setTab={(tab) => {
                  setAnalyticsTabView((prev) => ({
                    ...prev,
                    agentProductivity: tab,
                  }));
                }}
                filterType={"memberProductivity"}
                filter={analyticsFilter.agentProductivity}
                setFilter={(filter) => {
                  console.log("test", filter);
                  setAnalyticsFilter((prev) => ({
                    ...prev,
                    agentProductivity: { ...prev.agentProductivity, ...filter },
                  }));

                  getAgentProductivityData(filter, true);
                }}
              >
                <MembersStatus
                  agentProductivity={agentProductivity || {}}
                  selectedDates={analyticsDateRange?.agentProductivity[0]}
                />
              </DashboardCard>

              <DashboardCard
                title="My Tickets"
                uniqueId="myTickets"
                onDateRangeApply={(dateRange) => {
                  const { startDate, endDate } = dateRange[0];

                  const payload = {
                    fromDate: formatRangeDateForPayload(startDate),
                    toDate: formatRangeDateForPayload(endDate),
                  };

                  getMyTicketsData(payload);
                }}
                defaultDateRange={analyticsDateRange?.myTickets}
                setDefaultDateRange={(dateRange) => {
                  setAnalyticsDateRange((prev) => ({
                    ...prev,
                    myTickets: dateRange,
                  }));
                }}
                isNotFound={!myTickets || !myTickets?.length}
                notFoundLabel="No tickets found"
                loading={isMyTicketsLoading}
                filterType={"myTickets"}
              >
                <MyTickets processing={processing} tickets={myTickets} />
              </DashboardCard>
            </div>
          </>
        )}
        <div className="grid gap-5 grid-cols-2"></div>
      </div>
    </main>
  );
};

export default Dashboard;

function isNearWindow(right) {
  const threshold = 50; // You can adjust this threshold value

  const isNearRightEdge = right + threshold >= window.innerWidth;

  if (isNearRightEdge) {
    console.log("Element is near the right edge of the window");
  } else {
    console.log("Element is not near the right edge of the window");
  }
}
