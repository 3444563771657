function OtherAnalytics({ others }) {
  if (!Object?.keys(others)?.length) {
    return;
  }

  return (
    <div className="flex flex-wrap gap-8 mb-6">
      {Object.entries(others).map(([key, value], i) => {
        const { count } = value;
        return (
          <div
            key={i}
            className="p-4 border border-gray-300 rounded-md w-[300px] bg-white"
          >
            <div className="font-medium uppercase">{key}</div>
            <div className="font-semibold text-3xl mt-4">{count}</div>
          </div>
        );
      })}
    </div>
  );
}

export default OtherAnalytics;
