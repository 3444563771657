import Modal from "../../../components/common/Modal";
import PortalForm from "./PortalForm";

function CreatePortal({ isOpen, onClose, loading, onSubmit, error }) {
  return (
    <div className="text-gray-700">
      <Modal isOpen={isOpen} title="Create Portal" onClose={onClose}>
        <PortalForm
          onCancel={onClose}
          loading={loading}
          onSubmit={onSubmit}
          error={error}
        />
      </Modal>
    </div>
  );
}

export default CreatePortal;
