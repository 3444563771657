import { useCallback, useContext, useMemo } from "react";
import api from "../../../../../api/api";
import { AppContext } from "../../../../../App";

function useChildFlow(botId, flowId) {
  const { currCommunityId } = useContext(AppContext);

  const basePath = useMemo(() => {
    return `/api/${currCommunityId}/${botId}/flow/${flowId}`;
  }, [botId, currCommunityId, flowId]);

  const getChildFlows = useCallback(
    (payload) => {
      const url = basePath + "/botFlows";

      return () => api.post(url, payload);
    },
    [basePath]
  );

  const createChildFlow = useCallback(
    (payload) => {
      const url = basePath + "/createBotFlow";

      return () => api.post(url, payload);
    },
    [basePath]
  );

  const updateChildFlow = useCallback(
    (payload, updateId) => {
      const url = basePath + "/updateBotFlow/" + updateId;

      return () => api.put(url, payload);
    },
    [basePath]
  );

  const deleteChildFlow = useCallback(
    (deleteId) => {
      const url = basePath + "/deleteBotFlow/" + deleteId;

      return () => api.delete(url);
    },
    [basePath]
  );

  return {
    getChildFlows,
    createChildFlow,
    updateChildFlow,
    deleteChildFlow,
  };
}

export default useChildFlow;
