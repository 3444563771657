import { useContext, useState } from "react";
import Select from "react-select";
import { AppContext } from "../../App";
import Spinner from "../loaders/Spinner";

const UsersList = (props) => {
  const [activeItem, setActiveItem] = useState(null);
  const handleChange = (selectedOption) => {
    props.setUserFilterValue(selectedOption);
  };
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  return (
    <>
      {props?.isWhatsappTab ? null : (
        <div className="h-[8%] mb-2">
          <Select
            className="w-full capitalize chat-select input-md text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600 border-2 border-gray-200 rounded-md"
            value={{
              value: props.userFilter,
              label: props.userFilter,
            }}
            options={[
              { value: "all-open-chats", label: "All Open Chats" },
              { value: "unassigned", label: "Unassigned" },
              { value: "Assigned-to-me", label: "Assigned To me" },
              { value: "resolved", label: "resolved" },
              { value: "abandoned", label: "abandoned" },
            ]}
            onChange={(selectedOption) => handleChange(selectedOption.value)}
            styles={{
              dropdownIndicator: (base) => ({
                ...base,
                color: "#1C1B1F",
              }),
            }}
          />
        </div>
      )}

      <div
        className={`${
          props?.isWhatsappTab ? "h-[100%]" : "h-[92%]"
        }  overflow-auto border border-gray-300 rounded-md p-2 bg-white`}
      >
        {props.loading?.list ? (
          <div className="flex items-center justify-center h-[50vh]">
            <Spinner />
          </div>
        ) : Object.values(props.users).length > 0 ? (
          <>
            {Object.values(props.users).map((user, index) => {
              let count = 0;

              if (props.isWhatsappTab) {
                count = user.unread_count;
              } else if (user.content) {
                const data = JSON.parse(user.content);
                data?.forEach((message) => {
                  if (message.length > 0) {
                    const parsedMessage = JSON.parse(message);
                    if (parsedMessage.status === 0) {
                      count++;
                    }
                  }
                });
              }

              return (
                <div
                  key={index}
                  className={`${
                    props.user_id == user.client_id ? "bg-primary-50" : ""
                  }  hover:bg-primary-50 items-center cursor-pointer ease-in duration-200 rounded-md p-3 flex relative`}
                  onClick={() => props.handleUserId(user.client_id)}
                >
                  <div className="userImage">
                    <i className="fa-solid fa-user bg-gray-100 p-2 rounded-full mr-2 "></i>
                  </div>
                  <div className={`relative three-dots userDetails`}>
                    <span className={Number(count) === 0 ? "" : "!font-bold"}>
                      {user.client_name || "Guest -" + user.client_id}
                    </span>

                    {Number(count) > 0 && (
                      <span className="shadow-lg right-[8px] mt-1 text-white  absolute !w-[8px] !h-[8px] bg-indigo-600 rounded-full"></span>
                    )}
                  </div>
                </div>
              );
            })}

            {Object.values(props.users).length >= 10 && (
              <div
                className="bg-main text-white rounded flex items-center justify-center text-sm border p-2 m-2 cursor-pointer duration-500 ease"
                onClick={(e) => props.handleLoadMore()}
              >
                Load More
              </div>
            )}
          </>
        ) : (
          <p className="flex items-center justify-center h-[50vh]">
            No Chats Found
          </p>
        )}
      </div>
    </>
  );
};

export default UsersList;
