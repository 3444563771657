import { useState } from "react";
import Chart from "react-apexcharts";
import { isValidDate } from "../common/commonHelpers";
import moment from "moment";

function getDisplayData(tickets = {}, key) {
  const displayCounts = [];

  Object.values(tickets).forEach((value) => {
    displayCounts.push(value[key]?.count);
  });

  return displayCounts;
}

function getChartData(tickets) {
  if (!Object.keys(tickets)?.length) {
    return {};
  }

  const chartData = {
    series: [
      {
        name: "Open",
        data: getDisplayData(tickets, "open"),
      },
      {
        name: "In Progress",
        data: getDisplayData(tickets, "inprogress"),
      },
      {
        name: "Completed",
        data: getDisplayData(tickets, "completed"),
      },
      {
        name: "Hold",
        data: getDisplayData(tickets, "hold"),
      },
      {
        name: "Waiting For Approval",
        data: getDisplayData(tickets, "waiting_for_approval"),
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
      },

      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: Object.keys(tickets).filter((date) => isValidDate(date)),
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return chartData;
}

function TicketsAnalytics({
  tickets,
  selectedDates,
  tab,
  sortTicket,
  parentWidth,
}) {
  const [chartData, setChartData] = useState(getChartData(tickets));

  if (!parentWidth) {
    return;
  }

  return (
    <>
      {tab === "chart" ? (
        <Chart
          options={chartData?.options}
          series={chartData?.series}
          type="bar"
          height="400px"
          width={parentWidth}
        />
      ) : null}

      {tab === "table" ? (
        <TableView tickets={tickets} selectedDates={selectedDates} />
      ) : null}
    </>
  );
}

export default TicketsAnalytics;

function readableDate(date) {
  const originalDate = moment(date);
  const formattedDate = originalDate.format("DD MMM");

  return formattedDate;
}

function getDates(selectedDates) {
  const startDate = moment(selectedDates?.startDate);
  const endDate = moment(selectedDates?.endDate);

  const allDates = [];

  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    allDates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return allDates;
}

const status = [
  {
    label: "Open",
    key: "open",
  },
  {
    label: "In Progress",
    key: "inprogress",
  },
  {
    label: "Completed",
    key: "completed",
  },
  {
    label: "Hold",
    key: "hold",
  },
  {
    label: "Waiting For Approval",
    key: "waiting_for_approval",
  },
];

function TableView({ tickets, selectedDates }) {
  const dates = getDates(selectedDates);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full text-sm text-left">
        <thead className="uppercase bg-gray-50 issue-list-title">
          <tr>
            <th
              className={`p-3 border border-gray-300 bg-[#E3E9F3] sticky top-0 -left-1`}
            >
              Status
            </th>

            {dates.map((date, i) => {
              return (
                <th
                  key={i}
                  className={`border border-gray-300 bg-[#E3E9F3] p-3`}
                >
                  <div className="w-20">{readableDate(date)}</div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {status.map((s, i) => {
            const { key, label } = s;

            return (
              <tr key={i}>
                <td
                  className={`p-3 border border-gray-300 bg-white sticky -left-1`}
                >
                  {label}
                </td>

                {dates.map((date, j) => {
                  const ticket = tickets ? tickets[date] : null;

                  const dataTicket = ticket ? ticket[key] : null;

                  const { count, ids } = dataTicket || {};

                  const showData = dataTicket && Number(count);

                  return (
                    <td
                      key={j}
                      className={`border border-gray-300 bg-white p-3 w-32`}
                    >
                      {showData ? (
                        <a
                          href={`tickets?ticketIds=${ids}`}
                          className="text-blue-600 underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {count}
                        </a>
                      ) : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
