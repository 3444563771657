import { useState, useContext, useEffect } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import { getTodayDate } from "../../utils/common";

const Clockwork = (props) => {
  const [form, setForm] = useState({
    hours: "",
    minutes: "",
    date: "",
    hoursError: "",
    minutesError: "",
    timeError: "",
    dateError: "",
  });
  const [processing, setProcessing] = useState(false);

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setHasChanged,
  } = useContext(AppContext);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setHasChanged(true);
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setForm({
      hours: props.form.hours,
      minutes: props.form.minutes,
      date: props.form.date,
      hoursError: "",
      minutesError: "",
      timeError: "",
      dateError: "",
    });
    // console.log(props.form.date);
  }, []);

  const validations = () => {
    let isValid = true;
    if (form.hours != "") {
      if (form.hours.length > 2 || form.hours.length < 1) {
        setForm((prev) => ({
          ...prev,
          ["hoursError"]: "Invalid Hours",
        }));
        isValid = false;
      } else {
        setForm((prev) => ({
          ...prev,
          ["hoursError"]: "",
        }));
      }
    }
    if (form.minutes != "") {
      if (form.minutes?.length > 2 || form.minutes?.length < 1) {
        setForm((prev) => ({
          ...prev,
          ["minutesError"]: "Invalid Minutes",
        }));
        isValid = false;
      } else {
        setForm((prev) => ({
          ...prev,
          ["minutesError"]: "",
        }));
      }
    }

    if (form.minutes?.length === 0 && form.hours?.length === 0) {
      setForm((prev) => ({
        ...prev,
        ["timeError"]: "Please fill any one above field",
      }));
      isValid = false;
    } else {
      setForm((prev) => ({
        ...prev,
        ["timeError"]: "",
      }));
    }

    if (form.date === "") {
      setForm((prev) => ({
        ...prev,
        ["dateError"]: "Please fill this field",
      }));
      isValid = false;
    } else {
      setForm((prev) => ({
        ...prev,
        ["dateError"]: "",
      }));
    }

    return isValid;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const isValid = validations();
    setProcessing(true);
    if (isValid) {
      props.addClockWork(form, props.currClockId);
    } else {
      setProcessing(false);
    }
  };

  return (
    <div
      id="defaultModal"
      tabIndex={-1}
      className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-[150] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
    >
      <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
        <div className="relative dialog-content bg-white rounded-lg shadow">
          <div className="flex items-start justify-between px-4 py-6 pb-4 rounded-t border-b border-gray-300">
            <h3 className="text-xl font-semibold text-gray-900  ">Work Log</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
              data-modal-hide="defaultModal"
              onClick={() => {
                props.toggleClockModal(false);
                props.handleClockForm({ hours: "", minutes: "", date: "" });
                props.handleOpenDropdownId(null);
              }}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form
            action="#"
            onSubmit={(e) => handleFormSubmit(e)}
            className="mt-6"
          >
            <div className="px-6">
              <div className="mb-4 mt-1">
                <div className="mb-3 clockwork">
                  <div>
                    <div className="font-medium pb-1">Date</div>
                    <input
                      name="date"
                      className={`${
                        form.dateError !== "" ? "input-danger" : ""
                      } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                      placeholder="30m"
                      type="date"
                      value={form.date}
                      max={getTodayDate()}
                      onChange={(e) => handleChange(e)}
                    />
                    {form.dateError !== "" ? (
                      <div className="form-error">{form.dateError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mt-6 flex gap-5">
                    <div>
                      <div className="font-medium pb-1">Hours</div>
                      <input
                        className={`${
                          form.hoursError !== "" ? "input-danger" : ""
                        } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !text-black`}
                        placeholder="1"
                        type="number"
                        name="hours"
                        value={form.hours}
                        onChange={(e) => handleChange(e)}
                      />
                      {form.hoursError !== "" ? (
                        <div className="form-error">{form.hoursError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="font-medium pb-1">Minutes</div>
                      <input
                        className={`${
                          form.minutesError !== "" ? "input-danger" : ""
                        } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !text-black`}
                        placeholder="30"
                        type="number"
                        name="minutes"
                        value={form.minutes}
                        onChange={(e) => handleChange(e)}
                      />
                      {form.minutesError !== "" ? (
                        <div className="form-error">{form.minutesError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {form.timeError !== "" ? (
                    <div className="form-error">{form.timeError}</div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <span>Date</span>
                <input
                  name="date"
                  className={`${
                    form.dateError !== "" ? "input-danger" : ""
                  } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !text-black`}
                  placeholder="30m"
                  type="date"
                  value={form.date}
                  onChange={(e) => handleChange(e)}
                />
                {form.dateError !== "" ? (
                  <div className="form-error">{form.dateError}</div>
                ) : (
                  ""
                )} */}
              </div>
            </div>
            <div className="flex items-center justify-end px-6 py-6 space-x-2  border-gray-200 rounded-b">
              <button
                className="px-4 py-2 border border-gray-300 rounded-md text-indigo-600 font-medium"
                onClick={() => {
                  props.toggleClockModal(false);
                  props.handleClockForm({ hours: "", minutes: "", date: "" });
                  props.handleOpenDropdownId(null);
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className="flex px-4 py-2 bg-indigo-600 rounded-md text-white font-medium"
                type="submit"
                disabled={processing}
              >
                {processing && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Clockwork;
