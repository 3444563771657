import { useContext, useState } from "react";
import Loading from "../loaders/Loading";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import { AppContext } from "../../App";
import Modal from "../common/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const inputFields = [
  // {
  //   label: "Name:",
  //   name: "client_name",
  //   type: "text",
  //   required: true,
  // },
  // {
  //   label: "Phone Number:",
  //   name: "client_phone_no",
  //   type: "number",
  //   required: true,
  // },
  {
    label: "Name:",
    name: "client_name",
    type: "text",
    required: true,
    Comp: Input,
    props: {
      className:
        "my-1.5 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600",
    },
  },
  {
    label: "Phone Number:",
    name: "client_phone_no",
    Comp: PhoneInput,
    required: true,
    props: {
      country: "us",
      enableSearch: true,
      inputProps: {
        name: "client_phone_no",
        required: true,
        autoFocus: true,
      },
    },
  },
];

function CreateWhatsappClientForm({ getUsers }) {
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);

  const { mutate: submitMutate, loading } = useApiData({
    onSuccess: () => {
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Client Created Successfully",
      });
      setOpenForm(false);
      setFormData({});
      getUsers(2);
    },
    onError: (error) => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  function onSubmit(e) {
    e.preventDefault();

    const payload = { community_id: currCommunityId, ...formData };
    let isValid = true;
    const newFormErrors = {};

    inputFields.forEach((input) => {
      if (input.required && !formData[input.name]) {
        newFormErrors[input.name] = `${input.label} is required`;
        isValid = false;
      }
    });

    if (!isValid) {
      setFormErrors(newFormErrors);
      return;
    }
    submitMutate(() => api.post("/api/whatsappClientDetailUpsert", payload));
  }

  return (
    <div>
      <button
        className="w-6 h-6 rounded-full bg-indigo-600 text-white font-semibold flex justify-center items-center text-xl ml-3 mt-1.5"
        onClick={() => setOpenForm(!openForm)}
      >
        +
      </button>

      <Modal
        isOpen={openForm}
        onClose={() => setOpenForm(false)}
        title={"Create Whatsapp Client"}
        customCls="!overflow-visible"
      >
        <form className="text-left my-6 space-y-4" onSubmit={onSubmit}>
          {inputFields.map((input, i) => {
            const { label, name, type, required, Comp, props } = input;

            return (
              <div key={i}>
                <label className={required ? "required-input" : ""}>
                  {label}
                </label>
                <Comp
                  type={type}
                  name={name}
                  value={formData[name]}
                  onChange={(e) => {
                    const value =
                      name === "client_phone_no" ? e : e.target.value;
                    setFormData((prev) => ({ ...prev, [name]: value }));
                    setFormErrors((prev) => ({ ...prev, [name]: "" }));
                  }}
                  {...props}
                />
                {formErrors[name] && (
                  <div className="form-error">{formErrors[name]}</div>
                )}
              </div>
            );
          })}

          <button
            type="submit"
            className="flex save-btn justify-end mt-4 float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
            disabled={loading}
          >
            {loading && <Loading />}
            Create
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default CreateWhatsappClientForm;

function Input(props) {
  return <input {...props} />;
}
