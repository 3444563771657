import { getInsertFormat } from "../../../../common/commonHelpers";
import { Label } from "./FormApiConfiguration";

const inputCls =
  "w-full p-2 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600";

function TestVariables({
  remoteVariables,
  testVariables,
  setTestVariables,
  apiForm,
}) {
  const dynamicProperties = remoteVariables?.map((r) =>
    getInsertFormat(r.prefixString, r.name)
  );

  function updateTestVariables(update, key) {
    setTestVariables((prev) => {
      const keyData = prev[key] || {};
      return { ...prev, [key]: { ...keyData, ...update } };
    });
  }

  return (
    <div className="h-72 overflow-y-scroll mx-4 p-4">
      {dynamicProperties?.length ? (
        <>
          <Label
            label="Dynamic properties"
            notRequired={true}
            customCls="text-indigo-600 text-lg"
          />

          {dynamicProperties.map((dynamicProperty, index) => {
            return (
              <div>
                <Label label={dynamicProperty} notRequired={true} />

                <input
                  type="text"
                  className={inputCls}
                  key={index}
                  label={dynamicProperty}
                  onChange={(e) => {
                    updateTestVariables(
                      { [dynamicProperty]: e.target.value },
                      "dynamicProperties"
                    );
                  }}
                />
              </div>
            );
          })}
        </>
      ) : null}

      {apiForm?.length ? (
        <>
          <Label
            label="Form"
            notRequired={true}
            customCls="text-indigo-600 text-lg mt-4"
          />

          {apiForm.map((form, i) => {
            if (form?.value) {
              return null;
            }

            return (
              <FormInput
                key={i}
                label={form?.name}
                onChange={(e) => {
                  updateTestVariables({ [form?.name]: e.target.value }, "form");
                }}
                form={form || {}}
              />
            );
          })}
        </>
      ) : null}
    </div>
  );
}

export default TestVariables;

const inputComp = {
  textarea: Textarea,
  select: SelectInput,
};

function FormInput({ onChange, form }) {
  const { type } = form;

  const InputComp = inputComp[type] || Input;

  return <InputComp form={form} onChange={onChange} />;
}

function Input({ form, onChange }) {
  const { label, name, type } = form;

  return (
    <div>
      <Label label={label} notRequired={true} />

      <input name={name} type={type} className={inputCls} onChange={onChange} />
    </div>
  );
}

function Textarea({ form, onChange }) {
  const { label, name, type } = form;

  return (
    <div>
      <Label label={label} notRequired={true} />

      <textarea
        name={name}
        type={type}
        className={inputCls}
        onChange={onChange}
      />
    </div>
  );
}

function SelectInput({ form, onChange }) {
  const { label, name, type, options } = form;

  const opts = options || [];

  return (
    <div>
      <Label label={label} notRequired={true} />

      <select onChange={onChange} name={name} className={inputCls}>
        <option value="">Default</option>

        {opts.map((o, i) => {
          return <option value={o.value}>{o.label}</option>;
        })}
      </select>
    </div>
  );
}
