import moment from "moment";

export const socketURL =
  process.env.REACT_APP_SOCKET_PATH || "http://localhost:3001";

export const MIME_TYPE_TO_EXTENSION_MAPPING = {
  "application/doc": "doc",
  "application/ms-doc": "doc",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",

  "application/excel": "xls",
  "application/vnd.ms-excel": "xls",
  "application/x-excel": "xls",
  "application/x-msexcel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",

  "application/mspowerpoint": "ppt",
  "application/powerpoint": "ppt",
  "application/vnd.ms-powerpoint": "ppt",
  "application/x-mspowerpoint": "ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
};

export const formatDateWithTime = (date) => {
  return moment(date).format("DD MMM YYYY, hh:mm A");
};

export function getReactSelectOptions(options = []) {
  return options.map((o) => ({ label: o, value: o }));
}

export function getSelectedValue(selectedValue) {
  if (selectedValue) {
    return { label: selectedValue, value: selectedValue };
  }

  return;
}

export const customReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #d1d5db",
    minHeight: "30px",
    height: "35px",
  }),
};

export function insertPropertyToInput(inputRef, textToInsert, setInputValue) {
  const input = inputRef.current;

  if (input && textToInsert) {
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;

    // Get the current value of the input
    const currentValue = input.value;

    // Insert the text at the cursor position
    const newValue =
      currentValue.substring(0, startPos) +
      `{{${textToInsert}}}` +
      currentValue.substring(endPos);

    setInputValue(newValue);

    // Set the cursor position after the inserted text
    input.setSelectionRange(
      startPos + textToInsert.length,
      startPos + textToInsert.length
    );
  }
}

export const findDynamicPropertyRegex = /{{(.*?)}}/g;

export function extractDynamicProperties(inputString) {
  const matches = inputString.match(findDynamicPropertyRegex) || [];

  const placeholders = [];
  matches.forEach((match) => {
    placeholders.push(match.slice(2, -2)); // Remove the double curly braces
  });

  return placeholders;
}

export function replacePlaceholders(inputString = "", dynamicValues = "") {
  // Replace each placeholder in the input string with the corresponding value from dynamicValues
  const replacedString = inputString.replace(
    findDynamicPropertyRegex,
    (match, placeholder) => {
      // Check if the placeholder exists in dynamicValues
      if (dynamicValues.hasOwnProperty(placeholder)) {
        return dynamicValues[placeholder].toString(); // Convert the value to a string
      } else {
        return match; // Placeholder not found, keep it as is
      }
    }
  );

  return replacedString;
}

export function sortByDisplayOrder(array = [], key) {
  return array.sort((a, b) => a[key] - b[key]);
}

export const propertySplit = ": ";

export function getInsertFormat(prefixString, insertValue) {
  return `${prefixString}: ${insertValue}`;
}

export function embedBotflowPreview(id, botflowId, previewUIOnly) {
  removeBotflowPreview();

  const origin = window.location.host.includes("localhost")
    ? "http://localhost:5173"
    : window.location.origin;

  // let embedFilePath =
  //   "https://login.rhinoassist.com/chatbot/embed.js" + `?id=${id}`;

  let embedFilePath = `${origin}/chatbot/embed.js?id=${id}`;

  if (botflowId) {
    // sessionStorage.setItem("previewParentFlowId", botflowId);
    embedFilePath += `&parentFlowId=${botflowId}`;
  } else if (previewUIOnly) {
    embedFilePath += `&previewUIOnly=true`;
  }

  const script = document.createElement("script");

  script.src = embedFilePath;

  script.id = "botflow-preview";

  document.body.appendChild(script);
}

export function removeBotflowPreview() {
  if (window.removeIframe) {
    window.removeIframe();
  }

  sessionStorage.removeItem("previewParentFlowId");

  const script = document.getElementById("botflow-preview");

  if (script) {
    document.body.removeChild(script);
  }
}

// const domain = "rhinoassist.com";
const domain = "";

export function setCookie(name, value, expiry) {
  console.log("set cookie===>", name, value, expiry, domain);

  const date = new Date();
  date.setTime(date.getTime() + 1 * 60 * 1000); // 1 minute

  const expiresIn = expiry || date.toUTCString();

  const expires = "expires=" + expiresIn;
  const cookieString = `${name}=${value}; ${expires}; domain=${domain}; path=/`;

  document.cookie = cookieString;
}

export function getCookie(name) {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === name) {
      return cookie[1];
    }
  }
  return null;
}

export function removeCookie(name) {
  console.log("removeCookie", name);
  setCookie(name, "", new Date(0));
}

export const initialDomainUrl = window.location.host.includes("localhost")
  ? window.location.origin
  : "https://login.rhinoassist.com";

export function isQuillHavingText(messageText) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = messageText || "";
  const textContent = tempElement.textContent;

  if (!textContent) {
    return messageText?.includes("img");
  }

  return textContent.trim().length > 0;
}

export function getDateOnly(date) {
  if (!date) {
    return;
  }

  const dateString = new Date(date).toISOString();
  const dateParts = dateString.split("T");
  return dateParts[0];
}

export function isValidUrl(url) {
  // Regular expression for a simple URL validation
  var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  return urlPattern.test(url);
}

export function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

export function formatTimeFromSeconds(seconds) {
  if (!seconds) {
    return;
  }

  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedTime = [];

  if (days > 0) {
    formattedTime.push(`${days}d`);
  }

  if (hours > 0) {
    formattedTime.push(`${hours}h`);
  }

  if (minutes > 0) {
    formattedTime.push(`${minutes}m`);
  }

  return formattedTime.join(" ");
}

export function formatRangeDateForPayload(date) {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function getDefaultRangeDates() {
  const firstDayOfWeek = moment().startOf("week");
  const lastDayOfWeek = moment().endOf("week");
  const startDate = new Date(firstDayOfWeek.format("YYYY-MM-DD"));
  const endDate = new Date(lastDayOfWeek.format("YYYY-MM-DD"));

  return { startDate, endDate };
}
