import { useEffect, useState, useContext } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import TicketRow from "./TicketRow";
import TicketColumns from "./TicketColumns";

const TicketsList = (props) => {
  const [processing, setProcessing] = useState(false);
  const [ticketsModal, setTicketsModal] = useState(false);
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [activeColumns, setActiveColumns] = useState({});
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);

  useEffect(() => {
    if (props.tickets.prev_page_url != null) {
      var url = new URL(props.tickets.prev_page_url);
      var searchParams = new URLSearchParams(url.search);
      var page = searchParams.get("page");
      setPrevPage(page);
    } else {
      setPrevPage("");
    }

    if (props.tickets.next_page_url != null) {
      var url = new URL(props.tickets.next_page_url);
      var searchParams = new URLSearchParams(url.search);
      var page = searchParams.get("page");
      setNextPage(page);
    } else {
      setNextPage("");
    }
  }, [props.tickets]);

  useEffect(() => {
    var values = localStorage.getItem("ticketColumns-" + currCommunityId);

    if (values) {
      if (Object.keys(JSON.parse(values)).length > 0) {
        setActiveColumns(JSON.parse(values));
      } else {
        setActiveColumns({
          "Issue No": true,
          Topic: true,
          Assignee: true,
          Clients: true,
          Priority: true,
          Status: true,
        });
      }
    } else {
      setActiveColumns({
        "Issue No": true,
        Topic: true,
        Assignee: true,
        Clients: true,
        Priority: true,
        Status: true,
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(activeColumns).length > 0) {
      localStorage.setItem(
        "ticketColumns-" + currCommunityId,
        JSON.stringify(activeColumns)
      );
    }
  }, [activeColumns]);

  const handleColumns = (name, value) => {
    setActiveColumns((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div>
      <div className="relative shadow-sm mt-10 sm:rounded-md">
        <table className="w-full text-sm text-left text-gray-500 border border-gray-300">
          <thead className="text-xs text-gray-700 uppercase bg-[#E3E9F3] issue-list-title">
            <tr className="table-header border-b border-gray-300">
              {activeColumns["Issue No"] && (
                <th
                  scope="col"
                  className="px-6 py-3 cursor-pointer"
                  onClick={() =>
                    props.sortTicket({
                      sort: "id",
                      order:
                        props.queryParams.order === "desc" &&
                        props.queryParams.sort === "id"
                          ? "asc"
                          : "desc",
                    })
                  }
                >
                  <div className="flex gap-1">
                    <span>Issue No</span>
                    <div className="flex flex-col justify-center text-gray-400 ml-1">
                      <i
                        className={`${
                          props.queryParams.order === "asc" &&
                          props.queryParams.sort === "id" &&
                          "text-indigo-700"
                        } fa-solid fa-sort-up h-[0px] text-black`}
                      ></i>
                      <i
                        className={`${
                          props.queryParams.order === "desc" &&
                          props.queryParams.sort === "id" &&
                          "text-indigo-700"
                        } fa-solid fa-sort-down text-black`}
                      ></i>
                    </div>
                  </div>
                </th>
              )}
              {activeColumns["Topic"] && (
                <th scope="col" className="px-6 py-3">
                  <div className="flex gap-1">
                    <span>Topic</span>
                  </div>
                </th>
              )}
              {/* {activeColumns["Assignee"] && (
                <th scope="col" className="px-6 py-3 assignee">
                  Assignee
                </th>
              )} */}
              {activeColumns["Priority"] && (
                <th
                  scope="col"
                  className="px-6 py-3 assignee cursor-pointer"
                  onClick={() =>
                    props.sortTicket({
                      sort: "priority",
                      order:
                        props.queryParams.order === "desc" &&
                        props.queryParams.sort === "priority"
                          ? "asc"
                          : "desc",
                    })
                  }
                >
                  <div className="flex gap-1">
                    <span>Priority</span>
                    <div className="flex flex-col  justify-center text-gray-400 ml-1">
                      <i
                        className={`${
                          props.queryParams.order === "asc" &&
                          props.queryParams.sort === "priority" &&
                          "text-indigo-700"
                        } fa-solid fa-sort-up h-[0px] text-black`}
                      ></i>
                      <i
                        className={`${
                          props.queryParams.order === "desc" &&
                          props.queryParams.sort === "priority" &&
                          "text-indigo-700"
                        } fa-solid fa-sort-down text-black`}
                      ></i>
                    </div>
                  </div>
                </th>
              )}
              {activeColumns["Clients"] && (
                <th scope="col" className="px-6 py-3 assignee">
                  Clients
                </th>
              )}
              {activeColumns["Status"] && (
                <th
                  scope="col"
                  className="px-6 py-3 cursor-pointer "
                  onClick={() =>
                    props.sortTicket({
                      sort: "status",
                      order:
                        props.queryParams.order === "desc" &&
                        props.queryParams.sort === "status"
                          ? "asc"
                          : "desc",
                    })
                  }
                >
                  <div className="flex gap-1">
                    <span>Status</span>
                    <div className="flex flex-col justify-center text-gray-400 ml-1">
                      <i
                        className={`${
                          props.queryParams.order === "asc" &&
                          props.queryParams.sort === "status" &&
                          "text-indigo-700"
                        } fa-solid fa-sort-up h-[0px] text-black`}
                      ></i>
                      <i
                        className={`${
                          props.queryParams.order === "desc" &&
                          props.queryParams.sort === "status" &&
                          "text-indigo-700"
                        } fa-solid fa-sort-down text-black`}
                      ></i>
                    </div>
                  </div>
                </th>
              )}
              {props.commnuityFields?.map(
                (field) =>
                  activeColumns[field.field_name] && (
                    <th scope="col" className="px-6 py-3 ">
                      <div className="flex gap-1">
                        <span>
                          {typeof field.field_name === "string"
                            ? field.field_name
                            : null}
                        </span>
                      </div>
                    </th>
                  )
              )}

              <th className="!p-0 bg-indigo-50">
                <TicketColumns
                  handleColumns={handleColumns}
                  activeColumns={activeColumns}
                  commnuityFields={props.commnuityFields}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {// console.log("tickers", props.tickets)} */}
            {props.tickets.data.map((ticket, index) => (
              <TicketRow
                ticket={ticket}
                key={index}
                currUser={currUser}
                getAllTickets={props.getAllTickets}
                commnuityFields={props.commnuityFields}
                activeColumns={activeColumns}
                currCommunityId={currCommunityId}
              />
            ))}
          </tbody>
        </table>
        <div className="flex items-center justify-end py-3">
          <div className="pagination">
            <div className="pagination-total">
              Total <span>{props.tickets.total_records}</span> Items
            </div>

            <span
              className={`pagination-pager pagination-pager-next ${
                !prevPage ? "pagination-pager-inactive" : ""
              }`}
              onClick={() => {
                if (prevPage) {
                  props.handlePageChange(prevPage);
                }
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 20 20"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <ul>
              {Array.from(
                { length: props.tickets.last_page },
                (v, i) => i + 1
              ).map((pageNumber) => {
                if (
                  pageNumber === 1 ||
                  pageNumber === props.tickets.last_page ||
                  Math.abs(pageNumber - props.tickets.current_page) <= 1
                ) {
                  return (
                    <li
                      className={`pagination-pager ${
                        props.tickets.current_page == pageNumber
                          ? "text-indigo-600 bg-indigo-50"
                          : ""
                      } hover:bg-indigo-50 `}
                      key={pageNumber}
                      onClick={() => props.handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </li>
                  );
                } else if (
                  pageNumber === props.tickets.current_page - 2 ||
                  pageNumber === props.tickets.current_page + 2
                ) {
                  return (
                    <li className="pagination-pager pagination-pager-inactive">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth={0}
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                        />
                      </svg>
                    </li>
                  );
                }
              })}
            </ul>
            <span
              className={`pagination-pager pagination-pager-next ${
                !nextPage ? "pagination-pager-inactive" : ""
              }`}
              onClick={() => {
                if (nextPage) {
                  props.handlePageChange(nextPage);
                }
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 20 20"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketsList;
