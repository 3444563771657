import Select from "react-select";
import {
  extractDynamicProperties,
  getInsertFormat,
  getReactSelectOptions,
  getSelectedValue,
  insertPropertyToInput,
  replacePlaceholders,
} from "../../../../common/commonHelpers";
import CreatableSelect from "react-select/creatable";
import Loading from "../../../../loaders/Loading";
import Insert from "../../buildBotFlow/common/Insert";
import DefaultInsertOptions from "../../buildBotFlow/common/DefaultInsertOptions";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import AddHeadersInput from "./AddHeadersInput";
import HandleApiResponse from "./HandleApiResponse";
import { AppContext } from "../../../../../App";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useApiConfiguration from "./useApiConfiguration";
import useApiData from "../../../../../hooks/useApiData";
import Button from "../../../../common/Button";
import Spinner from "../../../../loaders/Spinner";
import BackBtn from "../../../../common/BackBtn";
import ApiPayloadAsForm from "./ApiPayloadAsForm";
import SelectPayloadType from "./SelectPayloadType";
import { getRemoteVariables } from "../../buildBotFlow/helpers/dialogHelpers";

function isValidJSON(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

function isNameNotUnique(arr) {
  if (!arr?.length) {
    return false;
  }

  const nameSet = new Set();

  for (const obj of arr) {
    if (nameSet.has(obj.name)) {
      return true;
    }
    nameSet.add(obj.name);
  }

  return false;
}

export function handleOnInsertProperty(
  insertValue,
  option,
  valueInputRef,
  onInsert
) {
  if (!insertValue) {
    return;
  }

  const { variableConfigurationId, apiConfigurationId, prefixString } = option;

  const data = { name: insertValue, prefixString };

  if (variableConfigurationId) {
    data.variableConfigurationId = variableConfigurationId;
  } else if (apiConfigurationId) {
    data.apiConfigurationId = apiConfigurationId;
  }

  insertPropertyToInput(
    valueInputRef,
    getInsertFormat(prefixString, insertValue),
    (value) => onInsert(value, data)
  );
}

function getAllDynamicPropertyStrings(formData) {
  let inputString = "";

  const { apiUrl, apiBody, apiForm } = formData;

  if (apiUrl) {
    inputString += apiUrl;
  }
  if (apiBody) {
    inputString += apiBody;
  }
  if (apiForm) {
    apiForm?.forEach((f) => {
      if (f.value) {
        inputString += f.value;
      }
    });
  }

  return inputString;
}

export function getDefaultApiForm() {
  return {
    name: "",
    value: "",
    type: "",
    hideForUser: false,
  };
}

const apiMethods = ["GET", "POST", "PUT", "PATCH", "DELETE"];

function FormApiConfiguration() {
  const [searchParams] = useSearchParams();
  const configurationId = searchParams.get("configurationId");

  const [formData, setFormData] = useState({
    apiName: "",
    apiUrl: "",
    apiMethod: "",
    accessTokenType: "",
    accessToken: "",
    apiBody: null,
    apiForm: null,
    usingParameters: [],
    remoteVariables: [],
  });

  const [payloadType, setPayloadType] = useState("JSON");

  const [apiValidated, setApiValidated] = useState(
    configurationId ? true : false
  );
  const [testVariables, setTestVariables] = useState({
    dynamicProperties: {},
    form: {},
  });
  const [apiError, setApiError] = useState(null);

  const [validateApiBtnText] = useState("Validate API");

  const { onSuccess, onError } = useContext(AppContext);

  const urlInputRef = useRef();

  const payloadInputRef = useRef();

  const updateFormData = useCallback(
    (update) => {
      setFormData((prev) => ({ ...prev, ...update }));
    },
    [setFormData]
  );

  const { id, botflowId } = useParams();

  const navigate = useNavigate();

  function getDynamicProperties() {
    const inputString = formData.apiUrl + formData.apiBody;

    const dynamicProperties = extractDynamicProperties(inputString);

    return [...new Set(dynamicProperties)];
  }

  const {
    createApiConfiguration,
    updateApiConfiguration,
    checkApiConfiguration,
    getApiConfiguration,
  } = useApiConfiguration(id, botflowId);

  const { mutate: get, loading } = useApiData({
    onSuccess: (res) => {
      // onSuccess("API Created Successfully");

      const apiData = res?.data?.data || {};

      delete apiData.uuid;

      apiData.usingParameters = apiData.usingParameters || [];

      setFormData(apiData);

      setPayloadType(apiData?.apiForm ? "FORM" : "JSON");
    },
    onError: () =>
      onError("Something went wrong while getting API configuration"),
  });

  const { mutate: create, loading: isCreateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("API configuration Created Successfully");
    },
    onError: (res) => {
      console.log("res", res);
      const msgData = res?.response?.data?.error?.apiName || [];

      onError(
        msgData[0] || "Something went wrong while creating API configuration"
      );
    },
  });

  const { mutate: update, loading: isUpdateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("API configuration Updated Successfully");
    },
    onError: () =>
      onError("Something went wrong while updating API configuration"),
  });

  const {
    data,
    mutate: check,
    loading: isChecking,
  } = useApiData({
    onSuccess: (res) => {
      setApiValidated(true);
      onSuccess("API validated");
    },
    onError: (error) => {
      setApiError(JSON.stringify(error, null, 2));
      onError("API is invalid");
    },
  });

  const saving = isCreateLoading || isUpdateLoading;

  function onRunTest() {
    setApiError(null);

    const payload = {
      ...formData,
      testVariables,
    };

    check(checkApiConfiguration(payload));
  }

  function onSubmit(e) {
    e.preventDefault();

    const isValidateAPIBtnClicked =
      validateApiBtnText === e?.nativeEvent?.submitter?.innerText;

    if (isValidateAPIBtnClicked) {
      onRunTest();
      return;
    }

    if (!apiValidated) {
      return;
    }

    const payload = {
      ...formData,
      dynamic: getDynamicProperties().length,
    };

    if (configurationId) {
      update(updateApiConfiguration(payload, configurationId));
    } else {
      create(createApiConfiguration(payload));
    }
  }

  useEffect(() => {
    if (configurationId) {
      get(getApiConfiguration(configurationId));
    }
  }, []);

  const onBackPath = `/train-chat-bot/${id}/botflow/${botflowId}/configuration/api`;

  const showPayloadInput = ["post", "put", "patch"].includes(
    formData.apiMethod.toLowerCase()
  );

  if (loading) {
    return (
      <div className="w-fit mx-auto mt-8">
        <Spinner />
      </div>
    );
  }

  const apiFormError = isNameNotUnique(formData?.apiForm);

  return (
    <>
      <BackBtn onClick={() => navigate(onBackPath)} />

      <form className="flex flex-col space-y-3 w-[90%]" onSubmit={onSubmit}>
        <div className="text-3xl font-semibold mb-2 text-gray-700">New API</div>

        <div>
          <Label label="API name" />

          <input
            type="text"
            className="w-full p-2 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            required
            value={formData.apiName}
            onChange={(e) => updateFormData({ apiName: e.target.value })}
          />
        </div>

        <div>
          <Label label="Method" />

          <Select
            className="text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            options={getReactSelectOptions(apiMethods)}
            onChange={(val = {}) => {
              setApiValidated(false);
              updateFormData({
                apiMethod: val?.value,
                apiBody: "",
                apiForm: "",
              });

              setPayloadType("JSON");
            }}
            value={getSelectedValue(formData.apiMethod)}
            required
          />
        </div>

        <div className="relative">
          <Label label="URL" />

          <input
            type="text"
            className="w-full p-2 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            required
            placeholder="Enter API URL"
            value={formData.apiUrl}
            onChange={(e) => {
              setApiValidated(false);
              updateFormData({ apiUrl: e.target.value });
            }}
            ref={urlInputRef}
          />

          <div className="absolute bottom-2 right-2 h-fit">
            <Insert>
              <DefaultInsertOptions
                configurationId={configurationId}
                onInsert={(insertValue, option) => {
                  // insertPropertyToInput(urlInputRef, insertValue, (value) => {
                  //   setApiValidated(false);
                  //   updateFormData({ apiUrl: value });
                  // });

                  handleOnInsertProperty(
                    insertValue,
                    option,
                    urlInputRef,
                    (value, remoteVariablesData) => {
                      setApiValidated(false);
                      const newRemoteVariables = getRemoteVariables(
                        { remoteVariables: formData?.remoteVariables },
                        remoteVariablesData,
                        getAllDynamicPropertyStrings({
                          ...formData,
                          apiUrl: value,
                        })
                      );

                      updateFormData({
                        apiUrl: value,
                        remoteVariables: newRemoteVariables,
                      });
                    }
                  );
                }}
              />
            </Insert>
          </div>
        </div>

        <AddHeadersInput
          accessTokenType={formData.accessTokenType}
          accessToken={formData.accessToken}
          updateFormData={updateFormData}
        />

        {showPayloadInput ? (
          <>
            <SelectPayloadType
              payloadType={payloadType}
              setPayloadType={(payloadType) => {
                const isJSON = payloadType === "JSON";

                updateFormData({
                  apiBody: "",
                  apiForm: isJSON ? null : [getDefaultApiForm()],
                });

                setPayloadType(payloadType);
              }}
            />
            <div className="pb-4">
              {payloadType === "JSON" ? (
                <div className="relative">
                  <Label
                    label="Enter the payload content (in JSON)"
                    notRequired={true}
                  />

                  <textarea
                    type="text"
                    className="min-h-[200px] w-full p-2 pb-6 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    placeholder="{ 'key' : 'value' }"
                    ref={payloadInputRef}
                    onChange={(e) => {
                      setApiValidated(false);
                      updateFormData({ apiBody: e.target.value });
                    }}
                    value={formData.apiBody}
                  />

                  <div className="absolute bottom-4 right-2 h-fit">
                    <Insert>
                      <DefaultInsertOptions
                        configurationId={configurationId}
                        onInsert={(insertValue, option) => {
                          // insertPropertyToInput(
                          //   payloadInputRef,
                          //   insertValue,
                          //   (value) => {
                          //     setApiValidated(false);
                          //     updateFormData({ apiBody: value });
                          //   }
                          // );

                          handleOnInsertProperty(
                            insertValue,
                            option,
                            payloadInputRef,
                            (value, remoteVariablesData) => {
                              setApiValidated(false);
                              const newRemoteVariables = getRemoteVariables(
                                { remoteVariables: formData?.remoteVariables },
                                remoteVariablesData,
                                getAllDynamicPropertyStrings({
                                  ...formData,
                                  apiBody: value,
                                })
                              );

                              updateFormData({
                                apiBody: value,
                                remoteVariables: newRemoteVariables,
                              });
                            }
                          );
                        }}
                      />
                    </Insert>
                  </div>
                </div>
              ) : (
                <ApiPayloadAsForm
                  apiForm={formData.apiForm || []}
                  updateFormData={updateFormData}
                  remoteVariables={formData?.remoteVariables}
                  dynamicInputString={(apiForm) =>
                    getAllDynamicPropertyStrings({ ...formData, apiForm })
                  }
                  apiFormError={apiFormError}
                />
              )}
            </div>
          </>
        ) : null}

        <HandleApiResponse
          usingParameters={formData.usingParameters}
          updateParameters={(key) => {
            setFormData((prev) => ({
              ...prev,
              usingParameters: [...prev.usingParameters, key],
            }));
          }}
          removeParameters={(key) => {
            setFormData((prev) => ({
              ...prev,
              usingParameters: prev.usingParameters.filter((p) => p !== key),
            }));
          }}
          remoteVariables={formData?.remoteVariables}
          onRunTest={onRunTest}
          showRunTest={formData.apiUrl && formData.apiMethod}
          isRunningTest={isChecking}
          data={data?.response_keys}
          testVariables={testVariables}
          setTestVariables={setTestVariables}
          apiError={apiError}
          apiForm={formData?.apiForm || []}
          validateApiBtnText={validateApiBtnText}
        />

        {apiValidated ? null : (
          <div className="text-red-600 my-2">Please validate the API</div>
        )}

        <Label label="Values to be used in Bot flows" />

        <div className="pl-4 border-l">
          <Label label="Body parameters" notRequired={true} />

          <CreatableSelect
            className="h-auto manageCustomField"
            isMulti
            value={getReactSelectOptions(formData.usingParameters)}
            options={[]}
            onChange={(val = {}, actions) => {
              console.log(val, actions);

              const { action, removedValue } = actions;

              if (action === "remove-value") {
                updateFormData({
                  usingParameters: formData.usingParameters.filter(
                    (p) => p !== removedValue?.value
                  ),
                });
              } else if (action === "clear") {
                updateFormData({ usingParameters: [] });
              }
            }}
          />
        </div>

        <div className="flex space-x-2 items-center pt-6">
          <button
            className={`flex justify-center bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white py-2 rounded-md w-24 ${
              saving || apiFormError ? "cursor-not-allowed" : ""
            }`}
            type="submit"
            disabled={saving || apiFormError}
          >
            {saving && <Loading />}
            {configurationId ? "Update" : "Save"}
          </button>

          <Button
            className="border border-red-600 py-2 rounded-md text-red-600 w-24"
            onClick={() => navigate(onBackPath)}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}

export default FormApiConfiguration;

export function Label({ label, notRequired, customCls = "" }) {
  return (
    <div
      className={`text-gray-500 my-1.5 mt-3 font-semibold ${
        notRequired ? "" : "required-input"
      } ${customCls}`}
    >
      {label}
    </div>
  );
}
