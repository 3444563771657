import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import { AppContext } from "../../App";
import Spinner from "../loaders/Spinner";
import { Tooltip } from "react-tooltip";

function validate(fields) {
  const err = {};
  let isError = false;

  Object.entries(fields).forEach(([key, value]) => {
    const { field_type, field_values } = value;

    const isHavingSubItem =
      field_type === "select" || field_type === "multiselect";

    if (isHavingSubItem && !field_values?.length) {
      err[key] = "Atleast one field is required.";
      isError = true;
    }
  });

  return { err, isError };
}

function handleChange(e, fieldNo, setFields) {
  const { name, value } = e.target;

  setFields((prev) => {
    let oldData = prev[fieldNo] || {};

    oldData[name] = value;

    return { ...prev, [fieldNo]: oldData };
  });
}

function handleRemove(fieldNo, fields, setFields, setFieldsCount) {
  const oldFields = fields;

  delete oldFields[fieldNo]; // remove selected field

  const newFieldData = {};

  // refactoring the balance fields
  Object.values(oldFields).forEach((data, i) => {
    const fieldNo = i + 1;
    newFieldData[fieldNo] = data;
  });

  setFields(newFieldData);
  setFieldsCount((prev) => prev - 1);
}

function AdditionalFieldsForm({
  customFields,
  getClientCustomFieldsData,
  isLoading,
}) {
  const [fields, setFields] = useState({}); // store data in obj based on field number, i.e, for field 1, 1:{ data },for field 2, 2:{ data }
  const [fieldsCount, setFieldsCount] = useState(0);
  const [error, setError] = useState({});
  const loading = false;
  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);

  useEffect(() => {
    const obj = {};
    console.log(customFields);
    customFields?.data.forEach((item, i) => {
      const fieldNo = i + 1;
      const { id, field_name, field_type, field_values } = item;
      console.log(id, field_name, field_type, field_values);
      const fieldValueObject = field_values ? JSON.parse(field_values) : "";
      const fieldValueArray = Object.values(fieldValueObject);

      obj[fieldNo] = {
        id,
        field_name,
        field_type,
        field_values: fieldValueArray,
      };
    });

    setFields(obj);
    setFieldsCount(obj ? Object.keys(obj).length : 0);
  }, [customFields]);

  const {
    data: updatedData,
    mutate: updateApi,
    loading: isUpdateLoading,
  } = useApiData({
    onSuccess: (res) => {
      if (res.data.statusCode == "200") {
        getClientCustomFieldsData();
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Data Saved Successfully",
        });
      } else {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      }
    },
  });

  const {
    data: deletedData,
    mutate: deleteApi,
    loading: isdeleteLoading,
  } = useApiData({
    onSuccess: (res) => {
      if (res.data.statusCode == "200") {
        getClientCustomFieldsData();

        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Data Deleted Successfully",
        });
      } else {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      }
    },
  });

  const formSubmit = (e) => {
    e.preventDefault();

    const { isError, err } = validate(fields);

    if (isError) {
      setError(err);
      return;
    } else {
      setError({});
    }

    const clientCustomFields = Object.values(fields).map(
      ({ id, field_name, field_type, field_values }) => ({
        id: id ? String(id) : "",
        field_name,
        field_type,
        field_values: field_values?.length ? field_values : "",
      })
    );

    const payload = {
      clientCustomFields,
    };

    updateApi(() =>
      api.post(
        `api/v1/createUpdateClientCustomFields/communityId/${currCommunityId}`,
        payload
      )
    );
  };

  const removeField = async (fieldNo, field) => {
    if (field.id) {
      const payload = {
        customfield_id: [field.id],
      };
      console.log(payload);

      deleteApi(() =>
        api.post(
          `api/v1/deleteClientCustomFields/communityId/${currCommunityId}`,
          payload
        )
      );
    } else {
      handleRemove(fieldNo, fields, setFields, setFieldsCount);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-[700px] overflow-hidden w-fit h-[30vh]">
          <Spinner />
        </div>
      ) : (
        <form
          className="mt-3"
          onSubmit={(e) => {
            formSubmit(e);
          }}
        >
          <div className="md:grid md:grid-cols-2 gap-5">
            {fieldsCount
              ? Array.from({ length: fieldsCount }).map((__, i) => {
                  const fieldNo = i + 1;
                  return (
                    <Form
                      key={i}
                      data={fields[fieldNo]}
                      onValueChange={(e) => handleChange(e, fieldNo, setFields)}
                      onRemove={() => removeField(fieldNo, fields[fieldNo])}
                      error={error[fieldNo]}
                      isdeleteLoading={isdeleteLoading}
                    />
                  );
                })
              : null}
          </div>
          <FooterBtns
            onAdd={() => {
              setFields((prev) => ({ ...prev, [fieldsCount + 1]: {} }));
              setFieldsCount((prev) => prev + 1);
            }}
            fieldsCount={fieldsCount}
            loading={isUpdateLoading}
          />
        </form>
      )}
    </>
  );
}

export default AdditionalFieldsForm;

function Form({ data = {}, onRemove, onValueChange, error, isdeleteLoading }) {
  const showSubItem =
    data.field_type === "select" || data.field_type === "multiselect";
  return (
    <div>
      <div>
        <label className="required-input">Field Name</label>
        <input
          type="text"
          name="field_name"
          value={data.field_name}
          onChange={onValueChange}
          className="mb-2 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
          required
        />
      </div>
      <div>
        <label className="required-input">Field Type</label>
        <select
          className="mb-2 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
          name="field_type"
          value={data.field_type}
          onChange={onValueChange}
          required
        >
          <option value="">Select Type</option>
          <option value="input">Input</option>
          <option value="select">Select</option>
          <option value="multiselect">Multi Select</option>
        </select>
      </div>

      {showSubItem && (
        <div>
          <label className="required-input">Field values</label>
          <FieldValues
            defaultValue={data.field_values}
            onChange={(values) => {
              const value = values.map((v) => v.value);
              onValueChange({ target: { value, name: "field_values" } }, true);
            }}
          />

          {error ? <div className="form-error">{error}</div> : ""}
        </div>
      )}

      <button
        type="button"
        onClick={onRemove}
        className="flex items-center add-btn justify-end button bg-red-50 hover:bg-red-500 active:bg-red-700 text-red-500 hover:text-white radius-round h-8 px-3 py-2 mb-1"
        disabled={isdeleteLoading}
      >
        {isdeleteLoading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        Remove
        <i aria-hidden="true"></i>
      </button>
    </div>
  );
}

function FooterBtns({ loading, onAdd, fieldsCount }) {
  return (
    <div className="custom-buttons">
      <div>
        <button
          className="flex mt-3 duration-300 ease-in inline-flex items-center justify-center button bg-gray-200 light-blue-hover text-gray-700 radius-round h-11 px-8 py-2 w-full"
          onClick={onAdd}
          disabled={fieldsCount >= 5}
          data-tooltip-content={
            fieldsCount >= 5 ? "You can only add up to 5 fields" : ""
          }
          data-tooltip-id="disable-add-button"
          data-tooltip-place="top"
        >
          Add
        </button>
        <Tooltip id="disable-add-button" />
      </div>

      <button
        type="submit"
        className="flex save-btn justify-end mt-4 float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
        disabled={loading}
      >
        {loading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        Save
      </button>
    </div>
  );
}

function FieldValues({ defaultValue, onChange }) {
  const defaultValueOptions = defaultValue
    ? defaultValue.map((value) => ({
        value,
        label: value,
      }))
    : [];
  return (
    <CreatableSelect
      className="mb-2"
      isMulti
      onChange={onChange}
      defaultValue={defaultValueOptions}
    />
  );
}
