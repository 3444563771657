import { useParams } from "react-router-dom";
import useApiConfiguration from "../../configuration/apiConfiguration/useApiConfiguration";
import useApiData from "../../../../../hooks/useApiData";
import { AppContext } from "../../../../../App";
import { useContext, useEffect, useState } from "react";
import { DisplayOptions } from "../common/DefaultInsertOptions";
import Spinner from "../../../../loaders/Spinner";

function FormApiOptions({ onSubmitForm, ...props }) {
  const [options, setOptions] = useState([]);

  const { id, botflowId } = useParams();

  const { getApiConfigurations } = useApiConfiguration(id, botflowId);

  const { onError } = useContext(AppContext);

  const { mutate: getApiList, loading } = useApiData({
    onSuccess: (res) => {
      let options = res.data?.data || [];

      const refactor = [];

      options.forEach((o) => {
        refactor.push({
          label: o.apiName,
          value: o.uuid,
        });
      });

      setOptions(refactor);
    },
    onError: () => onError("Something went wrong while getting form apis"),
  });

  useEffect(() => {
    getApiList(
      getApiConfigurations({
        search: "",
        type: "form",
      })
    );
  }, []);

  return (
    <div>
      {loading && <Spinner />}

      <DisplayOptions
        options={options}
        onOptionsSelect={(value, page, option) => {
          const data = {
            configurationId: {
              apiName: option.label,
              uuid: option.value,
            },

            configurationType: "API",
          };

          props.setOpen(false);

          onSubmitForm(data);
        }}
      />
    </div>
  );
}

export default FormApiOptions;
