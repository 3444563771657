import BotFlowSideBar from "./sidebar/BotFlowSideBar";

function BotflowLayout({ children, tab }) {
  return (
    <div className="flex">
      <BotFlowSideBar activeTab={tab} />

      <div className="w-full">{children}</div>
    </div>
  );
}

export default BotflowLayout;
