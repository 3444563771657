import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { json, useParams } from "react-router-dom";
import ModuleForm from "../../components/module/ModuleForm";

const ModuleContainer = () => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [folderDetails, setFolderDetails] = useState([]);
  const [userPrompt, setUserPrompt] = useState("");
  const [inputList, setInputList] = useState([]);
  const [fields, setFields] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    getFolderDetails();
  }, []);

  const getFolderDetails = () => {
    var postData = {
      id: id,
      community_id: currCommunityId,
    };
    api
      .post("/api/moduleDetailsGPT", postData)
      .then((response) => {
        if (response.data.data != null) {
          // console.log(response.data);
          setFolderDetails([response.data.data]);
          setUserPrompt(response.data.data.prompt);
          setFieldValue(response.data.modulefield);
        } else {
          setShowToast(true);
          setToastMessage({ type: "error", message: "Chat Bot Not Found" });
          setFolderDetails([]);
        }
      })
      .catch((error) => {
        setFolderDetails([]);
      });
  };

  const setFieldValue = (value) => {
    setFields(value);
  };

  return (
    <div className="p-5 container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            {folderDetails.length > 0 ? (
              <div className="h-full flex flex-col">
                <ModuleForm
                  folderDetails={folderDetails}
                  setFieldValue={setFieldValue}
                  fieldValue={fields}
                  processing={processing}
                  userPromptValue={userPrompt}
                />
              </div>
            ) : (
              <div className="h-full flex flex-col items-center justify-center">
                <div className="text-center">
                  <img
                    src={require("../../assets/images/welcome.png")}
                    alt="Welcome"
                    className="mx-auto mb-8"
                  />
                  <h3 className="mb-2">Chatbot Not Found</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleContainer;
