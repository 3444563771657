import { useContext, useEffect } from "react";
import { AppContext } from "../../../App";
import SideBar from "../../../components/settings/SideBar";
import CurrentPlan from "../../../components/settings/pricingPlan/CurrentPlan";
import Usage from "../../../components/settings/pricingPlan/Usage";
import useApiData from "../../../hooks/useApiData";
import usePricingPlan from "../../../components/settings/pricingPlan/usePricingPlan";
import Loading from "../../../components/loaders/Loading";
import Spinner from "../../../components/loaders/Spinner";

const PricingPlan = () => {
  const { setToastMessage, setShowToast, setNavToggle, onError, onSuccess } =
    useContext(AppContext);

  const { getAccountPlanDetails } = usePricingPlan();

  const {
    data: currentPlanData,
    mutate: getAccountPlanDetailsMutate,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting Plan"),
  });

  useEffect(() => {
    getAccountPlanDetailsMutate(getAccountPlanDetails());
  }, []);

  const { data } = currentPlanData || {};

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            <div className="flex gap-4 text-gray-700">
              <SideBar />
              <div className="w-[80%] h-[300px] mt-5">
                <div className="text-2xl font-semibold pb-4 h-auto">Plan</div>
                {/* <div className="text-md font-semibold pb-4 h-auto">
                  Manage Your Plan
                </div> */}
                {loading ? (
                  <div className="w-fit mx-auto mt-8">
                    <Spinner />
                  </div>
                ) : data && data.length !== 0 ? (
                  <div className="">
                    <CurrentPlan data={data} />
                    <Usage data={data} />
                  </div>
                ) : (
                  "No Plan Found"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
