import { formatDateWithTime } from "../../../components/common/commonHelpers";
import Spinner from "../../../components/loaders/Spinner";
import ThemeCard from "./ThemeCard";

const themes = [
  {
    name: "CUSTOMER_PORTAL",
  },
  {
    name: "ITSM_PORTAL",
  },
];

function Theme({ portal, loading, updateTheme }) {
  return (
    <div className="mt-12">
      <div className="font-semibold pb-2 border-b border-gray-300 text-lg">
        Themes
      </div>

      <div className="font-semibold text-gray-500 my-2 mt-8">Current Theme</div>

      {loading ? (
        <div className="flex flex-col justify-center items-center w-fit my-6">
          <Spinner />
          <div className="font-semibold text-blue-600 my-2">
            Applying theme...
          </div>
        </div>
      ) : (
        <ThemeCard isLive={portal?.status} name={portal.theme} />
      )}

      <div className="font-semibold text-gray-500 my-2 mt-8">
        Available Themes
      </div>

      <div className="grid xl:grid-cols-2 gap-12 w-fit">
        {themes.map((theme, i) => {
          return (
            <ThemeCard
              key={i}
              name={theme?.name}
              containerCls="!w-[320px]"
              isOptionType={true}
              isSelected={portal?.theme === theme.name}
              onApply={() => updateTheme(theme?.name)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Theme;
