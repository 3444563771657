import React, { useState, useEffect, useContext } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import Folder from "../../components/mail/Folder";
import MailList from "../../components/mail/MailList";
import { useNavigate, useParams } from "react-router-dom";
import MailDetail from "../../components/mail/MailDetail";
import ComposeEmail from "../../components/mail/ComposeMail";
import Spinner from "../../components/loaders/Spinner";

const Mails = () => {
  const [mails, setMails] = useState([]);
  const [folders, setFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState("");
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [composeModal, setComposeModal] = useState(false);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const location = useNavigate();

  const { id } = useParams();

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);

  const getMails = (refresh = null) => {
    var postData = {
      folder: activeFolder ? activeFolder : "INBOX",
      pageNumber: currentPage,
      community_id: currCommunityId,
      refresh: refresh,
    };
    if (refresh != null) {
      setLoading(true);
    }
    api
      .post("/api/getMails", postData)
      .then((response) => {
        setLoading(false);
        if (response.data.data.messages) {
          setMails(response.data.data.messages);
        }
        if (response.data.pagination) {
          setPagination(response.data.pagination);
        }
        if (response.data.data.folders) {
          setFolders(response.data.data.folders);
        }
      })
      .catch((error) => {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Imap is not configured yet please add",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    getMails();
    if (!id) {
      setLoading(true);
    }
  }, [activeFolder, currentPage]);

  const handleClick = (folder) => {
    // console.log(folder);
    setActiveFolder(folder.path);
    location("/mails");
  };
  return (
    <div className="mx-auto h-full px-2 p-8 pt-3">
      <div className="flex items-center justify-between pb-4 mt-3 border-b border-gray-300">
        <h3 className="mb-4 lg:mb-0 main-title">Email</h3>
        <button
          className="bg-primary-600 px-6 py-3 rounded-md text-white"
          onClick={() => setComposeModal(true)}
        >
          <i className="fa-solid fa-pencil mr-2"></i> Compose
        </button>
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-[50vh]">
          <Spinner />
        </div>
      ) : (
        <div className="flex gap-5 mt-4">
          <div className="w-[20%]">
            <div className="shadow-md bg-gray-50 rounded block">
              <div className="folderList flex flex-col p-3">
                {folders.map((folder) => (
                  <Folder
                    handleClick={handleClick}
                    key={folder.path}
                    folder={folder}
                    activeFolder={activeFolder}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="shadow-md custom-scroll rounded mailList w-full overflow-hidden overflow-y-auto ">
            {id ? (
              <MailDetail uid={id} activeFolder={activeFolder} />
            ) : (
              <MailList
                handlePageChange={handlePageChange}
                pagination={pagination}
                mails={mails}
                activeFolder={activeFolder}
                handleLoading={setLoading}
                getMails={getMails}
              />
            )}
          </div>
          {composeModal && (
            <ComposeEmail handleComposeModal={setComposeModal} />
          )}
        </div>
      )}
    </div>
  );
};

export default Mails;
