import { useEffect, useState, useContext, useCallback } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ChatBot from "../../components/chatBot/ChatBot";

import Spinner from "../../components/loaders/Spinner";
import TrainYourData from "../../components/chatBot/TrainYourData";
import ChatBotConfig from "../../components/chatBot/ChatBotConfig";
import EmailTemplates from "../../components/chatBot/EmailTemplates";
import Favian from "../../components/chatBot/Favian";
import ListBotFlow from "../../components/chatBot/botflow/listBotFlow/ListBotFlow";
import {
  embedBotflowPreview,
  removeBotflowPreview,
} from "../../components/common/commonHelpers";
import ToggleBtn from "../../components/common/ToggleBtn";
import AddRemoveTab from "./AddRemoveTab";
import OfflineSettings from "./OfflineSettings";
import AIConfig from "./AIConfig";
import ChangeBotStatus from "./ChangeBotStatus";
import GeneralConfigurations from "../../components/chatBot/GeneralConfigurations";
import Knowledge from "./KnowledgeContainer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const tabs = [
  { label: "General" },
  { label: "AI Configuration" },
  { label: "FAQ" },
  { label: "Bot Flows" },
];

function getWorkingHours(from_time, to_time) {
  if (from_time != null && to_time != null) {
    var from_dateObj = new Date(from_time);

    var from_hours = from_dateObj.getHours();
    var from_minutes = from_dateObj.getMinutes();

    var from_hoursStr =
      from_hours < 10 ? "0" + from_hours : from_hours.toString();
    var from_minutesStr =
      from_minutes < 10 ? "0" + from_minutes : from_minutes.toString();

    var from_hoursMinutes = from_hoursStr + ":" + from_minutesStr;

    var to_dateObj = new Date(to_time);

    var to_hours = to_dateObj.getHours();
    var to_minutes = to_dateObj.getMinutes();

    var to_hoursStr = to_hours < 10 ? "0" + to_hours : to_hours.toString();
    var to_minutesStr =
      to_minutes < 10 ? "0" + to_minutes : to_minutes.toString();

    var to_hoursMinutes = to_hoursStr + ":" + to_minutesStr;

    if (isNaN(from_dateObj.getDate())) {
      from_hoursMinutes = from_time;
    }

    if (isNaN(to_dateObj.getDate())) {
      to_hoursMinutes = to_time;
    }

    return {
      from_time: from_hoursMinutes,
      to_time: to_hoursMinutes,
    };
  }

  return {};
}

const TrainChatBot = () => {
  const {
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    handleLinkClick,
    setHasChanged,
  } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [folderDetails, setFolderDetails] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [workingHrs, setWorkingHrs] = useState({ from_time: "", to_time: "" });
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [isGpt, setIsGpt] = useState();
  const [openTab, setOpenTab] = useState(1);
  const [embedModal, setEmbedModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const { id } = useParams();
  // const [embedUrl, setEmbedUrl] = useState(
  //   `<script src='${window.location.origin}/chatbot/embed.js?id=${id}'></script>`
  // );

  const embedUrl = `<script>
    const options = {};
    //   optional
    //   options.userDetails = {
    //     name: "Example Name",
    //     email: "dummy@example.com"
    //   };
    const script = document.createElement("script");
    script.src = '${window.location.origin}/chatbot/embed.js?id=${id}';
    document.head.appendChild(script);
</script>`;

  const [config, setConfig] = useState({});
  const updateConfig = useCallback((update) => {
    setHasChanged(true);
    setConfig((prev) => ({ ...prev, ...update }));
  }, []);

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getFolderDetails();
    getUserInfo();
    const searchParams = new URLSearchParams(location.search);
    const tabValue = searchParams.get("tab");
    if (tabValue) {
      setOpenTab(parseInt(tabValue));
    }
  }, []);

  const getFolderDetails = () => {
    var postData = {
      id: id,
      community_id: currCommunityId,
    };
    setLoader(true);
    setHasChanged(false);
    api
      .post("/api/folderDetails", postData)
      .then((response) => {
        if (response.data.data != null) {
          setLoader(false);
          setFolderDetails(response.data);

          const configData = response?.data?.data || {};

          const { from_time, to_time } = configData;

          const workingHrs = getWorkingHours(from_time, to_time);

          setConfig({ ...configData, ...workingHrs });

          setIsGpt(response.data.data?.is_gpt);
          if (
            response.data.qadata != undefined &&
            response.data.qadata != null
          ) {
            if (response.data.qadata.length > 0) {
              setFilteredQuestions(response.data.qadata);
            }
          }
        } else {
          setLoader(false);
          setShowToast(true);
          setToastMessage({ type: "error", message: "Chat Bot Not Found" });
          setFolderDetails([]);
        }
      })
      .catch((error) => {
        setLoader(false);
        // setFolderDetails([]);
      });
  };

  useEffect(() => {
    embedBotflowPreview(id, null, true);

    return () => {
      removeBotflowPreview();
    };
  }, []);

  const changeTab = (value) => {
    setOpenTab(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", value);
    history({ search: "?" + searchParams.toString() });
  };

  return (
    <div style={{ width: "calc(100% - 6rem)" }}>
      <div>
        <div className=" relative h-full flex flex-auto flex-col sm:py-6">
          {loader ? (
            <div className="flex items-center justify-center h-[50vh]">
              <Spinner />
            </div>
          ) : (
            <div className="">
              {Object.keys(folderDetails).length > 0 ? (
                <div className="">
                  <div className="rounded-md">
                    <div className="flex items-center justify-between mb-6 w-full">
                      <div className="flex gap-1 items-center">
                        <h3 className="mb-4 lg:mb-0 main-title">
                          {folderDetails.data.name}
                        </h3>
                      </div>

                      <button
                        type="button"
                        className="text-indigo-600 flex items-center border border-gray-300 rounded-md px-4 py-2 text-sm"
                        onClick={() => setEmbedModal(!embedModal)}
                      >
                        <img
                          src="/images/icons/embed.svg"
                          alt="Embed Code"
                          className="mr-2"
                        />
                        Embed Code
                      </button>
                    </div>

                    <div className="border border-gray-300 rounded-md min-h-[82vh] bg-white relative">
                      <div className="flex border-b border-gray-300 bg-[#E3E9F3]">
                        {tabs.map((tab, i) => {
                          const value = i + 1;

                          const { label } = tab;

                          return (
                            <div
                              className={`py-4 mx-2 font-medium cursor-pointer tab-nav tab-nav-pill tab-nav-active px-3 py-1 hover:main-color duration-300 ease hover:border-b border-main-color hover:border-b-2 ${
                                openTab == value &&
                                "main-color border-b border-b-2 border-main-color"
                              }`}
                              onClick={() => changeTab(value)}
                            >
                              {label}
                            </div>
                          );
                        })}
                      </div>

                      {openTab === 1 && (
                        <>
                          <GeneralConfigurations
                            config={config}
                            setConfig={setConfig}
                            updateConfig={updateConfig}
                            getFolderDetails={getFolderDetails}
                          />

                          {/* <ChangeBotStatus
                            status={folderDetails?.data?.offline_status}
                            refetch={getFolderDetails}
                          /> */}

                          {/* <AddRemoveTab label="Config">
                            <ChatBotConfig
                              folderDetails={folderDetails}
                              getFolderDetails={getFolderDetails}
                              handleModalStatus={setModalStatus}
                            />
                          </AddRemoveTab>

                          <AddRemoveTab label="Scheduler">
                            <TrainYourData
                              folderDetails={folderDetails}
                              getFolderDetails={getFolderDetails}
                              handleModalStatus={setModalStatus}
                            />
                          </AddRemoveTab>

                          <AddRemoveTab label="Offline settings">
                            <OfflineSettings
                              folderDetails={folderDetails}
                              getFolderDetails={getFolderDetails}
                              handleModalStatus={setModalStatus}
                            />
                          </AddRemoveTab> */}
                        </>
                      )}

                      {openTab === 2 && <AIConfig />}

                      {openTab === 3 && <Knowledge />}

                      {openTab === 4 && (
                        <ListBotFlow
                          folderDetails={folderDetails}
                          getFolderDetails={getFolderDetails}
                          handleModalStatus={setModalStatus}
                        />
                      )}
                    </div>
                  </div>

                  {embedModal && (
                    <div
                      id="defaultModal"
                      tabIndex={-1}
                      className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
                    >
                      <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
                        <div className="relative dialog-content bg-white rounded-lg shadow">
                          <div className="relative text-center flex items-start justify-between p-4 rounded-t">
                            <div>
                              <h3 className="text-xl font-semibold text-gray-900  ">
                                Implement Chatbot In your website
                              </h3>

                              <p className="mt-5">
                                <label
                                  htmlFor="email"
                                  className="relative text-gray-400 focus-within:text-gray-600 block"
                                >
                                  {/* <pre
                                    className="text-left text-gray-800 mb-4 pr-2 pt-2 pb-2 pl-2 input form-input w-full border"
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {embedUrl}
                                  </pre> */}
                                  <SyntaxHighlighter
                                    language="javascript"
                                    style={vscDarkPlus}
                                    wrapLongLines={true}
                                    customStyle={{
                                      whiteSpace: "pre-wrap",
                                      wordBreak: "break-all",
                                      cursor: "text",
                                    }}
                                  >
                                    {embedUrl}
                                  </SyntaxHighlighter>

                                  <button
                                    type="button"
                                    data-copy-state="copy"
                                    className="copyInput flex items-center text-xs font-medium text-gray-600 bg-gray-100 rounded-none border-gray-200  hover:text-blue-700 copy-to-clipboard-button"
                                    onClick={() => {
                                      navigator.clipboard.writeText(embedUrl);
                                      setShowToast(true);
                                      setToastMessage({
                                        type: "success",
                                        message: "Copied code to clipboard",
                                      });
                                    }}
                                  >
                                    <svg
                                      className="w-4 h-4"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                                      />
                                    </svg>
                                  </button>
                                </label>
                                Implement Chatbot in your website, paste the
                                above code in end of the body tag
                              </p>
                            </div>
                            <button
                              type="button"
                              className="absolute right-5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                              data-modal-hide="defaultModal"
                            >
                              <svg
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => setEmbedModal(false)}
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="sr-only">Close modal</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="h-full flex flex-col items-center justify-center">
                  <div className="text-center">
                    <img
                      src={require("../../assets/images/welcome.png")}
                      alt="Welcome"
                      className="mx-auto mb-8"
                    />
                    <h3 className="mb-2">Chatbot Not Found</h3>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainChatBot;
