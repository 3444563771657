import { useCallback, useContext, useEffect, useState } from "react";
import FilterUsers from "./FilterUsers";
import moment from "moment";
import InputWrapper from "../../../common/InputWrapper";
import DisplayMails from "../DisplayMails";
import ChooseTemplate from "../ChooseTemplate";
import PreviewMail from "./PreviewMail";
import { AppContext } from "../../../../App";
import useMailTemplate from "../useMailTemplate";
import useApiData from "../../../../hooks/useApiData";
import Loading from "../../../loaders/Loading";
import { getDateOnly } from "../../../common/commonHelpers";

const firstDayOfWeek = moment().startOf("week");
const lastDayOfWeek = moment().endOf("week");
const firstday = new Date(firstDayOfWeek.format("YYYY-MM-DD"));
const lastday = new Date(lastDayOfWeek.format("YYYY-MM-DD"));

function BulkMail({ customFields, step, onNext, onBack, onClose }) {
  const [dateRange, setDateRange] = useState(null);
  const [customFieldForm, setCustomFieldForm] = useState([]);
  const [template, setTemplate] = useState(null);
  const [error, setError] = useState({});
  console.log("customFieldForm", dateRange);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const { currCommunityId, onSuccess, onError } = useContext(AppContext);

  const { getTemplates, sendMailTemplate, getRecipients } = useMailTemplate();

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => {},
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to get templates");
    },
  });

  const getTemplatesData = useCallback(() => {
    mutate(getTemplates({ community_id: currCommunityId }));
  }, [currCommunityId, getTemplates, mutate]);

  const { mutate: sendMailApi, loading: isSending } = useApiData({
    onSuccess: (res) => {
      onSuccess("Email send successfully");
      onClose();
    },
    onError: (err) => {
      const { message } = err?.response?.data || {};

      onError(message || "Unable to send Email");
    },
  });

  const { mutate: getRecipientsMutate, loading: isGettingUsers } = useApiData({
    onSuccess: (res) => {
      const { status, message, data } = res?.data;

      if (status === "success") {
        if (!data?.length) {
          onError("Users not found");
          return;
        }

        const users = data || [];

        setSelectedUsers(users.map((u) => u.client_email));
        onNext();
      } else {
        onError(message || "Users not found");
      }
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Users not found");
    },
  });

  useEffect(() => {
    getTemplatesData();
  }, []);

  const templates = data || [];

  const isGettingTemplates = step === 3 && loading;

  const isNextBtnDisabled = isGettingTemplates || isSending || isGettingUsers;

  const isNextLoading = isSending || isGettingUsers;

  return (
    <div className="py-4">
      {step === 1 ? (
        <FilterUsers
          customFields={customFields}
          dateRange={dateRange}
          setDateRange={setDateRange}
          customFieldForm={customFieldForm}
          setCustomFieldForm={setCustomFieldForm}
        />
      ) : null}

      {step === 2 ? (
        <InputWrapper label="Bcc">
          <DisplayMails
            to={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </InputWrapper>
      ) : null}

      {step === 3 ? (
        <ChooseTemplate
          templateId={template?.id}
          onSelect={(template) => setTemplate(template)}
          templates={templates}
          loading={loading}
        />
      ) : null}

      {step === 4 ? (
        <PreviewMail
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          template={template || {}}
        />
      ) : null}

      {error?.error ? (
        <div className="text-red-600 my-2">{error?.error}</div>
      ) : null}

      <div className="flex justify-center items-center space-x-2 mt-8">
        {step === 1 ? null : (
          <button
            className="px-4 py-2 rounded-md border border-indigo-600 text-indigo-600 hover:bg-indigo-50"
            onClick={onBack}
          >
            Back
          </button>
        )}

        <button
          className={`flex items-center px-4 py-2 rounded-md bg-indigo-600 text-white ${
            isNextBtnDisabled ? "cursor-not-allowed opacity-70" : ""
          }`}
          onClick={() => {
            if (step === 1) {
              const refactor = {};

              if (dateRange?.length) {
                const { startDate, endDate } = dateRange[0] || {};

                refactor.from_date = getDateOnly(startDate);
                refactor.to_date = getDateOnly(endDate);
              }

              const customFields = [];

              customFieldForm.forEach((c) => {
                const { customfield_values } = c;

                if (
                  typeof customfield_values === "string" &&
                  customfield_values
                ) {
                  customFields.push(c);
                } else if (customfield_values?.length) {
                  customFields.push(c);
                }
              });

              if (customFields?.length) {
                refactor.custom_field = customFields;
              }

              if (!dateRange && !customFields?.length) {
                setError({ error: "Please select atleast one filter" });
                return;
              }

              setError({});

              getRecipientsMutate(getRecipients(refactor));

              return;
            }

            if (step === 3 && !template) {
              setError({ error: "Please select the template" });
              return;
            }

            if (step === 4) {
              sendMailApi(
                sendMailTemplate({
                  community_id: currCommunityId,
                  templateid: template.id,
                  email: selectedUsers,
                })
              );

              return;
            }

            setError({});
            onNext();
          }}
          disabled={isNextBtnDisabled}
        >
          {isNextLoading ? <Loading /> : null}

          {step === 4 ? "Send Email" : "Next"}
        </button>
      </div>
    </div>
  );
}

export default BulkMail;
