import { useContext, useEffect, useMemo, useState } from "react";
import FilterButton, {
  SelectFilterOptionCheckbox,
  SelectFilterOptionRadio,
} from "../common/FilterButton";
import { AppContext } from "../../App";
import useApiData from "../../hooks/useApiData";
import usePortals from "../../containers/settings/portals/usePortals";
import Loading from "../loaders/Loading";
import useTicket from "./useTicket";
import Select from "react-select";
import DependencyDropdown from "./DependencyDropdown";
import api from "../../api/api";

function getRadioOptions(options) {
  return [...options, { label: "Clear", value: "" }];
}

const statusOptions = [
  { value: "open", label: "Open" },
  { value: "inprogress", label: "Inprogress" },
  { value: "hold", label: "Hold" },
  { value: "completed", label: "Completed" },
];

const priorityOptions = [
  { value: "p1", label: "P1" },
  { value: "p2", label: "P2" },
  { value: "p3", label: "P3" },
  { value: "p4", label: "P4" },
];

export function PriorityFilter({ sortTicket, selectedPriority }) {
  const [open, setOpen] = useState(false);

  return (
    <FilterButton
      label={"Priority"}
      selected={
        selectedPriority
          ? [{ label: selectedPriority, value: selectedPriority }]
          : null
      }
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          priority: "",
        });
      }}
    >
      {getRadioOptions(priorityOptions)?.map((priority, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={priority.value || "none"}
            label={priority.label}
            handleChange={(e) => {
              sortTicket({
                priority: priority?.value,
              });
            }}
            isChecked={selectedPriority === priority.value}
          />
        );
      })}
    </FilterButton>
  );
}

export function AssigneeFilter({ assignee, sortTicket }) {
  const { currUser } = useContext(AppContext);

  const assigneeOptions = currUser?.currCommunity?.members?.map(
    (data, index) => ({
      label: data.username,
      value: data.id?.toString(),
    })
  );

  const defaultAssignee = assignee?.split(",");

  const selectedAssignees = currUser?.currCommunity?.members
    ?.filter((item) => defaultAssignee?.includes(item.id.toString()))
    .map((item) => ({ value: item.id.toString(), label: item.username }));

  const [open, setOpen] = useState(false);

  return (
    <FilterButton
      label={"Assignee"}
      pluralLabel="Assignee's"
      selected={selectedAssignees}
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          assignee: "",
        });
      }}
    >
      {assigneeOptions?.map((assignee, i) => {
        return (
          <SelectFilterOptionCheckbox
            key={i}
            id={assignee.value}
            label={assignee.label}
            handleCheckboxChange={(e) => {
              let assignees = selectedAssignees || [];

              if (e.target.checked) {
                assignees.push(assignee);
              } else {
                assignees = assignees.filter((a) => a.value !== assignee.value);
              }

              sortTicket({
                assignee: assignees.map((option) => option.value).join(","),
              });
            }}
            isChecked={
              selectedAssignees.find((a) => a.value === assignee.value)
                ? true
                : false
            }
          />
        );
      })}
    </FilterButton>
  );
}

export function StatusFilter({ sortTicket, selectedStatus }) {
  const [open, setOpen] = useState(false);

  return (
    <FilterButton
      label={"Status"}
      selected={
        selectedStatus
          ? [{ label: selectedStatus, value: selectedStatus }]
          : null
      }
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          status: "",
        });
      }}
    >
      {getRadioOptions(statusOptions)?.map((status, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={status.value || "none"}
            label={status.label}
            handleChange={(e) => {
              sortTicket({
                status: status?.value,
              });
            }}
            isChecked={selectedStatus === status.value}
          />
        );
      })}
    </FilterButton>
  );
}

export function PortalFilter({ sortTicket, selectedPortal }) {
  const { onError } = useContext(AppContext);

  const { getPortals } = usePortals();

  const [open, setOpen] = useState(false);

  const {
    data: portalData,
    mutate: getPortalsData,
    loading: isPortalsLoading,
  } = useApiData({
    onSuccess: (response) => {},
    onError: () => onError("Something went wrong while getting portals"),
  });

  useEffect(() => {
    getPortalsData(getPortals());
  }, []);

  const portalOptions = portalData
    ? portalData?.data?.map((portal) => ({
        value: portal.id,
        label: portal.name,
      }))
    : [];

  if (isPortalsLoading || !portalOptions?.length) {
    return;
  }

  const selectedValue = portalOptions.find((c) => c.value === selectedPortal);

  return (
    <FilterButton
      label={"Portal"}
      selected={selectedValue ? [selectedValue] : null}
      open={open}
      setOpen={setOpen}
      onClear={(e) => {
        sortTicket({
          portal_id: "",
        });
      }}
    >
      {getRadioOptions(portalOptions)?.map((portal, i) => {
        return (
          <SelectFilterOptionRadio
            key={i}
            id={portal.value || "none"}
            label={portal.label}
            handleChange={(e) => {
              sortTicket({
                portal_id: portal?.value,
              });
            }}
            isChecked={selectedPortal === portal.value}
          />
        );
      })}
    </FilterButton>
  );
}

export function ClientFilter({ client, sortTicket }) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [clientOptions, setClientOptions] = useState();

  const { getClients } = useTicket();

  const searchClient = (value) => {
    setSearchValue(value);
    onClientSearch(value);
  };

  const { mutate: getClientsData, loading: isClientLoading } = useApiData({
    onSuccess: (res) => {
      const clientOptions = res?.data?.data?.map((data, index) => ({
        label: data.client_name,
        value: data.client_id,
      }));
      setClientOptions(clientOptions);
    },
    onError: () => {},
  });

  const onClientSearch = (value) => {
    getClientsData(getClients({ search: value }));
  };

  useEffect(() => {
    onClientSearch(searchValue);
  }, []);

  const defaultClient = client?.split(",");

  const selectedClient = clientOptions
    ?.filter((item) => defaultClient?.includes(item.value))
    .map((item) => ({ value: item.value, label: item.label }));

  return (
    <FilterButton
      label={"Client"}
      pluralLabel="Client's"
      selected={selectedClient}
      open={open}
      setOpen={setOpen}
      showPluralLabel={true}
      onClear={(e) => {
        sortTicket({
          client: "",
        });
      }}
    >
      <span className="p-2 w-full input-wrapper ">
        <div className="input-suffix-start">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={0}
            viewBox="0 0 24 24"
            className="text-lg"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <input
          className="input search-new-chatbot input-sm h-8  focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 w-full"
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={(e) => searchClient(e.target.value)}
        ></input>
      </span>
      {!isClientLoading ? (
        clientOptions?.map((client, i) => {
          return (
            <SelectFilterOptionCheckbox
              key={i}
              id={client.value}
              label={client.label}
              handleCheckboxChange={(e) => {
                let clients = selectedClient || [];

                if (e.target.checked) {
                  clients.push(client);
                } else {
                  clients = clients.filter((a) => a.value !== client.value);
                }

                sortTicket({
                  client: clients.map((option) => option.value).join(","),
                });
              }}
              isChecked={
                selectedClient?.find((a) => a.value === client.value)
                  ? true
                  : false
              }
            />
          );
        })
      ) : (
        <div className="w-[200px] flex justify-center items-center">
          <Loading />
        </div>
      )}
    </FilterButton>
  );
}

function displayAdditionalFields(fieldsData, commnuityFields) {
  if (!Object.keys(fieldsData)?.length) {
    return null;
  }

  const displayData = [];

  Object.entries(fieldsData).forEach(([key, value]) => {
    const { field_name } =
      commnuityFields.find((c) => c.id.toString() === key) || {};

    if (typeof value === "string") {
      // for input and select field

      const display = `${field_name}: ${value}`;

      displayData.push({ label: display, value: display });
    } else {
      // for dependency dropdown

      let display = [];

      Object.entries(value).forEach(([levelKey, levelValue]) => {
        const refactor = `${field_name[levelKey]}: ${levelValue}`;

        display.push(refactor);
      });

      const displayDataRefactor = display.join(", ");

      displayData.push({
        label: displayDataRefactor,
        value: displayDataRefactor,
      });
    }
  });

  return displayData;
}

export function AdditionalFieldsFilter({ sortTicket, customFields }) {
  const { currCommunityId } = useContext(AppContext);

  const { data, mutate } = useApiData({});

  useEffect(() => {
    mutate(() =>
      api.post("/api/getCommunityFields", { community_id: currCommunityId })
    );
  }, []);

  const commnuityFields = data?.data || [];

  const [open, setOpen] = useState(false);

  const commnuityFieldsData = useMemo(() => {
    return customFields ? JSON.parse(customFields) : {};
  }, [customFields]);

  // const { data, mutate, loading } = useApiData({
  //   onError: (error) =>
  //     onError(
  //       error?.response?.data?.message ||
  //         "Something went wrong while getting community fields"
  //     ),
  // });

  // useEffect(() => {
  //   mutate(() =>
  //     api.post("/api/getCommunityFields", { community_id: currCommunityId })
  //   );
  // }, []);

  // const commnuityFields = data?.data || [];

  function updateCommnuityFieldsData(id, data, field) {
    const prevData = { ...commnuityFieldsData };

    if (data) {
      prevData[id] = data;
    } else {
      delete prevData[id];
    }

    const customFieldsData = Object.keys(prevData)?.length
      ? JSON.stringify(prevData)
      : null;

    sortTicket({
      customFields: customFieldsData,
    });
  }

  // if (loading) {
  //   return <Loading />;
  // }

  if (!commnuityFields?.length) {
    return;
  }

  return (
    <FilterButton
      label="Additional Fields"
      pluralLabel="Fields"
      selected={displayAdditionalFields(commnuityFieldsData, commnuityFields)}
      open={open}
      setOpen={setOpen}
      noScroll={true}
      childrenCls="min-w-[350px]"
      position="right-0"
      dontCloseOnReactSelect={true}
      onClear={(e) => {
        sortTicket({
          customFields: "",
        });
      }}
    >
      <div className="grid grid-cols-2 gap-4 p-4">
        {commnuityFields.map((c, i) => {
          return (
            <AdditionalFieldsForm
              key={i}
              field={c}
              updateCommnuityFieldsData={updateCommnuityFieldsData}
              commnuityFieldsData={commnuityFieldsData}
            />
          );
        })}
      </div>
    </FilterButton>
  );
}

function AdditionalFieldsForm({
  field,
  commnuityFieldsData,
  updateCommnuityFieldsData,
}) {
  const { field_name, field_type, field_values, id } = field;

  const value = commnuityFieldsData[id];

  if (field_type === "input") {
    return (
      <Input
        label={field_name}
        onChange={(e) => updateCommnuityFieldsData(id, e.target.value, field)}
        value={value}
      />
    );
  }

  if (field_type === "select") {
    const options = field_values?.map((f) => ({ label: f, value: f }));

    return (
      <div className="">
        <div className="mb-2 font-semibold text-gray-700">{field_name}</div>

        <Select
          options={options}
          isClearable
          value={value ? { label: value, value: value } : null}
          onChange={(value) => {
            updateCommnuityFieldsData(id, value?.value, field);
          }}
        />
      </div>
    );
  }

  if (field_type === "dependency_dropdown") {
    return (
      <DependencyDropdown
        field={field}
        selectedValues={value}
        handleChange={(val) => {
          const value = Object.keys(val).length ? val : null;

          updateCommnuityFieldsData(id, value, field);
        }}
        fromApprovalPage={true}
      />
    );
  }

  return "Unknown Type";
}

function Input(props) {
  const { label, disabled, value, onChange = () => {}, required } = props || {};

  return (
    <div className="">
      <div className="mb-2 font-semibold text-gray-700">{label}</div>

      <input
        type="text"
        className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 ${
          disabled ? "cursor-not-allowed bg-gray-200" : ""
        }`}
        disabled={disabled}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  );
}
