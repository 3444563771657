import { useState } from "react";
import TicketAdditionalFieldsContainer from "./TicketAdditionalFieldsContainer";
import ManageApproval from "./ManageApproval";
import DropDown from "../common/DropDown";
import SLAModal from "./SLAModal";
import NotificationModal from "./NotificationModal";
import AutoAssign from "./AutoAssign";

function TicketSettings({ onClosingAdditionalFields }) {
  const [openSettings, setOpenSettings] = useState(false);

  const [modal, setModal] = useState();

  return (
    <div className="relative">
      <DropDown
        label={
          <div className="cursor-pointer py-2 px-2 hover:bg-gray-200 rounded-md">
            <img src="/images/icons/more.svg" alt="+" className="w-4" />
          </div>
        }
        childrenCls="mt-5"
      >
        <div
          className="flex items-center space-x-4 cursor-pointer hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round px-4 py-3 text-sm rounded-md whitespace-nowrap"
          onClick={() => setModal("fields")}
        >
          <i className="fa-solid fa-bars-progress"></i>
          <div>Manage Fields</div>
        </div>

        <div
          className="flex items-center space-x-4 cursor-pointer hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round px-4 py-3 text-sm rounded-md whitespace-nowrap border-t"
          onClick={() => setModal("approval")}
        >
          <i className="fa-solid fa-person-circle-check"></i>
          <div>Manage Approvals</div>
        </div>

        <div
          className="flex items-center space-x-4 cursor-pointer hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round px-4 py-3 text-sm rounded-md whitespace-nowrap border-t"
          onClick={() => setModal("autoAssign")}
        >
          <i className="fa-solid fa-user-plus"></i>
          <div>Auto Assign</div>
        </div>

        <div
          className="flex items-center space-x-4 cursor-pointer hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round px-4 py-3 text-sm rounded-md whitespace-nowrap border-t"
          onClick={() => setModal("sla")}
        >
          <i className="fa-solid fa-stopwatch"></i>
          <div>SLA Settings</div>
        </div>

        <div
          className="flex items-center space-x-4 cursor-pointer hover:bg-indigo-100 active:bg-indigo-200 text-indigo-600 radius-round px-4 py-3 text-sm rounded-md whitespace-nowrap border-t"
          onClick={() => setModal("notification")}
        >
          <i className="fa-solid fa-bell"></i>
          <div>Notification Settings</div>
        </div>
      </DropDown>

      {modal === "fields" && (
        <TicketAdditionalFieldsContainer
          modal={modal}
          setModal={setModal}
          onClosingAdditionalFields={onClosingAdditionalFields}
        />
      )}

      {modal === "approval" && (
        <ManageApproval modal={modal} setModal={setModal} />
      )}

      {modal === "sla" && <SLAModal modal={modal} setModal={setModal} />}

      {modal === "notification" && (
        <NotificationModal modal={modal} setModal={setModal} />
      )}

      {modal === "autoAssign" && (
        <AutoAssign modal={modal} setModal={setModal} />
      )}
    </div>
  );
}

export default TicketSettings;
