import { useContext, useEffect, useState } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import { useParams } from "react-router-dom";
import Spinner from "../loaders/Spinner";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const CommonFooterText = () => {
  const [loader, setLoader] = useState(false);
  const [mailTemplate, setMailTemplate] = useState([]);
  const [body, setBody] = useState("");
  const [processing, setProcessing] = useState(false);
  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);
  const { id } = useParams();
  var formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula",
    // 'image'
    // 'video'
  ];
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline"],
        ["code-block"],
      ],
    },
    placeholder: "Compose an epic...",
    theme: "snow", // or 'bubble'
    formats: formats,
  });

  useEffect(() => {
    getMailTemplate();
  }, []);

  useEffect(() => {
    if (quill) {
      if (mailTemplate.body != undefined && mailTemplate.body != null) {
        quill.clipboard.dangerouslyPasteHTML(mailTemplate?.body);
      }
      quill.on("text-change", (delta, oldDelta, source) => {
        setBody(quill.root.innerHTML);
      });
    }
  }, [quill, mailTemplate]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setMailTemplate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getMailTemplate = () => {
    var postData = {
      name: "common_footer_message",
      community_id: id,
    };
    setLoader(true);
    api
      .post("/api/getEmailTemplates", postData)
      .then((response) => {
        setLoader(false);
        if (response.data.data != null) {
          setMailTemplate(response.data.data);
        }
      })
      .catch((error) => {
        setLoader(false);
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      });
  };

  const updateMailTemplate = (e) => {
    e.preventDefault();
    var postData = {
      name: "common_footer_message",
      community_id: id,
      subject: mailTemplate.subject,
      body: body,
    };
    setProcessing(true);
    api
      .post("/api/addOrUpdateMailTemplate", postData)
      .then((response) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Email Template Saved Successfully",
        });
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      });
  };

  return (
    <>
      {loader ? (
        <div className="flex items-center justify-center h-[50vh]">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={(e) => updateMailTemplate(e)}>
          <div className="form-item vertical invalid">
            <label className="form-label mb-2">Body</label>
            <div>
              {/* <textarea
                className=" input-textarea input-form input input-invalid input-textarea"
                type="text"
                name="body"
                autoComplete="off"
                placeholder="Enter Body"
                spellcheck="false"
                value={mailTemplate.body}
                onChange={(e) => handleChange(e)}
              ></textarea> */}
              <div ref={quillRef} />
            </div>
            <button
              className="mt-5 inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
              type="submit"
              disabled={processing}
            >
              {processing && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Save
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default CommonFooterText;
