import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "../../../App";
import useApiData from "../../../hooks/useApiData";
import Loading from "../../loaders/Loading";
import useMailTemplate from "./useMailTemplate";
import TemplateCard from "./TemplateCard";
import { useNavigate } from "react-router-dom";

function ChooseTemplate({ templateId, onSelect, templates, loading }) {
  const navigate = useNavigate();

  return (
    <div className="grid md:grid-cols-2 gap-6">
      {loading ? (
        <Loading />
      ) : (
        <>
          {templates?.length ? (
            templates.map((template, i) => {
              return (
                <TemplateCard
                  template={template}
                  isActive={templateId === template.id}
                  key={i}
                  onSelect={() => onSelect(template)}
                />
              );
            })
          ) : (
            <div>
              Templates not found. <br />
              Please{" "}
              <span
                className="text-blue-600 underline cursor-pointer"
                onClick={() => navigate("/customer-management/email-templates")}
              >
                click here
              </span>{" "}
              to create a template
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ChooseTemplate;
