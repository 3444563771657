import { useEffect, useState } from "react";
import Loading from "../../loaders/Loading";

function MembersForm({
  data = {},
  loading,
  buttonText,
  onSearch = () => {},
  searchData,
  isSearchLoading,
  onAddUser = () => {},
  onRemoveUser = () => {},
  isAddUserLoading,
  isRemoveUserLoading,
}) {
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (search.length > 0) {
      onSearch(search);
    } else {
      setSearch("");
      searchData = [];
    }
  }, [search]);
  useEffect(() => {
    setSearch("");
    searchData = [];
  }, [data]);
  return (
    <div className="mt-4">
      <div className="relative">
        <input
          className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !normal-case"
          type="text"
          name="name"
          autoComplete="off"
          placeholder="Search Member name/email to find"
          required
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <svg
          stroke="currentColor"
          fill="none"
          stroke-width="0"
          viewBox="0 0 24 24"
          className="text-xl absolute top-[12px] right-[15px] text-[#9b9a9a]"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
        {search && (
          <div className="p-3 max-h-[160px] overflow-x-auto communityMembers bg-white mt-3 absolute  shadow-xl w-full">
            {isSearchLoading || isAddUserLoading ? (
              <Loading />
            ) : searchData?.data?.length > 0 ? (
              searchData?.data?.map((member, index) => (
                <div
                  key={index}
                  className="py-1 px-1 hover:bg-[#f5f5f5] border-b flex items-center gap-3 justify-between  communityMember cursor-pointer"
                  onClick={() =>
                    onAddUser({
                      team_id: data.team_id,
                      user_id: member.id,
                    })
                  }
                >
                  <div className="flex items-center gap-3">
                    <div className="rounded-full flex items-center justify-center w-[30px] h-[30px] bg-gray-200">
                      <i className="fa-solid fa-user text-md"></i>
                    </div>
                    <div>
                      <div className="text-sm font-bold">{member.username}</div>
                      <div className="text-sm">{member.email}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              "No Person Found"
            )}
          </div>
        )}
      </div>
      <div>
        <label className="mt-4 flex items-center text-md mt-3 font-semibold text-gray-700">
          Existing Members
        </label>
        {data.users?.length > 0 ? (
          <div className="members">
            {loading ? (
              <Loading />
            ) : (
              data.users?.map((user, key) => (
                <div
                  key={key}
                  className="py-5 border-b flex items-center gap-3 justify-between  communityMember"
                >
                  <div className="flex items-center gap-3">
                    <div
                      className={`rounded-full flex items-center justify-center w-[35px] h-[35px] ${
                        !user?.image && "bg-gray-200"
                      }`}
                    >
                      {user?.image ? (
                        <img src={user?.image}></img>
                      ) : (
                        <i className="fa-solid fa-user text-xl"></i>
                      )}
                    </div>

                    <div>
                      <div className="text-md font-bold">{user.username}</div>
                      <div>{user.email}</div>
                    </div>
                  </div>
                  <div className=" flex items-center gap-2">
                    <button
                      className="bg-red-50 text-red-500 py-2 px-3 rounded hover:bg-red-500 hover:text-white font-semibold duration-300 ease flex gap-1"
                      onClick={() =>
                        onRemoveUser({
                          team_id: data.team_id,
                          user_id: user.id,
                        })
                      }
                      disabled={isRemoveUserLoading}
                    >
                      {isRemoveUserLoading && <Loading />}
                      Remove
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        ) : (
          <div className="mt-2">No members in this team</div>
        )}
      </div>
    </div>
  );
}

export default MembersForm;
