function InputWrapper({ isRequired = false, children, label, error }) {
  return (
    <div className="w-full mt-4">
      <div
        className={`font-semibold text-gray-500 ${
          isRequired ? "required-input" : ""
        }`}
      >
        {label}
      </div>
      <div className="my-1.5 w-full">{children}</div>
      {error ? <div className="text-red-600">{error}</div> : null}
    </div>
  );
}

export default InputWrapper;
