import { useContext, useState } from "react";
import { AppContext } from "../../App";

const AutoAssign = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setInviteModal,
    inviteModal,
    setHasChanged,
    handleLinkClick,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  return (
    <div className="px-2  py-3">
      <div className="communityMembers">
        <div className="font-medium pb-8">
          Can we add a short description here to explain to new users what
          levels we’re refering to and what they do?
        </div>

        <form className="" onSubmit={(e) => props.formSubmit(e, "autoAssign")}>
          <div className="grid grid-cols-2 gap-8">
            {Object.keys(props.autoAssignForm).map((key, index) => (
              <div>
                <div className="font-medium mb-1">Level {index + 1}</div>

                <div className="flex">
                  <input
                    name={key}
                    className="border w-full p-3 rounded-md"
                    type="number"
                    placeholder="How many Members"
                    value={props.autoAssignForm[key]}
                    onChange={(e) => props.handleLevel(e)}
                  />
                  {index > 1 && (
                    <button
                      type="button"
                      onClick={() => props.handleRemove(index)}
                      className="pl-2"
                    >
                      <img src="/images/icons/delete-red.svg" alt="delete" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

          <button
            className="px-4 py-2 border border-gray-300 rounded-md flex items-center space-x-2 text-indigo-600 mt-6"
            onClick={() => props.handleAddMore()}
            type="button"
          >
            <img src="/images/icons/add-circle-blue.svg" alt="add" />
            <div>Add a Level</div>
          </button>

          <div className="flex items-center justify-end space-x-4 mt-6">
            <button
              type="button"
              className="px-4 py-3 border border-gray-300 rounded-md text-indigo-600 font-medium"
              onClick={() => props.refetch()}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="flex px-4 py-3 bg-indigo-600 rounded-md text-white font-medium"
              disabled={processing}
            >
              {processing && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AutoAssign;
