import { useCallback, useContext, useState } from "react";
import { isQuillHavingText } from "../../common/commonHelpers";
import InputWrapper from "../../common/InputWrapper";
import ReactQuill from "react-quill";
import PrimaryButton from "../../common/PrimaryButton";
import useApiData from "../../../hooks/useApiData";
import { AppContext } from "../../../App";
import useMailTemplate from "../mail/useMailTemplate";

const formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "image",
  // 'video'
];

const contentModules = {
  toolbar: [["bold", "italic", "underline", "code-block", "image"]],
};

function formatContent(user, content) {
  const { name, email, message } = user || {};

  const appendContent = ` <p><strong>Name:</strong> ${name}</p><p><strong>Email:</strong> ${email}</p><p><strong>Message:</strong> ${message}</p><p></p><p>---------------------------------------------------</p><p></p><p>${content}</p>`;

  return appendContent;
}

function MailForm({ user, onClose }) {
  const { onSuccess, onError } = useContext(AppContext);

  const [form, setForm] = useState({});

  const updateForm = useCallback((update) => {
    setForm((prev) => ({ ...prev, ...update }));
  }, []);

  const [error, setError] = useState({
    subject: "",
    content: "",
  });

  const { subject, content } = form;

  const { name, email, message } = user || {};

  const { sendMailForOfflineUser } = useMailTemplate();

  const { mutate: sendMailApi, loading: isSending } = useApiData({
    onSuccess: (res) => {
      const { message } = res?.data || {};

      onSuccess(message || "Email send successfully");
      onClose();
    },
    onError: (err) => {
      const { message } = err?.response?.data || {};

      onError(message || "Unable to send Email");
    },
  });

  return (
    <div className="my-1 min-w-[480px]">
      <form
        className="flex flex-col space-y-4"
        onSubmit={(e) => {
          e.preventDefault();

          const err = {};

          if (!subject) {
            err.subject = "Subject is required";
          }

          if (!isQuillHavingText(content)) {
            err.content = "Content is required";
          }

          if (Object.keys(err)?.length) {
            setError(err);
            return;
          }

          setError({ subject: "", content: "" });

          const refactor = {
            subject,
            content: formatContent(user, content),
            to: email,
          };

          //   updateForm({ content: refactor.content });

          sendMailApi(sendMailForOfflineUser(refactor));
        }}
      >
        <InputWrapper label="Subject" error={error.subject}>
          <input
            type="text"
            placeholder="Subject"
            className="border border-gray-300 px-2 py-2 w-full rounded-md outline-none focus:ring-1 focus:ring-blue-400"
            onChange={(e) => updateForm({ subject: e.target.value })}
            value={subject}
          />
        </InputWrapper>

        <InputWrapper label="Content" error={error.content}>
          <div className="mb-4 mt-2 font-semibold text-gray-700">
            <div>
              Name: <span className="font-normal">{name}</span>
            </div>
            <div>
              Email: <span className="font-normal">{email}</span>
            </div>
            <div>
              Message: <span className="font-normal">{message}</span>
            </div>
          </div>

          <ReactQuill
            theme="snow"
            value={content}
            onChange={(value) => {
              console.log("value", value);
              updateForm({ content: value });
            }}
            //   className={`bg-white ${isMsgError ? "border-red-600" : ""} ${
            //     isActive.current ? "" : "hidden"
            //   }`}
            //   modules={modules}
            modules={contentModules}
            formats={formats}
            placeholder="Enter your content here..."
          />
        </InputWrapper>

        <div className="flex justify-center">
          <PrimaryButton label={"Send Email"} loading={isSending} />
        </div>
      </form>
    </div>
  );
}

export default MailForm;
