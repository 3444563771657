import { useState, useContext } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { AppContext } from "../../App";
import api from "../../api/api";

const ModuleForm = (props) => {
  const [formValues, setFormValues] = useState({});
  const [response, setResponse] = useState("");
  const [processing, setProcessing] = useState(false);
  const { currUser, getUserInfo, setToastMessage, setShowToast } =
    useContext(AppContext);

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    let newValue;

    // Handle multi-select fields
    if (field.type === "multiselect") {
      const { name, options } = e.target;
      const selectedOptions = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedOptions.push(options[i].value);
        }
      }
      newValue = selectedOptions;
    } else {
      // handle other input types
      newValue = value;
    }

    setFormValues((prev) => ({
      ...prev,
      [field.label]: newValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const promptContent = generatePromptContent(formValues, props.fieldValue);
  };

  const generatePromptContent = (formValues, fields) => {
    let promptContent = props.userPromptValue;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const fieldValue = formValues[field.label] || "";
      promptContent = promptContent.replace(
        new RegExp(`{${field.label}}`, "g"),
        fieldValue
      );

      // console.log("sdfsdf", field.label, fieldValue);
    }
    setProcessing(true);
    var postData = {
      prompt: promptContent,
      id: props.folderDetails[0].uuid,
    };
    api
      .post("/api/moduleGpt", postData)
      .then((response) => {
        if (response.data.data != null) {
          setProcessing(false);

          if (
            response.data.data.error != undefined &&
            response.data.data.error != null
          ) {
            setShowToast(true);
            setToastMessage({ type: "error", message: "Invaild Gpt Key" });
          } else {
            setResponse(response.data.data.choices[0].message.content);
            // console.log("newRes", response);
          }
        } else {
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };
  return (
    <div className="w-full">
      <div className="flex gap-5">
        <div className="w-4/6	block rounded-lg bg-gray-100 p-5 text-center">
          <span className="text-3xl font-bold mb-4 mb-5 block">
            {props.folderDetails[0].name}
          </span>
          {!response && <span>Fill the form to generate your itinerary</span>}
          <div className="ResponseContainer ">
            {response && (
              <div className="ResponseContainer">
                <h3 className="ResponseTitle">Your results is ready.</h3>
                <p className="ResponseText">
                  <ReactMarkdown>{response}</ReactMarkdown>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="w-2/5	">
          {/* {JSON.stringify(props)} */}

          <h1 className="text-3xl font-bold mb-4">{props.fieldValue.name}</h1>
          <form onSubmit={handleSubmit}>
            {props.fieldValue.map((field) => (
              <div key={field.id} className="mb-4">
                <label className="font-bold mb-2 block" htmlFor={field.label}>
                  {field.label}
                </label>
                {field.type === "text" && (
                  <input
                    type="text"
                    name={field.label}
                    id={field.label}
                    className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    onChange={(e) => handleInputChange(e, field)}
                  />
                )}
                {field.type === "textarea" && (
                  <textarea
                    name={field.label}
                    id={field.label}
                    className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    onChange={(e) => handleInputChange(e, field)}
                  />
                )}
                {field.type === "dropdown" && (
                  <select
                    name={field.label}
                    id={field.label}
                    className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    onChange={(e) => handleInputChange(e, field)}
                  >
                    <option value="">Select an option</option>
                    {field.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                )}
                {field.type === "multiselect" && (
                  <select
                    name={field.label}
                    id={field.label}
                    className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    onChange={(e) => handleInputChange(e, field)}
                    multiple
                  >
                    {field.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                )}
                {field.type === "date" && (
                  <input
                    type="date"
                    name={field.label}
                    id={field.label}
                    className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    onChange={(e) => handleInputChange(e, field)}
                  />
                )}
              </div>
            ))}
            <button
              type="submit"
              className="mb-4 inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full float-right"
            >
              {processing && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Generate Prompt
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModuleForm;
