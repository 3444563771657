import { useParams } from "react-router-dom";
import Table from "../../../../common/Table";
import { useContext, useEffect, useMemo, useState } from "react";
import useDropDown from "../../../../../hooks/useDropDown";
import Modal from "../../../../common/Modal";
import PropertyNameForm from "./PropertyNameForm";
import DeleteModal from "../../../../common/DeleteModal";
import { AppContext } from "../../../../../App";
import useApiData from "../../../../../hooks/useApiData";
import Spinner from "../../../../loaders/Spinner";
import usePropertyConfiguration from "./usePropertyConfiguration";

const MAP_PROPERTY_TYPE = {
  USER_PROPERTIES: "User Property",
  BOT_PROPERTIES: "Bot Property",
};

function getColumns(props = {}) {
  return [
    {
      name: "Property name",
      renderComp: (data) => data.variableName,
    },
    {
      name: "Save in",
      renderComp: (data) => MAP_PROPERTY_TYPE[data.variableType],
    },
    {
      name: "Actions",
      renderComp: (data) => <Actions data={data} props={props} />,
    },
  ];
}

function PropertyConfigurationList() {
  const [openForm, setOpenForm] = useState({
    open: false,
    type: "",
    data: null,
    propertyType: "",
  });

  function clearForm() {
    setOpenForm({
      open: false,
      type: "",
      data: null,
      propertyType: "",
    });
  }

  const { onSuccess, onError } = useContext(AppContext);

  const { id, botflowId } = useParams();

  const {
    getPropertyConfigurations,
    createPropertyConfiguration,
    updatePropertyConfiguration,
    deletePropertyConfiguration,
  } = usePropertyConfiguration(id, botflowId);

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting properties"),
  });

  const { mutate: create, loading: isCreateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Property Created Successfully");
      clearForm();
      getList(getPropertyConfigurations());
    },
    onError: () => onError("Something went wrong while creating property"),
  });

  const { mutate: update, loading: isUpdateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Property Updated Successfully");
      clearForm();
      getList(getPropertyConfigurations());
    },
    onError: () => onError("Something went wrong while updating property"),
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Property Deleted Successfully");
      clearForm();
      getList(getPropertyConfigurations());
    },
    onError: () => onError("Something went wrong while deleting property"),
  });

  const propertiesList = data?.data || [];

  const columns = useMemo(() => {
    return getColumns({ setOpenForm });
  }, []);

  useEffect(() => {
    getList(getPropertyConfigurations());
  }, []);

  if (loading) {
    return (
      <div className="w-fit mx-auto mt-8">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="text-gray-700">
      <div className="text-3xl font-semibold mb-2">Custom properties</div>
      <div className="text-sm mb-6">
        Provide contextual support by retrieving, storing, and referencing
        information from customer conversations without needing their input.
      </div>
      <div className="flex justify-between items-center w-full">
        <div className="font-semibold">Properties</div>

        <NewPropertyDropdown
          onPropertyTypeSelect={(propertyType) => {
            setOpenForm({
              open: true,
              type: "Create",
              data: null,
              propertyType,
            });
          }}
        />
      </div>

      <Table data={propertiesList} columns={columns} />

      <DeleteModal
        title={"Are you sure, you want to delete this property"}
        open={openForm.open && openForm?.type === "Delete"}
        onClose={clearForm}
        loading={isDeleteLoading}
        onDelete={() => {
          deleteApi(deletePropertyConfiguration(openForm?.data?.uuid));
        }}
      />

      <Modal
        title={`${openForm.type} ${
          MAP_PROPERTY_TYPE[openForm.propertyType]
        } Properties`}
        isOpen={openForm?.open && openForm?.type !== "Delete"}
        onClose={clearForm}
      >
        <PropertyNameForm
          propertyData={openForm?.data}
          onSubmit={(data) => {
            const payload = { ...data, variableType: openForm.propertyType };

            if (openForm?.data) {
              update(
                updatePropertyConfiguration(payload, openForm?.data?.uuid)
              );
            } else {
              create(createPropertyConfiguration(payload));
            }
          }}
          loading={isCreateLoading || isUpdateLoading}
        />
      </Modal>
    </div>
  );
}

export default PropertyConfigurationList;

function NewPropertyDropdown({ onPropertyTypeSelect }) {
  const [open, setOpen] = useState(false);

  const { elementRef } = useDropDown({ open, setOpen });

  return (
    <div className="relative" ref={elementRef}>
      <button
        className="px-4 py-2 bg-indigo-600 text-white text-primary-600 rounded-md hover:bg-blue-50"
        onClick={() => setOpen(!open)}
      >
        New Property
        <i class="fa-solid fa-chevron-down ml-2"></i>
      </button>

      {open ? (
        <div className="absolute top-12 left-0 border bg-white">
          <button
            className="py-2 px-4 hover:bg-gray-100"
            onClick={() => onPropertyTypeSelect("BOT_PROPERTIES")}
          >
            Bot Properties
          </button>
          <button
            className="py-2 px-4 hover:bg-gray-100"
            onClick={() => onPropertyTypeSelect("USER_PROPERTIES")}
          >
            User Properties
          </button>
        </div>
      ) : null}
    </div>
  );
}

function Actions({ data, props }) {
  const { setOpenForm } = props;

  return (
    <div className="flex items-center">
      <button
        className="hover:bg-gray-100"
        onClick={(e) => {
          e.stopPropagation();
          setOpenForm({
            open: true,
            type: "Edit",
            data: data,
            propertyType: data?.variableType,
          });
        }}
      >
        <i className="fa-solid fa-pen-to-square hover:text-blue-600 m-2"></i>
      </button>

      <button
        className="hover:bg-gray-100"
        onClick={(e) => {
          e.stopPropagation();
          setOpenForm({
            open: true,
            type: "Delete",
            data: data,
            propertyType: data?.variableType,
          });
        }}
      >
        <i className="fa-solid fa-trash hover:text-red-600 m-2"></i>
      </button>
    </div>
  );
}
