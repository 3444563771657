import { useCallback, useContext } from "react";
import api from "../../../api/api";
import { AppContext } from "../../../App";

function useKnowledgeBase() {
  const { currCommunityId } = useContext(AppContext);

  const getKnowledgeBatch = useCallback(
    (access_token) => {
      return () =>
        api.get(`/api/getKnowledgeBatch?accessToken=${access_token}`);
    },
    [currCommunityId]
  );

  const getKnowledgeScript = useCallback(
    (access_token) => {
      return () =>
        api.get(`/api/getKnowledgeScript?accessToken=${access_token}`);
    },
    [currCommunityId]
  );

  const getKnowledgeBatchAndScripts = useCallback(
    (access_token, batch_id) => {
      return () =>
        api.get(
          `/api/getKnowledgeBatchAndScripts/${batch_id}?accessToken=${access_token}`
        );
    },
    [currCommunityId]
  );

  const getScriptAndPage = useCallback(
    (access_token, script_id) => {
      return () =>
        api.get(
          `/api/getScriptAndPage/${script_id}?accessToken=${access_token}`
        );
    },
    [currCommunityId]
  );

  const getKnowledgeBaseIntegration = useCallback(
    (chat_bot_id) => {
      return () =>
        api.get(
          `/api/${currCommunityId}/${chat_bot_id}/getKnowledgeBaseIntegration`
        );
    },
    [currCommunityId]
  );

  const manageKnowledgeBaseIntegration = useCallback(
    (chat_bot_id, payload) => {
      return () =>
        api.post(
          `/api/${currCommunityId}/${chat_bot_id}/manageKnowledgeBaseIntegration`,
          payload
        );
    },
    [currCommunityId]
  );

  return {
    getKnowledgeBatch,
    getKnowledgeScript,
    getKnowledgeBatchAndScripts,
    getScriptAndPage,
    getKnowledgeBaseIntegration,
    manageKnowledgeBaseIntegration,
  };
}

export default useKnowledgeBase;
