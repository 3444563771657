import { useParams } from "react-router-dom";
import useApiData from "../../hooks/useApiData";
import Spinner from "../loaders/Spinner";
import ClientCard from "./ClientCard";
import useTicket from "./useTicket";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import Select from "react-select";
import { Calendar } from "react-date-range";
import moment from "moment";
import SchedulerCard from "./SchedulerCard";
import { getTodayDate } from "../../utils/common";

const typeOptions = [
  { label: "Quarterly", value: "quarterly" },
  { label: "Monthly", value: "monthly" },
  { label: "Custom", value: "custom" },
];

const Scheduler = () => {
  const [form, setForm] = useState({
    type: "",
    open: false,
    data: {},
  });
  const { onError, onSuccess } = useContext(AppContext);
  const { id } = useParams();

  const { createSchedule, getSchedule, updateSchedule, deleteSchedule } =
    useTicket();

  const {
    data: schedules,
    mutate: getScheduleMutate,
    loading: isLoading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => onError("Something went wrong while getting Scheduler"),
  });

  const { mutate: createScheduleMutate, loading: isCreateLoading } = useApiData(
    {
      onSuccess: (res) => {
        formClear();
        getScheduleMutate(getSchedule({ ticket_no: id }));
      },
      onError: () => onError("Something went wrong while Creating Scheduler"),
    }
  );

  const { mutate: updateScheduleMutate, loading: isUpdateLoading } = useApiData(
    {
      onSuccess: (res) => {
        formClear();
        getScheduleMutate(getSchedule({ ticket_no: id }));
      },
      onError: () => onError("Something went wrong while Updating Scheduler"),
    }
  );

  const { mutate: removeScheduleMutate, loading: isRemoveLoading } = useApiData(
    {
      onSuccess: (res) => {
        formClear();
        getScheduleMutate(getSchedule({ ticket_no: id }));
      },
      onError: () => onError("Something went wrong while Updating Scheduler"),
    }
  );

  useEffect(() => {
    getScheduleMutate(getSchedule({ ticket_no: id }));
  }, []);

  const formClear = () => {
    setForm({
      type: "",
      open: false,
      data: {},
    });
  };

  const onChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      data: { ...prevState.data, [name]: value },
    }));
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (!form.data.type) {
      onError("Type is required");
      return;
    }
    if (form.type === "Edit") {
      updateScheduleMutate(
        updateSchedule({
          id: form.data.id,
          type: form.data.type,
          date: form.data.date,
        })
      );
    } else if (form.type === "Add") {
      createScheduleMutate(createSchedule({ ticket_no: id, ...form.data }));
    }
  };

  return (
    <div className="border border-gray-300 rounded-md bg-white p-6 mt-6">
      <div className="pb-2 border-b border-gray-300 flex items-center justify-between relative">
        <h4 className="font-semibold text-xl">Scheduler</h4>

        <button
          type="button"
          className="flex duration-300 ease-in text-indigo-600 font-medium text-sm hover:bg-gray-100"
          onClick={() => {
            setForm({
              type: "Add",
              open: true,
              data: {},
            });
          }}
        >
          {!form.open && !isLoading && schedules?.data?.length === 0
            ? "Add Scheduler"
            : "Manage Schedule"}
        </button>
      </div>

      <div className="w-full relative">
        {!form.open && (
          <>
            {!isLoading ? (
              schedules?.data?.length > 0 ? (
                schedules?.data?.map((data) => (
                  <SchedulerCard
                    data={data}
                    onEdit={(data) => {
                      setForm({
                        type: "Edit",
                        open: true,
                        data: data,
                      });
                    }}
                    onRemove={(id) => {
                      removeScheduleMutate(deleteSchedule({ id }));
                    }}
                    isRemoveLoading={isRemoveLoading}
                  />
                ))
              ) : (
                <div className="p-4 text-center font-semibold text-gray-500">
                  Schedule not found
                </div>
              )
            ) : (
              <div className="w-fit mx-auto mt-8">
                <Spinner />
              </div>
            )}
          </>
        )}

        {form.open && form.type !== "Delete" && (
          <div className="flex flex-col h-auto min-h-[7rem]">
            <form action="#" className="" onSubmit={(e) => formSubmit(e)}>
              <div className="px-3">
                <div className="mt-4">
                  <div className="form-container vertical">
                    <div className="form-item  mb-3 vertical ">
                      <label className="form-label mb-2 text-sm required-input">
                        Type
                      </label>
                      <div className>
                        <Select
                          options={typeOptions}
                          onChange={(value) => onChange("type", value.value)}
                          value={{
                            label: typeOptions.find(
                              (option) => option.value === form.data?.type
                            )?.label,
                            value: form.data?.type,
                          }}
                          //   isDisabled={form.type === "Edit"}
                        />
                      </div>
                    </div>
                    {form.data?.type === "custom" && (
                      <div className="form-item mb-3 vertical">
                        <label className="form-label text-sm required-input">
                          Date
                        </label>
                        <div className="scheduler-date bg-white relative  border rounded left-0 z-10">
                          <Calendar
                            className="border w-full"
                            date={
                              form.data?.date
                                ? moment(form.data?.date, "YYYY-MM-DD").toDate()
                                : new Date()
                            }
                            onChange={(value) => {
                              const formattedDate =
                                moment(value).format("YYYY-MM-DD");
                              console.log(formattedDate);
                              onChange("date", formattedDate);
                            }}
                            opens="center"
                            direction="horizontal"
                            minDate={new Date()}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center px-3 pb-6 space-x-2  border-gray-200 rounded-b">
                <button
                  className="inline-flex items-center justify-center button bg-gray-100 hover:bg-gray-500 hover:text-white ease-in duration-100 text-gray-700 radius-round h-11 py-2 px-4"
                  type="submit"
                  onClick={() => formClear()}
                >
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
                <button
                  className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isUpdateLoading || isCreateLoading) && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}{" "}
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scheduler;
