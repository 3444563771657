import { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../../components/common/Button";
import Table from "../../../components/common/Table";
import SideBar from "../../../components/settings/SideBar";
import CreatePortal from "./CreatePortal";
import { useNavigate } from "react-router-dom";
import DisplayPortalStatus from "./DisplayPortalStatus";
import usePortals from "./usePortals";
import useApiData from "../../../hooks/useApiData";
import Spinner from "../../../components/loaders/Spinner";
import DeleteModal from "../../../components/common/DeleteModal";
import { AppContext } from "../../../App";
import useDropDown from "../../../hooks/useDropDown";

const data = [
  {
    id: 1,
    name: "poral",
    url: "http://localhost:3000/support/newChat1",
    theme: "CUSTOMER_PORTAL",
    status: 1,
  },
];

function getColumns({ actionProps, statusProps }) {
  return [
    { name: "NAME", renderComp: (data) => data?.name },
    {
      name: "PORTAL URL",
      renderComp: (data) => (
        <Button
          className="text-blue-600 !break-words"
          onClick={() => {
            window.open(data?.url, "_blank");
          }}
        >
          {data?.url}
        </Button>
      ),
    },
    { name: "THEME", renderComp: (data) => data?.theme },
    {
      name: "STATUS",
      renderComp: (data) => {
        const { onStatusUpdate, loading, updateId } = statusProps;

        return (
          <DisplayPortalStatus
            status={data.status}
            onClick={(status) => {
              const toggleStatus = status === 1 ? 0 : 1;

              onStatusUpdate(data.uuid, toggleStatus);
            }}
            loading={loading && updateId === data.uuid}
          />
        );
      },
    },
    {
      name: "ACTIONS",
      renderComp: (data) => <Actions {...actionProps} id={data.uuid} />,
    },
  ];
}

function ListPortals() {
  const [openCreatePortal, setOpenCreatePortal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [formError, setFormError] = useState({});

  const { onSuccess, onError } = useContext(AppContext);

  const navigate = useNavigate();

  const { getPortals, deletePortal, createPortal, updatePortal } = usePortals();

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => {
      const { status } = res?.data;
      setUpdateId("");

      if (status === "error") {
        onError("Unable to get portals");
      }
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to get portals");
    },
  });

  const { mutate: createApi, loading: isCreating } = useApiData({
    onSuccess: (res) => {
      const { status } = res?.data;

      if (status === "success") {
        mutate(getPortals());
        setOpenCreatePortal(false);
        setFormError({});
        onSuccess("Portal created successfully");
      } else {
        onError("Unable to create portal");
      }
    },
    onError: (err) => {
      const { message, error } = err?.response?.data || {};
      const { url } = error || {};

      const msgData = err?.response?.data?.error || [];

      const urlError = url?.length ? url[0] : "";

      if (urlError) {
        setFormError({ url: urlError });
      }

      onError(urlError || msgData || message || "Unable to create portal");
    },
  });

  const { mutate: updateApi, loading: isUpdating } = useApiData({
    onSuccess: (res) => {
      const { status } = res?.data;

      if (status === "success") {
        mutate(getPortals());
        onSuccess("Portal status changed successfully");
      } else {
        onError("Unable to update portal status");
      }
    },
    onError: (err) => {
      setUpdateId("");

      onError(err?.response?.data?.message || "Unable to update portal status");
    },
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      const { status } = res?.data;
      if (status === "success") {
        setOpenDelete(false);
        mutate(getPortals());
      } else {
        onError("Unable to delete portal");
      }
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to delete portal");
    },
  });

  useEffect(() => {
    mutate(getPortals());
  }, []);

  const actionProps = useMemo(() => {
    return {
      onEdit: (id) => navigate(`/settings/portals/${id}`),
      onDelete: (id) => setOpenDelete(id),
    };
  }, [navigate]);

  const statusProps = useMemo(() => {
    return {
      onStatusUpdate: (id, status) => {
        setUpdateId(id);
        updateApi(updatePortal(id, { status }));
      },
      loading: isUpdating || loading,
      updateId,
    };
  }, [isUpdating, updateApi, updatePortal, updateId, loading]);

  const portals = data?.data || [];

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            <div className="flex gap-4 text-gray-700">
              <SideBar />
              <div className="w-full mt-3">
                <div className="mb-4 flex justify-between items-center">
                  <h3 className="text-2xl font-bold">Support Portals</h3>
                  <Button
                    className="flex items-center text-white bg-indigo-600 rounded-md px-6 py-3 text-sm"
                    onClick={() => setOpenCreatePortal(true)}
                  >
                    <img src="/images/icons/add.svg" alt="+" className="mr-3" />
                    New Portal
                  </Button>
                </div>

                {loading && !data ? (
                  <Spinner />
                ) : (
                  <>
                    {portals?.length ? (
                      <Table
                        data={portals}
                        columns={getColumns({ actionProps, statusProps })}
                        containerCls="text-sm bg-white"
                      />
                    ) : (
                      <div className="font-semibold text-gray-500 mt-8 text-xl">
                        Portals not found
                      </div>
                    )}
                  </>
                )}

                <CreatePortal
                  isOpen={openCreatePortal}
                  onClose={() => setOpenCreatePortal(false)}
                  onSubmit={(payload) => {
                    createApi(createPortal(payload));
                  }}
                  loading={isCreating}
                  error={formError}
                />

                {openDelete && (
                  <DeleteModal
                    title={"Are you sure, you want to delete this portal."}
                    open={openDelete}
                    onClose={() => {
                      setOpenDelete(false);
                    }}
                    loading={isDeleteLoading}
                    onDelete={() => {
                      deleteApi(deletePortal(openDelete));
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListPortals;

function Actions({ id, onEdit, onDelete }) {
  const [open, setOpen] = useState(false);

  const { elementRef } = useDropDown({ open, setOpen });

  return (
    <div className="flex items-center space-x-2 relative" ref={elementRef}>
      <Button
        className="px-2 py-4 hover:bg-gray-100 rounded-md"
        onClick={() => setOpen(!open)}
      >
        <img src="/images/icons/more.svg" alt="more" />
      </Button>

      {open ? (
        <div className="absolute top-8 right-0 rounded-md bg-white border border-gray-300 z-40 w-32">
          <Button
            onClick={() => {
              onEdit(id);
              setOpen(false);
            }}
            className="px-4 py-2 border-b border-gray-300 hover:bg-gray-100 w-full text-left"
          >
            Configure
          </Button>

          <Button
            onClick={() => {
              onDelete(id);
              setOpen(false);
            }}
            className="px-4 py-2 hover:bg-gray-100 w-full text-left"
          >
            Delete portal
          </Button>
        </div>
      ) : null}
    </div>
  );
}
