import { useCallback, useContext } from "react";
import api from "../../../../api/api";
import { AppContext } from "../../../../App";

function useChatBot() {
  const { currCommunityId } = useContext(AppContext);

  const updateIsConnectWithAgent = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/connection_status`, updatedPayload);
    },
    [currCommunityId]
  );

  return {
    updateIsConnectWithAgent,
  };
}

export default useChatBot;
