import { useContext, useEffect, useState } from "react";
import useApiData from "../../hooks/useApiData";
import { AppContext } from "../../App";
import api from "../../api/api";
import Select from "react-select";
import Spinner from "../loaders/Spinner";

function ClientCustomFieldsForm({ customFieldForm = [], setCustomFieldForm }) {
  const { currCommunityId } = useContext(AppContext);

  const {
    data: customFields,
    mutate: getCustomFieldsApi,
    loading,
  } = useApiData({
    onSuccess: (res) => {},
  });

  const getClientCustomFieldsData = () => {
    getCustomFieldsApi(() =>
      api.get(`api/v1/getClientCustomFields/communityId/${currCommunityId}`)
    );
  };

  useEffect(() => {
    getClientCustomFieldsData();
  }, []);

  const handleCustomFieldChange = (value, item) => {
    const newValue =
      item.field_type === "multiselect" ? value.map((v) => v) : value;

    const index = customFieldForm.findIndex(
      (field) => field.customfield_id === item.id
    );

    const updatedCustomFieldForm = [...customFieldForm];

    if (index !== -1) {
      updatedCustomFieldForm[index] = {
        ...updatedCustomFieldForm[index],
        customfield_values: newValue,
      };
    } else {
      updatedCustomFieldForm.push({
        customfield_id: item.id,
        customfield_values: newValue,
      });
    }

    setCustomFieldForm(updatedCustomFieldForm);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="form-item vertical text-sm">
      <label className="form-label mb-3">Custom Fields</label>
      <div>
        {customFields?.data?.map((item) => (
          <div key={item.id} className="custom-field mb-3">
            <label className="form-label mb-1 !text-gray-600">
              {item.field_name}
            </label>
            {item.field_type === "input" && (
              <input
                type="text"
                placeholder={item.field_name}
                className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                value={
                  customFieldForm?.find(
                    (field) => field.customfield_id === item.id
                  )?.customfield_values || ""
                }
                onChange={(e) => handleCustomFieldChange(e.target.value, item)}
              />
            )}
            {item.field_type === "select" && (
              <Select
                options={Object.entries(JSON.parse(item.field_values)).map(
                  ([key, value]) => ({
                    value,
                    label: value,
                  })
                )}
                value={
                  customFieldForm?.find(
                    (field) => field.customfield_id === item.id
                  )?.customfield_values
                    ? {
                        value: customFieldForm.find(
                          (field) => field.customfield_id === item.id
                        ).customfield_values,
                        label: customFieldForm.find(
                          (field) => field.customfield_id === item.id
                        ).customfield_values,
                      }
                    : ""
                }
                onChange={(selectedOption) =>
                  handleCustomFieldChange(
                    selectedOption ? selectedOption.value : null,
                    item
                  )
                }
              />
            )}
            {item.field_type === "multiselect" && (
              <Select
                options={Object.entries(JSON.parse(item.field_values)).map(
                  ([key, value]) => ({
                    value,
                    label: value,
                  })
                )}
                value={customFieldForm
                  ?.filter((field) => field.customfield_id === item.id)
                  .flatMap((field) =>
                    field.customfield_values.map((val) => ({
                      value: val,
                      label: val.toString(),
                    }))
                  )}
                isMulti
                onChange={(selectedOptions) =>
                  handleCustomFieldChange(
                    selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [],
                    item
                  )
                }
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientCustomFieldsForm;
