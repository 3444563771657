import React, { useRef, useState } from "react";
import Insert from "../../buildBotFlow/common/Insert";
import DefaultInsertOptions from "../../buildBotFlow/common/DefaultInsertOptions";
import {
  getInsertFormat,
  getReactSelectOptions,
  insertPropertyToInput,
} from "../../../../common/commonHelpers";
import Select from "react-select";
import ToggleBtn from "../../../../common/ToggleBtn";
import Table from "../../../../common/Table";
import Button from "../../../../common/Button";
import {
  Label,
  getDefaultApiForm,
  handleOnInsertProperty,
} from "./FormApiConfiguration";
import { getRemoteVariables } from "../../buildBotFlow/helpers/dialogHelpers";

const inputTypes = ["text", "number", "textarea", "password", "select"];

function getColumns(props) {
  const { updateForm, onDelete } = props;

  return [
    {
      name: <Label label="Label" customCls="!text-black" />,
      renderComp: (data, dataIndex) => (
        <input
          type="text"
          className="p-2 outline-none text-sm border border-gray-300 rounded-md w-[7rem]"
          placeholder="label"
          onChange={(e) =>
            props.updateForm({ label: e.target.value }, dataIndex)
          }
          value={data?.label}
          required
        />
      ),
    },
    {
      name: <Label label="Name" customCls="!text-black" />,
      renderComp: (data, dataIndex) => (
        <input
          type="text"
          className="p-2 outline-none text-sm border border-gray-300 rounded-md w-[7rem]"
          placeholder="name"
          onChange={(e) =>
            props.updateForm({ name: e.target.value }, dataIndex)
          }
          value={data?.name}
          required
        />
      ),
    },
    {
      name: "Value",
      renderComp: (data, dataIndex) => (
        <InputValue props={props} data={data} dataIndex={dataIndex} />
      ),
    },
    {
      name: "Input Type",
      renderComp: (data, dataIndex) => (
        <SelectInputType props={props} data={data} dataIndex={dataIndex} />
      ),
    },
    {
      name: "Hide for User",
      renderComp: (data, dataIndex) => (
        <ToggleBtn
          checked={data?.hideForUser}
          setChecked={(val) => updateForm({ hideForUser: val }, dataIndex)}
        />
      ),
    },
    {
      name: "",
      renderComp: (data, dataIndex) => (
        <Button
          className="hover:bg-gray-100"
          onClick={() => onDelete(dataIndex)}
        >
          <i className="fa-solid fa-trash hover:text-red-600 m-2"></i>
        </Button>
      ),
    },
  ];
}

function ApiPayloadAsForm({
  configurationId,
  apiForm,
  updateFormData,
  remoteVariables,
  dynamicInputString,
  apiFormError,
}) {
  function updateForm(update, index) {
    const prev = apiForm ? [...apiForm] : [];
    const prevData = prev[index] || {};

    prev[index] = { ...prevData, ...update };

    updateFormData({ apiForm: prev });
  }

  function updateValueAndRemoteVariables(update, index, remoteVariablesData) {
    const prev = apiForm ? [...apiForm] : [];
    const prevData = prev[index] || {};

    prev[index] = { ...prevData, ...update };

    const newRemoteVariables = getRemoteVariables(
      { remoteVariables },
      remoteVariablesData,
      dynamicInputString(prev)
    );

    updateFormData({ apiForm: prev, remoteVariables: newRemoteVariables });
  }

  function onDelete(index) {
    updateFormData({ apiForm: apiForm.filter((_, i) => i !== index) });
  }

  const disableAddBtn = apiForm.some((f) => !f.name);

  return (
    <div className="">
      <Table
        columns={getColumns({
          configurationId,
          updateForm,
          onDelete,
          updateValueAndRemoteVariables,
          remoteVariables,
        })}
        data={apiForm}
        containerCls="text-sm"
      />

      {apiFormError ? (
        <div className="text-red-600 my-3">Name should be unique</div>
      ) : null}

      <Button
        className={`flex items-center bg-white text-primary-600 py-2 px-4 w-fit border font-semibold hover:bg-blue-50 rounded-md ${
          disableAddBtn ? "cursor-not-allowed bg-opacity-70" : ""
        }`}
        onClick={() => {
          updateForm(getDefaultApiForm(), apiForm.length);
        }}
        disabled={disableAddBtn}
      >
        <i class="fa-solid fa-circle-plus mr-2"></i>
        Add input
      </Button>
    </div>
  );
}

export default ApiPayloadAsForm;

function InputValue({ props, data, dataIndex }) {
  const { updateForm, configurationId, updateValueAndRemoteVariables } = props;

  const valueInputRef = useRef();

  return (
    <div className="relative min-w-40">
      <input
        type="text"
        className="w-full py-2 pl-2 pr-8 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
        placeholder="value"
        value={data?.value}
        onChange={(e) => {
          updateForm({ value: e.target.value, type: "" }, dataIndex);
        }}
        ref={valueInputRef}
      />

      <div className="absolute bottom-2 right-2 h-fit bg-white">
        <Insert>
          <DefaultInsertOptions
            configurationId={configurationId}
            onInsert={(insertValue, option) => {
              handleOnInsertProperty(
                insertValue,
                option,
                valueInputRef,
                (value, remoteVariablesData) => {
                  updateValueAndRemoteVariables(
                    { value: value, type: "" },
                    dataIndex,
                    remoteVariablesData
                  );
                }
              );
            }}
          />
        </Insert>
      </div>
    </div>
  );
}

function getDefaultSelectOptions() {
  return { label: "", value: "" };
}

function SelectInputType({ props, data, dataIndex }) {
  const { updateForm, configurationId, updateValueAndRemoteVariables } = props;

  const { type, options } = data || {};

  if (data?.value) {
    return "-";
  }

  return (
    <div className="min-w-[200px]">
      <Select
        className="w-full text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
        options={getReactSelectOptions(inputTypes)}
        onChange={(val) =>
          updateForm(
            {
              type: val?.value,
              value: "",
              options: [getDefaultSelectOptions()],
            },
            dataIndex
          )
        }
        value={type ? { label: type, value: type, options: [] } : null}
        placeholder="Input type"
      />

      {type === "select" ? (
        <SelectOptions
          options={options || []}
          onChange={(value, key, index) => {
            const prevOptions = options
              ? [...options]
              : [getDefaultSelectOptions()];

            const existingData = prevOptions[index] || {};

            existingData[key] = value;

            prevOptions[index] = existingData;

            updateForm({ options: prevOptions }, dataIndex);
          }}
          onAdd={() => {
            const prevOptions = options
              ? [...options]
              : [getDefaultSelectOptions()];

            prevOptions.push(getDefaultSelectOptions());

            updateForm({ options: prevOptions }, dataIndex);
          }}
          onDelete={(index) => {
            const prevOptions = options
              ? [...options]
              : [getDefaultSelectOptions()];

            const remaining = prevOptions.filter((p, i) => i !== index);

            updateForm({ options: remaining }, dataIndex);
          }}
        />
      ) : null}
    </div>
  );
}

function SelectOptions({ options, onAdd, onDelete, onChange }) {
  return (
    <div className="text-gray-700">
      <div className="text-gray-500 font-semibold my-4 pb-2 border-b">
        Enter Options
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div className="text-gray-500 required-input">Label</div>

        <div className="text-gray-500 required-input">Value</div>

        {options.map((o, i) => {
          return (
            <React.Fragment key={i}>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                placeholder="Label"
                value={o.label}
                onChange={(e) => {
                  onChange(e.target.value, "label", i);
                }}
                required
              />
              <div className="flex space-x-2 items-center">
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                  placeholder="value"
                  value={o.value}
                  onChange={(e) => {
                    onChange(e.target.value, "value", i);
                  }}
                  required
                />

                {options.length > 1 ? (
                  <i
                    className="fa-solid fa-trash cursor-pointer"
                    onClick={() => onDelete(i)}
                  ></i>
                ) : null}
              </div>
            </React.Fragment>
          );
        })}
      </div>

      <Button
        className="px-4 py-2 border text-indigo-600 my-2 rounded-md hover:bg-gray-100"
        onClick={onAdd}
      >
        + Add Option
      </Button>
    </div>
  );
}
