import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../App";
import api from "../../api/api";

const MailList = (props) => {
  const [emails, setEmails] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [bulkAction, setBulkAction] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [emailsPerPage, setEmailsPerPage] = useState(10);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  useEffect(() => {
    setEmails(props.mails);
    setPagination(props.pagination);
    // console.log("pagination", props.pagination);
  }, [props.mails, props.pagination]);

  const handleCheckbox = (e, id) => {
    if (e.target.checked) {
      setSelectedEmails([...selectedEmails, id]);
    } else {
      setSelectedEmails(selectedEmails.filter((emailId) => emailId !== id));
    }
  };
  const refresh = () => {
    props.getMails("refresh");
  };
  const createTicket = (uid) => {
    var postData = {
      community_id: currCommunityId,
      tickets: uid ? [uid] : selectedEmails,
      folder: props.activeFolder ? props.activeFolder : "INBOX",
    };
    props.handleLoading(true);
    api
      .post("/api/createMailTicket", postData)
      .then((response) => {
        if (response.data.status == "success") {
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Ticket Created Successfully",
          });
          props.handleLoading(false);
          props.getMails("refresh");
        }
      })
      .catch((error) => {
        props.handleLoading(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "something went wrong",
        });
      });
  };
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1">
        <div className="actions items-center justify-between flex bg-[#E3E9F3] p-3">
          <div className="flex items-center">
            {" "}
            <div
              className="cursor-pointer light-blue-hover rounded px-4 py-2 text-gray-700"
              onClick={() => refresh()}
            >
              <i className="fa-solid fa-rotate-right text-lg"></i>
            </div>
            <div
              className="cursor-pointer light-blue-hover rounded px-4 py-2 text-gray-700"
              onClick={(e) => {
                e.preventDefault();
                createTicket();
              }}
            >
              <i className="fa-solid fa-clipboard-list text-lg"></i>
            </div>
          </div>

          <div>
            <Pagination
              pagination={pagination}
              prevPage={prevPage}
              nextPage={nextPage}
              {...props}
            />
          </div>
        </div>

        <table className="maillist-list w-full">
          <tbody>
            {emails.map((email) => (
              <tr
                key={email.uid}
                className={`${
                  email?.flags?.seen == "Seen"
                    ? "bg-gray-50"
                    : "font-semibold bg-white"
                } border-b flex block border-t mailRow hover:bg-gray-200 `}
              >
                <td className="px-4 py-2 text-gray-700">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      handleCheckbox(e, email.uid);
                    }}
                    checked={selectedEmails.includes(email.uid)}
                    disabled={email.ticketCreated ? true : false}
                  />
                </td>

                <Link
                  to={`/mails/${email.uid}`}
                  className="w-[93%] flex items-center relative cursor-pointer"
                >
                  <td className="px-4 py-2 w-44 text-gray-700 maillist-name">
                    {email.from}
                  </td>
                  <td className="px-4 py-2 text-gray-700 three-dots-mail">
                    {email.subject}
                  </td>
                  {!email.ticketCreated && (
                    <td
                      className="cursor-pointer create-ticket__icon light-blue-hover rounded px-4 py-2 text-gray-700"
                      onClick={(e) => {
                        e.preventDefault();
                        createTicket(email.uid);
                      }}
                    >
                      <i className="fa-solid fa-clipboard-list"></i>
                    </td>
                  )}
                  {email.ticketCreated && (
                    <td className="text-2xl text-emerald-400">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 20 20"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </td>
                  )}
                </Link>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <Pagination
          pagination={pagination}
          prevPage={prevPage}
          nextPage={nextPage}
          {...props}
        /> */}
      </div>
    </div>
  );
};

export default MailList;

function Pagination({ pagination, prevPage, nextPage, ...props }) {
  return (
    <div className="flex items-center justify-end text-sm">
      <div className="pagination">
        <div className="pagination-total font-medium">
          Total <span>{pagination.total}</span> Items
        </div>

        <span
          className={`pagination-pager pagination-pager-next ${
            !prevPage ? "pagination-pager-inactive" : ""
          }`}
          onClick={() => {
            if (prevPage) {
              props.handlePageChange(prevPage);
            }
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 20 20"
            height="1.5em"
            width="1.5em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <ul className="">
          {Array.from({ length: pagination.last_page }, (v, i) => i + 1).map(
            (pageNumber) => {
              if (
                pageNumber === 1 ||
                pageNumber === pagination.last_page ||
                Math.abs(pageNumber - pagination.current_page) <= 1
              ) {
                return (
                  <li
                    className={`pagination-pager ${
                      pagination.current_page == pageNumber
                        ? "text[#0099d8] bg-[#eff9ff]"
                        : ""
                    } hover:bg-indigo-50`}
                    key={pageNumber}
                    onClick={() => props.handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </li>
                );
              } else if (
                pageNumber === pagination.current_page - 2 ||
                pageNumber === pagination.current_page + 2
              ) {
                return (
                  <li className="pagination-pager pagination-pager-inactive">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      />
                    </svg>
                  </li>
                );
              }
            }
          )}
        </ul>
        <span
          className={`pagination-pager pagination-pager-next ${
            !nextPage ? "pagination-pager-inactive" : ""
          }`}
          onClick={() => {
            if (nextPage) {
              props.handlePageChange(nextPage);
            }
          }}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 20 20"
            height="1.5em"
            width="1.5em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}
