import { useState } from "react";
import TicketCreate from "../emailTemplate/TicketCreate";
import CommonFooterText from "../emailTemplate/CommonFooterText";

const EmailTemplates = (props) => {
  const [openTab, setOpenTab] = useState(1);

  return (
    <>
      <div className="px-2  py-3">
        <div className="font-medium mb-10">
          Can we add a short description here to explain who are the email
          recipients and why a user may need to compose these email templates?
        </div>

        <div className="communityMembers">
          <div className="relative sm:rounded-md">
            <div className="flex gap-7 mt-5">
              <div className="w-[25%] border-r">
                <div
                  className={`px-4 py-2 font-medium mt-1 cursor-pointer tab-nav tab-nav-pill tab-nav-active py-1 hover:text-blue-600 duration-300 ease hover:bg-blue-50 ${
                    openTab == 1 && "text-blue-600 bg-blue-50"
                  }`}
                  role="tab"
                  aria-selected="true"
                  tabIndex={0}
                  onClick={() => setOpenTab(1)}
                >
                  Ticket Create
                </div>
                <div
                  className={`px-4 py-2 font-medium mt-1 cursor-pointer tab-nav tab-nav-pill tab-nav-active py-1 hover:text-blue-600 duration-300 ease hover:bg-blue-50 ${
                    openTab == 2 && "text-blue-600 bg-blue-50"
                  }`}
                  role="tab"
                  aria-selected="true"
                  tabIndex={0}
                  onClick={() => setOpenTab(2)}
                >
                  Common Footer Text
                </div>
              </div>
              <div className="w-[75%]">
                {openTab == 1 && <TicketCreate />}
                {openTab == 2 && <CommonFooterText />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailTemplates;
