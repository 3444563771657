import { useCallback, useContext, useEffect, useState } from "react";
import InputWrapper from "../../common/InputWrapper";
import CreatableSelect from "react-select/creatable";
import TemplateCard from "./TemplateCard";
import useMailTemplate from "./useMailTemplate";
import useApiData from "../../../hooks/useApiData";
import { AppContext } from "../../../App";
import Loading from "../../loaders/Loading";
import PrimaryButton from "../../common/PrimaryButton";
import { useNavigate } from "react-router-dom";
import useDropDown from "../../../hooks/useDropDown";
import DisplayMails from "./DisplayMails";
import ChooseTemplate from "./ChooseTemplate";

function ViewTemplates(props) {
  const [form, setForm] = useState({
    to: [],
    subject: "",
    body: "",
    templateId: "",
  });

  const [viewFullMail, setViewFullMail] = useState(false);

  const { elementRef } = useDropDown({ setOpen: setViewFullMail });

  const [error, setError] = useState({ templateId: "" });

  const navigate = useNavigate();

  const { currCommunityId, onSuccess, onError } = useContext(AppContext);

  const updateForm = useCallback((update) => {
    setForm((prev) => ({ ...prev, ...update }));
  }, []);

  useEffect(() => {
    if (props?.to?.length) {
      updateForm({ to: props.to });
    }
  }, [props.to]);

  const { to } = form;

  const { getTemplates, sendMailTemplate } = useMailTemplate();

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => {},
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to get templates");
    },
  });

  const getTemplatesData = useCallback(() => {
    mutate(getTemplates({ community_id: currCommunityId }));
  }, [currCommunityId, getTemplates, mutate]);

  const { mutate: sendMailApi, loading: isSending } = useApiData({
    onSuccess: (res) => {
      onSuccess("Email send successfully");
      props.onClose();
    },
    onError: (err) => {
      const { message } = err?.response?.data || {};

      onError(message || "Unable to send Email");
    },
  });

  useEffect(() => {
    getTemplatesData();
  }, []);

  const templates = data || [];

  const remainingMailsCount = to?.length - 3;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!form.templateId) {
          setError({ templateId: "Please choose the template" });
        }

        setError({ templateId: "" });

        sendMailApi(
          sendMailTemplate({
            community_id: currCommunityId,
            templateid: form.templateId,
            email: to,
          })
        );
      }}
    >
      <InputWrapper label="Bcc">
        <DisplayMails
          to={to || []}
          setSelectedUsers={props?.setSelectedUsers}
        />
      </InputWrapper>

      <div className="font-semibold text-gray-500 mt-6 mb-3">
        Choose Template
      </div>

      <ChooseTemplate
        templateId={form.templateId}
        onSelect={(template) => updateForm({ templateId: template.id })}
        templates={templates}
        loading={loading}
      />

      {error?.templateId && templates?.length ? (
        <div className="text-red-600 my-2">{error.templateId}</div>
      ) : null}

      {templates?.length ? (
        <div className="flex justify-end mt-6">
          <PrimaryButton label="Send Email" loading={isSending} />
        </div>
      ) : null}
    </form>
  );
}

export default ViewTemplates;
