import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/loaders/Loading";
import { useEffect } from "react";

function SettingUp() {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const jwtToken = searchParams.get("jwtToken");
  const currCommunity = searchParams.get("currCommunity");

  useEffect(() => {
    let goTo = "/signin";

    const isLoginDomain = window.location.host === "login.rhinoassist.com";

    if (isLoginDomain) {
      localStorage.clear();
    } else if (jwtToken && currCommunity) {
      goTo = "/";

      localStorage.clear();

      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem("currCommunity", currCommunity);
      document.cookie = "chatSocketID-admin=" + currCommunity;
    }

    let id = setTimeout(() => {
      navigate(goTo);
    }, 1500);

    return () => clearTimeout(id);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-screen w-full text-xl">
      We are setting up your account, please wait...
      <Loading customCls="mt-8" />
    </div>
  );
}

export default SettingUp;
