import Knowledge from "../../components/chatBot/knowledgeBase/Knowledge";

const KnowledgeContainer = () => {
  return (
    <div className="mt-2">
      <Knowledge />
    </div>
  );
};

export default KnowledgeContainer;
