import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "../../../../common/SearchInput";
import Insert from "../common/Insert";
import useApiConfiguration from "../../configuration/apiConfiguration/useApiConfiguration";
import useApiData from "../../../../../hooks/useApiData";
import { useEffect } from "react";
import Spinner from "../../../../loaders/Spinner";

const apiOptions = [
  { label: "Post Api" },
  { label: "Get Api" },
  { label: "Post Api" },
  { label: "Get Api" },
  { label: "Post Api" },
  { label: "Get Api" },
];

function SelectApis({ onSelectingApis, configurationId, error }) {
  const testString = ["test"];

  const { id, botflowId } = useParams();

  const navigate = useNavigate();

  const formPath = `/train-chat-bot/${id}/botflow/${botflowId}/configuration/api/form`;

  const { getApiConfigurations } = useApiConfiguration(id, botflowId);

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => {},
  });

  useEffect(() => {
    getList(getApiConfigurations());
  }, []);

  const apis = data?.data || [];

  return (
    <div>
      <div className="text-gray-500 my-1.5 mt-3 font-semibold required-input">
        Select an API
      </div>

      <div
        className={`bg-white flex justify-between items-center border border-gray-300 rounded-md p-1.5 ${
          error ? "border-red-600" : ""
        }`}
      >
        <div className="w-10/12">
          {configurationId?.apiName ? (
            <div className="flex justify-between items-center px-3 py-1.5 border border-primary-600 font-semibold rounded-md bg-blue-50 text-primary-600">
              <div className="w-11/12 truncate">{configurationId?.apiName}</div>
              <i
                class="fa-solid fa-xmark text-black cursor-pointer"
                onClick={() => onSelectingApis(null)}
              ></i>
            </div>
          ) : null}
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <Insert>
            <SelectApisOptions
              navigate={navigate}
              apis={apis}
              formPath={formPath}
              onSelectingApis={onSelectingApis}
            />
          </Insert>
        )}
      </div>
    </div>
  );
}

export default SelectApis;

function SelectApisOptions(props) {
  const { navigate, apis, formPath, onSelectingApis } = props;
  return (
    <div className="w-[140px] p-2">
      {/* <SearchInput customCls="w-full" onSearch={() => {}} /> */}

      <button
        className="px-4 py-1.5 border border-primary-600 rounded-md bg-blue-50 text-primary-600 w-[95%] text-left my-2"
        onClick={() => navigate(formPath)}
      >
        + New API
      </button>

      {apis.map((api, i) => {
        return (
          <button
            key={i}
            className="py-1 px-4 hover:bg-gray-100 w-full text-left leading-6 flex space-x-2"
            onClick={() => {
              onSelectingApis(api);
              props.setOpen(false);
            }}
            //*** insert-data id is used for finding the element is clicked inside or outside dialog
            id="insert-data"
          >
            {i + 1}. {api.apiName}
            {/* <div>{i + 1}.</div>
            <div>{api.apiName}</div> */}
          </button>
        );
      })}
    </div>
  );
}
