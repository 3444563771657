import BotflowLayout from "../../components/chatBot/botflow/BotflowLayout";
import ApiConfigurationList from "../../components/chatBot/botflow/configuration/apiConfiguration/ApiConfigurationList";

function APIConfigurationContainer() {
  return (
    <BotflowLayout tab="configuration">
      <div className="container chatBotTraining p-8">
        <ApiConfigurationList />
      </div>
    </BotflowLayout>
  );
}

export default APIConfigurationContainer;
