import { formatDateWithTime } from "../../../../common/commonHelpers";

const methodsTextColor = {
  get: "blue",
  post: "green",
  put: "orange",
  delete: "red",
  patch: "primary",
};

function ApiDetailsCard({ api, onEdit, onDelete }) {
  const { apiMethod, apiName, created_at } = api;

  const color = methodsTextColor[apiMethod.toLowerCase()];

  return (
    <button
      className="p-4 border hover:shadow-md w-full text-left mt-4 rounded-md flex justify-between items-center"
      onClick={(e) => {
        e.stopPropagation();

        onEdit();
      }}
    >
      <div>
        {" "}
        <div
          className={`bg-${color}-50 border border-${color}-600 text-${color}-600 px-2 py-0.5 w-fit rounded-md text-sm`}
        >
          {apiMethod}
        </div>
        <div className="font-semibold my-2 mt-3">{apiName}</div>
        <div className="text-sm">Created: {formatDateWithTime(created_at)}</div>
      </div>

      <Actions onEdit={onEdit} onDelete={onDelete} />
    </button>
  );
}

export default ApiDetailsCard;

function Actions({ onEdit, onDelete }) {
  return (
    <div className="flex items-center">
      <button
        className="hover:bg-gray-100"
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <i className="fa-solid fa-pen-to-square hover:text-blue-600 m-2"></i>
      </button>

      <button
        className="hover:bg-gray-100"
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <i className="fa-solid fa-trash hover:text-red-600 m-2"></i>
      </button>
    </div>
  );
}
