import Button from "./Button";

function BackBtn(props) {
  return (
    <Button
      className="border px-3 py-1 rounded-md border-gray-400 hover:bg-gray-100 my-4"
      {...props}
    >
      <i className="fa-solid fa-arrow-left text-gray-600"></i>
    </Button>
  );
}

export default BackBtn;
