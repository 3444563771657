import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { embedBotflowPreview } from "../common/commonHelpers";

const isColorDark = (hexColor) => {
  // You can customize this darkness threshold based on your preference
  const brightnessThreshold = 128;

  // Convert hex to RGB
  const rgb = hexToRgb(hexColor);

  // Calculate brightness (using the Y component of the YIQ color space)
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

  // Check if the color is dark
  return brightness < brightnessThreshold;
};

const hexToRgb = (hex) => {
  // Remove the hash if it's included
  hex = hex.replace(/^#/, "");

  // Parse the hex values
  const bigint = parseInt(hex, 16);

  // Extract RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

const ChatBotConfig = (props) => {
  const [openTab, setOpenTab] = useState(1);
  const [uploadImage, setUploadImage] = useState();
  const [image, setImage] = useState();
  const [callAccept, setCallAccept] = useState(false);
  const [name, setName] = useState();
  const [color, setColor] = useState();
  const [offlineMessage, setOfflineMessage] = useState();
  const [firstMessage, setFirstMessage] = useState();
  const [processing, setProcessing] = useState(false);
  const [whatsappDetails, setWhatsappDetails] = useState({
    is_whatsapp: "",
    whatsapp_number: "",
    whatsapp_first_message: "",
  });

  useEffect(() => {
    setName(props.folderDetails?.data?.name);
    setCallAccept(props.folderDetails?.data?.is_call);
    setFirstMessage(props.folderDetails?.data?.first_message);
    if (props.folderDetails?.data?.logo) {
      var image = props.folderDetails?.data?.logo;
    } else {
      var image = require("../../assets/images/icons8-no-image-100.png");
    }
    setImage(image);
    setColor(props.folderDetails?.data?.color);
    setOfflineMessage(props.folderDetails?.data?.offline_message);
    setWhatsappDetails({
      is_whatsapp: props.folderDetails?.data?.is_whatsapp,
      whatsapp_number: props.folderDetails?.data?.whatsapp_number,
      whatsapp_first_message: props.folderDetails?.data?.whatsapp_first_message,
    });
  }, []);

  const inputFileRef = useRef();
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUploadImage(reader.result);
    };
  };

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    hasChanged,
    setHasChanged,
    handleLinkClick,
    onWarning,
  } = useContext(AppContext);
  const { id } = useParams();

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleColorChange = (e) => {
    const inputColor = e.target.value;

    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    console.log("inputColor", inputColor);
    if (hexColorRegex.test(inputColor)) {
      setColor(inputColor);
    }

    if (!isColorDark(inputColor)) {
      onWarning("Choose dark colors for better experience");
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    var postData = {
      id: id,
      name: name,
      color: color,
      logo: uploadImage,
      // offline_message: offlineMessage,
      first_message: firstMessage,
      isCall: callAccept,
      whatsappDetails: whatsappDetails,
    };

    api
      .post("/api/updateConfig", postData)
      .then((response) => {
        // console.log(response);
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Data Saved Successfully",
        });
        props.getFolderDetails();
        setHasChanged(false);
        embedBotflowPreview(id, null, true);
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      });
  };
  const whatsappOnChange = (name, value) => {
    setWhatsappDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="flex px-3 gap-7 mt-5">
      <div className="w-full">
        <div className="flex mt-4">
          <div className="w-[30%] pr-5 form-item vertical border-r">
            <label className="form-label">Logo</label>
            <div className="relative">
              <img
                src={uploadImage ? uploadImage : image}
                className={`border mt-2 mb-2 rounded w-full`}
              />
              <div className="flex flex-col gap-2">
                <button
                  className="bg-indigo-500 text-white px-4 py-2 rounded  z-10"
                  onClick={handleSelectButtonClick}
                  type="button"
                >
                  Select
                </button>

                <input
                  type="file"
                  accept=".png"
                  className="hidden"
                  ref={inputFileRef}
                  onChange={handleFileUpload}
                />
              </div>
            </div>
          </div>
          <div className="pl-5 w-full mb-4">
            <div className="">
              <h3 className="mb-4 lg:mb-0 font-semibold">Widget Name</h3>
              <input
                className={`${
                  name === "" ? "input-danger" : ""
                }  w-full mt-3 p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setHasChanged(true);
                }}
              />
            </div>
            <div className="flex  gap-2">
              <div className="w-full">
                <h3 className="mb-4 mt-4 lg:mb-0 font-semibold">
                  First Message
                </h3>
                <input
                  className={` w-full mt-3 p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                  value={firstMessage}
                  onChange={(e) => {
                    setFirstMessage(e.target.value);
                    setHasChanged(true);
                  }}
                />
              </div>
              {/* <div className="">
                <h3 className="mb-4 mt-4 lg:mb-0 font-semibold">
                  Toast Message
                </h3>
                <input
                  className={` w-full mt-3 p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                  value={firstMessage}
                  onChange={(e) => {
                    setFirstMessage(e.target.value);
                    setHasChanged(true);
                  }}
                />
              </div> */}
            </div>
            {/* <div className="">
              <h3 className="mb-4 mt-4 lg:mb-0 font-semibold">
                Offline Message
              </h3>
              <textarea
                className={` w-full mt-3 p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                value={offlineMessage}
                onChange={(e) => {
                  setOfflineMessage(e.target.value);
                  setHasChanged(true);
                }}
              />
            </div> */}

            <div className="flex mt-3 gap-5">
              <div className="w-[50%]">
                <h3 className="mb-4 lg:mb-0 font-semibold">
                  Want To Call from Client?
                </h3>
                <div className="flex mt-3">
                  <div
                    className={`${
                      callAccept && "bg-indigo-500 text-white"
                    } px-3 border-dotted border mx-2 flex items-center cursor-pointer`}
                    onClick={(e) => {
                      setCallAccept(true);
                      setHasChanged(true);
                    }}
                  >
                    Yes
                  </div>
                  <div
                    className={`${
                      callAccept == false && "bg-indigo-500 text-white"
                    } px-3 border-dotted border mx-2 flex items-center cursor-pointer`}
                    onClick={(e) => {
                      setCallAccept(false);
                      setHasChanged(true);
                    }}
                  >
                    No
                  </div>
                </div>
              </div>
              <div className="flex form-item vertical w-[40%]">
                <h3 className="mb-4 lg:mb-0 font-semibold">
                  Color{" "}
                  <span className="text-sm text-gray-500">
                    (Please choose dark colors for better experience)
                  </span>
                </h3>
                <div className="relative flex gap-2">
                  <input
                    className="w-[30%] bg-gray-100 border border-gray-300 rounded-md shadow-sm  block  appearance-none leading-normal focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    type="color"
                    name="subject"
                    autoComplete="off"
                    placeholder="Enter subject"
                    value={color}
                    onChange={handleColorChange}
                  />
                  <input
                    id="hexcolor"
                    className="w-[70%] p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    value={color}
                    type="text"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="w-[100%] gap-3 flex items-center">
              <h3 className="mb-4 lg:mb-0 font-semibold">
                Want to whatsapp Number in your Chatbot?
              </h3>
              <div className="cursor-pointer flex ">
                <i
                  className={`${
                    whatsappDetails.is_whatsapp == 1
                      ? "fa-toggle-on text-green-500"
                      : "fa-toggle-off"
                  } text-2xl fa-solid`}
                  onClick={() =>
                    whatsappOnChange(
                      "is_whatsapp",
                      whatsappDetails.is_whatsapp == 1 ? "0" : "1"
                    )
                  }
                ></i>
              </div>
            </div>
            <div className="w-[100%]">
              <h3 className="my-2 text-sm font-medium">Whatsapp Number</h3>
              <PhoneInput
                className="countryInput"
                country={"us"}
                value={whatsappDetails.whatsapp_number}
                onChange={(phone) => whatsappOnChange("whatsapp_number", phone)}
                enableSearch={true}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
              />
            </div>
            <div className="w-[100%]">
              <h3 className="my-2 text-sm font-medium">First Message</h3>
              <input
                className=" w-full mt-3 p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                type="text"
                value={whatsappDetails.whatsapp_first_message}
                onChange={(e) =>
                  whatsappOnChange("whatsapp_first_message", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="flex justify-end float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
          disabled={processing}
          onClick={(e) => formSubmit(e)}
        >
          {processing && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Save
        </button>
      </div>
    </div>
  );
};

export default ChatBotConfig;
