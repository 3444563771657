import { useCallback, useContext } from "react";
import api from "../../../api/api";
import { AppContext } from "../../../App";

function useTeam() {
  const { currCommunityId } = useContext(AppContext);

  const getTeams = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/team`, updatedPayload);
    },
    [currCommunityId]
  );

  const createTeam = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/createteam`, updatedPayload);
    },
    [currCommunityId]
  );

  const updateTeam = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/updateteam`, updatedPayload);
    },
    [currCommunityId]
  );

  const deleteTeam = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/deleteteam`, updatedPayload);
    },
    [currCommunityId]
  );

  const addUser = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/adduser`, updatedPayload);
    },
    [currCommunityId]
  );

  const removeUser = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/removeuser`, updatedPayload);
    },
    [currCommunityId]
  );

  const primaryTeam = useCallback(
    (payload) => {
      const updatedPayload = {
        ...payload,
        community_id: currCommunityId,
      };
      return () => api.post(`/api/primaryteam`, updatedPayload);
    },
    [currCommunityId]
  );

  return {
    getTeams,
    createTeam,
    updateTeam,
    deleteTeam,
    addUser,
    removeUser,
    primaryTeam,
  };
}

export default useTeam;
