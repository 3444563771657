function levelValidation(field_name, formValue, choices) {
  if (!formValue?.level1) {
    return;
  }

  let prevLevelChoices = choices;

  const error = {};

  Object.entries(field_name).forEach(([key, value], index) => {
    const levelNo = index + 1;

    const levelKey = `level${levelNo}`;

    const isHavingChoices = prevLevelChoices?.some((l) => l.label);

    if (!formValue[levelKey] && isHavingChoices) {
      error[levelKey] = "This field is required";
    }

    const nextChoices = prevLevelChoices?.find(
      (l) => l.label === formValue[levelKey]
    );

    prevLevelChoices = nextChoices?.choices;
  });

  return error;
}

export function validateAdditionalFields(
  additionalFields = {},
  dependency_dropdown_data
) {
  const error = {};
  let havingError = false;
  dependency_dropdown_data.forEach((field) => {
    if (field.is_required === 1) {
      if (
        additionalFields[field.id] === undefined ||
        additionalFields[field.id] === null ||
        !additionalFields[field.id]
      ) {
        if (field.field_type == "input" || field.field_type == "select") {
          error[field.id] = "This field is required";
        } else {
          error[field.id] = { level1: "This field is required" };
        }
        havingError = true;
      }
    }
  });

  Object.entries(additionalFields).forEach(([key, value]) => {
    if (value?.level1) {
      // if value?.level1 means it is depenedency dropdown

      const { field_values = {}, field_name } =
        dependency_dropdown_data.find((d) => d.id.toString() === key) || {};

      const { choices = [] } = field_values;
      // console.log(dependency_dropdown_data);
      // console.log(field_name, value, choices);
      if (field_name) {
        const validatedData = levelValidation(field_name, value, choices);
        if (Object.keys(validatedData)?.length) {
          error[key] = validatedData;
          havingError = true;
        }
      }
    }
  });
  // console.log(error, havingError);
  return { error, havingError };
}
export function checkLabelLevel(data, currentLevel = 0) {
  if (data.label === null) {
    return currentLevel - 1;
  }
  let maxLevel = currentLevel;
  if (data.choices) {
    for (const choice of data.choices) {
      maxLevel = Math.max(maxLevel, checkLabelLevel(choice, currentLevel + 1));
    }
  }

  return maxLevel;
}
