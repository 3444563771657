import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import useApiData from "../../hooks/useApiData";
import ApiValidation from "../common/ApiValidation";
import Loading from "../loaders/Loading";

function TreeNode({ label, children }) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <button
        onClick={() => setOpen((prev) => !prev)}
        className="flex items-center"
      >
        <i
          class={`fa-solid fa-chevron-${open ? "down" : "right"} mr-3 text-sm`}
        ></i>
        {label}
      </button>

      {open ? <div className="pl-12">{children}</div> : null}
    </div>
  );
}

const projects = [
  {
    value: "1",
    label: "Project 1",
  },
  {
    value: "2",
    label: "Project 2",
  },
];

function submitData(payload, setLoading, setShowToast, setToastMessage) {
  setLoading(true);

  api
    .post("/api/updateChatBotData", payload)
    .then((response) => {
      // console.log(response);
      setLoading(false);
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Data Saved Successfully",
      });
      // props.getFolderDetails();
      // setHasChanged(false);
    })
    .catch((error) => {
      setLoading(false);
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    });
}

function refactorProjects(projects = []) {
  return projects.map((p) => ({ label: p.projectName, value: p.id }));
}

function Favian() {
  const { setToastMessage, setShowToast } = useContext(AppContext);

  const { id } = useParams();

  const [selected, setSelected] = useState();
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");

  const [accessToken, setAccessToken] = useState("");
  const [accessTokenError, setAccessTokenError] = useState("");

  const [favianData, setFavianData] = useState({});

  const {
    mutate,
    loading: isLoadingConfig,
    isError,
    error: errorConfig,
  } = useApiData({
    onSuccess: ({ data = {} }) => {
      const { favianConfig = {} } = data?.data;

      if (favianConfig) {
        const { favian_project_id, access_token, url } = favianConfig;
        setSelected(favian_project_id);
        setAccessToken(access_token);
        setUrl(url);
      }

      setFavianData(data?.data);
    },
  });

  const { mutate: checkApiMutate, loading: isChecking } = useApiData({
    onSuccess: ({ data = {} }) => {
      const { error, projects } = data?.data || {};

      if (error) {
        setShowToast(true);
        setToastMessage({ type: "error", message: error });
        setAccessTokenError(error);
      }

      setFavianData({ favianProjects: { projects } });
    },
  });

  const { mutate: submitMutate, loading } = useApiData({
    onSuccess: () => {
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Data Saved Successfully",
      });
    },
    onError: () => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  const { favianConfig, favianProjects = {} } = favianData || {};

  function formSubmit() {
    if (!selected) {
      setError("Please select one.");
      return;
    }

    if (selected === "url" && !url) {
      setError("Url is required.");
      return;
    }

    setError("");

    const payload = {
      accessToken,
      chat_bot_id: id,
      type: selected === "url" ? "2" : "1", // 1-directory 2-url
      favian_project_id: selected,
      url: selected === "url" ? url : null,
    };

    submitMutate(() => api.post("/api/insertOrUpdate", payload));
  }

  useEffect(() => {
    mutate(() => api.post("/api/getFavianConfig", { chat_bot_id: id }));
  }, []);

  return (
    <ApiValidation
      loading={isLoadingConfig}
      isError={isError}
      error={errorConfig}
      loadingCls="ml-8"
    >
      <div className="text-lg pt-4 h-full">
        <h3 className="mb-4 font-semibold px-5">Favian</h3>

        <AccessTokenInput
          accessToken={accessToken}
          setAccessToken={setAccessToken}
          setFavianData={setFavianData}
          formSubmit={(e) => {
            e.preventDefault();
            setAccessTokenError("");
            checkApiMutate(() => api.post("/api/accessCheck", { accessToken }));
          }}
          loading={isChecking}
          accessTokenError={accessTokenError}
        />

        {favianProjects?.projects ? (
          <div className="flex justify-between w-[80%] pt-8">
            <h3 className="mb-4 font-semibold pl-5 w-[250px]">Location</h3>

            <div className="w-full h-full flex flex-col justify-between">
              <div>
                <TreeNode
                  label={
                    <div className="mt-0.5">
                      <i className="fa-regular fa-folder"></i> Projects
                    </div>
                  }
                >
                  {refactorProjects(favianProjects?.projects).map((p, i) => {
                    const { value, label } = p;

                    return (
                      <div className="flex items-center" key={i}>
                        <input
                          type="radio"
                          id={value}
                          value={value}
                          onChange={(e) => setSelected(e.target.value)}
                          checked={selected === value}
                          className="h-fit"
                        />
                        <div className="mt-1.5 ml-3">
                          <label for={value}>
                            <i className="fa-regular fa-folder"></i> {label}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </TreeNode>

                <div className="flex items-center mt-6">
                  <input
                    type="radio"
                    id="url"
                    value="url"
                    onChange={(e) => setSelected(e.target.value)}
                    checked={selected === "url"}
                    className="!h-[20px]"
                  />

                  <div
                    className={`ml-2 mt-0.5 ${
                      selected === "url" ? "required-input" : ""
                    }`}
                  >
                    <label for="url">Url</label>
                  </div>
                </div>

                {selected === "url" ? (
                  <input
                    type="text"
                    name="field_name"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="mb-2 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !normal-case"
                    required
                  />
                ) : null}

                {error ? <div className="form-error">{error}</div> : null}
              </div>

              <button
                onClick={formSubmit}
                className="flex px-8 py-2 rounded-md bg-indigo-600 text-white text-[16px] absolute bottom-4 right-4"
                disabled={loading}
              >
                {loading && <Loading />}
                Save Changes
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </ApiValidation>
  );
}

export default Favian;

function AccessTokenInput({
  accessToken,
  setAccessToken,
  formSubmit,
  loading,
  accessTokenError,
  setFavianData,
}) {
  return (
    <div className="pb-6 border-b border-gray-300">
      <form
        onSubmit={formSubmit}
        className="flex justify-between items-center w-[80%] mx-5"
      >
        <div className="w-[250px] text-[16px]">Access Token</div>

        <div className="flex items-center w-full">
          <input
            type="text"
            name="field_name"
            value={accessToken}
            onChange={(e) => {
              setAccessToken(e.target.value);
              setFavianData({});
            }}
            className="border rounded-md w-full px-2 py-2 truncate !normal-case text-sm"
            required
          />

          <button
            className="flex bg-indigo-600 text-white text-sm px-8 py-2 rounded-md ml-2 font-semibold"
            type="submit"
            disabled={loading}
          >
            {loading && <Loading />}
            Check
          </button>
        </div>
      </form>

      {accessTokenError ? (
        <div className="form-error">{accessTokenError}</div>
      ) : null}
    </div>
  );
}
