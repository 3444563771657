import React, { useState } from "react";
import ToolTip from "../../../../common/ToolTip";
import useDropDown from "../../../../../hooks/useDropDown";
import insert from "../../../../../assets/icons/playlist-add.svg";

function Insert({ label, children, optionsCls = "", disabled }) {
  const [open, setOpen] = useState();

  function handleLabelClick() {
    if (disabled) {
      return;
    }

    setOpen(true);
  }

  return (
    <div className="relative">
      {label ? (
        <div onClick={handleLabelClick} disabled={disabled}>
          {label}
        </div>
      ) : (
        <ToolTip tip="Insert" tipCls="bottom-[22px]">
          <img
            src={insert}
            alt="insert"
            className="cursor-pointer"
            onClick={handleLabelClick}
          />
        </ToolTip>
      )}

      {open ? (
        <InsertOptions
          open={open}
          setOpen={setOpen}
          label={label}
          optionsCls={optionsCls}
        >
          {children}
        </InsertOptions>
      ) : null}
    </div>
  );
}

export default Insert;

function InsertOptions(props) {
  const { open, setOpen, label, optionsCls } = props;

  const { elementRef } = useDropDown({ open, setOpen });

  return (
    <div
      className={`absolute z-50 bg-white rounded-md shadow-md border top-0 max-h-56 overflow-auto ${
        label ? "left-24" : "left-8"
      } text-sm text-gray-700 min-w-[150px] ${optionsCls}`}
      ref={elementRef}
    >
      {/* {children} */}

      {React.Children.map(props.children, (child) => {
        return React.cloneElement(child, props);
      })}
    </div>
  );
}
