import React, { useContext, useEffect, useState } from "react";
import api from "../../api/api";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { AppContext } from "../../App";
import MailAttachment from "./MailAttachment";
import MailThread from "./MailThread";
import ComposeEmail from "./ComposeMail";
import { Link } from "react-router-dom";
import Spinner from "../loaders/Spinner";

const MailDetail = (props) => {
  const [mail, setMail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [composeModal, setComposeModal] = useState(false);
  const [ticketsModal, setTicketsModal] = useState(false);
  const [tickets, setTickets] = useState();
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const fetchMail = (refresh = null) => {
    var postData = {
      uid: props.uid,
      community_id: currCommunityId,
      folder: props.activeFolder ? props.activeFolder : "INBOX",
      refresh: refresh,
    };
    setLoading(true);

    api
      .post("/api/getMail", postData)
      .then((response) => {
        setMail(response.data.data);
        setLoading(false);
        if (response.data.data.emails) {
          setTickets(
            response.data.data.emails.map((obj) => obj["ticketCreated"])
          );
        }
      })
      .catch((error) => {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went wrong",
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchMail();
  }, []);

  const Wrapper = styled.div`
    /* Add your custom styles for the wrapper here */
  `;

  return (
    <div>
      {loading && (
        <div className="flex items-center justify-center h-[50vh]">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="flex">
          <div className="p-5 api-html-container overflow-y w-full">
            <div className="flex  pb-5 justify-between items-center">
              <div>
                <Link to="/mails">
                  <i className="fa-solid fa-chevron-left bg-gray-100 w-[25px] h-[25px] flex justify-center items-center rounded-full mr-2"></i>
                </Link>
                <div className="font-semibold text-2xl subject ">
                  {mail?.subject}
                </div>
              </div>
              {tickets && (
                <div
                  className="cursor-pointer"
                  onClick={() => setTicketsModal(!ticketsModal)}
                >
                  <i className="fa-solid fa-clipboard-list"></i>
                </div>
              )}
            </div>
            <div className="mb-3">
              {mail?.emails.map((email) => (
                <MailThread
                  email={email}
                  messageId={mail?.messageId}
                  subject={mail?.subject}
                  handleLoading={setLoading}
                  fetchMail={fetchMail}
                />
              ))}
            </div>
          </div>
          {ticketsModal && (
            <div className="border">
              <div className="flex justify-between item-center">
                <span className="chat-header rounded-t-md w-full">
                  Tickets{" "}
                </span>
              </div>
              <div className="">
                <div className="flex h-auto min-h-[7rem] justify-center">
                  <div className="w-full rounded-b-md px-3 py-2 bg-white">
                    {tickets?.map(
                      (ticket) =>
                        ticket && (
                          <div>
                            <Link
                              to={`/ticket-detail/${ticket?.ticket_id}`}
                              className="w-[180px]"
                            >
                              {ticket?.ticket_id}
                            </Link>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MailDetail;
