import { useCallback, useEffect, useRef, useState } from "react";
import api from "../../api/api";
import io from "socket.io-client";
import { lighten, darken, saturate, desaturate } from "polished";
import moment from "moment";
import pkg from "socket.io-msgpack-parser";
import ReactMarkdown from "react-markdown";
import {
  MIME_TYPE_TO_EXTENSION_MAPPING,
  socketURL,
} from "../common/commonHelpers";
import AttachmentThumpnail from "../common/AttachmentThumpnail";
import useApiData from "../../hooks/useApiData";
import Loading from "../loaders/Loading";
const { customParser } = pkg;

const formatTime = (time) => {
  const dateTimeString = time?.split(" ")[1];
  const format = time?.split(" ")[2];
  return dateTimeString + " " + format;
};

const ChatBot = (props) => {
  const [messages, setMessages] = useState({
    messages: [{ message: "How can i help you?", type: "bot" }],
  });
  const messagesEndRef = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const [currSocketId, setCurrSocketId] = useState(false);
  const [socketState, setSocket] = useState(null);
  const [connectWithAgent, setConnectWithAgent] = useState(false);
  const [connectWithAgentText, setConnectWithAgentText] = useState(
    "Want to Connect with Agent"
  );
  const [formOpen, setFormOpen] = useState(false);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [existingSocket, SetExisitingSocket] = useState(false);
  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    hashed: props.id,
    hashedId: props.community_id,
  });
  const [offlineMessage, setOfflineMessage] = useState();
  const [firstMessage, setFirstMessage] = useState();
  const [faq, setFaq] = useState(false);
  const [color, setColor] = useState("#6231af");
  const [attachments, setAttachments] = useState([]);
  const inputFileRef = useRef(null);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const { mutate: getMsgsMutate, loading } = useApiData({
    onSuccess: (res) => {
      const { messages = [], pageNo } = res.data;
      console.log("res.data", messages, Number(pageNo) === 1, pageNo);

      if (messages.length) {
        var socketID = document.cookie.replace(
          new RegExp(
            `(?:(?:^|.*;\\s*)chatSocketID\\-${props.id}\\s*=\\s*([^;]*).*$)|^.*$`
          ),
          `$1`
        );

        const transformedMessages = messages.map((msg) => ({
          message: msg.message,
          attachments: msg.attachmets,
          type: msg.senderId === socketID ? "user" : "other",
          timestamp: msg.timestamp,
        }));

        setMessages((prev) => ({
          ...prev,
          messages:
            Number(pageNo) === 1
              ? transformedMessages
              : [...transformedMessages, ...prev.messages],
        }));
      } else {
        setHasMore(false);
      }
    },
    onError: (err) => {
      // setShowToast(true);
      // setToastMessage({
      //   type: "error",
      //   message: "Something went wrong while getting messages.",
      // });
    },
  });

  const getMsgs = useCallback(
    (pageNo) => {
      if (loading) return;

      var socketID = document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;\\s*)chatSocketID\\-${props.id}\\s*=\\s*([^;]*).*$)|^.*$`
        ),
        `$1`
      );

      const url = `${socketURL}/chatbot/messages/${socketID}?pageNo=${pageNo}`;

      getMsgsMutate(() => api.get(url));
    },
    [getMsgsMutate, loading, props]
  );

  useEffect(() => {
    if (props.isGpt == 1) {
      setSubmitEnable(true);
    } else {
      setSubmitEnable(false);
    }
    var messagesLocal = localStorage.getItem("chatBotDckap-" + props.id);
    if (
      messagesLocal != "" &&
      messagesLocal != undefined &&
      messagesLocal != null
    ) {
      setMessages(JSON.parse(messagesLocal));
      // console.log(JSON.parse(messagesLocal));
    }

    const startTime = localStorage.getItem("chatBotDckapStatus-" + props.id);

    if (startTime) {
      const chatLocalInterval = setInterval(() => {
        const elapsedTime = new Date().getTime() - startTime;
        const minutesPassed = Math.floor(elapsedTime / (1000 * 60));
        // console.log("elapsedTime 1", minutesPassed);
        if (minutesPassed >= 1) {
          document.cookie = "chatSocketID-" + props.id + "= ";
          localStorage.removeItem("chatBotDckapStatus-" + props.id);
          setConnectWithAgent(false);
          clearInterval(chatLocalInterval);
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (props.details?.data?.first_message) {
      var message = props.details?.data?.first_message;
    } else {
      var message = "Hi, How can i help you";
    }
    setFirstMessage(message);

    if (props.details?.data?.offline_message) {
      var offline_message = props.details?.data?.offline_message;
    } else {
      var offline_message =
        "Message Sent successfully. we will get back to you soon";
    }
    setOfflineMessage(offline_message);

    if (props.details?.data?.color) {
      var color = props.details?.data?.color;
    } else {
      var color = "#6231af";
    }
    setColor(color);
  }, [props.details]);

  useEffect(() => {
    var socketID = document.cookie.replace(
      new RegExp(
        `(?:(?:^|.*;\\s*)chatSocketID\\-${props.id}\\s*=\\s*([^;]*).*$)|^.*$`
      ),
      `$1`
    );
    if (socketID) {
      // setConnectWithAgent(true);
      // setSubmitEnable(true);
      setConnectWithAgentText("Want to Continue your Conversation?");
      SetExisitingSocket(true);
    } else {
      SetExisitingSocket(false);
    }
  }, []);
  const socketFunction = () => {
    if (socketState) {
      socketState.disconnect();
    }

    setProcessing(true);
    var socketID = document.cookie.replace(
      new RegExp(
        `(?:(?:^|.*;\\s*)chatSocketID\\-${props.id}\\s*=\\s*([^;]*).*$)|^.*$`
      ),
      `$1`
    );

    const socket = io(socketURL, {
      withCredentials: true,
      extraHeaders: {
        socketID: socketID,
      },
      auth: { token: "my-auth-token" },
      parser: customParser,
    });
    setSocket(socket);
    socket.on("connect", () => {
      console.log("Connecting socket chatbot.js");

      var chatSocketConnectionTime = document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;\\s*)chatSocketConnectionTime\\-${props.id}\\s*=\\s*([^;]*).*$)|^.*$`
        ),
        `$1`
      );
      if (!chatSocketConnectionTime) {
        const currentTime = new Date().getTime();
        document.cookie =
          "chatSocketConnectionTime-" + props.id + "=" + currentTime;
      }

      setProcessing(false);
      socket.emit("setUserType", "user", props.id, props.community_id);
      var cookieSocket = socketID;
      if (socketID) {
        socketID = socketID;
      } else {
        socketID = socket.id;
        document.cookie = "chatSocketID-" + props.id + "=" + socketID;
      }
      setCurrSocketId(socketID);

      socket.emit("join", socketID);

      if (!isCalling && !cookieSocket) {
        setProcessing(true);
        setTimeout(() => {
          socket.emit("sendMessage", {
            recipient: socketID,
            message: firstMessage,
            type: "user",
            senderId: props.community_id,
            status: "reopen",
            notification: socketID,
            file: attachments,
            botId: props.id,
          });
        }, [1500]);
      }

      if (isCalling === true) {
        setProcessing(true);
        setIsCalling(false);
        setTimeout(() => {
          socket.emit("sendMessage", {
            recipient: socketID,
            message: "invitedToCallAgent",
            type: "admin",
            senderId: socketID,
            status: "reopen",
            notification: props.community_id,
            file: attachments,
            botId: props.id,
          });
          window.open(`https://meet.jit.si/${socketID}`, "_blank");

          setNewMessage("");
          setAttachments([]);
        }, [1500]);
      }

      // socket.on("getMessages", (prevMessages) => {
      //   const transformedMessages = prevMessages.map((msg) => ({
      //     message: msg.message,
      //     attachments: msg.attachmets,
      //     type: msg.senderId === socketID ? "user" : "other",
      //     timestamp: msg.timestamp,
      //   }));
      //   // console.log(transformedMessages, prevMessages);
      //   setMessages({ messages: transformedMessages });
      // });
      socket.emit("connectedUsers");
      socket.on("sendMessage", (message) => {
        setProcessing(false);
        const isSentByCurrentUser = message.senderId === socketID;
        let chatLocalInterval;
        if (message.status == "resolved") {
          const currentTime = new Date().getTime();
          localStorage.setItem("chatBotDckapStatus-" + props.id, currentTime);

          const startTime = localStorage.getItem(
            "chatBotDckapStatus-" + props.id
          );
          if (startTime) {
            chatLocalInterval = setInterval(() => {
              var time = localStorage.getItem("chatBotDckapStatus-" + props.id);
              if (time) {
                const elapsedTime = new Date().getTime() - startTime;
                const minutesPassed = Math.floor(elapsedTime / (1000 * 60));
                // console.log("minutesPasses", minutesPassed);
                if (minutesPassed >= 5) {
                  localStorage.removeItem("chatBotDckapStatus-" + props.id);

                  document.cookie = "chatSocketID-" + props.id + "= ";
                  clearInterval(chatLocalInterval);
                }
              } else {
                clearInterval(chatLocalInterval);
              }
            }, 1000);
          }
        } else if (message.status == "reopen") {
          // console.log("dfdfdf");
          localStorage.removeItem("chatBotDckapStatus-" + props.id);
          clearInterval(chatLocalInterval);
          chatLocalInterval = null;
        }

        if (message.message === "invitedToCall") {
          // window.open("/peerCalling", "_blank");
        }

        setMessages((prevState) => {
          const messages = Array.isArray(prevState.messages)
            ? [
                ...prevState.messages,
                {
                  message: message.message,
                  attachments: message.attachmets,
                  type: isSentByCurrentUser ? "user" : "other",
                  timestamp: message.timestamp,
                },
              ]
            : [
                {
                  message: message.message,
                  attachments: message.attachmets,
                  type: isSentByCurrentUser ? "user" : "other",
                  timestamp: message.timestamp,
                },
              ];
          console.log("msg", message, message.status);

          return {
            messages,
          };
        });
      });

      socket.on("notification", (msg) => {
        if (socketID != msg.senderId) {
          playNotificationSound();
        }
      });
    });

    return () => {
      socket.disconnect();
    };
  };
  const playNotificationSound = () => {
    const audio = new Audio(
      require("../../assets/audio/the-notification-email-143029.mp3")
    );
    audio.play();
  };
  useEffect(() => {
    let disconnectSocket;

    if (connectWithAgent) {
      var fromTime = props.workingHrs.from_time;
      var toTime = props.workingHrs.to_time;
      var workingDays = props.selectedDays;

      var now = new Date();
      var currentDay = now.getDay().toString();
      var currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      var current = new Date(
        now.toLocaleString("en-US", { timeZone: currentZone })
      ).getTime();

      if (props.details?.data?.offline_status == 0) {
        setFormOpen(true);
        document.cookie = "chatSocketID-" + props.id + "= ";
        localStorage.removeItem("chatBotDckapStatus-" + props.id);
        setMessages({ messages: [] });
      } else if (fromTime === "" && toTime === "") {
        // Check workingDays only
        if (workingDays.includes(currentDay)) {
          // console.log("workingDays condition met");
          setFormOpen(false);
          disconnectSocket = socketFunction();
        } else {
          // console.log("workingDays condition not met");
          setFormOpen(true);
          document.cookie = "chatSocketID-" + props.id + "= ";
          localStorage.removeItem("chatBotDckapStatus-" + props.id);
          setMessages({ messages: [] });
        }
      } else if (workingDays.length === 0) {
        // Check fromTime and toTime only
        var from = new Date(
          now.toLocaleString("en-US", { timeZone: currentZone }).split(",")[0] +
            " " +
            fromTime
        );
        var to = new Date(
          now.toLocaleString("en-US", { timeZone: currentZone }).split(",")[0] +
            " " +
            toTime
        );

        if (current > from.getTime() && current < to.getTime()) {
          // console.log("fromTime and toTime condition met");
          setFormOpen(false);
          disconnectSocket = socketFunction();
        } else {
          // console.log("fromTime and toTime condition not met");
          setFormOpen(true);
          document.cookie = "chatSocketID-" + props.id + "= ";
          localStorage.removeItem("chatBotDckapStatus-" + props.id);
          setMessages({ messages: [] });
        }
      } else if (fromTime != "" && toTime != "" && workingDays?.length > 0) {
        // Check all conditions
        var from = new Date(
          now.toLocaleString("en-US", { timeZone: currentZone }).split(",")[0] +
            " " +
            fromTime
        );
        var to = new Date(
          now.toLocaleString("en-US", { timeZone: currentZone }).split(",")[0] +
            " " +
            toTime
        );

        if (
          workingDays.includes(currentDay) &&
          current > from.getTime() &&
          current < to.getTime()
        ) {
          // console.log("all conditions met");
          setFormOpen(false);
          disconnectSocket = socketFunction();
        } else {
          // console.log("all conditions not met");
          setFormOpen(true);
          document.cookie = "chatSocketID-" + props.id + "= ";
          localStorage.removeItem("chatBotDckapStatus-" + props.id);
          setMessages({ messages: [] });
        }
      } else {
        disconnectSocket = socketFunction();
      }
    } else if (disconnectSocket) {
      console.log("disconnecting socket");
      disconnectSocket();
    }

    return () => {
      if (disconnectSocket) {
        console.log("disconnecting socket on unmount");
        disconnectSocket();
      }
    };
  }, [connectWithAgent]);

  const handleSendMessage = (e, msg = null) => {
    e.preventDefault();
    if (msg) {
      msg = msg;
    } else {
      msg = newMessage;
    }

    if (connectWithAgent) {
      socketState.emit("sendMessage", {
        recipient: currSocketId,
        message: msg,
        type: "admin",
        senderId: currSocketId,
        status: "reopen",
        notification: props.community_id,
        botId: props.id,
        file: attachments,
      });
      if (msg === "invitedToCallAgent") {
        window.open(`https://meet.jit.si/${currSocketId}`, "_blank");
      }
      setProcessing(true);
      setNewMessage("");
      setAttachments([]);
    } else {
      setProcessing(true);
      var currentMessage = msg;
      setNewMessage("");
      setAttachments([]);
      var postData = {
        prompt: currentMessage,
        id: props.id,
      };
      setMessages((prevState) => {
        const messages = Array.isArray(prevState.messages)
          ? [...prevState.messages, { message: currentMessage, type: "user" }]
          : [{ message: currentMessage, type: "user" }];
        localStorage.setItem(
          "chatBotDckap-" + props.id,
          JSON.stringify({ messages })
        );
        return {
          messages,
        };
      });
      scrollToBottom();
      api.post("/api/chatBot", postData).then((response) => {
        // console.log(response);
        setProcessing(false);
        if (response.data.status == "success") {
          if (
            response.data.data.error == undefined &&
            response.data.data.error == null
          ) {
            setMessages((prevState) => {
              const messages = Array.isArray(prevState.messages)
                ? [
                    ...prevState.messages,
                    {
                      message: response.data.data.text?.replace("A:", ""),
                      type: "bot",
                    },
                  ]
                : [
                    {
                      message: response.data.data.text?.replace("A:", ""),
                      type: "bot",
                    },
                  ];
              localStorage.setItem(
                "chatBotDckap-" + props.id,
                JSON.stringify({ messages })
              );
              return {
                messages,
              };
            });
            scrollToBottom();
          } else {
            // console.log("invaild api key");
          }
        }
      });
    }
  };

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    if (formDetails.name != "" && formDetails.email != "") {
      setProcessing(true);
      var postData = formDetails;
      api
        .post("/api/createContact", postData)
        .then((response) => {
          setProcessing(false);
          if (response.data.status == "success") {
            setSuccessOpen(true);
          }
        })
        .catch((error) => {
          setProcessing(false);
        });
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const clearChat = (e) => {
    e.preventDefault();
    setMessages({
      messages: [{ message: "How can i help you?", type: "bot" }],
    });
    localStorage.removeItem("chatBotDckap-" + props.id);
  };

  const setFaqMessage = (message, id) => {
    setMessages((prevState) => {
      const messages = Array.isArray(prevState.messages)
        ? [...prevState.messages, { message: message, type: "user" }]
        : [{ message: message, type: "user" }];
      localStorage.setItem(
        "chatBotDckap-" + props.id,
        JSON.stringify({ messages })
      );
      return {
        messages,
      };
    });

    var postData = {
      prompt: message,
      id: props.id,
      quesId: id,
    };

    scrollToBottom();
    api.post("/api/chatBot", postData).then((response) => {
      // console.log(response);
      setProcessing(false);
      if (response.data.status == "success") {
        if (
          response.data.data.error == undefined &&
          response.data.data.error == null
        ) {
          setMessages((prevState) => {
            const messages = Array.isArray(prevState.messages)
              ? [
                  ...prevState.messages,
                  { message: response.data.data.answer, type: "bot" },
                ]
              : [{ message: response.data.data.answer, type: "bot" }];
            localStorage.setItem(
              "chatBotDckap-" + props.id,
              JSON.stringify({ messages })
            );
            return {
              messages,
            };
          });
          scrollToBottom();
        } else {
          // console.log("invaild api key");
        }
      }
    });
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            fileData,
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      console.log("attachmentFiles", attachmentFiles);
      setAttachments(attachmentFiles);
      setProcessing(false);
    } catch (error) {}
  };

  const processImage = (imageFile) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      // setImageDataURL(event.target.result);

      const fileData = event.target.result;
      const base64Data = fileData.split(",")[1];
      const mimeType = fileData.match(/^data:(.*?);/)[1];
      const fileName = imageFile.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (connectWithAgent) {
        setAttachments((prev) => [
          ...prev,
          {
            fileData,
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          },
        ]);
      }

      setNewMessage("");
    };
    reader.readAsDataURL(imageFile);
  };

  const onPaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const type = items[i].type;

      const isImage = type.indexOf("image") !== -1;

      const isPdf = type.indexOf("pdf") !== -1;

      const isSupportedDocs = MIME_TYPE_TO_EXTENSION_MAPPING[type];

      if (isImage || isSupportedDocs || isPdf) {
        const imageFile = items[i].getAsFile();
        processImage(imageFile);
      }
    }
  };

  //style

  const bgOriginal = {
    backgroundColor: color,
  };

  const bgLighten = {
    backgroundColor: lighten(0.5, color),
  };

  const textOriginal = {
    color: lighten(0.1, color),
  };

  return (
    <div className="mt-4 h-full-custom flex flex-col flex-grow w-[370px] right-0 justify-end bg-white border rounded-lg overflow-hidden chat-container">
      <div
        style={bgOriginal}
        className={`flex items-center justify-between border-b chat-top`}
      >
        <div className="flex items-center bg-white-500 text-gray-500 p-4">
          <div className=" mr-2 flex text-black justify-center items-center flex-shrink-0 h-10 w-10 rounded-full bg-[#f1f1f1] custom-tab">
            {props?.details?.data?.logo ? (
              <img src={props?.details?.data?.logo} className="w-10 h-10" />
            ) : props.name ? (
              Array.from(props.name)[0]
            ) : (
              "C"
            )}
          </div>
          <span className="text-black title">{props.name}</span>
        </div>
        <div className="flex items-center ">
          {props.details?.data?.is_whatsapp == 1 && (
            <a
              href={`https://wa.me/${props.details?.data?.whatsapp_number}?text=${props.details?.data?.whatsapp_first_message}`}
              target="__blank"
              className="flex mr-2 items-center call-btn justify-center w-[35px] h-[35px]   hover:text-white duration-300 ease cursor-pointer rounded-full text-sm"
            >
              <img src="https://rhinodeskbackend.dckap.com/storage/upload/uploads/13fabf85-db32-4286-9b44-85fe4e43ae49.png" />
            </a>
          )}
          {props.callAccept == 1 && (
            <div
              target="blank"
              onClick={(e) => {
                if (!connectWithAgent) {
                  setConnectWithAgent(true);
                  setSubmitEnable(true);
                  setIsCalling(true);
                } else {
                  handleSendMessage(e, "invitedToCallAgent");
                }
              }}
              style={bgLighten}
              className={`flex mr-2 items-center call-btn justify-center w-[35px] h-[35px]   hover:text-white duration-300 ease cursor-pointer rounded-full text-sm`}
            >
              <i style={textOriginal} className={`fa-solid fa-phone`}></i>
            </div>
          )}
          {props.clearChat && (
            <button
              type="button"
              className="mt-3 mr-5 clear-chat-btn inline-flex items-center justify-center button bg-gray-300 hover:bg-gray-400 text-gray-700 radius-round h-6 mr-2 text-sm px-4 py-4"
              onClick={(e) => clearChat(e)}
            >
              Clear Chat
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col flex-grow h-0 p-4 overflow-auto chat-widget">
        {connectWithAgent ? (
          <>
            {hasMore ? (
              <button
                className="bg-gray-500 px-4 py-2 my-4 rounded-full text-white w-fit mx-auto text-xs"
                disabled={loading}
                onClick={() => {
                  setPage((prev) => prev + 1);
                  getMsgs(page + 1);
                }}
                type="button"
              >
                {loading && <Loading />}
                Load More
              </button>
            ) : (
              <div className="text-gray-400 text-center my-4 text-sm mr-4">
                No messages
              </div>
            )}
          </>
        ) : null}

        {messages.messages.map(
          ({ message, type, attachments, timestamp }, index) =>
            type == "user" ? (
              <>
                <div
                  key={index}
                  className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end"
                >
                  <div>
                    {message ? (
                      <div
                        style={bgOriginal}
                        className={` custom-tab text-white p-3 rounded-l-lg rounded-br-lg`}
                      >
                        <p
                          className="text-sm max-w-[240px]"
                          dangerouslySetInnerHTML={{
                            __html: message,
                          }}
                        >
                          {/* {message} */}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex flex-col w-full mt-2 space-x-1 max-w-xs ml-auto items-end text-sm text-blue-500 underline">
                      {attachments?.map((file, index) => (
                        <a
                          href={file}
                          key={index}
                          download="download"
                          target="__blank"
                        >
                          Attachment-{index}
                        </a>
                      ))}
                    </div>
                    {connectWithAgent && (
                      <i className="text-[13px] font-normal flex w-full max-w-xs ml-auto justify-end">
                        {/* {moment(timestamp, "DD-MM-YYYY hh:mm:ss a").format(
                          "DD/MM/YYYY hh:mm A"
                        )} */}

                        {formatTime(timestamp)}
                      </i>
                    )}
                  </div>
                  <div
                    style={bgLighten}
                    className={`flex justify-center items-center flex-shrink-0 h-10 w-10 rounded-full custom-tab `}
                  >
                    <i className="fa-solid fa-user p-2 rounded-full "></i>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  key={index}
                  className="flex w-full mt-2 space-x-3 max-w-xs"
                >
                  <div
                    style={bgLighten}
                    className={`flex justify-center items-center flex-shrink-0 h-10 w-10 rounded-full  custom-tab`}
                  >
                    <i className="fa-solid fa-user p-2 rounded-full "></i>
                  </div>
                  <div>
                    <div
                      style={bgLighten}
                      className={` custom-tab p-3 rounded-r-lg rounded-bl-lg`}
                    >
                      {message == "invitedToCall" ? (
                        <>
                          <p className="text-sm">
                            Invited To connect. please click answer to connect
                          </p>
                          <a
                            href={`https://meet.jit.si/${currSocketId}`}
                            target="blank"
                            className="inline-flex items-center justify-center button bg-green-600 hover:bg-green-500 active:bg-green-700 text-white radius-round h-11 px-8 py-2 w-full"
                          >
                            Answer
                          </a>
                        </>
                      ) : message ? (
                        <p className="text-sm markdown">
                          <ReactMarkdown>{message}</ReactMarkdown>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="faq">
                      {faq && (
                        <>
                          {props?.filteredQuestions?.map((question, index) => (
                            <div
                              key={index}
                              className={`hover-scale cursor-pointer text-xs ease-in duration-300 border-indigo-100 border p-1 mt-2 rounded-md`}
                              onClick={() =>
                                setFaqMessage(question.question, question.id)
                              }
                            >
                              {question.question}
                            </div>
                          ))}
                        </>
                      )}

                      {connectWithAgent == false ? (
                        <>
                          {!faq && props?.filteredQuestions?.length > 0 && (
                            <div
                              className={`hover-scale cursor-pointer text-xs ease-in duration-300 border-indigo-100 border p-1 mt-2 rounded-md`}
                              onClick={() => {
                                setFaq(true);
                                setMessages({
                                  messages: [
                                    {
                                      message:
                                        "Please select any option in below",
                                      type: "bot",
                                    },
                                  ],
                                });
                              }}
                            >
                              Faq
                            </div>
                          )}
                          {!props.off && (
                            <div
                              className={`hover-scale cursor-pointer text-xs ease-in duration-300 border-indigo-100 border p-1 mt-2 rounded-md`}
                              onClick={(e) => {
                                setConnectWithAgent(true);
                                setSubmitEnable(true);
                                setMessages({
                                  messages: [],
                                });
                                getMsgs(1);
                              }}
                            >
                              {connectWithAgentText}
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex flex-col w-full mt-2 space-x-1  text-sm text-blue-500 underline">
                      {attachments?.map((file, index) => (
                        <a
                          href={file}
                          key={index}
                          download="download"
                          target="__blank"
                        >
                          Attachment-{index}
                        </a>
                      ))}
                    </div>
                    {connectWithAgent && (
                      <i className="text-[13px] font-normal flex w-full ml-auto ">
                        {/* {moment(
                          new Date(timestamp),
                          "DD-MM-YYYY hh:mm:ss a"
                        ).format("DD/MM/YYYY hh:mm A")} */}

                        {formatTime(timestamp)}
                      </i>
                    )}
                  </div>
                </div>
              </>
            )
        )}
        <div ref={messagesEndRef} />
        {formOpen && !successOpen && (
          <>
            <form
              action="#"
              onSubmit={(e) => handleContactFormSubmit(e)}
              className=""
            >
              <div className="px-3">
                <div className="mt-4">
                  <div className="form-container vertical">
                    <div className="form-item  mb-3 vertical">
                      <label className="form-label mb-2 text-sm">Name</label>
                      <div>
                        <input
                          className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                          type="text"
                          name="name"
                          autoComplete="off"
                          placeholder="Enter name"
                          defaultValue="BALA NK"
                          required={attachments?.length <= 0}
                          onChange={(e) => handleFormChange(e)}
                          value={formDetails.name}
                        />
                      </div>
                    </div>
                    <div className="form-item mb-3 vertical">
                      <label className="form-label mb-2 text-sm">Email</label>
                      <div>
                        <input
                          className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                          type="text"
                          name="email"
                          autoComplete="off"
                          placeholder="Enter email"
                          defaultValue=""
                          required
                          onChange={(e) => handleFormChange(e)}
                          value={formDetails.email}
                        />
                      </div>
                    </div>
                    <div className="form-item mb-3 vertical">
                      <label className="form-label mb-2 text-sm">Message</label>
                      <div>
                        <textarea
                          className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                          name="message"
                          onChange={(e) => handleFormChange(e)}
                          value={formDetails.message}
                          placeholder="Enter your message here"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center px-3 pb-6 space-x-2  border-gray-200 rounded-b">
                <button
                  style={bgOriginal}
                  className={`inline-flex items-center justify-center button text-white radius-round h-11 px-8 py-2 w-full`}
                  type="submit"
                  disabled={processing}
                >
                  {processing && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}{" "}
                  Submit
                </button>
              </div>
            </form>
          </>
        )}

        {successOpen && (
          <div className="flex w-72 items-center justify-center h-screen">
            <div>
              <div className="flex flex-col items-center space-y-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-green-600 w-28 h-28"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={1}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <h1 className="text-4xl font-bold">Thank You !</h1>
                <p className="text-center">{offlineMessage}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <p className="pb-1 text-sm text-gray-500 text-center">
        Powered By{" "}
        <a
          href="https://rhinodesk.dckap.com/"
          target="__blank"
          style={textOriginal}
        >
          RhinoDESK
        </a>
      </p>
      {!formOpen && submitEnable ? (
        <>
          <form
            className="flex bg-white-300 border-t p-4 items-center"
            onSubmit={(e) => {
              handleSendMessage(e);
            }}
          >
            <input
              className="flex items-center border h-10 w-full rounded px-3 text-sm"
              type="text"
              placeholder="Type your message…"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              required
              onPaste={onPaste}
            />
            <button
              type="submit"
              style={bgOriginal}
              className={`ml-2 inline-flex send-btn items-center justify-center button text-white radius-round h-10 px-8 py-2`}
              disabled={processing}
            >
              {processing == true ? (
                <svg
                  className="animate-spin -ml-1 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Send"
              )}
            </button>
            <div>
              <button
                className={`border ml-2 duration-300 ease px-4 py-2 rounded file-attachment z-10`}
                onClick={handleSelectButtonClick}
                type="button"
              >
                <i className="fa-solid fa-paperclip"></i>
              </button>
              <input
                type="file"
                className="hidden"
                ref={inputFileRef}
                onChange={handleFileUpload}
                multiple
                disabled={processing}
              />
            </div>
          </form>
          {attachments?.length > 0 && (
            <AttachmentThumpnail
              attachments={attachments}
              onRemove={(index) => {
                setAttachments((prev) => {
                  const remaining = prev.filter((p, i) => i !== index);

                  return remaining;
                });
              }}
            />
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ChatBot;
