import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { AppContext } from "../../App";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const TicketColumns = (props) => {
  const [processing, setProcessing] = useState(false);
  const [columnModal, setColumnModal] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setColumnModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);

  return (
    <div ref={selectRef} className="relative w-full">
      <div
        className="cursor-pointer view-btn bg-indigo-50 text-indigo-600 radius-round h-full text-sm rounded-md flex justify-center items-center w-full"
        onClick={() => setColumnModal(!columnModal)}
      >
        <img
          src="/images/icons/filter.svg"
          alt="filter-column"
          className="hover:bg-indigo-100"
        />
      </div>
      {columnModal && (
        <div className=" px-2 py-1 text-xs border rounded-md bg-white z-10 top-12 right-0 shadow-xl w-[150px] absolute filter-dropdown">
          <div className="mt-2 filter-dropdown-body grid lg:grid-cols-1 gap-2">
            <div className="!text-left">
              <label
                htmlFor=""
                className="mb-5 font-semibold text-gray-600 text-[14px]"
              >
                Columns
              </label>
              <div className="mt-3 flex gap-2">
                <input
                  type="checkbox"
                  name="Issue No"
                  className=" p-3 block border-gray-300"
                  onChange={(e) =>
                    props.handleColumns(e.target.name, e.target.checked)
                  }
                  checked={props.activeColumns["Issue No"]}
                />
                <label className="font-normal">Issue No</label>
              </div>
              <div className="mt-2 flex gap-2">
                <input
                  type="checkbox"
                  className=" p-3 block border-gray-300 "
                  name="Topic"
                  onChange={(e) =>
                    props.handleColumns(e.target.name, e.target.checked)
                  }
                  checked={props.activeColumns["Topic"]}
                />
                <label className="font-normal">Topic</label>
              </div>
              {/* <div className="mt-2 flex gap-2">
                <input
                  type="checkbox"
                  className=" p-3 block border-gray-300 "
                  name="Assignee"
                  onChange={(e) =>
                    props.handleColumns(e.target.name, e.target.checked)
                  }
                  checked={props.activeColumns["Assignee"]}
                />
                <label className="font-normal">Assignee</label>
              </div> */}
              <div className="mt-2 flex gap-2">
                <input
                  type="checkbox"
                  className=" p-3 block border-gray-300 "
                  name="Priority"
                  onChange={(e) =>
                    props.handleColumns(e.target.name, e.target.checked)
                  }
                  checked={props.activeColumns["Priority"]}
                />
                <label className="font-normal">Priority</label>
              </div>
              <div className="mt-2 flex gap-2">
                <input
                  type="checkbox"
                  className=" p-3 block border-gray-300 "
                  name="Clients"
                  onChange={(e) =>
                    props.handleColumns(e.target.name, e.target.checked)
                  }
                  checked={props.activeColumns["Clients"]}
                />
                <label className="font-normal">Clients</label>
              </div>
              <div className="mt-2 flex gap-2">
                <input
                  type="checkbox"
                  className=" p-3 block border-gray-300 "
                  name="Status"
                  onChange={(e) =>
                    props.handleColumns(e.target.name, e.target.checked)
                  }
                  checked={props.activeColumns["Status"]}
                />
                <label className="font-normal">Status</label>
              </div>
              {props.commnuityFields?.map((field, index) => {
                return typeof field.field_name === "string" ? (
                  <div key={index} className="mt-2 flex gap-2">
                    <input
                      type="checkbox"
                      className=" p-3 block border-gray-300 "
                      name={field.field_name}
                      onChange={(e) =>
                        props.handleColumns(e.target.name, e.target.checked)
                      }
                      checked={props.activeColumns[field.field_name]}
                    />
                    <label className="font-normal">{field.field_name}</label>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketColumns;
