import { useEffect, useRef, useContext, useState } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";

const AssignDropdown = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);

  const assignAgent = (id, ticketId) => {
    props.submitAssign(id, ticketId);
  };

  const assignTeam = (id) => {
    props.submiteamAssign(id);
  };

  return (
    <div
      id="assign-modal-chat"
      className={`${props.additionalclassName} assign-modal-chat rounded-md`}
    >
      {(props.loadingAssign || props.isTeamLoading) && (
        <div className="z-[999] w-full h-full assignTicketLoagingOverlay">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={0}
            viewBox="0 0 24 24"
            className="animate-spin text-indigo-600"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: 20, width: 20 }}
          >
            <path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              fill="currentColor"
            />
            <path
              d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}
      {!props.popOpen?.open && (
        <div>
          <div
            className="flex toggler justify-between agentList p-1 capitalize rounded-sm  hover:bg-indigo-50 duration-300 ease-in-out"
            onClick={() => {
              props.handlePopOpen({
                open: true,
                type: "CURRENT_TEAM",
              });
              props.getTeam();
            }}
          >
            Team Members
            <i className="fa-solid fa-chevron-right"></i>
          </div>
          <div
            className="flex toggler justify-between agentList p-1 capitalize rounded-sm  hover:bg-indigo-50 duration-300 ease-in-out"
            onClick={() =>
              props.handlePopOpen({
                open: true,
                type: "OTHER_TEAM",
              })
            }
          >
            Other team
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      )}
      {props.popOpen?.type == "CURRENT_TEAM" && props.popOpen?.open && (
        <div className="relative">
          <button
            className="text-left toggler text-sm border-b w-full pb-2"
            onClick={() =>
              props.handlePopOpen({
                open: false,
                type: "",
              })
            }
          >
            <i class="mr-2 fa-solid fa-chevron-left cursor-pointer mt-2 ml-2"></i>
            Current Team
          </button>
          {props.teamData &&
            props.teamData[0]?.users?.map((data, index) => (
              <>
                <div
                  id={data?.id}
                  className={`${
                    props.handled_by == data?.id
                      ? "bg-indigo-50 text-indigo-500"
                      : ""
                  } agentList p-1 capitalize rounded-sm  hover:bg-indigo-50 duration-300 ease-in-out`}
                  onClick={() =>
                    props.handled_by == data?.id
                      ? ""
                      : assignAgent(data.id, props.ticketId)
                  }
                >
                  {data.username}
                </div>
              </>
            ))}
        </div>
      )}
      {props.popOpen?.type == "OTHER_TEAM" && props.popOpen?.open && (
        <div className="relative">
          <button
            className="text-left toggler text-sm border-b w-full pb-2"
            onClick={() =>
              props.handlePopOpen({
                open: false,
                type: "",
              })
            }
          >
            <i class="mr-2 fa-solid fa-chevron-left cursor-pointer mt-2 ml-2"></i>
            Other Teams
          </button>
          {props?.teamsData?.map((data, index) => (
            <div
              id={data?.team_id}
              className={`agentList p-1 capitalize rounded-sm  hover:bg-indigo-50 duration-300 ease-in-out`}
              onClick={() => assignTeam(data.team_id)}
            >
              {data.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssignDropdown;
