import { useState, useContext } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { AppContext } from "../../App";
import api from "../../api/api";
import ModuleForm from "./ModuleForm";

const FormPreviewPage = (props) => {
  const [formValues, setFormValues] = useState({});
  const [response, setResponse] = useState("");
  const [processing, setProcessing] = useState(false);
  const [embedModal, setEmbedModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState(
    "<a target='blank' href='https://rhinodeskcrm.dckap.com/module/" +
      props.folderDetails[0].uuid +
      "'></a>"
  );
  const { currUser, getUserInfo, setToastMessage, setShowToast } =
    useContext(AppContext);

  const generatePromptContent = (formValues, fields) => {
    let promptContent = props.userPromptValue;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const fieldValue = formValues[field.label] || "";
      promptContent = promptContent.replace(
        new RegExp(`{${field.label}}`, "g"),
        fieldValue
      );

      // console.log("sdfsdf", field.label, fieldValue);
    }
    setProcessing(true);
    var postData = {
      prompt: promptContent,
      id: props.folderDetails[0].uuid,
    };
    api
      .post("/api/moduleGpt", postData)
      .then((response) => {
        if (response.data.data != null) {
          setProcessing(false);

          if (
            response.data.data.error != undefined &&
            response.data.data.error != null
          ) {
            setShowToast(true);
            setToastMessage({ type: "error", message: "Invaild Gpt Key" });
          } else {
            setResponse(response.data.data.choices[0].message.content);
            // console.log("newRes", response);
          }
        } else {
          setProcessing(false);
        }
      })
      .catch((error) => {
        setProcessing(false);
      });
  };
  return (
    <div className="w-full">
      <div className="flex justify-between">
        <span className=" text-2xl font-bold mb-4 mb-5 block">
          Preview your Module
        </span>
        <button
          type="button"
          className="mt-3 inline-flex items-center justify-center button bg-gray-100 light-blue-hover text-gray-700 radius-round h-6 text-sm px-4 py-4"
          onClick={() => setEmbedModal(!embedModal)}
        >
          Embed Code
        </button>
      </div>
      <ModuleForm
        folderDetails={props.folderDetails}
        setFieldValue={props.setFieldValue}
        fieldValue={props.fieldValue}
        processing={props.processing}
        userPromptValue={props.userPromptValue}
      />
      {embedModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="text-center flex items-start justify-between p-4 rounded-t">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Implement ChatBot In your Website
                  </h3>

                  <p className="mt-5">
                    <label
                      htmlFor="email"
                      className="relative text-gray-400 focus-within:text-gray-600 block"
                    >
                      <input
                        type="text"
                        name="text"
                        placeholder=""
                        value={embedUrl}
                        className="mb-4 pr-11 pt-2 pb-2 pl-2 input form-input w-full border"
                      />
                      <button
                        type="button"
                        data-copy-state="copy"
                        className="copyInput flex items-center text-xs font-medium text-gray-600 bg-gray-100 border-l border-gray-200   hover:text-blue-700   copy-to-clipboard-button"
                        onClick={() => {
                          navigator.clipboard.writeText(embedUrl);
                          setShowToast(true);
                          setToastMessage({
                            type: "success",
                            message: "Copied code to clipboard",
                          });
                        }}
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                          />
                        </svg>
                      </button>
                    </label>
                    Implement ChatBot In your Website, paste the above code in
                    end of the body tag
                  </p>
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center    "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setEmbedModal(false)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormPreviewPage;
