const TableSkeleton = () => {
  return (
    <div className="relative shadow-sm mt-10 sm:rounded-md">
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 issue-list-title">
          <tr>
            <th scope="col" className="px-6 py-3 cursor-pointer">
              <div className="flex gap-1">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </div>
            </th>
            <th scope="col" className="px-6 py-3">
              <div className="flex gap-1">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </div>
            </th>
            <th scope="col" className="px-6 py-3 ">
              <span
                className="skeleton"
                style={{ height: "20px", width: "85%" }}
              ></span>
            </th>
            <th scope="col" className="px-6 py-3  cursor-pointer">
              <div className="flex gap-1">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </div>
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer ">
              <div className="flex gap-1">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 6 }).map((_, index) => (
            <tr className="bg-white border-b issue-lists">
              <th
                scope="row"
                className="text-indigo-500 px-6 py-4 font-medium text-gray-900 whitespace-nowrap  ticket-id"
              >
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </th>
              <td className="px-6 py-4">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </td>
              <td className="px-6 py-4">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </td>
              <td className="px-6 py-4 ">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </td>
              <td className="px-6 py-4 ">
                <span
                  className="skeleton"
                  style={{ height: "20px", width: "85%" }}
                ></span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
