import { useContext, useEffect, useRef, useState } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import useApiData from "../../hooks/useApiData";
import Spinner from "../loaders/Spinner";
import { Link } from "react-router-dom";
import Select from "react-select";
import Loading from "../loaders/Loading";
import NewTicketForm from "./NewTicketForm";
import DropDown from "../common/DropDown";

const addTicketOptions = [
  {
    label: "Create New Ticket",
    value: "createNewTicket",
  },
  {
    label: "Add Existing Ticket",
    value: "addExistingTicket",
  },
];

function ChildTickets({ ticketDetail }) {
  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);

  const [showAddTicketOptions, setShowAddTicketOptions] = useState(false);

  const [createTicketBy, setCreateTicketBy] = useState();

  const [ticketOptions, setTicketOptions] = useState([]);

  const [processing, setProcessing] = useState(false);

  const btnRef = useRef(null);

  const {
    mutate: getChildTickets,
    loading,
    data,
  } = useApiData({
    onSuccess: () => {},
    onError: () => {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong while getting child tickets",
      });
    },
  });

  function getChildTicketsApi() {
    getChildTickets(() =>
      api.get(
        `/api/getChildTickets/${currCommunityId}/ticket/${ticketDetail?.ticket_id}`
      )
    );
  }

  useEffect(() => {
    if (ticketDetail) {
      getChildTicketsApi();
    }
  }, [ticketDetail]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       btnRef.current &&
  //       !btnRef.current.contains(event.target) &&
  //       event.target.closest("#related-modal-ticket") == null
  //     ) {
  //       setRelatedModal(false);
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    if (ticketDetail) {
      var postData = {
        value: "",
        community_id: currCommunityId,
        ticket_id: ticketDetail.ticket_id,
        childTicket: true,
      };
      api.post("/api/searchTickets", postData).then((response) => {
        if (response.data.status == "success") {
          const formattedOptions = response.data.data.map((ticket) => ({
            value: ticket.ticket_id,
            label: ticket.topic,
          }));
          setTicketOptions(formattedOptions);
        }
      });
    }
  }, [ticketDetail]);

  console.log("data", data);

  if (loading && !data) {
    return <Loading />;
  }

  const childTickets = data?.data || [];

  const ticketSelectInput = (newValue) => {
    // console.log(newValue);
    var postData = {
      value: newValue,
      community_id: currCommunityId,
      ticket_id: ticketDetail.ticket_id,
      childTickets: true,
    };
    api.post("/api/searchTickets", postData).then((response) => {
      if (response.data.status == "success") {
        const formattedOptions = response.data.data.map((ticket) => ({
          value: ticket.ticket_id,
          label: ticket.topic,
        }));
        setTicketOptions(formattedOptions);
      }
    });
  };
  const onSelectChange = (value) => {
    if (value) {
      addChildTicket(value.value);
    }
  };

  const addChildTicket = (value) => {
    setProcessing(true);
    var postData = {
      child_ticket_id: value,
    };

    setCreateTicketBy(null);

    api
      .post(
        `/api/createChildTickets/${currCommunityId}/ticket/${ticketDetail?.ticket_id}`,
        postData
      )
      .then((response) => {
        if (response.data.status == "success") {
          //   props.handleTicketDetail(response.data.data);
        }
        getChildTicketsApi();
        setProcessing(false);
        setShowAddTicketOptions(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Child Ticket Added",
        });
      })
      .catch((error) => {
        const msg = error?.response?.data?.msg;

        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: msg || "Something Went Wrong",
        });
      });
  };

  const removeChildTicket = (value) => {
    setProcessing(true);

    api
      .delete(`/api/removeChildTickets/${currCommunityId}/childTicket/${value}`)
      .then((response) => {
        if (response.data.status == "success") {
          //   props.handleTicketDetail(response.data.data);
        }
        getChildTicketsApi();
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Related Ticket Removed",
        });
        setProcessing(false);
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  return (
    <div className="w-full">
      <ul>
        {childTickets?.map((ticket, index) => (
          <span className="assign-div cursor-pointer flex items-center justify-between gap-2 pb-2 rounded-md">
            <a
              href={`/ticket-detail/${ticket.child_ticket_no}`}
              target="_blank"
              rel="noreferrer"
              className="hover:text-blue-700"
            >
              <li className="three-dots">
                {ticket.child_ticket_no} - {ticket.topic}
              </li>
            </a>
            <span
              className="assign-remove font-semibold text-gray-500"
              onClick={() => removeChildTicket(ticket.uuid)}
            >
              {processing ? <Loading /> : <i className="fa fa-times"></i>}
            </span>
          </span>
        ))}
      </ul>

      <div className="relative w-full">
        <DropDown
          label={
            <button
              type="button"
              className="flex duration-300 ease-in text-indigo-600 font-medium text-sm"
              disabled={processing}
              onClick={() => setShowAddTicketOptions(true)}
              ref={btnRef}
            >
              {processing && <Loading />}
              Add Child Tickets
            </button>
          }
          childrenCls="w-full"
          onClose={() => {
            if (createTicketBy === "addExistingTicket") {
              setCreateTicketBy("");
            }
          }}
        >
          {addTicketOptions.map((a, i) => (
            <button
              className={`w-full hover:bg-gray-100 py-2 
              ${a.value === createTicketBy ? "bg-indigo-600 text-white " : ""}
              ${i === 0 ? "border-b" : ""}`}
              onClick={() => setCreateTicketBy(a.value)}
            >
              {a.label}
            </button>
          ))}

          {createTicketBy === "addExistingTicket" && (
            <div
              id="related-modal-ticket"
              className="related-modal-ticket !top-[100px] z-[100]"
            >
              <Select
                className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                options={[...ticketOptions]}
                onInputChange={ticketSelectInput}
                onChange={onSelectChange}
                isClearable
                components={{ DropdownIndicator: () => null }}
                isSearchable
                menuIsOpen="open"
                formatOptionLabel={({ value, label }) => (
                  <div>
                    <span>{value}</span>
                    <span> -- </span>
                    <span>{label}</span>
                  </div>
                )}
              />
            </div>
          )}
        </DropDown>

        {createTicketBy === "createNewTicket" ? (
          <NewTicketForm
            childTicket={ticketDetail?.ticket_id}
            setOpen={setCreateTicketBy}
            refetch={() => {
              getChildTicketsApi();
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ChildTickets;
