import { useEffect, useState, useContext } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import ChatBot from "../../components/chatBot/ChatBot";
import api from "../../api/api";

const ChatBotContainer = () => {
  const { id } = useParams();
  const [openWidget, setOpenWidget] = useState(false);
  const [name, setName] = useState();
  const [workingHrs, setWorkingHrs] = useState({ from_time: "", to_time: "" });
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [community_id, setCommunity_id] = useState(null);
  const [isGpt, setIsGpt] = useState();
  const [callAccept, setCallAccept] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [details, setDetails] = useState([]);
  useEffect(() => {
    if (openWidget == true) {
      window.parent.postMessage({ height: "550px", width: "500px" }, "*");
    } else {
      window.parent.postMessage({ height: "100px", width: "100px" }, "*");
    }
    // Send a message to the parent window with the height of the iframe

    var postData = {
      id: id,
    };
    api
      .post("/api/folderDetailChatBot", postData)
      .then((response) => {
        if (response.data.data != null) {
          setDetails(response.data);
          setName(response.data.data.name);
          setFilteredQuestions(response.data.qadata);
          setIsGpt(response.data.data.is_gpt);
          setCallAccept(response.data.data.is_call);
          setCommunity_id(response.data.data.hasedData);
          if (
            response.data.data.from_time != null &&
            response.data.data.to_time != null
          ) {
            var from_dateObj = new Date(response.data.data.from_time);

            var from_hours = from_dateObj.getHours();
            var from_minutes = from_dateObj.getMinutes();

            var from_hoursStr =
              from_hours < 10 ? "0" + from_hours : from_hours.toString();
            var from_minutesStr =
              from_minutes < 10 ? "0" + from_minutes : from_minutes.toString();

            var from_hoursMinutes = from_hoursStr + ":" + from_minutesStr;

            var to_dateObj = new Date(response.data.data.to_time);

            var to_hours = to_dateObj.getHours();
            var to_minutes = to_dateObj.getMinutes();

            var to_hoursStr =
              to_hours < 10 ? "0" + to_hours : to_hours.toString();
            var to_minutesStr =
              to_minutes < 10 ? "0" + to_minutes : to_minutes.toString();

            var to_hoursMinutes = to_hoursStr + ":" + to_minutesStr;

            setWorkingHrs({
              from_time: from_hoursMinutes,
              to_time: to_hoursMinutes,
            });
          }
          if (response.data.data.days) {
            setSelectedDays(response.data.data.days.split(","));
          }
        } else {
        }
      })
      .catch((error) => {});
  }, [openWidget]);

  const bgOriginal = {
    backgroundColor: details?.data?.color ? details?.data?.color : "#6231af",
  };

  useEffect(() => {
    const chatLocalInterval = setInterval(() => {
      var chatSocketConnectionTime = document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;\\s*)chatSocketConnectionTime\\-${id}\\s*=\\s*([^;]*).*$)|^.*$`
        ),
        `$1`
      );
      if (chatSocketConnectionTime) {
        const elapsedTime = new Date().getTime() - chatSocketConnectionTime;
        const minutesPassed = Math.floor(elapsedTime / (1000 * 60));
        console.log("elapsedTime 1", minutesPassed);
        if (minutesPassed >= 540) {
          document.cookie = "chatSocketID-" + id + "= ";
          document.cookie = "chatSocketConnectionTime-" + id + "= ";
          // clearInterval(chatLocalInterval);
          var status = "resolved";
          var socketID = document.cookie.replace(
            new RegExp(
              `(?:(?:^|.*;\\s*)chatSocketID\\-${id}\\s*=\\s*([^;]*).*$)|^.*$`
            ),
            `$1`
          );
          var postData = {
            status: status,
            client_id: socketID,
          };

          api
            .post("/api/updateMessage", postData)
            .then((response) => {
              if (response.data.status == "success") {
              }
            })
            .catch(() => {});
        }
      }
    }, 1000);
  }, []);

  if (details?.data != undefined && details?.data != null) {
    return (
      <div className="chatBotWidgetContainer">
        {openWidget && (
          <div className="chatBotWidget">
            <ChatBot
              id={id}
              name={name}
              workingHrs={workingHrs}
              filteredQuestions={filteredQuestions}
              isGpt={isGpt}
              selectedDays={selectedDays}
              community_id={community_id}
              callAccept={callAccept}
              details={details}
            />
          </div>
        )}

        <div
          style={bgOriginal}
          className="chatIcon"
          onClick={() => setOpenWidget(!openWidget)}
        >
          {openWidget ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="close"
            >
              <path
                fill="#fff"
                d="M7 18a1 1 0 0 1-.707-1.707l10-10a1 1 0 0 1 1.414 1.414l-10 10A.997.997 0 0 1 7 18Z"
              />
              <path
                fill="#fff"
                d="M17 18a.997.997 0 0 1-.707-.293l-10-10a1 1 0 0 1 1.414-1.414l10 10A1 1 0 0 1 17 18Z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="chat-bubble"
              version="1.0"
              width="32.000000pt"
              height="32.000000pt"
              viewBox="0 0 32.000000 32.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  fill="#fff"
                  d="M40 245 c-7 -9 -13 -33 -13 -55 0 -42 16 -70 39 -70 8 0 14 -9 14 -20 0 -25 8 -25 40 0 14 11 37 20 51 20 35 0 54 24 54 70 0 59 -16 70 -100 70 -53 0 -76 -4 -85 -15z"
                />
                <path
                  fill="#fff"
                  d="M240 182 c0 -45 -23 -72 -62 -72 -14 0 -31 -7 -38 -15 -10 -13 -9 -15 10 -15 12 0 33 -9 47 -20 32 -25 43 -25 43 -2 0 9 10 22 23 27 41 18 34 135 -9 135 -10 0 -14 -12 -14 -38z"
                />
              </g>
            </svg>
          )}
        </div>
      </div>
    );
  }
};

export default ChatBotContainer;
