import { useContext, useRef, useState } from "react";
import Button from "../../../components/common/Button";
import Loading from "../../../components/loaders/Loading";
import ToggleBtn from "../../../components/common/ToggleBtn";
import Theme from "./Theme";
import AvailableThemes from "./AvailableThemes";
import { X } from "lucide-react";
import { AppContext } from "../../../App";

const supportDomainPrefix = `${window.location.origin}/support/`;

function getDefaultForm(defaultValue) {
  if (defaultValue) {
    const { name, url, theme, status, image, info } = defaultValue;

    return {
      image,
      info,
      name,
      theme,
      status,
      url: url?.split(supportDomainPrefix)[1],
    };
  }

  return {
    name: "",
    url: "",
    theme: "CUSTOMER_PORTAL",
    status: 1,
    image: "",
    info: "",
    removeImage: false,
  };
}

function PortalForm({ onCancel, defaultValue, loading, onSubmit, error }) {
  const [form, setForm] = useState(getDefaultForm(defaultValue));
  const [uploadedImage, setUploadedImage] = useState(null);
  const hiddenFileInput = useRef(null);
  const { onSuccess, onError } = useContext(AppContext);

  function updateForm(e) {
    const { name, value } = e.target;

    setForm((prev) => ({ ...prev, [name]: value }));
  }

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setUploadedImage(selectedImage);
    if (selectedImage) {
      const fileType = selectedImage.type.split("/")[0];
      if (fileType !== "image") {
        onError("File type not supported. Please upload an image.");

        e.target.value = "";
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        setForm((prev) => ({
          ...prev,
          image: event.target.result,
          removeImage: false,
        }));
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const { name, url, status, image, info, theme, removeImage } = form;
  return (
    <form
      className="mt-6"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("name", name);
        formData.append("url", supportDomainPrefix + url);
        if (uploadedImage) {
          formData.append("image", uploadedImage);
        }
        formData.append("info", info);
        formData.append("status", status);
        formData.append("theme", theme);
        formData.append("removeImage", removeImage);
        onSubmit(formData);
      }}
      enctype="multipart/form-data"
    >
      <InputWrapper label="Portal Name" error={""} requiredInput={true}>
        <input
          name="name"
          type="text"
          className="border border-gray-400 rounded-md px-2 py-1 outline-none w-full"
          required
          onChange={updateForm}
          value={name}
        />
      </InputWrapper>

      <InputWrapper label="Portal Image" error={""} requiredInput={false}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-1"
          ref={hiddenFileInput}
        />
        {removeImage && image && (
          <p className="mt-3 ">(Submit the form to save the changes)</p>
        )}
        {!removeImage && image && (
          <div className="relative w-[300px] mt-3  border rounded">
            <img
              src={image}
              className="font-semibold bg-white/30  overflow-hidden"
            />
            <div className="absolute top-1 right-1">
              <div className="bg-white/30 w-[300px] "></div>
              <button
                type="button"
                className="absolute top-1 right-1"
                onClick={() => {
                  setForm((prev) => ({
                    ...prev,
                    removeImage: true,
                    image: "",
                  }));
                  hiddenFileInput.current.value = null;
                }}
              >
                <X />
              </button>
            </div>
          </div>
        )}
      </InputWrapper>

      <InputWrapper label="Portal URL" error={error?.url} requiredInput={true}>
        <div className="flex checking border border-gray-400 rounded-md">
          <div className="bg-gray-200 px-2 py-2 rounded-l-md text-gray-700 font-semibold">
            {supportDomainPrefix}
          </div>

          <input
            name="url"
            type="text"
            className="rounded-r-md px-2 py-1 outline-none w-full"
            required
            onChange={updateForm}
            value={url}
          />
        </div>
      </InputWrapper>
      <InputWrapper label="Portal Info" error={""} requiredInput={false}>
        <textarea
          name="info"
          type="text"
          className="border border-gray-400 rounded-md px-2 py-1 outline-none w-full"
          onChange={updateForm}
          value={info}
        />
      </InputWrapper>
      <div className="flex items-center space-x-4 mt-8">
        <div className="font-semibold text-gray-500 mb-1">Enable/Disable</div>
        <ToggleBtn
          checked={status === 1}
          setChecked={(value) => {
            const event = {
              target: {
                name: "status",
                value: value ? 1 : 0,
              },
            };

            updateForm(event);
          }}
        />
      </div>

      <div className="flex justify-center items-center space-x-2 mt-12">
        <Button
          type="submit"
          className="flex save-btn justify-end button bg-indigo-600 text-white radius-round h-11 px-8 py-2"
          disabled={loading}
        >
          {loading ? <Loading /> : null}
          Submit
        </Button>

        {onCancel ? (
          <Button
            className="border border-red-600 rounded-md text-red-600 px-8 py-2"
            onClick={onCancel}
          >
            Cancel
          </Button>
        ) : null}
      </div>
    </form>
  );
}

export default PortalForm;

function InputWrapper({ children, label, error, requiredInput }) {
  return (
    <div className="w-full mt-4">
      <div
        className={`font-semibold text-gray-500 ${
          requiredInput ? "required-input" : ""
        }`}
      >
        {label}
      </div>
      <div className="my-1.5 w-full">{children}</div>
      {error ? <div className="text-red-600">{error}</div> : null}
    </div>
  );
}
