import { useState } from "react";
import useDropDown from "../../hooks/useDropDown";
import { X } from "lucide-react";

function FilterButton({
  open,
  setOpen,
  label,
  children,
  selected,
  pluralLabel,
  noScroll,
  childrenCls,
  dontCloseOnReactSelect,
  showPluralLabel,
  position,
  id = null,
  onClear = null,
}) {
  const { elementRef } = useDropDown({ setOpen, dontCloseOnReactSelect });

  return (
    <div className="relative flex items-center" ref={elementRef}>
      <div className="flex items-center px-4 py-2 border-dotted border-2 border-gray-400 rounded-md hover:bg-gray-100 w-fit">
        <button
          className="flex items-center"
          onClick={() => setOpen((prev) => !prev)}
          id={id}
        >
          <i class="fa-solid fa-plus mr-2"></i>
          {label}
          <DisplaySelectedOption
            showPluralLabel={showPluralLabel}
            selected={selected}
            pluralLabel={pluralLabel}
          />
        </button>
        {selected?.length > 0 && onClear && (
          <button
            onClick={() => {
              onClear();
            }}
          >
            <X className="ml-2 w-[15px]" />
          </button>
        )}
      </div>
      {open ? (
        <div
          className={`absolute z-[200] top-12 ${
            position ? position : "left-0"
          } bg-white rounded-md ${
            noScroll ? "" : "max-h-[150px] overflow-y-auto"
          } ${childrenCls}`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
}

export default FilterButton;

function DisplaySelectedOption({ showPluralLabel, selected, pluralLabel }) {
  const selectedCount = selected?.length;

  if (!selected?.length) {
    return;
  }

  const display =
    selected?.length > 2 || showPluralLabel
      ? [{ label: `${selectedCount} ${pluralLabel}` }]
      : selected;

  return display?.map((s, i) => {
    return (
      <div
        className="ml-3 capitalize pl-3 border-l border-gray-400 min-w-28 truncate font-semibold text-gray-500"
        key={i}
      >
        {s.label}
      </div>
    );
  });
}

export function SelectFilterOptionCheckbox({
  isChecked,
  handleCheckboxChange,
  label,
  id,
}) {
  return (
    <div className="flex items-center space-x-3 px-4 py-2 border-b hover:bg-gray-100">
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={id} className="w-20 truncate cursor-pointer">
        {label}
      </label>
    </div>
  );
}

export function SelectFilterOptionRadio({
  isChecked,
  handleChange,
  label,
  id,
}) {
  return (
    <div className="flex items-center space-x-3 px-4 py-2 border-b hover:bg-gray-100">
      <input type="radio" id={id} checked={isChecked} onChange={handleChange} />
      <label htmlFor={id} className="w-20 truncate cursor-pointer">
        {label}
      </label>
    </div>
  );
}
