import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import TicketsList from "../../components/tickets/TicketsList";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import TicketsFilter from "../../components/tickets/TicketsFilter";
import TableSkeleton from "../../components/loaders/TableSkeleton";
import TicketAdditionalFieldsContainer from "../../components/tickets/TicketAdditionalFieldsContainer";
import DependencyDropdown from "../../components/tickets/DependencyDropdown";
import SelectEmails from "../../components/common/SelectEmails";
import TicketSettings from "../../components/tickets/TicketSettings";
import TicketsHeader from "../../components/tickets/TicketsHeader";
import useTeam from "../../components/settings/team/useTeam";
import useApiData from "../../hooks/useApiData";
import useTicket from "../../components/tickets/useTicket";
import NewTicketForm from "../../components/tickets/NewTicketForm";
import usePortals from "../settings/portals/usePortals";

const TicketsContainer = () => {
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [availableChats, setAvailableChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState({
    name: "Select a chat",
    value: "",
  });
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [topic, setTopic] = useState("");
  const [relatedTicket, setRelatedTicket] = useState({ value: "", label: "" });
  const [priority, setPriority] = useState("");
  const [content, setContent] = useState("");
  const [topicError, setTopicError] = useState("");
  const [relatedTicketError, setRelatedTicketError] = useState("");
  const [priorityError, setPriorityError] = useState("");
  const [chatId, setChatId] = useState("");
  const [chatIdError, setChatIdError] = useState("");
  const [contentError, setContentError] = useState("");
  const [addTicketModal, setAddTicketModalopen] = useState(false);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [commnuityFields, setcommnuityFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParams] = useState({});
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState([]);
  const inputFileRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const [clientOptions, setClientOptions] = useState();

  const [additionaFields, setadditionaFields] = useState({});

  const history = useNavigate();
  const location = useLocation();

  const { getClients } = useTicket();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    sortTicket({
      page: pageNumber,
    });
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedChat({
        name: selectedOption.label,
        value: selectedOption.value,
      });
    } else {
      setSelectedChat({ name: "Select a chat", value: "" });
    }
  };

  const validation = (name, value, error = null) => {
    if (name === "priority") {
      if (error != null) {
        setPriorityError("This Field is required");
      } else {
        if (value !== "") {
          setPriority(value);
          setPriorityError("");
        } else {
          setPriority("");
          setPriorityError("This field is required");
        }
      }
    }

    if (name === "topic") {
      if (error != null) {
        setTopicError("This Field is required");
      } else {
        if (value !== "") {
          setTopicError("");
          setTopic(value);
        } else {
          setTopic("");
          setTopicError("This field is required");
        }
      }
    }

    if (name === "content") {
      if (error != null) {
        setContentError("This Field is required");
      } else {
        if (value !== "") {
          setContentError("");
          setContent(value);
        } else {
          setContent("");
          setContentError("This field is required");
        }
      }
    }
    if (name === "chatId") {
      if (error != null) {
        // setChatIdError("This Field is required");
      } else {
        if (value !== "") {
          // setChatIdError("");
          setChatId(value);
        } else {
          setChatId("");
          // setChatIdError("This field is required");
        }
      }
    }

    if (name === "client_name") {
      if (error != null) {
        setPriorityError("This Field is required");
      } else {
        if (value !== "") {
          setclientName(value);
          setPriorityError("");
        } else {
          setclientName("");
          setPriorityError("This field is required");
        }
      }
    }

    if (name === "client_email") {
      if (error != null) {
        setPriorityError("This Field is required");
      } else {
        if (value !== "") {
          setclientEmail(value);
          setPriorityError("");
        } else {
          setclientEmail([]);
          setPriorityError("This field is required");
        }
      }
    }
  };

  const fieldhandleChange = (event) => {
    const { name, value } = event.target;
    setadditionaFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSelectChange = (value) => {
    if (value) {
      setRelatedTicket({ value: value.value, label: value.label });
    }
  };

  const getAllTickets = () => {
    setProcessing(true);

    const refactorQueryParams = { ...queryParams };

    if (queryParams.customFields) {
      refactorQueryParams.customFields = JSON.parse(queryParams.customFields);
    }

    if (queryParams.created_at) {
      refactorQueryParams.created_at = JSON.parse(queryParams.created_at);
    }

    const { ticketIds } = queryParams;

    var postData = {
      chatId: queryParams?.chat,
      search: searchInput,
      community_id: currCommunityId,
      queryParams: refactorQueryParams,
    };

    let apiPath = `/api/getTickets?page=${currentPage}`;

    if (ticketIds) {
      apiPath += `&ticketIds=${ticketIds}`;
    }

    api
      .post(apiPath, postData)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          // console.log("response.data.data", response.data.data);
          setTickets(response.data);
        }
      })
      .catch(() => {
        setProcessing(false);
      });
  };

  const getAllFields = () => {
    var postData = {
      community_id: currCommunityId,
    };

    api.post(`/api/getCommunityFields`, postData).then((response) => {
      if (response.data.status == "success") {
        // console.log("response.data.data", response.data.data);
        setcommnuityFields(response.data.data);
        // console.log(commnuityFields);
      }
    });
  };

  const sortTicket = (sortParams) => {
    const updatedParams = { ...queryParams, ...sortParams };

    setQueryParams(updatedParams);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    // console.log(queryParams);
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        if (queryParams[key]) {
          const value = queryParams[key];
          const paramName = `${key}`;
          searchParams.append(paramName, value);
        }
      }
    }
    history({ search: searchParams.toString() });
  }, [queryParams]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      if (value) {
        params[key] = value;
      } else {
        params[key] = "";
      }
    }
    var page = searchParams.get("page");
    handlePageChange(page);
    setQueryParams(params);
  }, []);

  useEffect(() => {
    if (selectedChat || searchInput || currentPage || queryParams.sort) {
      getAllTickets();
    }
  }, [selectedChat, searchInput, currentPage, queryParams]);

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  function onSuccess(msg) {
    setShowToast(true);
    setToastMessage({
      type: "success",
      message: msg,
    });
  }

  const { getTeams } = useTeam();
  const { getPortals } = usePortals();

  const {
    data: teamsData,
    mutate: getLists,
    loading: isTeamLoading,
  } = useApiData({
    onSuccess: (response) => {
      var options = response.data.map((item) => {
        return {
          name: item.name,
          uuid: item.team_id,
        };
      });
      setAvailableChats(options);
    },
    onError: () => onError("Something went wrong while getting Teams"),
  });

  const {
    data: portalData,
    mutate: getPortalsData,
    loading: isPortalsLoading,
  } = useApiData({
    onSuccess: (response) => {},
    onError: () => onError("Something went wrong while getting Teams"),
  });

  const getAllFolders = () => {
    var postData = {
      search: "",
      community_id: currCommunityId,
    };

    api
      .post("/api/folderList", postData)
      .then((response) => {
        var options = response.data.data.map((item) => {
          return {
            name: item.name,
            uuid: item.uuid,
          };
        });
        setAvailableChats(options);
      })
      .catch((error) => {
        setAvailableChats([]);
      });
  };
  useEffect(() => {
    getAllFields();
    getUserInfo();
    getLists(getTeams());
    getPortalsData(getPortals());
    var postData = {
      value: "",
      community_id: currCommunityId,
    };
    api.post("/api/searchTickets", postData).then((response) => {
      if (response.data.status == "success") {
        const formattedOptions = response.data.data.map((ticket) => ({
          value: ticket.ticket_id,
          label: ticket.topic,
        }));
        setTicketOptions(formattedOptions);
      }
    });
  }, []);
  const folderSearch = (searchValue) => {
    setSearchInput(searchValue);
  };
  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.name, e.target.value);

    validation(e.target.name, e.target.value);
  };
  const clearForm = () => {
    setChatId("");
    setContent("");
    setPriority("");
    setTopic("");
    setclientName("");
    setclientEmail([]);
    setadditionaFields({});
    setRelatedTicket({ value: "", label: "" });
  };
  const folderForm = (e) => {
    e.preventDefault();

    setProcessing(true);
    var postData = {
      //client_id: "cus-121212",
      ticketContent: content,
      topic: topic,
      relatedTicket: relatedTicket.value,
      chatId: chatId,
      priority: priority,
      community_id: currCommunityId,
      status: "open",
      clientName: clientName,
      clientEmail: clientEmail,
      additionaFields: additionaFields,
      attachments: attachments,
    };

    api
      .post("/api/createTicket", postData)
      .then((response) => {
        if (response.data.status == "success") {
          // console.log("response.data.data", response.data.data);
          setProcessing(false);
          setAddTicketModalopen(false);
          setShowToast(true);
          getAllTickets();
          clearForm();
          setToastMessage({
            type: "success",
            message: "Ticket Created Successfully",
          });
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
          setToastMessage({ type: "error", message: "Something Went Wrong" });
        }
        setProcessing(false);
      });
  };

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      setAttachments(attachmentFiles);
      setProcessing(false);
    } catch (error) {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const ticketSelectInput = (newValue) => {
    // console.log(newValue);
    var postData = {
      value: newValue,
      community_id: currCommunityId,
    };
    setProcessing(true);

    api
      .post("/api/searchTickets", postData)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          const formattedOptions = response.data.data.map((ticket) => ({
            value: ticket.ticket_id,
            label: ticket.topic,
          }));
          setTicketOptions(formattedOptions);
        }
      })
      .catch((data) => {
        setProcessing(false);
      });
  };

  const chatOptions = availableChats.map((chat) => ({
    value: chat.uuid,
    label: chat.name,
  }));

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const hasQueryParams = Object.values(queryParams).some((q) => q);

  const {
    data: clientData,
    mutate: getClientsData,
    loading: isClientLoading,
  } = useApiData({
    onSuccess: (res) => {
      const clientOptions = res?.data?.data?.map((data, index) => ({
        label: data.client_name ? data.client_name : data.client_email,
        value: data.client_id,
      }));
      setClientOptions(clientOptions);
    },
    onError: () => {},
  });

  return (
    <div className="w-full">
      <div className="w-full">
        <div className="w-full">
          <div className="page-container relative h-full flex flex-auto flex-col pb-6 pt-3">
            <div className="w-full mx-auto h-full">
              {/* <h3 className="mb-3 main-title">Issue List</h3> */}

              <TicketsHeader
                sortTicket={sortTicket}
                queryParams={queryParams}
                showReset={hasQueryParams}
                onReset={() => {
                  history("/tickets");
                  setQueryParams({});
                }}
                folderSearch={folderSearch}
                onNewIssueBtnClick={() => {
                  setAddTicketModalopen(!addTicketModal);
                  clearForm({});
                  getAllFields();
                }}
                commnuityFields={commnuityFields}
                getAllFields={getAllFields}
                chatOptions={chatOptions}
                portalOptions={
                  portalData?.data?.map((portal) => ({
                    value: portal.id,
                    label: portal.name,
                  })) || []
                }
                clientOptions={clientOptions}
                isClientLoading={isClientLoading}
                onClientSearch={(value) => {
                  getClientsData(getClients({ search: value }));
                }}
              />

              {processing ? (
                <TableSkeleton />
              ) : Object.keys(tickets).length > 0 ? (
                <TicketsList
                  tickets={tickets}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  getAllTickets={getAllTickets}
                  sortTicket={sortTicket}
                  queryParams={queryParams}
                  commnuityFields={commnuityFields}
                />
              ) : (
                <div className="h-full flex flex-col items-center justify-center">
                  <div className="text-center">
                    <img
                      src={require("../../assets/images/welcome.png")}
                      alt="Welcome"
                      className="mx-auto mb-8"
                    />
                    <h3 className="mb-2">
                      Welcome on Board, lets get started with Train your gpt
                      chatbot
                    </h3>
                    <p className="text-base">
                      Telling us a bit about yourself to get the best
                      experience, this will only take a minute or two.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {addTicketModal && (
            <NewTicketForm
              setOpen={setAddTicketModalopen}
              refetch={() => {
                getAllTickets();
              }}
            />
            //   <div
            //   id="defaultModal"
            //   tabIndex={-1}
            //   className={` dialog-overlay add-new-tickets fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
            // >
            //   <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto lg:max-w-[700px]">
            //     <div className="relative dialog-content bg-white rounded-lg shadow">
            //       <div className="flex items-start justify-between p-4 rounded-t">
            //         <h3 className="text-xl font-semibold text-gray-900  ">
            //           Add new Ticket
            //         </h3>
            //         <button
            //           type="button"
            //           className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
            //           data-modal-hide="defaultModal"
            //           onClick={() => setAddTicketModalopen(false)}
            //         >
            //           <svg
            //             className="w-5 h-5"
            //             fill="currentColor"
            //             viewBox="0 0 20 20"
            //             xmlns="http://www.w3.org/2000/svg"
            //           >
            //             <path
            //               fillRule="evenodd"
            //               d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            //               clipRule="evenodd"
            //             />
            //           </svg>
            //           <span className="sr-only">Close modal</span>
            //         </button>
            //       </div>
            //       <form action="#" onSubmit={folderForm}>
            //         <div className="px-6">
            //           <div className="mt-4">
            //             <div className="form-container vertical">
            //               <div className="form-item vertical">
            //                 <label className="form-label mb-2">Title</label>
            //                 <div className>
            //                   <input
            //                     className={`${
            //                       topicError !== "" ? "input-danger" : ""
            //                     } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
            //                     type="text"
            //                     name="topic"
            //                     autoComplete="off"
            //                     placeholder="Enter topic"
            //                     value={topic}
            //                     onChange={(e) => handleChange(e)}
            //                     required
            //                   />
            //                   {topicError !== "" ? (
            //                     <div className="form-error">{topicError}</div>
            //                   ) : (
            //                     ""
            //                   )}
            //                 </div>
            //               </div>
            //               <div className="form-item vertical">
            //                 <label className="form-label mb-2">priority</label>
            //                 <div className>
            //                   <select
            //                     className={`${
            //                       priorityError !== "" ? "input-danger" : ""
            //                     } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600`}
            //                     name="priority"
            //                     value={priority}
            //                     onChange={(e) => handleChange(e)}
            //                     required
            //                   >
            //                     <option value="">
            //                       Please select a priority level
            //                     </option>
            //                     <option value="p1">P1</option>
            //                     <option value="p2">P2</option>
            //                     <option value="p3">P3</option>
            //                     <option value="p4">P4</option>
            //                   </select>
            //                   {priorityError !== "" ? (
            //                     <div className="form-error">
            //                       {priorityError}
            //                     </div>
            //                   ) : (
            //                     ""
            //                   )}
            //                 </div>
            //               </div>
            //               <div className="form-item vertical">
            //                 <label className="form-label mb-2">
            //                   Belongs To
            //                 </label>
            //                 <div className>
            //                   <select
            //                     className={`${
            //                       chatIdError !== "" ? "input-danger" : ""
            //                     } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600`}
            //                     name="chatId"
            //                     value={chatId}
            //                     onChange={(e) => handleChange(e)}
            //                   >
            //                     <option value="">
            //                       Please select a chatBot
            //                     </option>
            //                     {availableChats.map((chat, index) => (
            //                       <React.Fragment key={index}>
            //                         <option value={chat.uuid}>
            //                           {chat.name}
            //                         </option>
            //                       </React.Fragment>
            //                     ))}
            //                   </select>
            //                   {chatIdError !== "" ? (
            //                     <div className="form-error">{chatIdError}</div>
            //                   ) : (
            //                     ""
            //                   )}
            //                 </div>
            //               </div>
            //               <div className="form-item vertical">
            //                 <label className="form-label mb-2">
            //                   Related Tickets
            //                 </label>
            //                 <div className>
            //                   <Select
            //                     className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            //                     options={[...ticketOptions]}
            //                     onInputChange={ticketSelectInput}
            //                     onChange={onSelectChange}
            //                     isClearable
            //                     isSearchable
            //                     formatOptionLabel={({ value, label }) => (
            //                       <div>
            //                         <span>{value}</span>
            //                         <span> -- </span>
            //                         <span>{label}</span>
            //                       </div>
            //                     )}
            //                   />
            //                 </div>
            //               </div>
            //               <div className="form-item vertical invalid">
            //                 <label className="form-label mb-2">Content</label>
            //                 <div className>
            //                   <textarea
            //                     className={`${
            //                       contentError !== "" ? "input-danger" : ""
            //                     } input-textarea input-form input input-invalid input-textarea`}
            //                     type="text"
            //                     name="content"
            //                     autoComplete="off"
            //                     placeholder="Enter content"
            //                     spellCheck="false"
            //                     value={content}
            //                     onChange={(e) => handleChange(e)}
            //                     required
            //                   />
            //                   {contentError !== "" ? (
            //                     <div className="form-error">{contentError}</div>
            //                   ) : (
            //                     ""
            //                   )}
            //                 </div>
            //               </div>

            //               <div className="form-item vertical">
            //                 <label className="form-label mb-2">
            //                   Client Details
            //                 </label>
            //                 <div>
            //                   <div>
            //                     <label className="form-label mb-2">
            //                       Client Name
            //                     </label>
            //                     <div className>
            //                       <input
            //                         className={`${
            //                           topicError !== "" ? "input-danger" : ""
            //                         } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
            //                         type="text"
            //                         name="client_name"
            //                         autoComplete="off"
            //                         placeholder="Client Name"
            //                         value={clientName}
            //                         onChange={(e) => handleChange(e)}
            //                         required
            //                       />
            //                       {topicError !== "" ? (
            //                         <div className="form-error">
            //                           {topicError}
            //                         </div>
            //                       ) : (
            //                         ""
            //                       )}
            //                     </div>
            //                   </div>
            //                   <div>
            //                     <label className="form-label mb-2">
            //                       Client Email
            //                     </label>
            //                     <div className>
            //                       <input
            //                         className={`${
            //                           topicError !== "" ? "input-danger" : ""
            //                         } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
            //                         type="email"
            //                         name="client_email"
            //                         autoComplete="off"
            //                         placeholder="Client Email"
            //                         value={clientEmail}
            //                         onChange={(e) => handleChange(e)}
            //                         required
            //                       />

            //                       {/* <SelectEmails
            //                         handleChange={(values) => {
            //                           const event = {
            //                             preventDefault: () => {},
            //                             target: {
            //                               name: "client_email",
            //                               value: values.map((v) => v.value),
            //                             },
            //                           };
            //                           handleChange(event);
            //                         }}
            //                         required={true}
            //                       /> */}
            //                       {topicError !== "" ? (
            //                         <div className="form-error">
            //                           {topicError}
            //                         </div>
            //                       ) : (
            //                         ""
            //                       )}
            //                     </div>
            //                   </div>
            //                 </div>
            //               </div>

            //               <div className="form-item vertical">
            //                 <label className="form-label mb-2">
            //                   Additional Fields
            //                 </label>
            //                 <div>
            //                   {commnuityFields.map((item, idx) => (
            //                     <div>
            //                       {commnuityFields[idx]["field_type"] ==
            //                         "input" && (
            //                         <div>
            //                           <label className="form-label mb-2">
            //                             {commnuityFields[idx]["field_name"]}
            //                           </label>
            //                           <div className>
            //                             <input
            //                               className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            //                               type="text"
            //                               name={`${item.id}`}
            //                               autoComplete="off"
            //                               placeholder={
            //                                 commnuityFields[idx]["field_name"]
            //                               }
            //                               value={
            //                                 additionaFields[
            //                                   commnuityFields[idx]["id"]
            //                                 ]
            //                               }
            //                               onChange={(e) => fieldhandleChange(e)}
            //                             />
            //                           </div>
            //                         </div>
            //                       )}
            //                       {commnuityFields[idx]["field_type"] ==
            //                         "select" && (
            //                         <div>
            //                           <label className="form-label mb-2">
            //                             {commnuityFields[idx]["field_name"]}
            //                           </label>
            //                           <div className>
            //                             <select
            //                               className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
            //                               value={
            //                                 additionaFields[
            //                                   commnuityFields[idx]["id"]
            //                                 ]
            //                               }
            //                               onChange={(e) => fieldhandleChange(e)}
            //                               name={`${item.id}`}
            //                             >
            //                               <option value="">
            //                                 Please select{" "}
            //                                 {commnuityFields[idx]["field_name"]}
            //                               </option>
            //                               {commnuityFields[idx][
            //                                 "field_values"
            //                               ].map((field, index) => (
            //                                 <React.Fragment key={index}>
            //                                   <option value={field}>
            //                                     {field}
            //                                   </option>
            //                                 </React.Fragment>
            //                               ))}
            //                             </select>
            //                           </div>
            //                         </div>
            //                       )}
            //                       {item?.field_type ===
            //                       "dependency_dropdown" ? (
            //                         <DependencyDropdown
            //                           field={item}
            //                           selectedValues={additionaFields[item.id]}
            //                           id={item.id}
            //                           handleChange={(value) => {
            //                             const data = {
            //                               target: {
            //                                 name: item.id,
            //                                 value,
            //                               },
            //                             };

            //                             fieldhandleChange(data);
            //                           }}
            //                         />
            //                       ) : null}
            //                     </div>
            //                   ))}
            //                 </div>
            //               </div>
            //               <div>
            //                 <div
            //                   onClick={handleSelectButtonClick}
            //                   className="form-item  mb-3 vertical text-indigo-500 underline cursor-pointer"
            //                 >
            //                   Select Attachments
            //                 </div>

            //                 <input
            //                   type="file"
            //                   className="hidden"
            //                   ref={inputFileRef}
            //                   onChange={handleFileUpload}
            //                   multiple
            //                 />
            //                 {attachments?.length > 0 && (
            //                   <span className="ml-2">
            //                     {attachments.length} Files Selected
            //                   </span>
            //                 )}
            //               </div>
            //             </div>
            //           </div>
            //         </div>
            //         <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
            //           <button
            //             className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
            //             type="submit"
            //             disabled={processing}
            //           >
            //             {processing && (
            //               <svg
            //                 className="animate-spin -ml-1 mr-3 h-5 w-5"
            //                 xmlns="http://www.w3.org/2000/svg"
            //                 fill="none"
            //                 viewBox="0 0 24 24"
            //               >
            //                 <circle
            //                   className="opacity-25"
            //                   cx="12"
            //                   cy="12"
            //                   r="10"
            //                   stroke="currentColor"
            //                   strokeWidth="4"
            //                 ></circle>
            //                 <path
            //                   className="opacity-75"
            //                   fill="currentColor"
            //                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            //                 ></path>
            //               </svg>
            //             )}{" "}
            //             Submit
            //           </button>
            //         </div>
            //       </form>
            //     </div>
            //   </div>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketsContainer;
