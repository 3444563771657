import React, { useState } from "react";
import Modal from "../common/Modal";
import TicketAdditionalFieldsForm from "../common/TicketAdditionalFieldsForm";

function TicketAdditionalFieldsContainer({
  modal,
  setModal,
  onClosingAdditionalFields,
}) {
  return (
    <div>
      <Modal
        isOpen={modal}
        onClose={() => setModal("")}
        title={"Ticket Additional Fields"}
        customCls="md:max-w-[1000px]"
      >
        <div className="px-2">
          <TicketAdditionalFieldsForm
            closeModal={() => {
              setModal("");

              if (onClosingAdditionalFields) {
                onClosingAdditionalFields();
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default TicketAdditionalFieldsContainer;
