import { useCallback, useContext, useRef, useState } from "react";
import ChangeBotStatus from "../../containers/chatbot/ChangeBotStatus";
import ShowWidgetConfig from "./ShowWidgetConfig";
import { AppContext } from "../../App";
import Button from "../common/Button";
import ChooseColor from "./ChooseColor";
import ButtonOptions from "./ButtonOptions";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import Loading from "../loaders/Loading";
import { isValidUrl } from "../common/commonHelpers";
import Radio from "../common/Radio";

const yesNoBtns = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

const weeks = [
  { label: "Sun", value: "0" },
  { label: "Mon", value: "1" },
  { label: "Tues", value: "2" },
  { label: "Weds", value: "3" },
  { label: "Thurs", value: "4" },
  { label: "Fri", value: "5" },
  { label: "Sat", value: "6" },
];

function getWorkingHours(from_time, to_time) {
  if (from_time != null && to_time != null) {
    var from_dateObj = new Date(from_time);

    var from_hours = from_dateObj.getHours();
    var from_minutes = from_dateObj.getMinutes();

    var from_hoursStr =
      from_hours < 10 ? "0" + from_hours : from_hours.toString();
    var from_minutesStr =
      from_minutes < 10 ? "0" + from_minutes : from_minutes.toString();

    var from_hoursMinutes = from_hoursStr + ":" + from_minutesStr;

    var to_dateObj = new Date(to_time);

    var to_hours = to_dateObj.getHours();
    var to_minutes = to_dateObj.getMinutes();

    var to_hoursStr = to_hours < 10 ? "0" + to_hours : to_hours.toString();
    var to_minutesStr =
      to_minutes < 10 ? "0" + to_minutes : to_minutes.toString();

    var to_hoursMinutes = to_hoursStr + ":" + to_minutesStr;

    if (isNaN(from_dateObj.getDate())) {
      from_hoursMinutes = from_time;
    }

    if (isNaN(to_dateObj.getDate())) {
      to_hoursMinutes = to_time;
    }

    return {
      from_time: from_hoursMinutes,
      to_time: to_hoursMinutes,
    };
  }

  return {};
}

function handleFileUpload(e, updateConfig) {
  try {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      updateConfig({ logo: reader.result });
    };
  } catch (err) {
    console.log("On uploading widget icon ", err);
  }
}

function GeneralConfigurations({
  config,
  setConfig,
  updateConfig,
  getFolderDetails,
}) {
  const { onWarning, onError, onSuccess, currCommunityId } =
    useContext(AppContext);

  const { id } = useParams();

  const {
    offline_status,
    logo,
    name,
    first_message,
    color,
    is_call,
    is_whatsapp,
    whatsapp_number,
    whatsapp_first_message,
    from_time,
    to_time,
    days,
    offline_message,
    emailTemplate,
    Is_connect_with_agent,
  } = config || {};

  const updateConfigApi = useCallback((payload) => {
    return () => api.post(`/api/updateChatBotData`, payload);
  }, []);

  const { mutate, loading } = useApiData({
    onSuccess: () => {
      getFolderDetails();
      onSuccess("Configuration updated successfully");
    },
    onError: () => onError("Something went wrong while updating configuration"),
  });

  console.log("logo", isValidUrl(logo));

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const payload = {
          id,
          community_id: currCommunityId,
          offline_status,
          name,
          first_message,
          color,
          is_call,
          is_whatsapp,
          whatsapp_number,
          whatsapp_first_message,
          from_time,
          to_time,
          days,
          offline_message,
          Is_connect_with_agent,
        };

        if (!isValidUrl(logo)) {
          payload.logo = logo;
        }
        if (emailTemplate) {
          if (Object.keys(emailTemplate)?.length) {
            payload.emailTemplate = {
              ...emailTemplate,
              name: "offline_message",
            };
          }
        }

        mutate(updateConfigApi(payload));
      }}
    >
      <ChangeBotStatus
        status={offline_status}
        updateBotStatus={(updatedStatus) => {
          updateConfig({ offline_status: updatedStatus });
        }}
      />

      <ShowWidgetConfig
        label="Allow Users to connect with Live Agent from Home Page"
        config={
          <Radio
            options={[
              { label: "Enable", value: 1 },
              { label: "Disable", value: 0 },
            ]}
            selectedValue={Is_connect_with_agent}
            onChange={(value) => {
              updateConfig({ Is_connect_with_agent: value });
            }}
          />
        }
      />

      <ShowWidgetConfig
        label="Widget Icon"
        config={
          <Logo
            logo={logo}
            handleFileUpload={(e) => handleFileUpload(e, updateConfig)}
            removeFile={() => updateConfig({ logo: "delete" })}
          />
        }
      />

      <ShowWidgetConfig
        label="Widget Name"
        config={
          <input
            value={name}
            onChange={(e) => {
              updateConfig({ name: e.target.value });
            }}
            required
            className="border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-indigo-500 w-full"
            placeholder="Widget Name"
          />
        }
        requiredInput={true}
      />

      <ShowWidgetConfig
        label="Default First Message"
        config={
          <input
            value={first_message}
            onChange={(e) => {
              updateConfig({ first_message: e.target.value });
            }}
            className="border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-indigo-500 w-full"
            placeholder="Enter default first message"
          />
        }
      />

      <ShowWidgetConfig
        label={
          <div>
            <div>Widget Background Color</div>
            <div className="italic text-gray-500 text-sm mt-1 font-normal">
              (Use darker colors for better contrast)
            </div>
          </div>
        }
        config={
          <ChooseColor
            color={color}
            updateConfig={updateConfig}
            onWarning={onWarning}
          />
        }
      />

      <ShowWidgetConfig
        label="Allow Client to Call You?"
        config={
          <ButtonOptions
            options={yesNoBtns}
            onOptionChange={(value) => updateConfig({ is_call: value })}
            isSelected={(value) => value === is_call}
          />
        }
      />

      <WhatsappConfig
        is_whatsapp={is_whatsapp}
        whatsapp_number={whatsapp_number}
        whatsapp_first_message={whatsapp_first_message}
        updateConfig={updateConfig}
      />

      <ShowWidgetConfig
        label="Working Hours"
        config={
          <WorkingHours
            from_time={from_time}
            to_time={to_time}
            updateConfig={updateConfig}
          />
        }
      />

      <ShowWidgetConfig
        label="Working Days"
        config={
          <ButtonOptions
            options={weeks}
            onOptionChange={(value) => {
              let workingDays = days?.split(",") || [];

              if (workingDays?.includes(value)) {
                workingDays = workingDays.filter((w) => w !== value);
              } else {
                workingDays.push(value);
              }

              updateConfig({ days: workingDays.join(",") });
            }}
            isSelected={(value) => days?.includes(value)}
          />
        }
      />

      <ShowWidgetConfig
        label="Offline Chat Message"
        config={
          <textarea
            className="border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-indigo-500 w-full h-16"
            value={offline_message}
            onChange={(e) => {
              updateConfig({ offline_message: e.target.value });
            }}
            placeholder="Enter offline chat message"
            type="text"
            autoComplete="off"
            spellcheck="false"
          />
        }
      />

      <ShowWidgetConfig
        label="Offline Email"
        config={
          <div>
            <div className="form-item vertical">
              <label className="form-label !font-medium">Subject</label>
              <div>
                <input
                  className="border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-indigo-500 w-full"
                  type="text"
                  name="subject"
                  autoComplete="off"
                  placeholder="Enter subject"
                  value={emailTemplate?.subject}
                  onChange={(e) => {
                    updateConfig({
                      emailTemplate: {
                        ...emailTemplate,
                        subject: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-item vertical invalid">
              <label className="form-label mb-2 !font-medium">Body</label>
              <div>
                <textarea
                  className="border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-indigo-500 w-full h-16"
                  type="text"
                  name="body"
                  autoComplete="off"
                  placeholder="Enter Body"
                  spellcheck="false"
                  value={emailTemplate?.body}
                  onChange={(e) => {
                    updateConfig({
                      emailTemplate: {
                        ...emailTemplate,
                        body: e.target.value,
                      },
                    });
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        }
        alignLabelTop={true}
      />

      <div className="flex justify-end items-center space-x-4 font-medium text-sm my-6 mr-6">
        <Button
          className="text-indigo-600 border border-gray-300 rounded-md px-5 py-3"
          onClick={getFolderDetails}
        >
          Cancel
        </Button>

        <button
          type="submit"
          className="bg-indigo-600 text-white rounded-md px-4 py-3 flex items-center space-x-4"
          disabled={loading}
        >
          {loading ? <Loading /> : null}
          Save Changes
        </button>
      </div>
    </form>
  );
}

export default GeneralConfigurations;

function Logo({ logo, handleFileUpload, removeFile }) {
  const inputFileRef = useRef();

  return (
    <div className="flex items-center space-x-4">
      <img
        src={
          logo && logo !== "delete"
            ? logo
            : require("../../assets/images/icons8-no-image-100.png")
        }
        className={`border border-gray-300 w-24`}
        alt="chat-widget-logo"
      />

      <div>
        <div className="flex flex-col">
          <button
            className="text-indigo-600 font-medium"
            onClick={() => {
              inputFileRef.current.click();
            }}
            type="button"
          >
            {logo ? "Change" : "Select"}
          </button>
          {logo && (
            <button
              className="text-red-600 font-medium"
              onClick={() => removeFile()}
              type="button"
            >
              Remove
            </button>
          )}
        </div>
        <input
          type="file"
          accept=".png"
          className="hidden"
          ref={inputFileRef}
          onChange={handleFileUpload}
        />
      </div>
    </div>
  );
}

function WhatsappConfig({
  is_whatsapp,
  whatsapp_number,
  whatsapp_first_message,
  updateConfig,
}) {
  return (
    <div className="border-b border-gray-300 py-4">
      <ShowWidgetConfig
        label="Include Your WhatsApp Number in Your Chatbot?"
        config={
          <ButtonOptions
            options={yesNoBtns}
            onOptionChange={(value) => updateConfig({ is_whatsapp: value })}
            isSelected={(value) => value === is_whatsapp}
          />
        }
        containerCls="!border-none"
      />

      <ShowWidgetConfig
        label="Your WhatsApp Number"
        config={
          <PhoneInput
            containerStyle={{ height: "42px" }}
            inputStyle={{ height: "42px" }}
            country={"us"}
            value={whatsapp_number || ""}
            onChange={(phone) => updateConfig({ whatsapp_number: phone })}
            enableSearch={true}
            inputProps={{
              name: "phone",
              required: is_whatsapp ? true : false,
              autoFocus: true,
            }}
          />
        }
        containerCls="!border-none"
        requiredInput={is_whatsapp ? true : false}
      />

      <ShowWidgetConfig
        label="Default First Message"
        config={
          <input
            value={whatsapp_first_message}
            onChange={(e) => {
              updateConfig({ whatsapp_first_message: e.target.value });
            }}
            className="border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-1 focus:ring-indigo-500 w-full"
            placeholder="Enter default first message"
          />
        }
        containerCls="!border-none"
      />
    </div>
  );
}

function WorkingHours({ from_time, to_time, updateConfig }) {
  const workingHrsData = getWorkingHours(from_time, to_time);

  return (
    <div className="input-group">
      <input
        className="w-56 p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
        type="time"
        value={workingHrsData?.from_time}
        onChange={(e) => {
          updateConfig({ from_time: e.target.value });
        }}
      />
      <div className="input-addon input-addon-md h-11 mx-3 border-none">to</div>
      <input
        className="w-56 p-2 input focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
        type="time"
        value={workingHrsData?.to_time}
        onChange={(e) => {
          updateConfig({ to_time: e.target.value });
        }}
      />
    </div>
  );
}
