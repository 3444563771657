import ReactQuill from "react-quill";
import InputWrapper from "../../common/InputWrapper";
import PrimaryButton from "../../common/PrimaryButton";
import { useCallback, useState } from "react";
import { isQuillHavingText } from "../../common/commonHelpers";
const formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "image",
  // 'video'
];

const modules = {
  toolbar: [["bold", "italic", "underline"]],
};

const contentModules = {
  toolbar: [["bold", "italic", "underline", "code-block", "image"]],
};

function MailTemplateForm({ onSubmit, loading, defaultData, submitBtnLabel }) {
  const [form, setForm] = useState(
    defaultData
      ? defaultData
      : {
          header: "",
          subject: "",
          content: "",
          footer: "",
        }
  );

  const updateForm = useCallback((update) => {
    setForm((prev) => ({ ...prev, ...update }));
  }, []);

  const [error, setError] = useState({
    subject: "",
    content: "",
  });

  const { header, subject, content, footer } = form;

  return (
    <div className="my-1 min-w-[480px]">
      <form
        className="flex flex-col space-y-4"
        onSubmit={(e) => {
          e.preventDefault();

          const err = {};

          if (!subject) {
            err.subject = "Subject is required";
          }

          if (!isQuillHavingText(content)) {
            err.content = "Content is required";
          }

          if (Object.keys(err)?.length) {
            setError(err);
            return;
          }

          setError({ subject: "", content: "" });

          onSubmit(form);
        }}
      >
        <InputWrapper label="Subject" error={error.subject} isRequired={true}>
          <input
            type="text"
            placeholder="Subject"
            className="border border-gray-300 px-2 py-2 w-full rounded-md outline-none focus:ring-1 focus:ring-blue-400"
            onChange={(e) => updateForm({ subject: e.target.value })}
            value={subject}
          />
        </InputWrapper>

        <InputWrapper label="Header">
          <ReactQuill
            theme="snow"
            value={header}
            onChange={(value) => {
              updateForm({ header: value });
            }}
            //   className={`bg-white ${isMsgError ? "border-red-600" : ""} ${
            //     isActive.current ? "" : "hidden"
            //   }`}
            //   modules={modules}
            modules={modules}
            formats={formats}
            placeholder="Header"
          />
        </InputWrapper>

        <InputWrapper label="Content" error={error.content} isRequired={true}>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={(value) => {
              console.log("value", value);
              updateForm({ content: value });
            }}
            //   className={`bg-white ${isMsgError ? "border-red-600" : ""} ${
            //     isActive.current ? "" : "hidden"
            //   }`}
            //   modules={modules}
            modules={contentModules}
            formats={formats}
            placeholder="Content"
          />
        </InputWrapper>

        <InputWrapper label="Footer">
          <ReactQuill
            theme="snow"
            value={footer}
            onChange={(value) => {
              updateForm({ footer: value });
            }}
            //   className={`bg-white ${isMsgError ? "border-red-600" : ""} ${
            //     isActive.current ? "" : "hidden"
            //   }`}
            //   modules={modules}
            modules={modules}
            formats={formats}
            placeholder="Footer"
          />
        </InputWrapper>

        <div className="flex justify-center">
          <PrimaryButton
            label={submitBtnLabel || "Create Template"}
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
}

export default MailTemplateForm;
