import { useNavigate } from "react-router-dom";
import useApiData from "../../hooks/useApiData";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useMailTemplate from "../../components/customerManagement/mail/useMailTemplate";
import ListTemplates from "../../components/customerManagement/mail/ListTemplates";
import Spinner from "../../components/loaders/Spinner";
import Modal from "../../components/common/Modal";
import MailTemplateForm from "../../components/customerManagement/mail/MailTemplateForm";
import DeleteModal from "../../components/common/DeleteModal";
import BackBtn from "../../components/common/BackBtn";

function EmailTemplateContainer() {
  const [action, setAction] = useState({
    open: false,
    type: "",
    data: null,
  });

  const updateAction = useCallback((open, type, data) => {
    setAction({ open, type, data });
  }, []);

  const { currCommunityId, onSuccess, onError } = useContext(AppContext);

  const navigate = useNavigate();

  const { createTemplate, updateTemplate, deleteTemplate, getTemplates } =
    useMailTemplate();

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => {},
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to get templates");
    },
  });

  const getTemplatesData = useCallback(() => {
    mutate(getTemplates({ community_id: currCommunityId }));
  }, [currCommunityId, getTemplates, mutate]);

  const { mutate: createApi, loading: isCreating } = useApiData({
    onSuccess: (res) => {
      getTemplatesData();
      updateAction(false, "", null);
      onSuccess("Template created successfully");
    },
    onError: (err) => {
      const { message, error } = err?.response?.data || {};

      onError(message || "Unable to create Template");
    },
  });

  const { mutate: updateApi, loading: isUpdating } = useApiData({
    onSuccess: (res) => {
      getTemplatesData();
      updateAction(false, "", null);
      onSuccess("Template udpated successfully");
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to update Template");
    },
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      updateAction(false, "", null);
      getTemplatesData();
      onSuccess("Template deleted successfully");
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to delete Template");
    },
  });

  useEffect(() => {
    getTemplatesData();
  }, []);

  return (
    <div className="px-1">
      <BackBtn onClick={() => navigate("/customer-management")} />

      <div className="flex justify-between items-center">
        <div className="text-2xl font-semibold">Email Templates</div>

        <button
          className="bg-indigo-600 text-white px-4 py-2 rounded-md"
          onClick={() => updateAction(true, "Create")}
        >
          Create Template
        </button>
      </div>

      {loading && !data ? (
        <div className="flex justify-center mt-6">
          <Spinner />
        </div>
      ) : (
        <ListTemplates
          templates={data || []}
          onEdit={(template) => {
            const { id, header_content, subject, content, footer_content } =
              template;

            const refactor = {
              id,
              header: header_content,
              subject,
              content,
              footer: footer_content,
            };

            updateAction(true, "Update", refactor);
          }}
          onDelete={(template) => updateAction(true, "Delete", template)}
        />
      )}

      <Modal
        isOpen={action.open && ["Create", "Update"].includes(action.type)}
        onClose={() => updateAction(false, "", null)}
        title={`${action.type} Template`}
      >
        <MailTemplateForm
          onSubmit={(form) => {
            if (action.type === "Create") {
              createApi(
                createTemplate({ ...form, community_id: currCommunityId })
              );
            } else if (action.type === "Update") {
              updateApi(
                updateTemplate({ ...form, community_id: currCommunityId })
              );
            }
          }}
          loading={isCreating || isUpdating}
          defaultData={action.data}
          submitBtnLabel={`${action.type} Template`}
        />
      </Modal>

      <DeleteModal
        title={"Are you sure, you want to delete this template."}
        open={action.open && action.type === "Delete"}
        onClose={() => {
          updateAction(false, "", null);
        }}
        loading={isDeleteLoading}
        onDelete={() => {
          deleteApi(
            deleteTemplate({
              community_id: currCommunityId,
              id: action?.data?.id,
            })
          );
        }}
      />
    </div>
  );
}

export default EmailTemplateContainer;
