import { useEffect, useState } from "react";
import SignInCommon from "../../components/common/SignInCommon";
import { useNavigate } from "react-router-dom";

function AccountConfirmation() {
  const navigate = useNavigate();

  const [requestedOrigin] = useState(
    localStorage.getItem("requestedOrigin") || ""
  );

  useEffect(() => {
    if (!requestedOrigin) {
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-4 h-full">
        <SignInCommon />
        <div className="col-span-2 flex flex-col justify-center items-center bg-white text-gray-700">
          <div className="xl:min-w-[550px] px-8 text-md">
            <div className="font-semibold text-xl">
              Thank you for creating your DCKAP Desk account
            </div>

            <div className="py-8">We are setting up your account.</div>

            <div className="pb-8">
              You can access your account after 20 minutes. (
              <span
                className="font-semibold text-blue-600 cursor-pointer"
                onClick={() => (window.location.href = requestedOrigin)}
              >
                {requestedOrigin}
              </span>
              )
            </div>

            <div>
              Already have an account?{" "}
              <span
                className="text-blue-600 hover:border-b hover:border-blue-600 cursor-pointer"
                onClick={() => {
                  navigate("/signin");
                }}
              >
                Login here
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountConfirmation;
