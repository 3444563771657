export function getRemoteVariables(
  oldMsgData,
  newRemoteVariablesData,
  inputValue
) {
  let newRemoteVariables = [];

  const msgData = JSON.parse(JSON.stringify(oldMsgData));

  const remoteVariables = msgData.remoteVariables || [];

  console.log("oldremotevaribles", remoteVariables);

  const remoteVariablesData = newRemoteVariablesData || {};

  // find whether the varible is already existing or not
  const existingRemoteVariable = remoteVariables.find((r) => {
    const name = r.name === remoteVariablesData.name;

    const configId = r.variableConfigurationId || r.apiConfigurationId;
    const remoteVariablesDataConfigId =
      remoteVariablesData.variableConfigurationId ||
      remoteVariablesData.apiConfigurationId;

    const configurationId = configId === remoteVariablesDataConfigId;

    const prefixString = r.prefixString === remoteVariablesData.prefixString;

    return name && configurationId && prefixString;
  });

  newRemoteVariables = existingRemoteVariable
    ? remoteVariables
    : [...remoteVariables, remoteVariablesData];

  console.log("newRemoteVariables", newRemoteVariables);

  const newData = [];

  newRemoteVariables.forEach((r) => {
    const dynamicProperties = `{{${r.prefixString}: ${r.name}}}`;

    if (inputValue.includes(dynamicProperties)) {
      newData.push(r);
    }
  });

  console.log("newData", newData);

  return newData;
}

export function handleTextChange(value, remoteVariablesData, defaultProps) {
  const { updateFlows, displayOrder } = defaultProps;

  const flow = JSON.parse(JSON.stringify(defaultProps.flow || {}));

  const msgs = flow.dialogmessages || [];

  const msgData = msgs[0] || {};

  updateFlows(
    {
      dialogmessages: [
        {
          ...msgData,
          messageType: "TEXT",
          messageText: value,
          remoteVariables: getRemoteVariables(
            msgData,
            remoteVariablesData,
            value
          ),
        },
      ],
      dialogactions: [],
    },
    displayOrder
  );
}

export function onSelectingResponseType(val, defaultProps) {
  const { flow, updateFlows, displayOrder } = defaultProps;

  const msgs = flow.dialogmessages || [];

  const msgData = msgs[0] || {};

  const responseType = val?.value;

  let dialogresponses = [];

  if (responseType) {
    dialogresponses = [
      {
        answerType: responseType,
        values: responseType === "BUTTON" ? [{ name: "" }] : null,
      },
    ];
  }

  updateFlows(
    {
      dialogmessages: [
        {
          ...msgData,
          dialogresponses,
        },
      ],
      dialogactions: [],
    },
    displayOrder
  );
}

export function onChangingBtns(btnData, defaultProps) {
  const { flow, updateFlows, displayOrder } = defaultProps;

  const msgs = flow.dialogmessages || [];

  const msgData = msgs[0] || {};

  const dialogresponses = msgData?.dialogresponses || [{}];

  updateFlows(
    {
      dialogmessages: [
        {
          ...msgData,
          dialogresponses: [{ ...dialogresponses[0], values: btnData }],
        },
      ],
      dialogactions: [],
    },
    displayOrder
  );
}

export function onSubmitForm(data, defaultProps) {
  const { flow, updateFlows, displayOrder } = defaultProps;

  const msgs = flow.dialogmessages || [];

  const msgData = msgs[0] || {};

  const dialogresponses = msgData?.dialogresponses || [{}];

  updateFlows(
    {
      dialogmessages: [
        {
          ...msgData,
          dialogresponses: [{ ...dialogresponses[0], ...data }],
        },
      ],
      dialogactions: [],
    },
    displayOrder
  );
}

export const onSelectingActionType = (actionType, defaultActionProps) => {
  const { dialogactions, updateFlows, displayOrder } = defaultActionProps;

  const actions = dialogactions || [];

  const actionData = actions[0] || {};

  updateFlows(
    {
      dialogactions: [{ uuid: actionData?.uuid, actionType }],
      dialogmessages: [],
    },
    displayOrder
  );
};

export const onSelectingApis = (api, defaultActionProps) => {
  const { dialogactions, updateFlows, displayOrder } = defaultActionProps;

  const actions = dialogactions || [];

  const actionData = actions[0] || {};

  let configurationId;

  if (api) {
    const { uuid, apiName } = api;

    configurationId = {
      uuid,
      apiName,
    };
  }

  updateFlows(
    {
      dialogactions: [{ ...actionData, configurationId }],
      dialogmessages: [],
    },
    displayOrder
  );
};

export const onSetProperty = (newPropertyData, defaultActionProps) => {
  const { dialogactions, updateFlows, displayOrder } = defaultActionProps;

  const actions = dialogactions || [];

  const actionData = actions[0] || {};

  const propertyData = actionData?.setProperty || {};

  updateFlows(
    {
      dialogactions: [
        {
          ...actionData,
          setProperty: {
            ...propertyData,
            ...newPropertyData,
          },
        },
      ],
      dialogmessages: [],
    },
    displayOrder
  );
};

export const onSetPropertyName = (
  newPropertyData,
  configurationId,
  defaultActionProps
) => {
  const { dialogactions, updateFlows, displayOrder } = defaultActionProps;

  const actions = dialogactions || [];

  const actionData = actions[0] || {};

  const propertyData = actionData?.setProperty || {};

  updateFlows(
    {
      dialogactions: [
        {
          ...actionData,
          configurationId,
          setProperty: {
            ...propertyData,
            ...newPropertyData,
          },
        },
      ],
      dialogmessages: [],
    },
    displayOrder
  );
};

export function handleDialogEditListener(e, refElement, onClickingOutside) {
  const clickedElementId = e.target.getAttribute("id") || "";

  const isReactSelect = clickedElementId.includes("react-select");

  const isInsertingData = clickedElementId.includes("insert-data");

  if (isReactSelect || isInsertingData) {
    console.log("Clicking inside");
    // onClickingInside();
    return;
  }

  if (refElement.current && !refElement.current.contains(e.target)) {
    console.log("Clicking outside");
    // updateEnableEdit(false);
    // saveFlow();
    onClickingOutside();
  } else {
    console.log("Clicking inside");
    // onClickingInside();

    // updateEnableEdit(true);
  }
}

function validateActionFlow(flow) {
  const { dialogactions } = flow;

  let action = {};
  let havingError = false;

  if (!dialogactions) {
    action.actionType = "Please select the action type";
    havingError = true;

    return { action, havingError };
  }

  const actionData = dialogactions[0];

  if (!actionData) {
    action.actionType = "Please select the action type";
    havingError = true;
    return { action, havingError };
  }

  const { actionType, setProperty, configurationId } = actionData;

  if (!actionType) {
    action.actionType = "Please select the action type";
    havingError = true;
    return { action, havingError };
  }

  const { enumSaveIn, key, value } = setProperty || {};

  if (actionType === "SET_PROPERTY") {
    if (!enumSaveIn) {
      action.enumSaveIn = "Please select the save in properties";
      havingError = true;
      return { action, havingError };
    }

    if (!key) {
      action.key = "Please type or select the property name";
      havingError = true;
      return { action, havingError };
    }

    if (!value) {
      action.value = "Please type or select the property value";
      havingError = true;
      return { action, havingError };
    }
  } else if (actionType === "API" && !configurationId) {
    action.api = "Please select the api";
    havingError = true;
    return { action, havingError };
  }

  return { action, havingError };
}

function isHavingMessageText(messageText) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = messageText || "";
  const textContent = tempElement.textContent;

  return textContent.trim().length > 0;
}

function validateMsgFlow(flow) {
  const { dialogmessages } = flow;

  const message = {};
  let havingError = false;

  if (!dialogmessages) {
    message.messageText = "Please enter your message...";
    havingError = true;

    return { message, havingError };
  }

  const msgsData = dialogmessages[0];

  if (!msgsData) {
    message.messageText = "Please enter your message...";
    havingError = true;
    return { message, havingError };
  }

  if (!isHavingMessageText(msgsData?.messageText)) {
    message.messageText = "Please enter your message...";
    havingError = true;
  }

  const { dialogresponses } = msgsData;

  const {
    answerType,
    values: buttonValues,
    configurationId,
  } = dialogresponses?.length ? dialogresponses[0] : {};

  if (answerType === "BUTTON") {
    const btnError = {};

    buttonValues.forEach((btn, index) => {
      const { name } = btn || {};

      if (!name) {
        btnError[index] = "Button is required!";
        havingError = true;
      }
    });

    message.buttonResponseTypeError = btnError;
  } else if (answerType === "FORM" && !configurationId) {
    message.formResponseTypeError = "Please select the api for submitting form";
    havingError = true;
  }

  return { message, havingError };
}

export function validateFlow(flowData) {
  const flow = flowData || {};

  const error = {};

  const { entityType, displayOrder } = flow;

  if (entityType === "MESSAGE") {
    const { message, havingError } = validateMsgFlow(flow);

    if (havingError) {
      error.havingError = havingError;
      error.message = message;
    }
  } else if (entityType === "ACTION") {
    const { action, havingError } = validateActionFlow(flow);

    if (havingError) {
      error.havingError = havingError;
      error.action = action;
    }
  }

  return { [displayOrder]: error };
}
