import { useEffect, useState, useRef, useContext, useMemo } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Spinner from "../loaders/Spinner";
import Loading from "../loaders/Loading";
import RelatedTickets from "./RelatedTickets";
import ChildTickets from "./ChildTickets";
import TicketAssign from "./TicketAssign";
import CustomField from "./CustomFields";

const ticketStatusLabel = {
  "waiting for approval": "Waiting for Approval",
  inprogress: "In Progress",
  hold: "Hold",
  completed: "Completed",
  Approved: "Approved",
  approved: "Approved",
};

function getTicketStatusLabel(status) {
  return ticketStatusLabel[status] || status;
}

const getStatusBackgroundColor = (status) => {
  switch (status) {
    case "waiting for approval":
      return "#F8FCE0"; // Light yellow for "waiting for approval"
    case "inprogress":
      return "#F6F8B0"; // Light yellow for "inprogress"
    case "hold":
      return "#FCE8B4"; // Light orange for "hold"
    case "completed":
      return "#D8FCD8"; // Light green for "completed"
    case "Approved":
      return "#D8FCD8"; // Light green for "completed"
    default:
      return "white"; // Default background color
  }
};

function getColumns(columns, props) {
  let newColumns = columns;

  if (props?.status === "completed") {
    newColumns.filter((c) => c.key !== "dueDate");
  }

  return newColumns;
}

function formatDuration(hours) {
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;
  const minutes = Math.round(
    (remainingHours - Math.floor(remainingHours)) * 60
  );
  const formattedDuration = `${days}d ${Math.floor(
    remainingHours
  )}h ${minutes}m`;
  return formattedDuration;
}

const TicketInfo = (props) => {
  const selectRef = useRef(null);
  const priorityRef = useRef(null);
  const portalRef = useRef(null);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        props.handleStatusModal(false);
      }

      if (priorityRef.current && !priorityRef.current.contains(event.target)) {
        props.handlePriorityModal(false);
      }

      if (portalRef.current && !portalRef.current.contains(event.target)) {
        props.handlePortalModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const approvalStatus = props?.ticketDetail?.approvalStatus || {};

  const ticketStatus = props?.ticketDetail?.status;
  const portalId = props?.ticketDetail?.portal_id;
  const foundTeamName = props.portalData?.data.find(
    (portal) => portal.id === portalId
  );
  const portalName = foundTeamName ? foundTeamName.name : "-";

  const showApproveBtn =
    approvalStatus.approvers && !approvalStatus.curr_approver_status;

  const showWaitingForApprovalOption =
    approvalStatus.status && ticketStatus !== "waiting for approval";

  const showEditStatus = ticketStatus !== "waiting for approval";

  const isStatusDisabled =
    ticketStatus === "waiting for approval" && approvalStatus.status;

  const showApproved =
    approvalStatus.approvers && approvalStatus.curr_approver_status;

  const { setTicketDetail, ticketDetail, getTicketDetails } = props;

  const columns = useMemo(() => {
    return [
      {
        label: "Status",
        value: () => {
          return (
            <>
              {" "}
              <div ref={selectRef}>
                {props.processing === "status" ? (
                  <div className="flex items-center space-x-2 text-gray-500">
                    <Spinner customStyle={{ height: 20, width: 20 }} />
                  </div>
                ) : (
                  <>
                    {isStatusDisabled ? (
                      getTicketStatusLabel(ticketStatus)
                    ) : (
                      <Select
                        className={`capitalize input-md text-sm ${
                          showApproveBtn || showApproved ? "" : "w-48"
                        } focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600`}
                        value={{
                          value: props.status,
                          label: getTicketStatusLabel(props.status),
                        }}
                        options={
                          showWaitingForApprovalOption
                            ? [
                                {
                                  value: "waiting for approval",
                                  label: getTicketStatusLabel(
                                    "waiting for approval"
                                  ),
                                },
                              ]
                            : [
                                {
                                  value: "inprogress",
                                  label: getTicketStatusLabel("inprogress"),
                                },
                                {
                                  value: "hold",
                                  label: getTicketStatusLabel("hold"),
                                },
                                {
                                  value: "completed",
                                  label: getTicketStatusLabel("completed"),
                                },
                              ]
                        }
                        onChange={(selectedOption) => {
                          props.updateTicket("status", selectedOption.value);
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: getStatusBackgroundColor(
                              props.status
                            ),
                          }),
                        }}
                        isDisabled={
                          ticketStatus === "waiting for approval" &&
                          approvalStatus.status
                        }
                      />
                    )}
                  </>
                )}
              </div>
              {showApproveBtn && (
                <button
                  className="text-xs cursor-pointer bg-indigo-600 py-2 px-4 text-white rounded-md flex items-center space-x-2"
                  onClick={props.approveTicketApi}
                  disabled={props.isApproveLoading}
                >
                  {props.isApproveLoading && <Loading />}
                  Approve
                </button>
              )}
              {showApproved && (
                <div className="text-xs text-green-600 border px-3 py-1 rounded-lg border-green-600">
                  Approved
                </div>
              )}
            </>
          );
        },
      },
      {
        label: "Priority",
        value: () => {
          return (
            <div ref={priorityRef}>
              {props.processing === "priority" ? (
                <div className="flex items-center space-x-2 text-gray-500">
                  <Spinner customStyle={{ height: 20, width: 20 }} />
                </div>
              ) : (
                <Select
                  className="capitalize input-md w-48 text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600"
                  value={{
                    value: props.priority,
                    label: props.priority,
                  }}
                  options={[
                    { value: "p1", label: "P1" },
                    { value: "p2", label: "P2" },
                    { value: "p3", label: "P3" },
                    { value: "p4", label: "P4" },
                  ]}
                  onChange={(selectedOption) =>
                    props.updateTicket("priority", selectedOption.value)
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        label: "Support Panel",
        value: () => {
          return (
            <div className="w-48" ref={portalRef}>
              {props.processing === "portal_id" ? (
                <div className="flex items-center space-x-2 text-gray-500">
                  <Spinner customStyle={{ height: 20, width: 20 }} />
                </div>
              ) : (
                <Select
                  className="capitalize input-md w-full text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 mb-4 focus:border-indigo-600"
                  value={{
                    value: portalId,
                    label: portalName,
                  }}
                  options={
                    props?.portalData?.data?.map((portal) => ({
                      value: portal.id,
                      label: portal.name,
                    })) || []
                  }
                  onChange={(selectedOption) =>
                    props.updateTicket("portal_id", selectedOption.value)
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        label: "Date Created",
        value: () => {
          return (
            <div>
              {new Date(props.ticketDetail.created_at).toLocaleDateString(
                "en-US",
                {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                }
              )}
            </div>
          );
        },
      },
      {
        label: "Due Date",
        value: () => {
          return (
            <div>
              {props.ticketDetail.remainingTime
                ? formatDuration(props.ticketDetail.remainingTime)
                : "-"}
            </div>
          );
        },
        key: "dueDate",
      },
      // {
      //   label: "Assignee(s)",
      //   value: () => {
      //     return (
      //       <TicketAssign
      //         ticketDetail={ticketDetail}
      //         handleTicketDetail={setTicketDetail}
      //         getTicketDetails={getTicketDetails}
      //       />
      //     );
      //   },
      // },
      {
        label: "Related Tickets",
        value: () => {
          return (
            <RelatedTickets
              handleTicketDetail={setTicketDetail}
              getTicketDetails={getTicketDetails}
              ticketDetail={ticketDetail}
            />
          );
        },
      },
      {
        label: "Child Tickets",
        value: () => {
          return <ChildTickets ticketDetail={ticketDetail} />;
        },
      },
    ];
  }, [
    approvalStatus.status,
    getTicketDetails,
    isStatusDisabled,
    portalId,
    portalName,
    props,
    setTicketDetail,
    showApproveBtn,
    showApproved,
    showWaitingForApprovalOption,
    ticketDetail,
    ticketStatus,
  ]);

  return (
    <div className="border border-gray-300 rounded-md bg-white p-6">
      <h4 className="font-semibold text-xl pb-2 border-b border-gray-300">
        Ticket Details
      </h4>

      <table class="table-auto mt-4 w-full">
        <tbody>
          {getColumns(columns, props).map((col, i) => {
            const { label, value } = col;
            return (
              <tr key={i}>
                <td className="font-semibold text-gray-700 pr-2 py-3 align-top w-[10.5rem]">
                  {label}
                </td>

                <td className="flex items-center space-x-4 py-3 w-full">
                  {value()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <CustomField
        getTicketDetails={getTicketDetails}
        ticketDetail={ticketDetail}
        handleTicketDetail={setTicketDetail}
      />
    </div>
  );
};

export default TicketInfo;

function Clock() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 20 20"
      className="text-lg"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
        clipRule="evenodd"
      />
    </svg>
  );
}
