import React, { useState } from "react";
import PropTypes from "prop-types";

const Field = (field) => {
  const [editableLable, setEditableLable] = useState(false);
  if (!field) {
    return null;
  }
  const { index, type, id, label, options } = field;
  const handleChange = (label) => {
    const currField = { index, type, id, label, options };
    field.handleLabelChange(currField);
  };

  const optionsFields = () => {
    field.setOptionValues(type, options, field.index);
  };

  return (
    <div>
      <div
        className="flex justify-between items-center form-item hover:border hover:p-2"
        onClick={(e) => optionsFields()}
      >
        <div className="w-[97%]">
          <label
            className="form-label mb-2 cursor-auto hover:border"
            htmlFor={id}
            contentEditable
            onBlur={(e) => handleChange(e.currentTarget.textContent)}
          >
            {label}
          </label>
          {type === "text" && (
            <div className="flex">
              <input
                className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                type="text"
                id={id}
              />
            </div>
          )}
          {type === "textarea" && (
            <div className="flex">
              <textarea
                className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                id={id}
              />
            </div>
          )}
          {type === "dropdown" && (
            <div className="flex">
              <select
                className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                id={id}
              >
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          {type === "multiselect" && (
            <div className="flex">
              <select
                className="input input-form input-md h-50 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                id={id}
                multiple
              >
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          {type === "date" && (
            <div className="flex">
              <input
                className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                type="date"
                id={id}
              />
            </div>
          )}
        </div>
        <button
          className="ml-3 mt-7"
          onClick={() => {
            field.handleRemoveField(field.index);
          }}
        >
          <i className="fa fa-times bg-red-100 hover:bg-red-500 rounded-full p-2"></i>
        </button>
      </div>
    </div>
  );
};

export default Field;
