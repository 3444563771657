import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../../api/api";
import SignInCommon from "../../components/common/SignInCommon";
import SignInWithGoogle from "../../components/common/SignInWithGoogle";
import SubdomainInput from "../../components/common/SubdomainInput";

const SignUp = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [userName, setUserName] = useState();
  const [subDomain, setSubDomain] = useState();
  const [userNameError, setUserNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [subDomainError, setSubDomainError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [processing, setProcessing] = useState(false);
  const [formError, setFormError] = useState("");
  const location = useNavigate();
  const { currUser, getUserInfo, setToastMessage, setShowToast } =
    useContext(AppContext);
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const validation = (name, value, error = null) => {
    if (name === "email") {
      if (error !== null) {
        setEmailError(error);
      } else {
        if (value !== "") {
          setEmail(value);
          setEmailError("");
        } else {
          setEmail("");
          setEmailError("The email field is required");
        }
      }
    }

    if (name === "username") {
      if (error !== null) {
        setUserNameError(error);
      } else {
        if (value !== "") {
          setUserName(value);
          setUserNameError("");
        } else {
          setUserName("");

          setUserNameError("The username field is required");
        }
      }
    }

    if (name === "subdomain") {
      if (error !== null) {
        setSubDomainError(error);
      } else {
        if (/\d/.test(value)) {
          setSubDomainError("Numbers not allowed!");
        } else if (value !== "") {
          setSubDomain(value);
          setSubDomainError("");
        } else {
          setSubDomain("");
          setSubDomainError("The subdomain field is required");
        }
      }
    }

    if (name === "password") {
      if (error !== null) {
        setPasswordError(error);
      } else {
        if (value !== "") {
          if (value.length > 7) {
            setPasswordError("");
            setPassword(value);
          } else {
            setPasswordError("Password must be at least 8 characters");
            setPassword(value);
          }
        } else {
          setPassword("");
          setPasswordError("Please enter your password");
        }
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    validation(e.target.name, e.target.value);
  };

  const handleForm = (e) => {
    e.preventDefault();
    var requestOptions = {
      email: email,
      password: password,
      username: userName,
      subdomain: subDomain,
    };

    // if (!subDomain) {
    //   setSubDomainError("Please enter your subdomain");
    //   return;
    // }

    // if (subDomainError) {
    //   return;
    // }
    setProcessing(true);

    api
      .post("/api/auth/register", requestOptions)
      .then((response) => {
        setProcessing(false);

        if (response.data.status === "error") {
          setFormError(response.data.message);

          return;
        }

        if (response.data.status === "success") {
          const { authorisation, Origin } = response?.data || {};

          // localStorage.setItem("jwtToken", authorisation.token);
          localStorage.setItem("requestedOrigin", Origin);
          setProcessing(false);
          location("/account-confirmation");
        }
        // console.log(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
        }
      });
  };
  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-4 h-full">
        <SignInCommon />
        <div className="col-span-2 my-5 flex flex-col justify-center items-center bg-white">
          <div className="xl:min-w-[550px] px-8">
            <div className="mb-8">
              <h3 className="mb-1 text-3xl font-semibold font-['Inter']">
                Hello!
              </h3>
              <p className="text-gray-600">
                Please enter your Details to Register!
              </p>
            </div>
            <div>
              <form action="#" method="post" onSubmit={handleForm}>
                <div className="form-container signup-form vertical">
                  <div className="form-item vertical">
                    <label className="form-label mb-1">Username</label>
                    <div className>
                      <input
                        className={`${
                          userNameError !== "" || formError !== ""
                            ? "input-danger"
                            : ""
                        } p-3 w-full input text-sm font-['Inter'] input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                        type="text"
                        name="username"
                        autoComplete="off"
                        placeholder="username"
                        onChange={(e) => handleChange(e)}
                      />
                      {userNameError !== "" ? (
                        <div className="form-error">{userNameError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-1">Email</label>
                    <div className>
                      <input
                        className={`${
                          emailError !== "" || formError !== ""
                            ? "input-danger"
                            : ""
                        } p-3 w-full input text-sm font-['Inter'] input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                        type="text"
                        name="email"
                        autoComplete="off"
                        placeholder="email"
                        onChange={(e) => handleChange(e)}
                      />
                      {emailError !== "" ? (
                        <div className="form-error">{emailError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-1">Password</label>
                    <div className>
                      <span className="input-wrapper w-full">
                        <input
                          className={`${
                            passwordError !== "" || formError !== ""
                              ? "input-danger"
                              : ""
                          } p-3 input w-full text-sm font-['Inter'] input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                          type={passwordType}
                          name="password"
                          autoComplete="off"
                          placeholder="password"
                          style={{ paddingRight: "2.25rem" }}
                          onChange={(e) => handleChange(e)}
                        />
                        <div className="input-suffix-end">
                          <span className="cursor-pointer text-base">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth={0}
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={togglePassword}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              />
                            </svg>
                          </span>
                        </div>
                      </span>
                      {passwordError !== "" ? (
                        <div className="form-error">{passwordError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-item vertical">
                    <label className="form-label mb-1">Subdomain</label>

                    <SubdomainInput
                      isError={subDomainError !== "" || formError !== ""}
                      handleChange={handleChange}
                    />
                    <div className>
                      {/* <input
                        className={`${
                          subDomainError !== "" || formError !== ""
                            ? "input-danger"
                            : ""
                        } p-3 w-full input text-sm font-['Poppins'] input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                        type="text"
                        name="subdomain"
                        autoComplete="off"
                        placeholder="subDomain"
                        onChange={(e) => handleChange(e)}
                      /> */}
                      {subDomainError !== "" ? (
                        <div className="form-error">{subDomainError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {formError !== "" ? (
                    <div className="mb-5 form-error">{formError}</div>
                  ) : (
                    ""
                  )}
                  <button
                    className="inline-flex font-['Inter'] items-center justify-center px-4 py-2 font-medium text-lg leading-6 shadow rounded grat-btn hover:shadow-lg h-11 transition ease-in-out duration-150 w-full"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Register
                  </button>
                  <div className="divider my-3">
                    <div className="divider-text font-['Inter']">or</div>
                  </div>
                  {/* <SignInWithGoogle /> */}
                  <div className="mt-4 text-center font-['Inter'] text-sm">
                    <span>Already have an account? </span>
                    <Link
                      className="text-[#0058FF] font-semibold underline"
                      to={`/signin`}
                    >
                      Sign in
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
