function findPercantage(usage, feature_limit) {
  let percentage = (usage / feature_limit) * 100;
  if (percentage > 100) {
    percentage = 100;
  }
  return `${percentage}%`;
}

function removeSpecialCharacters(string) {
  return string.replace(/[^\w\s]/gi, " ");
}

const Usage = ({ data }) => {
  const { planFeatures } = data || {};

  return (
    <div className="pb-10 ">
      <div className="mt-5 bg-white px-6 py-4 border border-gray-300 rounded-md">
        <div className="flex items-center justify-between w-full block">
          <h3 className="mb-4 text-md lg:mb-0 font-semibold">Usage</h3>
        </div>
        <div className="mt-3 flex flex-wrap gap-4">
          {planFeatures?.map((data, key) => {
            const { uuid, feature_name, feature_limit, usage } = data;
            return (
              <div
                key={uuid}
                className="usage-card w-1/4 grow border p-5 rounded-md"
              >
                <div className="flex items-center justify-between">
                  <h3 className="capitalize mb-4 text-sm lg:mb-0 font-medium text-gray-500">
                    {removeSpecialCharacters(feature_name)}
                  </h3>
                </div>
                <h3 className="mb-4 text-md lg:mb-0 font-semibold">
                  {usage} <span>of {feature_limit}</span>
                </h3>
                <div className="mt-3 w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className="bg-secondary-600 h-2.5 rounded-full"
                    style={{ width: findPercantage(usage, feature_limit) }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Usage;
