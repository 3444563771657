import { useContext, useEffect, useState } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import WhatsappReqForm from "./WhatsappReqForm";
import Modal from "../common/Modal";
import Loading from "../loaders/Loading";
import useApiData from "../../hooks/useApiData";
import Spinner from "../loaders/Spinner";

const WhatsappIntergration = () => {
  const [openModal, setOpenModal] = useState(false);
  const [facebookLoginUrl, setFacebookLoginUrl] = useState("");
  const { setShowToast, setToastMessage, currCommunityId } =
    useContext(AppContext);

  const { data, mutate, loading } = useApiData({
    onSuccess: () => {},
    onError: (err) => {
      const { message, errors } = err.response.data;
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: message ? message : "Something Went Wrong",
      });
    },
  });

  const checkStatus = () => {
    mutate(() =>
      api.post("/api/whatsappIntegrationDetail", {
        community_id: currCommunityId,
      })
    );
  };
  const statusMsg =
    data?.data?.status === 1
      ? "Request submitted"
      : data?.data?.status === 0 && "Request Approved";

  const statusPara =
    data?.data?.status === 1
      ? `We'll provide updates once WhatsApp is integrated into your community, your request ID is <strong>${data?.data?.request_id}</strong>.`
      : data?.data?.status === 0 &&
        "WhatsApp has been successfully integrated into your community, with request ID 123.";

  const isStatus = data?.data?.status === 1 || data?.data?.status === 0;

  return (
    <>
      <div
        onClick={() => {
          checkStatus();
          setOpenModal(!openModal);
        }}
        className="bg-white w-[330px] h-[220px] rounded-md border border-gray-300 flex flex-col justify-center items-center"
      >
        <img src="/images/logos/whatsapp-logo.svg" alt="whatsapp" />
        <div className="mt-4 font-semibold">Whatsapp</div>
      </div>

      {/* <div
        className="ml-3 cursor-pointer hover:shadow-xl duration-300 flex justify-center flex-col flex-wrap text-center items-center  integrators-icon bg-green-50 py-3 px-3 h-[80%] rounded-xl"
        onClick={() => setOpenModal(!openModal)}
      >
        <img
          src={require("../../assets/images/whatsapp.png")}
          alt="Welcome"
          className=""
        />
        <div className="mt-2 ">
          <span className="font-medium">Whatsapp</span>
          <p className="w-96 text-gray-500 w-auto"></p>
        </div>
      </div> */}

      {openModal ? (
        <Modal
          title={"Whatsapp Request"}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          customCls="!overflow-visible"
        >
          {loading ? (
            <div className="w-fit mx-auto mt-8">
              <Spinner />
            </div>
          ) : (
            <WhatsappReqForm
              details={{
                msg: statusMsg,
                para: <span dangerouslySetInnerHTML={{ __html: statusPara }} />,
                status: isStatus,
              }}
              onClose={() => setOpenModal(false)}
              checkStatus={checkStatus}
            />
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default WhatsappIntergration;
