import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function UsersTabs() {
  const [selectedTab, setSelectedTab] = useState("online");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const tab = location.pathname.includes("offline") ? "offline" : "online";

    setSelectedTab(tab);
  }, [location.pathname]);

  return (
    <div className="flex justify-center items-center font-semibold text-gray-700 w-fit h-fit p-1 rounded-lg border border-gray-300 bg-white">
      <button
        className={`px-8 py-2 rounded-lg ${
          selectedTab === "online" ? "bg-secondary-600 text-white" : ""
        }`}
        onClick={() => navigate("/customer-management")}
      >
        Online Users
      </button>

      <button
        className={`px-8 py-2 rounded-lg ${
          selectedTab === "offline" ? "bg-secondary-600 text-white" : ""
        }`}
        onClick={() => navigate("/customer-management/offline-users")}
      >
        Offline Users
      </button>
    </div>
  );
}

export default UsersTabs;
