import { useState } from "react";
import AddOptions from "./AddOptions";

function AddDialogBtn({
  updateFlows,
  nextFlowCount,
  setActiveDialog,
  disableAdd,
  setDisableAdd,
}) {
  const [openOptions, setOpenOptions] = useState(false);

  return (
    <div className="flex items-center space-x-3">
      <div className="w-40 border-b border-gray-400"></div>

      {openOptions ? (
        <AddOptions
          open={openOptions}
          setOpen={setOpenOptions}
          onOptionsChange={(dialog) => {
            updateFlows(
              {
                entityType: dialog,
                dialogmessages: [],
                dialogactions: [],
                displayOrder: nextFlowCount,
                create: true,
              },
              nextFlowCount
            );

            setActiveDialog(nextFlowCount);
            setDisableAdd(true);
          }}
        />
      ) : (
        <i
          className={`fa-solid fa-circle-plus text-2xl text-gray-600 hover:text-primary-600 cursor-pointer transition duration-100 ease-in-out ${
            disableAdd
              ? "!text-gray-400 hover:!text-gray-400 !cursor-not-allowed"
              : ""
          }`}
          onClick={() => {
            if (disableAdd) {
              return;
            }

            setOpenOptions(true);
          }}
        ></i>
      )}

      <div className="w-40 border-b border-gray-400"></div>
    </div>
  );
}

export default AddDialogBtn;
