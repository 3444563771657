import moment from "moment";
import { useState } from "react";
import { formatTimeFromSeconds } from "../common/commonHelpers";
import { Tooltip } from "react-tooltip";
import { DisplayCount, DisplayDetails } from "./MemberChatAnalytics";

function readableDate(date) {
  const originalDate = moment(date);
  const formattedDate = originalDate.format("DD MMM");

  return formattedDate;
}

function getDates(selectedDates) {
  const startDate = moment(selectedDates?.startDate);
  const endDate = moment(selectedDates?.endDate);

  const allDates = [];

  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    allDates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return allDates;
}

function MemberTicketAnalytics({ memberTickets, selectedDates }) {
  const [hovering, setHovering] = useState({});

  const dates = getDates(selectedDates);

  return (
    <div>
      <table className="min-w-full text-sm text-left">
        <thead className="uppercase bg-gray-50 issue-list-title">
          <tr>
            <th
              className={`p-3 border border-gray-300 bg-[#E3E9F3] sticky top-0 -left-1`}
            >
              Members
            </th>

            {dates.map((date, i) => {
              return (
                <th
                  key={i}
                  className={`border border-gray-300 bg-[#E3E9F3] p-3`}
                >
                  <div className="w-20">{readableDate(date)}</div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {memberTickets.map((member, i) => {
            const { ticketsData, username, email, image } = member;

            const name = username || email;

            const { total_average } = ticketsData || {};

            return (
              <tr key={i}>
                <td
                  className={`p-3 border border-gray-300 sticky -left-1 z-[50] bg-white`}
                >
                  <div className="flex items-center justify-between space-x-4 w-[18rem]">
                    {" "}
                    <div className="flex items-center space-x-4 h-[4rem]">
                      <div className="rounded-full w-[30px] h-[30px] flex justify-center items-center font-semibold bg-gray-100 overflow-hidden text-sm">
                        {image ? (
                          <img
                            src={image}
                            alt="user-profile"
                            className="object-cover w-full h-full"
                          />
                        ) : (
                          name[0]
                        )}
                      </div>

                      <div className="max-w-[6rem] truncate">{name}</div>
                    </div>
                    <div>
                      {total_average
                        ? formatTimeFromSeconds(total_average)
                        : null}
                    </div>
                  </div>
                </td>

                {dates.map((date, j) => {
                  const ticket = ticketsData ? ticketsData[date] : null;

                  const keyForData = member.id + "-" + date;

                  return (
                    <td
                      key={j}
                      className={`border border-gray-300 bg-white p-3`}
                    >
                      {ticket ? (
                        <DisplayTicketDetails
                          ticket={ticket || {}}
                          setHovering={setHovering}
                          hovering={hovering}
                          date={date}
                          keyForData={keyForData}
                        />
                      ) : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default MemberTicketAnalytics;

function DisplayTicketDetails({
  ticket,
  setHovering,
  hovering,
  date,
  keyForData,
}) {
  const {
    open,
    all_tickets,
    inprogress,
    completed,
    hold,
    waiting_for_approval,
    avg_resolve_time,
  } = ticket || {};

  return (
    <div className="flex items-center relative">
      <div className="flex space-x-2">
        <DisplayCount {...completed} />
        <div>/</div>
        <DisplayCount {...all_tickets} />
      </div>

      <i
        className={`fa-solid fa-circle-info ml-3 text-gray-600 cursor-pointer text-lg`}
        id={"member-ticket-" + keyForData}
      ></i>

      <Tooltip
        anchorSelect={"#member-ticket-" + keyForData}
        place="top"
        style={{
          zIndex: 99999,
        }}
        clickable
      >
        <>
          <DisplayDetails
            label="Open"
            value={<DisplayCount {...open} isToolTip={true} />}
          />
          <DisplayDetails
            label="In Progress"
            value={<DisplayCount {...inprogress} isToolTip={true} />}
          />
          <DisplayDetails
            label="Completed"
            value={<DisplayCount {...completed} isToolTip={true} />}
          />
          <DisplayDetails
            label="Hold"
            value={<DisplayCount {...hold} isToolTip={true} />}
          />
          <DisplayDetails
            label="Waiting For Approval"
            value={<DisplayCount {...waiting_for_approval} isToolTip={true} />}
          />
          <DisplayDetails
            label="All Tickets"
            value={<DisplayCount {...all_tickets} isToolTip={true} />}
          />
          <DisplayDetails
            label="Avg. resolved time"
            value={formatTimeFromSeconds(avg_resolve_time)}
          />
        </>
      </Tooltip>
    </div>
  );
}
