import { useCallback, useContext } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";

function useTicket() {
  const { currCommunityId } = useContext(AppContext);

  const getClients = useCallback(
    (payload) => {
      return () => api.post(`/api/${currCommunityId}/getClients`, payload);
    },
    [currCommunityId]
  );

  const getTicketClients = useCallback(
    (id) => {
      return () => api.get(`/api/${currCommunityId}/${id}/getTicketClients`);
    },
    [currCommunityId]
  );

  const addClientToTicket = useCallback(
    (id, payload) => {
      return () =>
        api.post(`/api/${currCommunityId}/${id}/addClientToTicket`, payload);
    },
    [currCommunityId]
  );

  const removeClientFromTicket = useCallback(
    (id, uuid) => {
      return () =>
        api.delete(
          `/api/${currCommunityId}/${id}/removeClientFromTicket/${uuid}`
        );
    },
    [currCommunityId]
  );

  const setAsPrimaryClientTicket = useCallback(
    (id, uuid) => {
      return () =>
        api.post(
          `/api/${currCommunityId}/${id}/setAsPrimaryClientTicket/${uuid}`
        );
    },
    [currCommunityId]
  );

  const getTicketClockWork = useCallback(
    (ticket_id) => {
      return () =>
        api.get(`/api/${currCommunityId}/getTicketClockWork/${ticket_id}`);
    },
    [currCommunityId]
  );

  const getTicketComments = useCallback(
    (ticket_id) => {
      return () =>
        api.get(`/api/${currCommunityId}/getTicketComments/${ticket_id}`);
    },
    [currCommunityId]
  );

  const getTicketHistory = useCallback(
    (ticket_id) => {
      return () =>
        api.get(`/api/${currCommunityId}/getTicketHistory/${ticket_id}`);
    },
    [currCommunityId]
  );

  const createSchedule = useCallback(
    (payload) => {
      return () => api.post(`/api/CreateSchedule`, payload);
    },
    [currCommunityId]
  );

  const getSchedule = useCallback(
    (payload) => {
      return () => api.post(`/api/Schedule`, payload);
    },
    [currCommunityId]
  );

  const updateSchedule = useCallback(
    (payload) => {
      return () => api.post(`/api/UpdateSchedule`, payload);
    },
    [currCommunityId]
  );

  const deleteSchedule = useCallback(
    (payload) => {
      return () => api.post(`/api/DeleteSchedule`, payload);
    },
    [currCommunityId]
  );

  return {
    getClients,
    getTicketClients,
    addClientToTicket,
    removeClientFromTicket,
    setAsPrimaryClientTicket,
    getTicketClockWork,
    getTicketComments,
    getTicketHistory,
    createSchedule,
    getSchedule,
    updateSchedule,
    deleteSchedule,
  };
}

export default useTicket;
