import React, { useState, useEffect, useContext } from "react";

import { AppContext } from "../../App";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import DependencyDropdown from "./DependencyDropdown";
import { validateAdditionalFields } from "../../hooks/commonHelper";

function DisplayCustomFields({ CustomField = {} }) {
  const { customfield_type, customfield_values, customfield_name } =
    CustomField;
  const { level1, level2, level3 } = customfield_values;

  if (customfield_type === "dependency_dropdown") {
    const levels = Object.keys(customfield_values);
    if (levels.length === 0 || !customfield_values[levels[0]]) {
      return null;
    }

    return (
      <tr>
        <td className="font-semibold text-gray-700 pr-4 py-3 align-top">
          <div className="border-l-2 border-gray-300 pl-1">
            {levels.map((level, index) => (
              <div key={level}>
                <span className="text-gray-400">{"-".repeat(index * 2)}</span>{" "}
                {customfield_name[level]}
              </div>
            ))}
          </div>
        </td>

        <td className="flex flex-col py-3 w-full">
          {levels.map((level) => (
            <div key={level}>{customfield_values[level]}</div>
          ))}
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className="font-semibold text-gray-700 pr-2 py-3 align-top w-[10.5rem]">
        {customfield_name}
      </td>

      <td className="flex items-center space-x-4 py-3 w-full">
        {customfield_values}
      </td>
    </tr>
  );
}

function DisplayCustomFieldsOld({ CustomField = {} }) {
  const { customfield_type, customfield_values, customfield_name } =
    CustomField;
  const { level1, level2, level3 } = customfield_values;

  if (customfield_type === "dependency_dropdown") {
    if (!level1) {
      return;
    }

    return (
      <div className="border-b">
        {" "}
        <div className="my-2 border-l border-gray-400 pl-1">
          {level1 && (
            <div>
              {customfield_name?.level1} : {customfield_values?.level1}
            </div>
          )}
          {level2 && (
            <div>
              <span className="text-gray-400">---</span>{" "}
              {customfield_name?.level2} : {customfield_values?.level2}
            </div>
          )}
          {level3 && (
            <div>
              <span className="text-gray-400">-----</span>{" "}
              {customfield_name?.level3} : {customfield_values?.level3}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="my-2 pb-1 border-b">
      {customfield_name} : {customfield_values}
    </div>
  );
}

const CustomField = (props) => {
  const [processing, setProcessing] = useState(false);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [commnuityFields, setcommnuityFields] = useState([]);
  const [additionaFields, setadditionaFields] = useState({});
  const [customFieldsModel, setCustomFieldsModel] = useState(false);
  const [additionalFieldsError, setAdditionalFieldsError] = useState({});
  const { id } = useParams();
  // useEffect(() => {
  //   props.handleDistributorRemove(!props.distributorRemove);
  // }, [customFieldsModel]);

  useEffect(() => {
    var postData = {
      community_id: currCommunityId,
    };
    api.post(`/api/getCommunityFields`, postData).then((response) => {
      if (response.data.status == "success") {
        // console.log("response.data.data", response.data.data);
        setcommnuityFields(response.data.data);
        // console.log("cc", commnuityFields);
      }
    });
  }, []);
  useEffect(() => {
    props.ticketDetail?.ticketCustomFields?.map((CustomField, index) => {
      setadditionaFields((prevFields) => ({
        ...prevFields,
        [CustomField.customfield_id]: CustomField.customfield_values,
      }));
    });
  }, [props]);
  const fieldhandleChange = (event, additionaFields, field) => {
    const { name, value } = event.target;
    const newFields = { ...additionaFields };

    if (value) {
      newFields[name] = value;
    } else {
      delete newFields[name];
    }
    setadditionaFields(newFields);
    const errorData = validateAdditionalFields(newFields, [field]);
    setAdditionalFieldsError((prev) => ({
      ...prev,
      [name]: errorData.error ? errorData.error[name] : "",
    }));
  };

  const folderForm = (e) => {
    e.preventDefault();
    let hasError = false;
    setProcessing(true);
    var postData = {
      ticket_id: id,
      community_id: currCommunityId,
      additionaFields: additionaFields,
    };

    if (postData.additionaFields) {
      const additionalFieldvalues = postData.additionaFields;
      const errorData = validateAdditionalFields(
        additionalFieldvalues,
        commnuityFields
      );
      hasError = errorData.havingError;
      setAdditionalFieldsError(errorData.error);
    }
    if (hasError) {
      setProcessing(false);
      return;
    }
    api
      .post("/api/updateCustomFields", postData)
      .then((response) => {
        if (response.data.status == "success") {
          props.handleTicketDetail(response.data.data);
          setProcessing(false);
          setadditionaFields({});
          setCustomFieldsModel(false);
          setShowToast(true);

          setToastMessage({
            type: "success",
            message: "Ticket Updated Successfully",
          });
        }
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);

        setToastMessage({
          type: "error",
          message: "something went wrong",
        });
      });
  };

  return (
    <div className="mt-6">
      <h4 className="font-semibold text-xl pb-2 border-b border-gray-300 flex space-x-4">
        <p>Custom Fields</p>

        <button onClick={() => setCustomFieldsModel(true)}>
          <i className="fa-regular fa-pen-to-square !text-[16px] text-indigo-600"></i>
        </button>
      </h4>

      {props.ticketDetail?.ticketCustomFields?.length ? (
        <table class="table-auto mt-4 w-full">
          <tbody>
            {props.ticketDetail?.ticketCustomFields?.map(
              (CustomField, index) =>
                CustomField.customfield_values && (
                  <DisplayCustomFields CustomField={CustomField} />
                )
            )}
          </tbody>
        </table>
      ) : (
        <div className="font-semibold text-lg pt-4 pb-2 text-gray-400">
          None
        </div>
      )}

      {customFieldsModel && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="flex items-start justify-between p-4 rounded-t">
                <h3 className="text-xl font-semibold text-gray-900  ">
                  Add Custom Field
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                  data-modal-hide="defaultModal"
                  onClick={() => setCustomFieldsModel(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form action="#" onSubmit={(e) => folderForm(e)}>
                <div className="px-6 max-h-[400px] overflow-y-scroll">
                  <div className="mb-4 mt-1">
                    {commnuityFields.map((item, idx) => (
                      <div className="py-4 border-b">
                        {commnuityFields[idx]["field_type"] == "input" && (
                          <div>
                            <label
                              className={`form-label mb-2 ${
                                commnuityFields[idx]["is_required"]
                                  ? "required-input"
                                  : ""
                              }`}
                            >
                              {commnuityFields[idx]["field_name"]}
                            </label>
                            <div className>
                              <input
                                className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                                type="text"
                                name={`${item.id}`}
                                autoComplete="off"
                                placeholder={commnuityFields[idx]["field_name"]}
                                value={
                                  additionaFields[commnuityFields[idx]["id"]]
                                }
                                onChange={(e) =>
                                  fieldhandleChange(e, additionaFields, item)
                                }
                                // required={commnuityFields[idx]["is_required"]}
                              />
                            </div>
                            {additionalFieldsError && (
                              <div className="form-error">
                                {additionalFieldsError[item.id]}
                              </div>
                            )}
                          </div>
                        )}
                        {commnuityFields[idx]["field_type"] == "select" && (
                          <div>
                            <label
                              className={`form-label mb-2 ${
                                commnuityFields[idx]["is_required"]
                                  ? "required-input"
                                  : ""
                              }`}
                            >
                              {commnuityFields[idx]["field_name"]}
                            </label>
                            <div className>
                              <select
                                className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                                value={
                                  additionaFields[commnuityFields[idx]["id"]]
                                }
                                onChange={(e) =>
                                  fieldhandleChange(e, additionaFields, item)
                                }
                                name={`${item.id}`}
                                // required={commnuityFields[idx]["is_required"]}
                              >
                                <option value="">
                                  Please select{" "}
                                  {commnuityFields[idx]["field_name"]}
                                </option>
                                {commnuityFields[idx]["field_values"].map(
                                  (field, index) => (
                                    <React.Fragment key={index}>
                                      <option value={field}>{field}</option>
                                    </React.Fragment>
                                  )
                                )}
                              </select>
                            </div>
                            {additionalFieldsError && (
                              <div className="form-error">
                                {additionalFieldsError[item.id]}
                              </div>
                            )}
                          </div>
                        )}

                        {item?.field_type === "dependency_dropdown" ? (
                          <DependencyDropdown
                            field={item}
                            selectedValues={additionaFields[item.id]}
                            id={item.id}
                            handleChange={(value) => {
                              const data = {
                                target: {
                                  name: item.id,
                                  value: Object.keys(value).length
                                    ? value
                                    : null,
                                },
                              };

                              fieldhandleChange(data, additionaFields, item);
                            }}
                            fromApprovalPage={item.is_required}
                            depenedencyError={additionalFieldsError[item.id]}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                  <button
                    className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                    type="submit"
                    disabled={processing}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomField;
