import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../../components/settings/SideBar";
import DisplayPortalStatus from "./DisplayPortalStatus";
import { useContext, useEffect, useMemo, useState } from "react";
import PortalForm from "./PortalForm";
import Theme from "./Theme";
import BackBtn from "../../../components/common/BackBtn";
import usePortals from "./usePortals";
import useApiData from "../../../hooks/useApiData";
import { AppContext } from "../../../App";
import Spinner from "../../../components/loaders/Spinner";
import KnowledgeBase from "./KnowledgeBase";

function UpdatePortal() {
  const [edit, setEdit] = useState(false);
  const [formError, setFormError] = useState({});
  const [updating, setUpdating] = useState("");

  const { onSuccess, onError } = useContext(AppContext);

  const navigate = useNavigate();

  const { id } = useParams();

  const { getPortal, updatePortal, deleteAdditionalLink } = usePortals();

  const { data, mutate, loading } = useApiData({
    onSuccess: (res) => {
      const { status } = res?.data;
      setUpdating("");

      if (status === "error") {
        onError("Unable to get portal");
      }
    },
    onError: (err) => {
      onError(err?.response?.data?.message || "Unable to get portal");
    },
  });

  const { mutate: updateApi, loading: isUpdating } = useApiData({
    onSuccess: (res) => {
      const { status } = res?.data;

      if (status === "success") {
        setFormError({});
        setEdit(false);
        onSuccess("Portal changes changed successfully");
      } else {
        setUpdating("");
        onError("Unable to update portal changes");
      }
    },
    onError: (err) => {
      const { message, error } = err?.response?.data || {};
      const { url } = error || {};

      const urlError = url?.length ? url[0] : "";

      if (urlError) {
        setFormError({ url: urlError });
      }

      setUpdating("");

      onError(urlError || message || "Unable to update portal status");
    },
  });

  const { mutate: deleteAdditionalLinkApi, loading: isDeleteAdditionalLink } =
    useApiData({
      onSuccess: (res) => {
        const { status } = res?.data;

        if (status === "success") {
          mutate(getPortal(id));
          onSuccess("Addtional Link Deleted successfully");
        } else {
          setUpdating("");
          onError("Unable to update portal changes");
        }
      },
      onError: (err) => {
        const { message, error } = err?.response?.data || {};
        const { url } = error || {};

        const urlError = url?.length ? url[0] : "";

        if (urlError) {
          setFormError({ url: urlError });
        }

        setUpdating("");

        onError(urlError || message || "Unable to update portal status");
      },
    });

  useEffect(() => {
    mutate(getPortal(id));
  }, []);

  useEffect(() => {
    if (!isUpdating && updating !== "knowledgeBase") {
      mutate(getPortal(id));
    }
  }, [isUpdating]);

  const portal = data?.data;

  const statusProps = useMemo(() => {
    return {
      onStatusUpdate: (status) => {
        setUpdating("status");
        updateApi(updatePortal(id, { status }));
      },
      statusUpdating: updating === "status",
    };
  }, [updateApi, updatePortal, updating, id]);
  console.log("updating", updating);

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            <div className="lg:flex items-center justify-between mb-4">
              <h3 className="mb-4 lg:mb-0 main-title">Portal Configuration</h3>
            </div>
            <div className="flex gap-4 text-gray-700">
              <SideBar />
              <div className="w-[80%]">
                <div className="mb-4 flex justify-between items-center">
                  <h3 className="text-xl font-bold">Portal Configuration</h3>
                </div>

                <div className="p-8 rounded-md bg-white shadow-md">
                  <BackBtn onClick={() => navigate("/settings/portals")} />

                  {loading && !data ? (
                    <Spinner />
                  ) : portal ? (
                    <>
                      {edit ? (
                        <div className="md:w-1/2">
                          <PortalForm
                            onCancel={() => setEdit(false)}
                            defaultValue={portal}
                            onSubmit={(form) => {
                              setUpdating("form");
                              updateApi(updatePortal(id, form));
                            }}
                            loading={updating === "form"}
                            error={formError}
                          />
                        </div>
                      ) : (
                        <DisplayPortal
                          portal={portal}
                          onEdit={() => setEdit(true)}
                          {...statusProps}
                        />
                      )}

                      <Theme
                        portal={portal}
                        updateTheme={(theme) => {
                          setUpdating("theme");
                          updateApi(updatePortal(id, { theme }));
                        }}
                        loading={updating === "theme"}
                      />
                      <KnowledgeBase
                        portal={portal}
                        updateKb={(kbDetails) => {
                          setUpdating("knowledgeBase");
                          updateApi(
                            updatePortal(id, { knowledgeBase: kbDetails })
                          );
                        }}
                        getLoading={loading}
                        loading={updating === "knowledgeBase"}
                        deleteKb={(uuid) => {
                          deleteAdditionalLinkApi(
                            deleteAdditionalLink(id, uuid)
                          );
                        }}
                        deleteLoading={isDeleteAdditionalLink}
                        isUpdating={isUpdating}
                      />
                    </>
                  ) : (
                    <div className="mt-6 text-gray-500 font-semibold text-xl">
                      Portal not found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePortal;

function DisplayPortal({ onEdit, portal, onStatusUpdate, statusUpdating }) {
  const { name, url, status, image, info } = portal;

  return (
    <div className="flex justify-between mt-6">
      <div className="flex flex-col space-y-6 text-gray-700">
        <div className="">
          Portal Image:{" "}
          <img
            src={image}
            className="font-semibold w-[300px] mt-3 height-[300px] overflow-hidden"
          />
        </div>
        <div>
          Portal Name: <span className="font-semibold">{name}</span>
        </div>

        <div className="flex items-center space-x-4">
          Portal URL:{" "}
          <div
            className="font-semibold text-blue-600 cursor-pointer xl:max-w-[400px] md:max-w-[300px] max-w-[200px] !break-words ml-2"
            onClick={() => {
              window.open(url, "_blank");
            }}
          >
            {url}
          </div>
        </div>

        <div className="flex items-center space-x-4">
          Portal Info:
          <div className="font-semibold xl:max-w-[400px] md:max-w-[300px] max-w-[200px] !break-words ml-2">
            {info}
          </div>
        </div>

        <div className="flex items-center text-sm">
          <div className="mr-2">Status:</div>{" "}
          <DisplayPortalStatus
            status={status}
            onClick={() => {
              const toggleStatus = status === 1 ? 0 : 1;

              onStatusUpdate(toggleStatus);
            }}
            loading={statusUpdating}
          />
        </div>
      </div>

      <button
        className="border border-blue-300 hover:bg-blue-50 px-4 py-2 rounded-md h-fit text-blue-600"
        onClick={onEdit}
      >
        <i className="fa fa-edit pr-1.5"></i> <span>Edit</span>
      </button>
    </div>
  );
}
