import { useContext, useEffect, useState } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import { useParams } from "react-router-dom";
import Spinner from "../loaders/Spinner";

const TicketCreate = () => {
  const [loader, setLoader] = useState(false);
  const [mailTemplate, setMailTemplate] = useState([]);
  const [processing, setProcessing] = useState(false);
  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    getMailTemplate();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setMailTemplate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getMailTemplate = () => {
    var postData = {
      name: "ticket_creating",
      community_id: id,
    };
    setLoader(true);
    api
      .post("/api/getEmailTemplates", postData)
      .then((response) => {
        setLoader(false);
        if (response.data.data != null) {
          setMailTemplate(response.data.data);
        }
      })
      .catch((error) => {
        setLoader(false);
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      });
  };

  const updateMailTemplate = (e) => {
    e.preventDefault();
    var postData = {
      name: "ticket_creating",
      community_id: id,
      subject: mailTemplate.subject,
      body: mailTemplate.body,
    };
    setProcessing(true);
    api
      .post("/api/addOrUpdateMailTemplate", postData)
      .then((response) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Email Template Saved Successfully",
        });
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      });
  };

  return (
    <>
      {loader ? (
        <div className="flex items-center justify-center h-[50vh]">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={(e) => updateMailTemplate(e)}>
          <div className="form-item vertical">
            <label className="form-label">Subject</label>
            <div>
              <input
                className=" input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                type="text"
                name="subject"
                autoComplete="off"
                placeholder="Enter subject"
                value={mailTemplate.subject}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="form-item vertical invalid">
            <label className="form-label mb-2">Body</label>
            <div>
              <textarea
                className=" input-textarea input-form input input-invalid input-textarea"
                type="text"
                name="body"
                autoComplete="off"
                placeholder="Enter Body"
                spellcheck="false"
                value={mailTemplate.body}
                onChange={(e) => handleChange(e)}
              ></textarea>
            </div>

            <div className="flex items-center justify-end mt-6 space-x-4">
              <button
                className="flex px-4 py-3 bg-indigo-600 rounded-md text-white font-medium"
                type="submit"
                disabled={processing}
              >
                {processing && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Save Changes
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default TicketCreate;
