import { useCallback, useContext } from "react";
import api from "../../../api/api";
import { AppContext } from "../../../App";

function usePricingPlan() {
  const { currCommunityId } = useContext(AppContext);

  const getAccountPlanDetails = useCallback(() => {
    return () => api.get(`/api/getAccountPlanDetails/${currCommunityId}`);
  }, [currCommunityId]);

  return {
    getAccountPlanDetails,
  };
}

export default usePricingPlan;
