import { useCallback, useContext } from "react";
import api from "../api/api";
import useApiData from "./useApiData";
import { AppContext } from "../App";

export function redirectToOrigin(Origin) {
  const jwtToken = localStorage.getItem("jwtToken");
  const currCommunity = localStorage.getItem("currCommunity");

  localStorage.clear();
  document.cookie = "chatSocketID-admin=";

  const origin = window.location.host.includes("localhost")
    ? window.location.origin
    : Origin;

  window.location.href = `${origin}/setting-up?jwtToken=${jwtToken}&currCommunity=${currCommunity}`;
}

function useCommunityDetails() {
  const { onError, setCurrCommunityId } = useContext(AppContext);

  const getCommunityDetails = useCallback((payload) => {
    const url = "/api/getCommunityDetails";

    return () => api.post(url, payload);
  }, []);

  const { mutate, loading } = useApiData({
    onSuccess: (res) => {
      redirectToOrigin(res?.data?.Origin);
    },
    onError: (error) => {
      console.log("error", error);
      onError("Something went wrong while getting community details");

      localStorage.removeItem("currCommunity");
      setCurrCommunityId("");
    },
  });

  return {
    getCommunityDetails: (payload) => {
      mutate(getCommunityDetails(payload));
    },
    loading,
  };
}

export default useCommunityDetails;
