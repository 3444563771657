import { useEffect, useState, useContext, useRef, useMemo } from "react";
import api from "../../api/api";
import { AppContext } from "../../App";
import Select from "react-select";
import { Link } from "react-router-dom";
import TicketAdditionalFieldsForm from "./TicketAdditionalFieldsForm";
import SelectEmails from "../common/SelectEmails";
import DisplayClientCustomFields from "../common/DisplayClientCustomFields";
import ClientCustomFieldsForm from "../common/ClientCustomFieldsForm";
import usePortals from "../../containers/settings/portals/usePortals";
import useApiData from "../../hooks/useApiData";
import ToolTip from "../common/ToolTip";
import useTicket from "../tickets/useTicket";
import { validateAdditionalFields } from "../../hooks/commonHelper";

function getClientDetails(data = {}) {
  const {
    client_name,
    client_phone_no,
    client_email,
    client_custom_fields = [],
    recipients,
  } = data;

  const refactor = {
    name: client_name,
    phoneNo: client_phone_no,
    email: client_email,
    customFieldValues: client_custom_fields?.map((c) => ({
      customfield_values: c?.customfield_values,
      customfield_id: c?.customfield_id,
    })),
    recipients,
  };

  return refactor;
}

const Usersdetails = (props) => {
  const [clientDetails, setClientDetails] = useState({});
  const [processing, setProcessing] = useState(false);
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [ticketsModal, setTicketsModal] = useState(false);
  const [relatedTicket, setRelatedTicket] = useState("");
  const [ticketOptions, setTicketOptions] = useState([]);
  const inputFileRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const [additionaFields, setadditionaFields] = useState({});
  const [clientErrors, setClientErrors] = useState({});
  const { getClients } = useTicket();
  const [additionalFieldsError, setAdditionalFieldsError] = useState({});
  const [error, setError] = useState({});

  const fieldhandleChange = (event, additionaFields, field) => {
    const { name, value } = event.target;
    const newFields = { ...additionaFields };

    if (value) {
      newFields[name] = value;
    } else {
      delete newFields[name];
    }
    setadditionaFields(newFields);
    const errorData = validateAdditionalFields(newFields, [field]);

    setAdditionalFieldsError((prev) => ({
      ...prev,

      [name]: errorData.error ? errorData.error[name] : "",
    }));
  };

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);

  const { data, mutate } = useApiData({});

  useEffect(() => {
    mutate(() =>
      api.post("/api/getCommunityFields", { community_id: currCommunityId })
    );
  }, []);

  const commnuityFields = data?.data || [];

  const { getPortals } = usePortals();
  const {
    data: portalData,
    mutate: getPortalsData,
    loading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => {},
  });

  const {
    data: clientData,
    mutate: getClientsData,
    loading: isClientLoading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => {},
  });

  useEffect(() => {
    getPortalsData(getPortals());
  }, []);

  useEffect(() => {
    var postData = {
      client_id: props.user_id,
      community_id: currCommunityId,
    };

    api.post("/api/getClient", postData).then((response) => {
      if (response.data.status == "success") {
        // console.log("response.data.data", response.data.data);
        props.setClient(response.data.data);

        setClientDetails(getClientDetails(response.data.data));
      }
    });

    var postData = {
      value: "",
      community_id: currCommunityId,
    };
    api.post("/api/searchTickets", postData).then((response) => {
      if (response.data.status == "success") {
        const formattedOptions = response.data.data.map((ticket) => ({
          value: ticket.ticket_id,
          label: ticket.topic,
        }));
        setTicketOptions(formattedOptions);
      }
    });
  }, [props.user_id]);

  const ticketSelectInput = (newValue) => {
    // console.log(newValue);
    var postData = {
      value: newValue,
      community_id: currCommunityId,
    };
    api.post("/api/searchTickets", postData).then((response) => {
      if (response.data.status == "success") {
        const formattedOptions = response.data.data.map((ticket) => ({
          value: ticket.ticket_id,
          label: ticket.topic,
        }));
        setTicketOptions(formattedOptions);
      }
    });
  };
  const userDetailsForm = (e) => {
    e.preventDefault();
    if (!clientDetails.email) {
      setClientErrors({
        email: "This field is required",
      });
      return;
    } else {
      setClientErrors({
        email: "",
      });
    }
    setProcessing(true);

    var postData = {
      client_id: props.user_id,
      chatId: props.selectedChat,
      client_name: clientDetails.name,
      client_email: clientDetails.email,
      client_phone_no: clientDetails.phoneNo,
      community_id: currCommunityId,
      customFieldValues: clientDetails.customFieldValues,
      recipients: clientDetails.recipients,
    };

    var url = "/api/createUpdateClient";

    api.post(url, postData).then((response) => {
      setProcessing(false);
      if (response.data.status == "success") {
        props.setClient(response.data.data);
        const updatedUsers = props.users.map((user) => {
          if (user.client_id === props.user_id) {
            return {
              ...user,
              client_name: response.data.data.client_name,
            };
          }
          return user;
        });
        props.setUsers(updatedUsers);

        setUserDetailsModal(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Details Added",
        });
      }
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setClientDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "email") {
      if (!value) {
        setClientErrors({
          email: "This field is required",
        });
        return;
      }
      setClientErrors({
        email: "",
      });
      const encodedEmail = value ? encodeURIComponent(value) : "";

      api
        .get(
          `/api/getClientDataEmail/${currCommunityId}?client_email=${encodedEmail}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            // console.log("response.data.data", response.data.data);
            // props.setClient(response.data.data);
            setClientDetails(getClientDetails(response.data.data));
          }
        });
    }

    setError((prev) => ({
      ...prev,
      [name]: value ? "" : "This field is required",
    }));
  };

  const createNewTicket = (e) => {
    e.preventDefault();
    setProcessing(true);
    var postData = {
      client_id: props.user_id,
      ticketContent: clientDetails.ticketContent,
      topic: clientDetails.topic,
      chatId: props.selectedChat,
      priority: clientDetails.priority,
      community_id: currCommunityId,
      relatedTicket: relatedTicket.value,
      status: "open",
      attachments: attachments,
      additionaFields: additionaFields,
      portal_id: clientDetails.portal_id,
    };
    const requiredFields = ["topic", "ticketContent", "priority", "portal_id"];
    let hasError = false;

    requiredFields.forEach((field) => {
      if (!postData[field]) {
        setError((prev) => ({
          ...prev,
          [field]: "This field is required",
        }));
        hasError = true;
      }
    });

    if (postData.additionaFields) {
      const additionalFieldvalues = postData.additionaFields;
      const errorData = validateAdditionalFields(
        additionalFieldvalues,
        commnuityFields
      );
      if (!hasError) {
        hasError = errorData.havingError;
      }
      setAdditionalFieldsError(errorData.error);
    }

    if (hasError) {
      setProcessing(false);
      return;
    }

    api.post("/api/createTicket", postData).then((response) => {
      if (response.data.status == "success") {
        props.setClient(response.data.data);

        const { tickets = [] } = response?.data?.data || {};

        const currentTicket = tickets[tickets?.length - 1] || {};

        props.setOnTicketCreation(currentTicket);

        setProcessing(false);
        setTicketsModal(false);
        setShowToast(true);
        setAttachments([]);
        setClientDetails("");
        setToastMessage({
          type: "success",
          message: "Ticket Created Successfully",
        });
      }
    });
  };

  const onSelectChange = (value) => {
    if (value) {
      setRelatedTicket({ value: value.value, label: value.label });
    }
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const maxSizeInBytes = 3145728; // 3MB

    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        if (attachment.size > maxSizeInBytes) {
          reject(new Error("File size exceeds the limit (3MB)"));
          return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      setAttachments(attachmentFiles);
      setProcessing(false);
    } catch (error) {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: error.message,
      });
    }
  };

  const recipients = useMemo(() => {
    return props?.userDetails?.recipients
      ? JSON.parse(props.userDetails.recipients)
      : [];
  }, [props?.userDetails?.recipients]);

  const { client_custom_fields } = props?.userDetails || {};

  if (props.user_id) {
    return (
      <div className="h-full text-gray-900 rounded-md overflow-y-auto custom-scroll overflow-hidden">
        {/* {JSON.stringify(userDetails)} */}
        <div className="">
          <div className="border border-gray-300 rounded-md bg-white">
            <div className="flex justify-between item-center">
              <span className="chat-header rounded-t-md w-full !font-semibold">
                User Details{" "}
                <button onClick={() => setUserDetailsModal(!userDetailsModal)}>
                  <i className="fa-regular fa-pen-to-square text-lg text-primary-600"></i>
                </button>
              </span>
            </div>
            <div className="py-1">
              <div className=" h-auto bg-white min-h-[6rem] mt-3">
                {userDetailsModal ? (
                  <form
                    action="#"
                    className=""
                    onSubmit={(e) => userDetailsForm(e)}
                  >
                    <div className="px-3">
                      <div className="mt-4">
                        <div className="form-container vertical">
                          <div className="form-item  mb-3 vertical">
                            <label className="form-label mb-2 text-sm">
                              Name
                            </label>
                            <div className>
                              <input
                                className={`input input-form input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                                type="text"
                                name="name"
                                autoComplete="off"
                                placeholder="Enter name"
                                defaultValue={props.userDetails.client_name}
                                onChange={(e) => handleChange(e)}
                                value={clientDetails.name}
                              />
                            </div>
                          </div>
                          <div className="form-item mb-3 vertical">
                            <label className="form-label mb-2 text-sm required-input">
                              Email
                            </label>
                            <div className>
                              {/* <input
                                className={`input input-form input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                                type="email"
                                name="email"
                                autoComplete="off"
                                placeholder="Enter email"
                                defaultValue={props.userDetails.client_email}
                                onChange={(e) => handleChange(e)}
                                required
                              /> */}

                              <SelectEmails
                                options={clientData?.data?.map((c) => ({
                                  label: c.client_email,
                                  value: c.client_email,
                                }))}
                                handleChange={(values) => {
                                  const event = {
                                    preventDefault: () => {},
                                    target: {
                                      name: "email",
                                      value: values.value,
                                    },
                                  };
                                  handleChange(event);
                                }}
                                onSearch={(value) =>
                                  getClientsData(getClients({ search: value }))
                                }
                                defaultValues={[
                                  {
                                    label: clientDetails.email,
                                    value: clientDetails.email,
                                  },
                                ]}
                                required={true}
                                isMulti={false}
                              />
                              {clientErrors?.email !== "" ? (
                                <div className="form-error">
                                  {clientErrors?.email}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          {/* <div className="form-item mb-3 vertical">
                          <label className="form-label mb-2 text-sm">
                            Recipients
                          </label>
                          <div className>
                            <SelectEmails
                              defaultValues={recipients.map((c) => ({
                                label: c,
                                value: c,
                              }))}
                              handleChange={(values) => {
                                setClientDetails((prevState) => ({
                                  ...prevState,
                                  recipients: values.map((v) => v.value),
                                }));
                              }}
                            />
                          </div>
                        </div> */}

                          <div className="form-item mb-3 vertical">
                            <label className="form-label mb-2 text-sm">
                              Phone No
                            </label>
                            <div className>
                              <input
                                className={`input input-form input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                                type="text"
                                name="phoneNo"
                                autoComplete="off"
                                placeholder="Enter phoneno"
                                defaultValue={props.userDetails.client_phone_no}
                                onChange={(e) => handleChange(e)}
                                value={clientDetails.phoneNo}
                              />
                            </div>
                          </div>

                          <ClientCustomFieldsForm
                            customFieldForm={clientDetails?.customFieldValues}
                            setCustomFieldForm={(values) => {
                              setClientDetails((prevState) => ({
                                ...prevState,
                                customFieldValues: values,
                              }));
                            }}
                            client_custom_fields={client_custom_fields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center px-3 pb-6 space-x-2  border-gray-200 rounded-b">
                      <button
                        className="block items-center justify-center button bg-gray-100 hover:bg-gray-500 hover:text-white ease-in duration-100 text-gray-700 radius-round h-11 py-2 px-4"
                        type="button"
                        onClick={() => {
                          setUserDetailsModal(false);
                        }}
                      >
                        <i className="fa-solid fa-chevron-left"></i>
                      </button>
                      <button
                        className="flex block items-center justify-center button bg-blue-500 text-white radius-round h-11 px-8 py-2 w-full"
                        type="submit"
                        disabled={processing}
                      >
                        {processing && (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}{" "}
                        Submit
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="w-full  rounded-b-md px-3 py-2 bg-white">
                    <div className="xl:flex xl:w-fit w-16 break-words">
                      {/* {JSON.stringify(userDetails)} */}
                      <div className="user-profile">
                        {props.userDetails && props.userDetails.client_name
                          ? Array.from(props.userDetails.client_name)[0]
                          : "G"}
                      </div>
                      <div className="detailsUser xl:mt-0 mt-4">
                        <div className="userName">
                          {props.userDetails && props.userDetails.client_name
                            ? props.userDetails.client_name
                            : "No Name"}
                        </div>
                        <div className="xl:flex others my-2">
                          <i className="fa-regular fa-envelope mr-2 mt-1.5"></i>
                          {props.userDetails && props.userDetails.client_email
                            ? props.userDetails.client_email
                            : "No email"}
                        </div>

                        {/* <div className="flex others my-2">
                        <i class="fa-solid fa-users  mr-2 mt-1.5 text-gray-700"></i>
                        {props.userDetails && props.userDetails.recipients
                          ? recipients.join(", ")
                          : "No recipients"}
                      </div> */}

                        <div className="others">
                          <i className="fa-solid fa-headset mr-2"></i>
                          {props.userDetails &&
                          props.userDetails.client_phone_no
                            ? props.userDetails.client_phone_no
                            : "No phone number"}
                        </div>

                        {client_custom_fields?.length ? (
                          <div className="others xl:flex !text-sm mt-1">
                            <i class="fa-solid fa-bars-progress mr-2 mt-1.5 text-gray-700"></i>
                            <DisplayClientCustomFields
                              client_custom_fields={
                                props?.userDetails?.client_custom_fields
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {ticketsModal ? (
            <div>
              <div className="mt-5 flex justify-between item-center">
                <span className="chat-header rounded-t-md w-full">
                  Create Ticket
                </span>
              </div>
              <div className="">
                <div className="flex min-h-[7rem] justify-center">
                  <div className="w-full chatuser-info rounded-b-md py-2 bg-white">
                    <form
                      action="#"
                      className=""
                      onSubmit={(e) => createNewTicket(e)}
                    >
                      <div className="px-3">
                        <div className="mt-4">
                          <div className="form-container vertical">
                            <div className="form-item  mb-3 vertical">
                              <label className="form-label mb-2 text-sm required-input">
                                Issue Topic
                              </label>
                              <div className>
                                <input
                                  className={`input input-form input-md h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                                  type="text"
                                  name="topic"
                                  autoComplete="off"
                                  placeholder="Enter Topic"
                                  spellCheck="false"
                                  value={clientDetails.ticketTopic}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              {error.topic && (
                                <div className="form-error">{error.topic}</div>
                              )}
                            </div>
                            <div className="form-item  mb-3 vertical">
                              <label className="form-label mb-2 text-sm required-input">
                                Priority
                              </label>
                              <div className>
                                <select
                                  className="input input-form input-md w-48 text-sm h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow"
                                  name="priority"
                                  value={clientDetails.priority}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">
                                    Please select a priority level
                                  </option>
                                  <option value="p4">Low</option>
                                  <option value="p3">Medium</option>
                                  <option value="p2">High</option>
                                  <option value="p1">Very high</option>
                                </select>
                              </div>
                              {error.priority && (
                                <div className="form-error">
                                  {error.priority}
                                </div>
                              )}
                            </div>
                            <div className="form-item  mb-3 related-tic vertical">
                              <label className="form-label mb-2">
                                Related Tickets
                              </label>
                              <div className>
                                <Select
                                  className="h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow"
                                  options={[...ticketOptions]}
                                  onInputChange={ticketSelectInput}
                                  onChange={onSelectChange}
                                  isClearable
                                  isSearchable
                                  formatOptionLabel={({ value, label }) => (
                                    <div>
                                      <span>{value}</span>
                                      <span> -- </span>
                                      <span>{label}</span>
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="form-item  mb-3 vertical">
                              <label className="form-label mb-2 text-sm required-input">
                                Content
                              </label>
                              <div className>
                                <textarea
                                  className={`input-textarea input-form input input-invalid input-textarea focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                                  type="text"
                                  name="ticketContent"
                                  autoComplete="off"
                                  placeholder="Enter content"
                                  spellCheck="false"
                                  value={clientDetails.ticketContent}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              {error.ticketContent && (
                                <div className="form-error">
                                  {error.ticketContent}
                                </div>
                              )}
                              <div>
                                <div className="form-item  mb-3 related-tic vertical">
                                  <label className="form-label mb-2 required-input">
                                    Select Portal
                                  </label>
                                  <div className>
                                    <Select
                                      className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                                      options={
                                        portalData?.data?.map((portal) => ({
                                          value: portal.id,
                                          label: portal.name,
                                        })) || []
                                      }
                                      onChange={(values, actions) => {
                                        const event = {
                                          preventDefault: () => {},
                                          target: {
                                            name: "portal_id",
                                            value: values ? values.value : "",
                                          },
                                        };
                                        handleChange(event);
                                      }}
                                      isClearable
                                      isSearchable
                                      formatOptionLabel={({ value, label }) => (
                                        <div>
                                          <span>{label}</span>
                                        </div>
                                      )}
                                    />
                                  </div>
                                  {error.portal_id && (
                                    <div className="form-error">
                                      {error.portal_id}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <TicketAdditionalFieldsForm
                                additionaFields={additionaFields}
                                fieldhandleChange={fieldhandleChange}
                                additionalFieldsError={additionalFieldsError}
                              />
                              <div>
                                <div
                                  onClick={handleSelectButtonClick}
                                  className="form-item  mb-3 vertical text-indigo-500 underline cursor-pointer"
                                >
                                  Select Attachments
                                </div>

                                <input
                                  type="file"
                                  className="hidden"
                                  ref={inputFileRef}
                                  onChange={handleFileUpload}
                                  multiple
                                />
                                {attachments?.length > 0 && (
                                  <span className="ml-2">
                                    {attachments.length} Files Selected
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center px-3 pb-6 space-x-2  border-gray-200 rounded-b">
                        <button
                          className="inline-flex items-center justify-center button bg-gray-100 hover:bg-gray-500 hover:text-white ease-in duration-100 text-gray-700 radius-round h-11 py-2 px-4"
                          type="button"
                          onClick={() => {
                            setTicketsModal(false);
                          }}
                        >
                          <i className="fa-solid fa-chevron-left"></i>
                        </button>
                        <button
                          className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                          type="submit"
                          disabled={processing}
                        >
                          {processing && (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )}{" "}
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {props?.userDetails?.tickets &&
              props.userDetails.tickets.length > 0 ? (
                <div>
                  <div className="border border-gray-300 rounded-md mt-5">
                    <div className="flex justify-between item-center ">
                      <span className="chat-header rounded-t-md w-full">
                        Previous Tickets{" "}
                      </span>
                    </div>
                    <div className="">
                      <div className="flex h-auto min-h-[7rem] justify-center">
                        <div className="w-full rounded-b-md px-3 py-2 bg-white">
                          {props.userDetails.tickets.map((ticket, index) => (
                            <div>
                              <div
                                key={index}
                                className="three-dots xl:!w-full !w-12"
                              >
                                <Link
                                  target="_blank"
                                  to={`/ticket-detail/${ticket.ticket_id}`}
                                >
                                  {ticket.ticket_id} - {ticket.topic}
                                </Link>
                              </div>

                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center mt-4">
                    <button
                      className={`flex justify-center items-center w-full hover:bg-primary-500 text-white text-[14px] py-2.5 rounded-md"
                  type="submit ${
                    !props.userDetails?.client_email
                      ? "pointer-events-none	cursor-not-allowed bg-gray-500"
                      : "bg-primary-600 "
                  }`}
                      type="submit"
                      disabled={
                        !props.userDetails?.client_name &&
                        !props.userDetails?.client_email
                          ? true
                          : processing
                      }
                      onClick={() => setTicketsModal(!ticketsModal)}
                    >
                      {processing && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      <img
                        src="/images/icons/add.svg"
                        alt="+"
                        className="mr-2 w-5"
                      />
                      New Ticket
                    </button>
                    {!props.userDetails?.client_email && (
                      <ToolTip
                        tip="Client email is missing to create a ticket"
                        tipCls="!w-40"
                      >
                        <i className="fa-regular fa-circle-question text-blue-700 cursor-pointer"></i>
                      </ToolTip>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex gap-2 items-center mt-4">
                  <button
                    className={`flex justify-center items-center w-full hover:bg-primary-500 text-white text-[14px] py-2.5 rounded-md"
                  type="submit ${
                    !props.userDetails?.client_email
                      ? "pointer-events-none	cursor-not-allowed bg-gray-500"
                      : "bg-primary-600 "
                  }`}
                    disabled={
                      !props.userDetails?.client_name &&
                      !props.userDetails?.client_email
                        ? true
                        : processing
                    }
                    onClick={() => setTicketsModal(!ticketsModal)}
                  >
                    {processing && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    <img
                      src="/images/icons/add.svg"
                      alt="+"
                      className="mr-2 w-5"
                    />
                    New Ticket
                  </button>
                  {!props.userDetails?.client_email && (
                    <ToolTip
                      tip="Client email is missing to create a ticket"
                      tipCls="!w-40"
                    >
                      <i className="fa-regular fa-circle-question text-blue-700 cursor-pointer"></i>
                    </ToolTip>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default Usersdetails;
