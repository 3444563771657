import { useState } from "react";
import useDropDown from "../../../hooks/useDropDown";

function DisplayMails({ to, setSelectedUsers, isPreview }) {
  const [viewFullMail, setViewFullMail] = useState(false);

  const { elementRef } = useDropDown({ setOpen: setViewFullMail });

  const remainingMailsCount = to?.length - 3;

  return (
    <div>
      <div
        className="leading-8"
        ref={elementRef}
        onClick={() => {
          if (to?.length > 1) {
            setViewFullMail(true);
          }
        }}
      >
        {viewFullMail ? (
          <div className="grid grid-cols-2 gap-4 w-fit">
            {to.map((mail, i) => {
              return (
                <div
                  key={i}
                  className="bg-gray-100 border border-gray-300 pl-2 rounded-md flex justify-between items-center truncate"
                >
                  <div className="w-[90%] truncate">{mail}</div>
                  {to?.length > 1 && !isPreview ? (
                    <i
                      className="fa-solid fa-xmark px-2 cursor-pointer"
                      onClick={() => {
                        setSelectedUsers((prev) =>
                          prev.filter((p) => p !== mail)
                        );
                      }}
                    ></i>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {to?.slice(0, 3)?.join(", ")}
            {remainingMailsCount > 0 ? (
              <span className="bg-gray-100 px-2 py-2 border border-gray-300 rounded-md ml-2">
                {remainingMailsCount + " more"}
              </span>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default DisplayMails;
