import Notifications from "../../containers/settings/notifications/Notifications";
import Modal from "../common/Modal";

function NotificationModal({ modal, setModal }) {
  return (
    <div>
      <Modal
        isOpen={modal}
        onClose={() => setModal("")}
        title={"Notification Settings"}
        customCls="md:!max-w-[1100px]"
      >
        <Notifications hideTitle={true} />
      </Modal>
    </div>
  );
}

export default NotificationModal;
