import ToggleBtn from "../../components/common/ToggleBtn";
import api from "../../api/api";
import { useContext, useState } from "react";
import { AppContext } from "../../App";
import { useParams } from "react-router-dom";
import Loading from "../../components/loaders/Loading";
import ShowWidgetConfig from "../../components/chatBot/ShowWidgetConfig";
import Radio from "../../components/common/Radio";

const options = [
  { label: "Enable", value: 1 },
  { label: "Disable", value: 0 },
];

function ChangeBotStatus({ status, updateBotStatus }) {
  return (
    <div>
      <ShowWidgetConfig
        label="Enable Live Agent"
        config={
          <Radio
            options={options}
            selectedValue={status}
            onChange={(value) => {
              updateBotStatus(value);
            }}
          />
        }
      />
    </div>
  );
}

export default ChangeBotStatus;
