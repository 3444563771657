import React, { useEffect, useState } from "react";
import ToggleBtn from "../../../common/ToggleBtn";
import { formatDateWithTime } from "../../../common/commonHelpers";
import Loading from "../../../loaders/Loading";

function BotDetailsCard({
  flow,
  onEdit,
  onToggleChange,
  toggleChecked,
  navigate,
  setNavToggle,
  onDelete,
  onTeamConfig,
  isUpdateLoading,
}) {
  const { flowName, created_at, uuid } = flow;
  const [seletedData, setSelectedData] = useState(false);

  useEffect(() => {
    setSelectedData(false);
  }, []);

  return (
    <button
      className="p-4 pl-8 rounded-md hover:shadow-md border bg-white w-[300px] text-left"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`./botflow/${uuid}`);
      }}
    >
      <div className="flex justify-between space-x-8">
        <div className="text-xl font-semibold text-gray-700 truncate">
          {flowName}

          <div className="text-[12px] font-normal">
            {formatDateWithTime(created_at)}
          </div>
        </div>

        <div className="flex items-center">
          {isUpdateLoading && seletedData ? (
            <Loading customCls="mr-0" />
          ) : (
            <ToggleBtn
              btnSize={"20px"}
              checked={toggleChecked}
              setChecked={(value) => {
                onToggleChange(value);
                setSelectedData(true);
              }}
            />
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mt-8 text-gray-600">
        {/* <div className="text-[12px]">{formatDateWithTime(created_at)}</div> */}
        <div></div>

        <div className="flex justify-center space-x-4">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onTeamConfig();
            }}
            className="hover:bg-gray-100"
          >
            <i class="fa-solid fa-people-group cursor-pointer hover:text-blue-600"></i>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className="hover:bg-gray-100"
          >
            <i class="fa-solid fa-pen-to-square cursor-pointer hover:text-blue-600"></i>
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            className="hover:bg-gray-100"
          >
            <i class="fa-solid fa-trash cursor-pointer hover:text-red-600"></i>
          </button>
        </div>
      </div>
    </button>
  );
}

export default BotDetailsCard;
