import { useEffect, useState } from "react";
import Loading from "../../loaders/Loading";

function TeamForm({ data = {}, loading, onSubmit = () => {}, buttonText }) {
  const [name, setName] = useState();

  useEffect(() => {
    setName(data?.name);
  }, [data]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ name });
      }}
      className="mt-4"
    >
      <input
        className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !normal-case"
        type="text"
        name="name"
        autoComplete="off"
        placeholder="Enter Team Name"
        required
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <button
        type="submit"
        className="mt-4 bg-indigo-600 px-4 py-1 rounded-md text-white flex items-center space-x-2"
      >
        {loading && <Loading />}
        {buttonText}
      </button>
    </form>
  );
}

export default TeamForm;
