import { useCallback, useContext, useEffect, useState } from "react";
import SignInCommon from "../../components/common/SignInCommon";
import BackBtn from "../../components/common/BackBtn";
import Loading from "../../components/loaders/Loading";
import api from "../../api/api";
import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";
import useApiData from "../../hooks/useApiData";
import SubdomainInput from "../../components/common/SubdomainInput";

function CreateSubDomain() {
  const { getUserInfo, onSuccess, onError } = useContext(AppContext);

  const navigate = useNavigate();

  const [subDomain, setSubDomain] = useState();
  const [subDomainError, setSubDomainError] = useState("");

  const { mutate, loading } = useApiData({
    onSuccess: (response) => {
      console.log("response", response);

      const { status, message } = response?.data || {};

      if (status === "success") {
        localStorage.clear();
        localStorage.setItem("requestedOrigin", subDomain);

        onSuccess("Subdomain created successfully!");

        navigate("/account-confirmation");
      } else {
        onError(message);
      }
    },
    onError: (error) => {
      console.log("error", error);

      const msg = error?.response?.data?.message;

      onError(typeof msg === "string" ? msg : "Something went wrong!");
    },
  });

  const createSubdomain = useCallback((payload) => {
    const url = "/api/auth/createSubdomain";

    return () => api.post(url, payload);
  }, []);

  function handleChange(value) {
    if (/\d/.test(value)) {
      setSubDomainError("Numbers not allowed!");
    } else if (!value) {
      setSubDomain("");
      setSubDomainError("Please enter your subdomain");
    } else {
      setSubDomain(value);
      setSubDomainError("");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (subDomainError) {
      return;
    }

    mutate(
      createSubdomain({
        subdomain: subDomain,
      })
    );
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-4 h-full">
        <SignInCommon />

        <div className="col-span-2 flex flex-col justify-center items-center bg-white">
          <div className="xl:min-w-[550px] px-8">
            <div className="mb-8 community-form-cnt">
              <BackBtn onClick={() => navigate("/communities")} />

              <h3 className="my-2 text-3xl text-slate-800 font-semibold font-['Inter']">
                Create Subdomain
              </h3>
              <p className="text-slate-800 text-base font-['Inter']">
                Please Enter a Subdomain
              </p>

              <form onSubmit={handleSubmit}>
                <div className="form-item vertical mt-6">
                  <label className="form-label mb-1 !text-gray-500">
                    Subdomain
                  </label>
                  <div className>
                    <SubdomainInput
                      isError={subDomainError}
                      handleChange={(e) => handleChange(e.target.value)}
                      inputProps={{ disabled: loading }}
                    />
                    {subDomainError ? (
                      <div className="form-error">{subDomainError}</div>
                    ) : null}
                  </div>

                  <button
                    className="mt-3 inline-flex items-center justify-center px-4 py-2 font-medium text-sm leading-6 shadow rounded text-white grat-btn  hover:shadow-lg h-11 font-['Inter'] transition ease-in-out duration-150 w-full button_animi"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && <Loading />}
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSubDomain;
