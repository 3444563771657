import { useContext, useEffect, useState } from "react";
import ApiValidation from "../../common/ApiValidation";
import Loading from "../../loaders/Loading";
import useKnowledgeBase from "./useKnowledgeBase";
import { useParams } from "react-router-dom";
import useApiData from "../../../hooks/useApiData";
import { AppContext } from "../../../App";

function TreeNode({
  label,
  children,
  extOnClick = () => {},
  initialOpen = false,
  checkedValue = false,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (initialOpen) {
      setOpen(true);
    }
    if (checkedValue) {
      setOpen(true);
    }
  }, [initialOpen, checkedValue]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    console.log("checkedValue || initialOpen", checkedValue || initialOpen);
    if (checkedValue || initialOpen) {
      extOnClick();
    }
  }, [open]);

  return (
    <div>
      <div className="flex items-center">
        <button
          onClick={() => {
            handleClick();
          }}
          className={`flex items-center ${
            !initialOpen &&
            "dashborder-custom after:border-dashed after:border-t-2 after:border-gray-400"
          }`}
        >
          <i class={`fa-solid fa-${open ? "minus" : "plus"} mr-3 text-sm`}></i>
        </button>
        {label}
      </div>
      {open ? (
        <div className="pl-5 ml-[0.30rem] border-dashed border-l-2 border-gray-400 ">
          {children}
        </div>
      ) : null}
    </div>
  );
}

const Knowledge = () => {
  const { id } = useParams();
  const [accessToken, setAccessToken] = useState("");
  const [accessTokenError, setAccessTokenError] = useState("");
  const { setToastMessage, setShowToast } = useContext(AppContext);
  const [knowledgeBaseData, setKnowledgeBaseData] = useState({
    batch: [],
    script: [],
  });
  const [knowledgeBasePayload, setKnowledgeBasePayload] = useState({
    batch: [],
    script: [],
  });
  const [firstLoad, setFirstLoad] = useState(false);
  const {
    getKnowledgeBatch,
    getKnowledgeScript,
    getKnowledgeBatchAndScripts,
    getScriptAndPage,
    getKnowledgeBaseIntegration,
    manageKnowledgeBaseIntegration,
  } = useKnowledgeBase();

  const {
    mutate,
    loading: isLoadingConfig,
    isError,
    error: errorConfig,
  } = useApiData({
    onSuccess: ({ data = {} }) => {
      const { access_token } = data?.data;
      setAccessToken(access_token);
      if (data?.data) {
        setKnowledgeBasePayload(data?.data);
      }
      setFirstLoad(true);
    },
    onError: () => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  const {
    mutate: manageKnowledgeBaseIntegrationMutate,
    loading: isLoadingManageKnowledgeBaseIntegration,
  } = useApiData({
    onSuccess: ({ data = {} }) => {
      mutate(getKnowledgeBaseIntegration(id));
    },
    onError: () => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  const { mutate: getKnowledgeBatchMutate, loading: isChecking } = useApiData({
    onSuccess: ({ data = {} }) => {
      const { batchs } = data?.data || {};
      const { statusCode } = data;
      if (statusCode == 200) {
        setKnowledgeBaseData((prev) => {
          const updatedData = {
            ...prev,
            ["batch"]: batchs,
          };
          markChecked(updatedData);
          return updatedData;
        });
      } else if (statusCode == 401) {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Invalid access token" });
      } else {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      }
    },
    onError: () => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  const {
    mutate: getKnowledgeScriptMutate,
    loading: isgetKnowledgeScriptChecking,
  } = useApiData({
    onSuccess: ({ data = {} }) => {
      const { script } = data?.data || {};
      const { statusCode } = data;
      if (statusCode == 200) {
        setKnowledgeBaseData((prev) => {
          const updatedData = {
            ...prev,
            ["script"]: script,
          };
          markChecked(updatedData);

          return updatedData;
        });
      } else if (statusCode == 401) {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Invalid access token" });
      } else {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      }
    },
    onError: () => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  const {
    mutate: getKnowledgeBatchAndScriptsMutate,
    loading: isgetKnowledgeBatchAndScriptsChecking,
  } = useApiData({
    onSuccess: ({ data = {} }) => {
      const { result } = data?.data || {};
      const { statusCode, batch_id } = data;
      if (statusCode == 200) {
        setKnowledgeBaseData((prev) => {
          const updatedData = {
            ...prev,
            batch: prev?.batch.map((batch) => {
              if (batch.uuid === batch_id) {
                batch.ChildPages = result;
              }
              return batch;
            }),
          };
          markChecked(updatedData);

          return updatedData;
        });
      } else if (statusCode == 401) {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Invalid access token" });
      } else {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      }
    },
    onError: () => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  const {
    mutate: getScriptAndPageMutate,
    loading: isgetScriptAndPageChecking,
  } = useApiData({
    onSuccess: ({ data = {} }) => {
      const { hierarchy } = data?.data || {};
      const { statusCode, script_id } = data;
      if (statusCode == 200) {
        setKnowledgeBaseData((prev) => {
          const updatedData = {
            ...prev,
            batch: prev?.batch
              ? prev?.batch?.map((item) =>
                  item.ChildPages &&
                  item.ChildPages.some((data) => data.uuid === script_id)
                    ? {
                        ...item,
                        ChildPages: item.ChildPages.map((data) =>
                          data.uuid === script_id
                            ? { ...data, ChildPages: hierarchy }
                            : data
                        ),
                      }
                    : item
                )
              : [],
            script: prev?.script
              ? prev?.script?.map((script) => {
                  if (script.uuid === script_id) {
                    script.ChildPages = hierarchy;
                  }
                  return script;
                })
              : [],
          };

          markChecked(updatedData);

          return updatedData;
        });
      } else if (statusCode == 401) {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Invalid access token" });
      } else {
        setShowToast(true);
        setToastMessage({ type: "error", message: "Something Went Wrong" });
      }
    },
    onError: () => {
      setShowToast(true);
      setToastMessage({ type: "error", message: "Something Went Wrong" });
    },
  });

  useEffect(() => {
    mutate(getKnowledgeBaseIntegration(id));
  }, []);

  useEffect(() => {
    markChecked(knowledgeBaseData);
  }, [knowledgeBasePayload]);

  useEffect(() => {
    if (firstLoad && accessToken) {
      getKnowledgeBatchMutate(getKnowledgeBatch(accessToken));
      getKnowledgeScriptMutate(getKnowledgeScript(accessToken));
      setFirstLoad(false);
    }
  }, [firstLoad]);

  const getChildPages = (type, value) => {
    if (type == "batch") {
      getKnowledgeBatchAndScriptsMutate(
        getKnowledgeBatchAndScripts(accessToken, value)
      );
    }
    // console.log("type", type);
    if (type == "script") {
      getScriptAndPageMutate(getScriptAndPage(accessToken, value));
    }
  };

  const handleCheckboxChange = (uuid, isChecked) => {
    const updatedData = knowledgeBaseData;
    const updatedPayload = knowledgeBasePayload?.data;
    const updateCheckedProperty = (array) => {
      for (const item of array) {
        if (item.uuid === uuid) {
          item.checked = isChecked;
        }

        if (item.ChildPages) {
          updateCheckedProperty(item.ChildPages);
        }
      }
    };
    console.log("updatedPayload", updatedPayload, updatedData);

    updateCheckedProperty(updatedData?.batch ? updatedData?.batch : []);
    updateCheckedProperty(updatedData?.script ? updatedData?.script : []);

    updateCheckedProperty(updatedPayload?.batch ? updatedPayload?.batch : []);
    updateCheckedProperty(updatedPayload?.script ? updatedPayload?.script : []);

    setKnowledgeBaseData(updatedData);
    setKnowledgeBasePayload((prev) => ({
      ...prev,
      data: updatedPayload,
    }));
  };

  function markChecked(kbData) {
    console.log("triggered??????", kbData, knowledgeBasePayload?.data);
    const mergeCheck = (array, dbArray) => {
      for (let i = array?.length - 1; i >= 0; i--) {
        const item = array[i];

        const matchingBatchItemOne = dbArray?.find(
          (kbItem) => kbItem.uuid === item.uuid
        );

        if (matchingBatchItemOne) {
          item.checked = matchingBatchItemOne.checked;
        }

        if (item.ChildPages) {
          // console.log("item.ChildPages", item.ChildPages, matchingBatchItemOne);
          mergeCheck(item.ChildPages, matchingBatchItemOne?.ChildPages);
        }
      }
    };

    mergeCheck(kbData?.batch, knowledgeBasePayload?.data?.batch);
    mergeCheck(kbData?.script, knowledgeBasePayload?.data?.script);
  }

  function formSubmit() {
    // console.log("knowledgeBasePayload", knowledgeBasePayload);
    const payload = {
      id: knowledgeBasePayload?.uuid ? knowledgeBasePayload?.uuid : "",
      access_token: accessToken,
      data: getAllCheckedItems(),
      status: knowledgeBasePayload?.status,
    };

    manageKnowledgeBaseIntegrationMutate(
      manageKnowledgeBaseIntegration(id, payload)
    );
  }

  const getAllCheckedItems = (data = null) => {
    let checkedItems = deepCopy(knowledgeBaseData);
    if (data) {
      checkedItems = deepCopy(data);
    }
    const filterUncheckedItems = (array) => {
      for (let i = array.length - 1; i >= 0; i--) {
        const item = array[i];

        if (!item.checked) {
          array.splice(i, 1);
        } else {
          const { uuid, title, ChildPages, checked } = item;
          array[i] = { uuid, title, ChildPages, checked };
        }

        if (item.ChildPages) {
          filterUncheckedItems(item.ChildPages);
        }
      }
    };

    filterUncheckedItems(checkedItems.batch);
    filterUncheckedItems(checkedItems.script);

    // console.log(checkedItems);
    return checkedItems;
  };

  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

  return (
    <ApiValidation
      loading={isLoadingConfig}
      isError={isError}
      error={errorConfig}
      loadingCls="ml-8"
    >
      <div className="text-lg pt-4 h-full">
        <div className="mb-4 flex items-center gap-3">
          <h3 className="font-semibold px-5">Knowledge Base</h3>
          {knowledgeBasePayload?.status && knowledgeBasePayload?.status == 1 ? (
            <img
              src="/images/icons/toggle-on.svg"
              alt="online"
              className="cursor-pointer"
              onClick={(e) =>
                setKnowledgeBasePayload((prev) => ({
                  ...prev,
                  status: 0,
                }))
              }
            />
          ) : (
            <img
              src="/images/icons/toggle-off.svg"
              alt="online"
              className="cursor-pointer"
              onClick={(e) =>
                setKnowledgeBasePayload((prev) => ({
                  ...prev,
                  status: 1,
                }))
              }
            />
          )}
        </div>
        <AccessTokenInput
          accessToken={accessToken}
          setAccessToken={setAccessToken}
          setKnowledgeBaseData={setKnowledgeBaseData}
          formSubmit={(e) => {
            e.preventDefault();
            setAccessTokenError("");
            getKnowledgeBatchMutate(getKnowledgeBatch(accessToken));
            getKnowledgeScriptMutate(getKnowledgeScript(accessToken));
          }}
          loading={isChecking}
          accessTokenError={accessTokenError}
        />
        {knowledgeBaseData ? (
          <div className="mb-5 flex justify-between w-[80%] pt-8">
            <h3 className="mb-4 font-semibold pl-5 w-[250px]">Location</h3>

            <div className="w-full h-full flex flex-col justify-between">
              <div>
                <TreeNode
                  label={
                    <div className="mt-0.5">
                      <i className="fa-regular fa-folder"></i> Projects
                    </div>
                  }
                  initialOpen={true}
                >
                  <RenderRefactor
                    data={knowledgeBaseData.batch}
                    type="batch"
                    getChildPages={getChildPages}
                    isLoading={isgetKnowledgeBatchAndScriptsChecking}
                    handleCheckboxChange={handleCheckboxChange}
                  />

                  <RenderRefactor
                    data={knowledgeBaseData.script}
                    type="script"
                    getChildPages={getChildPages}
                    isLoading={isgetScriptAndPageChecking}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </TreeNode>
              </div>
              <button
                onClick={formSubmit}
                className="px-8 py-2 rounded-md bg-indigo-600 text-white text-[16px] absolute bottom-4 right-4"
                disabled={isLoadingManageKnowledgeBaseIntegration}
              >
                {isLoadingManageKnowledgeBaseIntegration && <Loading />}
                Save Changes
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </ApiValidation>
  );
};

export default Knowledge;

function AccessTokenInput({
  accessToken,
  setAccessToken,
  formSubmit,
  loading,
  accessTokenError,
}) {
  return (
    <div className="pb-6 border-b border-gray-300">
      <form
        onSubmit={formSubmit}
        className="flex justify-between items-center w-[80%] mx-5"
      >
        <div className="w-[250px] text-[16px]">Access Token</div>

        <div className="flex items-center w-full">
          <input
            type="text"
            name="field_name"
            value={accessToken}
            onChange={(e) => {
              setAccessToken(e.target.value);
            }}
            className="border rounded-md w-full px-2 py-2 truncate !normal-case text-sm"
            required
          />

          <button
            className="bg-indigo-600 text-white text-sm px-8 py-2 rounded-md ml-2 font-semibold"
            type="submit"
            disabled={loading}
          >
            {loading && <Loading />}
            Check
          </button>
        </div>
      </form>

      {accessTokenError ? (
        <div className="form-error">{accessTokenError}</div>
      ) : null}
    </div>
  );
}

function RenderRefactor({
  data,
  type,
  getChildPages = () => {},
  isLoading,
  handleCheckboxChange = () => {},
}) {
  const [selectedParent, setSelectedParent] = useState();

  return data?.map((p, i) => {
    const { uuid: value, title: label, ChildPages, checked } = p;
    return (
      <div className="flex items-center" key={i}>
        {type === "page" && ChildPages.length == 0 ? (
          <div className="flex items-center pl-[1.3rem]" key={i}>
            <input
              type="checkbox"
              id={value}
              value={value}
              className="h-fit"
              onChange={(e) => handleCheckboxChange(value, e.target.checked)}
              checked={checked ? checked : false}
            />
            <div className="mt-1.5 ml-3">
              <label for={value}>
                <i className="fa-regular fa-file-lines"></i> {label}
              </label>
            </div>
          </div>
        ) : (
          <TreeNode
            label={
              <>
                <input
                  type="checkbox"
                  id={value}
                  value={value}
                  onChange={(e) => {
                    getChildPages(type, value);
                    handleCheckboxChange(value, e.target.checked);
                    setSelectedParent(value);
                  }}
                  className="h-fit"
                  checked={checked ? checked : false}
                />
                <div className="mt-1.5 ml-3">
                  <label for={value}>
                    {type == "batch" ? (
                      <i className="mr-2 fa-regular fa-folder"></i>
                    ) : type == "script" ? (
                      <i className="mr-2 fa-regular fa-file"></i>
                    ) : (
                      <i className="mr-2 fa-regular fa-file-lines"></i>
                    )}
                    {label}
                  </label>
                </div>
              </>
            }
            extOnClick={() => {
              setSelectedParent(value);
              getChildPages(type, value);
            }}
            checkedValue={checked ? checked : false}
          >
            {ChildPages && type == "batch" ? (
              <RenderRefactor
                data={ChildPages ? ChildPages : []}
                type="script"
                getChildPages={getChildPages}
                handleCheckboxChange={handleCheckboxChange}
              />
            ) : (
              <RenderRefactor
                data={ChildPages ? ChildPages : []}
                type="page"
                getChildPages={() => {}}
                handleCheckboxChange={handleCheckboxChange}
              />
            )}
          </TreeNode>
        )}
      </div>
    );
  });
}
