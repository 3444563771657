import { useContext, useEffect, useMemo, useState } from "react";
import SearchInput from "../../../../common/SearchInput";
import { AppContext } from "../../../../../App";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useChildFlow from "./useChildFlow";
import useApiData from "../../../../../hooks/useApiData";
import Spinner from "../../../../loaders/Spinner";
import { sortByDisplayOrder } from "../../../../common/commonHelpers";
import DeleteModal from "../../../../common/DeleteModal";
import Modal from "../../../../common/Modal";
import ChildFlowNameForm from "./ChildFlowNameForm";

const pages = ["Hello", "UI Elements and API's", "Setting Properties"];

function ListChildFlows() {
  const [search, setSearch] = useState("");

  const [openForm, setOpenForm] = useState({
    open: false,
    type: "",
    data: null,
  });

  function clearForm() {
    setOpenForm({
      open: false,
      type: "",
      data: null,
    });
  }

  const { onSuccess, onError } = useContext(AppContext);

  const { id, botflowId } = useParams();

  const basePath = `/train-chat-bot/${id}/botflow/${botflowId}`;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const childFlowId = searchParams.get("childFlowId");

  const { getChildFlows, createChildFlow, updateChildFlow, deleteChildFlow } =
    useChildFlow(id, botflowId);

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting child flows"),
  });

  const { mutate: create, loading: isCreateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Flow Created Successfully");
      clearForm();
      getList(getChildFlows());
    },
    onError: () => onError("Something went wrong while creating flow"),
  });

  const { mutate: update, loading: isUpdateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Flow Updated Successfully");
      clearForm();
      getList(getChildFlows());
    },
    onError: () => onError("Something went wrong while updating flow"),
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Flow Deleted Successfully");

      const { data } = openForm || {};
      // if deleting flow is equal to selected child flow we need to redirect to home page
      const redirect = data?.uuid === childFlowId;

      clearForm();
      getList(getChildFlows());

      if (redirect) {
        navigate(basePath);
      }
    },
    onError: () => onError("Something went wrong while deleting flow"),
  });

  const flows = sortByDisplayOrder(data?.data, "displayorder");

  useEffect(() => {
    if (!search || search?.length > 2) {
      getList(getChildFlows({ search }));
    }
  }, [search]);

  // if (loading) {
  //   return (
  //     <div className="w-fit mx-auto mt-8">
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <div className="mt-6 text-gray-700">
      <div className="my-2 mb-4 flex items-center">
        <SearchInput
          onSearch={(e) => {
            setSearch(e.target.value);
          }}
          customCls="w-11/12"
        />

        <i
          class="fa-solid fa-circle-plus text-xl cursor-pointer pr-4"
          onClick={() => {
            setOpenForm({
              open: true,
              type: "Create",
              data: null,
            });
          }}
        ></i>
      </div>

      {loading ? (
        <div className="w-fit mx-auto mt-8">
          <Spinner />
        </div>
      ) : (
        <>
          {flows.map((flow, i) => {
            const { uuid, flowName, displayorder } = flow;

            const isActive = childFlowId === uuid;

            return (
              <div key={i} className="">
                <button
                  className={`flex justify-between items-center text-left w-[98%] py-3 px-4 border-b text-gray-700 hover:bg-gray-100 ${
                    isActive ? "bg-blue-100 border-b-2 border-blue-300" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();

                    navigate({
                      pathname: basePath,
                      search: `?childFlowId=${uuid}`,
                    });
                  }}
                >
                  <div className="truncate">
                    {displayorder}. {flowName}
                  </div>

                  <Actions
                    onEdit={() =>
                      setOpenForm({
                        open: true,
                        type: "Edit",
                        data: flow,
                      })
                    }
                    onDelete={() =>
                      setOpenForm({
                        open: true,
                        type: "Delete",
                        data: flow,
                      })
                    }
                  />
                </button>
              </div>
            );
          })}
        </>
      )}

      <DeleteModal
        title={"Are you sure, you want to delete this flow"}
        open={openForm.open && openForm?.type === "Delete"}
        onClose={clearForm}
        loading={isDeleteLoading}
        onDelete={() => {
          deleteApi(deleteChildFlow(openForm?.data?.uuid));
        }}
      />

      <Modal
        title={`${openForm.type} Flow`}
        isOpen={openForm?.open && openForm?.type !== "Delete"}
        onClose={clearForm}
      >
        <ChildFlowNameForm
          propertyData={openForm?.data}
          onSubmit={(data) => {
            if (openForm?.data) {
              update(updateChildFlow(data, openForm?.data?.uuid));
            } else {
              create(createChildFlow(data));
            }
          }}
          loading={isCreateLoading || isUpdateLoading}
        />
      </Modal>
    </div>
  );
}

export default ListChildFlows;

function Actions({ onEdit, onDelete }) {
  return (
    <div className="flex items-center">
      <button
        className="hover:bg-gray-100"
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <i className="fa-solid fa-pen-to-square hover:text-blue-600 m-2"></i>
      </button>

      <button
        className="hover:bg-gray-100"
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <i className="fa-solid fa-trash hover:text-red-600 m-2"></i>
      </button>
    </div>
  );
}
