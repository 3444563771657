import { useEffect, useRef, useState } from "react";
import ToolTip from "../../../common/ToolTip";
import useDropDown from "../../../../hooks/useDropDown";
import MessageDialog from "./messageDialog/MessageDialog";
import ActionDialog from "./actionDialog/ActionDialog";
import {
  handleDialogEditListener,
  handleTextChange,
  onChangingBtns,
  onSelectingActionType,
  onSelectingApis,
  onSelectingResponseType,
  onSetProperty,
  onSetPropertyName,
  onSubmitForm,
} from "./helpers/dialogHelpers";
import AddOptions from "./common/AddOptions";

function DialogForm({
  flow,
  updateFlows,
  displayOrder,
  createBotFlowSetup,
  onDeleteDialog,
  error,
  setActiveDialog,
  activeDialog,
  setDisableAdd,
}) {
  const elementRef = useRef(null);

  const { entityType, dialogmessages, dialogactions } = flow;

  const { dialogresponses } = dialogmessages?.length ? dialogmessages[0] : {};

  const defaultProps = {
    flow,
    updateFlows,
    displayOrder,
  };

  const defaultActionProps = {
    dialogactions,
    updateFlows,
    displayOrder,
  };

  const isActive = useRef(String(activeDialog) === String(displayOrder));

  function saveBotFlow() {
    if (isActive.current) {
      createBotFlowSetup(displayOrder);
    }
  }

  useEffect(() => {
    isActive.current = String(activeDialog) === String(displayOrder);
  }, [activeDialog, displayOrder]);

  function handleAddEventListener(e) {
    handleDialogEditListener(e, elementRef, () => {
      if (isActive.current) {
        saveBotFlow();
      }
    });
  }

  function handleRemoveEventListener() {
    document.body.removeEventListener("click", handleAddEventListener);
  }

  useEffect(() => {
    setTimeout(() => {
      if (isActive.current) {
        document.body.addEventListener("click", handleAddEventListener);
      } else {
        handleRemoveEventListener();
      }
    }, 100);

    return handleRemoveEventListener;
  }, [isActive.current]);
  isActive.current = String(activeDialog) === String(displayOrder);

  // console.log(String(activeDialog), String(displayOrder), entityType, isActive);
  return (
    <div className={``}>
      <div
        ref={elementRef}
        onClick={() => {
          if (!activeDialog) {
            setActiveDialog(displayOrder);
            isActive.current = true;
            setDisableAdd(true);
          }
        }}
      >
        {entityType === "MESSAGE" ? (
          <MessageDialog
            msgData={flow?.dialogmessages[0] || {}}
            dialogResponses={dialogresponses || []}
            onTextMsgChange={(value, remoteVariablesData) => {
              handleTextChange(value, remoteVariablesData, defaultProps);
            }}
            onSelectingResponseType={(val) => {
              onSelectingResponseType(val, defaultProps);
            }}
            onChangingBtns={(btnData) => {
              onChangingBtns(btnData, defaultProps);
            }}
            onSubmitForm={(data) => {
              onSubmitForm(data, defaultProps);
            }}
            onDeleteDialog={onDeleteDialog}
            displayOrder={displayOrder}
            error={error?.message}
            isActive={isActive}
          />
        ) : null}

        {entityType === "ACTION" ? (
          <ActionDialog
            actionsData={dialogactions[0] || {}}
            onSelectingActionType={(actionType) => {
              onSelectingActionType(actionType, defaultActionProps);
            }}
            onSelectingApis={(api) => {
              onSelectingApis(api, defaultActionProps);
            }}
            onSetProperty={(newPropertyData) => {
              onSetProperty(newPropertyData, defaultActionProps);
            }}
            onSetPropertyName={(newPropertyData, configurationId) => {
              onSetPropertyName(
                newPropertyData,
                configurationId,
                defaultActionProps
              );
            }}
            onDeleteDialog={onDeleteDialog}
            error={error?.action || {}}
            isActive={isActive}
          />
        ) : null}
      </div>
    </div>
  );
}

export default DialogForm;
