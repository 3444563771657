import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import DisplayClientCustomFields from "../common/DisplayClientCustomFields";
import ClientCustomFieldsForm from "../common/ClientCustomFieldsForm";
import SelectEmails from "../common/SelectEmails";
import Modal from "../common/Modal";
import PrimaryButton from "../common/PrimaryButton";
import ClientCard from "./ClientCard";
import useTicket from "./useTicket";
import useApiData from "../../hooks/useApiData";
import Spinner from "../loaders/Spinner";
import { useParams } from "react-router-dom";
import Loading from "../loaders/Loading";

const ClientDetails = ({ clientDetails, setClientDetails, ...props }) => {
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  // const [clientDetails, setClientDetails] = useState({});
  const [customFieldForm, setCustomFieldForm] = useState(null);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [clientListOpen, setClientListOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const btnRef = useRef();
  const { id } = useParams();

  const {
    getClients,
    getTicketClients,
    addClientToTicket,
    removeClientFromTicket,
    setAsPrimaryClientTicket,
  } = useTicket();

  const {
    data: clientData,
    mutate: getClientsData,
    loading: isClientLoading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => onError("Something went wrong while getting Clients"),
  });

  const {
    data: ticketClientData,
    mutate: getTicketClientsData,
    loading: isTicketClientLoading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => onError("Something went wrong while getting Ticket Clients"),
  });

  const { mutate: addTicketClientData, loading: isAddTicketClientLoading } =
    useApiData({
      onSuccess: (res) => {
        getTicketClientsData(getTicketClients(id));
        onSuccess("Client added to this ticket");
        setClientListOpen(false);
      },
      onError: (err) => {
        const { msg } = err.response.data;
        onError(msg ? msg : "Something went wrong while Adding Ticket Clients");
      },
    });

  const {
    mutate: removeTicketClientData,
    loading: isRemoveTicketClientLoading,
  } = useApiData({
    onSuccess: (res) => {
      getTicketClientsData(getTicketClients(id));
      onSuccess("Client Removed to this ticket");
    },
    onError: (err) => {
      console.log("err", err);
      onError(
        err?.msg
          ? err?.msg
          : "Something went wrong while Removeing Client from Ticket "
      );
    },
  });

  const {
    mutate: setAsPrimaryClientTicketData,
    loading: isSetAsPrimaryClientTicketLoading,
  } = useApiData({
    onSuccess: (res) => {
      getTicketClientsData(getTicketClients(id));
      onSuccess("Client setted as primary to this ticket");
    },
    onError: (err) => {
      console.log("err", err);
      onError(
        err?.msg
          ? err?.msg
          : "Something went wrong while setting primary Client"
      );
    },
  });

  const modalToggle = (value) => {
    setClientListOpen(value);
    if (value) {
      getClientsData(getClients());
    }
  };

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  function onSuccess(msg) {
    setShowToast(true);
    setToastMessage({
      type: "success",
      message: msg,
    });
  }

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setClientDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getTicketClientsData(getTicketClients(id));
    function handleClickOutside(event) {
      if (
        btnRef.current &&
        !btnRef.current.contains(event.target) &&
        event.target.closest("#client-list") == null
      ) {
        setClientListOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function updateClientDetails() {
    setProcessing(true);
    var postData = {
      client_name: clientDetails.name,
      client_email: clientDetails.email,
      client_phone_no: clientDetails.phoneNo,
      customFieldValues: clientDetails?.customFieldValues,
      community_id: currCommunityId,
      ticket_id: id,
    };

    var url = "/api/createUpdateClient";

    api
      .post(url, postData)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          setUserDetailsModal(false);
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Details Added",
          });
          props.getTicketDetails();
          getClientsData(getClients());
          setClientListOpen(false);
          setOpenConfirmation(false);
        }
      })
      .catch((err) => {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message:
            err?.response?.data?.message ||
            "Something went wrong while adding client",
        });
        setClientListOpen(false);
        setOpenConfirmation(false);
      });
  }

  const userDetailsForm = (e) => {
    e.preventDefault();

    // if (
    //   props.ticketDetail?.client_email &&
    //   props.ticketDetail?.client_email !== clientDetails.email
    // ) {
    //   setOpenConfirmation(true);
    //   return;
    // }

    updateClientDetails();
  };

  const { client_custom_fields, recipients } = props?.ticketDetail || {};

  const recipientsData = useMemo(() => {
    return recipients ? JSON.parse(recipients) : [];
  }, [recipients]);

  console.log("ticketClientData?.data", ticketClientData?.data);

  return (
    <div className="border border-gray-300 rounded-md bg-white p-6 mt-6">
      <div className="pb-2 border-b border-gray-300 flex items-center justify-between relative">
        <h4 className="font-semibold text-xl">Clients</h4>

        <button
          type="button"
          className="flex duration-300 ease-in text-indigo-600 font-medium text-sm hover:bg-gray-100"
          disabled={isAddTicketClientLoading}
          ref={btnRef}
          onClick={() => modalToggle(!clientListOpen)}
        >
          {isAddTicketClientLoading ? <Loading /> : null}
          {clientListOpen ? "Back" : "Add Client"}
        </button>
      </div>

      {clientListOpen ? (
        <div id="client-list" className="bg-white w-full my-4">
          <button
            className="w-full hover:bg-gray-100 py-2 border border-gray-300 rounded-md"
            onClick={() => {
              setUserDetailsModal(true);
              setClientListOpen(false);
            }}
          >
            Create New Client
          </button>

          <div className="my-2 text-center">or</div>

          <div className="mt-2">
            <span className="mb-3 w-full input-wrapper ">
              <div className="input-suffix-start">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  className="text-lg"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                className="input search-new-chatbot input-sm h-12  focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 w-full"
                type="text"
                placeholder="Choose from existing clients"
                onChange={(e) =>
                  getClientsData(getClients({ search: e.target.value }))
                }
              ></input>
            </span>
            <div className="h-[200px] overflow-y-scroll border border-gray-300">
              {!isClientLoading ? (
                clientData?.data.length > 0 ? (
                  clientData?.data.map((client, key) => (
                    <button
                      key={key}
                      type="button"
                      className="text-left border-b flex cursor-pointer w-full rounded-b-md px-3 py-2 text-gray-700 text-md flex duration-300 ease-in pt-2 hover:bg-gray-200"
                      onClick={() => {
                        addTicketClientData(
                          addClientToTicket(id, {
                            client_id: client.client_id,
                          })
                        );
                      }}
                    >
                      <div className="capitalize user-profile">
                        {client && client.client_name
                          ? Array.from(client.client_name)[0]
                          : "C"}
                      </div>
                      <div className="detailsUser">
                        <div className="userName">
                          {client && client.client_name
                            ? client.client_name
                            : client.client_email}
                        </div>
                        <div className="others text-sm whitespace-normal break-words w-[200px]">
                          {client.client_email}
                        </div>
                      </div>
                    </button>
                  ))
                ) : (
                  <div className="p-4 text-center font-semibold text-gray-500">
                    Client not found
                  </div>
                )
              ) : (
                <div className="w-fit mx-auto mt-8">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {userDetailsModal ? (
        <div className="flex flex-col h-auto min-h-[7rem]">
          <form action="#" className="" onSubmit={(e) => userDetailsForm(e)}>
            <div className="px-3">
              <div className="mt-4">
                <div className="form-container vertical">
                  <div className="form-item  mb-3 vertical ">
                    <label className="form-label mb-2 text-sm required-input">
                      Name
                    </label>
                    <div className>
                      <input
                        className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                        type="text"
                        name="name"
                        autoComplete="off"
                        placeholder="Enter name"
                        onChange={(e) => handleInputChange(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-item mb-3 vertical">
                    <label className="form-label mb-2 text-sm required-input">
                      Email
                    </label>
                    <div className>
                      <input
                        className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                        type="text"
                        name="email"
                        autoComplete="off"
                        placeholder="Enter email"
                        required
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>

                  {/* <div className="form-item mb-3 vertical">
                      <label className="form-label mb-2 text-sm">
                        Recipients
                      </label>
                      <div>
                        <SelectEmails
                          defaultValues={recipientsData.map((c) => ({
                            label: c,
                            value: c,
                          }))}
                          handleChange={(values) => {
                            setClientDetails((prevState) => ({
                              ...prevState,
                              recipients: values.map((v) => v.value),
                            }));
                          }}
                        />
                      </div>
                    </div> */}

                  <div className="form-item mb-3 vertical">
                    <label className="form-label mb-2 text-sm">Phone No</label>
                    <div className>
                      <input
                        className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                        type="text"
                        name="phoneNo"
                        autoComplete="off"
                        placeholder="Enter phoneno"
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>

                  <ClientCustomFieldsForm
                    customFieldForm={clientDetails?.customFieldValues}
                    setCustomFieldForm={(values) => {
                      setClientDetails((prevState) => ({
                        ...prevState,
                        customFieldValues: values,
                      }));
                    }}
                    client_custom_fields={client_custom_fields}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center px-3 pb-6 space-x-2  border-gray-200 rounded-b">
              <button
                className="inline-flex items-center justify-center button bg-gray-100 hover:bg-gray-500 hover:text-white ease-in duration-100 text-gray-700 radius-round h-11 py-2 px-4"
                type="submit"
                onClick={() => {
                  setUserDetailsModal(false);
                }}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <button
                className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                type="submit"
                disabled={processing}
              >
                {processing && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}{" "}
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : null}

      {userDetailsModal || clientListOpen ? null : (
        <div className="w-full relative">
          <div>
            {!isTicketClientLoading ? (
              ticketClientData?.data?.map((client) => (
                <ClientCard
                  client={client}
                  onRemove={(uuid) => {
                    removeTicketClientData(removeClientFromTicket(id, uuid));
                  }}
                  isRemoveTicketClientLoading={isRemoveTicketClientLoading}
                  onSetAsPrimary={(uuid) => {
                    setAsPrimaryClientTicketData(
                      setAsPrimaryClientTicket(id, uuid)
                    );
                  }}
                  isSetAsPrimaryClientTicketLoading={
                    isSetAsPrimaryClientTicketLoading
                  }
                />
              ))
            ) : (
              <div className="w-fit mx-auto mt-8">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}

      <ConfirmUserMailChange
        isOpen={openConfirmation}
        onClose={() => {
          setOpenConfirmation(false);
          setUserDetailsModal(false);
        }}
        onYes={() => {
          updateClientDetails();
        }}
        processing={processing}
      />
    </div>
  );
};

export default ClientDetails;

function ConfirmUserMailChange({ isOpen, onClose, onYes, processing }) {
  return (
    <Modal isOpen={isOpen} title="Confirmation" onClose={onClose}>
      <div className="py-8 px-4">
        <div className="text-2xl font-semibold text-gray-700 text-center mb-2">
          Are you sure?{" "}
        </div>
        <div className="text-lg font-semibold text-gray-700">
          You are going to change client email. The new Client will get this
          ticket details
        </div>

        <div className="flex justify-center items-center space-x-4 mt-6">
          <PrimaryButton
            label="Yes, change email"
            onClick={onYes}
            loading={processing}
          />

          <button
            className="border border-blue-600 text-blue-600 px-4 py-2 rounded-md hover:bg-blue-50"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
