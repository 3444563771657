import React, { useState, useEffect, useContext } from "react";
import Imap from "./Imap";
import Smtp from "./Smtp";

const MailIntergration = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  return (
    <div>
      <div
        onClick={() => setOpenModal(!openModal)}
        className="bg-white w-[330px] h-[220px] rounded-md border border-gray-300 flex flex-col justify-center items-center"
      >
        <img src="/images/logos/mail-logo.svg" alt="mail" />
        <div className="mt-4 font-semibold">Email</div>
      </div>

      {openModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="text-center flex items-start justify-between p-4 rounded-t">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Mail Intergration
                  </h3>
                </div>
                <div className="flex">
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                    data-modal-hide="defaultModal"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
              </div>
              <div className="px-6 pb-5">
                <div className="mb-5 flex gap-1 border-b">
                  <div
                    className={`font-medium cursor-pointer tab-nav tab-nav-pill tab-nav-active px-3 py-1 hover:main-color duration-300 ease hover:border-b border-main-color hover:border-b-2 ${
                      openTab == 1
                        ? "main-color border-b border-b-2 border-main-color"
                        : ""
                    }`}
                    role="tab"
                    aria-selected="true"
                    tabIndex="0"
                    onClick={() => setOpenTab(1)}
                  >
                    IMAP
                  </div>
                  <div
                    className={`font-medium cursor-pointer tab-nav tab-nav-pill tab-nav-active px-3 py-1 hover:main-color duration-300 ease hover:border-b border-main-color hover:border-b-2 ${
                      openTab == 2
                        ? "main-color border-b border-b-2 border-main-color"
                        : ""
                    }`}
                    role="tab"
                    aria-selected="true"
                    tabIndex="0"
                    onClick={() => setOpenTab(2)}
                  >
                    SMTP
                  </div>
                </div>
                {openTab == 1 && <Imap />}
                {openTab == 2 && <Smtp />}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MailIntergration;
