import { useContext, useState } from "react";
import Loading from "../loaders/Loading";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import { AppContext } from "../../App";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const inputFields = [
  {
    label: "Whatsapp Display Name",
    name: "bussiness_name",
    type: "text",
    required: true,
    Comp: Input,
    props: {
      className:
        "my-1.5 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600",
    },
    error: "",
  },
  {
    label: "Whatsapp Phone Number",
    name: "phone_number",
    Comp: PhoneInput,
    required: true,
    props: {
      country: "us",
      enableSearch: true,
      inputProps: {
        name: "phone_number",
        required: true,
        autoFocus: true,
      },
    },
    error: "",
  },
];

function WhatsappReqForm({ onClose, details, checkStatus }) {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const { setToastMessage, setShowToast, currCommunityId } =
    useContext(AppContext);

  const { mutate: submitMutate, loading } = useApiData({
    onSuccess: () => {
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Request Send Successfully",
      });
      checkStatus();
    },
    onError: (err) => {
      console.log(err);
      const { message, errors } = err.response.data;
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: message ? message : "Something Went Wrong",
      });
    },
  });

  function onSubmit(e) {
    e.preventDefault();

    let isValid = true;
    const newFormErrors = {};

    inputFields.forEach((input) => {
      if (input.required && !formData[input.name]) {
        newFormErrors[input.name] = `${input.label} is required`;
        isValid = false;
      }
    });

    if (!isValid) {
      setFormErrors(newFormErrors);
      return;
    }

    const payload = { community_id: currCommunityId, ...formData };
    submitMutate(() => api.post("/api/whatsappIntegrationRequest", payload));
  }
  if (details.status) {
    return (
      <div
        id="modal-box"
        class="sm:w-[385px] text-center flex gap-2 flex-col mt-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-[#059669] mx-auto h-11 rounded-full bg-[#D1FAE5] w-11"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M5 13l4 4L19 7"
          />
        </svg>
        <span className="text-2xl font-bold">{details.msg}</span>
        <p className="text-center">{details.para}</p>
      </div>
    );
  }

  return (
    <form className="text-left my-6 pb-12 space-y-4" onSubmit={onSubmit}>
      {inputFields.map((input, i) => {
        const { label, name, type, required, Comp, props, error } = input;

        return (
          <div key={i}>
            <label className={required ? "required-input" : ""}>{label}</label>
            <Comp
              type={type}
              name={name}
              value={formData[name]}
              onChange={(e) => {
                const value = name === "phone_number" ? e : e.target.value;
                setFormData((prev) => ({ ...prev, [name]: value }));
                setFormErrors((prev) => ({ ...prev, [name]: "" }));
              }}
              {...props}
            />
            {formErrors[name] && (
              <div className="form-error">{formErrors[name]}</div>
            )}
          </div>
        );
      })}

      <button
        type="submit"
        className="flex save-btn justify-end mt-4 float-right button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2"
        disabled={loading}
      >
        {loading && <Loading />}
        Send Request
      </button>
    </form>
  );
}

export default WhatsappReqForm;

function Input(props) {
  return <input {...props} />;
}
