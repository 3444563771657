import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import SelectEmails from "../common/SelectEmails";
import useApiData from "../../hooks/useApiData";
import api from "../../api/api";
import { AppContext } from "../../App";
import Spinner from "../loaders/Spinner";
import DependencyDropdown from "./DependencyDropdown";
import DropDown from "../common/DropDown";
import DeleteModal from "../common/DeleteModal";
import useTeam from "../settings/team/useTeam";
import Loading from "../loaders/Loading";

function getFieldsOptions(fieldsData = []) {
  const options = [];

  fieldsData.forEach((f) => {
    const { field_name, field_type, id } = f;

    let label = "";

    if (field_type === "dependency_dropdown") {
      label = Object.values(field_name).join("--> ");
    } else {
      label = field_name;
    }

    options.push({
      label,
      value: id,
      field_type,
    });
  });

  return options;
}

function getColumns(props = {}) {
  return [
    {
      name: "Action",
      render: (data = {}) => <ToggleBtn props={props} data={data} />,
    },
    {
      name: <div className="required-input">Labels</div>,
      render: (data = {}) => {
        const { autoAssign = {}, autoAssignIndex } = data;

        const { custom_field_id } = autoAssign;

        const existingFormData = props.formData[autoAssignIndex] || {};

        const { label = "" } = existingFormData;

        return (
          <Input
            props={{
              ...props,
              required: true,
              value: label,
              onChange: (e) =>
                props.updateFormData(
                  {
                    label: e.target.value,
                    custom_field_id,
                  },
                  autoAssignIndex
                ),
            }}
            data={data}
            required={true}
          />
        );
      },
    },
    {
      name: "Field Names",
      render: (data = {}) => <FieldNames props={props} data={data} />,
    },
    {
      name: "Values",
      render: (data = {}) => <FieldValues props={props} data={data} />,
    },
    {
      name: <div className="required-input">Teams</div>,
      render: (data = {}) => <SelectTeam props={props} data={data} />,
    },
  ];
}

function AutoAssignForm({ closeModal }) {
  const { currCommunityId, currUser, setShowToast, setToastMessage, onError } =
    useContext(AppContext);

  const [formData, setFormData] = useState({});
  const [deleteId, setDeleteId] = useState();

  const { getTeams } = useTeam();

  const {
    data: teams,
    mutate: getTeamsList,
    loading: isTeamLoading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting Teams"),
  });

  function updateFormData(update, index) {
    setFormData((prev) => {
      const existingFormData = prev[index] || {};

      return {
        ...prev,
        [index]: {
          id: "",
          ...existingFormData,
          ...update,
        },
      };
    });
  }

  const members = currUser?.currCommunity?.members || [];

  const membersOptions = members.map((m) => ({
    label: m.username,
    value: m.email,
  }));

  const columns = getColumns({
    membersOptions,
    formData,
    setFormData,
    updateFormData,
    teams,
    isTeamLoading,
  });

  const {
    data,
    mutate: getTicketFieldsMutate,
    loading: isLoading,
  } = useApiData({
    onSuccess: (res) => {
      const fieldsData = res?.data?.data || [];

      if (fieldsData?.length) {
        getAutoAssignData();
      }
    },
  });

  const fieldsData = useMemo(() => {
    return data?.data || [];
  }, [data]);

  const {
    data: autoAssignData,
    mutate: getAutoAssign,
    loading: isLoadingAutoAssignData,
  } = useApiData({
    onSuccess: (res) => {},
  });

  const getAutoAssignData = () => {
    getAutoAssign(() =>
      api.get(`api/getAutoAssignSettings/${currCommunityId}`)
    );
  };

  const { mutate: deleteAutoAssign, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      setShowToast(true);
      setToastMessage({
        type: "success",
        message: "Auto Assign Setting Deleted Successfully",
      });
      setDeleteId(null);
      getAutoAssignData();
    },
    onError: (error) => {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something Went Wrong",
      });
      setDeleteId(null);
    },
  });

  const { mutate: createUpdateAutoAssign, loading: isCreateUpdateLoading } =
    useApiData({
      onSuccess: (res) => {
        const error = res?.data?.errMessage?.alreadyExists;

        if (error) {
          setShowToast(true);
          setToastMessage({
            type: "error",
            message: error,
          });
          return;
        }

        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Auto Assign setting updated Successfully",
        });
        getAutoAssignData();
      },
      onError: (error) => {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      },
    });

  const getTicketFieldsData = () => {
    getTicketFieldsMutate(() =>
      api.get(`api/getTicketCustomFields/${currCommunityId}`)
    );
  };

  const deleteAutoAssignApi = () => {
    const { id, index } = deleteId || {};

    if (id) {
      deleteAutoAssign(() =>
        api.delete(`api/deleteAutoAssignSettings/${currCommunityId}/${id}`)
      );
    } else {
      setFormData((prev) => {
        const prevData = { ...prev };

        delete prevData[index];

        const refactor = {};

        Object.values(prevData).forEach((p, i) => {
          refactor[i] = p;
        });

        return refactor;
      });

      setDeleteId(null);
    }
  };

  function createUpdateApi() {
    createUpdateAutoAssign(() =>
      api.post(`api/createUpdateAutoAssign/${currCommunityId}`, {
        auto_assign_list: Object.values(formData),
      })
    );
  }

  useEffect(() => {
    getTicketFieldsData();
    getTeamsList(getTeams());
  }, []);

  useEffect(() => {
    const autoAssign = autoAssignData?.data || [];

    const refactor = {};
    if (autoAssign?.length) {
      autoAssign.forEach((a, i) => {
        const payload = {
          ...a,
          team_id: a?.team?.team_id,
        };

        delete payload.team;

        refactor[i] = payload;
      });
    } else {
      fieldsData?.forEach((field, i) => {
        const add = {
          custom_field_id: field.id,
          status: 0,
        };
        if (field.field_type === "input") {
          add.auto_assign_value = "";
        }
        refactor[i] = add;
      });
    }
    setFormData(refactor);
  }, [autoAssignData, fieldsData]);

  if (!fieldsData?.length) {
    return;
  }

  if (isLoading || isLoadingAutoAssignData) {
    return <Spinner />;
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createUpdateApi();
        }}
      >
        <table class="table-auto w-full text-sm text-left text-gray-500 overflow-x-scroll">
          <thead className="text-gray-700 uppercase bg-gray-200 issue-list-title">
            <tr>
              {columns.map((column, i) => {
                return (
                  <th
                    className="py-3 cursor-pointer px-4 whitespace-nowrap"
                    key={i}
                  >
                    {column.name}
                  </th>
                );
              })}

              <th className="py-3 cursor-pointer px-4 whitespace-nowrap text-center">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(formData).map((autoAssign, i) => {
              const field =
                fieldsData.find((f) => f.id === autoAssign.custom_field_id) ||
                {};

              if (!autoAssign) {
                return null;
              }

              return (
                <tr className="border-b" key={i}>
                  {columns.map((column, j) => {
                    const Comp = column.render({
                      autoAssign,
                      field,
                      autoAssignIndex: i,
                    });

                    return (
                      <td className="py-4 px-4" key={j}>
                        {Comp}
                      </td>
                    );
                  })}

                  <td
                    className="py-4 px-4 text-center bg-red-50 hover:bg-red-100 cursor-pointer"
                    onClick={() =>
                      setDeleteId({
                        id: autoAssign?.id,
                        index: i,
                      })
                    }
                  >
                    {autoAssign.id && autoAssign.id === deleteId ? (
                      <Spinner
                        customStyle={{ height: 24, width: 24, margin: "auto" }}
                      />
                    ) : (
                      <i className="fa-regular fa-trash-can text-xl text-red-600"></i>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <AddAutoAssign
          options={getFieldsOptions(fieldsData)}
          onAddAutoAssign={(selectedField) => {
            if (selectedField?.value) {
              const newAutoAssignIndex = Object.keys(formData)?.length;

              const add = {
                custom_field_id: selectedField?.value,
                status: 0,
              };

              if (selectedField.field_type === "input") {
                add.auto_assign_value = "";
              }

              updateFormData(add, newAutoAssignIndex);
            }
          }}
        />

        <SubmitBtn loading={isCreateUpdateLoading} onCancel={closeModal} />
      </form>

      <DeleteModal
        title="Are you sure, you want to delete this auto assign setting?"
        open={deleteId}
        onClose={() => setDeleteId(null)}
        onDelete={() => deleteAutoAssignApi()}
        loading={isDeleteLoading}
      />
    </div>
  );
}

export default AutoAssignForm;

function ToggleBtn({ props, data }) {
  const { formData, updateFormData } = props;

  const { autoAssign = {}, autoAssignIndex } = data;

  const { custom_field_id } = autoAssign;

  const existingFormData = formData[autoAssignIndex] || {};

  const { status } = existingFormData;

  return (
    <button
      onClick={() =>
        updateFormData(
          { status: status ? 0 : 1, custom_field_id },
          autoAssignIndex
        )
      }
      type="button"
    >
      {status ? (
        <i className="fa-solid fa-toggle-on text-green-500 text-[1.8rem]"></i>
      ) : (
        <i className="fa-solid fa-toggle-off text-[1.8rem]"></i>
      )}
    </button>
  );
}

function Input({ props, data }) {
  const { disabled, value, onChange = () => {}, required } = props || {};

  return (
    <input
      type="text"
      className={`input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 ${
        disabled ? "cursor-not-allowed bg-gray-200" : ""
      }`}
      disabled={disabled}
      value={value}
      onChange={onChange}
      required={required}
    />
  );
}

function FieldValues({ props, data }) {
  const { autoAssign = {}, autoAssignIndex, field = {} } = data;

  const { formData, updateFormData } = props;

  const { custom_field_id } = autoAssign;

  const existingFormData = formData[autoAssignIndex] || {};

  const { field_type, field_values, id } = field;

  if (field_type === "input") {
    return <Input props={{ disabled: true, value: field_values }} />;
  }

  if (field_type === "select") {
    const options = field_values?.map((f) => ({ label: f, value: f }));

    return (
      <Select
        options={options}
        value={existingFormData?.auto_assign_value?.map((v) => ({
          label: v,
          value: v,
        }))}
        isMulti
        onChange={(values) =>
          updateFormData(
            {
              auto_assign_value: values.map((v) => v.value),
              custom_field_id,
            },
            autoAssignIndex
          )
        }
        styles={customStyles}
        required={true}
      />
    );
  }

  if (field_type === "dependency_dropdown") {
    return (
      <DependencyDropdown
        field={field}
        selectedValues={existingFormData?.auto_assign_value}
        handleChange={(val) =>
          updateFormData(
            {
              auto_assign_value: val,
              custom_field_id,
            },
            autoAssignIndex
          )
        }
        fromApprovalPage={true}
      />
    );
  }

  return "Unknown Type";
}

function FieldNames({ props, data }) {
  const { autoAssignIndex, field = {} } = data;

  const { field_type, field_name } = field;

  const { formData } = props;

  const existingFormData = formData[autoAssignIndex] || {};

  if (field_type === "dependency_dropdown" && field_name?.level1) {
    const { level1, level2, level3 } = field_name;

    const { auto_assign_value = {} } = existingFormData;

    return (
      <div className="">
        {" "}
        <div className="my-2 border-l border-gray-400 pl-1">
          {level1 && <div>{level1}</div>}

          {auto_assign_value?.level1 && level2 && (
            <div className="my-1 flex items-center">
              <div className="text-gray-400 whitespace-nowrap pr-1">--</div>{" "}
              {level2}
            </div>
          )}

          {auto_assign_value?.level2 && level3 && (
            <div className="flex items-center">
              <div className="text-gray-400 whitespace-nowrap pr-1">----</div>{" "}
              {level3}
            </div>
          )}
        </div>
      </div>
    );
  }

  return <div>{field_name}</div>;
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 999 }),
  multiValue: (base) => ({
    ...base,
    zIndex: 100,
  }),
  control: (provided, state) => ({
    ...provided,
    width: "200px",
  }),
};

function SelectTeam({ props, data }) {
  const { teams = [], isTeamLoading, updateFormData } = props;

  const { autoAssign = {}, autoAssignIndex } = data;

  const { custom_field_id } = autoAssign;

  const existingFormData = props.formData[autoAssignIndex] || {};

  const { team_id } = existingFormData;

  if (isTeamLoading) {
    return <Loading />;
  }

  const teamsOptions = teams?.map((t) => ({ label: t.name, value: t.team_id }));

  const selectedTeam = teamsOptions?.find((t) => t.value === team_id);

  return (
    <div className="pr-6">
      <Select
        options={teamsOptions}
        onChange={(val) =>
          updateFormData(
            { custom_field_id, team_id: val?.value },
            autoAssignIndex
          )
        }
        menuPortalTarget={document.querySelector("body")}
        styles={customStyles}
        value={selectedTeam || {}}
        required
      />
    </div>
  );
}

function AddAutoAssign({ options, onAddAutoAssign }) {
  const [openSelectField, setOpenSelectField] = useState(false);
  const [selectedField, setSelectedField] = useState();

  return (
    <div className="mt-4">
      {openSelectField ? (
        <div className="flex items-center space-x-2">
          <Select
            options={options}
            onChange={(val) => setSelectedField(val)}
            menuPortalTarget={document.querySelector("body")}
            styles={customStyles}
          />

          <button
            className="cursor-pointer bg-indigo-600 py-2 px-4 text-white rounded-md"
            onClick={() => {
              onAddAutoAssign(selectedField);
              setOpenSelectField(false);
            }}
            type="button"
          >
            + Add
          </button>

          <button
            className="cursor-pointer text-indigo-600 py-2 border-2 px-4 border-blue-300 rounded-md"
            onClick={() => setOpenSelectField(false)}
            type="button"
          >
            Cancel
          </button>
        </div>
      ) : (
        <button
          className="cursor-pointer text-indigo-600 py-2 border-2 px-4 border-blue-300 rounded-md"
          onClick={() => setOpenSelectField(true)}
          type="button"
        >
          + Add Auto Assign
        </button>
      )}
    </div>
  );
}

function SubmitBtn({ loading, onCancel }) {
  return (
    <div className="flex items-center justify-center space-x-4 mt-6">
      <button
        className="cursor-pointer bg-indigo-600 py-2 px-4 text-white rounded-md flex items-center space-x-2"
        type="submit"
      >
        {loading && <Spinner customStyle={{ width: 20, height: 20 }} />}
        Submit
      </button>

      <button
        className="cursor-pointer text-red-600 py-2 border-2 px-4 border-red-300 rounded-md"
        onClick={onCancel}
        type="button"
      >
        Cancel
      </button>
    </div>
  );
}
