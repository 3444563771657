import useDropDown from "../../../../../hooks/useDropDown";
import ToolTip from "../../../../common/ToolTip";

const addOptions = [
  {
    name: "Message",
    value: "MESSAGE",
    icon: "fa-solid fa-message border-r",
  },
  {
    name: "Actions",
    value: "ACTION",
    icon: "fa-solid fa-bolt",
  },
];

function AddOptions({ open, setOpen, onOptionsChange }) {
  const { elementRef } = useDropDown({ open, setOpen });

  return (
    <div
      className="flex justify-center items-center bg-white shadow-lg text-gray-700 rounded-lg border w-fit mx-auto"
      ref={elementRef}
    >
      {addOptions.map((o, index) => {
        const { name, icon, value } = o;
        return (
          <ToolTip tip={name} key={index}>
            <button
              onClick={() => {
                onOptionsChange(value);
                setOpen(false);
              }}
            >
              <i
                className={
                  icon +
                  " text-gray-600 hover:text-primary-600 hover:bg-gray-200 cursor-pointer transition duration-200 ease-in-out p-2"
                }
              ></i>
            </button>
          </ToolTip>
        );
      })}
    </div>
  );
}

export default AddOptions;
