import { useContext, useEffect, useState } from "react";
import MailAttachment from "./MailAttachment";
import TimeDisplay from "../common/TimeDisplay";
import ComposeEmail from "./ComposeMail";
import { AppContext } from "../../App";
import api from "../../api/api";

const MailThread = (props) => {
  const [openThread, setOpenThread] = useState(false);
  const [composeModal, setComposeModal] = useState(false);
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  useEffect(() => {
    if (props.email.messageId == props.messageId) {
      setOpenThread(true);
    }
  }, []);

  const createTicket = (uid) => {
    var postData = {
      community_id: currCommunityId,
      tickets: [uid],
      folder: props.activeFolder ? props.activeFolder : "INBOX",
    };
    props.handleLoading(true);
    api
      .post("/api/createMailTicket", postData)
      .then((response) => {
        if (response.data.status == "success") {
          setShowToast(true);
          setToastMessage({
            type: "success",
            message: "Ticket Created Successfully",
          });
          props.handleLoading(false);
          props.fetchMail("refresh");
        }
      })
      .catch((error) => {
        props.handleLoading(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "something went wrong",
        });
      });
  };
  return (
    <div className=" py-3 border-y mail-thread">
      <div
        className={`${
          openThread ? "mb-8" : "text-gray-600"
        } mail-thread__header`}
      >
        <div className=" flex justify-between items-center	 mail-thread__header__title">
          <div
            className="flex items-center gap-2 cursor-pointer w-[70%]"
            onClick={() => setOpenThread(!openThread)}
          >
            <div className="userImage">
              <i className="fa-solid fa-user bg-gray-100 p-2 rounded-full mr-2 "></i>
            </div>
            <h2 className="text-md font-medium">{props.email.name}</h2>
          </div>
          <div className="w-[30%] flex gap-2 text-sm items-center">
            <p>
              <TimeDisplay timestamp={props.email.date} />
            </p>
            <i
              className="cursor-pointer fa-solid text-[12px] fa-reply rounded-full p-[4px] hover:bg-gray-500 hover:text-white duration-300 ease"
              onClick={() => setComposeModal(true)}
            ></i>
            {!props.email.ticketCreated && (
              <td
                className="cursor-pointer light-blue-hover rounded px-4 py-2 text-gray-700"
                onClick={(e) => {
                  e.preventDefault();
                  createTicket(props.email.uid);
                }}
              >
                <i className="fa-solid fa-clipboard-list"></i>
              </td>
            )}
            {props.email.ticketCreated && (
              <td className="text-2xl text-emerald-400">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 20 20"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </td>
            )}
          </div>
        </div>
      </div>
      {openThread && (
        <>
          {/* <div
            dangerouslySetInnerHTML={{
              __html: props.email?.body.html,
            }}
          ></div> */}
          <iframe
            srcDoc={props.email?.body.html}
            className="w-[100%] h-[500px]"
          ></iframe>

          {props.email?.attachments.map((attachment) => (
            <MailAttachment
              base64Image={attachment.base64}
              mimetype={attachment.mime}
              name={attachment.name}
              extension={attachment.extension}
            />
          ))}
        </>
      )}
      {composeModal && (
        <ComposeEmail
          handleComposeModal={setComposeModal}
          to={props.email.from}
          cc={props.email.cc}
          messageId={props.email.messageId}
          referenceId={props.email.reference_id}
          subject={props.subject}
        />
      )}
    </div>
  );
};

export default MailThread;
