import { useCallback, useContext, useMemo } from "react";
import api from "../../../../../api/api";
import { AppContext } from "../../../../../App";

function useApiConfiguration(botId, flowId) {
  const { currCommunityId } = useContext(AppContext);

  const basePath = useMemo(() => {
    return `/api/${currCommunityId}/${botId}/flow/${flowId}`;
  }, [botId, currCommunityId, flowId]);

  const getApiConfigurations = useCallback(
    (payload) => {
      const url = basePath + "/getApiConfigurations";

      return () => api.post(url, payload);
    },
    [basePath]
  );

  const getApiConfiguration = useCallback(
    (id) => {
      const url = basePath + "/getApiConfiguration/" + id;

      return () => api.get(url);
    },
    [basePath]
  );

  const checkApiConfiguration = useCallback(
    (payload) => {
      const url = basePath + "/checkApiConfiguration";

      return () => api.post(url, payload);
    },
    [basePath]
  );

  const createApiConfiguration = useCallback(
    (payload) => {
      const url = basePath + "/createApiConfiguration";

      return () => api.post(url, payload);
    },
    [basePath]
  );

  const updateApiConfiguration = useCallback(
    (payload, updateId) => {
      const url = basePath + "/updateApiConfiguration/" + updateId;

      return () => api.put(url, payload);
    },
    [basePath]
  );

  const deleteApiConfiguration = useCallback(
    (deleteId) => {
      const url = basePath + "/deleteApiConfiguration/" + deleteId;

      return () => api.delete(url);
    },
    [basePath]
  );

  return {
    getApiConfigurations,
    getApiConfiguration,
    createApiConfiguration,
    updateApiConfiguration,
    deleteApiConfiguration,
    checkApiConfiguration,
  };
}

export default useApiConfiguration;
