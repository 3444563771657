import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import { useBeforeUnload, useLocation, useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";

const UnsavedAlert = (props) => {
  const { hasChanged, setHasChanged } = useContext(AppContext);
  const history = createBrowserHistory();
  const prevLocationRef = useRef(null);

  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        props.onClose();
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  useEffect(() => {
    prevLocationRef.current = history.location.pathname;
  }, [history.location.pathname]);

  useEffect(() => {
    // console.log("hasChnaged", hasChanged);

    if (hasChanged) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        return (event.returnValue = "");
      };

      window.onbeforeunload = () => handleBeforeUnload;
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [hasChanged]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // const handleWindowClose = (event) => {
    //   event.preventDefault();
    //   return (event.returnValue = "");
    // };
    // window.onhashchange = () => {
    //   return "Are you sure you want to leave this page?  You will lose any unsaved data.";
    // };
    // return () => {
    //   window.removeEventListener("onhashchange", handleWindowClose);
    // };
  }, [hasChanged]);

  return (
    <></>
    // <div
    //   id="defaultModal"
    //   tabIndex={-1}
    //   className={` dialog-overlay fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
    // >
    //   <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
    //     <div
    //       className="relative dialog-content bg-white rounded-lg shadow"
    //       ref={btnRef}
    //     >
    //       <div className="text-center flex items-start justify-between p-4 rounded-t">
    //         <div>
    //           <h3 className="text-xl font-semibold text-gray-900">
    //             Unsaved Changes
    //           </h3>
    //           <p className="mt-5">
    //             It seems like there is an unsaved changes. Are you sure to
    //             continue?
    //           </p>
    //         </div>
    //         <button
    //           type="button"
    //           className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center   "
    //           data-modal-hide="defaultModal"
    //           onClick={() => props.onClose()}
    //         >
    //           <svg
    //             className="w-5 h-5"
    //             fill="currentColor"
    //             viewBox="0 0 20 20"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               fillRule="evenodd"
    //               d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
    //               clipRule="evenodd"
    //             />
    //           </svg>
    //           <span className="sr-only">Close modal</span>
    //         </button>
    //       </div>
    //       <div>
    //         <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
    //           <button
    //             className="inline-flex items-center justify-center button bg-red-600 hover:bg-red-500 active:bg-red-700 text-white radius-round h-11 px-8 py-2 w-full"
    //             type="submit"
    //             onClick={() => props.onClose()}
    //           >
    //             Continue
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default UnsavedAlert;
