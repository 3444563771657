import React, { useEffect, useRef, useState } from "react";
import {
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFile,
} from "react-icons/fa";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import CSVReader from "../common/CSVReader";
import moment from "moment";

const handleDownload = (file) => {
  const { link, fileName } = file;

  const downloadLink = document.createElement("a");

  downloadLink.href = link;
  downloadLink.download = fileName || "true";
  downloadLink.target = "_blank";

  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);
};

const RenderAttachment = {
  image: RenderImage,
  pdf: () => <FaFilePdf color="red" className="m-auto mt-1" size={60} />,
  word: () => <FaFileWord color="blue" className="m-auto mt-1" size={60} />,
  excel: () => <FaFileExcel color="green" className="m-auto mt-1" size={60} />,
};

const FileCard = (props) => {
  const [openModal, setOpenModal] = useState();
  const [type, setType] = useState("");
  const [showActions, setShowActions] = useState(false);
  const iframeRef = useRef();

  const { handleRemoveAttachment, file, isViewPage } = props;

  const { created_at, filename, fileName, link, fileExtension } = file || {};

  const isSelectingPage = !link;

  // const docs = [{ uri: props.file.link, fileType: type }];
  // const renderIcon = () => {
  //   const fileType = getFileTypeFromUrl(props.file.link);
  //   if (fileType === "image") {
  //     return <FaFileImage />;
  //   } else if (fileType === "pdf") {
  //     return <FaFilePdf />;
  //   } else if (fileType === "word") {
  //     return <FaFileWord />;
  //   } else if (fileType === "excel") {
  //     return <FaFileExcel />;
  //   } else {
  //     return <FaFile />;
  //   }
  // };
  // useEffect(() => {
  //   if (props.distributorRemove != undefined) {
  //     props.handleDistributorRemove(!props.distributorRemove);
  //   }
  // }, [openModal]);

  useEffect(() => {
    const fileType = isSelectingPage
      ? fileExtension
      : link.split(/[#?]/)[0].split(".").pop().trim();

    setType(fileType);
    // console.log(props.file.link.split(/[#?]/)[0].split(".").pop().trim());
  }, [props]);

  const onError = (e) => {
    // logger.logError(e, "error in file-viewer");
  };

  const getFileTypeFromUrl = (url) => {
    const extension = isSelectingPage
      ? url
      : url.split(".").pop().toLowerCase();
    // Map file extensions to file types
    const fileTypeMap = {
      jpg: "image",
      jpeg: "image",
      png: "image",
      gif: "image",
      pdf: "pdf",
      doc: "word",
      docx: "word",
      xls: "excel",
      xlsx: "excel",
    };

    if (extension in fileTypeMap) {
      return fileTypeMap[extension];
    }

    return "file";
  };

  const Comp =
    RenderAttachment[getFileTypeFromUrl(link || fileExtension)] || RenderOthers;

  return (
    <div className="">
      <div
        className="relative w-[156px] h-[110px] rounded-md border border-gray-300 overflow-hidden"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <Comp attachmentIndex={props.index + 1} file={props?.file} />

        <div className="p-1 bg-white absolute bottom-0 left-0 w-full z-10 text-[11px]">
          <div className="font-semibold truncate">{filename || fileName}</div>
          <div className="truncate">
            {created_at
              ? moment(created_at).format("DD MMM YYYY, h:mm A")
              : null}
          </div>
        </div>

        {showActions ? (
          <Actions
            onDownload={() => handleDownload(file)}
            onView={() => setOpenModal(!openModal)}
            onDelete={handleRemoveAttachment}
            isViewPage={isViewPage}
            isSelectingPage={isSelectingPage}
          />
        ) : null}
      </div>

      {openModal && (
        <div
          id="defaultModal"
          tabIndex={-1}
          className={` dialog-overlay attachment-popup fixed dialog-container top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
        >
          <div className="mx-auto relative w-full h-full max-w-[60rem]	 md:h-auto">
            <div className="relative dialog-content bg-white rounded-lg shadow">
              <div className="text-center flex items-start justify-between p-4 rounded-t">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900  ">
                    Document
                  </h3>
                </div>
                <div className="flex items-center">
                  <a
                    href={props?.file?.link}
                    download
                    className="py-1 px-2 font-semibold bg-main text-white rounded-sm text-sm"
                    target="__blank"
                  >
                    <i class="fa-solid fa-download mr-2"></i>
                    Download
                  </a>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
                    data-modal-hide="defaultModal"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
              </div>
              <div style={{ height: "100%" }}>
                {/* <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={docs}
                /> */}
                {["doc", "docx", "xls", "xlsx"].includes(type) ? (
                  <embed
                    className="w-[100%]"
                    style={{ height: "450px" }}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${props?.file?.link}`}
                  ></embed>
                ) : ["jpg", "jpeg", "png", "gif"].includes(type) ? (
                  <img
                    className="w-[100%]"
                    style={{ height: "auto" }}
                    src={`${props?.file?.link}`}
                  ></img>
                ) : ["pdf"].includes(type) ? (
                  <embed
                    type="application/pdf"
                    className="w-[100%]"
                    style={{ height: "450px" }}
                    src={`${props?.file?.link}`}
                  ></embed>
                ) : (
                  <embed
                    type={`application/${type}`}
                    className="w-[100%]"
                    style={{ height: "450px" }}
                    src={`${props?.file?.link}`}
                  ></embed>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileCard;

function RenderImage({ file }) {
  const { link, mimeType, base64Data } = file || {};

  const dataURL = link || `data:${mimeType};base64,${base64Data}`;

  return (
    <img
      src={dataURL}
      alt="attachment"
      className="object-contain w-full h-full"
    />
  );
}

function RenderOthers() {
  return (
    <div>
      <FaFile color="gray" className="m-auto mt-1" size={60} />
    </div>
  );
}

function getActionBtns(actionsBtns, isViewPage, isSelectingPage) {
  if (isViewPage) {
    return actionsBtns.filter((b) => b.alt !== "delete");
  } else if (isSelectingPage) {
    return actionsBtns.filter((b) => b.alt === "delete");
  } else {
    return actionsBtns;
  }
}

function Actions({
  onDownload,
  onView,
  onDelete,
  isViewPage,
  isSelectingPage,
}) {
  const actionsBtns = [
    {
      src: "/images/icons/attachment/eye.svg",
      alt: "View",
      onClick: onView,
    },
    {
      src: "/images/icons/attachment/download.svg",
      alt: "download",
      onClick: onDownload,
    },
    {
      src: "/images/icons/attachment/delete.svg",
      alt: "delete",
      onClick: onDelete,
    },
  ];

  return (
    <div className="bg-black bg-opacity-60 absolute top-0 left-0 z-20 w-full h-full rounded-md flex justify-center items-center p-4 space-x-4">
      {getActionBtns(actionsBtns, isViewPage, isSelectingPage).map((btn, i) => {
        const { src, alt, onClick } = btn;
        return (
          <img
            src={src}
            alt={alt}
            onClick={onClick}
            key={i}
            className="cursor-pointer"
          />
        );
      })}
    </div>
  );
}
