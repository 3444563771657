function formatDate(endDate) {
  const dateObj = new Date(endDate);
  const options = { year: "numeric", month: "short", day: "numeric" };

  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  return formattedDate;
}

const CurrentPlan = ({ data }) => {
  const { planName, uuid, planPrice, status, end_date } = data || {};
  return (
    <div>
      <div className="bg-white px-6 py-4 border border-gray-300 rounded-md">
        <div className="flex items-center justify-between w-full block">
          <h3 className="mb-4 text-md lg:mb-0 font-semibold">Current Plan</h3>
        </div>
        <div className="mt-3 flex gap-4">
          <div className="plan-card w-full border p-5 rounded-md">
            <div className="flex items-center justify-between">
              <h3 className="mb-4 text-sm lg:mb-0 font-medium text-gray-500">
                Monthly Plan
              </h3>
              <div className="status flex gap-3">
                <span className="capitalize py-1 px-3 block bg-secondary-600 text-[#ffffff] rounded-md">
                  {planName}
                </span>
                {status === 1 ? (
                  <span className="py-1 px-3 block bg-green-100 text-green-500 rounded-md">
                    Active
                  </span>
                ) : (
                  <span className="py-1 px-3 block bg-red-100 text-red-500 rounded-md">
                    Inactive
                  </span>
                )}
              </div>
            </div>
            <h3 className="mb-4 text-md lg:mb-0 font-semibold">
              ${planPrice} <span>/ Monthly</span>
            </h3>
          </div>
          <div className="expiry-card w-full border p-5 rounded-md">
            <div className="flex items-center justify-between">
              <h3 className="mb-4 text-sm lg:mb-0 font-medium text-gray-500">
                Renew at
              </h3>
            </div>
            <h3 className="mb-4 text-md lg:mb-0 font-semibold">
              {formatDate(end_date)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;
