import Button from "../../../../common/Button";
import Spinner from "../../../../loaders/Spinner";

function ChildFlowsOptions({
  childFlows,
  onChildFlowSelect,
  loading,
  ...props
}) {
  let opts = childFlows?.length
    ? childFlows
    : [{ label: "No Child Flows", value: "" }];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="text-gray-400 font-semibold py-2 text-center">
        Child Flows
      </div>

      {opts.map((flow, i) => {
        return (
          <Button
            key={i}
            className="py-1.5 px-4 hover:bg-gray-100 w-full text-left border-b"
            onClick={() => {
              onChildFlowSelect(flow);
              props.setOpen(false);
            }}
            //*** insert-data id is used for finding the element is clicked inside or outside dialog
            id="insert-data"
          >
            {flow?.flowName}
          </Button>
        );
      })}
    </div>
  );
}

export default ChildFlowsOptions;
