import BotflowLayout from "../../components/chatBot/botflow/BotflowLayout";
import FormApiConfiguration from "../../components/chatBot/botflow/configuration/apiConfiguration/FormApiConfiguration";

function ApiConfigurationFormContainer() {
  return (
    <BotflowLayout tab="configuration">
      <div className="p-8">
        <FormApiConfiguration />
      </div>
    </BotflowLayout>
  );
}

export default ApiConfigurationFormContainer;
