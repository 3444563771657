import { useLocation, useNavigate, useParams } from "react-router-dom";
import ListConfigurationTypes from "./ListConfigurationTypes";
import ListChildFlows from "./flowPages/ListChildFlows";
import BackBtn from "../../../common/BackBtn";
import { useEffect } from "react";
import {
  embedBotflowPreview,
  removeBotflowPreview,
} from "../../../common/commonHelpers";

const activeCls = "bg-indigo-600 text-white py-2 w-1/2 h-10";

const inActiveCls =
  "border border-primary-600 hover:bg-blue-50 text-primary-600 py-2 w-1/2 h-10";

function isActive(tab, activeTab) {
  if (tab === activeTab) {
    return activeCls;
  }

  return inActiveCls;
}

function BotFlowSideBar({ activeTab }) {
  const navigate = useNavigate();

  const { search } = useLocation();

  const { id, botflowId } = useParams();

  const botflowPath = `/train-chat-bot/${id}/botflow/${botflowId}`;

  // useEffect(() => {
  //   sessionStorage.setItem("previewParentFlowId", botflowId);

  //   const script = document.createElement("script");

  //   const embedFilePath =
  //     "http://localhost:5173/chatgpt/trainyourdata/chatbot/embed.js";

  //   script.src = `${embedFilePath}?id=${id}&parentFlowId=${botflowId}`;

  //   script.id = "botflow-preview";

  //   document.body.appendChild(script);

  //   return () => {
  //     if (window.removeIframe) {
  //       window.removeIframe();
  //     }

  //     sessionStorage.removeItem("previewParentFlowId");

  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    embedBotflowPreview(id, botflowId);

    return () => {
      removeBotflowPreview();
    };
  }, []);

  return (
    <div className="w-[30%] truncate border-r">
      <BackBtn onClick={() => navigate(`/train-chat-bot/${id}?tab=4`)} />

      <div className="flex">
        <button
          className={isActive("bot", activeTab)}
          onClick={() =>
            navigate({
              pathname: botflowPath,
            })
          }
        >
          Build
        </button>

        <button
          className={isActive("configuration", activeTab)}
          onClick={() =>
            navigate({
              pathname: botflowPath + "/configuration/api",
            })
          }
        >
          Configure
        </button>
      </div>

      {activeTab === "bot" ? <ListChildFlows /> : null}

      {activeTab === "configuration" ? (
        <ListConfigurationTypes
          onPageChange={(pageType) => {
            navigate({
              pathname: botflowPath + "/configuration/" + pageType,
            });
          }}
        />
      ) : null}
    </div>
  );
}

export default BotFlowSideBar;
