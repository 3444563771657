import { useCallback, useContext } from "react";
import api from "../../../api/api";
import { AppContext } from "../../../App";

function useMailTemplate() {
  const { currCommunityId } = useContext(AppContext);

  const getPath = useCallback(
    (path) => {
      return `/api/v1/${path}/communityId/${currCommunityId}`;
    },
    [currCommunityId]
  );

  const getTemplates = useCallback(
    (payload) => {
      return () => api.post(getPath("getTemplates"), payload);
    },
    [getPath]
  );

  const createTemplate = useCallback(
    (payload) => {
      return () => api.post(getPath("Template"), payload);
    },
    [getPath]
  );

  const updateTemplate = useCallback(
    (payload) => {
      return () => api.post(getPath("updateTemplate"), payload);
    },
    [getPath]
  );

  const deleteTemplate = useCallback(
    (payload) => {
      return () => api.post(getPath("deleteTemplate"), payload);
    },
    [getPath]
  );

  const sendMailTemplate = useCallback(
    (payload) => {
      return () => api.post(getPath("sendemail"), payload);
    },
    [getPath]
  );

  const getRecipients = useCallback((payload) => {
    return () =>
      api.post(`/api/getRecipients/communityId/${currCommunityId}`, payload);
  }, []);

  const sendMailForOfflineUser = useCallback(
    (payload) => {
      return () => api.post(getPath("offlineemail"), payload);
    },
    [getPath]
  );

  return {
    createTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplates,
    sendMailTemplate,
    getRecipients,
    sendMailForOfflineUser,
  };
}

export default useMailTemplate;
