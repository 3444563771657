import { useParams } from "react-router-dom";
import useChildFlow from "../../sidebar/flowPages/useChildFlow";
import useApiData from "../../../../../hooks/useApiData";
import { useEffect, useState } from "react";
import Spinner from "../../../../loaders/Spinner";
import { sortByDisplayOrder } from "../../../../common/commonHelpers";
import { DisplayOptions } from "../common/DefaultInsertOptions";
import useBuildBotFlow from "../useBuildBotFlow";

function refactorDialogsData(data) {
  const dialogs = data || [];

  return dialogs.map((d) => ({
    label: `DIALOGUE ${d.displayOrder}`,
    value: `DIALOGUE ${d.displayOrder}`,
    data: d,
  }));
}

function InsertDialogOptions(props) {
  const { id, botflowId } = useParams();
  const [showDialogs, setShowDialogs] = useState(false);
  const [flowName, setFlowName] = useState("");

  const { getChildFlows } = useChildFlow(id, botflowId);

  const { getDialogs } = useBuildBotFlow(id, botflowId);

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => {},
  });

  const {
    data: dialogsData,
    mutate: getDialogsList,
    loading: isGettingDialogs,
  } = useApiData({
    onSuccess: () => {
      setShowDialogs(true);
    },
    onError: () => {},
  });

  useEffect(() => {
    getList(getChildFlows());
  }, []);

  const flows = sortByDisplayOrder(data?.data, "displayorder") || [];

  const refactorFlows = flows.map((flow) => {
    const { uuid, flowName, displayorder } = flow;

    return {
      label: `${displayorder}. ${flowName}`,
      value: uuid,
      flowName,
    };
  });

  console.log("refactorFlows=>", dialogsData);

  const dialogs = refactorDialogsData(dialogsData?.data);

  //   if (loading || isGettingDialogs) {
  //     return (
  //       <div className="w-fit mx-auto mt-2">
  //         <Spinner />
  //       </div>
  //     );
  //   }

  return (
    <div>
      {loading || isGettingDialogs ? <Spinner /> : null}

      <DisplayOptions
        options={showDialogs ? dialogs : refactorFlows}
        onOptionsSelect={(value, page, option) => {
          if (showDialogs) {
            props.setOpen(false);

            if (value) {
              props.onInsert(`${flowName}: ${value}`, option, flowName);
            }
            return;
          }

          setFlowName(option?.flowName);

          getDialogsList(getDialogs(value));
        }}
      />

      {/* {showDialogs ? (
        <>
          <DisplayOptions
            options={dialogs}
            onOptionsSelect={(value, page, option) => {
              props.setOpen(false);
              props.onInsert(value, option);
            }}
          />
        </>
      ) : (
        <>
          <DisplayOptions
            options={refactorFlows}
            onOptionsSelect={(value, page, option) => {
              getDialogsList(getDialogs(value));
              setShowDialogs(true);
            }}
          />
        </>
      )} */}
    </div>
  );
}

export default InsertDialogOptions;
