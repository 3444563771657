import { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../App";
import api from "../../../api/api";
import SideBar from "../../../components/settings/SideBar";
import CommunityCard from "../../../components/settings/CommunityCard";

const Communities = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [gptCheck, setGptCheck] = useState();
  const location = useNavigate();

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            <div className="lg:flex items-center justify-between mb-4">
              <h3 className="mb-4 lg:mb-0 main-title">My Profile</h3>
            </div>
            <div className="flex gap-4 text-gray-700">
              <SideBar />
              <div className="w-[80%] grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {currUser?.user?.map((community) => (
                  <CommunityCard community={community} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Communities;
