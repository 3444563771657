import { useState } from "react";
import { Label } from "./FormApiConfiguration";

function AddHeadersInput({ accessTokenType, accessToken, updateFormData }) {
  const [addAuth, setAddAuth] = useState(accessTokenType ? true : false);

  return (
    <div className="py-4">
      <div className="flex item-center space-x-3">
        <input
          type="checkbox"
          className="mt-1"
          onChange={(e) => {
            const { checked } = e.target;
            setAddAuth(checked);

            if (!checked) {
              updateFormData({ accessTokenType: "", accessToken: "" });
            }
          }}
          checked={addAuth}
        />

        <Label
          label="Add Authorization"
          notRequired={true}
          onClick={() => {
            setAddAuth(!addAuth);

            if (!addAuth) {
              updateFormData({ accessTokenType: "", accessToken: "" });
            }
          }}
          customCls="cursor-pointer"
        />
      </div>

      {addAuth ? (
        <div className="pl-4 border-l">
          <div>
            <Label label="Access Token Type" />

            <input
              type="text"
              className="w-full p-2 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
              required
              placeholder="Bearer"
              value={accessTokenType}
              onChange={(e) =>
                updateFormData({ accessTokenType: e.target.value })
              }
            />
          </div>

          <div>
            <Label label="Access Token" />

            <input
              type="text"
              className="w-full p-2 border border-gray-300 text-sm rounded-md focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
              required
              placeholder="xxx"
              value={accessToken}
              onChange={(e) => updateFormData({ accessToken: e.target.value })}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default AddHeadersInput;
