import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Tooltip } from "react-tooltip";

function SelectDateRange({
  defaultDateRange,
  setDefaultDateRange,
  onApply,
  uniqueId,
}) {
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const [open, setOpen] = useState(false);

  const containerRef = useRef();
  const applyBtnRef = useRef();

  const handleOutsideClick = (event) => {
    const containerClicked =
      containerRef.current && containerRef.current.contains(event.target);

    const applyBtnClicked =
      applyBtnRef.current && applyBtnRef.current.contains(event.target);

    const isClickedInside = containerClicked || applyBtnClicked;

    if (open && !isClickedInside) {
      setOpen(false);
      setDateRange(defaultDateRange);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  if (!uniqueId) {
    return "Please give the uniqueId for the date range element to render the component";
  }

  return (
    <div className="relative" ref={containerRef}>
      <button
        className="text-sm flex items-center bg-white px-4 py-2.5 rounded-md border border-gray-300 hover:bg-gray-100"
        onClick={() => {
          setOpen(!open);

          if (open) {
            setDateRange(defaultDateRange);
          }
        }}
        id={uniqueId}
      >
        <img src="/images/icons/calendar.svg" alt="calendar" className="mr-2" />
        {new Date(dateRange[0].startDate).toLocaleDateString()} -{" "}
        {new Date(dateRange[0].endDate).toLocaleDateString()}
      </button>

      <Tooltip
        anchorSelect={"#" + uniqueId}
        place="top"
        style={{
          zIndex: 99,
          background: "#b8b6b6",
          color: "black",
          padding: "1px",
        }}
        clickable
        isOpen={open}
        setIsOpen={() => {
          // restricting the state changes in tooltip by giving empty function () => {}, we will update state manually
        }}
        opacity={1}
      >
        {open ? (
          <div
            className={`border border-gray-300 shadow-md bg-white flex flex-col`}
          >
            <DateRangePicker
              onChange={(item) => setDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              opens="center"
              direction="horizontal"
            />

            <div className="flex justify-end m-4">
              <button
                className="bg-indigo-600 px-4 py-2 rounded-md text-white w-fit"
                onClick={() => {
                  setDefaultDateRange(dateRange);
                  onApply(dateRange);
                  setOpen(false);
                }}
                type="button"
                ref={applyBtnRef}
              >
                Apply
              </button>

              {/* <button
                className="border border-gray-300 text-indigo-600 hover:bg-gray-100 px-4 py-2 rounded-md w-fit ml-4"
                onClick={() => {
                  setDateRange(defaultDateRange);
                  setOpen(false);
                }}
              >
                Cancel
              </button> */}
            </div>
          </div>
        ) : null}
      </Tooltip>
    </div>
  );
}

export default SelectDateRange;
