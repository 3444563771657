import { useEffect, useState } from "react";
import Loading from "../../../loaders/Loading";

function BotNameForm({ flowData = {}, loading, onSubmit = () => {} }) {
  const [flowName, setFlowName] = useState();

  useEffect(() => {
    setFlowName(flowData?.flowName);
  }, [flowData]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ flowName });
      }}
      className="mt-4"
    >
      <input
        className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 !normal-case"
        type="text"
        name="name"
        autoComplete="off"
        placeholder="Enter Bot Flow Name"
        required
        onChange={(e) => setFlowName(e.target.value)}
        value={flowName}
      />

      <button
        type="submit"
        className="mt-4  bg-indigo-600 px-4 py-1 rounded-md text-white flex items-center space-x-2"
      >
        {loading && <Loading />}
        Submit
      </button>
    </form>
  );
}

export default BotNameForm;
