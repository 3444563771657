import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../App";
import Modal from "../../../common/Modal";
import BotDetailsCard from "./BotDetailsCard";
import BotNameForm from "./BotNameForm";
import useBotFlowList from "./useBotFlowList";
import useApiData from "../../../../hooks/useApiData";
import Spinner from "../../../loaders/Spinner";
import DeleteModal from "../../../common/DeleteModal";
import TeamConfigurationForm from "./TeamConfigurationForm";
import useTeam from "../../../settings/team/useTeam";
import useSection from "./useSection";
import ToolTip from "../../../common/ToolTip";
import useChatBot from "./useChatBot";
import Loading from "../../../loaders/Loading";

function ListBotFlow({ folderDetails, getFolderDetails }) {
  const [openForm, setOpenForm] = useState({
    open: false,
    type: "",
    data: null,
  });

  const [openTeamConfigForm, setOpenTeamConfigForm] = useState({
    open: false,
    type: "",
    data: null,
  });

  const [sectionFormOpen, setSectionFormOpen] = useState({
    open: false,
    type: "",
    data: null,
  });

  const navigate = useNavigate();

  const { id } = useParams();

  const { getBotFlowsList, createBotFlow, updateBotFlow, deleteBotFlow } =
    useBotFlowList();

  const { getTeams } = useTeam();
  const { updateIsConnectWithAgent } = useChatBot();

  const { getSection, createSection, deleteSection } = useSection();

  const { setToastMessage, setShowToast, setNavToggle } =
    useContext(AppContext);

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  function onSuccess(msg) {
    setShowToast(true);
    setToastMessage({
      type: "success",
      message: msg,
    });
  }

  function clearForm() {
    setOpenForm({
      open: false,
      type: "",
      data: null,
    });
  }

  function clearTeamConfigForm() {
    setOpenTeamConfigForm({
      open: false,
      type: "",
      data: null,
    });
  }

  function clearSectionForm() {
    setSectionFormOpen({
      open: false,
      type: "",
      data: null,
    });
    setOpenTeamConfigForm({
      open: true,
      type: "Add",
      data: openTeamConfigForm.data,
    });
  }

  const {
    data,
    mutate: getList,
    loading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting bot flows"),
  });

  const {
    mutate: updateConnectWithAgent,
    loading: isUpdateConnectWithAgentLoading,
  } = useApiData({
    onSuccess: () => {
      getFolderDetails();
    },
    onError: () => onError("Something went wrong while Updating"),
  });

  const { mutate: create, loading: isCreateLoading } = useApiData({
    onSuccess: (res) => {
      console.log("res", res, res.data);
      onSuccess("Bot Flow Created Successfully");
      const flowId = res?.data?.data;
      navigate(`./botflow/${flowId}`);
    },
    onError: (err) => {
      const msgData = err?.response?.data?.error || [];
      onError(
        msgData ? msgData : "Something went wrong while creating bot flow"
      );
    },
  });

  const { mutate: update, loading: isUpdateLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Bot Flow Updated Successfully");
      clearForm();
      getList(getBotFlowsList(id));
    },
    onError: () => onError("Something went wrong while updating bot flow"),
  });

  const { mutate: deleteApi, loading: isDeleteLoading } = useApiData({
    onSuccess: (res) => {
      onSuccess("Bot Flow Deleted Successfully");
      clearForm();
      getList(getBotFlowsList(id));
    },
    onError: () => onError("Something went wrong while deleting bot flow"),
  });

  const {
    data: teams,
    mutate: getTeamsList,
    loading: isTeamLoading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting Teams"),
  });

  const {
    data: section,
    mutate: getSectionList,
    loading: isSectionLoading,
  } = useApiData({
    onSuccess: () => {},
    onError: () => onError("Something went wrong while getting section"),
  });

  const { mutate: createSectionData, loading: isCreateSectionLoading } =
    useApiData({
      onSuccess: () => {
        setSectionFormOpen({
          open: false,
          type: "",
          data: null,
        });
        getSectionList(
          getSection({
            bot_id: openTeamConfigForm?.data?.uuid,
          })
        );
        onSuccess("Team Added Successfully..!");
      },
      onError: (err) => {
        const { error } = err.response.data;
        onError(error ? error : "Something went wrong while Create section");
      },
    });

  const { mutate: deleteSectionData, loading: isDeleteSectionLoading } =
    useApiData({
      onSuccess: () => {
        onSuccess("Team Deleted Successfully..!");
        setOpenTeamConfigForm({
          open: true,
          type: "Add",
          data: openTeamConfigForm.data,
        });
      },
      onError: () => onError("Something went wrong while Delete section"),
    });

  const botflows = data?.data || [];

  useEffect(() => {
    getList(getBotFlowsList(id));
    getTeamsList(getTeams());
  }, []);

  useEffect(() => {
    if (sectionFormOpen?.type == "Delete") {
      setOpenTeamConfigForm({
        open: false,
        type: "Add",
        data: openTeamConfigForm.data,
      });
    }
  }, [sectionFormOpen]);

  if (loading) {
    return (
      <div className="w-fit mx-auto mt-8">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center p-8 px-4">
        <div className="font-semibold text-gray-700 text-lg">My Bots</div>
        <div className="flex items-center gap-3">
          {/* <div className="flex items-center gap-2">
            <p>To enable connect with agent in root</p>
            {isUpdateConnectWithAgentLoading ? (
              <Loading />
            ) : (
              <ToolTip
                tip="To enable connect with agent in root"
                tipCls="!w-40"
              >
                {folderDetails?.data?.Is_connect_with_agent == 1 ? (
                  <i
                    class="fa-solid cursor-pointer text-2xl fa-toggle-on text-green-500"
                    onClick={() => {
                      updateConnectWithAgent(
                        updateIsConnectWithAgent({
                          uuid: id,
                          status: "0",
                        })
                      );
                    }}
                  ></i>
                ) : (
                  <i
                    class="fa-solid cursor-pointer text-2xl fa-toggle-off "
                    onClick={() => {
                      updateConnectWithAgent(
                        updateIsConnectWithAgent({
                          uuid: id,
                          status: "1",
                        })
                      );
                    }}
                  ></i>
                )}
              </ToolTip>
            )}
          </div> */}
          <button
            className="bg-indigo-600 px-4 py-1 rounded-md text-white"
            onClick={() =>
              setOpenForm({
                open: true,
                type: "Create",
                data: null,
              })
            }
          >
            New Bot
          </button>
        </div>
      </div>

      {botflows?.length ? (
        <div className="flex flex-wrap gap-4 w-full px-4">
          {botflows.map((flow, i) => (
            <BotDetailsCard
              key={i}
              flow={flow}
              onEdit={() =>
                setOpenForm({
                  open: true,
                  type: "Edit",
                  data: flow,
                })
              }
              onDelete={() =>
                setOpenForm({
                  open: true,
                  type: "Delete",
                  data: flow,
                })
              }
              onTeamConfig={() => {
                setOpenTeamConfigForm({
                  open: true,
                  type: "Add",
                  data: flow,
                });
              }}
              toggleChecked={flow.visibleOnFrontend}
              onToggleChange={(visibleOnFrontend) =>
                update(
                  updateBotFlow(
                    id,
                    { ...flow, visibleOnFrontend: visibleOnFrontend ? 1 : 0 },
                    flow?.uuid
                  )
                )
              }
              navigate={navigate}
              setNavToggle={setNavToggle}
              isUpdateLoading={isUpdateLoading}
            />
          ))}
        </div>
      ) : (
        <div className="text-gray-400 font-semibold text-center">
          No Bot Flows
        </div>
      )}

      <DeleteModal
        title={"Are you sure, you want to delete this bot flow"}
        open={openForm.open && openForm?.type === "Delete"}
        onClose={clearForm}
        loading={isDeleteLoading}
        onDelete={() => {
          deleteApi(deleteBotFlow(id, null, openForm?.data?.uuid));
        }}
      />

      <Modal
        title={`${openForm.type} Name`}
        isOpen={openForm?.open && openForm?.type !== "Delete"}
        onClose={clearForm}
      >
        <BotNameForm
          flowData={openForm?.data}
          onSubmit={(data) => {
            if (openForm?.data) {
              update(updateBotFlow(id, data, openForm?.data?.uuid));
            } else {
              create(createBotFlow(id, data));
            }
          }}
          loading={isCreateLoading || isUpdateLoading}
        />
      </Modal>

      <DeleteModal
        title={"Are you sure, you want to delete this team connection"}
        open={sectionFormOpen.open && sectionFormOpen?.type === "Delete"}
        onClose={clearSectionForm}
        loading={isDeleteSectionLoading}
        onDelete={() => {
          deleteSectionData(
            deleteSection({
              section_id: sectionFormOpen?.data?.id,
            })
          );
        }}
      />
      <Modal
        title={`${openTeamConfigForm.type} Team Configuration`}
        isOpen={
          openTeamConfigForm?.open && openTeamConfigForm?.type !== "Delete"
        }
        onClose={clearTeamConfigForm}
      >
        <TeamConfigurationForm
          data={openTeamConfigForm?.data}
          onSearch={(data) => {
            getTeamsList(
              getTeams({
                search: data,
              })
            );
          }}
          onSubmit={(data) => {
            createSectionData(createSection(data));
          }}
          getSection={() => {
            getSectionList(
              getSection({
                bot_id: openTeamConfigForm?.data?.uuid,
              })
            );
          }}
          section={section}
          searchData={teams}
          loading={isTeamLoading || isCreateSectionLoading}
          sectionFormOpen={sectionFormOpen}
          handleSectionFormOpen={setSectionFormOpen}
          isSectionLoading={isSectionLoading}
        />
      </Modal>
    </div>
  );
}

export default ListBotFlow;
