import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../../api/api";
import Select from "react-select";
import Loading from "../loaders/Loading";

const RelatedTickets = (props) => {
  const [processing, setProcessing] = useState(false);
  const [relatedModal, setRelatedModal] = useState(false);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [relatedTicket, setRelatedTicket] = useState({ value: "", label: "" });
  const { id } = useParams();

  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const btnRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        btnRef.current &&
        !btnRef.current.contains(event.target) &&
        event.target.closest("#related-modal-ticket") == null
      ) {
        setRelatedModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props.ticketDetail) {
      var postData = {
        value: "",
        community_id: currCommunityId,
        ticket_id: id,
      };
      api.post("/api/searchTickets", postData).then((response) => {
        if (response.data.status == "success") {
          const formattedOptions = response.data.data.map((ticket) => ({
            value: ticket.ticket_id,
            label: ticket.topic,
          }));
          setTicketOptions(formattedOptions);
        }
      });
    }
  }, [props.ticketDetail]);

  const onSelectChange = (value) => {
    if (value) {
      addRelatedTicket(value.value);
    }
  };

  const ticketSelectInput = (newValue) => {
    // console.log(newValue);
    var postData = {
      value: newValue,
      community_id: currCommunityId,
      ticket_id: id,
    };
    api.post("/api/searchTickets", postData).then((response) => {
      if (response.data.status == "success") {
        const formattedOptions = response.data.data.map((ticket) => ({
          value: ticket.ticket_id,
          label: ticket.topic,
        }));
        setTicketOptions(formattedOptions);
      }
    });
  };

  const addRelatedTicket = (value) => {
    // console.log(value);
    var postData = {
      ticket_id: id,
      community_id: currCommunityId,
      relatedTicket: value,
    };

    api
      .post("/api/addRelatedTickets", postData)
      .then((response) => {
        if (response.data.status == "success") {
          props.handleTicketDetail(response.data.data);
        }
        setRelatedModal(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Related Ticket Added",
        });
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  const removeRelatedTicket = (value) => {
    // console.log(value);
    var postData = {
      ticket_id: id,
      community_id: currCommunityId,
      id: value,
    };
    api
      .post("/api/removeRelatedTickets", postData)
      .then((response) => {
        if (response.data.status == "success") {
          props.handleTicketDetail(response.data.data);
        }
        setRelatedModal(false);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Related Ticket Removed",
        });
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Something Went Wrong",
        });
      });
  };

  const { relatedTickets, parentTicket } = props?.ticketDetail || {};

  const showTickets = relatedTickets?.length || parentTicket?.length;

  return (
    <div className="w-full">
      {showTickets ? (
        <ul>
          {props.ticketDetail?.relatedTickets?.map(
            (ticket, index) =>
              props.ticketDetail.ticket_id != ticket.ticket_id && (
                <span className="assign-div cursor-pointer flex items-center justify-between gap-2 pb-2 rounded-md">
                  <a
                    className="hover:text-blue-700"
                    href={`/ticket-detail/${ticket.ticket_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className="three-dots">
                      {ticket.ticket_id} - {ticket.topic}
                    </li>
                  </a>
                  <span
                    className="assign-remove font-semibold text-gray-500"
                    onClick={(e) => removeRelatedTicket(ticket.id)}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                </span>
              )
          )}
          {props.ticketDetail?.parentTicket?.map(
            (ticket, index) =>
              props.ticketDetail.ticket_id != ticket.ticket_id && (
                <span className="assign-div cursor-pointer flex items-center justify-between gap-2 pb-2 rounded-md">
                  <a
                    href={`/ticket-detail/${ticket.ticket_id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-700"
                  >
                    <li className="three-dots">
                      {ticket.ticket_id} - {ticket.topic}
                    </li>
                  </a>
                  <span
                    className="assign-remove font-semibold text-gray-500"
                    onClick={(e) => removeRelatedTicket(ticket.id)}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                </span>
              )
          )}
        </ul>
      ) : null}

      <div className="relative w-full">
        <button
          type="button"
          className="flex duration-300 ease-in text-indigo-600 font-medium text-sm"
          disabled={processing}
          onClick={() => setRelatedModal(!relatedModal)}
          ref={btnRef}
        >
          {processing ? <Loading /> : null}
          Add Related Ticket
        </button>

        {relatedModal && (
          <div
            id="related-modal-ticket"
            className="absolute z-40 top-8 left-0 w-full bg-white"
          >
            <Select
              className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
              options={[...ticketOptions]}
              onInputChange={ticketSelectInput}
              onChange={onSelectChange}
              isClearable
              components={{ DropdownIndicator: () => null }}
              isSearchable
              menuIsOpen="open"
              formatOptionLabel={({ value, label }) => (
                <div>
                  <span>{value}</span>
                  <span> -- </span>
                  <span>{label}</span>
                </div>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RelatedTickets;
