import { useState } from "react";
import Modal from "../common/Modal";
import ManageApprovalForm from "./ManageApprovalForm";

function ManageApproval({ modal, setModal }) {
  return (
    <div>
      <Modal
        isOpen={modal}
        onClose={() => setModal("")}
        title={"Manage Approval"}
        customCls="md:!max-w-[1100px]"
      >
        <div className="py-6">
          <ManageApprovalForm closeModal={() => setModal("")} />
        </div>
      </Modal>
    </div>
  );
}

export default ManageApproval;
