import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import Select from "react-select";
import DependencyDropdown from "./DependencyDropdown";
import useTeam from "../settings/team/useTeam";
import useApiData from "../../hooks/useApiData";
import useTicket from "./useTicket";
import SelectEmails from "../common/SelectEmails";
import usePortals from "../../containers/settings/portals/usePortals";
import Button from "../common/Button";
import FileCard from "./FileCard";
import { validateAdditionalFields } from "../../hooks/commonHelper";

const priorityOptions = [
  {
    value: "p1",
    label: "P1",
  },
  {
    value: "p2",
    label: "P2",
  },
  {
    value: "p3",
    label: "P3",
  },
  {
    value: "p4",
    label: "P4",
  },
];

function NewTicketForm({
  setOpen,
  getAllTickets = () => {},
  clearForm = () => {},
  childTicket,
  refetch = () => {},
}) {
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [availableChats, setAvailableChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState({
    name: "Select a chat",
    value: "",
  });
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [topic, setTopic] = useState("");
  const [relatedTicket, setRelatedTicket] = useState({ value: "", label: "" });
  const [priority, setPriority] = useState("");
  const [content, setContent] = useState("");
  const [topicError, setTopicError] = useState("");
  const [relatedTicketError, setRelatedTicketError] = useState("");
  const [priorityError, setPriorityError] = useState("");
  const [chatId, setChatId] = useState("");
  const [chatIdError, setChatIdError] = useState("");
  const [contentError, setContentError] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");
  const [addTicketModal, setAddTicketModalopen] = useState(false);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [commnuityFields, setcommnuityFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryParams, setQueryParams] = useState({});
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState([]);
  const [portal, setPortal] = useState("");
  const [portalError, setPortalError] = useState("");
  const inputFileRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const [depenedencyError, setDepenedencyError] = useState({});

  const [additionaFields, setadditionaFields] = useState({});
  const [additionalFieldsError, setAdditionalFieldsError] = useState({});

  const { getTeams } = useTeam();
  const { getClients } = useTicket();
  const { getPortals } = usePortals();

  const getAllFields = () => {
    var postData = {
      community_id: currCommunityId,
    };

    api.post(`/api/getCommunityFields`, postData).then((response) => {
      if (response.data.status == "success") {
        // console.log("response.data.data", response.data.data);
        setcommnuityFields(response.data.data);
        // console.log(commnuityFields);
      }
    });
  };

  const validation = (name, value, error = null) => {
    if (name === "priority") {
      if (error != null) {
        setPriorityError("This Field is required");
      } else {
        if (value !== "") {
          setPriority(value);
          setPriorityError("");
        } else {
          setPriority("");
          setPriorityError("This field is required");
        }
      }
    }

    if (name === "topic") {
      if (error != null) {
        setTopicError("This Field is required");
      } else {
        if (value !== "") {
          setTopicError("");
          setTopic(value);
        } else {
          setTopic("");
          setTopicError("This field is required");
        }
      }
    }

    if (name === "content") {
      if (error != null) {
        setContentError("This Field is required");
      } else {
        if (value !== "") {
          setContentError("");
          setContent(value);
        } else {
          setContent("");
          setContentError("This field is required");
        }
      }
    }
    if (name === "chatId") {
      if (error != null) {
        // setChatIdError("This Field is required");
      } else {
        if (value !== "") {
          // setChatIdError("");
          setChatId(value);
        } else {
          setChatId("");
          // setChatIdError("This field is required");
        }
      }
    }

    if (name === "client_name") {
      if (error != null) {
      } else {
        if (value !== "") {
          setclientName(value);
        } else {
          setclientName("");
        }
      }
    }

    if (name === "client_email") {
      if (error != null) {
        setClientEmailError("This Field is required");
      } else {
        if (value !== "") {
          setclientEmail(value);
          setClientEmailError("");
        } else {
          setclientEmail([]);
          setClientEmailError("This Field is required");
        }
      }
    }

    if (name === "portal_id") {
      if (error != null) {
        setPortalError("This Field is required");
      } else {
        if (value !== "") {
          setPortal(value);
          setPortalError("");
        } else {
          setPortal([]);
          setPortalError("This Field is required");
        }
      }
    }
  };

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  const folderForm = (e) => {
    e.preventDefault();

    setProcessing(true);
    let hasError = false;
    var postData = {
      //client_id: "cus-121212",
      ticketContent: content,
      topic: topic,
      relatedTicket: relatedTicket.value,
      chatId: chatId,
      priority: priority,
      community_id: currCommunityId,
      status: "open",
      clientName: clientName,
      clientEmail: clientEmail,
      portal_id: portal,
      additionaFields: additionaFields,
      attachments: attachments,
    };

    if (childTicket) {
      postData.childTicket = childTicket;
    }
    Object.keys(postData).forEach((key) => {
      if (key === "topic" && postData.topic === "") {
        setTopicError("This field is required");
        hasError = true;
      } else if (key === "ticketContent" && postData.ticketContent === "") {
        setContentError("This field  is required");
        hasError = true;
      } else if (key === "priority" && postData.priority === "") {
        setPriorityError("This field  is required");
        hasError = true;
      } else if (key === "clientEmail" && postData.clientEmail.length === 0) {
        setClientEmailError("This field  is required");
        hasError = true;
      } else if (key === "portal_id" && postData.portal_id === "") {
        setPortalError("This field  is required");
        hasError = true;
      } else if (key === "additionaFields") {
        const additionalFieldvalues = postData.additionaFields;
        const errorData = validateAdditionalFields(
          additionalFieldvalues,
          commnuityFields
        );
        hasError = errorData.havingError;
        setAdditionalFieldsError(errorData.error);
      }
    });
    if (hasError) {
      setProcessing(false);
      return;
    }

    api
      .post("/api/createTicket", postData)
      .then((response) => {
        if (response.data.status == "success") {
          // console.log("response.data.data", response.data.data);
          setProcessing(false);
          setAddTicketModalopen(false);
          setShowToast(true);
          getAllTickets();
          clearForm();
          refetch();
          setOpen(false);
          setToastMessage({
            type: "success",
            message: "Ticket Created Successfully",
          });
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          var errors = error.response.data.errors;
          Object.keys(errors).map((keyName, keyIndex) => {
            validation(keyName, null, errors[keyName][0]);
          });
          setToastMessage({ type: "error", message: "Something Went Wrong" });
        }
        setProcessing(false);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.name, e.target.value);

    validation(e.target.name, e.target.value);
  };

  const ticketSelectInput = (newValue) => {
    // console.log(newValue);
    var postData = {
      value: newValue,
      community_id: currCommunityId,
    };
    setProcessing(true);

    api
      .post("/api/searchTickets", postData)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          const formattedOptions = response.data.data.map((ticket) => ({
            value: ticket.ticket_id,
            label: ticket.topic,
          }));
          setTicketOptions(formattedOptions);
        }
      })
      .catch((data) => {
        setProcessing(false);
      });
  };

  const onSelectChange = (value) => {
    if (value) {
      setRelatedTicket({ value: value.value, label: value.label });
    }
  };

  const fieldhandleChange = (event, additionaFields, field) => {
    const { name, value } = event.target;

    const newFields = { ...additionaFields };

    if (value) {
      newFields[name] = value;
    } else {
      delete newFields[name];
    }
    setadditionaFields(newFields);
    const errorData = validateAdditionalFields(newFields, [field]);
    setAdditionalFieldsError((prev) => ({
      ...prev,

      [name]: errorData.error ? errorData.error[name] : "",
    }));
  };

  const handleSelectButtonClick = () => {
    inputFileRef.current.click();
  };

  const handleFileUpload = async (e) => {
    setProcessing(true);
    const files = Array.from(e.target.files);
    var attachmentFiles = [];
    const attachmentPromises = files.map((attachment) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileData = event.target.result;
          const base64Data = fileData.split(",")[1];
          const mimeType = fileData.match(/^data:(.*?);/)[1];
          const fileName = attachment.name;
          const fileExtension = attachment.name.split(".").pop().toLowerCase();
          attachmentFiles.push({
            base64Data,
            fileName,
            fileExtension,
            mimeType,
          });
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(attachment);
      });
    });

    try {
      await Promise.all(attachmentPromises);
      setAttachments(attachmentFiles);
      setProcessing(false);
    } catch (error) {
      setShowToast(true);
      setToastMessage({
        type: "error",
        message: "Something went wrong",
      });
    }
  };

  const getAllFolders = () => {
    var postData = {
      search: "",
      community_id: currCommunityId,
    };

    api
      .post("/api/folderList", postData)
      .then((response) => {
        var options = response.data.data.map((item) => {
          return {
            name: item.name,
            uuid: item.uuid,
          };
        });
        // setAvailableChats(options);
      })
      .catch((error) => {
        // setAvailableChats([]);
      });
  };

  const {
    data: teamsData,
    mutate: getLists,
    loading: isTeamLoading,
  } = useApiData({
    onSuccess: (response) => {
      var options = response.data.map((item) => {
        return {
          name: item.name,
          uuid: item.team_id,
        };
      });
      setAvailableChats(options);
    },
    onError: () => onError("Something went wrong while getting Teams"),
  });

  const {
    data: clientData,
    mutate: getClientsData,
    loading: isClientLoading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => {},
  });

  const {
    data: portalData,
    mutate: getPortalsData,
    loading,
  } = useApiData({
    onSuccess: (res) => {},
    onError: () => {},
  });

  useEffect(() => {
    getAllFields();
    // getUserInfo();
    getAllFolders();
    getLists(getTeams());
    getClientsData(getClients());
    getPortalsData(getPortals());

    var postData = {
      value: "",
      community_id: currCommunityId,
    };
    api.post("/api/searchTickets", postData).then((response) => {
      if (response.data.status == "success") {
        const formattedOptions = response.data.data.map((ticket) => ({
          value: ticket.ticket_id,
          label: ticket.topic,
        }));
        setTicketOptions(formattedOptions);
      }
    });
  }, []);

  return (
    <div
      id="defaultModal"
      tabIndex={-1}
      className={` dialog-overlay add-new-tickets fixed dialog-container top-0 left-0 right-0 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
    >
      <div className="mx-auto relative w-full h-full max-w-lg	 md:h-autothat lg:max-w-[700px]">
        <div className="relative dialog-content bg-white rounded-lg shadow">
          <div className="flex items-start justify-between p-4 rounded-t">
            <h3 className="text-xl font-semibold text-gray-900  ">
              Add new Ticket
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center  "
              data-modal-hide="defaultModal"
              onClick={() => setOpen(false)}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form action="#" onSubmit={folderForm}>
            <div className="">
              <div className="mt-4">
                <div className="grid grid-cols-2 gap-4 h-[380px] overflow-y-scroll px-6">
                  <div className="">
                    <label className="form-label mb-2 required-input">
                      Title
                    </label>
                    <div className>
                      <input
                        className={`${
                          topicError !== "" ? "input-danger" : ""
                        } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                        type="text"
                        name="topic"
                        autoComplete="off"
                        placeholder="Enter topic"
                        value={topic}
                        onChange={(e) => handleChange(e)}
                      />
                      {topicError !== "" ? (
                        <div className="form-error">{topicError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="">
                    <label className="form-label mb-2 required-input">
                      Priority
                    </label>
                    <div className>
                      <Select
                        className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        options={[...priorityOptions]}
                        onChange={(values) =>
                          validation("priority", values.value)
                        }
                      />
                      {/* <select
                        className={`${
                          priorityError !== "" ? "input-danger" : ""
                        } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600`}
                        name="priority"
                        value={priority}
                        onChange={(e) => handleChange(e)}
                        required
                      >
                        <option value="">Please select a priority level</option>
                        <option value="p1">P1</option>
                        <option value="p2">P2</option>
                        <option value="p3">P3</option>
                        <option value="p4">P4</option>
                      </select> */}
                      {priorityError !== "" ? (
                        <div className="form-error">{priorityError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="">
                    <label className="form-label mb-2">Belongs To</label>
                    <div className>
                      <select
                        className={`${
                          chatIdError !== "" ? "input-danger" : ""
                        } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600`}
                        name="chatId"
                        value={chatId}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Please select a Team</option>
                        {availableChats.map((chat, index) => (
                          <React.Fragment key={index}>
                            <option value={chat.uuid}>{chat.name}</option>
                          </React.Fragment>
                        ))}
                      </select>
                      {chatIdError !== "" ? (
                        <div className="form-error">{chatIdError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> */}
                  <div className=" invalid col-span-2 !my-0">
                    <label className="form-label mb-2 required-input">
                      Content
                    </label>
                    <div className>
                      <textarea
                        className={`${
                          contentError !== "" ? "input-danger" : ""
                        } input-textarea input-form input input-invalid input-textarea`}
                        type="text"
                        name="content"
                        autoComplete="off"
                        placeholder="Enter content"
                        spellCheck="false"
                        value={content}
                        onChange={(e) => handleChange(e)}
                      />
                      {contentError !== "" ? (
                        <div className="form-error">{contentError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className=" col-span-2 !mb-4">
                    <label className="form-label mb-2">Related Tickets</label>
                    <div className>
                      <Select
                        className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        options={[...ticketOptions]}
                        onInputChange={ticketSelectInput}
                        onChange={onSelectChange}
                        isClearable
                        isSearchable
                        formatOptionLabel={({ value, label }) => (
                          <div>
                            <span>{value}</span>
                            <span> -- </span>
                            <span>{label}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="form-label mb-2 required-input">
                      Client Email
                    </label>
                    <div className>
                      {/* <input
                            className={`${
                              topicError !== "" ? "input-danger" : ""
                            } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                            type="email"
                            name="client_email"
                            autoComplete="off"
                            placeholder="Client Email"
                            value={clientEmail}
                            onChange={(e) => handleChange(e)}
                            required
                          /> */}
                      <SelectEmails
                        options={clientData?.data?.map((c) => ({
                          label: c.client_email,
                          value: c.client_email,
                        }))}
                        handleChange={(values) => {
                          const event = {
                            preventDefault: () => {},
                            target: {
                              name: "client_email",
                              value: values.value,
                            },
                          };
                          handleChange(event);
                        }}
                        onSearch={(value) =>
                          getClientsData(getClients({ search: value }))
                        }
                        // required={true}
                        isMulti={false}
                      />
                      {clientEmailError !== "" ? (
                        <div className="form-error">{clientEmailError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="form-label mb-2 required-input">
                      Select Portal
                    </label>
                    <div className>
                      <Select
                        className="h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                        options={
                          portalData?.data?.map((portal) => ({
                            value: portal.id,
                            label: portal.name,
                          })) || []
                        }
                        onChange={(values, actions) => {
                          const event = {
                            preventDefault: () => {},
                            target: {
                              name: "portal_id",
                              value: values.value,
                            },
                          };
                          handleChange(event);
                        }}
                        isSearchable
                        formatOptionLabel={({ value, label }) => (
                          <div>
                            <span>{label}</span>
                          </div>
                        )}
                      />
                      {portalError !== "" ? (
                        <div className="form-error">{portalError}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className=" col-span-2 !mt-4">
                    <label className="form-label mb-4">Additional Fields</label>
                    <div className="grid grid-cols-2 gap-4">
                      {commnuityFields.map((item, idx) => (
                        <div>
                          {commnuityFields[idx]["field_type"] == "input" && (
                            <div>
                              <label
                                className={`form-label mb-2 ${
                                  commnuityFields[idx]["is_required"] === 1
                                    ? "required-input"
                                    : ""
                                }`}
                              >
                                {commnuityFields[idx]["field_name"]}
                              </label>
                              <div className>
                                <input
                                  className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                                  type="text"
                                  name={`${item.id}`}
                                  autoComplete="off"
                                  placeholder={
                                    commnuityFields[idx]["field_name"]
                                  }
                                  value={
                                    additionaFields[commnuityFields[idx]["id"]]
                                  }
                                  onChange={(e) =>
                                    fieldhandleChange(
                                      e,
                                      additionaFields,
                                      commnuityFields[idx]
                                    )
                                  }
                                  // required={
                                  //   commnuityFields[idx]["is_required"] === 1
                                  // }
                                />
                              </div>
                              {additionalFieldsError[item.id] && (
                                <div className="form-error">
                                  {additionalFieldsError[item.id]}
                                </div>
                              )}
                            </div>
                          )}
                          {commnuityFields[idx]["field_type"] == "select" && (
                            <div>
                              <label
                                className={`form-label mb-2 ${
                                  commnuityFields[idx]["is_required"] === 1
                                    ? "required-input"
                                    : ""
                                }`}
                              >
                                {commnuityFields[idx]["field_name"]}
                              </label>
                              <div className>
                                <select
                                  className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                                  value={
                                    additionaFields[commnuityFields[idx]["id"]]
                                  }
                                  onChange={(e) =>
                                    fieldhandleChange(
                                      e,
                                      additionaFields,
                                      commnuityFields[idx]
                                    )
                                  }
                                  name={`${item.id}`}
                                  // required={
                                  //   commnuityFields[idx]["is_required"] === 1
                                  // }
                                >
                                  <option value="">
                                    Please select{" "}
                                    {commnuityFields[idx]["field_name"]}
                                  </option>
                                  {commnuityFields[idx]["field_values"].map(
                                    (field, index) => (
                                      <React.Fragment key={index}>
                                        <option value={field}>{field}</option>
                                      </React.Fragment>
                                    )
                                  )}
                                </select>
                              </div>
                              {additionalFieldsError[item.id] && (
                                <div className="form-error">
                                  {additionalFieldsError[item.id]}
                                </div>
                              )}
                            </div>
                          )}
                          {item?.field_type === "dependency_dropdown" ? (
                            <DependencyDropdown
                              field={item}
                              selectedValues={additionaFields[item.id]}
                              id={item.id}
                              handleChange={(value) => {
                                const data = {
                                  target: {
                                    name: item.id,
                                    value: Object.keys(value).length
                                      ? value
                                      : null,
                                  },
                                };

                                fieldhandleChange(data, additionaFields, item);
                              }}
                              fromApprovalPage={item.is_required}
                              depenedencyError={additionalFieldsError[item.id]}
                            />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="pb-6 col-span-2">
                    <Button
                      className="text-indigo-600 font-medium hover:bg-gray-100 w-full py-3 border border-gray-300 rounded-md flex justify-center items-center"
                      onClick={handleSelectButtonClick}
                    >
                      <img
                        src="/images/icons/attachment-blue.svg"
                        alt="attachment"
                        className="mr-2"
                      />
                      Attach File
                      <input
                        type="file"
                        className="hidden"
                        ref={inputFileRef}
                        onChange={handleFileUpload}
                        multiple
                      />
                    </Button>

                    {attachments?.length ? (
                      <div className="my-2 flex gap-2 flex-wrap">
                        {attachments.map((attach, index) => {
                          return (
                            <FileCard
                              key={attach.id}
                              index={index}
                              file={attach}
                              handleRemoveAttachment={() => {
                                setAttachments((prev) =>
                                  prev.filter((a, i) => i !== index)
                                );
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : null}

                    {/* <div
                      onClick={handleSelectButtonClick}
                      className="mb-3 vertical text-indigo-500 underline cursor-pointer"
                    >
                      Select Attachments
                    </div>

                    <input
                      type="file"
                      className="hidden"
                      ref={inputFileRef}
                      onChange={handleFileUpload}
                      multiple
                    /> */}
                    {/* {attachments?.length > 0 && (
                      <span className="ml-2">
                        {attachments.length} Files Selected
                      </span>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
              <button
                className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                type="submit"
                disabled={processing}
              >
                {processing && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}{" "}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewTicketForm;
