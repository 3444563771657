import Favian from "../../components/chatBot/Favian";

function AIConfig() {
  return (
    <div className="mt-2">
      <Favian />
    </div>
  );
}

export default AIConfig;
