import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import moment from "moment";

const ApiRow = (props) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setInviteModal,
    inviteModal,
    setHasChanged,
    handleLinkClick,
  } = useContext(AppContext);
  const btnRef = useRef();
  const [openDropDown, setOpenDropDown] = useState(false);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (btnRef.current && !btnRef.current.contains(e.target)) {
        setOpenDropDown(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    // // console.log(props.getAllFolders);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <tr key={props.data.hashId} className="bg-white border-b issue-lists">
      <td className="p-4">
        <div className="flex items-center">
          <div className="three-dots pt-1">{props.data.access_token}</div>
          <button
            type="button"
            data-copy-state="copy"
            className=" "
            onClick={() => {
              navigator.clipboard.writeText(props.data.access_token);
              setShowToast(true);
              setToastMessage({
                type: "success",
                message: "Copied key to clipboard",
              });
            }}
          >
            <img src="/images/icons/copy.svg" alt="copy" />
          </button>
        </div>
      </td>
      <td className="p-4">
        <div
          ref={btnRef}
          className="relative px-4 py-2 cursor-pointer border border-gray-300 rounded-md w-fit"
          onClick={() => setOpenDropDown(!openDropDown)}
        >
          {props.data.status == 1 ? "Active" : "Inactive"}
          <i className="fa-solid fa-chevron-down ml-3"></i>
          {openDropDown && (
            <div className="z-10 rounded absolute mt-3 bg-white shadow-md w-24 left-0">
              <div
                className="border-b py-2 px-3 hover:bg-gray-100 hover:text-gray-700"
                onClick={() => props.updateStatus(props.data.hashId, 1)}
              >
                Active
              </div>
              <div
                className=" py-2 px-3 hover:bg-gray-100 hover:text-gray-700"
                onClick={() => props.updateStatus(props.data.hashId, 0)}
              >
                Inactive
              </div>
            </div>
          )}
        </div>
      </td>

      <td className="p-4">
        {moment(props.data.created_at).format("YYYY-MM-DD")}
      </td>
      {/* <td className="px-6 py-4 ticket-status">
        <span className="px-4 cursor-pointer hover:bg-red-100 duration-300 ease py-2 bg-red-50 text-red-500 rounded">
          Remove
        </span>
      </td> */}
      <td />
    </tr>
  );
};

export default ApiRow;
