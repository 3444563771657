import React, { useState } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import Field from "./Field";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link } from "react-router-dom";
const FIELD = "field";

const initialFields = [
  { id: "1", type: "text", label: "Text Field", options: [] },
  {
    id: "3",
    type: "dropdown",
    label: "Dropdown",
    options: ["", ""],
  },
  {
    id: "4",
    type: "multiselect",
    label: "Multiselect",
    options: [""],
  },
  { id: "5", type: "date", label: "Date Field", options: [] },
];

const FormBuilderPage = (props) => {
  const [selectOptionForm, setSelectOptionForm] = useState(false);
  const [multiSelectOptionForm, setMultiSelectOtionForm] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsCurrIndex, setOptionsCurrIndex] = useState(0);
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.index === destination.index) {
      return;
    }

    const newFields = [...props.fieldValue];
    const [removed] = newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, removed);
    props.setFieldValue(newFields);
  };

  const DraggableField = ({ id, index, type, label, children, options }) => {
    const [{ isDragging }, drag] = useDrag({
      type: FIELD,
      item: { id, index, type, label, options },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [{ canDrop, isOver }, drop] = useDrop({
      accept: FIELD,
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = index;
        const newFields = [...props.fieldValue];
        const [removed] = newFields.splice(dragIndex, 1);
        newFields.splice(hoverIndex, 0, removed);

        props.setFieldValue(newFields);
        item.index = hoverIndex;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = canDrop && isOver;
    return (
      <div
        ref={drag}
        className={`mb-2 p-2 ease-in duration-300 cursor-pointer hover:bg-gray-200 rounded-md ${
          isDragging ? "bg-gray-200" : ""
        }`}
      >
        <div
          ref={drop}
          className={`${isActive ? "bg-gray-200" : ""} checking1`}
        >
          {children}
        </div>
      </div>
    );
  };
  function handleRemoveField(index) {
    props.setFieldValue((fields) => {
      const updatedFields = [...fields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  }
  const DroppableFields = ({ children }) => {
    const [{ canDrop, isOver }, drop] = useDrop({
      accept: FIELD,
      drop: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = props.fieldValue.length;
        const newFields = [...props.fieldValue, item];
        // const [removed] = newFields.splice(dragIndex, 1);
        // newFields.splice(hoverIndex, 0, removed);
        props.setFieldValue(newFields);
        item.index = hoverIndex;
        // console.log("children", item);
        setOptions(item.options);
        setOptionsCurrIndex(item.index);
        if (item.type == "dropdown" || item.type == "multiselect") {
          setSelectOptionForm(true);
        } else {
          setSelectOptionForm(false);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const isActive = canDrop && isOver;
    return (
      <div
        ref={drop}
        className={`${isActive ? "bg-gray-200" : ""} w-full h-full`}
      >
        {children}
      </div>
    );
  };

  const handleLabelChange = (field) => {
    const newFields = [...props.fieldValue];
    newFields[field.index].label = field.label;
    // // console.log("newFields", newFields);
    props.setFieldValue(newFields);
  };

  const addOptionField = () => {
    setOptions([...options, ""]);
  };

  const onOptionFormSubmit = (e) => {
    e.preventDefault();
    // console.log(props.fieldValue);
    const newFields = [...props.fieldValue];
    newFields[optionsCurrIndex].options = options;
    // console.log("newFields", newFields);
    props.setFieldValue(newFields);
  };

  const setOptionValues = (type, options, index) => {
    if (type == "dropdown" || type == "multiselect") {
      setSelectOptionForm(true);
      setOptions(options);
      setOptionsCurrIndex(index);
    } else {
      setSelectOptionForm(false);
    }
  };

  const handleOptionChange = (e, index) => {
    const newOption = [...options];
    newOption[index] = e.target.value;
    setOptions(newOption);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container mx-auto form-builder">
        <h1 className="text-2xl font-bold my-3">Form Builder</h1>
        <div className="flex">
          <div className="rounded-xl w-1/4 p-4 bg-gray-50 mr-4">
            {selectOptionForm && (
              <form onSubmit={(e) => onOptionFormSubmit(e)}>
                <div
                  className="cursor-pointer flex items-center mb-2"
                  onClick={(e) => setSelectOptionForm(false)}
                >
                  <i className="fa-solid fa-chevron-left mr-2 bg-gray-200 py-1 px-2 text-gray-500 rounded"></i>
                  <h2 className="text-lg font-semibold ">
                    Please enter Options
                  </h2>
                </div>
                {/* {JSON.stringify(options)} */}
                {options.map((value, index) => (
                  <input
                    className="mb-2 input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                    value={value}
                    key={index}
                    onChange={(e) => handleOptionChange(e, index)}
                  />
                ))}
                <button
                  className="mt-4 mb-2 inline-flex items-center justify-center button bg-gray-100 light-blue-hover active:bg-gray-300 text-gray-600 radius-round h-11 px-8 py-2 w-full"
                  type="button"
                  onClick={() => addOptionField("select")}
                >
                  {" "}
                  Add Option
                </button>
                <button
                  className="mb-4 inline-flex items-center justify-center button bg-indigo-500 hover:bg-indigo-700 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                  type="submit"
                >
                  Save
                </button>
              </form>
            )}
            {selectOptionForm != true && (
              <div>
                <h2 className="text-lg font-bold mb-2">Fields</h2>
                {initialFields.map((field, index) => (
                  <DraggableField
                    key={field.id}
                    id={field.id}
                    type={field.type}
                    label={field.label}
                    index={index}
                    options={field.options}
                  >
                    {field.label}
                  </DraggableField>
                ))}
              </div>
            )}
          </div>
          <div className="rounded-xl w-3/4 p-4 bg-gray-50">
            <div className="w-full h-full ">
              <DroppableFields index={0} className="w-full">
                {/* {JSON.stringify(props.fieldValue)} */}
                {props.fieldValue.map((field, index) => (
                  <Field
                    key={field.id}
                    index={index}
                    type={field.type}
                    label={field.label}
                    options={field.options}
                    handleRemoveField={handleRemoveField}
                    handleLabelChange={handleLabelChange}
                    setOptionValues={setOptionValues}
                  />
                ))}
              </DroppableFields>
              <button
                className=" mb-4 inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round next-btn h-11 px-8 py-2 w-36 float-right"
                type="submit"
                disabled={props.processing}
                onClick={props.formBuilderSubmit}
              >
                {props.processing && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}{" "}
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default FormBuilderPage;
