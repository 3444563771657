import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../App";
import api from "../../../api/api";
import Select from "react-select";
import Spinner from "../../loaders/Spinner";

const Imap = (props) => {
  const [processing, setProcessing] = useState(false);
  const [id, setId] = useState();
  const [loader, setLoader] = useState(true);
  const [imapDetail, setImapDetail] = useState({
    host: "",
    port: "",
    encryption: "",
    validate_cert: "",
    username: "",
    password: "",
    status: "",
  });
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    socket,
  } = useContext(AppContext);

  useEffect(() => {
    var postData = {
      community_id: currCommunityId,
    };
    setLoader(true);
    api
      .post("/api/getImap", postData)
      .then((response) => {
        setImapDetail(response.data.data);
        setId(response.data.data.id);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setImapDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
    // console.log(imapDetail);
  };
  const formSubmit = (e, status = null) => {
    e.preventDefault();

    setProcessing(true);
    var postData = {
      community_id: currCommunityId,
      host: imapDetail.host,
      port: imapDetail.port,
      encryption: imapDetail.encryption,
      validate_cert: imapDetail.validate_cert,
      username: imapDetail.username,
      password: imapDetail.password,
      status: status != null ? status : imapDetail.status,
      id: id,
    };
    // console.log(postData);

    if (id) {
      var apiUrl = "/api/updateImap";
    } else {
      var apiUrl = "/api/insertImap";
    }

    api
      .post(apiUrl, postData)
      .then((response) => {
        setProcessing(false);
        setImapDetail(response.data.data);
        setId(response.data.data.id);
        setShowToast(true);
        setToastMessage({
          type: "success",
          message: "Data Saved Successfully",
        });
        console.log("callinng====>");
        socket.emit("restart-imap");
      })
      .catch((error) => {
        setProcessing(false);
        setShowToast(true);
        setToastMessage({
          type: "error",
          message:
            "something went wrong. please check the config details is correct or not",
        });
      });
  };

  const handleselect = (selectedOption) => {
    setImapDetail((prevDetail) => ({
      ...prevDetail,
      validate_cert: selectedOption.value,
    }));
  };

  const updateStatus = (e, status) => {
    formSubmit(e, status);
  };

  return loader ? (
    <div className="flex items-center justify-center h-[50vh]">
      <Spinner />
    </div>
  ) : (
    <>
      {imapDetail && (
        <div className="flex justify-between space-x-2  border-gray-200  rounded-b">
          <label className="form-label mb-2">Imap Configuration</label>
          <div className="cursor-pointer">
            <span
              className={`${
                imapDetail.status == 1
                  ? "bg-green-600 hover:bg-green-500 active:bg-green-700"
                  : "bg-gray-600 hover:bg-gray-500 active:bg-gray-700"
              }  button text-sm text-center text-white px-2 py-1 `}
              type="button"
              onClick={(e) => updateStatus(e, imapDetail.status == 1 ? 0 : 1)}
            >
              {imapDetail.status == 1 ? "Active" : "Disable"}
            </span>
          </div>
        </div>
      )}
      <form action="#" onSubmit={(e) => formSubmit(e)}>
        <div className="">
          <div className="mt-4">
            <div className="form-container vertical">
              <div className="flex gap-2 ">
                <div className="form-item vertical w-[50%]">
                  <label className="form-label mb-2">Host</label>
                  <div>
                    <input
                      className=" input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      type="text"
                      name="host"
                      autoComplete="off"
                      placeholder="Enter Host"
                      required=""
                      value={imapDetail.host}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-item vertical w-[50%]">
                  <label className="form-label mb-2">Port</label>
                  <div>
                    <input
                      className=" input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      type="text"
                      name="port"
                      autoComplete="off"
                      placeholder="Enter Port"
                      required=""
                      value={imapDetail.port}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2 ">
                <div className="form-item vertical w-[50%]">
                  <label className="form-label mb-2">Encryption</label>
                  <div>
                    <input
                      className=" input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      type="text"
                      name="encryption"
                      autoComplete="off"
                      placeholder="Enter Encryption"
                      required=""
                      value={imapDetail.encryption}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-item vertical w-[50%]">
                  <label className="form-label mb-2">Validate Cert</label>
                  <div>
                    <Select
                      className="capitalize text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 focus:border-indigo-600"
                      placeholder="Validate Cert"
                      options={[
                        { value: "true", label: "True" },
                        { value: "false", label: "False" },
                      ]}
                      name="validate_cert"
                      value={{
                        value: imapDetail.validate_cert,
                        label: imapDetail.validate_cert,
                      }}
                      onChange={handleselect}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2 ">
                <div className="form-item vertical w-[50%]">
                  <label className="form-label mb-2">Username</label>
                  <div>
                    <input
                      className=" input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      type="text"
                      name="username"
                      autoComplete="off"
                      placeholder="Enter Username"
                      required=""
                      value={imapDetail.username}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-item vertical w-[50%]">
                  <label className="form-label mb-2">Password</label>
                  <div>
                    <input
                      className=" input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      type="text"
                      name="password"
                      autoComplete="off"
                      placeholder="Enter Password"
                      required=""
                      value={imapDetail.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-2  border-gray-200 rounded-b">
                <button
                  className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                  type="submit"
                  disabled={processing}
                >
                  {processing && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}{" "}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Imap;
