import { useState } from "react";

function getData(data, viewMore, length) {
  const newData = data?.length ? [...data] : [];

  return viewMore ? newData : newData.slice(0, length || 3);
}

function ViewMoreWrapper({
  children,
  data,
  length,
  labelCls,
  viewMoreLabel,
  viewLessLabel,
}) {
  const [viewMore, setViewMore] = useState(false);

  const more = viewMoreLabel || "View more";
  const less = viewLessLabel || "View less";

  return (
    <div>
      {children(getData(data, viewMore, length))}

      {data?.length > 3 ? (
        <button
          onClick={() => setViewMore(!viewMore)}
          className={`outline-none hover:bg-gray-100 text-indigo-600 flex items-center hover:bg-gray-100 my-4 ${labelCls}`}
        >
          <i
            class={`fa-solid ${
              viewMore ? "fa-chevron-up" : "fa-chevron-down"
            } mr-2`}
          ></i>
          {viewMore ? less : more}
        </button>
      ) : null}
    </div>
  );
}

export default ViewMoreWrapper;
