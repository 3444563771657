import {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Spinner from "../loaders/Spinner";
import AssignDropdown from "../common/AssignDropdown";
import api from "../../api/api";
import useApiData from "../../hooks/useApiData";
import Loading from "../loaders/Loading";
import moment from "moment";
import { AppContext } from "../../App";
import {
  MIME_TYPE_TO_EXTENSION_MAPPING,
  socketURL,
} from "../common/commonHelpers";
import AttachmentThumpnail from "../common/AttachmentThumpnail";
import LoadMoreBtn from "./LoadMoreBtn";

function getPastedMsgType(pastedAttachment) {
  const mimeType = pastedAttachment?.mimeType;

  let isDocument = MIME_TYPE_TO_EXTENSION_MAPPING[mimeType];

  if (!isDocument) {
    isDocument = mimeType === "application/pdf";
  }

  if (isDocument) {
    return "document";
  }

  const imageMimeTypes = ["image/jpeg", "image/png"];

  const videoMimeTypes = ["video/mp4", "video/3gp"];

  if (imageMimeTypes.includes(mimeType)) {
    return "image";
  }

  if (videoMimeTypes.includes(mimeType)) {
    return "video";
  }
}

function refactorMsgType(msg) {
  if (typeof msg === "string") {
    return JSON.parse(msg);
  }

  return msg;
}

function getMessagesPerDate(messages = []) {
  return messages.reduce((groups, message) => {
    const date = moment(message?.created_at).format("DD/MM/YYYY");

    if (!groups[date]) {
      groups[date] = [];
    }

    const { reply_to, msg } = message;

    const refactorMsg = {
      ...message,
      msg: refactorMsgType(msg),
      whatsapp_msg_status: refactorMsgType(message.whatsapp_msg_status),
      reply_to: reply_to
        ? {
            ...reply_to,
            msg: refactorMsgType(reply_to?.msg),
          }
        : null,
    };

    groups[date].push(refactorMsg);
    return groups;
  }, {});
}

const socketUrl = socketURL;

const WhatsappChat = forwardRef(
  (
    {
      messages,
      setMessages,
      user_id,
      socket,
      getUsers,
      onTicketCreation,
      setOnTicketCreation,
      setOpenAttachmentPreview,
      scrollToBottom,
      messagesRef,
      page,
      setPage,
      setHasMore,
      hasMore,
      getContentHeight,
      ...props
    },
    ref
  ) => {
    const { setToastMessage, setShowToast, currCommunityId } =
      useContext(AppContext);

    // const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [pastedAttachment, setPastedAttachment] = useState();
    const [replyTo, setReplyTo] = useState({
      msgId: "",
      msgFrom: "",
      msg: "",
    });

    // const messagesContainerRef = useRef();

    const { mutate, loading } = useApiData({
      onSuccess: (res) => {
        const { messages = [], pageNo, isHavingNextPage } = res?.data || {};

        setHasMore(isHavingNextPage);

        if (messages.length) {
          setMessages((prev) => [...messages, ...prev]);
        }

        if (Number(pageNo) === 1) {
          setTimeout(() => {
            scrollToBottom();
          }, 500);
        }
      },
      onError: () => setMessages([]),
    });

    const { mutate: sendTextMsg, loading: isSendLoading } = useApiData({
      onSuccess: (res) => {
        console.log(res);
        setMessages((prev) => [...prev, res?.data?.msg]);
        setNewMessage("");
        getUsers(2);

        if (replyTo?.msgId) {
          setReplyTo({
            msgId: "",
            msgFrom: "",
            msg: "",
          });
        }

        if (onTicketCreation) {
          setOnTicketCreation(null);
        }

        scrollToBottom();
      },
      onError: (res) => {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: res?.error?.message || "Something went wrong",
        });
      },
    });

    const { mutate: sendAttachment, loading: isAttachmentLoading } = useApiData(
      {
        onSuccess: (res) => {
          setMessages((prev) => [...prev, res?.data?.msg]);
          getUsers(2);
          setPastedAttachment("");

          if (replyTo?.msgId) {
            setReplyTo({
              msgId: "",
              msgFrom: "",
              msg: "",
            });
          }

          scrollToBottom();
        },
        onError: (res) => {
          setShowToast(true);
          setToastMessage({
            type: "error",
            message: res?.error?.message || "Something went wrong",
          });
        },
      }
    );

    function sendMsgText(e) {
      // e.preventDefault();

      sendTextMsg(() =>
        api.post(socketUrl + "/whatsapp/send-msg", {
          clientId: user_id,
          msg: newMessage,
          replyTo: replyTo?.msgId,
        })
      );
    }

    function sendAttachmentMsg(data) {
      sendAttachment(() =>
        api.post(socketUrl + "/whatsapp/send-attachment", data)
      );
    }

    const processImage = (imageFile) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileData = event.target.result;
        const base64Data = fileData.split(",")[1];
        const mimeType = fileData.match(/^data:(.*?);/)[1];
        const fileName = imageFile.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();

        setPastedAttachment({
          imageFile,
          fileData,
          base64Data,
          fileName,
          fileExtension,
          mimeType,
        });

        setNewMessage("");
      };
      reader.readAsDataURL(imageFile);
    };

    const onPaste = (event) => {
      const clipboardData = event.clipboardData || window.clipboardData;
      const items = clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        const type = items[i].type;

        const isImage = type.indexOf("image") !== -1;

        const isPdf = type.indexOf("pdf") !== -1;

        const isSupportedDocs = MIME_TYPE_TO_EXTENSION_MAPPING[type];

        if (isImage || isSupportedDocs || isPdf) {
          const imageFile = items[i].getAsFile();
          processImage(imageFile);
        }
      }
    };

    function sendPastedAttachment() {
      const whatsappMsgType = getPastedMsgType(pastedAttachment);

      if (!whatsappMsgType) {
        setShowToast(true);
        setToastMessage({
          type: "error",
          message: "Unsupported Type",
        });

        setPastedAttachment(null);
        return;
      }

      const formData = new FormData();

      formData.append("file", pastedAttachment?.imageFile);
      formData.append("whatsappMsgType", whatsappMsgType);
      formData.append("clientId", user_id);

      if (replyTo?.msgId) {
        formData.append("replyTo", replyTo?.msgId);
      }

      sendAttachmentMsg(formData);
    }

    function getWhatsappMsgs(pageNo) {
      mutate(() =>
        api.post(socketUrl + "/messages/get", { clientId: user_id, pageNo })
      );
    }

    // useEffect(() => {
    //   getWhatsappMsgs(1);

    // if (socket != null) {
    //   socket.on("wt-message", (msg) => {
    //     if (msg.client_id === user_id) {
    //       setMessages((prev) => [...prev, msg]);
    //     }
    //   });

    //   getUsers(2);

    //   return () => {
    //     socket.off("wt-message");
    //   };
    // }
    // }, []);

    useEffect(() => {
      setPage(1);
      setHasMore(true);
      setMessages([]);

      setTimeout(() => {
        if (user_id) {
          getWhatsappMsgs(1);
        }
      }, 500);
    }, [user_id]);

    const groupedMessages = useMemo(
      () => getMessagesPerDate(messages),
      [messages]
    );

    useEffect(() => {
      if (onTicketCreation) {
        console.log("onTicketCreation", onTicketCreation);
        const { topic, ticket_id } = onTicketCreation;
        const msg = `Ticket created successfully, \n Click here to see the full details: ${window.location.origin}/ticket-detail/${ticket_id}`;

        sendTextMsg(() =>
          api.post(socketUrl + "/whatsapp/send-msg", {
            clientId: user_id,
            msg,
          })
        );
      }
    }, [onTicketCreation, user_id]);

    const sendBtnLoading = pastedAttachment
      ? isAttachmentLoading
      : isSendLoading;

    return (
      <>
        <div
          // className="flex flex-col flex-grow custom-scroll min-h-[100px] userchat-area p-4 overflow-auto overflow-x-hidden"
          ref={messagesRef}
          className="overflow-auto p-4"
          style={{ height: getContentHeight() }}
        >
          {loading && page === 1 ? (
            <div className="flex items-center justify-center h-[50vh]">
              <Spinner />
            </div>
          ) : (
            <>
              <LoadMoreBtn
                loading={loading}
                hasMore={hasMore}
                onLoad={() => {
                  setPage((prev) => prev + 1);
                  getWhatsappMsgs(page + 1);
                }}
              />

              {Object.entries(groupedMessages).map(([date, messages]) => (
                <div key={date}>
                  <h2 className="mb-5 w-full relative flex items-center">
                    <div className=" bg-gradient-to-t from-transparent via-transparent to-[rgba(9,30,66,.12)] h-1 bg-repeat-x bg-bottom bg-no-repeat w-[40%]"></div>
                    <span className="text-sm font-bold px-3">{date}</span>
                    <div className="bg-gradient-to-t from-transparent via-transparent to-[rgba(9,30,66,.12)] h-1 bg-repeat-x bg-bottom bg-no-repeat w-[40%]"></div>
                  </h2>

                  <DisplayMsgs
                    messages={messages}
                    userDetails={props.userDetails}
                    setReplyTo={setReplyTo}
                    currCommunityId={currCommunityId}
                    setOpenAttachmentPreview={setOpenAttachmentPreview}
                  />
                </div>
              ))}
            </>
          )}

          {/* <div ref={messagesEndRef} /> */}
        </div>
        <div
          id="chat-box-footer"
          className="px-4 py-3 bg-gray-100 border-t border-gray-300 rounded-b-md flex flex-col justify-center items-center"
        >
          {console.log("replyTo", replyTo)}
          {replyTo.msgId ? (
            <div className="p-4 border text-xs flex justify-between bg-white w-full rounded border border-l-4 border-[#1a1838]">
              <div>
                <div className="font-semibold py-1 text-blue-400">
                  {replyTo?.msgFrom}
                </div>
                <div className="text-sm">{replyTo?.msg}</div>
              </div>

              <i
                className="fa-solid fa-xmark text-xl text-gray-700 cursor-pointer hover:text-gray-500"
                onClick={() =>
                  setReplyTo({
                    msgId: "",
                    msgFrom: "",
                    msg: "",
                  })
                }
              ></i>
            </div>
          ) : null}

          <form
            className="w-full flex py-2 border-t items-center"
            onSubmit={(e) => {
              e.preventDefault();

              if (pastedAttachment) {
                sendPastedAttachment();
                return;
              }

              sendMsgText(e);
            }}
          >
            {pastedAttachment ? (
              <AttachmentThumpnail
                attachments={[pastedAttachment]}
                customCls="border !h-fit w-full rounded text-sm"
                onRemove={() => setPastedAttachment(null)}
              />
            ) : (
              <input
                className="input flex items-center border h-10 w-full rounded px-3 text-sm"
                type="text"
                placeholder="Type your message…"
                value={newMessage}
                required
                onChange={(e) => setNewMessage(e.target.value)}
                onPaste={onPaste}
              />
            )}

            <button
              type="submit"
              className="text-[14px] ml-2 inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-10 px-8 py-2"
              disabled={sendBtnLoading}
            >
              {sendBtnLoading ? <Loading /> : "Send"}
            </button>

            {pastedAttachment ? null : (
              <Attchment
                onFileSubmit={(file, type) => {
                  const formData = new FormData();

                  formData.append("file", file);
                  formData.append("whatsappMsgType", type);
                  formData.append("clientId", user_id);

                  if (replyTo?.msgId) {
                    formData.append("replyTo", replyTo?.msgId);
                  }

                  sendAttachmentMsg(formData);
                }}
                isLoading={isAttachmentLoading}
              />
            )}
          </form>
        </div>
      </>
    );
  }
);

export default WhatsappChat;

function getReplyMsg(reply_to, messages, client_phone_no, guestName) {
  if (!reply_to) {
    return {};
  }

  const message = reply_to;

  const isGuestMsg = Number(message?.from_number) === Number(client_phone_no);

  const { whatsapp_msg_type, msg } = message;
  // console.log("whatsapp_msg_type", whatsapp_msg_type, msg, reply_to);

  const textMsg = msg?.text?.body;

  let attachment = msg[whatsapp_msg_type];

  const { filename = "Attachment" } = attachment;

  const data = {
    msgFrom: isGuestMsg ? guestName : "You",
    msg: textMsg || filename,
    reply_to,
  };

  return data;
}

function DisplayMsgs({
  messages,
  userDetails = {},
  setReplyTo,
  currCommunityId,
  setOpenAttachmentPreview,
}) {
  const [msgHovering, setMsgHovering] = useState();

  const { client_name, client_phone_no } = userDetails;

  function onReplyTo(msgId, msg, msgFrom) {
    setReplyTo({
      msgId,
      msg,
      msgFrom,
    });
  }

  const scrollToElement = (id) => {
    const element = document.getElementById(id);

    console.log("element", element);

    if (element) {
      // element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {messages.map((message, i) => {
        const {
          client_id,
          from_number,
          to_number,
          whatsapp_msg_type,
          whatsapp_msg_id,
          msg,
          created_at,
          reply_to,
        } = message;

        // console.log("reply_to", message);

        const textMsg = msg?.text?.body;

        let attachment = msg[whatsapp_msg_type];

        const { id, filename = "Attachment", mime_type } = attachment;

        const isGuestMsg = Number(from_number) === Number(client_phone_no);

        const guestNameLogo =
          userDetails && client_name ? Array.from(client_name)[0] : "G";

        const guestName =
          userDetails && client_name ? Array.from(client_name) : "Guest";

        return (
          <div
            key={message.id}
            className={`flex w-full mt-2 space-x-3 max-w-xs items-center ${
              isGuestMsg ? "" : "justify-end ml-auto"
            }`}
            onMouseOver={() => setMsgHovering(i)}
            onMouseLeave={() => setMsgHovering(null)}
            id={whatsapp_msg_id}
          >
            {isGuestMsg ? (
              <>
                <div className="mr-2 flex text-black justify-center items-center flex-shrink-0 h-10 w-10 rounded-full bg-gray-100">
                  {guestNameLogo}
                </div>

                <DisplayMsgContent
                  whatsapp_msg_type={whatsapp_msg_type}
                  isGuestMsg={isGuestMsg}
                  textMsg={textMsg}
                  filename={filename}
                  id={id}
                  created_at={created_at}
                  replyData={getReplyMsg(
                    reply_to,
                    messages,
                    client_phone_no,
                    guestName
                  )}
                  scrollToElement={scrollToElement}
                  currCommunityId={currCommunityId}
                  setOpenAttachmentPreview={setOpenAttachmentPreview}
                  mime_type={mime_type}
                />

                {msgHovering === i && (
                  <i
                    className="fa-solid fa-reply mr-2 cursor-pointer"
                    onClick={() =>
                      onReplyTo(
                        whatsapp_msg_id,
                        textMsg || filename,
                        client_name || "Guest"
                      )
                    }
                  ></i>
                )}
              </>
            ) : (
              <>
                {msgHovering === i && (
                  <i
                    className="fa-solid fa-reply ml-2 cursor-pointer"
                    onClick={() =>
                      onReplyTo(whatsapp_msg_id, textMsg || filename, "You")
                    }
                  ></i>
                )}

                <DisplayMsgContent
                  whatsapp_msg_type={whatsapp_msg_type}
                  isGuestMsg={isGuestMsg}
                  textMsg={textMsg}
                  filename={filename}
                  id={id}
                  created_at={created_at}
                  replyData={getReplyMsg(
                    reply_to,
                    messages,
                    client_phone_no,
                    guestName
                  )}
                  scrollToElement={scrollToElement}
                  currCommunityId={currCommunityId}
                  setOpenAttachmentPreview={setOpenAttachmentPreview}
                  mime_type={mime_type}
                />

                <div className="flex justify-center items-center flex-shrink-0 h-10 w-10 rounded-full bg-gray-100">
                  <i className="fa-solid fa-user bg-gray-100 p-2 rounded-full "></i>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
}

function DisplayMsgContent({
  whatsapp_msg_type,
  isGuestMsg,
  textMsg,
  filename,
  id,
  created_at,
  replyData,
  scrollToElement,
  currCommunityId,
  setOpenAttachmentPreview,
  mime_type,
}) {
  const attachmentURL = `${socketURL}/messages/download-attachment?id=${id}&filename=${filename}&whatsappMsgType=${whatsapp_msg_type}&community_id=${currCommunityId}`;

  return (
    <div>
      {replyData?.msgFrom ? (
        <button
          className="border mb-1 text-xs rounded-lg w-full text-left"
          // onClick={() => scrollToElement(replyData.reply_to)}
        >
          <div className="px-2 py-1 border-l-2 border-blue-400 rounded-lg">
            <div className="font-semibold py-1 text-blue-400">
              {replyData.msgFrom}
            </div>
            <div className="text-sm">{replyData.msg}</div>
          </div>
        </button>
      ) : null}

      {whatsapp_msg_type === "text" ? (
        <div
          className={`${
            isGuestMsg ? "bg-gray-100" : "bg-[#eff9ff]"
          } px-3 py-2 text-grey p-3 rounded-l-lg rounded-br-lg`}
        >
          <p className="text-sm">{textMsg}</p>
        </div>
      ) : (
        <div className="flex flex-col w-full mt-2 space-x-1  ml-auto text-sm text-blue-500 underline">
          <div className="flex items-center justify-between three-dots bg-indigo-50  text-indigo-500 p-1">
            <div
              className="cursor-pointer pl-2 truncate"
              onClick={() =>
                setOpenAttachmentPreview({
                  link: attachmentURL,
                  type:
                    MIME_TYPE_TO_EXTENSION_MAPPING[mime_type] ||
                    mime_type.split("/")[1],
                })
              }
            >
              {filename || "Attachment"}
            </div>

            <a href={attachmentURL} download="download" target="__blank">
              <i class="fa-solid fa-download mr-2"></i>
            </a>
          </div>
        </div>
      )}

      <i
        className={`text-[13px] font-normal flex w-full max-w-xs pt-1 text-gray-400 ${
          isGuestMsg ? "pl-2 " : "justify-end ml-auto pr-2"
        }`}
      >
        {moment(created_at).format("hh:mm A")}
      </i>
    </div>
  );
}

const popupContent = [
  {
    type: "image",
    label: "Images",
    Img: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        stroke="currentColor"
      >
        <path
          d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z"
          fill="currentColor"
        />
      </svg>
    ),
    supportedTypes: "image/jpeg, image/png",
    sizeLimit: 5,
  },
  {
    type: "document",
    label: "Documents",
    Img: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        stroke="currentColor"
      >
        <path
          d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"
          fill="currentColor"
        />
      </svg>
    ),

    supportedTypes:
      "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    sizeLimit: 100,
  },
  {
    type: "video",
    label: "Videos",
    Img: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        stroke="currentColor"
      >
        <path
          d="m160-800 80 160h120l-80-160h80l80 160h120l-80-160h80l80 160h120l-80-160h120q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800Zm0 240v320h640v-320H160Zm0 0v320-320Z"
          fill="currentColor"
        />
      </svg>
    ),
    supportedTypes: "video/mp4, video/3gp",
    sizeLimit: 16,
  },
];

function Attchment({ onFileSubmit, isLoading }) {
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleFileChange = (event, sizeLimit, type) => {
    const file = event.target.files[0];

    if (file && file.size > sizeLimit * 1024 * 1024) {
      setError(`File size should be less than ${sizeLimit}MB.`);
      return;
    }
    setError("");
    onFileSubmit(file, type);
    setIsOpen(false);
  };

  return (
    <div className="relative flex justify-center items-center">
      <button
        className="bg-white border border-gray-300 ml-2 hover:bg-indigo-500 hover:text-white duration-300 ease px-2 py-2 rounded z-10"
        onClick={togglePopup}
        type="button"
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner customStyle={{ height: 22, width: 22 }} />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            fill="currentcolor"
          >
            <path d="M460-80q-92 0-156-64t-64-156v-420q0-66 47-113t113-47q66 0 113 47t47 113v380q0 42-29 71t-71 29q-42 0-71-29t-29-71v-380h60v380q0 17 11.5 28.5T460-300q17 0 28.5-11.5T500-340v-380q0-42-29-71t-71-29q-42 0-71 29t-29 71v420q0 66 47 113t113 47q66 0 113-47t47-113v-420h60v420q0 92-64 156T460-80Z" />
          </svg>
        )}
      </button>

      {isOpen && (
        <div className="absolute bottom-12 right-0 bg-white border border-gray-300 p-4 rounded shadow-lg z-10 w-[350px]">
          <div className="grid grid-cols-2 gap-2">
            {popupContent.map((item, index) => (
              <div className="">
                <div className="flex items-center">
                  <label className="cursor-pointer py-2 px-4 rounded-lg hover:text-blue-400 flex">
                    {<item.Img />} {item.label}
                    <input
                      type="file"
                      accept={item.supportedTypes}
                      className="hidden"
                      onChange={(e) =>
                        handleFileChange(e, item.sizeLimit, item.type)
                      }
                    />
                  </label>
                </div>
                {error && <div className="mt-2 text-red-500">{error}</div>}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
