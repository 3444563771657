import InputWrapper from "../../../common/InputWrapper";
import DisplayMails from "../DisplayMails";
import TemplateCard from "../TemplateCard";

function PreviewMail({ selectedUsers, setSelectedUsers, template }) {
  return (
    <div>
      <InputWrapper label="Bcc">
        <DisplayMails
          to={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          isPreview={true}
        />
      </InputWrapper>

      <div className="mt-6">
        <TemplateCard template={template} />
      </div>
    </div>
  );
}

export default PreviewMail;
