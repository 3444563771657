import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import ApiRow from "./ApiRow";

const ApiToken = (props) => {
  const [processing, setProcessing] = useState(false);
  const [apiData, setApiData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    var postData = {
      communityId: id,
    };
    api.post("/api/getAccessToken", postData).then((response) => {
      // console.log(response);
      setProcessing(false);
      if (response.data.status == "success") {
        setApiData(response.data.data);
      }
    });
  }, []);
  const generateKey = () => {
    var postData = {
      communityId: id,
    };
    setProcessing(true);
    api.post("/api/createAccessToken", postData).then((response) => {
      // console.log(response);
      setProcessing(false);
      if (response.data.status == "success") {
        setApiData(response.data.data);
      }
    });
  };

  const updateStatus = (hashedId, status) => {
    var postData = {
      id: hashedId,
      communityId: id,
      status: status,
    };
    setProcessing(true);
    api.post("/api/updateAccessToken", postData).then((response) => {
      // console.log(response);
      setProcessing(false);
      if (response.data.status == "success") {
        setApiData(response.data.data);
      }
    });
  };

  return (
    <>
      <div className="px-2  py-3">
        <div className="communityMembers">
          <div className="relative sm:rounded-md">
            <table className={`my-6 border table-auto w-full text-left`}>
              <thead>
                <tr>
                  <th scope="col" className="bg-gray-100 p-4">
                    <div className="flex gap-1">
                      <span>Key</span>
                    </div>
                  </th>
                  <th scope="col" className="bg-gray-100 p-4">
                    Status
                  </th>
                  <th scope="col" className="bg-gray-100 p-4">
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody>
                {apiData?.map((data) => (
                  <ApiRow data={data} updateStatus={updateStatus} />
                ))}
              </tbody>
            </table>
            <div>
              <button
                className="px-4 py-3 border border-gray-300 rounded-md text-indigo-600 font-medium flex items-center"
                disabled={processing}
                onClick={() => generateKey()}
              >
                {processing && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                <img
                  src="/images/icons/add-circle-blue.svg"
                  alt="+"
                  className="mr-3"
                />
                Create New Token
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiToken;
