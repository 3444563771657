import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

const isValidEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
};

function SelectEmails({
  options = [],
  defaultValues = [],
  handleChange,
  required = false,
  isMulti = true,
  onSearch,
}) {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [error, setError] = useState("");

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
    onSearch(inputValue);
  };

  useEffect(() => {
    if (defaultValues?.length) {
      setSelectedEmails(defaultValues);
    }
  }, []);

  return (
    <div>
      <CreatableSelect
        className="h-11  focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
        isMulti={isMulti}
        onChange={(values, actions) => {
          if (actions?.action === "remove-value") {
            setSelectedEmails(values);
            handleChange(values);
            return;
          }
          if (isValidEmail(values.value)) {
            setSelectedEmails(values);
            handleChange(values);
            setError(false);
          } else {
            setError(true);
          }
        }}
        onInputChange={handleInputChange}
        defaultValue={defaultValues}
        options={options}
        value={selectedEmails}
        formatCreateLabel={(inputValue) => `Add: ${inputValue}`}
        required={required}
      />

      {error && <div className="my-2 text-red-600">Invalid Email</div>}
    </div>
  );
}

export default SelectEmails;
