import { useState } from "react";
import Button from "../../../../common/Button";

export function checkDataType(value) {
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    typeof value === "undefined" ||
    typeof value === "symbol"
  ) {
    return "Primitive";
  } else if (typeof value === "object") {
    if (Array.isArray(value)) {
      return "Array";
    } else if (value instanceof Function) {
      return "Function";
    } else {
      return "Object";
    }
  } else {
    return "Unknown";
  }
}

export const MAP_RESPONSE_TYPE = {
  Primitive: DisplayPrimitiveData,
  Array: DisplayArrayData,
  Object: DisplayObjectData,
};

export function DisplayPrimitiveData(props) {
  const { dataKey, value, parentKey, onUpdateKey, usingParameters } = props;

  const isAdded = usingParameters.includes(parentKey);

  const addIconCls = isAdded ? "fa-circle-minus" : "fa-circle-plus";

  return (
    <div className="flex items-center space-x-2">
      <Button
        className="flex items-center bg-gray-600 px-4 py-2 rounded-md ml-7"
        onClick={() => {
          onUpdateKey(parentKey, !isAdded);
        }}
      >
        {dataKey}

        <i className={`fa-solid ${addIconCls} ml-2`}></i>
      </Button>

      <div className="text-blue-400">:</div>

      <div className="text-orange-500">{value}</div>
    </div>
  );
}

export function DisplayArrayData(props) {
  const { dataKey, value, parentKey, onUpdateKey, usingParameters } = props;

  const [expand, setExpand] = useState(false);

  const isAdded = usingParameters.includes(parentKey);

  const addIconCls = isAdded ? "fa-circle-minus" : "fa-circle-plus";

  const expandIconCls = expand
    ? "fa-circle-chevron-down"
    : "fa-circle-chevron-right";

  return (
    <div className={`${expand ? "border-b border-gray-500 w-fit" : ""} `}>
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2">
          <i
            className={`fa-solid ${expandIconCls} cursor-pointer text-xl`}
            onClick={() => setExpand(!expand)}
          ></i>

          <Button
            className="flex items-center bg-gray-600 px-4 py-2 rounded-md"
            onClick={() => {
              onUpdateKey(parentKey, !isAdded);
            }}
          >
            {dataKey}

            <i className={`fa-solid ${addIconCls} ml-2`}></i>
          </Button>
        </div>

        <div className="text-blue-400">:</div>

        <div className="text-orange-500">{value.length} items</div>
      </div>
      {expand ? (
        <>
          {value.map((val, index) => {
            const Comp = MAP_RESPONSE_TYPE[checkDataType(val)];

            if (!Comp) {
              return "Unknown";
            }

            return (
              <div key={index} className="my-3">
                <Comp
                  dataKey={index}
                  value={val}
                  parentKey={parentKey + "[]"}
                  onUpdateKey={onUpdateKey}
                  usingParameters={usingParameters}
                />
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
}

export function DisplayObjectData(props) {
  const {
    dataKey,
    value: valueData,
    parentKey,
    onUpdateKey,
    usingParameters,
  } = props;

  const [expand, setExpand] = useState(false);

  const isAdded = usingParameters.includes(parentKey);

  const addIconCls = isAdded ? "fa-circle-minus" : "fa-circle-plus";

  const expandIconCls = expand
    ? "fa-circle-chevron-down"
    : "fa-circle-chevron-right";

  return (
    <div className={`${expand ? "border-b border-gray-500 w-fit" : ""} `}>
      {" "}
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2">
          <i
            className={`fa-solid ${expandIconCls} cursor-pointer text-xl`}
            onClick={() => setExpand(!expand)}
          ></i>

          <Button
            className="flex items-center bg-gray-600 px-4 py-2 rounded-md"
            onClick={() => {
              onUpdateKey(parentKey, !isAdded);
            }}
          >
            {dataKey}

            <i className={`fa-solid ${addIconCls} ml-2`}></i>
          </Button>
        </div>

        <div className="text-blue-400">:</div>

        <div className="text-orange-500">object</div>
      </div>
      {expand ? (
        <>
          {Object.entries(valueData).map(([key, value], index) => {
            const Comp = MAP_RESPONSE_TYPE[checkDataType(value)];

            if (!Comp) {
              return "Unknown";
            }

            return (
              <div key={index} className="my-3">
                <Comp
                  dataKey={key}
                  value={value}
                  parentKey={parentKey + "." + key}
                  onUpdateKey={onUpdateKey}
                  usingParameters={usingParameters}
                />
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
}
