const SignInCommon = () => {
  return (
    <div className="bg-no-repeat col-span-2 bg-cover flex justify-center items-center front-bg">
      <div className="signup-cnt">
        <div className="text-3xl text-white text-center font-semibold">
          <div className="">Welcome to</div>
          <div className="text-[#DFBD46]">AI Powered CRM</div>
        </div>

        <div className="mt-6 signup-img flex justify-center text-center gap-4">
          <img src="/images/star-bg.svg" alt="-" />
        </div>
      </div>
    </div>
  );
};

export default SignInCommon;
