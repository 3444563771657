import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { checkLabelLevel } from "../../hooks/commonHelper";

function getChoices(choices = []) {
  const options = [];

  choices.forEach((c) => {
    if (c.label === null || c.label === "") {
      return;
    }

    options.push({
      label: c.label,
      value: c.label,
    });
  });

  return options;
}

function DependencyDropdown({
  field,
  selectedValues = {},
  handleChange,
  fromApprovalPage,
  depenedencyError,
}) {
  const { field_name, field_values } = field;
  const { choices = [] } = field_values;
  const [num, setNum] = useState(1);

  useEffect(() => {
    const maxLevelOptions = checkLabelLevel(field_values);
    setNum(maxLevelOptions);
  }, []);

  const levelsData = useMemo(() => {
    const data = [];
    for (let i = 1; i <= num; i++) {
      data.push(selectedValues[`level${i}`]);
    }
    return data;
  }, [selectedValues, num]);

  const levelsOptions = useMemo(() => {
    const options = [];
    let currentChoices = choices;
    for (let i = 0; i < num; i++) {
      const currentLevel = i + 1;
      const levelOptions = getChoices(currentChoices);
      options.push(levelOptions);

      const selectedValue = selectedValues[`level${currentLevel}`];
      if (selectedValue) {
        const nextChoices = currentChoices.find(
          (c) => c.label === selectedValue
        )?.choices;
        currentChoices = nextChoices || [];
      } else {
        currentChoices = [];
      }
    }
    return options;
  }, [choices, selectedValues, num]);

  return (
    <div className="border-l border-gray-400">
      {Array.from({ length: num }, (_, index) => {
        const level = index + 1;
        const levelData = levelsData[index];
        const levelOptions = levelsOptions[index];

        const shouldDisplay =
          level === 1 ||
          (levelsData[level - 2] && levelsOptions[level - 1].length > 0);

        return (
          shouldDisplay && (
            <div className="flex items-center" key={level}>
              {level > 1 && (
                <div
                  className="text-gray-400"
                  style={{ width: `${10 * level}px` }}
                >
                  {"-".repeat(level)}
                </div>
              )}
              <SelectData
                label={field_name[`level${level}`]}
                options={levelOptions}
                onChange={(value) => {
                  const val = value?.value;

                  const newSelectedValues = { ...selectedValues };
                  for (let i = level; i <= num; i++) {
                    delete newSelectedValues[`level${i}`];
                  }

                  if (val || val === 0) {
                    newSelectedValues[`level${level}`] = val;
                  }

                  handleChange(newSelectedValues);
                }}
                value={
                  levelData ? { label: levelData, value: levelData } : null
                }
                level={level}
                fromApprovalPage={level === 1 ? fromApprovalPage : false}
                error={depenedencyError}
              />
            </div>
          )
        );
      })}
    </div>
    //   <div className="border-l border-gray-400">
    //   {level1Options?.length ? (
    //     <SelectData
    //       label={field_name.level1}
    //       options={level1Options}
    //       onChange={(value) => {
    //         const val = value?.value;

    //         if (!val && val !== 0) {
    //           handleChange({});
    //           return;
    //         }

    //         handleChange({ level1: val });
    //       }}
    //       value={level1Data ? { label: level1Data, value: level1Data } : null}
    //       customCls="ml-2"
    //       level={1}
    //       fromApprovalPage={fromApprovalPage}
    //       error={depenedencyError}
    //     />
    //   ) : null}

    //   {level1Data && level2Options?.length ? (
    //     <div className="flex items-center">
    //       <div className="text-gray-400 w-8">---</div>
    //       <SelectData
    //         label={field_name.level2}
    //         options={level2Options}
    //         onChange={(value) => {
    //           const val = value?.value;

    //           if (!val && val !== 0) {
    //             const prevData = selectedValues;

    //             delete prevData.level3;

    //             delete prevData.level2;

    //             handleChange(prevData);

    //             return;
    //           }

    //           const prevData = selectedValues;

    //           delete prevData.level3;

    //           prevData.level2 = val;

    //           handleChange(prevData);
    //         }}
    //         value={level2Data ? { label: level2Data, value: level2Data } : null}
    //         level={2}
    //         fromApprovalPage={false}
    //         error={depenedencyError}
    //       />
    //     </div>
    //   ) : null}

    //   {level1Data && level2Data && level3Options?.length ? (
    //     <div className="flex items-center">
    //       <div className="text-gray-400 w-14">-----</div>
    //       <SelectData
    //         label={field_name.level3}
    //         options={level3Options}
    //         onChange={(value) => {
    //           const val = value?.value;

    //           if (!val && val !== 0) {
    //             const prevData = selectedValues;

    //             delete prevData.level3;

    //             handleChange(prevData);

    //             return;
    //           }

    //           const prevData = selectedValues;

    //           prevData.level3 = val;

    //           handleChange(prevData);
    //         }}
    //         value={level3Data ? { label: level3Data, value: level3Data } : null}
    //         level={3}
    //         fromApprovalPage={false}
    //         error={depenedencyError}
    //       />
    //     </div>
    //   ) : null}
    // </div>
  );
}

export default DependencyDropdown;

function SelectData({
  options = [],
  value = {},
  defaultValue,
  onChange,
  label,
  customCls = "w-full",
  level,
  fromApprovalPage,
  error,
}) {
  let isRequired =
    (fromApprovalPage && level === 1) || (!fromApprovalPage && level !== 1);
  // for from approval page only level 1 field is required, whereas for others, only level 2 & 3 fields are required
  return (
    <div className={customCls} style={{ marginLeft: "5px" }}>
      <div
        className={`mb-2 font-semibold text-gray-700 ${
          isRequired ? "required-input" : ""
        }`}
      >
        {label}
      </div>

      <Select
        className="capitalize input-md text-sm focus:ring-indigo-600 focus-within:ring-indigo-600 bg-white focus-within:border-indigo-600 mb-4 focus:border-indigo-600"
        options={[...options]}
        isClearable
        value={value}
        onChange={onChange}
        placeholder="select"
        // required={isRequired}
      />
      {error && <div className="form-error">{error[`level${level}`]}</div>}
    </div>
  );
}
