import Button from "../../../../common/Button";

const payloadTypes = [
  {
    label: "JSON",
    value: "JSON",
  },
  {
    label: "Form",
    value: "FORM",
  },
];

function SelectPayloadType({ payloadType, setPayloadType }) {
  return (
    <div className="flex items-center space-x-2 text-gray-500 font-semibold">
      <div className="my-2">Payload type</div>

      <div className="flex p-2 bg-gray-100 rounded-md w-fit">
        {payloadTypes.map((p) => {
          const { label, value } = p;

          const isActive = value === payloadType;

          return (
            <Button
              className={`w-20 py-2 rounded-md ${
                isActive ? "bg-indigo-600 text-white" : ""
              }`}
              onClick={() => setPayloadType(value)}
            >
              {label}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default SelectPayloadType;
