import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";
import api from "../../api/api";
import { json, useParams } from "react-router-dom";
import ChatBot from "../../components/chatBot/ChatBot";
import DropFileInput from "../../components/drop-file-input/DropFileInput";
import FormBuilderPage from "../../components/module/FormBuilderPage";
import PromptFormPage from "../../components/module/PromptFormPage";
import FormPreviewPage from "../../components/module/FormPreviewPage";
import Toast from "../../components/common/Toast";

const BuildModule = () => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [folderDetails, setFolderDetails] = useState([]);
  const [gptKey, setGptKey] = useState("");
  const [userPrompt, setUserPrompt] = useState("");
  const [inputList, setInputList] = useState([]);
  const [fields, setFields] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    getFolderDetails();
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getFolderDetails = () => {
    var postData = {
      id: id,
      community_id: currCommunityId,
    };
    api
      .post("/api/moduleDetails", postData)
      .then((response) => {
        if (response.data.data != null) {
          // console.log(response.data);
          setFolderDetails([response.data.data]);
          setUserPrompt(response.data.data.prompt);
          setGptKey(response.data.data.gpt_key);
          setFieldValue(response.data.modulefield);
        } else {
          setShowToast(true);
          setToastMessage({ type: "error", message: "Chat Bot Not Found" });
          setFolderDetails([]);
        }
      })
      .catch((error) => {
        setFolderDetails([]);
      });
  };

  const setFieldValue = (value) => {
    setFields(value);
  };

  const setOpenTabValue = (value) => {
    setOpenTab(value);
  };

  const setUserPromptValue = (value) => {
    // // console.log("userprompt 1", value);
    setUserPrompt(value);
  };

  const formBuilderSubmit = (e) => {
    setProcessing(true);

    if (openTab == 1) {
      // console.log("new", fields);

      var postData = {
        id: id,
        fields: fields,
        step: 1,
      };
      api
        .post("/api/updateFields", postData)
        .then((response) => {
          if (response.data.data != null) {
            setProcessing(false);
            setOpenTabValue(2);
          } else {
            setShowToast(true);
            setToastMessage({ type: "error", message: "Module Not Found" });
          }
        })
        .catch((error) => {
          setProcessing(false);
          setShowToast(true);
          setToastMessage({ type: "error", message: "Something Went Wrong" });
        });
    }
    if (openTab == 2) {
      var postData = {
        id: id,
        prompt: userPrompt,
        step: 2,
      };
      api
        .post("/api/updateFields", postData)
        .then((response) => {
          if (response.data.data != null) {
            setProcessing(false);
            setShowToast(true);
            setToastMessage({ type: "success", message: "Prompt Saved" });
            // setOpenTab(3);
          } else {
            setShowToast(true);
            setToastMessage({ type: "error", message: "Module Not Found" });
          }
        })
        .catch((error) => {
          setProcessing(false);
          setShowToast(true);
          setToastMessage({
            type: "error",
            message: "Something Went Wrong",
          });
        });
    }
  };

  return (
    <div className="container">
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            {folderDetails.length > 0 ? (
              <div className="h-full flex flex-col">
                {openTab == 1 && (
                  <FormBuilderPage
                    setFieldValue={setFieldValue}
                    fieldValue={fields}
                    processing={processing}
                    formBuilderSubmit={formBuilderSubmit}
                  />
                )}
                {openTab == 2 && (
                  <PromptFormPage
                    folderDetails={folderDetails}
                    setFieldValue={setFieldValue}
                    fieldValue={fields}
                    processing={processing}
                    formBuilderSubmit={formBuilderSubmit}
                    setOpenTabValue={setOpenTabValue}
                    setUserPromptValue={setUserPromptValue}
                    userPromptValue={userPrompt}
                  />
                )}
                {openTab == 3 && (
                  <FormPreviewPage
                    folderDetails={folderDetails}
                    setFieldValue={setFieldValue}
                    fieldValue={fields}
                    processing={processing}
                    formBuilderSubmit={formBuilderSubmit}
                    setOpenTabValue={setOpenTabValue}
                    setUserPromptValue={setUserPromptValue}
                    userPromptValue={userPrompt}
                  />
                )}
              </div>
            ) : (
              <div className="h-full flex flex-col items-center justify-center">
                <div className="text-center">
                  <img
                    src={require("../../assets/images/welcome.png")}
                    alt="Welcome"
                    className="mx-auto mb-8"
                  />
                  <h3 className="mb-2">Chatbot Not Found</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildModule;
