import { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../App";
import DropFileInput from "../../components/drop-file-input/DropFileInput";
import api from "../../api/api";
import useCommunityDetails, {
  redirectToOrigin,
} from "../../hooks/useCommunityDetails";

const JoinCommunity = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [logo, setLogo] = useState(false);
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [userName, setUserName] = useState();
  const [userNameError, setUserNameError] = useState("");
  const {
    setCurrCommunityId,
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    setShowCommunityModal,
    onError,
  } = useContext(AppContext);
  const { id } = useParams();
  const [passwordType, setPasswordType] = useState({
    password: "password",
    confirmPassword: "password",
  });
  const location = useNavigate();
  const locationData = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(locationData.search);
    const userEmail = queryParams.get("email");
    setEmail(userEmail);
  }, []);
  const { getCommunityDetails, loading } = useCommunityDetails();

  const togglePassword = (type) => {
    setPasswordType((prevState) => ({
      ...prevState,
      [type]: prevState[type] === "password" ? "text" : "password",
    }));
  };

  const validation = (name, value, error = null) => {
    if (name === "email") {
      if (error != null) {
        setEmailError("This Field is required");
      } else {
        if (value !== "") {
          setEmailError("");
          setEmail(value);
        } else {
          setEmail("");
          setEmailError("This field is required");
        }
      }
    }

    if (name === "password") {
      if (error != null) {
        setPasswordError("This Field is required");
      } else {
        if (value !== "") {
          setPasswordError("");
          setPassword(value);
        } else {
          setPassword("");
          setPasswordError("This field is required");
        }
      }
    }

    if (name === "username") {
      if (error !== null) {
        setUserNameError(error);
      } else {
        if (value !== "") {
          setUserName(value);
          setUserNameError("");
        } else {
          setUserName("");

          setUserNameError("Please enter your username");
        }
      }
    }

    if (name === "confirmPassword") {
      if (error != null) {
        setConfirmPasswordError("This Field is required");
      } else {
        if (value !== "") {
          setConfirmPasswordError("");
          setConfirmPassword(value);
        } else {
          setConfirmPassword("");
          setConfirmPasswordError("This field is required");
        }
      }
    }

    if (confirmPassword != "" && password != "") {
      if (confirmPassword != password) {
        // setConfirmPasswordError(
        //   "The Password and confirm password Didn't Match"
        // );
      } else {
        setConfirmPasswordError("");
      }
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    // // console.log(e.target.name, e.target.value);
    validation(e.target.name, e.target.value);
  };

  const joinCommunity = (e) => {
    e.preventDefault();

    var payload = {
      email: email,
      password: password,
      username: userName,
      type: "invite-join",
      community_id: id,
    };
    if (!newUser) {
      setProcessing(true);

      api
        .post("/api/joinCommunityController", payload)
        .then((response) => {
          console.log("response joinCommunityController", response);
          setProcessing(false);
          // console.log("response.data.status", response.data.status);
          if (response.data.status == "success") {
            setShowToast(true);
            setToastMessage({
              type: "success",
              message: "Joined to This community",
            });
            // localStorage.setItem("currCommunity", id);
            setCurrCommunityId(id);
            window.location.href = "/signin";

            // getCommunityDetails({
            //   community_id: id,
            // });

            return;
          }
          if (response.data.status == "new_user") {
            setNewUser(true);
          }
        })
        .catch((error) => {
          setProcessing(false);
          if (error.response.data.errors) {
            var errors = error.response.data.errors;
            Object.keys(errors).map((keyName, keyIndex) => {
              validation(keyName, null, errors[keyName][0]);
            });
          }
          const msgData = error?.response?.data?.error || "";

          onError(msgData ? msgData : "Something went wrong while joining...!");
        });
    } else {
      if (confirmPassword != "" && password != "") {
        if (confirmPassword != password) {
          // setConfirmPasswordError(
          //   "The Password and confirm password Didn't Match"
          // );
          return;
        } else {
          setConfirmPasswordError("");
        }
      }
      setProcessing(true);
      api
        .post("/api/auth/createCommunityMember", payload)
        .then((response) => {
          setProcessing(false);
          console.log("response createCommunityMember", response);
          if (response.data.status === "success") {
            localStorage.setItem("jwtToken", response.data.authorisation.token);
            localStorage.setItem("currCommunity", id);
            setCurrCommunityId(id);

            window.location.href = "/";
          }
          // console.log(response.data);
        })
        .catch((error) => {
          setProcessing(false);
          if (error.response.data.errors) {
            var errors = error.response.data.errors;
            Object.keys(errors).map((keyName, keyIndex) => {
              validation(keyName, null, errors[keyName][0]);
            });
          }
        });
    }
  };

  return (
    <>
      <div
        id="defaultModal"
        tabIndex={-1}
        className={`bg-gray-100 shadow-2xl dialog-overlay fixed dialog-container top-0 left-0 right-0 z-[999] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
      >
        <div className="mx-auto relative w-full h-full max-w-lg	 md:h-auto">
          <div className="relative dialog-content bg-white rounded-lg shadow">
            <div className="flex items-start justify-between p-4 rounded-t">
              <h3 className="text-xl font-semibold text-gray-900  ">
                Join to this Community
              </h3>
            </div>
            <form action="#" onSubmit={(e) => joinCommunity(e)}>
              <div className="px-6">
                <div className="mt-4">
                  <div className="form-container vertical">
                    <div className="form-item vertical">
                      <label className="form-label mb-2 required-input">
                        Email
                      </label>
                      <div className>
                        <input
                          className={`${
                            emailError !== "" ? "input-danger" : ""
                          } input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                          type="email"
                          name="email"
                          autoComplete="off"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => handleChange(e)}
                          disabled={newUser}
                        />
                        {emailError !== "" ? (
                          <div className="form-error">{emailError}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {newUser && (
                      <>
                        <div className="form-item vertical">
                          <label className="form-label mb-2 required-input">
                            User Name
                          </label>
                          <div className>
                            <input
                              className={`${
                                userNameError !== "" ? "input-danger" : ""
                              } p-3 w-full input input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                              type="text"
                              name="username"
                              autoComplete="off"
                              placeholder="user name"
                              onChange={(e) => handleChange(e)}
                            />
                            {userNameError !== "" ? (
                              <div className="form-error">{userNameError}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-item vertical">
                          <label className="form-label mb-2 required-input">
                            Password
                          </label>
                          <div className>
                            <span className="input-wrapper w-full">
                              <input
                                type={passwordType.password}
                                className={`${
                                  passwordError !== "" ? "input-danger" : ""
                                } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                name="password"
                                autoComplete="off"
                                placeholder="Password"
                                style={{ paddingRight: "2.25rem" }}
                                onChange={(e) => handleChange(e)}
                              />
                              <div className="input-suffix-end">
                                <span className="cursor-pointer text-xl">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth={0}
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => togglePassword("password")}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </span>
                            {passwordError !== "" ? (
                              <div className="form-error">{passwordError}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-item vertical">
                          <label className="form-label mb-2 required-input">
                            Confirm Password
                          </label>
                          <div className>
                            <span className="input-wrapper w-full">
                              <input
                                type={passwordType.confirmPassword}
                                className={`${
                                  confirmPasswordError !== ""
                                    ? "input-danger"
                                    : ""
                                } p-3 input w-full input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600`}
                                name="confirmPassword"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                style={{ paddingRight: "2.25rem" }}
                                onChange={(e) => handleChange(e)}
                              />
                              <div className="input-suffix-end">
                                <span className="cursor-pointer text-xl">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth={0}
                                    viewBox="0 0 24 24"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      togglePassword("confirmPassword")
                                    }
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                    />
                                  </svg>
                                </span>
                              </div>
                            </span>
                            {confirmPasswordError !== "" ? (
                              <div className="form-error">
                                {confirmPasswordError}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center px-6 pb-6 space-x-2  border-gray-200 rounded-b">
                <button
                  className="inline-flex items-center justify-center button bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white radius-round h-11 px-8 py-2 w-full"
                  type="submit"
                  disabled={loading || processing}
                >
                  {(processing || loading) && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}{" "}
                  Join
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinCommunity;
