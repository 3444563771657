import { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import api from "../../../api/api";
import SideBar from "../../../components/settings/SideBar";
import Spinner from "../../../components/loaders/Spinner";

const Notifications = ({ hideTitle }) => {
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    currCommunityId,
    setInviteModal,
    inviteModal,
  } = useContext(AppContext);
  const [processing, setProcessing] = useState(false);
  const [communityName, setCommunityName] = useState();
  const [image, setImage] = useState();
  const [communityNameError, setCommunityNameError] = useState("");
  const [communityDetails, setCommunityDetails] = useState([]);

  const [communityId, setCommunityId] = useState("");
  const [toggleCheckbox, settoggleCheckbox] = useState([]);

  useEffect(() => {
    getUserInfo();
    var payload = {
      selectedCommunity: currCommunityId,
    };
    setProcessing(true);

    api
      .post("/api/selectedCommunity", payload)
      .then((response) => {
        setProcessing(false);
        if (response.data.status == "success") {
          setCommunityName(response.data.data.name);
          setImage(response.data.data.logo);
          setCommunityDetails(response.data.data);
          settoggleCheckbox(response.data.data.notifications);
        }
      })
      .catch((error) => {});
  }, []);

  const toggleCheckboxChange = (event) => {
    let toggleCheckboxarr = [];
    setProcessing(true);

    if (event.target.checked) {
      settoggleCheckbox((prev) => {
        return [...prev, event.target.value];
      });
      toggleCheckbox[toggleCheckbox.length] = event.target.value;
      toggleCheckboxarr = toggleCheckbox;
      var payload = {
        toggleCheckbox: toggleCheckboxarr,
        selectedCommunity: currCommunityId,
      };
      api
        .post("/api/updateCommunityNotifications", payload)
        .then((response) => {
          setProcessing(false);
          if (response.data.status == "success") {
          }
        })
        .catch((error) => {
          setProcessing(false);
        });
    } else {
      let toggleCheckboxarr = toggleCheckbox.filter(
        (row) => row !== event.target.value
      );
      settoggleCheckbox(toggleCheckboxarr);

      var payload = {
        toggleCheckbox: toggleCheckboxarr,
        selectedCommunity: currCommunityId,
      };
      api
        .post("/api/updateCommunityNotifications", payload)
        .then((response) => {
          setProcessing(false);
          if (response.data.status == "success") {
          }
        })
        .catch((error) => {
          setProcessing(false);
        });
    }
  };

  return (
    <div className={hideTitle ? "" : "container"}>
      <div>
        <div className="page-container relative h-full flex flex-auto flex-col sm:py-6">
          <div className="container mx-auto h-full">
            {hideTitle ? null : (
              <div className="lg:flex items-center justify-between mb-4">
                <h3 className="mb-4 lg:mb-0 main-title">
                  {communityDetails?.name}
                </h3>
              </div>
            )}

            <div className="flex gap-4 text-gray-700">
              {hideTitle ? null : <SideBar />}

              <div className="w-[80%]">
                {hideTitle ? null : (
                  <div className="flex items-center justify-between w-full block">
                    <h3 className="mb-4 text-xl lg:mb-0 font-bold">
                      Notifications
                    </h3>
                  </div>
                )}
                <div className="">
                  <div>
                    <label>Enable Notifications for Tickets:</label>
                    {processing ? (
                      <Spinner />
                    ) : (
                      <div className="ml-4 rtl:mr-4 w-full enable-notification">
                        <div className="w-full mb-4 mt-4">
                          P1
                          <label className="switch ml-4">
                            <input
                              type="checkbox"
                              name="p1_checkbox"
                              value="p1"
                              attr={toggleCheckbox[0]}
                              checked={toggleCheckbox.includes("p1")}
                              onChange={(e) => toggleCheckboxChange(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="w-full mb-4">
                          P2
                          <label className="switch ml-4">
                            <input
                              type="checkbox"
                              name="p2_checkbox"
                              value="p2"
                              checked={toggleCheckbox.includes("p2")}
                              onChange={(e) => toggleCheckboxChange(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="w-full mb-4">
                          P3
                          <label className="switch ml-4">
                            <input
                              type="checkbox"
                              name="p3_checkbox"
                              value="p3"
                              checked={toggleCheckbox.includes("p3")}
                              onChange={(e) => toggleCheckboxChange(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="w-full mb-4">
                          P4
                          <label className="switch ml-4">
                            <input
                              type="checkbox"
                              name="p4checkbox"
                              value="p4"
                              checked={toggleCheckbox.includes("p4")}
                              onChange={(e) => toggleCheckboxChange(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="w-full mb-4">
                          FRD
                          <label className="switch ml-4">
                            <input
                              type="checkbox"
                              name="frd_checkbox"
                              value="frd"
                              checked={toggleCheckbox.includes("frd")}
                              onChange={(e) => toggleCheckboxChange(e)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
