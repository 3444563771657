import { useCallback, useContext, useMemo } from "react";
import api from "../../../../../api/api";
import { AppContext } from "../../../../../App";

function usePropertyConfiguration(botId, flowId) {
  const { currCommunityId } = useContext(AppContext);

  const basePath = useMemo(() => {
    return `/api/${currCommunityId}/${botId}/flow/${flowId}`;
  }, [botId, currCommunityId, flowId]);

  const getPropertyConfigurations = useCallback(() => {
    const url = basePath + "/getVariableConfigurations";

    return () => api.get(url);
  }, [basePath]);

  const createPropertyConfiguration = useCallback(
    (payload) => {
      const url = basePath + "/createVariableConfiguration";

      return () => api.post(url, payload);
    },
    [basePath]
  );

  const updatePropertyConfiguration = useCallback(
    (payload, updateId) => {
      const url = basePath + "/updateVariableConfiguration/" + updateId;

      return () => api.put(url, payload);
    },
    [basePath]
  );

  const deletePropertyConfiguration = useCallback(
    (deleteId) => {
      const url = basePath + "/deleteVariableConfiguration/" + deleteId;

      return () => api.delete(url);
    },
    [basePath]
  );

  return {
    getPropertyConfigurations,
    createPropertyConfiguration,
    updatePropertyConfiguration,
    deletePropertyConfiguration,
  };
}

export default usePropertyConfiguration;
