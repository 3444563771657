import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState, createContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import io from "socket.io-client";
import Sidebar from "./components/common/Sidebar";
import TopNav from "./components/common/TopNav";
import Folders from "./containers/chatbot/Folders";
import TrainChatBot from "./containers/chatbot/TrainChatBot";
import SignIn from "./containers/auth/SignIn";
import SignUp from "./containers/auth/SignUp";
import api from "./api/api";
import ChatBotContainer from "./containers/chatbot/ChatBotContainer";
import Toast from "./components/common/Toast";
import Modules from "./containers/module/Modules";
import Dashboad from "./containers/dashboard/DashboardContainer";
import BuildModule from "./containers/module/BuildModule";
import ModuleContainer from "./containers/module/ModuleContainer";
import MessageContainer from "./containers/message/MessageContainer";
import TicketsContainer from "./containers/tickets/TicketsContainer";
import CommunityDropdown from "./components/community/CommunityDropdown";
import CommunityForm from "./components/community/CommunityFrom";
import CommunitySelection from "./containers/auth/CommunitySelection";
import JoinCommunity from "./components/community/JoinCommunity";
import TicketDetail from "./containers/tickets/TicketDetail";
import TicketClientDetail from "./containers/tickets/TicketClientDetail";
import Integrations from "./containers/settings/integrations/Integrations";
import Notifications from "./containers/settings/notifications/Notifications";
import SignInBlock from "./containers/auth/SignInBlock";
import Profile from "./containers/settings/myProfile/Profile";
import Communities from "./containers/settings/communities/Communities";
import Call from "./components/message/Call";
import CommunityDetail from "./containers/settings/communities/CommunityDetail";
import FaceBookSetupBlock from "./containers/settings/integrations/FaceBookSetupBlock";
import Mails from "./containers/mail/Mails";
import MailDetail from "./components/mail/MailDetail";
import ClockWork from "./containers/clockwork/ClockWork";
import ForgotPassword from "./containers/auth/ForgotPassword";
import ResetPassword from "./containers/auth/ResetPassword";
import UnsavedAlert from "./components/common/UnsavedAlert";
import pkg from "socket.io-msgpack-parser";
import CmContainer from "./containers/customerManagement/CmContainer";
import OfflineUsers from "./containers/customerManagement/OfflineUsers";
import useSocket from "./hooks/useSocket";
import { socketURL } from "./components/common/commonHelpers";
import ApiConfigurationFormContainer from "./containers/chatbot/ApiConfigurationFormContainer";
import APIConfigurationContainer from "./containers/chatbot/APIConfigurationContainer";
import PropertyConfigurationContainer from "./containers/chatbot/PropertyConfigurationContainer";
import BuildBotFlowContainer from "./containers/chatbot/BuildBotFlowContainer";
import AccountConfirmation from "./containers/auth/AccountConfirmation";
import SettingUp from "./containers/auth/SettingUp";
import CreateSubDomain from "./containers/auth/CreateSubDomain";
import ListPortals from "./containers/settings/portals/ListPortals";
import UpdatePortal from "./containers/settings/portals/UpdatePortal";
import EmailTemplateContainer from "./containers/customerManagement/EmailTemplateContainer";
import AnalyticsContainer from "./containers/analytics/AnalyticsContainer";
import PricingPlan from "./containers/settings/pricingPlan/PricingPlan";
const { customParser } = pkg;

const routes = [
  {
    path: "/chatbots",
    Component: Folders,
  },
  {
    path: "/train-chat-bot/:id",
    Component: TrainChatBot,
  },
  {
    path: "/train-chat-bot/:id/botflow/:botflowId",
    Component: BuildBotFlowContainer,
  },
  {
    path: "/train-chat-bot/:id/botflow/:botflowId/configuration/api",
    Component: APIConfigurationContainer,
  },
  {
    path: "/train-chat-bot/:id/botflow/:botflowId/configuration/api/form",
    Component: ApiConfigurationFormContainer,
  },
  {
    path: "/train-chat-bot/:id/botflow/:botflowId/configuration/property",
    Component: PropertyConfigurationContainer,
  },
  {
    path: "/build-module/:id",
    Component: BuildModule,
  },
  {
    path: "/",
    Component: Dashboad,
  },
  {
    path: "/work-log",
    Component: ClockWork,
  },
  {
    path: "/modules",
    Component: Modules,
  },
  {
    path: "/messages",
    Component: MessageContainer,
    removeDefaultCls: true,
  },
  {
    path: "/mails",
    Component: Mails,
  },
  {
    path: "/mails/:id",
    Component: Mails,
  },
  {
    path: "/tickets",
    Component: TicketsContainer,
  },
  {
    path: "/ticket-detail/:id",
    Component: TicketDetail,
  },
  {
    path: "/ticket-client-detail/:id",
    Component: TicketClientDetail,
  },
  {
    path: "/customer-management",
    Component: CmContainer,
  },
  {
    path: "/customer-management/offline-users",
    Component: OfflineUsers,
  },
  {
    path: "/customer-management/email-templates",
    Component: EmailTemplateContainer,
  },
  {
    path: "/settings/integrators",
    Component: Integrations,
  },
  {
    path: "/settings/notifications",
    Component: Notifications,
  },
  {
    path: "/settings/profile",
    Component: Profile,
  },
  {
    path: "/settings/portals",
    Component: ListPortals,
  },
  {
    path: "/settings/portals/:id",
    Component: UpdatePortal,
  },
  {
    path: "/settings/communities",
    Component: Communities,
  },
  {
    path: "/settings/pricing-plan",
    Component: PricingPlan,
  },
  {
    path: "/settings/community/:id",
    Component: CommunityDetail,
  },
  {
    path: "/analytics",
    Component: AnalyticsContainer,
  },
];

export const AppContext = createContext();

function App() {
  const [currUser, setCurrUser] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showCommunityModal, setShowCommunityModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [navToggle, setNavToggle] = useState(true);
  // const [currSocketId, setCurrSocketId] = useState(null);
  const [toastMessage, setToastMessage] = useState({
    type: "",
    message: "",
  });
  // const [socket, setSocket] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [csrfToken, setCsrfToken] = useState(null);
  const [currCommunityId, setCurrCommunityId] = useState(
    localStorage.getItem("currCommunity") || null
  );
  const history = createBrowserHistory();
  const [hasChanged, setHasChanged] = useState(false);
  const [hasChangedModal, setHasChangedModal] = useState(false);
  const [currCommunityDetails, setCurrCommunityDetails] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [gettingUserInfo, setGettingUserInfo] = useState(false);

  const { socket, setSocket, currSocketId, setCurrSocketId } = useSocket({
    currCommunityId,
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const excludedRoutes = [
    "/signin",
    "/signup",
    "/account-confirmation",
    "/setting-up",
    "/community-form",
    "/communities",
    "/chat",
    "/join-community",
    "/google/callback",
    "/peerCalling",
    "/ticket-client-detail",
    "/forgot-password",
    "/reset-password",
    "/create-subdomain",
  ];
  const getUserInfo = async () => {
    setGettingUserInfo(true);

    var payload = {
      communityId: currCommunityId,
    };

    // setTimeout(() => {
    api
      .post("/api/auth/getUserInfo", payload)
      .then((response) => {
        // // console.log(response);
        setCurrUser(response.data);
        setGettingUserInfo(false);
      })
      .catch((error) => {
        setCurrUser([]);
        setGettingUserInfo(false);
      });
    // }, 2000);
  };

  useEffect(() => {
    async function fetchCsrfToken() {
      const response = await api.post("/api/csrf-token");
      setCsrfToken(response.data.csrf_token);
    }

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    checkPath();
  }, [history.location.pathname]);

  const handleLinkClick = (e) => {
    if (hasChanged) {
      const confirmed = window.confirm(
        "Are you sure you want to leave? You have unsaved changes."
      );
      if (confirmed) {
        setHasChanged(false);
        history.push(e.currentTarget.getAttribute("href"));
      } else {
        e.preventDefault();
        return false;
      }
    }
  };

  const checkPath = () => {
    const isExcludedRoute = excludedRoutes.some((route) =>
      window.location.pathname.startsWith(route)
    );
    if (!isExcludedRoute && !localStorage.getItem("currCommunity")) {
      document.cookie = "chatSocketID-admin=";
      window.location.href = "/communities";
    }

    if (!isExcludedRoute) {
      // socketConnection();
    }

    var currCommunity = localStorage.getItem("currCommunity");
    if (currCommunity) {
      setCurrCommunityId(currCommunity);
    }
    setShowCommunityModal(false);
  };

  const socketConnection = () => {
    // client-side
    var socketID = document.cookie.replace(
      new RegExp(`(?:(?:^|.*;\\s*)chatSocketID-admin\\s*=\\s*([^;]*).*$)|^.*$`),
      `$1`
    );
    // console.log("socketID", socketID);
    const socket = io(socketURL, {
      withCredentials: true,
      extraHeaders: {
        socketID: socketID,
      },
      parser: customParser,
    });
    setSocket(socket);
    socket.on("connect", () => {
      console.log("Connecting socket App.js");
      if (socketID) {
        socketID = socketID;
      } else {
        socketID = currCommunityId;
        document.cookie = "chatSocketID-admin=" + currCommunityId;
      }
      setCurrSocketId(socketID);
    });

    return () => {
      socket.disconnect();
    };
  };

  useEffect(() => {
    if (socket) {
      let isPageFocused = true;

      window.addEventListener("focus", () => {
        isPageFocused = true;
      });

      window.addEventListener("blur", () => {
        isPageFocused = false;
      });
      const handleAppNotification = (msg) => {
        var socketID = document.cookie.replace(
          /(?:(?:^|.*;\s*)chatSocketID-admin\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        );
        if (socketID != msg.senderId) {
          playNotificationSound();

          console.log("msg", msg, document);
          if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
          } else if (Notification.permission === "granted" && !isPageFocused) {
            browserNotification(msg);
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted" && !isPageFocused) {
                browserNotification(msg);
              }
            });
          }
        }
      };

      const handleWhatsappNotification = (data) => {
        const { msg, client } = data;

        if (currCommunityId === client?.community_id) {
          playNotificationSound();

          if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
          } else if (Notification.permission === "granted" && !isPageFocused) {
            browserWhatsappNotification(client);
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted" && !isPageFocused) {
                browserWhatsappNotification(client);
              }
            });
          }
        }
      };

      socket.on("notification", handleAppNotification);

      socket.on("wt-notification", handleWhatsappNotification);

      // return () => {
      //   socket.off("notification", handleAppNotification);
      // };
    }
  }, [socket]);
  const browserNotification = (msg) => {
    const notification = new Notification("Hello!", {
      body: `You have a new message from the client. Click to open: ${window.location.origin}/messages?bot=${msg.botId}&filter=&client=${msg.senderId}`,
      icon: "https://www.dckap.com/wp-content/uploads/2022/01/dckap-primary-no-tagline-1536x260.png",
    });
  };

  const browserWhatsappNotification = (client) => {
    const notification = new Notification("Hello!", {
      body: `You have a new message from the client. Click to open: ${window.location.origin}/messages?msgTab=whatsapp&client=${client.client_id}`,
      icon: "https://www.dckap.com/wp-content/uploads/2022/01/dckap-primary-no-tagline-1536x260.png",
    });
  };

  const audio = new Audio(
    require("./assets/audio/the-notification-email-143029.mp3")
  );

  const playNotificationSound = () => {
    audio.play().catch((error) => {
      console.error("Failed to play audio:", error);
    });
  };

  function onError(msg) {
    setShowToast(true);
    setToastMessage({
      type: "error",
      message: msg || "Something went wrong",
    });
  }

  function onSuccess(msg) {
    setShowToast(true);
    setToastMessage({
      type: "success",
      message: msg,
    });
  }

  function onWarning(msg) {
    setShowToast(true);
    setToastMessage({
      type: "warning",
      message: msg,
    });
  }

  return (
    <div
      className={`App ${navToggle && "slider-close"} ${scroll ? "sticky" : ""}`}
    >
      {showToast && (
        <Toast
          message={toastMessage}
          duration={3000}
          onClose={() => setShowToast(false)}
        />
      )}

      <div className="flex">
        <AppContext.Provider
          value={{
            currUser,
            getUserInfo,
            setToastMessage,
            setShowToast,
            setShowCommunityModal,
            showCommunityModal,
            csrfToken,
            currCommunityId,
            setCurrCommunityId,
            inviteModal,
            setInviteModal,
            socket,
            setSocket,
            setCurrSocketId,
            currSocketId,
            hasChanged,
            setHasChanged,
            navToggle,
            setNavToggle,
            handleLinkClick,
            onSuccess,
            onError,
            onWarning,
            gettingUserInfo,
          }}
        >
          <Router basename="/">
            {/* <Router> */}
            <UnsavedAlert
              when={hasChanged}
              message="Are you sure you want to leave?"
              onClose={() => setHasChangedModal(false)}
            />
            <Routes>
              {routes.map((route, i) => {
                const { path, Component, removeDefaultCls } = route;
                return (
                  <Route
                    exact
                    path={path}
                    element={
                      <main className="w-full">
                        <TopNav />
                        <Sidebar />

                        {/* header height */}
                        <div className="w-screen h-[5rem]"></div>

                        <div className="flex">
                          {/* sidebar width */}
                          {/* <div className={"w-[90px]"}></div> */}

                          <div
                            className={`w-full min-h-[90vh] ${"pl-[100px] pr-4"}`}
                          >
                            <Component />
                          </div>
                        </div>
                      </main>
                    }
                    key={i}
                  />
                );
              })}
              {/* <Route
                exact
                path="/chatbots"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Folders />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/train-chat-bot/:id"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <TrainChatBot />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/train-chat-bot/:id/botflow/:botflowId"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />

                      <BuildBotFlowContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/train-chat-bot/:id/botflow/:botflowId/configuration/api"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />

                      <APIConfigurationContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/train-chat-bot/:id/botflow/:botflowId/configuration/api/form"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />

                      <ApiConfigurationFormContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/train-chat-bot/:id/botflow/:botflowId/configuration/property"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />

                      <PropertyConfigurationContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/build-module/:id"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <BuildModule />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/"
                element={
                  <>
                    <main className="w-full">
                      <TopNav />
                      <Sidebar />
                      <Dashboad />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/clockwork"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <ClockWork />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/modules"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Modules />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/messages"
                element={
                  <>
                    <main className="main-content container !p-0 !pr-4 bg-white">
                      <Sidebar />
                      <TopNav />
                      <MessageContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/mails"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Mails />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/mails/:id"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Mails />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/tickets"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <TicketsContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/ticket-detail/:id"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <TicketDetail />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/ticket-client-detail/:id"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <TicketClientDetail />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/customer-management"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <CmContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/customer-management/offline-users"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <OfflineUsers />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/customer-management/email-templates"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <EmailTemplateContainer />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/settings/integrators"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Integrations />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/settings/notifications"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Notifications />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/settings/profile"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Profile />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/settings/portals"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <ListPortals />
                    </main>
                  </>
                }
              />
              <Route
                exact
                path="/settings/portals/:id"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <UpdatePortal />
                    </main>
                  </>
                }
              />
              {/* <Route
                exact
                path="/settings/communities"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <Communities />
                    </main>
                  </>
                }
              /> */}
              <Route
                exact
                path="/settings/community/:id"
                element={
                  <>
                    <main className="md:pr-8 main-content container">
                      <Sidebar />
                      <TopNav />
                      <CommunityDetail />
                    </main>
                  </>
                }
              />{" "}
              <Route exact path="/signin" element={<SignIn />} />
              <Route exact path="/setting-up" element={<SettingUp />} />
              <Route
                exact
                path="/account-confirmation"
                element={<AccountConfirmation />}
              />
              <Route
                exact
                path="/reset-password/:id"
                element={<ResetPassword />}
              />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route exact path="/signup" element={<SignUp />} />
              <Route
                exact
                path="/communities"
                element={<CommunitySelection />}
              />
              <Route
                exact
                path="/create-subdomain"
                element={<CreateSubDomain />}
              />
              <Route exact path="/community-form" element={<CommunityForm />} />
              <Route
                exact
                path="/join-community/:id"
                element={<JoinCommunity />}
              />
              <Route exact path="/chat/:id" element={<ChatBotContainer />} />
              <Route exact path="/module/:id" element={<ModuleContainer />} />
              <Route exact path="/google/callback" element={<SignInBlock />} />
              <Route
                exact
                path="/facebook/callback"
                element={<FaceBookSetupBlock />}
              />
              <Route exact path="/peerCalling/:id" element={<Call />} />
            </Routes>
          </Router>
        </AppContext.Provider>
      </div>
    </div>
  );
}

export default App;
