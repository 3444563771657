import React, { useContext, useEffect } from "react";
import DependencyDropdown from "../tickets/DependencyDropdown";
import { AppContext } from "../../App";
import api from "../../api/api";
import useApiData from "../../hooks/useApiData";
import Spinner from "../loaders/Spinner";

function TicketAdditionalFieldsForm({
  additionaFields,
  fieldhandleChange,
  additionalFieldsError,
}) {
  const { currCommunityId } = useContext(AppContext);

  const { data, mutate, loading, isError, error } = useApiData({});

  useEffect(() => {
    mutate(() =>
      api.post("/api/getCommunityFields", { community_id: currCommunityId })
    );
  }, []);

  const commnuityFields = data?.data || [];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="form-item vertical mt-2">
      <label className="form-label mb-2">Additional Fields</label>
      <div>
        {commnuityFields.map((field, idx) => {
          const {
            field_type,
            id,
            field_name,
            field_values = [],
            is_required,
          } = field;
          return (
            <div className="my-2 pb-4 border-b !text-[12px]">
              {field_type === "input" && (
                <div>
                  <label
                    className={`form-label mb-2 ${
                      is_required === 1 ? "required-input" : ""
                    }`}
                  >
                    {field_name}
                  </label>
                  <div className>
                    <input
                      className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      type="text"
                      name={`${id}`}
                      autoComplete="off"
                      placeholder={field_name}
                      value={additionaFields[id]}
                      onChange={(e) =>
                        fieldhandleChange(e, additionaFields, field)
                      }
                    />
                  </div>
                  {additionalFieldsError && (
                    <div className="form-error">
                      {additionalFieldsError[id]}
                    </div>
                  )}
                </div>
              )}

              {field_type === "select" && (
                <div>
                  <label
                    className={`form-label mb-2 ${
                      is_required === 1 ? "required-input" : ""
                    }`}
                  >
                    {commnuityFields[idx]["field_name"]}
                  </label>
                  <div className>
                    <select
                      className="input input-form input-md h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600"
                      value={additionaFields[id]}
                      onChange={(e) =>
                        fieldhandleChange(e, additionaFields, field)
                      }
                      name={id}
                    >
                      <option value="">Please select {field_name}</option>
                      {field_values.map((field, index) => (
                        <React.Fragment key={index}>
                          <option value={field}>{field}</option>
                        </React.Fragment>
                      ))}
                    </select>
                  </div>
                  {additionalFieldsError && (
                    <div className="form-error">
                      {additionalFieldsError[id]}
                    </div>
                  )}
                </div>
              )}

              {field_type === "dependency_dropdown" ? (
                <DependencyDropdown
                  field={field}
                  selectedValues={additionaFields[id]}
                  id={id}
                  handleChange={(value) => {
                    const data = {
                      target: {
                        name: id,
                        value: Object.keys(value).length ? value : null,
                      },
                    };

                    fieldhandleChange(data, additionaFields, field);
                  }}
                  fromApprovalPage={field.is_required}
                  depenedencyError={additionalFieldsError[id]}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TicketAdditionalFieldsForm;
