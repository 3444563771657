import { useLocation } from "react-router-dom";

const pages = [
  { label: "API library", routePath: "api" },
  { label: "Custom Properties", routePath: "property" },
];

function ListConfigurationTypes({ onPageChange }) {
  const { pathname } = useLocation();

  const pageRoutes = pathname.split("/");

  return (
    <div className="mt-6">
      {pages.map((page, i) => {
        const { label, routePath } = page;

        const isActive = pageRoutes.includes(routePath);

        return (
          <div key={i}>
            <button
              className={`text-left w-[98%] py-3 px-4 border-b text-gray-700 hover:bg-gray-100 ${
                isActive ? "bg-blue-100 border-b-2 border-blue-300" : ""
              }`}
              onClick={() => onPageChange(routePath)}
            >
              {label}
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default ListConfigurationTypes;
