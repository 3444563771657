import moment from "moment";
import { formatTimeFromSeconds } from "../common/commonHelpers";
import { Tooltip } from "react-tooltip";
import { DisplayDetails } from "./MemberChatAnalytics";

function readableDate(date) {
  const originalDate = moment(date);
  const formattedDate = originalDate.format("DD MMM");

  return formattedDate;
}

function getDates(selectedDates) {
  const startDate = moment(selectedDates?.startDate);
  const endDate = moment(selectedDates?.endDate);

  const allDates = [];

  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    allDates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return allDates;
}

function refactorAgentProductivity(agentProductivity) {
  const { agentAttendance, currActivAgents } = agentProductivity;

  return currActivAgents?.map((agent) => {
    const attendance = agentAttendance[agent.id] || {};

    return {
      ...agent,
      agentAttendance: attendance?.agentAttendance,
    };
  });
}

const MembersStatus = ({ agentProductivity, selectedDates }) => {
  const members = refactorAgentProductivity(agentProductivity);

  const dates = getDates(selectedDates);

  return (
    <div>
      <table className="min-w-full text-sm text-left">
        <thead className="uppercase bg-gray-50 issue-list-title">
          <tr>
            <th
              className={`p-3 border border-gray-300 bg-[#E3E9F3] sticky top-0 -left-1`}
            >
              Members
            </th>

            {dates.map((date, i) => {
              return (
                <th
                  key={i}
                  className={`border border-gray-300 bg-[#E3E9F3] p-3`}
                >
                  <div className="w-20">{readableDate(date)}</div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {members.map((member, i) => {
            const { agentAttendance } = member;

            return (
              <tr key={i}>
                <td
                  className={`p-3 border border-gray-300 bg-white sticky -left-1`}
                >
                  <div className="flex items-center space-x-4">
                    <div className="py-4 flex items-center gap-3">
                      <div
                        className={`rounded-full flex items-center justify-center w-[35px] h-[35px] ${
                          !member?.image && "bg-gray-200"
                        }`}
                      >
                        {member?.image ? (
                          <img src={member?.image} alt="user-profile"></img>
                        ) : (
                          <i className="fa-solid fa-user text-xl"></i>
                        )}
                      </div>
                      <div className="">
                        <div className="text-md font-bold text-gray-600">
                          {member?.username}
                          {member.status == 1 ? (
                            <span className="px-2 ml-2 text-[10px] font-medium bg-green-500 p-1 text-white rounded-full">
                              ACTIVE
                            </span>
                          ) : (
                            <span className="px-2 ml-2 text-[10px] font-medium bg-gray-500 p-1 text-white rounded-full">
                              INACTIVE
                            </span>
                          )}
                        </div>
                        <div className="text-[12px]">{member?.email}</div>
                      </div>
                    </div>
                  </div>
                </td>

                {dates.map((date, j) => {
                  const attendance = agentAttendance
                    ? agentAttendance[date]
                    : null;

                  const { total, logs } = attendance || {};

                  const keyForData = member.id + "-" + date;

                  return (
                    <td
                      key={j}
                      className={`border border-gray-300 bg-white p-3`}
                    >
                      {total ? formatTimeFromSeconds(total) : null}

                      {total ? <DisplayLogs logs={logs} /> : null}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MembersStatus;

const MAP_LOG_TYPE__KEYS = {
  loggedIn: "Log In",
  loggedOut: "Log out",
};

function DisplayLogs({ logs, keyForData }) {
  return (
    <>
      <i
        className={`fa-solid fa-circle-info ml-3 text-gray-600 cursor-pointer text-lg`}
        id={"member-productivity-" + keyForData}
      ></i>

      <Tooltip
        anchorSelect={"#member-productivity-" + keyForData}
        place="top"
        style={{
          zIndex: 99999,
        }}
        clickable
      >
        {logs?.map((log, i) => {
          const { type, timeStamp } = log;

          const label = MAP_LOG_TYPE__KEYS[type];

          return (
            <div key={i} className="my-1">
              <DisplayDetails label={label} value={getTime(timeStamp)} />
            </div>
          );
        })}
      </Tooltip>
    </>
  );
}

function getTime(date) {
  const timeStamp = new Date(date);
  const timeString = timeStamp.toTimeString().split(" ")[0];
  return timeString + " (GMT)";
}
