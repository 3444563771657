function ShowWidgetConfig({
  label,
  config,
  containerCls = "",
  alignLabelTop,
  requiredInput,
}) {
  return (
    <div className={`py-4 px-5 border-b border-gray-300 ${containerCls}`}>
      <div
        className={`flex ${
          alignLabelTop ? "" : "items-center"
        }  xl:w-[70%] w-full`}
      >
        <div
          className={`md:w-[320px] w-[180px] font-medium text-gray-800 pr-3 ${
            requiredInput ? "required-input" : ""
          }`}
        >
          {label}
        </div>

        <div className="w-full">{config}</div>
      </div>
    </div>
  );
}

export default ShowWidgetConfig;
