import React, { useEffect, useState } from "react";

const MailAttachment = (props) => {
  const buttonStyle = {
    // width: "150px",
    // height: "150px",
    border: "none",
    borderRadius: "10px",
    marginLeft: "10px",
    cursor: "pointer",
  };
  const iframeStyle = {
    width: "100%",
    height: "100%",
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  };
  const EmbeddedFile = () => {
    const embedSrc = `data:${props.mimetype};base64,${props.base64Image}`;

    return (
      <iframe src={embedSrc} style={iframeStyle} title="Embedded File"></iframe>
    );
  };

  const [extension, setExtension] = useState();
  const types = [
    {
      Ext: ".doc",
      "MIME Type": "application/msword",
    },
    {
      Ext: ".dot",
      "MIME Type": "application/msword",
    },
    {
      Ext: ".docx",
      "MIME Type":
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    {
      Ext: ".dotx",
      "MIME Type":
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    },
    {
      Ext: ".docm",
      "MIME Type": "application/vnd.ms-word.document.macroEnabled.12",
    },
    {
      Ext: ".dotm",
      "MIME Type": "application/vnd.ms-word.template.macroEnabled.12",
    },
    {
      Ext: ".xls",
      "MIME Type": "application/vnd.ms-excel",
    },
    {
      Ext: ".xlt",
      "MIME Type": "application/vnd.ms-excel",
    },
    {
      Ext: ".xla",
      "MIME Type": "application/vnd.ms-excel",
    },
    {
      Ext: ".xlsx",
      "MIME Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    {
      Ext: ".xltx",
      "MIME Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    },
    {
      Ext: ".xlsm",
      "MIME Type": "application/vnd.ms-excel.sheet.macroEnabled.12",
    },
    {
      Ext: ".xltm",
      "MIME Type": "application/vnd.ms-excel.template.macroEnabled.12",
    },
    {
      Ext: ".xlam",
      "MIME Type": "application/vnd.ms-excel.addin.macroEnabled.12",
    },
    {
      Ext: ".xlsb",
      "MIME Type": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    },
  ];

  useEffect(() => {
    const mimeTypeArray = props.mimetype.split("application/").filter(Boolean);

    const uniqueMimeTypes = Array.from(new Set(mimeTypeArray));

    if (props.extension) {
      var extensionNew = props.extension;
    } else {
      // Find extension based on MIME type
      const fileType = types.find(
        (type) => type["MIME Type"] === "application/" + uniqueMimeTypes[0]
      );
      if (fileType) {
        var extensionNew = fileType["Ext"];
      }
    }
    setExtension(extensionNew);
  }, []);

  const openNewTab = () => {
    const mimeTypeArray = props.mimetype.split("application/").filter(Boolean);

    const uniqueMimeTypes = Array.from(new Set(mimeTypeArray));
    const dataUrl = `data:application/${uniqueMimeTypes[0]};base64,${props.base64Image}`;

    const newTab = window.open();
    newTab.document.body.innerHTML = `<iframe src="${dataUrl}" style="width: 100%; height: 100vh;"></iframe>`;
  };

  const downloadImage = () => {
    const mimeTypeArray = props.mimetype.split("application/").filter(Boolean);

    const uniqueMimeTypes = Array.from(new Set(mimeTypeArray));

    const dataUrl = `data:application/${uniqueMimeTypes[0]};base64,${props.base64Image}`;

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = dataUrl;

    let extension = null;

    // console.log(props.extension, uniqueMimeTypes[0]);
    if (props.extension) {
      extension = props.extension;
    } else {
      // Find extension based on MIME type
      const fileType = types.find(
        (type) => type["MIME Type"] === "application/" + uniqueMimeTypes[0]
      );
      if (fileType) {
        extension = fileType["Ext"];
      }
    }

    link.download = `${props.name}.${extension}`; // Specify the desired filename here

    // Programmatically trigger the download
    link.click();
  };

  return (
    <button
      className="text-indigo-500 text-underline"
      style={buttonStyle}
      onClick={downloadImage}
    >
      {props.name}.{extension}
    </button>
  );
};

export default MailAttachment;
