import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../../api/api";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import SignInCommon from "../../components/common/SignInCommon";
import Select from "react-select";
import Spinner from "../../components/loaders/Spinner";
import useApiData from "../../hooks/useApiData";
import { setCookie } from "../../components/common/commonHelpers";
import useCommunityDetails from "../../hooks/useCommunityDetails";

const CommunitySelection = () => {
  const [community, setCommnuity] = useState();
  const [communitySelectionError, setcommunitySelectionError] = useState("");
  const [formError, setFormError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [initial, setInitial] = useState(true);
  const [availableCommunities, setAvailableCommunities] = useState([]);
  const location = useNavigate();
  const {
    currUser,
    getUserInfo,
    setToastMessage,
    setShowToast,
    setCurrCommunityId,
    onError,
  } = useContext(AppContext);

  const jwtToken = localStorage.getItem("jwtToken");
  const origin = localStorage.getItem("origin");

  function goToCommunityForm() {
    if (origin && jwtToken) {
      window.location.href = origin + `/community-form?jwtToken=${jwtToken}`;
    } else {
      console.log(
        "Unable to open community form",
        ": origin, jwtToken",
        origin,
        jwtToken
      );
    }
  }

  useEffect(() => {
    setInitial(true);
    api
      .post("/api/getCommunities")
      .then((response) => {
        setProcessing(false);
        setInitial(false);
        if (response.data.data.length > 0) {
          setAvailableCommunities(response.data.data);
        } else {
          goToCommunityForm();
        }
      })
      .catch((error) => {
        setProcessing(false);
        setInitial(false);
      });
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  const { getCommunityDetails, loading } = useCommunityDetails();

  const validation = (name, value, error = null) => {
    if (name === "community" || name == "selectedCommunity") {
      if (error != null) {
        setcommunitySelectionError(error);
      } else {
        if (value) {
          setCommnuity(value);
          setcommunitySelectionError("");
        } else {
          setCommnuity("");
          setcommunitySelectionError("Please select a community");
        }
      }
    }
  };
  const handleChange = (value) => {
    if (value?.label && value?.value) {
      // console.log("value", value);
      validation("community", value.value);
    }
  };

  const handleForm = (e) => {
    e.preventDefault();
    // setProcessing(true);
    if (!community) {
      validation("community", community);

      return;
    }
    var payload = {
      community_id: community,
    };

    localStorage.setItem("currCommunity", community);
    setCurrCommunityId(community);

    getCommunityDetails(payload);

    // if (community) {
    //   localStorage.setItem("currCommunity", community);
    //   setCurrCommunityId(community);
    //   location("/");
    // } else {
    //   setcommunitySelectionError("Please select a community");
    // }

    // api
    //   .post("/api/imapConnection", payload)
    //   .then((response) => {
    //     setProcessing(false);
    //     if (response.data.status == "success") {
    //       location("/");
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response.data.errors) {
    //       var errors = error.response.data.errors;
    //       Object.keys(errors).map((keyName, keyIndex) => {
    //         validation(keyName, null, errors[keyName][0]);
    //       });
    //     }
    //     setProcessing(false);

    //     // console.log(error);
    //   });
  };

  return (
    <div className="page-container sign-heignt relative h-full flex flex-auto flex-col">
      <div className="grid lg:grid-cols-4 h-full">
        <SignInCommon />
        <div className="col-span-2 flex flex-col justify-center items-center bg-white">
          <div className="xl:min-w-[550px] px-8">
            <div className="mb-8 community-form-cnt">
              <h3 className="mb-1 text-3xl text-slate-800 font-semibold font-['Inter']">
                Communities
              </h3>
              <p className="text-slate-800 text-base font-['Inter']">
                Please Select a community
              </p>
            </div>
            <div>
              {initial ? (
                <div className="flex items-center justify-center ">
                  <Spinner />
                </div>
              ) : (
                <form action="#" method="post" onSubmit={handleForm}>
                  <div className="form-container community-form vertical">
                    <div className="form-item vertical">
                      <label className="form-label mb-1 required-input">
                        Community
                      </label>
                      <div className>
                        <Select
                          className={`${
                            communitySelectionError !== "" || formError !== ""
                              ? "input-danger"
                              : ""
                          } text-sm w-full h-10 focus-within:border-gray-300 focus:border-gray-300 focus-visible:shadow`}
                          options={availableCommunities.map(
                            (community, index) => ({
                              value: community.community_id,
                              label: community.name,
                            })
                          )}
                          onChange={handleChange}
                          isClearable
                          isSearchable
                        />
                        {communitySelectionError !== "" ? (
                          <div className="form-error">
                            {communitySelectionError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <button
                      className="inline-flex items-center justify-center px-4 py-2 font-medium text-sm leading-6 shadow rounded text-white grat-btn  hover:shadow-lg h-11 font-['Inter'] transition ease-in-out duration-150 w-full button_animi"
                      type="submit"
                      disabled={loading || processing}
                    >
                      {(loading || processing) && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      Continue
                    </button>

                    {origin ? (
                      <div className="mt-4 text-center font-['Inter'] text-sm">
                        <span>Want to Create a community? </span>
                        <button
                          type="button"
                          className="text-[#0058FF] font-semibold underline hover:text-blue-800"
                          onClick={goToCommunityForm}
                        >
                          Create
                        </button>
                      </div>
                    ) : (
                      <div className="mt-4 text-center font-['Inter'] text-sm">
                        <span>Want to Create A subdomain? </span>
                        <button
                          type="button"
                          className="text-[#0058FF] font-semibold underline hover:text-blue-800"
                          onClick={() => {
                            location("/create-subdomain");
                          }}
                        >
                          Create Subdomain
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunitySelection;
