import { useEffect, useState } from "react";
import Papa from "papaparse";

const CSVReader = ({ url }) => {
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);

  const fetchCSVDataFromURL = (url) => {
    // Fetch the CSV data from the provided URL
    fetch(url, {
      mode: "cors",
    })
      .then((response) => response.text())
      .then((data) => {
        // Parse the CSV data using PapaParse
        console.log(data);

        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            const rowsArray = [];
            const valuesArray = [];

            // Iterating data to get column name and their values
            results.data.map((d) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });

            // Parsed Data Response in array format
            setParsedData(results.data);

            // Filtered Column Names
            setTableRows(rowsArray[0]);
            // Filtered Values
            setValues(valuesArray);
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching CSV data:", error);
      });
  };

  useEffect(() => {
    fetchCSVDataFromURL(url);
  }, [url]);

  return (
    <div>
      {/* Table */}
      <table>
        <thead>
          <tr>
            {tableRows?.map((rows, index) => {
              return <th key={index}>{rows}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {values?.map((value, index) => {
            return (
              <tr key={index}>
                {value.map((val, i) => {
                  return <td key={i}>{val}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CSVReader;
