import Loading from "../loaders/Loading";

function PrimaryButton({ label, disabled, type, loading, btnCls, onClick }) {
  return (
    <button
      className={`flex justify-center bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white px-4 py-2 rounded-md w-fit ${
        loading ? "cursor-not-allowed" : ""
      } ${btnCls}`}
      type={type || "submit"}
      disabled={disabled}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {loading && <Loading />}

      {label || "Submit"}
    </button>
  );
}

export default PrimaryButton;
