import React from "react";
import Modal from "./Modal";
import Loading from "../loaders/Loading";

function DeleteModal({ title, open, onClose, onDelete, loading }) {
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      grayCls="!z-[100]"
      containerCls="!z-[200]"
    >
      <div className="flex flex-col items-center justify-center my-2">
        <div className="text-lg">{title}</div>
        <div className="flex space-x-4 mt-6">
          <button
            className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg flex items-center space-x-2"
            onClick={onDelete}
          >
            {loading && <Loading />}
            Delete
          </button>
          <button
            className="px-4 py-2 border border-gray-700 hover:bg-gray-100 text-gray-700 rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
