import Modal from "../common/Modal";
import AutoAssignForm from "./AutoAssignForm";

function AutoAssign({ modal, setModal }) {
  return (
    <div>
      <Modal
        isOpen={modal}
        onClose={() => setModal("")}
        title={"Auto Assign"}
        customCls="md:!max-w-[1100px]"
      >
        <div className="py-6">
          <AutoAssignForm closeModal={() => setModal("")} />
        </div>
      </Modal>
    </div>
  );
}

export default AutoAssign;
